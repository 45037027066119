import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Navigator } from 'src/app/system/components/menu/navigator';
import { AuthenticationProvider } from '../../providers/authentication-provider';
import { UserSessionProvider } from 'src/app/system/providers/user-session';
import { CustomerModel } from 'src/app/modules/account/models/customer-model';
import { IonInput, Platform } from '@ionic/angular';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { WebservicesProvider } from 'src/app/system/providers/webservices';
import { TranslationService } from 'src/app/service/translation.service';
import { CommonService } from 'src/app/service/common.service';
import { Config } from 'src/app/system/providers/configuration';

@Component({
  selector: 'app-active-account',
  templateUrl: './active-account.page.html',
  styleUrls: ['./active-account.page.scss'],
})
export class ActiveAccountPage implements OnInit {

  @Input() callback: any;
  @ViewChild('verifyCodeInput') verifyCodeInput: IonInput;

  customer: CustomerModel;

  emailForm: FormGroup;
  email: AbstractControl;
  verifyCode: AbstractControl;

  verifiedStatus = false;
  disableGetCodeButton = false;
  canInputVerificationCode = false;

  resendInterval: any;
  resendTimePeriod = 60;
  downTimePeriod = 1;

  resent = false;

  customerSub: any

  constructor(
    private authProvider: AuthenticationProvider,
    public userSession: UserSessionProvider,
    private cdr: ChangeDetectorRef,
    private platform: Platform,
    private webService: WebservicesProvider,
    private transService: TranslationService,
    public comService: CommonService,
    private config: Config
  ) {
    this.emailForm = new FormGroup({
      email: new FormControl('', [
        Validators.pattern(
          '^([0-9a-zA-Z]+[-._+&amp;])*[0-9a-zA-Z]+@([-0-9a-zA-Z]+[.])+[a-zA-Z]{2,6}$'
        ),
        Validators.required,
        Validators.email
      ]),
      verifycode: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6)
      ])
    });

    this.email = this.emailForm.get('email');
    this.verifyCode = this.emailForm.get('verifycode');
  }

  ngOnInit() {
    this.downTimePeriod = this.config.getConfig().COOLDOWN_PERIOD_IN_MINUTES_FOR_SENDING_EMAIL_VERIFICATION ? Number(this.config.getConfig().COOLDOWN_PERIOD_IN_MINUTES_FOR_SENDING_EMAIL_VERIFICATION) : 1;

    this.customerSub = this.userSession.getCustomer().subscribe(customer => {
      if (customer) {
        this.customer = customer;
        this.email.patchValue(this.customer.email);

        // this.resend();
      }
    });

    setTimeout(() => {
      this.customerSub.unsubscribe();
      this.customerSub = null;
    }, 1500);
  }

  ionViewWillLeave() {
    if (this.customerSub) this.customerSub.unsubscribe();
    this.customerSub = null;
  }

  resend() {
    if (this.customer) {
      // this.authProvider.resendEmailCode(this.customer.customerId)
      this.webService.post('player/email/send-validation-code', {
        email: this.email.value,
        session_id: this.userSession.getSessionToken()
      }).subscribe(async (resp) => {
        if (resp.code == 200 && resp.result) {
          this.resent = true;
          var msg = await this.transService.getTranslationWord('AUTH.SIGNUP.SENT_VERIFY_EMAIL');
          this.comService.showToast(msg);
          this.canInputVerificationCode = true;
          this.disableGetCodeButton = true;

          if (this.verifyCodeInput) this.verifyCodeInput.value = '';
          this.verifyCode.patchValue('');
          this.verifyCode.markAsUntouched();
          this.verifyCode.markAsPristine();
          this.verifiedStatus = false;

          this.emailForm.markAsDirty();

          clearInterval(this.resendInterval);
          this.resendInterval = null;
          this.resendTimePeriod = this.downTimePeriod * 60;

          this.resendInterval = setInterval(() => {
            this.resendTimePeriod--;

            if (this.resendTimePeriod <= 0) {
              clearInterval(this.resendInterval);
              this.resendInterval = null;
              this.disableGetCodeButton = false;
              this.resent = false;
              this.resendTimePeriod = this.downTimePeriod * 60;
            }

            this.cdr.detectChanges();
          }, 1000);

          this.cdr.detectChanges();
        }
      }, error => {
        console.error('err == ', error);
        this.canInputVerificationCode = false;
        this.disableGetCodeButton = false;
        this.comService.showToast(error.getErrorMessage(), 'error_toast');
      });
    }
  }

  inputVerificationCode($event) {
    this.verifyCode.setErrors(null);
    var val = $event.target.value;
    var filteredValue = val.replace(/[^0-9]*$/g, '');

    if (this.verifyCodeInput) this.verifyCodeInput.value = filteredValue;
    this.verifyCode.patchValue(filteredValue);
    this.verifyCode.markAsTouched();
    this.verifyCode.markAsDirty();
    this.verifiedStatus = false;

    var verificationCode = filteredValue;
    if (verificationCode.length == 6) {
      this.validateCode();
    }
  }

  validateCode() {
    this.webService.post('player/email/validate-code', {
      email: this.email.value,
      code: this.verifyCode.value,
      session_id: this.userSession.getSessionToken()
    }).subscribe(async (response) => {
      console.log('validate code == ', response);
      if (response.code == 200) {
        this.verifiedStatus = true;
      }
    }, async (error) => {
      console.error('validate err == ', error);
      this.verifiedStatus = false;
      this.verifyCode.setErrors({WRONG_CODE: true});
    });
  }

  submit() {
    this.webService.post('auth/player/account', {
      email: this.email.value,
      customer_id: this.customer.customerId,
      session_id: this.userSession.getSessionToken(),
      code: this.verifyCode.value,
      email_check: true
    }).subscribe((response) => {
      if (response.code == 200) {
        this.callback();
      }
    }, (err: any) => {
      console.error('update err == ', err);
      this.comService.showToast(err.error_payload, 'error_toast');
    });
  }

}
