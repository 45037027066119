import {
  stringToClass,
  pathnameRegistered,
  deepLinkRegistered,
} from './stringToClassMapper';
import { isUndefined } from '../../../shared/codeUtils';
import { GoogleToolsProvider } from '../../../shared/providers/googleTools';
import {
  MenuController,
  ModalController,
  NavController,
  Platform,
  PopoverController,
} from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';
import { LoginPage } from 'src/app/modules/authentication/pages/login/login';
import { CommonService } from 'src/app/service/common.service';
export interface NavigationOptions {
  forceModal?: boolean;
  forcePage?: boolean;
  multipleModals?: boolean;
  callback?: Function;
  disableBackdrop?: boolean;
  cssClass?: string;
}

class NAV {

  public platform: Platform;
  public modalCtrl: ModalController;
  public navCtrl: NavController;
  public menuCtrl: MenuController;
  public popCtrl: PopoverController;
  private googleToolsProvider;
  public events: MyEvent;
  private currentPath;
  private activePage = '';
  public comService: CommonService;

  constructor(
    public modalStack: Array<any>
  ) {
    this.modalStack = [];
  }
  /*
   * Navigates to a page/modal.
   * params: pageName - name of the poage to navigate
   * Please update the stringToCalssMapper.ts when adding a new page
   *
   */
  async navigateTo(pageName: any, options?: NavigationOptions, params?: any) {
    this.currentPath = window.location.pathname;
    let opts: NavigationOptions = this.getOptions(options);
    let page = stringToClass(pageName);
    let prms = typeof params !== 'undefined' ? params : '';
    let modalCSSClass: string = '';
    let smallPages = [];

    if (smallPages.indexOf(pageName) > -1) {
      modalCSSClass = 'small-page';
    } else if (opts.cssClass) {
      modalCSSClass = opts.cssClass;
    }
    if (pageName == 'GetReferralCodePage') {
      modalCSSClass = 'referr_modal';
    }
    if (pageName == 'LogoutPage') {
      modalCSSClass = 'micro_modal';
    }
    if (pageName == 'AccountInformationPage') {
      modalCSSClass = 'account_info';
    }
    // dismiss the modals
    if (
      pageName != 'LogoutPage' &&
      this.modalStack.length &&
      !opts.multipleModals
    ) {
      this.dismissModals();
      this.toLobby();
    }

    if (this.isModal(opts)) {
      let modal = await this.modalCtrl.create({
        component: page,
        componentProps: {
          ...prms,
        },
        backdropDismiss: opts.disableBackdrop ? false : true,
        // canDismiss: opts.disableBackdrop ? false : true,
        showBackdrop: true,
        cssClass: modalCSSClass,
      });
      modal.present();

      this.modalStack.push(modal);
    } else if (this.isPage(opts)) {
      var openPage: any;
      if (pageName == 'LoginPage') {
        openPage = "login";
      } else if (pageName == 'SignupPage') {
        openPage = "signup1";
      } else {
        openPage = pageName;
      }
      console.log('Navigator open Page ' + pageName);
      this.navCtrl.navigateForward(`${this.comService.currentTemplate}/${openPage}`, prms);
    }

    // send google events
    this.googleToolsProvider = Object.create(GoogleToolsProvider.prototype);
    this.googleToolsProvider.sendPageHistoryEvent(pageName);
    this.doDeepLinking(pageName);
    this.activePage = pageName;
  }

  public getActivePage() {
    return this.activePage;
  }

  private then(result, params) {
    // if canEnter returns false and there is a redirect page
    if (!result && !isUndefined(params.redirectPage)) {
      this.navigateTo(params.redirectPage);
    }
    //... more then code
  }

  back() {
    if (this.modalCtrl) this.modalCtrl.getTop().then(modal => {
      modal.dismiss();
    });
    if (this.popCtrl) this.popCtrl.getTop().then(pop => pop.dismiss());
    if (this.modalStack.length) {
      let modal = this.modalStack[this.modalStack.length - 1];
      modal.dismiss();
      this.modalStack.pop();
    } else {
      this.navCtrl.pop();
    }
    // do not show the side menu if there are modals in chain
    if (!this.modalStack.length) {
      // this.menuCtrl.open();
      //click back on modal - brings back the previous pathname
      let path = window.location.pathname;
      if (pathnameRegistered(path)) this.pushLastHistoryState();
    }
  }
  openNavigation() {
    this.back();
  }
  toLobby() {
    if (this.popCtrl) this.popCtrl.getTop().then(pop => pop.dismiss());
    if (this.modalCtrl) this.modalCtrl.getTop().then(modal => {
      modal?.dismiss();
    });
    if (this.modalStack.length) {
      console.log('Modal To Lobby');
      setTimeout(() => {
        this.dismissModals();
      }, 50);
    } else {
      console.log('Page To Lobby');
      // this.navCtrl.navigateRoot('dashboard');
    }

    let path = window.location.pathname;
    if (pathnameRegistered(path)) this.pushLastHistoryState();
  }

  toSports() {
    if (this.modalStack.length) {
      console.log('Modal To Lobby');
      setTimeout(() => {
        this.dismissModals();
      }, 50);
    }

    let path = window.location.pathname;
    if (pathnameRegistered(path)) this.pushLastHistoryState();

    this.events.publishSomeData({ event: 'event:tabs:load', tab: 'sports' });
  }

  toHomePage() {
    this.toLobby();
    // this.events.publish("event:homepage:load")
    this.events.publishSomeData({ event: 'event:homepage:load:app-action' });
  }

  async openModal(pageName, cssClass = '', params = {}, canDismiss = true) {
    let page = stringToClass(pageName);
    var modal = await this.modalCtrl.create({
      component: page,
      componentProps: {
        ...params
      },
      backdropDismiss: canDismiss,
      canDismiss: canDismiss,
      cssClass: cssClass
    });
    modal.present();
    this.modalStack.push(modal);
  }
  /*
   * Returns true is there are no modals/pages in the que
   * and menu is not open
   *
   * */
  isOnLobby() {
    let noNavigation = !this.modalStack.length ? true : false;
    let menuIsOpen = !this.menuCtrl.isOpen();
    return noNavigation && menuIsOpen ? true : false;
  }

  private isModal(opts: NavigationOptions): boolean {
    return (
      !opts.forcePage &&
      (this.isDesktop() ||
        this.isMobileWeb() ||
        this.isMobileDevice() ||
        opts.forceModal)
    );
  }
  private isPage(opts: NavigationOptions): boolean {
    return !opts.forceModal && (!this.isDesktop() || opts.forcePage);
  }
  private isDesktop(): boolean {
    return this.platform.is('desktop');
  }
  private isMobileWeb(): boolean {
    return this.platform.is('mobileweb');
  }
  private isMobileDevice(): boolean {
    return this.platform.is('mobile');
  }
  private getOptions(options: NavigationOptions) {
    let opts: NavigationOptions = {};
    if (typeof options === 'undefined') {
      opts = {
        forceModal: false,
        forcePage: false,
        multipleModals: false,
        callback: null,
        disableBackdrop: false,
        cssClass: ''
      };
    } else {
      opts.forceModal =
        typeof options.forceModal === 'undefined' ? false : options.forceModal;
      opts.forcePage =
        typeof options.forcePage === 'undefined' ? false : options.forcePage;
      opts.multipleModals =
        typeof options.multipleModals === 'undefined'
          ? false
          : options.multipleModals;
      opts.callback =
        typeof options.callback === 'undefined' ? null : options.callback;

      opts.disableBackdrop = typeof options.disableBackdrop === 'undefined' ? false : options.disableBackdrop;

      opts.cssClass = options.cssClass || '';
    }
    return opts;
  }
  dismissModals() {
    this.modalStack.forEach((modal, index) => {
      modal.dismiss();
    });
    this.modalStack = [];
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  private doDeepLinking(pathname, title = '') {
    if (!!deepLinkRegistered(pathname)) {
      this.pushHistoryState(deepLinkRegistered(pathname));
    }
  }

  private pushLastHistoryState() {
    if (this.currentPath) {
      if (this.currentPath[0] == '/') {
        this.currentPath = this.currentPath.substring(1);
      }
      this.pushHistoryState(this.currentPath);
    }
  }

  pushHistoryState(pathname) {
    //only for web
    // if (!this.platform.is('cordova')) {
    //   let newurl =
    //     window.location.protocol +
    //     '//' +
    //     window.location.host +
    //     '/' +
    //     pathname +
    //     window.location.search +
    //     document.location.hash;
    //   history.pushState({ path: newurl }, '', newurl);
    //   this.events.publishSomeData({
    //     event: 'event:history:push',
    //     data: { url: newurl },
    //   });
    // }
  }

  redirectAfterSuccessfulRegistration() {
    //Removing dismissModal call as that's causing issue After Singup Complete steps to show Geo Popup.
    setTimeout(() => {
      this.dismissModals();
    }, 300);
    this.events.publishSomeData({ event: 'event:load:sports:signup' });
    this.events.publishSomeData({ event: 'event:tabs:load', tab: 'dashboard' });
  }
}
export let Navigator = new NAV([]);
