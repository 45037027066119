
import { SignupStepOnePage } from './../../../modules/authentication/pages/signup-step-one/signup-step-one';
import { LimitsHistoryPage } from './../../../modules/account/pages/responsible-gaming/limits-history/limits-history';
import { SetDepositLimitsPage } from './../../../modules/account/pages/responsible-gaming/set-deposit-limits/set-deposit-limits';
import { SetLossLimitsPage } from './../../../modules/account/pages/responsible-gaming/set-loss-limits/set-loss-limits';
import { SetTimeLimitsPage } from './../../../modules/account/pages/responsible-gaming/set-time-limits/set-time-limits';
import { SetWagerLimitsPage } from './../../../modules/account/pages/responsible-gaming/set-wager-limits/set-wager-limits';
import { UpdatePhoneNumberPage } from './../../../modules/account/pages/information-security/update-phone-number/update-phone-number';
// Information & Security
import { AccountInformationPage } from '../../../modules/account/pages/information-security/account-information/account-information';
import { PersonalInformationPage } from '../../../modules/account/pages/information-security/personal-information/personal-information';
import { ChangePasswordPage } from '../../../modules/account/pages/information-security/change-password/change-password';


import { PendingWithdrawalsPage } from '../../../modules/cashier/pages/overview/pending-withdrawals/pending-withdrawals';
import { WithdrawalReverseConfirmPage } from '../../../modules/cashier/pages/withdrawal/withdrawal-reverse-confirm/withdrawal-reverse-confirm';
import { WithdrawalReverseWithdrawalPage } from '../../../modules/cashier/pages/withdrawal/withdrawal-reverse-withdrawal/withdrawal-reverse-withdrawal';
import { MyTransactionMethodsConfirmPage } from '../../../modules/cashier/pages/my-transaction-methods-confirm/my-transaction-methods-confirm';
import { AccountSummaryPage } from '../../../modules/cashier/pages/account-summary/account-summary';

// Bonuses
import { CasinoBonusActivePage } from '../../../modules/rewards/pages/bonuses/casino-bonus/casino-bonus-active/casino-bonus-active';
import { CasinoBonusHistoryPage } from '../../../modules/rewards/pages/bonuses/casino-bonus/casino-bonus-history/casino-bonus-history';
import { CasinoBonusDetailsPage } from '../../../modules/rewards/pages/bonuses/casino-bonus/casino-bonus-details/casino-bonus-details';
import { RedeemBonusCodePage } from '../../../modules/rewards/pages/bonuses/redeem-bonus-code/redeem-bonus-code';
/**
 * END Rewards
 */
import { ForgotPasswordPage } from './../../../modules/authentication/pages/forgot-password/forgot-password';
import { LoginPage } from './../../../modules/authentication/pages/login/login';
import { LogoutPage } from './../../../modules/authentication/pages/logout/logout';
import { ResetPasswordPage } from './../../../modules/account/pages/information-security/reset-password/reset-password';

import { NewBankPage } from 'src/app/pages/new-bank/new-bank.page';
import { MenuPage } from 'src/app/pages/menu/menu.page';
import { BonusesPage } from 'src/app/pages/bonuses/bonuses.page';
import { ActiveAccountPage } from 'src/app/modules/authentication/pages/active-account/active-account.page';
import { GetReferralCodePage } from 'src/app/modules/rewards/pages/get-referral-code/get-referral-code.page';
import { ProfileInfoPage } from 'src/app/pages/profile-info/profile-info.page';
import { FootballPage } from 'src/app/pages/football/football.page';
import { EditNamePage } from 'src/app/modules/account/pages/information-security/edit-name/edit-name.page';
import { PaymentsPage } from 'src/app/pages/payments/payments.page';
import { RefWithDepoComponent } from 'src/app/components/ref-with-depo/ref-with-depo.component';
import { TransactionsPage } from 'src/app/pages/transactions/transactions.page';
import { GameHistoryPage } from 'src/app/pages/game-history/game-history.page';
import { LogoutConfirmComponent } from 'src/app/modules/account/components/logout-confirm/logout-confirm.component';
import { AddEmailPage } from 'src/app/modules/account/pages/information-security/add-email/add-email.page';
import { BettingCommissionComponent } from 'src/app/pages/referrals/components/betting-commission/betting-commission.component';
import { UserListComponent } from 'src/app/pages/referrals/components/user-list/user-list.component';
import { VipLevelBonusComponent } from 'src/app/pages/referrals/components/vip-level-bonus/vip-level-bonus.component';
import { AcheiveBonusComponent } from 'src/app/pages/referrals/components/acheive-bonus/acheive-bonus.component';
import { QRCodeComponent } from 'src/app/pages/referrals/components/qrcode/qrcode.component';
import { ExchangeConfirmComponent } from 'src/app/pages/referrals/components/exchange-confirm/exchange-confirm.component';
import { SpinWheelComponent } from 'src/app/components/spin-wheel/spin-wheel.component';


const classFactory: Object = {
    "LimitsHistoryPage": LimitsHistoryPage,
    "SetDepositLimitsPage": SetDepositLimitsPage,
    "SetTimeLimitsPage": SetTimeLimitsPage,
    "SetWagerLimitsPage": SetWagerLimitsPage,
    "SetWagersLimitsPage": SetWagerLimitsPage,
    "SetLossLimitsPage": SetLossLimitsPage,
    "AccountInformationPage": AccountInformationPage,
    "PersonalInformationPage": PersonalInformationPage,
    "ChangePasswordPage": ChangePasswordPage,
    "PendingWithdrawalsPage": PendingWithdrawalsPage,
    "WithdrawalReverseConfirmPage": WithdrawalReverseConfirmPage,
    "WithdrawalReverseWithdrawalPage": WithdrawalReverseWithdrawalPage,
    "NewBankPage": NewBankPage,
    "MyTransactionMethodsConfirmPage": MyTransactionMethodsConfirmPage,
    "AccountSummaryPage": AccountSummaryPage,
    "CasinoBonusActivePage": CasinoBonusActivePage,
    "CasinoBonusHistoryPage": CasinoBonusHistoryPage,
    "CasinoBonusDetailsPage": CasinoBonusDetailsPage,
    "RedeemBonusCodePage": RedeemBonusCodePage,
    "ForgotPasswordPage": ForgotPasswordPage,
    "LoginPage": LoginPage,
    "LogoutPage": LogoutPage,
    "ResetPasswordPage": ResetPasswordPage,
    "SignupStepOnePage":SignupStepOnePage,
    "UpdatePhoneNumberPage": UpdatePhoneNumberPage,
    "MenuPage": MenuPage,
    "BonusesPage": BonusesPage,
    "ActiveAccountPage": ActiveAccountPage,
    "GetReferralCodePage": GetReferralCodePage,
    "ProfileInfoPage": ProfileInfoPage,
    "FootballPage": FootballPage,
    "EditNamePage": EditNamePage,
    "PaymentsPage": PaymentsPage,
    "RefWithDepoComponent": RefWithDepoComponent,
    "TransactionsPage": TransactionsPage,
    "GameHistoryPage": GameHistoryPage,
    "LogoutConfirmComponent": LogoutConfirmComponent,
    "AddEmailPage": AddEmailPage,
    "BettingCommissionComponent": BettingCommissionComponent,
    "UserListComponent": UserListComponent,
    "AcheiveBonusComponent": AcheiveBonusComponent,
    "VipLevelBonusComponent": VipLevelBonusComponent,
    "QRCodeComponent": QRCodeComponent,
    "ExchangeConfirmComponent": ExchangeConfirmComponent,
    "SpinWheelComponent": SpinWheelComponent
}

export const stringToClass = className => {

    if (className in classFactory) {
        return classFactory[className];
    } else {
        console.error("No page class match for " + className)
    }
};

export const deepLinks = () => {
    let formatedDialogPathname = Object.keys(classFactory).reduce((ret, key) => {
        switch (key) {
            case 'DepositMainPage':
                ret[key] = 'deposit'
                break;
            case 'SignupStepOnePage':
                ret[key] = 'signup'
                break;
            case 'SignupStepTwoPage':
                ret[key] = 'signup-step-2'
                break;
            case 'DepositEcheckSelectPage':
            case 'DepositVipPage':
            case 'DepositBorgataPage':
            case 'DepositPrePaidCardsPage':
            case 'DepositOnlineBankingPage':
            case 'DepositPayWithCashPage':
            case 'DepositNetellerPage':
            case 'EnvironmentSelectorPage':
            case 'GeoNotEnabledAtLaunchPage':
            case 'GeoNotEnabledPage':
            case 'GeoStatusPage':
            case 'GeoComplyOobeeInstallPage':
            case 'InvalidGeoComplyPage':
            case 'InvalidGeoPage':
            case 'InvalidKycPage':
            case 'ResetPasswordVerifyStep2Page':
            case 'ResetPasswordVerifyStep3Page':
            case 'IdologyKbaPage':
            case 'DepositThresholdPage':
                ret[key] = ''
                break;
            case 'LoginPage':
                ret[key] = 'login'
                break;
            default:
                ret[key] = key.replace('Page', '').toLowerCase();
                break;
        }

        return ret;
    }, {});


    return formatedDialogPathname;
}


export const deepLinkRegistered = pageName => {
    if (pageName[0] == "/") {
        pageName = pageName.substring(1)
    }
    return   (pageName in deepLinks()) ? deepLinks()[pageName] : false;
};

export const pathnameRegistered = pathname => {
    if (pathname[0] == "/") {
        pathname = pathname.substring(1)
    }

    let inversedDeepLinks = Object.keys(deepLinks()).reduce((ret, key) => {
        ret[deepLinks()[key]] = key;
        return ret;
    }, {});

    return  (pathname in inversedDeepLinks) ? inversedDeepLinks[pathname] : false;
};
