import { Injectable } from '@angular/core';
import { Config } from '../../../system/providers/configuration';
import { WebservicesProvider } from '../../../system/providers/webservices';
import { Observable, combineLatest, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KeywordsProvider {
  private keywordsAndGames;

  constructor(private config: Config, private webservice: WebservicesProvider) {
    this.keywordsAndGames = new Map();
  }

  getKeywordsAndGames(lobbyName) {
    if (this.keywordsAndGames.has(lobbyName)) {
      return new Promise((resolve, reject) => {
        let data = this.keywordsAndGames.get(lobbyName);
        resolve(data);
      });
    } else {
      return new Promise((resolve, reject) => {
        combineLatest([
          this.fetchKeywords(lobbyName),
          this.fetchGames(lobbyName),
        ]).subscribe(
          (response: any) => {
            this.keywordsAndGames.set(lobbyName, response);
            return resolve(response);
          },
          (error) => {
            return reject();
          }
        );
      });
    }
  }

  forceFetch(lobbyName = 'web') {
    return new Promise((resolve, reject) => {
      combineLatest([
        this.fetchKeywords(lobbyName),
        this.fetchGames(lobbyName),
      ]).subscribe(
        (response: any) => {
          this.keywordsAndGames.set(lobbyName, response);
          return resolve(response);
        },
        (error) => {
          return reject();
        }
      );
    });
  }

  private fetchKeywords(lobbyName) {
    return this.webservice.get(`lobby/${lobbyName}/keywords`, {
      client_app_id: this.config.getClientApp(),
    });
  }

  private fetchGames(lobbyName) {
    console.log('client_app_id == ', this.config.getClientApp());
    return this.webservice.get(`lobby/${lobbyName}/games`, {
      client_app_id: this.config.getClientApp(),
    });
  }

  getLiveDealerGames() {
    return this.fetchGames('LIVE_DEALER');
  }
}
