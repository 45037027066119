import { Component, OnInit } from '@angular/core';
import { Navigator } from 'src/app/system/components/menu/navigator';

@Component({
  selector: 'app-vip-level-bonus',
  templateUrl: './vip-level-bonus.component.html',
  styleUrls: ['./vip-level-bonus.component.scss'],
})
export class VipLevelBonusComponent implements OnInit {

  levels: any[] = [
    {level: 4, wager: 3000, reward: 400},
    {level: 8, wager: 11000, reward: 2000},
    {level: 14, wager: 23000, reward: 4000},
    {level: 22, wager: 39000, reward: 9500},
    {level: 30, wager: 57000, reward: 20000},
    {level: 38, wager: 73000, reward: 40000},
    {level: 46, wager: 89000, reward: 63000},
    {level: 54, wager: 179000, reward: 95000},
    {level: 62, wager: 409700, reward: 160000},
    {level: 70, wager: 1500000, reward: 400000}
  ];

  constructor() { }

  ngOnInit() {}

  close() {
    Navigator.back();
  }

}
