import { NgModule } from '@angular/core';
import { PendingWithdrawalsPageModule } from './pages/overview/pending-withdrawals/pending-withdrawals.module';
import { AccountSummaryPageModule } from './pages/account-summary/account-summary.module';
import { MyTransactionMethodsPageModule } from './pages/my-transaction-methods/my-transaction-methods.module';
import { MyTransactionMethodsConfirmPageModule } from './pages/my-transaction-methods-confirm/my-transaction-methods-confirm.module';
import { WithdrawalReverseWithdrawalPageModule } from './../cashier/pages/withdrawal/withdrawal-reverse-withdrawal/withdrawal-reverse-withdrawal.module';
import { WithdrawalReverseConfirmPageModule } from './../cashier/pages/withdrawal/withdrawal-reverse-confirm/withdrawal-reverse-confirm.module';
import { SystemModule } from '../../system/system.module';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [

    ],
    imports: [
        WithdrawalReverseConfirmPageModule,
        WithdrawalReverseWithdrawalPageModule,
        AccountSummaryPageModule,
        MyTransactionMethodsPageModule,
        MyTransactionMethodsConfirmPageModule,
        PendingWithdrawalsPageModule,
        SystemModule,
        CommonModule,
        IonicModule
    ],
    entryComponents: [
    ],
    providers: [],
    exports: [

    ]
})
export class CashierModule { }
