import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavController, Platform, PopoverController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { TouchUserProvider } from 'src/app/modules/authentication/providers/touch-user-provider';
import { GameHubController } from 'src/app/modules/gameHub/game-hub-controller';
import { MainProvider } from 'src/app/modules/lobby/providers/main.provider';
import { UserMenuPage } from 'src/app/modules/popover/pages/user-menu/user-menu';
import { CommonService } from 'src/app/service/common.service';
import { MyEvent } from 'src/app/service/myevent.services';
import { Navigator } from 'src/app/system/components/menu/navigator';
import { BalanceModel } from 'src/app/system/models/balance-model';
import { BalanceProvider } from 'src/app/system/providers/balance-provider';
import { Config } from 'src/app/system/providers/configuration';
import { UserSessionProvider } from 'src/app/system/providers/user-session';
import { WebservicesProvider } from 'src/app/system/providers/webservices';
import { debounceTime, distinctUntilChanged, filter, map, delay } from 'rxjs/operators';
import { BalancesComponent } from '../balances/balances.component';
import { CustomerModel } from 'src/app/modules/account/models/customer-model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit, AfterViewInit {

  @Output() changeSize = new EventEmitter<any>();

  mainLogo: string = '';
  size: any = 'large';
  headerBtn = '';

  accountBalances: BalanceModel;
  balances: any[] = [];
  currency: any;
  currentBalance: any;

  allGames: any[] = [];
  allProviders: any[] = [];

  searchTxt: any;
  searching = false;
  searchSub: BehaviorSubject<string> = new BehaviorSubject<string>('');

  placeholderImg: any;
  imagesPath = this.mainProvider.galleryImagePath;

  marginMiddlePart: any;
  marginEndPart: any;
  headerRec: any;

  theme: any = 'blue';

  inGame: boolean = false;

  syncBalanceInterval: any;
  stopSyncBalanceInterval: any;

  siteDefaultCurrencyFromConfig: any = 'USD';

  appConfigInfo: any;

  customer: CustomerModel;

  refreshInverval: any;
  isMobileWeb = false;

  menuOptions: Array<any> = [];

  constructor(
    public comService: CommonService,
    private config: Config,
    private events: MyEvent,
    public userSession: UserSessionProvider,
    private platform: Platform,
    private storage: Storage,
    public touchUserProvider: TouchUserProvider,
    private mainProvider: MainProvider,
    private popoverCtrl: PopoverController,
    private balanceProvider: BalanceProvider,
    public gameHubController: GameHubController,
    private cdr: ChangeDetectorRef,
    private webservice: WebservicesProvider,
    private navCtrl: NavController,
    private route: Router
  ) {
    if (this.platform.is('mobile') || this.platform.is('mobileweb')) {
      this.isMobileWeb = true;
    } else {
      this.isMobileWeb = false;
    }
  }

  ngOnInit() {
    this.appConfigInfo = this.config.getAppConfigInfo();
    this.mainLogo = this.appConfigInfo?.main_logo || 'assets/imgs/logo.png';
    if (this.isMobileWeb) {
      this.mainLogo = this.appConfigInfo?.main_mobile_logo ? this.appConfigInfo?.main_mobile_logo : (this.appConfigInfo?.main_logo || 'assets/imgs/logo.png');
    }

    this.placeholderImg = this.appConfigInfo.placeholder_img;
    this.theme = this.appConfigInfo.theme;
    this.siteDefaultCurrencyFromConfig = this.config.getConfig()['DEFAULT_CURRENCY'];

    this.events.getObservable().subscribe(data => {
      if (data.event == 'event:tabs:load') {
        this.headerBtn = data.tab.toLowerCase();
      } else if (data.event == 'dashboard:loaded') {
        this.setHeaderWidth();
      } else if (data.event =='event:tabs:bonusdetail') {
        this.headerBtn = null;
      } else if (data.event == "event:balanceState") {
        if (data.data.code == "IN_GAME") this.inGame = true;
        else this.inGame = false;
      } else if (data.event == 'async:balance:interval') {
        if (this.syncBalanceInterval) {
          clearInterval(this.syncBalanceInterval);
          this.syncBalanceInterval = null;
        }

        if (this.stopSyncBalanceInterval) {
          clearTimeout(this.stopSyncBalanceInterval);
          this.stopSyncBalanceInterval = null;
        }

        this.syncBalanceChanges();
      }
    });

    this.comService.allGamesSubject.subscribe(games => {
      this.allGames = games;
    });

    this.comService.allProvidersSub.subscribe(provs => {
      this.allProviders = provs;
    });

    this.searchSub.pipe(map((searchText: any) => searchText), debounceTime(1000), distinctUntilChanged()).subscribe((text: string) => {
      this.searchTxt = text;
      if (this.searchTxt) this.searching = true;
      else this.searching = false;
    });

    this.userSession.getCustomer().subscribe(customer => {
      if (customer) {
        if (customer.currentLoginTime) this.mainProvider.setCurrentLoginTime(customer.currentLoginTime);
        this.customer = customer;
        this.initBalance();

        clearInterval(this.refreshInverval);
        this.refreshInverval = null;
        this.refreshBalance();
      } else {
        this.accountBalances = null;
        this.balances = [];
        this.currentBalance = null;
        this.currency = null;

        clearInterval(this.syncBalanceInterval);
        this.syncBalanceInterval = null;

        clearTimeout(this.stopSyncBalanceInterval);
        this.stopSyncBalanceInterval = null;

        clearInterval(this.refreshInverval);
        this.refreshInverval = null;
      }
    });
  }

  ngAfterViewInit() {
    this.appConfigInfo = this.config.getAppConfigInfo();
    this.mainLogo = this.appConfigInfo?.main_logo || 'assets/imgs/logo.png';
    if (this.isMobileWeb) {
      this.mainLogo = this.appConfigInfo?.main_mobile_logo ? this.appConfigInfo?.main_mobile_logo : (this.appConfigInfo?.main_logo || 'assets/imgs/logo.png');
    }

    this.placeholderImg = this.appConfigInfo.placeholder_img;
    this.theme = this.appConfigInfo.theme;
    this.siteDefaultCurrencyFromConfig = this.config.getConfig()['DEFAULT_CURRENCY'];

    this.setHeaderWidth();
    fromEvent(window, 'resize').pipe(debounceTime(100)).subscribe((event) => {
      this.setHeaderWidth();
    });

    // get user menu here
    this.mainProvider.getSidemenuData('desktop').then(
      data => {
        this.menuOptions = data.filter(el => !el.isDisabled);
      }
    );
  }

  initBalance() {
    this.balances = [];

    this.balanceProvider
      .getBalance().pipe(delay(350))
      .subscribe((response: BalanceModel) => {
        console.log('balance == ', response);
        this.accountBalances = response;
        if (response) {
          this.balances = [];
          Object.keys(response).forEach(key => {
            if (key != 'DEFAULT_CURRENCY' && key != 'FIFI') {
              let bb = {
                currency: key,
                balance: response.getDisplayBalanceByCurrency(key),
                amountInUSD: response.amountInUSD(key)
              }
              this.balances.push(bb);
            }
          });

          this.balances.sort((a,b) => {
            if (a.amountInUSD > b.amountInUSD) return -1;
            else if (a.amountInUSD < b.amountInUSD) return 1;
            else return 0;
          });

          if (!this.currency && this.balances.length) {
            var bb = this.balances.find(el => el.currency == this.comService.defaulCurrency);
            if (bb) {
              this.currency = this.comService.defaulCurrency;
            }
            else {
              var pp = this.balances.find(el => el.currency == this.config.getConfig().DEFAULT_CURRENCY);
              if (pp) this.currency = this.config.getConfig().DEFAULT_CURRENCY;
              else this.currency = this.balances[0].currency;
            }

            this.comService.setDefaultCurrency(this.currency);
          }

          this.currentBalance = this.balances.find(bl => bl.currency == this.currency);
        }
      });
  }

  syncBalanceChanges() {
    this.syncBalanceInterval = setInterval(() => {
      this.balanceProvider.updateBalance();
    }, 12 * 1000);

    this.stopSyncBalanceInterval = setTimeout(() => {
      clearInterval(this.syncBalanceInterval);
      this.syncBalanceInterval = null;

      clearTimeout(this.stopSyncBalanceInterval);
      this.stopSyncBalanceInterval = null;
    }, 15 * 60 * 1000);
  }


  refreshBalance() {
    this.refreshInverval = setInterval(() => {
      this.balanceProvider.updateBalance();
    }, 2.5 * 60 * 1000)
  }


  setHeaderWidth() {
    setTimeout(() => {
      let menu_logo = document.getElementById('menu_logo');
      let menuLogoWidth: any = menu_logo ? menu_logo.offsetWidth : 0;
      console.log('menuLogoWidth : ', menuLogoWidth);

      let mainContent = document.getElementById('parent_div');
      var mainContentWidth = mainContent ? mainContent.offsetWidth : 0;

      var ptd_b = document.getElementById('ptd_b');
      if (ptd_b && ptd_b.offsetWidth > 0) {
        mainContentWidth = ptd_b.offsetWidth;
      }

      var ptd_bt = document.getElementById('ptd_bt');
      if (ptd_bt && ptd_bt.offsetWidth > 0) {
        mainContentWidth = ptd_bt.offsetWidth;
      }

      var ptd_c = document.getElementById('ptd_c');
      if (ptd_c && ptd_c.offsetWidth > 0) {
        mainContentWidth = ptd_c.offsetWidth;
      }

      var ptd_p = document.getElementById('ptd_p');
      if (ptd_p && ptd_p.offsetWidth > 0) {
        mainContentWidth = ptd_p.offsetWidth;
      }

      var ptd_t = document.getElementById('ptd_t');
      if (ptd_t && ptd_t.offsetWidth > 0) {
        mainContentWidth = ptd_t.offsetWidth;
      }

      var ptd_gh = document.getElementById('ptd_gh');
      if (ptd_gh && ptd_gh.offsetWidth > 0) {
        mainContentWidth = ptd_gh.offsetWidth;
      }

      if (!mainContentWidth) {
        mainContentWidth = Number(sessionStorage.getItem('content_width'));
      }

      if (!mainContentWidth) {
        if (window.innerWidth > 992) {
          mainContentWidth = 800;
        }
        if (window.innerWidth > 1090) {
          mainContentWidth = 900;
        }
        if (window.innerWidth > 1200) {
          mainContentWidth = 1000;
        }
        if (window.innerWidth > 1339) {
          mainContentWidth = 1020;
        }
        if (window.innerWidth > 1429) {
          mainContentWidth = 1020;
        }
      }

      var leftFar = (window.innerWidth * (this.size == 'large' ? 17 : 6) / 100) + (window.innerWidth - window.innerWidth * (this.size == 'large' ? 17 : 6) / 100 - mainContentWidth) / 2;
      var logoItemWidth = menuLogoWidth + 16 + 24 + 16 + 16 + 4; // 24 is menu icon width, 16 is menu icon's right margin, 16s item inline paddings, 4 is left margin

      let marginVal = leftFar - logoItemWidth - 33; //  23 is scrollbar width
      marginVal = marginVal > -10 ? marginVal : -10;
      this.marginMiddlePart = {
        'margin-left': `${marginVal}px`,
      };

      var endMargin = (window.innerWidth - window.innerWidth * (this.size == 'large' ? 17 : 7) / 100 - mainContentWidth) / 2 + (window.innerWidth < 1200 ? 9 : 23) + 1;
      if (window.innerWidth < 992) {
        endMargin = 7;
      }
      this.marginEndPart = {
        "margin-right" : `${endMargin}px`
      }
      this.cdr.detectChanges();
    }, 50);
  }

  changeSizeFunc() {
    if (this.size == 'large') {
      this.size = 'small';
    } else if (this.size == 'small') {
      this.size = 'large';
    }
    this.changeSize.emit(this.size);
    this.events.publishSomeData({event: 'sizebar:size:changed', size: this.size});
    this.setHeaderWidth();
  }

  clickHeader(type) {
    this.headerBtn = type;
    this.events.publishSomeData({event: 'event:tabs:load', tab: type});
    this.events.publishSomeData({event: 'event:main:button'});
  }

  openSportsPage() {
    this.events.publishSomeData({event: 'event:load:sports'});
  }

  onLogin() {
    Navigator.navigateTo("LoginPage", { forceModal: true, cssClass: 'login_modal' });
    this.mainProvider.clearWaitingGameData();
  }

  onSignup() {
    var code = null;
    if (localStorage.getItem('b_referral_code')) {
      var temp = localStorage.getItem('b_referral_code');
      code = temp.split(':')[0];
    }
    if (this.comService.isMobileWeb) {
      Navigator.navigateTo("LoginPage", { forceModal: true, cssClass: 'login_modal' }, { screenType: 'signup', signupCode: code });
    } else {
      Navigator.navigateTo("SignupStepOnePage", { forceModal: true, cssClass: 'signup_modal', disableBackdrop: true }, { signupCode: code });
    }
  }

  openUserMenu($event) {
    this.popoverCtrl.create({
      component: UserMenuPage,
      componentProps: {
        menus: this.menuOptions
      },
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: 'user-menu' + (this.size == 'small' ? ' small-user-menu' : ''),
      event: $event
    }).then(pop => pop.present());
  }

  async onBalanceClick($event) {
    if (this.inGame || this.route.url.includes('sports')) return;

    var pop = await this.popoverCtrl.create({
      component: BalancesComponent,
      componentProps: {
        balances: this.balances
      },
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: 'balance-menu' + (this.size == 'small' ? ' small-balance-menu' : ''),
      event: $event
    });
    pop.onDidDismiss().then(data => {
      if (data.role == 'changed') {
        this.currentBalance = data.data.balance;

        this.currency = this.currentBalance.currency;
        this.comService.setDefaultCurrency(this.currency);

        this.cdr.detectChanges();
      }
    });
    pop.present();
  }

  showBonus() {
    this.headerBtn = 'bonuses';
    this.events.publishSomeData({event: 'event:main:button'});
    this.events.publishSomeData({event: 'event:tabs:load', tab: 'bonuses'});
  }



  onSearch($event) {
    var $searchTxt = $event.target.value;
    this.searchSub.next($searchTxt);
  }

  onFocus($event) {
    if (this.searchTxt && this.searchTxt.trim() != '') {
      this.searching = true;
    } else {
      this.searching = false;
    }
  }

  launchCasinoGame(gameData) {
    console.log('gameData: ', gameData);
    this.searching = false;
    if (!this.userSession.isAuthenticated()) {
      this.mainProvider.setWaitingGameData(gameData);
      this.onLogin();
    } else {
      this.events.publishSomeData({event: "event:gameHub:closeGame"});
      setTimeout(() => {
        this.gameHubController.launchCasinoGame(gameData);
      }, 500);
    }
  }

  async showGamesByProvider(provider) {
    this.navCtrl.navigateForward(`${this.comService.currentTemplate}/games?provider_id=${provider.id}`);
    this.searching = false;
  }

  loadImg(game) {
    game.loaded = true;
    this.cdr.detectChanges();
  }

  errorImg(game) {
    game.loaded = 'error';
    this.cdr.detectChanges();
  }

  showLeaderboard() {
    this.headerBtn = null;
    this.events.publishSomeData({event: 'event:tabs:load', tab: 'leaderboard'});
    this.events.publishSomeData({event: 'event:main:button'});
  }

  goDashboard() {
    this.events.publishSomeData({event: 'event:tabs:load', tab: 'dashboard'});
    // this.navCtrl.navigateRoot(`${this.comService.currentTemplate}/dashboard`);
    this.events.publishSomeData({event: 'event:main:button'});
    setTimeout(() => {
      this.events.publishSomeData({event: 'dashboard:scrolltop'});
    }, 350);
  }

}
