import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Config } from './../system/providers/configuration';
import { FormControl } from '@angular/forms';
import Cookies from 'js-cookie';
import { Platform } from '@ionic/angular';
import { MyEvent } from '../service/myevent.services';

declare var window: any;

@Injectable({
  providedIn: 'root',
})
export class Utils {
  public memoryStorage = new Map();

  constructor(
    public config: Config,
    public platform: Platform,
    private storage: Storage,
    private events: MyEvent
  ) {}

  formatLobbyDate() {}

  escapeSpecialCharacters(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  getTimeOnline(timenow, timeloggedin) {
    var last_login = new Date(timeloggedin);
    var now = new Date(timenow * 1000);
    var diff = now.getTime() - last_login.getTime();
    var mil = Math.floor(diff);
    var seconds = (mil / 1000) | 0;
    mil -= seconds * 1000;
    var minutes = (seconds / 60) | 0;
    seconds -= minutes * 60;
    var hours = (minutes / 60) | 0;
    minutes -= hours * 60;
    var days = (hours / 24) | 0;
    hours -= days * 24;
    var time_on_site = '';
    if (hours > 0) {
      time_on_site = time_on_site + hours + 'h ';
    }
    time_on_site = time_on_site + minutes + 'm';
    return time_on_site;
  }

  formatssnValue(ssnValue: string): string {
    let ssnValueCopy = ssnValue.slice(0);
    let ssnValueArray = ssnValue.split('');
    ssnValueArray.splice(1, 0, '-');
    return 'XXX-XX' + ssnValueArray.join('');
  }

  formatDateWithTextMonth(date: string, delimiter: string = ' '): string {
    var tempDate1: any[] = date.split(delimiter);
    var tempDate2: any[] = tempDate1[0].split('-');
    var formattedDate: string =
      this.getMonthString(tempDate2[1]) +
      ' ' +
      tempDate2[2] +
      ', ' +
      tempDate2[0];
    return formattedDate;
  }

  formatDateandTime(datestr) {
    //date string example "2020-07-23T09:40:37.000-06:00"
    if (datestr && datestr.length >= 19) {
      let date = datestr.slice(0, 10).replace(/-/g, '/');
      let time = datestr.slice(11, 16);
      //am or pm
      let hour = Number(datestr.slice(11, 13));
      if (!isNaN(hour)) {
        let ampm = hour >= 12 ? 'PM' : 'AM';
        return date + ', ' + time + ' ' + ampm;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  toAmericanDateStandard(datestr) {
    if (datestr && datestr.length >= 19) {
      let year = datestr.slice(8, 10);
      let month = datestr.slice(0, 2);
      let day = datestr.slice(3, 5);
      let time = datestr.slice(11, 16);
      //am or pm
      let hour = Number(datestr.slice(11, 13));
      if (!isNaN(hour)) {
        let ampm = hour >= 12 ? 'PM' : 'AM';
        if (hour > 12) {
          hour -= 12;
        }
        if (hour === 0) {
          hour = 12;
        }
        return month + '/' + day + '/' + year + ',' + time + ' ' + ampm;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  toAmericanDateStandardfromYYYY(datestr) {
    if (datestr && datestr.length >= 19) {
      let year = datestr.slice(2, 4);
      let month = datestr.slice(5, 7);
      let day = datestr.slice(8, 10);
      let time = datestr.slice(11, 16);
      let hour = Number(datestr.slice(11, 13));
      if (!isNaN(hour)) {
        let ampm = hour >= 12 ? 'PM' : 'AM';
        if (hour > 12) {
          hour -= 12;
        }
        if (hour === 0) {
          hour = 12;
        }
        time = hour + datestr.slice(13, 16);
        return (
          parseInt(month) +
          '/' +
          parseInt(day) +
          '/' +
          year +
          ',' +
          ' ' +
          time +
          ' ' +
          ampm
        );
      } else {
        return '';
      }
    } else if (datestr && datestr.length >= 10) {
      let year = datestr.slice(2, 4);
      let month = datestr.slice(5, 7);
      let day = datestr.slice(8, 10);
      return parseInt(month) + '/' + parseInt(day) + '/' + year;
    } else {
      return '';
    }
  }

  //Use the Server Time for Header with American Date format MM/DD/YYYY, 13:25 EDT(Timezone)
  toAmericanDateStandardWithServerTime(datestr) {
    if (datestr && datestr.length >= 19) {
      let year = datestr.slice(6, 10);
      let month = datestr.slice(0, 2);
      let day = datestr.slice(3, 5);
      let time = datestr.slice(11);
      if (time) {
        return month + '/' + day + '/' + year + ', ' + time;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  getMonthString(month: string): string {
    switch (month) {
      case '01':
        return 'Jan';
      case '02':
        return 'Feb';
      case '03':
        return 'Mar';
      case '04':
        return 'Apr';
      case '05':
        return 'May';
      case '06':
        return 'Jun';
      case '07':
        return 'Jul';
      case '08':
        return 'Aug';
      case '09':
        return 'Sep';
      case '10':
        return 'Oct';
      case '11':
        return 'Nov';
      case '12':
        return 'Dec';
    }

    return '';
  }

  formatDate_YYYY_MM_DD(date: Date) {
    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!
    let yyyy = date.getFullYear();

    let str = yyyy.toString() + '-';

    if (mm < 10) {
      str += '0' + mm.toString() + '-';
    } else {
      str += mm.toString() + '-';
    }

    if (dd < 10) {
      str += '0' + dd.toString();
    } else {
      str += dd.toString();
    }

    //returns date as YYYY-MM-DD
    return str;
  }

  formatDate_MM_DD_YYYY(date: Date) {
    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!
    let yyyy = date.getFullYear();

    let str = '';

    if (mm < 10) {
      str += '0' + mm.toString() + '-';
    } else {
      str += mm.toString() + '-';
    }

    if (dd < 10) {
      str += '0' + dd.toString();
    } else {
      str += dd.toString();
    }

    str += '-' + yyyy.toString();

    //returns date as MM-DD-YYYY
    return str;
  }

  formatDate_from_MM_DD_YYYY_to_YYYY_MM_DD(datestr) {
    if (datestr && datestr.length >= 10) {
      let month = datestr.slice(0, 2);
      let day = datestr.slice(3, 5);
      let year = datestr.slice(6, 10);
      return year + '-' + month + '-' + day;
    } else {
      return '';
    }
  }

  reverseDate_YYYY_MM_DD(date) {
    let yyyy = date.slice(6, 10);
    let mm = date.slice(0, 2);
    let dd = date.slice(3, 5);

    let str = yyyy + '-' + mm + '-' + dd;

    return str;
  }

  getCDNAsset(path) {
    if (undefined !== path) {
      if (undefined !== typeof this.config.getConfig().CDN_BASEURL) {
        return (
          this.config.getConfig().CDN_BASEURL +
          '/assets/' +
          `${path}?v=${this.cache_buster()}`
        );
      } else {
        return '';
      }
    }
  }

  getCDNConfigAsset(path) {
    if (undefined !== path) {
      if (undefined !== typeof this.config.getConfig().CDN_BASEURL) {
        return this.config.getConfig().CDN_BASEURL + '/assets/' + path;
      } else {
        return '';
      }
    }
  }

  // helper function to pull location hash tags into key/value pairs
  // return passed in 'key' hash value
  getHashParam(k) {
    if ('undefined' === typeof parent.location.hash) return null;
    var hash = parent.location.hash.substr(1);
    var value = hash
      .substr(hash.indexOf(k + '='))
      .split('&')[0]
      .split('=')[1];
    if (value) {
      return value;
    }
    return null;
  }

  getUrlParams(queryString = null) {
    // get query string from url (optional) or window
    if (queryString === null) {
      queryString = parent.window.location.search.slice(1);
    }

    // we'll store the parameters here
    var obj = {};

    // if query string exists
    if (queryString) {
      // split our query string into its component parts
      var arr = queryString.split('&');

      for (var i = 0; i < arr.length; i++) {
        // separate the keys and the values
        var a = arr[i].split('=');

        // in case params look like: list[]=thing1&list[]=thing2
        var paramNum = undefined;
        var paramName = a[0].replace(/\[\d*\]/, function (v) {
          paramNum = v.slice(1, -1);
          return '';
        });

        // set parameter value (use 'true' if empty)
        var paramValue = typeof a[1] === 'undefined' ? '' : a[1];

        // (optional) keep case consistent
        paramName = paramName.toLowerCase();
        ////paramValue = paramValue.toLowerCase();

        // if parameter name already exists
        if (obj[paramName]) {
          // convert value to array (if still string)
          if (typeof obj[paramName] === 'string') {
            obj[paramName] = [obj[paramName]];
          }
          // if no array index number specified...
          if (typeof paramNum === 'undefined') {
            // put the value on the end of the array
            obj[paramName].push(paramValue);
          }
          // if array index number specified...
          else {
            // put the value at that index number
            obj[paramName][paramNum] = paramValue;
          }
        }
        // if param name doesn't exist yet, set it
        else {
          obj[paramName] = paramValue;
        }
      }
    }

    return obj;
  }

  removeUrlParams() {
    var clean_uri =
      location.protocol + '//' + location.host + location.pathname;
    window.history.replaceState({}, document.title, clean_uri);
  }

  /**
   * look for cookie named 'affiliate-data'
   */
  getCookieParams(name) {
    return Cookies.get(name);
  }

  setCookieParam(name, value) {
    Cookies.set(name, value);
  }

  isDesktop(): boolean {
    return this.platform.is('desktop');
  }

  isMobileApp() {
    return this.platform.is('cordova');
  }

  isIosApp() {
    return this.platform.is('cordova') && this.platform.is('ios');
  }

  // sets the CampaignId to use it for deposit pages
  // CampaignId is used to get the bonus code and the image for the deposit-main page, using defaultCampaign provider
  setCampaignId(code) {
    this.memoryStorage.set('campaignId', code);
  }

  getCampaignId() {
    return this.memoryStorage.get('campaignId');
  }

  removeCampaignId() {
    this.memoryStorage.delete('campaignId');
  }

  isQualified(customrQualification) {
    let data = customrQualification.payload;
    let pageToNavigate;

    let response: any = {
      result: true,
      pageToNavigate: pageToNavigate,
    };

    if (!!data) {
      switch (data['policy-name']) {
        case 'emailvalidation':
          pageToNavigate = 'InvalidEmailPage';
          break;
        case 'geolocation':
          pageToNavigate = 'InvalidGeoPage';
          break;
        case 'geocomply':
          pageToNavigate = 'InvalidGeoComplyPage';
          break;
        case 'kyc':
          pageToNavigate = 'InvalidKycPage';
          break;
        case 'spincriteria':
          pageToNavigate = 'InvalidSpinPage';
          break;
      }
      response = {
        result: false,
        pageToNavigate: pageToNavigate,
      };
    }
    return response;
  }

  getDomain() {
    var hostname = document.domain;

    if (hostname != null) {
      var parts = hostname.split('.').reverse();

      if (parts) {
        var domain = parts[0];
        for (let partscount = 1; partscount < parts.length - 1; partscount++) {
          domain = parts[partscount] + '.' + domain;
        }
      }
    }

    return domain;
  }

  /**
   * return the value of the property from the given object regardless of case of the property name
   */
  getInsensitiveProp(o: any, propName: string) {
    propName = ('' + propName).toLowerCase();
    for (var k in o) {
      if (o.hasOwnProperty(k) && propName == ('' + k).toLowerCase()) {
        return o[k];
      }
    }
    return null;
  }

  // clones the array for immutability
  clone(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.clone(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = this.clone(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  /*
   * param Title
   *
   */
  /**
     * Updates the url with a new pathname ex: whitelabelcompany.com/pathname
     *
     * @param {string}   pathname      Ex: casino, promotions
     * @param {string}   [title=""]    Changing the document title, empty by default
     * @param {Object} objectVar     Description.

     */
  pushHistoryState(pathname, title = '') {
    //used for both mobile and web
    // if(!this.isMobileApp()) {
    // let newurl = window.location.protocol + "//" + window.location.host + '/' + pathname.toLowerCase() + window.location.hash;
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      '/' +
      pathname.toLowerCase();
    history.pushState({ path: newurl }, title, newurl);
    this.events.publishSomeData({
      event: 'event:history:push',
      data: { url: newurl },
    });
    // }
  }

  loadCssFile(filename, id) {
    let fileref = document.createElement('link');
    fileref.setAttribute('rel', 'stylesheet');
    fileref.setAttribute('href', filename);
    fileref.setAttribute('id', id);

    if (typeof fileref != 'undefined')
      document.getElementsByTagName('head')[0].appendChild(fileref);
  }

  cache_buster() {
    return 'undefined' !== typeof window.CACHE_BUSTER
      ? window.CACHE_BUSTER
      : null;
  }

  loadCDNAsset(path) {
    let lobbyUrl = this.config.getConfig().LOBBY_URL;
    let fromCDN = `${lobbyUrl}/${path}?v=${this.cache_buster()}`;
    return this.cache_buster() != null || this.platform.is('cordova')
      ? fromCDN
      : path;
  }
  //removing the "/" in the url "/url/"=> url
  cleanPathName(pageName) {
    if (pageName[0] == '/') {
      pageName = pageName.substring(1);
    }
    if (pageName[pageName.length - 1] == '/') {
      pageName = pageName.substring(0, pageName.length - 1);
    }
    return pageName;
  }

  isAbsoluteURL(url) {
    let regexURL = /^https?:\/\//i;
    if (regexURL.test(url)) {
      return true;
    } else {
      return false;
    }
  }

  deleteAllCookies() {
    var cookies = document.cookie.split(';');

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf('=');
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

  getBrandingPageUrl(): string {
    let appConfigInfo = this.config.getAppConfigInfo();
    return 'brandingHotelUrl' in appConfigInfo
      ? appConfigInfo.brandingHotelUrl + '/casino'
      : 'casino';
  }

  isPartOfExternalLinks(pageName) {
    let links = this.config.getExternalLinks();
    let slug = this.cleanPathName(pageName);
    let link = links.filter((link) => this.cleanPathName(link.url) == slug);
    return !!link.length;
  }
}
