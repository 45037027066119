import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs'; // For rxjs 6

@Injectable({
  providedIn: 'root',
})
export class MyEvent {

  unsubscribeEvents: any[] = [];

  private selectedLanguage = new Subject<any>();
  private fooSubject = new Subject<any>();

  constructor() {}

  public getLanguageObservable(): Observable<any> {
    return this.selectedLanguage.asObservable();
  }

  public setLanguageData(data) {
    this.selectedLanguage.next(data);
  }

  public publishSomeData(data: any) {
    if (!this.unsubscribeEvents.includes(data.event)) {
      this.fooSubject.next(data);
    }
  }

  public getObservable(): Subject<any> {
    return this.fooSubject;
  }

  unsubscribe(event = '') {
    if (!this.unsubscribeEvents.includes(event)) {
      this.unsubscribeEvents.push(event);
    }
  }
}
