export const locale = {
  lang: 'id-ID',
  data: {
    LEFT_SIDE: {
      EARN_CATEGORY_TITLE: 'Dapatkan',
      REFERRAL: 'Refer dan Dapatkan',
      VIP: 'Klub VIP',
      LANGS: {
        // https://www.omniglot.com/language/names.htm
        ENGLISH: 'English',
        CHINESE: '简体中文',
        PORTUGUES: 'Português',
        THAILIAND: 'ภาษาไทย',
        VIETNAME: 'Tiếng Việt',
        SPANISH: 'Español',
        INDONESIAN: 'Bahasa Indonesia',
        JAPANESE: '日本語'
      },
      INTEREST: "interest",
      REFER_EARN: "Refer & Earn",
      VIP_CLUB: '<span style="color: #ffb636;">VIP</span> Klub'
    },
    HEADER: {
      SPORT_BUTTON_TEXT: "Olahraga",
      CASINO_BUTTON_TEXT: "Kasino",
      PROMOTIONS: 'Promosi',
      SEARCH: "Search",
      SEARCH_PLACEHOLDER: "Cari Permainan atau Pemasok",
      SEARCH_PART_GAMES: "Permainan",
      SEARCH_PART_PROVIDERS: "Pemasok",
      SEARCH_RESULT: "Search Results",
      SEARCH_NO_RESULT: "Tidak ada hasil pencarian",
      SEARCH_TRY_GAMES: "Recommended",
      RECOMMENDED_GAMES: "Recommended for you",
      LOGIN_BUTTON_TEXT: "Masuk",
      REGISTER_BUTTON_TEXT: "Daftar",
      BALANCE_INGAME: "DALAM PERMAINAN",
      DEPOSIT_BUTTON_TEXT: "Dompet",
      Settings: "Settings",
      ALL_GAMES: "All Games",
      SELECT_GAME: "Select Game"
    },
    BALANCES: {
      FIAT: 'Fiat',
      CRYPTO: 'Kripto',
      DISPLAY_FIAT: 'Tampilan dalam Fiat'
    },
    MOBILE_FOOTER: {
      BETSPLUS: {
        SPORT_TAB_TEXT: 'Olahraga',
        CASINO_TAB_TEXT: 'Kasino',
        PROMOTIONS_TAB_TEXT: 'Promosi',
        MENU_TAB_TEXT: 'Menu'
      }
    },
    DASHBOARD: {
      CATEGORY_LOBBY_NAME: "Lobi",
      LAST_PLAYED: "Terakhir Dimainkan",
      VIEWALL_BUTTON_TEXT: "Semua",
      VIEWALL_BUTTON_TEXT1: "View All",
      ALL_GAMES_COUNT: "Semua {{count}}",
      NO_GAMES: "Tidak ada data permainan",
      GAME_PROVIDERS_TITLE: "Penyedia Permainan",
      RECORDS: {
        TITLE: "Rekor",
        TABS: {
          MY_BETS_TITLE: "Taruhan Saya",
          ALL_BETS_TITLE: "Semua Taruhan",
          RARE_WINS_TITLE: 'Kemenangan Besar',
        },
        DATA: {
          BETS_TABLE: {
            COLUMN1: 'Permainan',
            COLUMN2: 'Nama Pengguna',
            COLUMN3: 'Waktu',
            COLUMN4: 'Bertaruh',
            COLUMN5: 'Pengganda',
            COLUMN6: 'Pembayaran'
          },
          WINS_TABLE: {
            COLUMN1: 'Permainan',
            COLUMN2: 'Nama Pengguna',
            COLUMN3: 'Bertaruh',
            COLUMN4: 'Pengganda',
            COLUMN5: 'Pembayaran'
          },
          WINS_TABLE_BETSPLUS: {
            COLUMN1: 'Peringkat',
            COLUMN2: 'Nama Pengguna',
            COLUMN3: 'Bertaruh',
            COLUMN4: 'Hadiah'
          },
          NO_MY_BETS_TEXT: "Tidak Ada Catatan",
          NO_ALL_BETS_TEXT: "Belum ada taruhan",
          NO_RARE_WINS_TEXT: "Tidak ada kemenangan besar"
        }
      }
    },
    AUTH: {
      TOPBAR: {
        LOGIN_BUTTON_TEXT: "Masuk",
        REGISTER_BUTTON_TEXT: "Daftar"
      },
      LOGIN: {
        TITLE: "Selamat Kembali!",
        EMAIL: 'Email',
        EMAIL_EMPTY_ERROR: "Silakan masukkan email",
        EMAIL_INVALID_ERROR: 'Silakan masukkan email yang valid',
        PHONE_NUMBER: "Nomor Telepon",
        PHONE_EMPTY_ERROR: 'Silakan masukkan nomor telepon Anda',
        PHONE_INVALID_ERROR: 'Silakan masukkan nomor telepon yang valid',
        PASSWORD: 'Kata Sandi',
        PASSWORD_EMPTY_ERROR: 'Kata sandi diperlukan',
        PASSWORD_LENGTH_ERROR: 'Kata sandi harus lebih dari 6 karakter',
        SUBMIT_BUTTON_TEXT: 'Masuk',
        DONT_HAVE_ACCOUNT: "Belum punya akun?",
        CREATE_NEW_ONE: "Daftar",
        FORGOT_PASSWORD_BUTTON_TEXT: 'Lupa kata sandi?',
        ERRORS: {
          NOT_EXIST: "Email tidak terdaftar",
          WRONG_PASSWORD: 'Kata sandi salah',
          TOO_MANY_ATEMP: 'Akun Anda dinonaktifkan setelah terlalu banyak percobaan masuk yang gagal, silakan hubungi dukungan.',
          TIME_LIMIT: 'Waktu telah habis.',
          NOT_ACTIVE: "Akun Anda tidak dalam status 'Aktif'. Silakan hubungi dukungan.",
          PERMANENT_EXCLUDE: 'Akun Anda telah dikecualikan secara permanen dari situs ini.',
          SUSPENDED: 'Anda telah menonaktifkan diri dari bermain.',
          TEMPORARY_SUSPENDED: 'Akun Anda telah ditangguhkan sementara. Jika ini tidak diselesaikan dalam 24 jam, silakan hubungi dukungan.',
          ERROR_OCCURED: 'Terjadi kesalahan saat masuk. Silakan coba lagi.',
          SUCCESS_LOGIN: 'Berhasil masuky',
          INACTIVITY: 'Sesi terakhir Anda berakhir karena tidak aktif'
        },
        USING_SOCIAL: 'Masuk menggunakan'
      },
      SIGNUP: {
        TITLE: 'Daftar di {{sitename}}',
        EMAIL: 'Email',
        EMAIL_EMPTY_ERROR: "Silakan masukkan alamat email",
        EMAIL_INVALID_ERROR: 'Silakan masukkan alamat email yang valid',
        EMAIL_ALREADY_USED_ERROR: 'Alamat email tersebut sudah digunakan. Jika Anda mencoba menyelesaikan registrasi, silakan masuk untuk melanjutkan dari tempat terakhir Anda.',
        PHONE_NUMBER: "Nomor Telepon",
        PHONE_EMPTY_ERROR: 'Silakan masukkan nomor telepon Anda',
        PHONE_INVALID_ERROR: 'Silakan masukkan nomor telepon yang valid',
        PHONE_ALREADY_USED_ERROR: 'Nomor telepon tersebut sudah digunakan. Jika Anda mencoba menyelesaikan registrasi, silakan masuk untuk melanjutkan dari tempat terakhir Anda.',
        VERIFICATION_CODE: 'Kode Verifikasi',
        VERIFY_BUTTON_TEXT: 'Verifikasi',
        GET_CODE_TXT: 'Dapatkan Kode',
        CODE_SENT: 'Kode Terkirim',
        RESEND: 'Kirim Ulang',
        PASSWORD: 'Kata Sandi',
        PASSWORD_EMPTY_ERROR: 'Kata sandi diperlukan',
        PASSWORD_LENGTH_ERROR: 'Kata sandi harus lebih dari 6 karakter',
        PASSOWRD_PATTERN_ERROR: 'Kata sandi harus mengandung setidaknya satu angka, satu huruf kecil, satu huruf besar, dan satu karakter khusus non-abjad: $ # ! @ - _',
        REFERRAL_CODE: 'Kode Referral',
        REFERAL_CODE_ERROR: 'Kode promo tidak valid',
        TERMS_DESC: 'Dengan mendaftar, Anda menyetujui',
        TERMS_BUTTON_TEXT: 'Syarat & Ketentuan',
        SUBMIT_BUTTON_TEXT: 'Mulai',
        ALREADY_HAVE_ACCOUNT: "Sudah punya akun?",
        SIGNIN_BUTTON_TEXT: 'Masuk',
        ERRORS: {
          ALREADY_EXIST: 'Email sudah ada',
          NOT_CREATED: 'Tidak dapat membuat akun, silakan hubungi dukungan pelanggan.'
        },
        VERIFY_CODE_REQUIRED: 'Kode verifikasi diperlukan',
        VERIFICATION_CODE_LENGTH_ERROR: "Kode verifikasi harus berisi 6 angka",
        VERIFICATION_WRONG_CODE: "Kode verifikasi salah",
        SENT_VERIFY_EMAIL: "Kami telah mengirimkan email untuk verifikasi",
        SENT_VERIFY_SMS: "Kami telah mengirimkan kode SMS untuk verifikasi",
        USING_SOCIAL: 'Daftar menggunakan',
        CAPTCHA: "CAPTCHA",
        CAPTCHA_REQUIRED: "CAPTCHA diperlukan",
        CAPTCHA_PATTERN_ERROR: "Silakan masukkan CAPTCHA yang valid",
        CAPTCHA_WRONG: "CAPTCHA tidak valid, silakan coba lagi"
      },
      FORGOT_PASSWORD: {
        TITLE: "Lupa kata sandi?",
        DESCRIPTION: 'Lupa kata sandi?',
        EMAIL: 'Email',
        EMAIL_EMPTY_ERROR: "Silakan masukkan alamat email",
        EMAIL_INVALID_ERROR: 'Silakan masukkan alamat email yang valid',
        PHONE_NUMBER: "Nomor Telepon",
        PHONE_EMPTY_ERROR: 'Silakan masukkan nomor telepon Anda',
        PHONE_INVALID_ERROR: 'Silakan masukkan nomor telepon yang valid',
        VERIFICATION_CODE: 'Kode Verifikasi',
        VERIFY_BUTTON_TEXT: 'Verifikasi',
        SUBMIT_BUTTON_TEXT: 'Kirim',
        SUCCESS_MSG: 'Tautan reset kata sandi berhasil dikirim',
        VERIFICATION_CODE_LENGTH_ERROR: "Kode verifikasi harus berisi 8 karakter",
        SUCCESS_RESET_MSG: 'Kata sandi Anda telah berhasil direset. Silakan masuk dengan kata sandi baru Anda lagi'
      },
      LOGOUT: {
        MULTI_DEVICE: "Anda telah keluar karena masuk dengan beberapa perangkat",
        INACTIVITY: 'Anda sekarang keluar karena tidak aktif',
        DEFAULT: 'Anda sekarang keluar'
      },
      OR: 'ATAU',
      GOOGLE_BUTTON_TEXT: "Google"
    },
    DEPOSIT: {
      CURRENCY_TITLE: 'Mata Uang Deposit',
      CHOOSE_PAYMENT_TITLE: 'Pilih Metode Pembayaran',
      AMOUNT: 'Jumlah',
      INSTANT: 'Instan',
      MIN: 'Min',
      MAX: 'Max',
      SUBMIT_BUTTON_TEXT: 'Setoran',
      NEED_HELP: 'Butuh bantuan?',
      CONTACT_SUPPORT: 'Hubungi dukungan Langsung kami',
      CRYPTO_DEPOSIT_TITLE: 'Setoran',
      CRYPTO_DEPOSIT_DESCRIPTION: 'Ini adalah alamat deposit pribadi Anda. Setiap deposit yang Anda lakukan akan muncul dalam saldo Anda setelah konfirmasi blockchain. Kami akan memberi tahu Anda melalui email begitu deposit tiba.',
      MINIMUM_DEPOSIT_AMOUNT: 'Jumlah deposit minimum adalah {{amount}} {{currency}}',
      SEND_MONEY_TO: 'Kirim sejumlah {{currency}} (ditambah biaya penambang) ke:',
      NO_PAYMENT_METHOD: 'Tidak ada metode pembayaran yang tersedia',
      ADDRESS_COPIED: "Salin dan Deposit"
    },
    WITHDRAW: {
      CURRENCY_TITLE: 'Mata Uang Penarikan',
      CHOOSE_PAYMENT_TITLE: 'Pilih Metode Pembayaran',
      INFO_TITLE: 'Informasi Penarikan',
      BANK_CODE_TITLE: 'Kode Penerbit',
      BANK_NAME_TITLE: 'Nama Penerima',
      ACCOUNT_TYPE: "Jenis Akun",
      BANK_ACCOUNT_TITLE: 'Nomor Rekening',
      AMOUNT: 'Jumlah',
      INSTANT: 'Instan',
      MIN: 'Minimum',
      MAX: 'Maksimum',
      SUBMIT_BUTTON_TEXT: 'Tarik',
      NEED_HELP: 'Butuh bantuan?',
      CONTACT_SUPPORT: 'Hubungi dukungan Langsung kami',
      EXIST_ACCOUNT_TITLE: 'Akun yang Ada',
      ADDRESS: 'Alamat',
      WITHDRAW_FEE_ESTIMATED: 'Biaya penarikan diperkirakan sebesar {{amount}} {{currency}}',
      MINIMUM_WITHDRAW_AMOUNT: 'Jumlah penarikan minimum adalah {{amount}} {{currency}}',
      WITHDRAW_DEDUCT_DESC: 'Penarikan akan dikenakan biaya penanganan sebesar 10%',
      COMPLETE_EMAIL_VERIFY: 'Untuk alasan keamanan, lengkapi verifikasi email sebelum penarikan',
      VERIFY_EMAIL_BUTTON: 'Verifikasi Alamat Email',
      COMPLETE_PHONE_VERIFY: 'Untuk alasan keamanan, lengkapi verifikasi telepon sebelum penarikan',
      VERIFY_PHONE_BUTTON: 'Verifikasi Nomor Telepon',
      COMPLETE_EMAIL_PHONE_VERIFY: 'Untuk alasan keamanan, lengkapi verifikasi email dan telepon sebelum penarikan',
      VERIFY_EMAIL_PHONE_BUTTON: 'Verifikasi Sekarang',
      COMPLETE_PROFILE: 'Untuk alasan keamanan, lengkapi info profil Anda sebelum penarikan',
      ACCOUNT_BUTTON: 'Akun',
      WITHDRAW_SUCCESS: 'Penarikan telah diajukan, harap perhatikan akun penarikan Anda, terima kasih',
      HOME_BUTTON: 'Beranda',
      RESTRICTED_BALANCE: 'Anda memiliki saldo terbatas sebesar {{currency}} {{amount}}.',
      RESTRICTED_BALANCE_VIEW: 'Lihat',
      NO_PAYMENT_METHOD: 'Tidak ada metode pembayaran yang tersedia',
      IFSC_TITLE: 'Nomor IFSC',
      INVALID_AMOUNT_ERROR: {
        EMPTY_AMOUNT: 'Silakan masukkan jumlah',
        LESS_ZERO: 'Jumlah tidak boleh kurang dari nol',
        LESS_MIN: 'Jumlah kurang dari nilai minimum',
        GREATER_MAX: 'Melebihi nilai maksimum',
        GREATER_BALANCE: 'Melebihi saldo',
        GREATER_AVAILABLE: 'Saldo tidak mencukupi'
      },
      ADDRESS_REQUIRED: "Alamat diperlukan",
      ADDRESS_PATTERM_ERROR: "Alamat salah"
    },
    GAME_GALLEY: {
      NO_GAME_DATA: 'Tidak ada game di daftar Anda. Silakan klik tombol beranda dan nikmati salah satu dari banyak permainan.',
      DISPLAY_COUNT_GAMES: 'Menampilkan {{showCount}} dari {{totalCount}} permainan'
    },
    GAME: {
      LOADING: 'Memuat',
      PLAY_BUTTON_WITHOUT_LOGIN: 'Masuk',
      PLAY_BUTTON_WITH_LOGIN: 'Bermain Nyata',
      DEMO_PLAY_BUTTON: "Demo",
      RTP: 'RTP',
      RECENT_BIGGEST_WIN: 'Kemenangan Terbesar Terbaru',
      MY_GAMES_TITLE: 'Permainan Saya'
    },
    ACTIVE_ACCOUNT: {
      TITLE: 'Verifikasi Email',
      EMAIL_VERIFY_DESCRIPTION: 'Silakan masuk ke email Anda, klik tautan dalam email untuk menyelesaikan verifikasi',
      GET_CODE_TEXT: 'Kirim Kode',
      RESEND_BUTTON_TEXT: 'Kirim Ulang Kode',
      SUBMIT_BUTTON_TEXT: 'Kirim',
      NOT_RECEIVE_TXT: "Tidak menerima kode?",
      CODE: 'Kode',
      CODE_INPUT_PLACEHOLDER: 'Isi kode di sini'
    },
    EMAIL_VERIFY: {
      DISPLAY_INFO: 'Sedang diverifikasi. Harap tunggu sebentar...',
      ERROR_TITLE: 'Maaf, ada masalah.',
      VERIFY_ERROR: 'Kode email tidak ditemukan',
      SUCCESS_TITLE: 'Berhasil!',
      VERIFY_SUCCESS: 'Email Anda berhasil diverifikasi'
    },
    BONUSES: {
      BONUSES_TITLE: 'Promosi',
      BONUS_TITLE: 'Bonus',
      NO_BONUSES_DATA: 'Tidak ada promosi yang tersedia saat ini',
      CHECK_LATER: 'Cek kembali nanti!',
      MORE_DETAIL_BUTTON_TEXT: 'Pelajari Lebih Lanjut',
      APPLY_BUTTON_TEXT: 'Ajukan Sekarang',
      TAB1: 'Kasino',
      TAB2: 'Olahraga',
      ENDS_AT: 'Berakhir pada :',
      EVENT_ENDED: 'Acara Berakhir'
    },
    EMPTY_PAGE: {
      NOT_FOUND: 'Oops.. Halaman tidak ditemukan.',
      REFRESH_OR_GOHOME: 'Harap segarkan halaman atau klik di bawah untuk kembali ke halaman utama',
      ACTION_BUTTON_TEXT: 'Beranda'
    },
    FOOTBALL_PAGE: {
      TITLE: 'Olahraga',
      DESCRIPTION: 'Buku olahraga segera datang. Cek kembali nanti!',
      ACTION_BUTTON_TEXT: 'Beranda'
    },
    LOGOUT_CONFIRM: {
      TITLE: 'Keluar',
      DESCRIPTION: "Jangan lupa untuk mengecek berbagai promosi kasino dan olahraga kami sebelum Anda pergi!",
      BUTTON_TEXT: 'Keluar'
    },
    ACCOUNT_INFO: {
      UID: 'UID',
      USERNAME_TITLE: 'Nama Pengguna',
      EMAIL_TITLE: 'Email',
      PHONE_TITLE: 'Nomor Telepon',
      PHONE_PLACEHOLDER: 'Tambahkan Nomor Telepon',
      PASSWORD_TITLE: 'Kata Sandi',
      EDIT_BUTTON_TEXT: 'Sunting',
      VERIFY_BUTTON_TEXT: 'Verifikasi',
      ADD_EMAIL: 'Tambahkan Email',
      COPY_BUTTON_TXT: 'Salin',
      SUMMARY_TEXT: ''
    },
    ADD_EMAIL: {
      TITLE: 'Tambahkan Email',
      DESCRIPTION: 'Tambahkan Email Anda untuk keamanan akun',
      EMAIL_PLACEHOLDER: 'Email',
      VERIFICATION_CODE: 'Kode Verifikasi',
      VERIFY_BUTTON_TEXT: 'Verifikasi',
      SUBMIT_BUTTON_TEXT: 'Simpan'
    },
    CHANGE_PASSWORD: {
      TITLE: 'Ubah Kata Sandi',
      CURRENT_PW_PLACEHOLDER: 'Kata Sandi Saat Ini',
      CURRENT_PW_REQUIRED: 'Silakan masukkan kata sandi saat ini Anda',
      CURRENT_PW_WRONG: 'Kata sandi saat ini yang dimasukkan salah',
      NEW_PW_PLACEHOLDER: 'Kata Sandi Baru',
      NEW_PW_REQUIRED: 'Silakan masukkan kata sandi baru',
      NEW_PW_LENGTH_ERROR: 'Kata sandi harus setidaknya 6 karakter',
      CONFIRM_PW_PLACEHOLDER: 'Ulangi Kata Sandi Baru',
      CONFIRM_PW_REQUIRED: 'Silakan konfirmasi kata sandi baru Anda',
      NEW_PW_NOT_MATCH: 'Kata sandi yang dimasukkan tidak cocok',
      NEW_PW_NOT_CURRENT: 'Kata sandi baru tidak boleh cocok dengan kata sandi saat ini Anda',
      SUBMIT_BUTTON_TEXT: 'Simpan',
      MESSAGES: {
        SUCCESS: 'Kata sandi Anda telah diubah',
        WRONG_CURRENT_PASSWORD: 'Anda telah salah memasukkan kata sandi saat ini Anda.',
        NEW_NOT_MATCH_CURRENT: 'Kata sandi baru Anda tidak boleh cocok dengan kata sandi saat ini Anda.'
      }
    },
    EDIT_USERNAME: {
      TITLE: 'Ubah Nama Pengguna',
      PLACEHOLDER_TEXT: 'Nama Pengguna',
      SUBMIT_BUTTON_TEXT: 'Simpan',
      MESSAGES: {
        USERNAME_REQUIRED: 'Silakan masukkan nama pengguna.',
        USERNAME_LENGTH: 'Nama pengguna harus lebih dari 3 karakter'
      }
    },
    RESET_PASSWORD: {
      TITLE: 'Atur Ulang Kata Sandi',
      DESCRIPTION: 'Atur Ulang Kata Sandi',
      PASSWORD_PLACEHOLDER: 'Kata Sandi',
      PW_LENGTH_ERROR: 'Kata sandi harus setidaknya 6 karakter.',
      PW_REQUIRED: 'Silakan masukkan kata sandi baru.',
      PW_PATTERN_ERROR: 'Kata sandi harus mencakup angka, huruf kapital, dan simbol.',
      CONFIRM_PW_PLACEHOLDER: 'Konfirmasi kata sandi',
      CONFIRM_PW_REQUIRED: 'Silakan konfirmasi kata sandi baru Anda.',
      CONFIRM_PW_MISMATCH: 'Kata sandi tidak cocok.',
      NEW_PW_NOT_CURRENT: 'Kata sandi baru Anda tidak boleh cocok dengan kata sandi saat ini Anda.',
      SUBMIT_BUTTON_TEXT: 'Mengatur ulang',
      MESSAGES: {
        TOKEN_INVALID: 'Token yang diberikan tidak valid',
        TOKEN_EXPIRED: 'Token yang diberikan sudah kedaluwarsa',
        SUCCESS: 'Kata sandi Anda telah diatur ulang. Silakan login menggunakan kata sandi baru',
        FAILD: 'Gagal mengatur ulang kata sandi Anda. Silakan coba lagi'
      }
    },
    UPDATE_PHONE: {
      TITLE: 'Edit Nomor Telepon',
      DESCRIPTION: 'Tambahkan nomor telepon Anda untuk keamanan akun',
      PLACEHOLDER: 'hanya angka',
      PHONE_REQUIRED: 'Silakan masukkan nomor telepon Anda',
      PHONE_INVALID: 'Silakan masukkan nomor telepon yang valid',
      VERIFY_CODE: 'Kode Verifikasi',
      VERIFY_BUTTON_TEXT: 'Verifikasi',
      SUBMIT_BUTTON_TEXT: 'Kirim',
      MESSAGES: {
        FAILD: 'Ada masalah saat mencoba memperbarui nomor telepon. Harap hubungi dukungan pelanggan.'
      }
    },
    ACTIVE_BONUSES: {
      TITLE: 'Bonus Aktif',
      NO_DATA: "Anda tidak memiliki bonus aktif saat ini.",
      SHOW_RESULT: 'Klik pada bonus untuk melihat detail lengkap tentangnya',
      BONUS_ACCOUNT: 'Akun Bonus',
      DESC1: "Terkadang Anda bisa mendapatkan 'Bonus' sebagai hadiah khusus untuk tindakan tertentu, seperti melakukan deposit.",
      DESC2: "Jumlah Terbatas Total (deposit Anda + jumlah bonus) dibatasi untuk taruhan hanya pada permainan. Begitu persyaratan taruhan terpenuhi, Jumlah Terbatas Total dilepaskan dan menjadi bagian dari saldo uang nyata Anda.",
      TABLE_HEADER: {
        COLUMN1: 'BONUS JUMLAH',
        COLUMN2: 'BONUS TERSISA',
        COLUMN3: 'REFERENSI',
        COLUMN4: 'TANGGAL MULAI',
        COLUMN5: 'TANGGAL KADALUARSA',
        COLUMN6: 'STATUS',
        COLUMN7: 'JUMLAH TERBATAS'
      }
    },
    PAST_BONUSES: {
      TITLE: 'Bonus Terdahulu',
      DESCRIPTION: 'Di bawah ini Anda dapat melihat bonus sebelumnya yang telah Anda terima. Silakan pilih rentang tanggal:',
      FROM_DATE_LABEL: 'Dari',
      INPUT_DATE_PLACEHOLDER: 'Pilih Tanggal',
      TO_DATE_LABEL: 'Hingga',
      SUBMIT_BUTTON_TEXT: 'KIRIM',
      NO_RESULT: 'Tidak ada hasil ditemukan.',
      SHOW_RESULT: 'Pilih bonus di bawah ini untuk melihat lebih banyak detail:',
      TABLE_HEADER: {
        COLUMN1: 'REFERENSI',
        COLUMN2: 'BONUS JUMLAH',
        COLUMN3: 'TANGGAL MULAI',
        COLUMN4: 'TANGGAL KADALUARSA',
        COLUMN5: 'STATUS',
      }
    },
    BONUS_DETAIL: {
      TITLE: 'Bonus - Detail',
      TERMS_SERVICE: 'Syarat & Ketentuan',
      BONUS: 'Bonus',
      START_DATE: 'Tanggal Mulai',
      EXPIRED_DATE: 'Tanggal Kedaluwarsa',
      PROGRESS: 'Progres',
      CASINO: 'Kasino',
      SPORTS: 'Olahraga',
      LIVE: 'Live',
      VALID_GAMES: 'Permainan Valid',
      ROLLOVER: 'Persyaratan Rollover',
      WAGERS_REMAIN: 'Taruhan Tersisa',
      RESTRICT_AMOUNT: 'JUMLAH TERBATAS',
      WINNINGS: 'Kemenangan',
      DEPOSIT: 'Setoran',
      TOTAL_RESTRICT_AMOUNT: 'Total Jumlah Terbatas',
      FORFEIT_BUTTON_TEXT: 'Hilangkan Bonus Saya',
      FORFEIT_DESC1: "Jika Anda membatalkan bonus ini, kami akan menghapus:",
      FORFEIT_DESC2: '{{currency}} {{winning}} dari kemenangan yang terbatas.',
      FORFEIT_DESC3: '{{currency}} {{bonus}} dari uang bonus yang terbatas.',
      FORFEIT_DESC4: 'kan meninggalkan Anda dengan {{currency}} {{deposit}} dari deposit Anda.',
      FORFEIT_DESC5: 'Apakah Anda yakin ingin membatalkan bonus ini?',
      CANCEL_BUTTON_TEXT: 'Batal',
      CONFIRM_BUTTON_TEXT: 'Ya, Batalkan Bonus',
      ERRORS: {
        CLOSE_OPENED_GAMES: 'Harap tutup semua permainan yang terbuka untuk membatalkan bonus.',
        RESOLVE_SPORTS_GAME: 'Sebuah acara olahraga menyimpan dana dari bonus ini - harap tunggu hingga diselesaikan terlebih dahulu.',
        RESOLVE_SAVED_GAME: 'Sebuah permainan yang disimpan menyimpan dana dari bonus ini - harap selesaikan terlebih dahulu.'
      },
      APPLY_BUTTON_TXT: {
        DEFAULT: 'Deposit Sekarang',
        LABEL1: 'Ajukan Sekarang',
        LABEL2: 'Deposit Sekarang',
        LABEL3: 'Main Sekarang',
        LABEL4: 'Tebus Sekarang',
        LABEL5: 'Detail Lebih Lanjut'
      }
    },
    REDEEM_BONUS: {
      TITLE: 'Kode Promo',
      DESCRIPTION: "Bergabunglah dengan komunitas {{name}} dan dapatkan kode promo!",
      INPUT_LABEL: 'Kode Bonus:',
      INPUT_PLACEHOLDER: 'Kode Promo',
      SUBMIT_BUTTON_TEXT: 'Tebus',
      FAILD: 'Kode bonus salah atau kedaluwarsa, silakan coba yang lain.',
      NOVALID: "Kode promo tidak tersedia",
      SUCCESS: "Kode Promo telah digunakan dengan sukses",
      SUCCESS_BUTTON_TXT: "Selesai"
    },
    REFER_FRIEND: {
      TITLE: 'Referensikan teman',
      SUBTITLE1: 'Undang Teman, Dapatkan Uang',
      DESCRIPTION1: 'Terima saldo tunai untuk setiap teman yang Anda referensikan {{website}}',
      TOTAL_REFERRED: 'Total Pengguna yang Anda Referensikan',
      SUBTITLE2: 'Bagikan kode undangan Anda',
      DESCRIPTION2: 'Kode Referensi Anda'
    },
    SPORTS_PAGE: {
      LOADING: 'Memuat'
    },
    GAME_HISTORY: {
      TITLE: "Riwayat Permainan",
      TABLE: {
        COLUMN1: "Permainan",
        COLUMN2: "Hasil taruhan",
        COLUMN3: "Waktu",
        COLUMN4: "Jumlah Taruhan",
        COLUMN5: "Pengganda",
        COLUMN6: "Pembayaran"
      },
      NO_HISTORY: "Tidak Ada Riwayat Permainan",
      SHOW_DATA_DESC: 'Hanya 50 rekaman pertandingan terakhir yang tersedia',
      TOTAL: "Jumlah",
      WIN: 'Menang',
      LOSS: 'Kerugian',
      DRAW: 'Gambar',
      UNSETTLED: 'BELUM TERSELESAIKAN',
      MOBILE: {
        CASH: 'SLOT',
        BET_AMOUNT: 'Jumlah Taruhan',
        MULTIPLIER: 'Pengganda',
        PAYOUT: 'Pembayaran'
      },
      SHAREING: {
        SUCCESS: {
          TITLE1: 'Lebih dari sekedar kemenangan,',
          TITLE2: 'dapatkan token dan dividen tambahan di sini.',
          BET_AMOUNT: 'dengan bertaruh',
          MULTIPLIER: "Pengganda {{value}}x",
          PLACED_USER: 'Ditempatkan Oleh: {{username}}',
          REFERRAL_CODE: 'Kode Referensi',
          CTA_SENTENCE2: 'Bagikan SEKARANG & menangkan hadiah lotere',
          DOWNLOAD_BTN: 'Unduh',
          SHARE_BTN: 'Bagikan'
        },
        FAILD: {
          TITLE: 'Gagal Berbagi',
          ALREADY_SHARED_GAME_LOG: 'Kemenangan ini dibagikan oleh orang lain.',
          ABOVE_ONE_MULTIPLIER: 'Hanya transaksi di atas 1x Multiplier yang dapat dibagikan.',
          EXCEEDED_DAILY: "Anda telah mencapai batas harian Anda."
        }
      }
    },
    LEADERBOARD: {
      TITLE: 'Papan Peringkat',
      TABLE: {
        COLUMN1: "",
        COLUMN2: "",
        COLUMN3: "Pemain",
        COLUMN4: "Total $FUN Token",
        COLUMN5: "Hadiah"
      },
      NO_DATA: 'Tidak Ada Papan Peringkat'
    },
    NEW_BANK: {
      TITLE: 'Informasi Bank',
      PAYMENT_TYPE: 'Jenis Pembayaran',
      SELECT_PAYMENT_TYPE: 'Pilih Jenis Pembayaran',
      BANK_LIST: 'Daftar Bank',
      SELECT_BANK: 'Pilih Bank',
      NAME: 'Nama Lengkap',
      ACCOUNT_NUMBER: 'Nomor Rekening',
      ACCOUNT_NUMBER_DESC: '1 hingga 17 digit saja',
      SUBMIT_BUTTON_TXT: 'KIRIM',
      ADD_NEW_BUTTON_TXT: 'Tambah Baru',
      CLOSE_BUTTON_TXT: 'Tutup'
    },
    PAYMENTS: {
      DEPOSIT_TXT: 'Setoran',
      WITHDRAW_TXT: 'Tarik'
    },
    PROFILE_INFO: {
      TITLE: 'Info Pribadi',
      FIRST_NAME: 'Nama Depan',
      FIRST_NAME_REQURIED_ERROR: 'Silakan masukkan nama depan resmi Anda.',
      FIRST_NAME_PATTERN_ERROR: "Nama Depan hanya boleh berisi karakter alfabet, spasi, tanda hubung, dan titik. Harap masukkan minimal 2 dan maksimal 20 karakter.",
      LAST_NAME: 'Nama Belakang',
      LAST_NAME_REQUIRED_ERROR: 'Silakan masukkan nama belakang resmi Anda.',
      LAST_NAME_PATTERN_ERROR: "Nama Belakang hanya boleh berisi karakter alfabet, spasi, tanda hubung, dan titik. Harap masukkan minimal 2 dan maksimal 20 karakter.",
      BIRTHDAY: {
        TITLE: 'Tanggal Lahir',
        YEAR: 'Tahun',
        MONTH: 'Bulan',
        DAY: 'Hari',
        ERROR: 'Tanggal lahir tidak valid.'
      },
      ADDRESS: {
        STREET: 'Alamat Jalan',
        STREET_REQURIED_ERROR: 'Silakan masukkan alamat jalan Anda.',
        PO_BOX_ERROR: 'Tidak dapat menggunakan kotak PO.',
        COUNTRY: 'Negara',
        STATE: 'Negara Bagian',
        CITY: 'Kota',
        CITY_REQURIED_ERROR: 'Silakan masukkan kota Anda.',
        CITY_PATTERN_ERROR: 'Silakan masukkan nama kota yang valid. Kota tidak boleh mencantumkan angka.',
        ZIPCODE: 'Kode Pos',
        ZIPCODE_REQUIRED_ERROR: 'Silakan masukkan kode pos Anda.',
        ZIPCODE_PATTERN_ERROR: 'Silakan masukkan huruf dan angka saja. Tanpa spasi atau karakter khusus.',
        ZIPCODE_MINLENGTH_ERROR: 'kode pos minimal harus 5 karakter'
      },
      SUBMIT_BUTTON_TXT: 'KIRIM',
      CHOOSE_FILE: 'Bukti Identitas/Pilih file',
      FILE_NOTE: "*Dokumen diterima : Paspor, SIM atau KTP..",
      ALERT: {
        SUCCESS: {
          TITLE: 'Verifikasi',
          MSG: 'Berhasil diperbarui'
        }
      }
    },
    PROVIDERS: {
      TITLE: 'Penyedia Game',
      GAMES_LENGTH: '{{count}} Permainan',
      GAMES: 'Permainan',
      CURRENT_DISPLAYING: 'Menampilkan {{count}} dari {{total}} penyedia'
    },
    TERMS: {
      TITLE: 'Syarat dan Ketentuan',
      PRINT_BUTTON_TXT: 'Cetak',
      DOWNLOAD_BUTTON_TXT: 'Unduh'
    },
    TRANSACTIONS: {
      TITLE: 'Transaksi',
      DEPOSIT_BUTTON_TXT: 'Setoran',
      WITHDRAW_BUTTON_TXT: 'Tarik',
      BONUS_BUTTON_TXT: 'Bonus',
      NO: 'Tidak',
      SHOW_DATA_DESC: 'Hanya 50 transaksi terakhir yang tersedia',
      TOTAL: 'Jumlah',
      deposit: 'Setoran',
      withdraw: 'Tarik',
      bonuses: 'Bonus',
      STATE: {
        CONFIRMED: "Confirmed",
        UNCONFIRMED: "Unconfirmed",
        UNSUCCESSFUL: "Unsuccessful"
      },
      DEPOSIT_TABLE: {
        COLUMN1: 'ID Pesanan',
        COLUMN2: 'Waktu',
        COLUMN3: 'Negara Bagian',
        COLUMN4: 'Mata Uang',
        COLUMN5: 'Setoran',
        COLUMN6: 'Sebelum Saldo',
        COLUMN7: 'Setelah Saldo',
        COLUMN8: 'Jumlah'
      },
      WITHDRAW_TABLE: {
        COLUMN1: 'ID Pesanan',
        COLUMN2: 'Waktu',
        COLUMN3: 'Negara Bagian',
        COLUMN4: 'Mata Uang',
        COLUMN5: 'Mundur',
        COLUMN6: 'Sebelum Saldo',
        COLUMN7: 'Setelah Saldo',
        COLUMN8: 'Jumlah'
      },
      BONUSES_TABLE: {
        COLUMN1: 'Jenis',
        COLUMN2: 'ID Pesanan',
        COLUMN3: 'Waktu',
        COLUMN4: 'Jumlah Bonus',
        COLUMN5: 'Bonus Name'
      }
    },
    USER_MENU: {
      WALLET: 'Dompet',
      TRANSACTIONS: 'Transaksi',
      GAME_HIISTORY: 'Riwayat Permainan',
      KYC_INFO: 'KYC',
      VIP: 'Klub VIP',
      REDEEM_COUPON: 'Tukarkan Kupon',
      REFERRAL: 'Rujuk dan Dapatkan',
      PROMOTIONS: 'Promosi',
      ACTIVE_BONUS:'Bonus Aktif',
      PAST_BONUS: 'Bonus Sebelumnya',
      LIVE_SUPPORT: 'Dukungan Langsung',
      ACCOUNT: 'Profil'
    },
    STEP_COMPONENT: {
      STEP_TXT: 'Langkah'
    },
    PLAYER_VIP: {
      MY_DATA: {
        VIP_PROGRESS: "Kemajuan VIP Anda",
        TOOLTIP: 'Setiap taruhan $1 memberi Anda 1XP. Semakin banyak Anda bermain, semakin cepat Anda mencapai peringkat tertinggi.',
        RAKEBACK: 'Rakeback',
        RANK_UP_BUTTON_TXT: 'Naik Peringkat',
        TOTAL_CLAIMED: 'Total Rakeback yang Diklaim',
        CLAIM_BUTTON_TXT: 'Klaim',
        REMAIN_UNTIL_NEXT: "{{xp}} XP hingga VIP {{level}}",
        REACHED_MAX_LEVEL: "Anda mencapai level maksimal!"
      },
      BETSPLUS: {
        LEVEL: "Tingkat",
        REQUIRED_XP: 'EXP yang diperlukan {{amount}} {{currency}}',
        LEVELUP_DESC: 'Dapatkan imbalan setiap kali Anda mengisi bilah kemajuan. Naik level memberi Anda hak untuk mendapatkan hadiah yang lebih besar dan lebih baik!',
        WITHDRAWN_AMOUNT: 'Jumlah yang dapat ditarik',
        TOTAL_REWARDS: 'Total Hadiah Tersedia',
        EXCHANGE_BUTTON_TXT: 'Pertukaran',
        CLAIM_BUTTON_TXT: 'Klaim',
        REWARD_REDEMPTION: "Penukaran Hadiah",
        TOTAL_CLAIMED: 'Total Hadiah yang Diklaim',
        REWARD: "Hadiah"
      }
    },
    REFERRALS: {
      MY_REFERRALS: {
        TOTAL_COMMISSION_EARNING: 'Total Pendapatan Komisi Referensi',
        REFERRED_YOU: "Siapa yang Merujuk Anda",
        AMOUNT_REFERRED: 'Jumlah pengguna yang Anda referensikan',
        NUMBER_OF_USERS: "Number of users"
      },
      BETSPLUS: {
        TITLE: 'Proyek Jutawan',
        DESCR1: 'DAPATKAN HINGGA <span style="color: #FFC83A;">N{{value}}</span>',
        DESCR2: 'DAN KOMISI HINGGA <span style="color: #FFC83A;">{{percent}}%</span>',
        HOWTOWORK: 'cara kerjanya',
        TOOLTIP_DETAIL: 'Step 1: Share referral code to friends and social media. <br> Step 2: Guide them to get their deposit bonus. <br> Step 3: Recommend some great games for them. <br> Step 4: Claim and spend your referral rewards. <br> Step 5: Repeat the steps!',
        INVITE_SECT: {
          TITLE: 'Undang Pengguna',
          SHARES: 'Bagikan',
          INVITEES: 'Yang diundang',
          INVITE_LINK: 'Tautan undangan',
          INVITE_QR_CODE: 'Undang kode QR',
          QUICK_SHARE: 'Bagikan cepat',
          DOWNLOAD_BTN: 'Unduh'
        },
        DATA_CENTER: {
          TITLE: 'Pusat Data',
          TOTAL_REVENUE: 'Total pendapatan',
          INVITATION: 'Undangan',
          WAGER: 'Taruhan',
          ACHIEVEMENT: 'Prestasi',
          VIP: 'VIP',
          MORE_BTN: 'Lainnya'
        },
        REWARD_SECT: {
          TITLE: 'Penukaran Hadiah',
          AMOUNT_WITHDRAWN: 'Jumlah yang dapat diklaim',
          EXCHANGE_BUTTON_TXT: 'Pertukaran',
          CLAIM_BUTTON_TXT: 'Klaim',
          TOTAL_REWARD: 'Total Hadiah yang Tersedia : {{value}}'
        },
        ACTIVITY_RULES: {
          TITLE: 'Aturan Aktivitas',
          SEE_MORE: 'Lihat Selengkapnya',
          DATA1: {
            LABEL: 'BONUS UNDANGAN',
            TEXT: 'Setiap kali Anda mengundang teman yang depositnya mencapai 20 USD, Anda akan mendapatkan hadiah 5 USD.'
          },
          DATA2: {
            LABEL: 'KOMISI TARUHAN',
            TEXT: 'Peraturan Bonus: Semua pemain di level "Promotor" menerima persentase bonus keuntungan platform untuk setiap taruhan.'
          },
          DATA3: {
            LABEL: 'BONUS PRESTASI',
            TEXT: 'Jika jumlah kumulatif undangan memenuhi ketentuan, Anda akan menerima hadiah pencapaian tambahan. <br> Semakin banyak orang yang Anda undang, semakin tinggi hadiahnya.'
          },
          DATA4: {
            LABEL: 'BONUS PENINGKATAN LEVEL VIP',
            TEXT: 'Setiap pengguna yang Anda undang mencapai level tertentu dalam permainan, dan Anda akan mendapatkan bonus kenaikan level VIP tambahan. <br> USD adalah mata uang khusus yang diluncurkan oleh Platform . Anda dapat ...'
          }
        },
        AMONG_BEST: 'Di antara yang terbaik',
        LIVE_REWARD: 'Hadiah Langsung',
        QA: {
          TITLE: 'Tanya Jawab'
        },
        EXCHANGE: {
          MESSAGE: 'Berhasil diterima',
          CONFIRM_BUTTON_TXT: 'Konfirmasi'
        },
        ACHIEVE_BONUS: {
          TITLE: 'Bonus Prestasi',
          DESCRIPTION: 'Jika jumlah kumulatif undangan memenuhi ketentuan, Anda akan menerima hadiah pencapaian tambahan. <br> Semakin banyak orang yang Anda undang, semakin tinggi hadiahnya.',
          TABLE: {
            COLUMN1: 'Level Teman',
            COLUMN2: 'Beberapa orang',
            COLUMN3: 'Hadiah'
          }
        },
        BETTING_COMMISSION: {
          TITLE: 'Komisi Taruhan',
          SHORT_DESC:'Aturan Bonus: Semua pemain di level "Promotor" menerima persentase bonus keuntungan platform untuk setiap taruhan.',
          LONG_DESC: `Proporsi 3 level adalah sebagai berikut <br>
          Tingkat 1: Dapatkan 35% dari 1% taruhan <br>
          Tingkat 2: Dapatkan 12,25% Dari 1% taruhan <br>
          Tingkat 3: Dapatkan 4,29% dari 1% taruhan <br>
          Ini akan menjadi penghasilan jangka panjang Anda, dan setiap kali pemain yang Anda referensikan memasang taruhan, Anda akan menerima persentase komisi yang berbeda terlepas dari apakah dia menang atau kalah. Jadi yang harus Anda lakukan adalah meningkatkan keterampilan permainan Anda, pikirkan tentang cara memenangkan permainan dan membaginya dengan orang lain, dan bantu lebih banyak orang menang dengan metode Anda. Kami ingin semua pemain bersenang-senang di platform kami, baik itu kesenangan memenangkan taruhan atau permainan itu sendiri`
        },
        USER_LIST: {
          TITLE: "Users List",
          TABS: {
            TAB1: 'Sub-pengguna',
            TAB2: 'Pengguna bawahan'
          },
          SEARCH_PLACEHOLDER: 'UID Pengguna Penelusuran',
          SELECTS: {
            ITEM1: 'Semua',
            ITEM2: 'Hari ini',
            ITEM3: '7 hari terakhir',
            ITEM4: '30 hari terakhir',
            ITEM5: '60 hari terakhir'
          },
          TABLE: {
            COLUMN1: 'Email / Phone',
            COLUMN2: 'WAKTU',
            COLUMN3: 'SETORAN',
            COLUMN4: 'TARUHAN',
            COLUMN5: 'UNDANGAN',
          },
          TOTAL: 'Jumlah',
          NO_DATA: "No user data"
        },
        VIP_LEVEL_BONUS: {
          TITLE: 'Bonus Naik Level VIP',
          DESC: 'Setiap pengguna yang Anda undang mencapai level yang ditentukan dalam game, dan Anda akan mendapatkan bonus tambahan level VIP. USD adalah mata uang khusus yang diluncurkan oleh Platform. Anda dapat menukar USD ke mata uang lain kapan saja.',
          TABLE: {
            COLUMN1: 'Level Teman',
            COLUMN2: 'Total Taruhan',
            COLUMN3: 'Hadiah'
          }
        }
      }
    },
    KYC: {
      TITLE: "KYC",
      DESC: "Karena persyaratan peraturan dari Komisi Regulasi Lotere Nasional (NLRC) dan lisensi operasi Curacao, kami mengharuskan Anda memverifikasi Alamat Email dan nomor Telepon Anda untuk mencegah penipuan, kecurangan, dan aktivitas mencurigakan. Jika Anda mengalami masalah, Anda juga dapat meminta bantuan dari Live Chat.",
      BUTTON_TXT: "Akun"
    },
    GOOGLE_LOGIN: {
      EERROR_MSG: 'Tidak dapat login dengan info akun. Silakan coba lagi dengan yang lain'
    },
    SUCCESS_MSG: {
      TITLE: 'Sukses!'
    },
    ERROR_MSG: {
      TITLE: 'Maaf, ada masalah'
    },
    COMMON: {
      COMING_SOON: "Segera Hadir",
      LOAD_MORE: "Muat Lebih Banyak",
      LOGOUT: 'Keluar',
      DEPOSIT: 'Setoran',
      WITHDRAW: 'Menarik',
      INVALID_AMOUNT: 'Jumlah tidak valid',
      HOME_BUTTON_TXT: 'Rumah',
      BACK_BUTTON_TXT: 'Kembali',
      COPIED: 'Disalin',
      OK: "Oke",
      CANCEL: "Batal",
      SEARCH: "Pencarian"
    },
    LUCKSPIN: {
      TITLE: "Lucky Spin",
      TRY_AGAIN: "Try again",
      BUTTON_TEXT: "Spin",
      WIN_SPIN_TEXT: "LUCKY WIN SPIN NOW",
      BOTTOM_TEXT: "DAILY FREE SPINS",
      AVAILABLE_SPINS: "Available spins"
    },
    ERRORS: {
      400: 'Status ilegal',
      401: 'Tidak Sah',
      402: 'Pembayaran diperlukan',
      404: 'idak ditemukan',
      500: 'Kesalahan server dari dalam',
      503: '[Ezugi] Pengidentifikasi permainan tidak valid',
      504: '[Ezugi] Operasi tidak valid',
      506: '[Ezugi] Mata uang tidak valid',
      508: '[Ezugi] Pengidentifikasi transaksi tidak valid',
      511: '[Leander] Token tidak valid',
      1002: '[Evolusi] ID sesi tidak valid',
      1007: '[NYX] RC mata uang tidak diketahui',
      6001: 'Sesi permainan [Vanguard] tidak valid',
      6003: 'Otentikasi [Vanguard] gagal',
      40011: '[RGS] Jenis permainan tidak valid',
      40012: '[RGS] ID permainan tidak valid',
      10000: 'Batas akun terlampaui',
      10001: 'Penambahan gagal',
      10002: 'Verifikasi usia gagal',
      10004: 'Sudah punya akun',
      10005: 'Sudah terdaftar',
      10006: 'Sudah terkirim',
      10010: 'Argumen buruk',
      10017: "Kode bonus tidak ditemukan",
      10018: 'Bonus tidak ada',
      10019: 'Bonus tidak valid',
      10021: 'Tidak dapat menyetujui permintaan pembayaran',
      10022: 'Tidak dapat membatalkan permintaan pembayaran',
      10024: 'Tidak dapat memproses pembayaran',
      10025: 'Tidak dapat memproses permintaan pembayaran',
      10034: 'Kode mata uang tidak ditemukan',
      10037: 'Pelanggan sudah masuk',
      10038: 'Pelanggan tidak ada', //
      10042: 'Pelanggan masuk daftar hitam',
      10045: 'Pelanggan tidak ditemukan', //
      10046: 'Pelanggan tidak terdaftar', //
      10047: 'Pelanggan tidak termasuk',
      10049: 'Pelanggan ditangguhkan',
      10052: 'Verifikasi pelanggan tidak ditemukan',
      10055: 'Pembaruan khusus gagal',
      10059: 'Akun dinonaktifkan',
      10064: 'Email sudah ada',
      10066: 'Kode email tidak ditemukan',
      10069: 'Email sedang digunakan',
      10071: 'Email tidak ditemukan',
      10143: 'Format file tidak valid',
      10145: 'Permainan sudah dimulai',
      10146: 'Kode permainan tidak valid',
      10150: 'Permainan tidak ditemukan',
      10154: 'Dapatkan keseimbangan',
      10155: 'Dapatkan kesalahan saldo',
      10170: 'Dana tidak mencukupi',
      10173: 'Jumlah maks tidak valid',
      10174: 'Jumlah min tidak valid',
      10177: 'ID bonus tidak valid',
      10178: 'Jenis bonus tidak valid',
      10186: 'Pelanggan tidak valid',
      10190: 'ID permainan tidak valid',
      10200: 'Sesi tidak valid',
      10207: 'Token tidak valid',
      10221: 'Gagal masuk',
      10222: 'Login maksimal login tercapai',
      10223: 'Masuk kata sandi salah',
      10224: 'Masuk nama pengguna salah',
      10225: 'Logout gagal',
      10246: 'Kata sandi tidak cocok',
      10250: 'Telepon sudah ada',
      10307: 'Nama pengguna sudah ada',
      10308: 'Validasi tanggal lahir gagal',
      10325: 'Email belum terdaftar',
      10326: 'Email sudah digunakan sebagai akun google sso',
      10327: 'Kode referral salah',
      10328: 'Pelanggan belum mencairkan komisi referral',
      10329: 'tidak bisa naik level',
      10330: 'Gagal mengklaim cashback VIP',
      10331: 'Level VIP pelanggan tidak valid',
      10332: 'Gagal mengklaim komisi referral',
      10333: 'Gagal mendapatkan informasi pencapaian rujukan',
      10334: 'Nomor telepon tidak valid',
      10341: "Not enough value to claim bonus",
      11507: 'Negara tidak ditemukan',
      11511: 'Bonus kasino tidak valid',
      11512: 'Harap tutup semua game yang dibuka untuk kehilangan bonus.',
      11513: 'Game yang disimpan menyimpan dana dari bonus ini - harap selesaikan terlebih dahulu.',
      11514: 'Acara olahraga mencadangkan dana dari bonus ini - harap tunggu penyelesaiannya terlebih dahulu.',
      11515: 'Bonus kasino tidak aktif',
      11516: 'Tidak dapat kehilangan bonus kasino Deposit Rollover.',
      11517: "Kredit harian pemain tidak mencukupi",
      11518: 'Gagal mendapatkan hadiah',
      11519: "Hitungan harian hadiah tidak mencukupi",
      11520: "Harap tunggu masa cooldown sebelum mengirim email lagi.",
      11521: "Mohon tunggu masa cooldown sebelum mengirim sms lagi.",
      11522: "Capai waktu maksimum pengiriman email untuk verifikasi dalam sehari.",
      11523: "Capai waktu maksimum pengiriman sms verifikasi dalam sehari.",
      11526: "ClubPay Exception",
      100000: 'GAGAL_MEMVALIDASI_KODE_PENDAFTARAN',
      100002: 'GAGAL_MENGIRIM_VALIDASI_SMS'
    },
    BONUS_TYPES: {
      "Deposit": "Deposit",
      "Withdrawal": "Withdrawal",
      "Table Buyin": "Table Buyin",
      "Table Rebuy": "Table Rebuy",
      "Table Leave": "Table Leave",
      "Place Bet": "Place Bet",
      "Tournament Buyin": "Tournament Buyin",
      "Tournament Fee": "Tournament Fee",
      "Tournament Buyin Refund": "Tournament Buyin Refund",
      "Tournament Fee Refund": "Tournament Fee Refund",
      "Manual Adjustment": "Manual Adjustment",
      "Recovery Refund": "Recovery Refund",
      "Transfer In": "Transfer In",
      "Transfer Out": "Transfer Out",
      "Round Win": "Round Win",
      "Bonus Win": "Bonus Win",
      "Deposit Bonus": "Deposit Bonus",
      "Account Creation Bonus": "Account Creation Bonus",
      "Referral Bonus": "Referral Bonus",
      "Bonus Conversion": "Bonus Conversion",
      "Login Grant": "Login Grant",
      "Initial Grant": "Initial Grant",
      "Virtual Goods Purchase": "Virtual Goods Purchase",
      "Loyalty": "Loyalty",
      "Winning in random scratcher": "Winning in random scratcher",
      "Mystery Gift Win": "Mystery Gift Win",
      "FB Credit Order": "FB Credit Order",
      "Leader Board Points": "Leader Board Points",
      "Tournament Award": "Tournament Award",
      "Returning Player Bonus": "Returning Player Bonus",
      "Cancelled round refund": "Cancelled round refund",
      "Sit & Go Award": "Sit & Go Award",
      "Sit & Go Buyin": "Sit & Go Buyin",
      "Sit & Go Fee": "Sit & Go Fee",
      "Sit & Go Buyin Refund": "Sit & Go Buyin Refund",
      "Sit & Go Fee Refund": "Sit & Go Fee Refund",
      "Fast Poker buyin": "Fast Poker buyin",
      "Fast Poker leave": "Fast Poker leave",
      "Fast Poker rebuy": "Fast Poker rebuy",
      "Tournament Rebuy": "Tournament Rebuy",
      "Tournament AddOn": "Tournament AddOn",
      "Sit & Go Rebuy": "Sit & Go Rebuy",
      "Sit & Go AddOn": "Sit & Go AddOn",
      "Bonus Deposit": "Bonus Deposit",
      "Bonus Released": "Bonus Released",
      "Bonus Deposit Released": "Bonus Deposit Released",
      "Bonus Forfeit": "Bonus Forfeit",
      "Bonus Expired": "Bonus Expired",
      "Bonus Winning Released": "Bonus Winning Released",
      "Void Deposit": "Void Deposit",
      "Bonus Deposit Forfeit": "Bonus Deposit Forfeit",
      "Bonus Winnings Forfeit": "Bonus Winnings Forfeit",
      "Bonus Deposit Expired": "Bonus Deposit Expired",
      "Bonus Winnings Expired": "Bonus Winnings Expired",
      "SnG Buyin Recovery Refund": "SnG Buyin Recovery Refund",
      "SnG Fee Recovery Refund": "SnG Fee Recovery Refund",
      "Tournament Buyin Recovery Refund": "Tournament Buyin Recovery Refund",
      "Tournament Fee Recovery Refund": "Tournament Fee Recovery Refund",
      "Clearing released bonus": "Clearing released bonus",
      "Clearing released bonus deposit": "Clearing released bonus deposit",
      "Clearing released bonus winnings": "Clearing released bonus winnings",
      "Void Withdrawal": "Void Withdrawal",
      "Bonus Deposit Forfeit Cleared": "Bonus Deposit Forfeit Cleared",
      "Bonus Deposit Expired Cleared": "Bonus Deposit Expired Cleared",
      "Bonus Cash Grant": "Bonus Cash Grant",
      "Place a Purchase": "Place a Purchase",
      "Cancel a Purchase": "Cancel a Purchase",
      "Tournament Rebuy Refund": "Tournament Rebuy Refund",
      "Tournament Rebuy Recovery Refund": "Tournament Rebuy Recovery Refund",
      "Tournament AddOn Refund": "Tournament AddOn Refund",
      "Tournament AddOn Recovery Refund": "Tournament AddOn Recovery Refund",
      "SnG Rebuy Refund": "SnG Rebuy Refund",
      "SnG Rebuy Recovery Refund": "SnG Rebuy Recovery Refund",
      "SnG AddOn Refund": "SnG AddOn Refund",
      "SnG AddOn Recovery Refund": "SnG AddOn Recovery Refund",
      "Bounty Tournament Award": "Bounty Tournament Award",
      "Jackpot winning": "Jackpot winning",
      "Collectible game award": "Collectible game award",
      "Claim reward": "Claim reward",
      "Cancel reward": "Cancel reward",
      "Coupon redeemed": "Coupon redeemed",
      "Tournament Bounty Buyin": "Tournament Bounty Buyin",
      "Tournament Bounty Buyin Refund": "Tournament Bounty Buyin Refund",
      "Tournament Bounty Buyin Recovery Refund": "Tournament Bounty Buyin Recovery Refund",
      "SnG Bounty Buyin": "SnG Bounty Buyin",
      "SnG Bounty Buyin Refund": "SnG Bounty Buyin Refund",
      "SnG Bounty Buyin Recovery Refund": "SnG Bounty Buyin Recovery Refund",
      "Reward Claimed Refund": "Reward Claimed Refund",
      "Void reward": "Void reward",
      "Tournament Prize Pool Distribution On Recovery": "Tournament Prize Pool Distribution On Recovery",
      "SnG Prize Pool Distribution On Recovery": "SnG Prize Pool Distribution On Recovery",
      "Chips Coupon Claimed": "Chips Coupon Claimed",
      "Milestone Bonus": "Milestone Bonus",
      "External Grant": "External Grant",
      "Void Bet": "Void Bet",
      "Daily TopOff": "Daily TopOff",
      "Bonus Grant": "Bonus Grant",
      "Bonus Used": "Bonus Used",
      "Sport Bet": "Sport Bet",
      "Sport Win": "Sport Win",
      "Sport Win Adjustment": "Sport Win Adjustment",
      "Sport Round Refund": "Sport Round Refund",
      "Sport Round Void": "Sport Round Void",
      "Round Win Bonus": "Round Win Bonus",
      "IBC Sport Unsettle": "IBC Sport Unsettle",
      "IBC Sport Adjust Balance": "IBC Sport Adjust Balance",
      "VIP Level rank-up bonus": "VIP Level rank-up bonus",
      "VIP Level cashback bonus": "VIP Level cashback bonus",
      "Referral commission bonus": "Referral commission bonus",
      "Any Deposit Rollover Bonus": "Any Deposit Rollover Bonus",
      "KYC bonus": "KYC bonus",
      "Daily Lose Rake Back": "Daily Lose Rake Back",
      "Daily lose cashback bonus": "Daily lose cashback bonus",
      "IBC Sport Settled": "IBC Sport Settled"
    }
  }
};
