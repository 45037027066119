import { AppLinks } from './../../../../shared/app-links';
import { UniqueIDProvider } from '../../../../shared/providers/unique-id-provider';
import { Navigator } from '../../../../system/components/menu/navigator';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from '@angular/forms';
import { Config } from '../../../../system/providers/configuration';
import { Utils } from '../../../../shared/utils';
import { AuthenticationProvider } from '../../providers/authentication-provider';
import { ConfigurationProvider } from '../../../../system/providers/configuration-provider';
import { UserSessionProvider } from '../../../../system/providers/user-session';
import { AffiliationEventProvider } from '../../../../shared/providers/affiliation-event';
import {
  mustBeTruthy,
  passwordMatchValidator,
  PASSWORD_PATTERN,
} from '../../../../shared/customFormValidators';
import { TouchUserProvider } from './../../providers/touch-user-provider';
import { Storage } from '@ionic/storage-angular';
import { isUndefined } from '../../../../shared/codeUtils';
import { MyEvent } from 'src/app/service/myevent.services';
import { IonContent, IonInput, NavController, Platform } from '@ionic/angular';
import { RewardsProvider } from '../../../rewards/providers/rewards-provider';
import { CommonService } from 'src/app/service/common.service';
import { ActivatedRoute } from '@angular/router';
import { TranslationService } from 'src/app/service/translation.service';
import { WebservicesProvider } from 'src/app/system/providers/webservices';
import { environment } from 'src/environments/environment';
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'page-signup-step-one',
  templateUrl: 'signup-step-one.html',
  styleUrls: ['./signup-step-one.scss'],
})
export class SignupStepOnePage implements OnInit, OnDestroy {

  @Input() signupCode: any;
  @Input() depositCode: any;

  @ViewChild(IonContent) content: IonContent;
  @ViewChild('emailInp') emailInp: IonInput;
  @ViewChild('verifyCodeInput') verifyCodeInput: IonInput;
  @ViewChild('phoneInput') phoneInput: IonInput;

  public error = null;
  public infoForm: FormGroup;
  public email: AbstractControl;
  public phone: AbstractControl;
  public passwordGroup: AbstractControl;
  public password: AbstractControl;
  // public passwordConfirm: AbstractControl;
  public certOver18: AbstractControl;
  public customerAttributionData: any[];
  public extAffiliateId: string;
  public registrationSuccessful: boolean = false;
  public featureSiteTermsLink;
  public welcomeOffers = [];
  public promoCode: AbstractControl;
  public csrf: AbstractControl;
  public csrf_token: string = '';
  public verifyCode: AbstractControl;
  public feautureCustomerSupportURL: string;

  public bonusCode;
  public verifyBonusBtnDisabled: boolean = true;
  selectedBonusIndex;
  bonusIsVerified: boolean = false;
  referrVal: any;

  public buttonDisabled: boolean = false;
  public isMobileWeb: boolean = false;
  // public environmentName: string;
  // public promotionCodeFlag: string = 'true';
  public customer_id: string;

  passType = 'password';
  cpassType = 'password';

  websiteName: any;

  mainLogo: any;
  banner: any;

  signupType = 'email';
  showCountrySelection = false;
  countryCode: string;
  verificationCode: any;
  verifiedStatus = false;

  checkEmailStatus: any;
  checkPhoneStatus: any;

  tokenInterval: any;

  appConfigInfo: any;
  showEmail = false;
  showPhone = false;
  showVerificationCode = false;

  canInputVerificationCode = false;
  disableGetCodeButton = false;
  resendInterval: any;
  resendTimePeriod = 60;
  downTimePeriod = 1;

  captcha: AbstractControl;
  recaptchaEnabled = false;
  reCaptchakey = environment.recaptcha.siteKey;

  constructor(
    private config: Config,
    public events: MyEvent,
    private affiliationEvent: AffiliationEventProvider,
    public navCtrl: NavController,
    public configProvider: ConfigurationProvider,
    public authProvider: AuthenticationProvider,
    private userSession: UserSessionProvider,
    public utils: Utils,
    public fb: FormBuilder,
    public platform: Platform,
    public uniqueIDProvider: UniqueIDProvider,
    public touchUserProvider: TouchUserProvider,
    private storage: Storage,
    private rewardProvider: RewardsProvider,
    private appLinks: AppLinks,
    public comService: CommonService,
    private activeRouter: ActivatedRoute,
    private transService: TranslationService,
    private webService: WebservicesProvider,
    private cdr: ChangeDetectorRef,
    private cookieService: CookieService
  ) {
    this.activeRouter.queryParams.subscribe(params => {
      if (params['referral']) {
        this.signupCode = params['referral'];
        this.referrVal = params['referral'];
        this.infoForm.patchValue({inputReferCode: params['referral']});
        this.verifyBonus();
      }
    });
  }

  ngOnInit() {
    //this.websiteName = document.querySelector('title').text;

    this.featureSiteTermsLink = this.config.getConfig().FEATURE_SITE_TERMS_LINK || '';

    this.appConfigInfo = this.config.getAppConfigInfo();

    this.websiteName = this.appConfigInfo.site_name || '';

    this.mainLogo = this.appConfigInfo.main_logo || 'assets/imgs/logo.png';
    this.banner = this.appConfigInfo.signup_banner || '';

    this.countryCode = this.appConfigInfo.country_code;

    this.infoForm = new FormGroup({
      clientapp: new FormControl(''),
      csrf: new FormControl(),
      inputReferCode: new FormControl('', [Validators.maxLength(6)]),
      email: new FormControl('', [
        Validators.pattern(
          '^([0-9a-zA-Z]+[-._+&amp;])*[0-9a-zA-Z]+@([-0-9a-zA-Z]+[.])+[a-zA-Z]{2,6}$'
        ),
        Validators.required,
        Validators.email
      ]),
      passwordGroup: new FormGroup(
        {
          password: new FormControl('', [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(48),
            Validators.pattern(this.appConfigInfo.password_pattern || ''),
          ]),
          // passwordConfirm: new FormControl('', [
          //   // Validators.required,
          //   // Validators.minLength(6),
          //   // Validators.pattern(appConfigInfo.password_pattern || ''),
          // ]),
        },
        // passwordMatchValidator
      ),
      certOver18: new FormControl(true, []), // mustBeTruthy
      customerAttributionData: new FormControl(''),
      extAffiliateId: new FormControl(''),
      promo_code: new FormControl(''),
      email_check: new FormControl(true),
      phone_check: new FormControl(false)
    });

    this.bonusCode = this.signupCode;

    if (this.signupCode) {
      this.referrVal = this.signupCode;
      this.infoForm.patchValue({inputReferCode: this.signupCode});
      this.verifyBonus();
    }

    this.infoForm.get('clientapp').setValue(this.config.getClientApp());
    this.email = this.infoForm.get('email');
    this.passwordGroup = this.infoForm.get('passwordGroup');
    this.password = this.passwordGroup.get('password');
    // this.passwordConfirm = this.passwordGroup.get('passwordConfirm');
    this.certOver18 = this.infoForm.get('certOver18');
    this.promoCode = this.infoForm.get('promo_code');

    this.feautureCustomerSupportURL = this.config.getConfig().FEATURE_CUSTOMER_SUPPORT_URL || '';

    this.canInputVerificationCode = false;

    if (this.signupType == 'email') {
      this.showVerificationCode = (this.appConfigInfo.auth_type['email'] && (this.config.getConfig().FEATURE_SIGN_UP_EMAIL_VERIFICATION == 'true'));
      if (this.showVerificationCode) {
        this.infoForm.addControl('code', new FormControl('', [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
        ]));
        this.verifyCode = this.infoForm.get('code');
      }
      this.downTimePeriod = this.config.getConfig().COOLDOWN_PERIOD_IN_MINUTES_FOR_SENDING_EMAIL_VERIFICATION ? Number(this.config.getConfig().COOLDOWN_PERIOD_IN_MINUTES_FOR_SENDING_EMAIL_VERIFICATION) : 1;
    } else if (this.signupType == 'phone') {
      this.showVerificationCode = (this.appConfigInfo.auth_type['phone'] && (this.config.getConfig().FEATURE_SIGN_UP_SMS_VERIFICATION == 'true'));
      if (this.showVerificationCode) {
        this.infoForm.addControl('code', new FormControl('', [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
        ]));
        this.verifyCode = this.infoForm.get('code');
      }
      this.downTimePeriod = this.config.getConfig().COOLDOWN_PERIOD_IN_MINUTES_FOR_SENDING_SMS_VERIFICATION ? Number(this.config.getConfig().COOLDOWN_PERIOD_IN_MINUTES_FOR_SENDING_SMS_VERIFICATION) : 1;
    }

    this.showEmail = this.appConfigInfo.auth_type['email'];
    this.showPhone = this.appConfigInfo.auth_type['phone'];
    if (!this.showEmail && this.showPhone) { // only phone option
      this.signupType = 'phone';

      this.infoForm.removeControl('email');
      this.email = null;
      this.infoForm.patchValue({email_check: false});

      this.infoForm.addControl('phone', new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+$'),
        Validators.minLength(8),
        Validators.maxLength(20),
      ]));
      this.phone = this.infoForm.get('phone');

      if (this.config.getConfig().FEATURE_SIGN_UP_SMS_VERIFICATION == 'true') {
        this.infoForm.patchValue({phone_check: true});

        this.infoForm.addControl('code', new FormControl('', [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
        ]));
        this.verifyCode = this.infoForm.get('code');

        this.showVerificationCode = true;
      }
      this.downTimePeriod = this.config.getConfig().COOLDOWN_PERIOD_IN_MINUTES_FOR_SENDING_SMS_VERIFICATION ? Number(this.config.getConfig().COOLDOWN_PERIOD_IN_MINUTES_FOR_SENDING_SMS_VERIFICATION) : 1;
    }
    else if (this.showEmail && (this.config.getConfig().FEATURE_SIGN_UP_EMAIL_VERIFICATION != 'true')) { // email option
      this.infoForm.patchValue({email_check: false});
    }

    if (this.config.getConfig().FEATURE_REGISTER_RECAPTCHA_ENABLE == '1') {
      if (this.config.getConfig().FEATURE_REGISTER_RECAPTCHA_SITE_KEY) {
        this.reCaptchakey = this.config.getConfig().FEATURE_REGISTER_RECAPTCHA_SITE_KEY;
      }

      this.infoForm.addControl('captcha', new FormControl(null, [
        Validators.required
      ]));
      this.captcha = this.infoForm.get('captcha');

      this.recaptchaEnabled = true;
    }
  }

  ngAfterViewInit() {
    this.ionViewDidEnter();
  }

  getCsrfToken() {
    this.authProvider.getCsrfToken().subscribe((response) => {
      console.log('csrf_token == ', response);
      if (
        !isUndefined(response.payload) &&
        !isUndefined(response.payload.token)
      ) {
        this.csrf_token = response.payload.token;
      }
    });
  }

  ionViewDidEnter() {
    // let appConfigInfo = this.config.getAppConfigInfo();
    // this.environmentName = appConfigInfo.state;
    // this.promotionCodeFlag = appConfigInfo.promotion_code_step_one_flag;

    this.getCsrfToken();
    this.tokenInterval = setInterval(() => {
      this.getCsrfToken();
    }, 30 * 1000);

    // this should get the welcome offers
    this.rewardProvider
      .callGetSignupOffers({ clientapp: this.config.getClientApp() })
      .subscribe(
        (response: any) => {
          console.log('callGetSignupOffers :', response);
          if (response && !!response.result && !isUndefined(response.payload)) {
            this.welcomeOffers = response.payload;
            this.selectedBonusIndex = this.welcomeOffers.findIndex((offer) =>
              offer.tagline != null
                ? offer.tagline.toUpperCase().includes('DEFAULT')
                : null
            );

            if (this.selectedBonusIndex == -1) {
              this.selectedBonusIndex = this.welcomeOffers.length - 1;
            }
            // if no bonus code from navParams
            if (!this.bonusCode) {
              let codeNotNull =
                this.welcomeOffers[this.selectedBonusIndex] != null &&
                this.welcomeOffers[this.selectedBonusIndex].signupcode != null
                  ? true
                  : false;
              this.bonusCode = codeNotNull
                ? this.welcomeOffers[this.selectedBonusIndex].signupcode
                : null;
            } else {
              this.selectedBonusIndex = this.welcomeOffers.findIndex((offer) =>
                offer.signupcode != null
                  ? offer.signupcode == this.bonusCode
                  : null
              );
              if (this.selectedBonusIndex == -1) {
                this.selectedBonusIndex = this.welcomeOffers.length - 1;
              }
            }
          }
        },
        (error) => {
          console.log('callGetAllCountries ', error);
        }
      );

    this.emailInp?.setFocus();
  }

  ionViewWillLeave() {
    clearInterval(this.tokenInterval);
    this.tokenInterval = null;
  }

  ngOnDestroy(): void {
    clearInterval(this.tokenInterval);
    this.tokenInterval = null;
  }

  onBonusSelect(index, offer) {
    this.bonusCode = offer.signupcode;
    this.selectedBonusIndex = index;
  }

  async onSubmit() {
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve('');
      }, 120);
    });
    this.buttonDisabled = true;
    console.log('form value: ', this.infoForm.value);
    if (this.referrVal) {
      if (this.bonusIsVerified) {
        this.bonusCode = this.referrVal;
      } else {
        this.content.scrollToTop();
        this.buttonDisabled = false;
        return false;
      }
    }
    this.promoCode.patchValue(this.bonusCode);
    this.infoForm.get('clientapp').setValue(this.config.getClientApp());
    this.infoForm.get('csrf').setValue(this.csrf_token);
    this.certOver18.patchValue(true);

    if (this.signupType == 'email' && (this.config.getConfig().FEATURE_SIGN_UP_EMAIL_VERIFICATION == 'true')) {
      this.infoForm.patchValue({email_check: true});
    } else {
      this.infoForm.patchValue({email_check: false});
    }

    if (this.signupType == 'phone' && (this.config.getConfig().FEATURE_SIGN_UP_SMS_VERIFICATION == 'true')) {
      this.infoForm.patchValue({phone_check: true});
    } else {
      this.infoForm.patchValue({phone_check: false});
    }

    if (!this.infoForm.valid || this.selectedBonusIndex == 'invalid' || (this.showVerificationCode && !this.verifyCode.value)) {
      this.buttonDisabled = false;
      console.log('form valid: ', this.infoForm.valid);
      return;
    }

    if (this.infoForm.value['phone']) {
      // var country_code = this.countryCode.substring(1);
      this.infoForm.value['dialCode'] = this.countryCode;
    }

    this.authProvider.callSignupStep1(this.infoForm.value).subscribe(
      (response: any) => {
        this.buttonDisabled = false;
        console.log('callSignup response = ', response);
        if (response && !!response.result) {
          if (response.result) {
            this.customer_id = response.payload.customerId;
            let csrf_token = response.payload.csrf_token
              ? response.payload.csrf_token
              : 'override';

            let obj: any = {
              csrf_token: csrf_token,
              password: this.password.value,
              client_app_id: this.config.getClientApp()
            };
            if (this.signupType == 'email') {
              obj.email = this.email.value;
            } else if (this.signupType == 'phone') {
              // obj.phone = this.infoForm.value['dialCode'] + '' + this.phone.value;
              obj.phone = this.phone.value;
            }
            const device = this.uniqueIDProvider.advertisingID;
            if (device != null) {
              obj.device_id = device;
            }

            this.authProvider.callLogin(obj).subscribe((response: any) => {
              if (response && !!response.result) {
                this.userSession.setSessionToken(response.payload.sessionToken);
                if (!isUndefined(response.payload.customerId)) {
                  this.userSession.setCustomerId(response.payload.customerId);
                }

                if (this.signupCode) {
                  localStorage.removeItem('b_referral_code');
                }

                this.updateAffiliateData(this.customer_id);
                // this.doMFA();
                this.continueSignup();
              }
            });
          } else {
            this.registrationSuccessful = false;
          }
        }
      },
      async (error) => {
        this.buttonDisabled = false;
        console.log('callSignup error = ', error);

        this.getCsrfToken();

        switch (error.error_code) {
          case 10064:
            var msg = await this.transService.getTranslationWord('AUTH.SIGNUP.ERRORS.ALREADY_EXIST');
            error.setErrorMessage(msg);
            this.email.setErrors({ EMAIL_ALREADY_EXISTS: true });
            this.email.markAsTouched();
            this.email.markAsDirty();
            break;
          case 10004:
            var msg = await this.transService.getTranslationWord('AUTH.SIGNUP.ERRORS.ALREADY_EXIST');
            error.setErrorMessage(msg);
            break;
          case 10042:
            var msg = await this.transService.getTranslationWord('AUTH.SIGNUP.ERRORS.NOT_CREATED');
            error.setErrorMessage(msg);
            break;
          case 10157:
            break;
          case 10011:
            error.setErrorMessage(error.getErrorMessage());
            break;
          case 10292:
            break;
          default:
            error.setErrorMessage(error.getErrorMessage());
            break;
        }

        this.error = error;
        this.content.scrollToTop();
      }
    );
  }

  updateAffiliateData(customer_id: string) {
    console.info('signup.ts: updateAffiliateData');
    // get affiliate data
    this.affiliationEvent.getAttributionData().then((data) => {
      var customerUpdates: any = {};
      if (!data) {
        // look for appnexus data
        let appnexus_data: any = null;
        try {
          appnexus_data = JSON.parse(this.affiliationEvent.getAppnexusData());
        } catch (e) {
          console.log(
            'json parse error',
            this.affiliationEvent.getAppnexusData()
          );
        }
        if (
          !!appnexus_data &&
          'undefined' !== typeof appnexus_data.extaffiliateid
        ) {
          customerUpdates['extAffiliateId'] = appnexus_data.extaffiliateid;
        }
        if (!!appnexus_data) {
          customerUpdates['customerAttributionData'] =
            JSON.stringify(appnexus_data);
        } else {
          delete customerUpdates['customerAttributionData'];
        }
      }
      if (data) {
        try {
          if (typeof data === 'string') {
            data = JSON.parse(data);
          }
          this.customerAttributionData = data;
          if ('undefined' !== typeof data.affiliate) {
            this.extAffiliateId = data.affiliate;
          }
          if ('undefined' !== typeof data.ext_affiliate_id) {
            this.extAffiliateId = data.ext_affiliate_id;
          }

          if (this.utils.getInsensitiveProp(data, 'extAffiliateId')) {
            this.extAffiliateId = this.utils.getInsensitiveProp(
              data,
              'extAffiliateId'
            );
          }
        } catch (err) {
          console.log('signup.ts: JSON parse fail on input: ' + data);
        }
        if ('undefined' !== typeof this.extAffiliateId) {
          customerUpdates['extAffiliateId'] = this.extAffiliateId;
        }
        if ('undefined' !== typeof this.customerAttributionData) {
          customerUpdates['customerAttributionData'] = JSON.stringify(
            this.customerAttributionData
          );
        }
      }
      const countKeys = (myobj) => {
        var count = 0;
        for (var k in myobj) if (myobj.hasOwnProperty(k)) count++;
        return count;
      };
      if (countKeys(customerUpdates) > 0) {
        console.info(
          'signup.ts: updateAffiliateData: calling updateAccount...'
        );
        this.userSession.updateAccount(customer_id, customerUpdates);
      }
    });
  }

  //Used for terms in welcome Offers
  goToFeatureOfferTermsLink(url) {
    let absoluteURL = this.utils.isAbsoluteURL(url);
    if (absoluteURL) {
      this.appLinks.openExternalPage(url);
    }
    return false;
  }

  openExternalLinks(code: string): any {

  }

  onLogin() {
    if (this.comService.isMobileWeb) {
      this.events.publishSomeData({event: 'show:login'});
    } else {
      Navigator.back();
      Navigator.navigateTo(
        'LoginPage',
        { forceModal: true, cssClass: 'login_modal' },
        { depositCode: this.depositCode }
      );
    }
  }

  goToFeautureCustomerSupportURL() {
    if (!this.feautureCustomerSupportURL) return;
    this.appLinks.openExternalPage(this.feautureCustomerSupportURL);
    return false;
  }

  verifyBonus() {
    let bonusCode = this.referrVal;
    this.authProvider.validateReferrerCode(bonusCode).subscribe(
      (response) => {
        console.log('refer resp == ', response);
        if (response.code == 200 && response.result) {
          this.selectedBonusIndex = 'input';
          this.bonusIsVerified = true;
        }
      },
      (error) => {
        let errorCode = error.error_code || undefined;
        this.bonusIsVerified = false;
        this.content.scrollToTop();
        this.selectedBonusIndex = 'invalid';

        if (this.signupCode) {
          localStorage.removeItem('b_referral_code');
        }

        // setTimeout(() => {
        //   this.infoForm.patchValue({
        //     inputReferCode: ''
        //   });
        //   this.referrVal = null;
        //   this.selectedBonusIndex = null;
        // }, 3500);
      }
    );
  }

  bonusChanged($event) {
    var temp = $event.target.value;
    this.referrVal = temp;
    this.selectedBonusIndex = null;
    if (this.bonusIsVerified) {
      this.bonusIsVerified = false;
    }

    if (temp.length == 6) {
      this.verifyBonus();
    }
  }

  lossFocus($event) {
    if (this.referrVal && (this.referrVal.length < 6 || this.referrVal.length > 6)) {
      this.bonusIsVerified = false;
      this.selectedBonusIndex = 'invalid';
    }
  }

  // private doMFA = () => {
  //     let isMFA = this.config.getConfig().FEATURE_MFA_ENABLED == "1" ? true : false;

  //     if(isMFA) {
  //         Navigator.navigateTo("CodeLoginPage", { multipleModals: true }, { customerId: this.customer_id, callBackFn: this.continueSignup })
  //     } else {
  //         this.continueSignup();
  //     }
  // }

  private continueSignup = () => {
    // this.cookieService.set("DoNotShowPaymentsPageThisTime", "1")
    this.userSession.refreshCustomer().subscribe(() => {
      Navigator.toLobby();
      // this.events.publish("event:appInit:init", { triggeredBy: "SIGNUP_STEP_ONE" });
      this.events.publishSomeData({ event: 'event:appInit:init' });
      // let luckySpinKey = `LuckySpinDaily_${this.userSession.customerId}_${String(new Date().getDate())}`
      // if ("1" != localStorage.getItem(luckySpinKey)) {
      //   Navigator.navigateTo('SpinWheelComponent', {forceModal: true, cssClass: 'spin-wheel'});
      //   localStorage.setItem(luckySpinKey, "1")
      // }
    });
    // event for successful signup
    this.events.publishSomeData({
      event: 'event:signupstep1complete',
      data: { customer_id: this.customer_id },
    });
    this.events.publishSomeData({
      event: 'event:login',
      data: { customer_id: this.customer_id },
    });
  };

  changeTypeOfPassword() {
    if (this.passType == 'password') this.passType = 'text';
    else this.passType = 'password';
  }

  changeTypeOfCPassword() {
    if (this.cpassType == 'password') this.cpassType = 'text';
    else this.cpassType = 'password';
  }

  onClose() {
    Navigator.toLobby();
  }

  inputPhone($event) {
    const value = $event.target!.value;
    var filteredValue = value.replace(/[^0-9]+/g, '');
    if (this.phone) this.phone.patchValue(filteredValue);
    if (this.phoneInput) this.phoneInput.value = filteredValue;

    this.clearError('phone');
  }

  clearError(part?) {
    this.error = null;

    if (part == 'password') {
      if (this.verifyCode) {
        this.verifyCode.markAsDirty();
        this.verifyCode.markAsTouched();
      }
    }

    if (part == 'email' || part == 'phone') {
      this.checkEmailStatus = false;
      this.checkPhoneStatus = false;

      this.verificationCode = null;
      if (this.verifyCodeInput) this.verifyCodeInput.value = '';
      if (this.verifyCode) {
        this.verifyCode.patchValue('');
        this.verifyCode.setErrors(null);
        this.verifyCode.markAsPristine();
        this.verifyCode.markAsUntouched();
      }
      this.verifiedStatus = false;
      this.disableGetCodeButton = false;
      this.canInputVerificationCode = false;

      this.infoForm.markAsDirty();
    }
  }

  googleLogin() {
    if (this.selectedBonusIndex == 'input' && this.bonusIsVerified) {
      sessionStorage.setItem('google_referral_code', '' + this.referrVal);
    }
    this.authProvider.googleLoginFunc();
  }

  changeSignupType(type) {
    this.signupType = type;

    this.password.markAsPristine();
    this.password.markAsUntouched();
    this.password.patchValue('');
    this.password.setErrors(null);

    this.showCountrySelection = false;

    if (type == 'email') { // email option

      this.infoForm.reset();

      this.infoForm.removeControl('phone');
      this.phone = null;
      this.infoForm.patchValue({phone_check: false});

      this.infoForm.addControl('email', new FormControl('', [
        Validators.pattern(
          '^([0-9a-zA-Z]+[-._+&amp;])*[0-9a-zA-Z]+@([-0-9a-zA-Z]+[.])+[a-zA-Z]{2,6}$'
        ),
        Validators.required,
        Validators.email
      ]));
      this.email = this.infoForm.get('email');

      if (this.config.getConfig().FEATURE_SIGN_UP_EMAIL_VERIFICATION != 'true') {
        this.infoForm.patchValue({email_check: false});
        this.showVerificationCode = false;

        if (this.verifyCode) {
          this.infoForm.removeControl('code');
          this.verifyCode = null;
        }
      }
      else {
        this.infoForm.patchValue({email_check: true});

        if (!this.verifyCode) {
          this.infoForm.addControl('code', new FormControl('', [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(6),
          ]));
          this.verifyCode = this.infoForm.get('code');
        }

        this.showVerificationCode = true;
      }

      if (this.signupCode) {
        this.infoForm.patchValue({inputReferCode: this.signupCode});
      }

      this.downTimePeriod = this.config.getConfig().COOLDOWN_PERIOD_IN_MINUTES_FOR_SENDING_EMAIL_VERIFICATION ? Number(this.config.getConfig().COOLDOWN_PERIOD_IN_MINUTES_FOR_SENDING_EMAIL_VERIFICATION) : 1;
    }
    else if (type == 'phone') {

      this.infoForm.reset();

      this.infoForm.removeControl('email');
      this.email = null;
      this.infoForm.patchValue({email_check: false});

      this.infoForm.addControl('phone', new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+$'),
        Validators.minLength(8),
        Validators.maxLength(20),
      ]));
      this.phone = this.infoForm.get('phone');

      if (this.config.getConfig().FEATURE_SIGN_UP_SMS_VERIFICATION == 'true') {
        this.infoForm.patchValue({phone_check: true});

        if (!this.verifyCode) {
          this.infoForm.addControl('code', new FormControl('', [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(6),
          ]));
          this.verifyCode = this.infoForm.get('code');
        }

        this.showVerificationCode = true;

      } else {
        this.infoForm.patchValue({phone_check: false});
        this.showVerificationCode = false;

        if (this.verifyCode) {
          this.infoForm.removeControl('code');
          this.verifyCode = null;
        }
      }

      if (this.signupCode) {
        this.infoForm.patchValue({inputReferCode: this.signupCode});
      }

      this.downTimePeriod = this.config.getConfig().COOLDOWN_PERIOD_IN_MINUTES_FOR_SENDING_SMS_VERIFICATION ? Number(this.config.getConfig().COOLDOWN_PERIOD_IN_MINUTES_FOR_SENDING_SMS_VERIFICATION) : 1;
    }

    this.clearError(this.signupType);
  }

  showCountries() {
    this.showCountrySelection = !this.showCountrySelection;
  }

  changeCountry($event) {
    this.countryCode = $event.country_code;
    this.showCountrySelection = !this.showCountrySelection;
    if (this.phone.value && this.phone.valid) {
      this.checkPhone();
    }
  }

  clickOutside() {
    setTimeout(() => {
      this.showCountrySelection = false;
    }, 670);
  }



  inputVerificationCode($event) {
    this.verifyCode.setErrors(null);
    var val = $event.target.value;
    var filteredValue = val.replace(/[^0-9]*$/g, '');
    this.verificationCode = filteredValue;
    if (this.verifyCodeInput) this.verifyCodeInput.value = filteredValue;
    this.verifyCode.patchValue(filteredValue);
    this.verifyCode.markAsTouched();
    this.verifyCode.markAsDirty();
    this.verifiedStatus = false;

    if (this.verificationCode.length == 6) {
      if (this.signupType == 'email') this.validateCode();
      else if (this.signupType == 'phone') this.verifySMSCode();
    }
  }


  /**
   *
   * @returns validate email
   */
  checkEmail() {
    if (!this.email.valid) return;
    this.checkEmailStatus = false;
    this.authProvider.checkEmail(this.email.value).subscribe(resp => {
      console.log('check email == ', resp);
      if (resp.code == 200 && resp.result) {
        this.checkEmailStatus = resp.payload;
      }
    }, error => {
      console.error('check email error == ', error);
    });
  }

  getCode() {
    this.webService.post('player/email/send-validation-code', {
      email: this.email.value,
      site: this.websiteName,
      skinId: 1,
      language: 'en',//this.transService.getSelectedLanguage(),
      emailFormat: 'HTML'
    }).subscribe(async (response) => {
      console.log('get code == ', response);
      if (response.code == 200) {
        var msg = await this.transService.getTranslationWord('AUTH.SIGNUP.SENT_VERIFY_EMAIL');
        this.comService.showToast(msg);
        this.canInputVerificationCode = true;
        this.disableGetCodeButton = true;

        if (this.verifyCodeInput) this.verifyCodeInput.value = '';
        this.verifyCode.patchValue('');
        this.verifyCode.markAsUntouched();
        this.verifyCode.markAsPristine();
        this.verifiedStatus = false;

        this.infoForm.markAsDirty();

        clearInterval(this.resendInterval);
        this.resendInterval = null;
        this.resendTimePeriod = this.downTimePeriod * 60;

        this.resendInterval = setInterval(() => {
          this.resendTimePeriod--;

          if (this.resendTimePeriod <= 0) {
            clearInterval(this.resendInterval);
            this.resendInterval = null;
            this.disableGetCodeButton = false;
            this.resendTimePeriod = this.downTimePeriod * 60;
          }

          this.cdr.detectChanges();
        }, 1000);

        this.cdr.detectChanges();
      }
    }, err => {
      console.error('code err == ', err);
      this.canInputVerificationCode = false;
      this.disableGetCodeButton = false;
      this.comService.showToast(err.getErrorMessage(), 'error_toast');
    });
  }

  validateCode() {
    this.webService.post('player/email/validate-code', {
      email: this.email.value,
      code: this.verificationCode
    }).subscribe(async (response) => {
      console.log('validate code == ', response);
      if (response.code == 200) {
        this.verifiedStatus = true;
      }
    }, async (error) => {
      console.error('validate err == ', error);
      this.verifiedStatus = false;
      this.verifyCode.setErrors({WRONG_CODE: true});
    });
  }

  /**
   * phone validation
   */
  checkPhone() {
    if (!this.phone.value) return;
    this.checkPhoneStatus = false;
    var country_code = this.countryCode.substring(1);
    this.authProvider.checkPhone(country_code + '' + this.phone.value).subscribe(resp => {
      console.log('check phone == ', resp);
      if (resp.code == 200 && resp.result) {
        this.checkPhoneStatus = resp.payload;
      }
    }, error => {
      console.error('check phone error == ', error);
    });
  }

  sendSMSCode() {
    var country_code = this.countryCode.substring(1);
    this.webService.post('player/sms/send-validation-code', {
      phone_number: country_code + '' + this.phone.value,
      site: this.websiteName,
      skin_id: 1,
      language_code: 'en',//this.transService.getSelectedLanguage()
    }).subscribe(async (response) => {
      console.log('get sms code == ', response);
      if (response.code == 200) {
        var msg = await this.transService.getTranslationWord('AUTH.SIGNUP.SENT_VERIFY_SMS');
        this.comService.showToast(msg);
        this.canInputVerificationCode = true;
        this.disableGetCodeButton = true;

        clearInterval(this.resendInterval);
        this.resendInterval = null;
        this.resendTimePeriod = this.downTimePeriod * 60;

        this.resendInterval = setInterval(() => {
          this.resendTimePeriod--;

          if (this.resendTimePeriod <= 0) {
            clearInterval(this.resendInterval);
            this.resendInterval = null;
            this.disableGetCodeButton = false;
            this.resendTimePeriod = this.downTimePeriod * 60;
          }

          this.cdr.detectChanges();
        }, 1000);

        this.cdr.detectChanges();
      }
    }, err => {
      console.error('sms code err == ', err);
      this.canInputVerificationCode = false;
      this.disableGetCodeButton = false;
      this.comService.showToast(err.error_message, 'error_toast');
    });
  }

  verifySMSCode() {
    var country_code = this.countryCode.substring(1);
    this.webService.post('player/sms/validate-code', {
      phone_number: country_code + '' + this.phone.value,
      code: this.verificationCode,
      skin_id: 1
    }).subscribe(async (response) => {
      console.log('validate code == ', response);
      if (response.code == 200) {
        this.verifiedStatus = true;
      }
    }, async (error) => {
      console.error('validate err == ', error);
      this.verifiedStatus = false;
      this.verifyCode.setErrors({WRONG_CODE: true});
    });
  }

  /**
   * recaptcha
   */
  inputCaptcha($event) {
    if (this.captcha) {
      this.captcha.setErrors(null);

      this.captcha.markAsTouched();
      this.captcha.markAsDirty();
    }
  }

  resolved($event) {
    console.log(`Resolved captcha with response: ${$event}`);

    if($event != null && $event != undefined) {
      if (this.captcha) {
        this.captcha.patchValue($event);
      }
    }
  }
}
