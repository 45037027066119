import { Injectable } from '@angular/core';
import { CustomerModel } from '../../modules/account/models/customer-model';
import {
  AffiliateTrackerInterface,
  AffiliateTrackerCallbackParams,
} from './affiliation-event';
import { WebservicesProvider } from '../../system/providers/webservices';

@Injectable({
  providedIn: 'root',
})
export class IncomeAccessTracker implements AffiliateTrackerInterface {

  public customer: CustomerModel;
  protected configVars: any;
  protected params: any;
  private attributionData: any = null;

  constructor(
    private webservices: WebservicesProvider,
  ) {}

  initialize(): Promise<AffiliateTrackerInterface> {
    return Promise.resolve(this);
  }

  captureParameters(): Array<string> {
    return ['btag'];
  }

  setAttributionData(attr_data: any): void {
    console.log('incomeaccess-tracker.ts: setAttributionData: ', attr_data);
    this.attributionData = attr_data;
  }

  protected eventtrackingRegistration(customer_id: number | string) {
    let args: any = {
      player_id: customer_id,
    };
    if (
      this.attributionData &&
      'undefined' !== typeof this.attributionData.btag
    ) {
      args.BTag = this.attributionData.btag;
    }
    this.webservices
      .post('external-services/incomeaccess/event/register', args)
      .subscribe(
        (data) => {
          console.log('incomeaccess-tracker.ts: data:', data);
        },
        (err) => {
          console.log('incomeaccess-tracker.ts: err:', err);
        }
      );
  }

  public signupComplete(cbParams: AffiliateTrackerCallbackParams) {
    this.eventtrackingRegistration(cbParams.customer_id);
  }
  public signupStep1Complete(cbParams: AffiliateTrackerCallbackParams) {
    this.eventtrackingRegistration(cbParams.customer_id);
  }
}
