import { CustomerModel } from './../../account/models/customer-model';
import { UserSessionProvider } from './../../../system/providers/user-session';
import { WebservicesProvider } from './../../../system/providers/webservices';

import { Injectable } from '@angular/core';
import { Config } from '../../../system/providers/configuration';

@Injectable({
  providedIn: 'root',
})
export class ResponsibleGamingProvider {

  public customer: CustomerModel;

  constructor(
    private userSession: UserSessionProvider,
    private webservice: WebservicesProvider,
    protected config: Config
  ) {
    console.log('ResponsibleGamingProvider');

    this.userSession.getCustomer().subscribe((customer) => {
      this.customer = customer;
    });
  }

  getGlobalLimits() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.get('accountable-gaming/player-limits/global', obj);
  }

  getLimitHistory() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.get('accountable-gaming/player-limits/history', obj);
  }

  getDepositLimit() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.get('accountable-gaming/player-limits/deposit', obj);
  }

  setDepositLimit(data: any) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    if (data.deposit_daily_limit != null && data.deposit_daily_limit != '') {
      obj.daily = data.deposit_daily_limit;
    }

    if (data.deposit_weekly_limit != null && data.deposit_weekly_limit != '') {
      obj.weekly = data.deposit_weekly_limit;
    }

    if (
      data.deposit_monthly_limit != null &&
      data.deposit_monthly_limit != ''
    ) {
      obj.monthly = data.deposit_monthly_limit;
    }

    return this.webservice.post(
      'accountable-gaming/player-limits/deposit/set',
      obj
    );
  }

  deleteDepositLimit(data: any) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      term_type: data.termType,
    };

    return this.webservice.post(
      'accountable-gaming/player-limits/deposit/delete',
      obj
    );
  }

  getSpendLimit() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.get('accountable-gaming/player-limits/wager', obj);
  }

  setSpendLimit(data: any) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    if (data.spend_daily_limit != null && data.spend_daily_limit != '') {
      obj.daily = data.spend_daily_limit;
    }

    if (data.spend_weekly_limit != null && data.spend_weekly_limit != '') {
      obj.weekly = data.spend_weekly_limit;
    }

    if (data.spend_monthly_limit != null && data.spend_monthly_limit != '') {
      obj.monthly = data.spend_monthly_limit;
    }

    return this.webservice.post(
      'accountable-gaming/player-limits/wager/set',
      obj
    );
  }

  deleteSpendLimit(data: any) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      term_type: data.termType,
    };

    return this.webservice.post(
      'accountable-gaming/player-limits/wager/delete',
      obj
    );
  }

  getTimeLimit() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.get('accountable-gaming/player-limits/time', obj);
  }

  getTotalLoginTime(data: any) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      client_app_id: data,
    };

    return this.webservice.get(
      'accountable-gaming/player-limits/total-login-time',
      obj
    );
  }

  setTimeLimit(data: any) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    if (data.time_limit != null && data.time_limit != '') {
      obj.daily = data.time_limit;
    }

    return this.webservice.post(
      'accountable-gaming/player-limits/time/set',
      obj
    );
  }

  deleteTimeLimit(data: any) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      term_type: data.termType,
    };

    return this.webservice.post(
      'accountable-gaming/player-limits/time/delete',
      obj
    );
  }

  getWagerLimit() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.get('accountable-gaming/player-limits/wager', obj);
  }

  setWagerLimit(data: any) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    if (
      data.wager_single_max_limit != null &&
      data.wager_single_max_limit != ''
    ) {
      obj.single = data.wager_single_max_limit;
    }

    return this.webservice.post(
      'accountable-gaming/player-limits/wager/set',
      obj
    );
  }

  deleteWagerLimit(data: any) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      term_type: data.termType,
    };

    return this.webservice.post(
      'accountable-gaming/player-limits/wager/delete',
      obj
    );
  }

  //PA regulatory webservices

  getAllPendingLimit() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.get('accountable-gaming/player-limits/pending', obj);
  }

  confirmAllExceptDepositLimit(data: any) {
    let obj: any = {
      confirm: data.userSelection,
      customer_limit_id: data.customerLimitID,
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.post('responsibleGaming/apply-limit', obj);
  }

  confirmDepositLimit(data: any) {
    let obj: any = {
      confirm: data.userSelection,
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      limit_term_type: data.termType,
    };

    return this.webservice.post('responsibleGaming/apply-deposit-limit', obj);
  }

  //PA regulatory webservices

  public callCloseAccount() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.get('accountable-gaming/close-account', obj);
  }

  /*
        POST /customer/resend-pin
    */
  public callResendPin(customerId?) {
    let customer_id = !this.customer ? customerId : this.customer.customerId;

    let obj: any = {
      player_id: customer_id,
      session_id: this.userSession.sessionToken,
    };

    // return this.webservice.post('customer/resend-pin', obj);
    // return this.webservice.post('auth/player/pin-number/resend', obj);
    return this.webservice.post('player/pin-number/resend', obj);
  }

  public setCoolOff(days) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      period_days: days,
    };
    return this.webservice.post('accountable-gaming/status/cool-off', obj);
  }

  public setSelfExclude(data: any, state: string) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      pin_number: data.pin,
    };

    if (state == 'true' && data.excludePeriod[2] == 'Y') {
      obj.period_years = data.excludePeriod[0];
    }

    if (state == 'true' && data.excludePeriod[2] == 'M') {
      obj.period_months = data.excludePeriod[0];
    }

    if (state == 'false') {
      obj.period_years = data.excludePeriod;
    }

    return this.webservice.post('accountable-gaming/status/self-exclude', obj);
  }

  getLossLimit() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.get('accountable-gaming/player-limits/loss', obj);
  }

  setLossLimit(data: any) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    if (data.loss_daily_limit != null && data.loss_daily_limit != '') {
      obj.daily = data.loss_daily_limit;
    }

    if (data.loss_weekly_limit != null && data.loss_weekly_limit != '') {
      obj.weekly = data.loss_weekly_limit;
    }

    if (data.loss_monthly_limit != null && data.loss_monthly_limit != '') {
      obj.monthly = data.loss_monthly_limit;
    }

    return this.webservice.post(
      'accountable-gaming/player-limits/loss/set',
      obj
    );
  }

  deleteLossLimit(data: any) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      term_type: data.termType,
    };

    return this.webservice.post(
      'accountable-gaming/player-limits/loss/delete',
      obj
    );
  }
}
