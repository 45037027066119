import { Utils } from './../../../../shared/utils';
import { UniqueIDProvider } from './../../../../shared/providers/unique-id-provider';
import { Device } from '@ionic-native/device/ngx';
import { Storage } from '@ionic/storage-angular';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
// import { DatePipe } from '@angular/common';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  FormBuilder,
} from '@angular/forms';
import { Config } from '../../../../system/providers/configuration';
import { UserSessionProvider } from '../../../../system/providers/user-session';
import {
  AuthenticationProvider,
  ILoginResponsePayload,
  LoginResponseComments,
} from '../../providers/authentication-provider';
import { Navigator } from './../../../../system/components/menu/navigator';
import { CustomerModel } from '../../../account/models/customer-model';
import { PopoverFactory } from './../../../popover/factory';
import { isUndefined } from '../../../../shared/codeUtils';
import { AppLinks } from './../../../../shared/app-links';
import { MainProvider } from '../../../lobby/providers/main.provider';
import {
  IonInput,
  IonSlides,
  ModalController,
  Platform,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';
import { CommonService } from 'src/app/service/common.service';
import { TranslationService } from 'src/app/service/translation.service';

@Component({
  selector: 'page-login',
  templateUrl: 'login.html',
  styleUrls: ['./login.scss'],
})
export class LoginPage implements OnInit {

  @Input() signupCode: any;
  @Input() depositCode: any = '';
  @Input() screenType: any = 'login';

  @ViewChild('emailInput') emailInput: IonInput;
  @ViewChild('pwdInput', {static: true}) pwdInput: IonInput;
  @ViewChild('slides', {static: true}) slides: IonSlides;

  slideConfig = {
    slidesPerView: 1,
    spaceBetween: 10,
    centeredSlides: true,
    initialSlide: 0
  };

  loginResponse;
  public error = null;
  errorCode: any;

  public csrf: AbstractControl;
  public csrf_token: string = '';
  public email: AbstractControl;
  public phone: AbstractControl;

  public loginForm: FormGroup;
  public feautureCustomerSupportURL: string;
  public tooManyLoginAttemps: boolean = false;
  public emailisSent: boolean = false;
  public hideBackButton: boolean = true;
  customer: CustomerModel;

  public tapCount: number = 0;
  public tapTimeoutID: any = 0;
  public advertisingID: any;
  public advertisingVendorID: any;
  public buttonDisabled: boolean = false;
  public state: string;
  public toastMessageQue: any = [];

  mainLogo: any;
  passType = 'password';
  websiteName: any;

  signinType = 'email';
  showCountrySelection = false;
  countryCode: any;

  tokenInterval: any;
  appConfigInfo: any;

  constructor(
    private userSession: UserSessionProvider,
    private authProvider: AuthenticationProvider,
    private config: Config,
    private modal: ModalController,
    private popoverCtrl: PopoverController,
    private toastController: ToastController,
    public events: MyEvent,
    private storage: Storage,
    private device: Device,
    private fb: FormBuilder,
    private uniqueIDProvider: UniqueIDProvider,
    private utils: Utils,
    private platform: Platform,
    private appLinks: AppLinks,
    private mainProvider: MainProvider,
    public comService: CommonService,
    private transService: TranslationService
  ) {}

  async ngOnInit() {
    this.buttonDisabled = false;
    this.loginForm = this.fb.group({
      username: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('^([0-9a-zA-Z]+[-._+&amp;])*[0-9a-zA-Z]+@([-0-9a-zA-Z]+[.])+[a-zA-Z]{2,6}$')
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(48),
      ]),
      client_app_id: new FormControl(''),
      csrf: new FormControl(),
    });

    this.loginForm.get('client_app_id').setValue(this.config.getClientApp());

    this.feautureCustomerSupportURL = this.config.getConfig().FEATURE_CUSTOMER_SUPPORT_URL || '';

    this.websiteName = document.querySelector('title').text;

    this.slides.lockSwipes(true);
    this.events.getObservable().subscribe(data => {
      if (data.event == 'show:login') {
        this.showLogin()
      } else if (data.event == 'show:signup') {
        this.showSignup();
      }
    });

    if (this.screenType == 'signup') {
      this.showSignup();
    }

    this.appConfigInfo = this.config.getAppConfigInfo();
    this.state = this.appConfigInfo.state;
    this.mainLogo = this.appConfigInfo.main_logo || 'assets/imgs/logo.png';
    this.countryCode = this.appConfigInfo.country_code;

    this.email = this.loginForm.get('username');

    if (!this.appConfigInfo.auth_type.email && this.appConfigInfo.auth_type.phone) {
      this.signinType = 'phone';

      this.loginForm.removeControl('username');
      this.email = null;

      this.loginForm.addControl('phone', new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+$'),
        Validators.minLength(8),
        Validators.maxLength(20)
      ]));
      this.phone = this.loginForm.get('phone');

      this.loginForm.reset();
    }
  }

  ionViewWillEnter() {
    this.platform.ready().then(() => {
      this.advertisingVendorID = this.device.uuid;
    });
  }

  ionViewDidEnter() {
    this.generateCsrfToken();
    this.tokenInterval = setInterval(() => {
      this.generateCsrfToken();
    }, 30 * 1000);
    this.emailInput?.setFocus();
    if (localStorage.getItem('BP_last_login_username')) {
      if (this.emailInput) this.emailInput.value = localStorage.getItem('BP_last_login_username');
      this.loginForm.patchValue({username: localStorage.getItem('BP_last_login_username')});
    }
  }

  ionViewWillLeave() {
    clearInterval(this.tokenInterval);
    this.tokenInterval = null;
  }

  generateCsrfToken() {
    this.authProvider.getCsrfToken().subscribe((response) => {
      if (
        !isUndefined(response.payload) &&
        !isUndefined(response.payload.token)
      ) {
        this.csrf_token = response.payload.token;
      }
    });
  }

  goToFeautureCustomerSupportURL() {
    if (!this.feautureCustomerSupportURL) return;
    this.appLinks.openExternalPage(this.feautureCustomerSupportURL);
    return false;
  }

  onSendResetPasswordEMail(email) {
    let clientApp = this.config.getClientApp();
    let obj = {
      email: email,
      client_app_id: clientApp,
    };
    this.authProvider.callForgotPassword(obj).subscribe(
      (response: any) => {
        this.emailisSent = true;
      },
      (error) => {
        this.emailisSent = true;
      }
    );
  }

  doLogin() {
    this.error = null;
    this.errorCode = null;
    this.utils.setCookieParam('userSession.mfa', 'false');
    this.mainProvider.setMFARequired(true);

    this.loginForm.get('client_app_id').setValue(this.config.getClientApp());
    this.loginForm.get('csrf').setValue(this.csrf_token);

    if (!this.loginForm.valid) {
      console.log('Form validation failed');
      return;
    } 
    else {
      let obj = this.loginForm.value;
      const device = this.uniqueIDProvider.getMFADeviceId();
      if (device) {
        obj.device_id = device;
      }
      if (this.signinType == 'phone') {
        var country_code = this.countryCode.substring(1);
        obj['phoneNumber'] = country_code + '' + this.loginForm.value['phone'];
      } else if (this.signinType == 'email') {
        obj['email'] = this.loginForm.value['username'];
      }
      this.buttonDisabled = true;
      this.authProvider.callLogin(obj).subscribe(
        (response: any) => {
          console.log('login resp == ', response);
          if (response && response.code == 200 && response.payload) {
            this.userSession.setSessionToken(response.payload.sessionToken);
            if (!isUndefined(response.payload.customerId)) {
              this.userSession.setCustomerId(response.payload.customerId);
            }

            this.loginResponse = response.payload;

            //Adding Toast Message Queue
            if (
              !isUndefined(response.payload.lastLogoutReason) &&
              (response.payload.lastLogoutReason == 'GAME_CLT_LOGOUT' ||
                response.payload.lastLogoutReason == 'APP_INACTIVITY')
            ) {
              this.toastMessageQue.push('INACTIVITY');
            }

            if ('lastIPAddress' in this.loginResponse) {
              this.userSession.setCustomerIPAddress(this.loginResponse.lastIPAddress);
            }

            if ('lastLogoutReason' in this.loginResponse) {
              this.storage.set(
                'lastLogoutReason',
                this.loginResponse.lastLogoutReason
              );
            }

            //Adding BWPA strongAuthentication F5 fix - Store the value in storage and then clear the storage value once user validate the strong auth code
            if ('strongAuthenticationRequired' in this.loginResponse) {
              this.mainProvider.setStrongAuthRequired(true);
              this.storage.set(
                'strongAuthenticationRequired',
                this.loginResponse.strongAuthenticationRequired
              );
            } else {
              this.storage.set('strongAuthenticationRequired', false);
            }

            const loginInfo = response.payload as ILoginResponsePayload;
            if (
              loginInfo &&
              loginInfo.comments &&
              loginInfo.comments.indexOf(
                LoginResponseComments.CUSTOMER_MFA_REQUIRED
              ) > -1
            ) {
              if (loginInfo.deviceId) {
                this.uniqueIDProvider.setMFADeviceIdAsObservable(
                  loginInfo.deviceId
                );
              }
              this.doMFA();
            } else {
              this.checkLoginActions();
            }

            if (this.signinType == 'email') localStorage.setItem('BP_last_login_username', this.loginForm.value.username);

            this.buttonDisabled = false;
          } 
          else {
            this.generateCsrfToken();
          }
        },
        async (error) => {
          console.error('login err == ', error);
          this.buttonDisabled = false;
          this.generateCsrfToken();
          this.errorCode = error.error_code;
          switch (error.error_code) {
            case 10224:
              var msg = await this.transService.getTranslationWord('AUTH.LOGIN.ERRORS.NOT_EXIST');
              error.setErrorMessage(msg);
              break;
            case 10223:
              var msg = await this.transService.getTranslationWord('AUTH.LOGIN.ERRORS.WRONG_PASSWORD');
              error.setErrorMessage(msg);
              break;
            case 10078:
            case 10222:
              var msg = await this.transService.getTranslationWord('AUTH.LOGIN.ERRORS.TOO_MANY_ATEMP');
              error.setErrorMessage(msg);
              this.hideBackButton = false;
              this.tooManyLoginAttemps = true;
              break;
            case 10288:
              var msg = await this.transService.getTranslationWord('AUTH.LOGIN.ERRORS.TIME_LIMIT');
              error.setErrorMessage(msg);
              break;
            case 10232:
              error.setErrorMessage(
                'You are logging from an unauthorized client app version.'
              );
              break;
            case 10040:
              error.setErrorMessage(
                'Your account has been excluded from this site. Please contact the State of ' +
                  this.state +
                  ' Division of Gaming Enforcement for further information'
              );
              break;
            case 10054:
              var msg = await this.transService.getTranslationWord('AUTH.LOGIN.ERRORS.NOT_ACTIVE');
              error.setErrorMessage(msg);
              break;
            case 10047:
              var msg = await this.transService.getTranslationWord('AUTH.LOGIN.ERRORS.PERMANENT_EXCLUDE');
              error.setErrorMessage(msg);
              let page = PopoverFactory('SelfExcludePopover');
              this.popoverCtrl
                .create({
                  component: page,
                  componentProps: {},
                  showBackdrop: false,
                  cssClass: 'standard-popover',
                })
                .then((popover) => popover.present());
              break;
            case 10048:
              var msg = await this.transService.getTranslationWord('AUTH.LOGIN.ERRORS.SUSPENDED');
              error.setErrorMessage(msg);
              break;
            case 10049:
              var msg = await this.transService.getTranslationWord('AUTH.LOGIN.ERRORS.TEMPORARY_SUSPENDED');
              error.setErrorMessage(msg);
              break;
            case 10271:
              error.setErrorMessage(error.error_payload);
              // this.comService.showToast(error.error_payload, 'error_toast');
              break;
            default:
              var msg = await this.transService.getTranslationWord('AUTH.LOGIN.ERRORS.ERROR_OCCURED');
              error.setErrorMessage(msg);
              break;
          }
          this.events.publishSomeData({
            event: 'event:login:error',
            data: {
              errorCode: error.error_code,
              errorName: error.getErrorMessage(),
            },
          });
          // pass error object to page
          this.error = error;
          console.log('POST ERROR', this.loginForm);
        }
      );
    }
  }

  private doMFA = () => {
    this.afterLoginActions();
  };

  private checkLoginActions = () => {
    this.afterLoginActions();
  };

  private afterLoginActions = () => {
    this.userSession.refreshCustomer().subscribe((customer: CustomerModel) => {

      this.mainProvider.setCurrentLoginTime(customer.currentLoginTime);

      this.events.publishSomeData({
        event: 'event:login',
        data: { customer_id: customer.customerId },
      });
      this.events.publishSomeData({ event: 'event:update:banners' });

      Navigator.toHomePage();

      this.initToastMessages();

      let params = !!this.depositCode ? { depositCode: this.depositCode } : {};
      this.events.publishSomeData({
        event: 'event:appInit:init',
        data: params,
      });
    });
  };

  initToastMessages() {
    this.userSession
      .getCustomer()
      .subscribe((customer) => {
        if (customer.lastLoginTime) {
          this.toastMessageQue.push('LASTLOGIN');
        }
        if (customer.sendEmailUponLogin) {
          if (customer.sendEmailUponLogin == true) {
            this.toastMessageQue.push('OPTEDINEMAIL');
          }
        }
        this.runToastMessages();
      })
      .unsubscribe();
  }

  runToastMessages() {
    if (this.toastMessageQue.length) {
      let e = this.toastMessageQue[0];
      this.toastMessageQue.shift();
      this.showToastMessages(e);
    }
  }

  async showToastMessages(e) {
    let toast;

    switch (e) {
      case 'LASTLOGIN':
        this.userSession
          .getCustomer()
          .subscribe(async (customer) => {
            // let date = this.utils.toAmericanDateStandardfromYYYY(
            //   customer.lastLoginTime
            // );
            let date = this.comService.timeFormat2(customer.lastLoginTime || customer.currentLoginTime);
            console.log('last login time: ', date);
            var msg = await this.transService.getTranslationWord('AUTH.LOGIN.ERRORS.SUCCESS_LOGIN');
            this.comService.showToast(msg);
          })
          .unsubscribe();
        break;
      case 'INACTIVITY':
        var msg = await this.transService.getTranslationWord('AUTH.LOGIN.ERRORS.INACTIVITY');
        this.comService.showToast(msg);
        break;
      case 'OPTEDINEMAIL':
        break;
    }

    setTimeout(() => {
      this.runToastMessages();
    }, 3500);
  }

  onForgotPassword() {
    Navigator.back();
    Navigator.navigateTo('ForgotPasswordPage', { forceModal: true, cssClass: 'forgot_modal' });
    return false;
  }

  onClose() {
    console.log('onclose');
    Navigator.toLobby();
  }
  onSignup() {
    var code = null;
    if (localStorage.getItem('b_referral_code')) {
      var temp = localStorage.getItem('b_referral_code');
      code = temp.split(':')[0];
    }
    if (this.comService.isMobileWeb) {
      this.events.publishSomeData({event: 'show:signup'});
    } else {
      Navigator.back();
      Navigator.navigateTo("SignupStepOnePage", { forceModal: true, cssClass: 'signup_modal', disableBackdrop: true }, { signupCode: code });
    }
  }

  onBack = () => {
    if (this.emailisSent) {
      this.hideBackButton = false;
      this.tooManyLoginAttemps = true;
      this.emailisSent = false;
    } else {
      this.hideBackButton = true;
      this.tooManyLoginAttemps = false;
      this.emailisSent = false;
    }
  };

  changeTypeOfPassword() {
    if (this.passType == 'password') this.passType = 'text';
    else this.passType = 'password';
  }

  ekeyup($event) {
    var code = $event.keyCode;
    if (code == 13) {
      // this.pwdInput.setFocus();
    } else {
      this.clearError();
    }
  }

  keyup($event) {
    var code = $event.keyCode;
    if (code == 13) {
      this.doLogin();
    } else {
      this.clearError();
    }
  }

  clearError() {
    this.error = null;
    this.errorCode = null;
  }

  async showSignup() {
    this.screenType = 'signup';
    await this.slides.lockSwipes(false);
    this.slides.slideNext();
    await this.slides.lockSwipes(true);
  }

  async showLogin() {
    this.screenType = 'login';
    await this.slides.lockSwipes(false);
    this.slides.slidePrev();
    await this.slides.lockSwipes(true);
  }

  googleLogin() {
    this.authProvider.googleLoginFunc();
  }

  changeSigninType(type) {
    this.signinType = type;
    this.clearError();

    this.loginForm.get('password').markAsPristine();
    this.loginForm.get('password').markAsUntouched();
    this.loginForm.get('password').patchValue('');

    if (type == 'email') {
      this.loginForm.removeControl('phone');
      this.phone = null;

      this.loginForm.addControl('username', new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('^([0-9a-zA-Z]+[-._+&amp;])*[0-9a-zA-Z]+@([-0-9a-zA-Z]+[.])+[a-zA-Z]{2,6}$')
      ]));
      this.email = this.loginForm.get('username');

      this.loginForm.reset();

      if (localStorage.getItem('BP_last_login_username')) {
        if (this.emailInput) this.emailInput.value = localStorage.getItem('BP_last_login_username');
        this.email.patchValue(localStorage.getItem('BP_last_login_username'));
      }
    }
    else {
      this.loginForm.removeControl('username');
      this.email = null;

      this.loginForm.addControl('phone', new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+$'),
        Validators.minLength(8),
        Validators.maxLength(20)
      ]));
      this.phone = this.loginForm.get('phone');

      this.loginForm.reset();
    }

    this.showCountrySelection = false;
  }

  showCountries() {
    this.showCountrySelection = !this.showCountrySelection;
  }

  changeCountry($event) {
    this.countryCode = $event.country_code;
    this.showCountrySelection = !this.showCountrySelection;
  }

  clickOutside() {
    setTimeout(() => {
      this.showCountrySelection = false;
    }, 670);
  }
}
