// declare global {
//     interface Window { xtremepush: any;XtremePush:any; }
// }
declare var window: any;
declare let XtremePush: any;
declare let cordova: any;

import { UserSessionProvider } from './../../system/providers/user-session';

import { Injectable } from '@angular/core';
import {
  AffiliateTrackerInterface,
  AffiliateTrackerCallbackParams,
} from './affiliation-event';
import { Config } from './../../system/providers/configuration';
import { GoogleToolsProvider } from './googleTools';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class XtremepushTracker implements AffiliateTrackerInterface {

  protected configVars: any;
  protected params: any;
  private isTrackerSetup: boolean = false;
  private customer;
  private trackerData = null;
  private brand: String = '';
  private jurisdiction: String = '';

  constructor(
    private googleTools: GoogleToolsProvider,
    private config: Config,
    private userSession: UserSessionProvider,
    private platform: Platform
  ) {
    this.userSession.getCustomer().subscribe((customer) => {
      if (customer) {
        this.customer = customer;
      }
    });
  }

  initialize(): Promise<AffiliateTrackerInterface> {
    this.setup();
    return Promise.resolve(this);
  }

  login(cbParams: AffiliateTrackerCallbackParams) {
    // console.log(cbParams);
    var eventData = {
      type: 'loginSuccess',
      detail: {
        brand: this.brand,
        timestamp: new Date().getTime(),
        userId: this.formatId(cbParams.customer_id),
      },
    };

    if (this.isTrackerSetup) {
      if (this.platform.is('cordova')) {
        //XtremePush.setExternalId(cbParams.customer_id);
        XtremePush.setExternalId(this.formatId(cbParams.customer_id));
      } else {
        window.xtremepush(
          'set',
          'user_id',
          this.formatId(cbParams.customer_id)
        );
      }
    }

    this.track(eventData);
  }

  logout() {
    var eventData = {
      type: 'logout',
    };

    this.track(eventData);
  }

  loginError(cbParams: AffiliateTrackerCallbackParams) {
    var eventData = {
      type: 'error',
      detail: {
        brand: this.brand,
        errorCode: cbParams.errorCode,
        errorName: cbParams.errorName,
        errorType: 'loginError',
        timestamp: new Date().getTime(),
      },
    };
    this.track(eventData);
  }

  forgotPassword(cbParams: AffiliateTrackerCallbackParams) {
    // Forgot Password - Customer completed the forgot password process
    var eventData = {
      type: 'forgotPassword',
      detail: {
        brand: this.brand,
        timestamp: new Date().getTime(),
      },
    };
    this.track(eventData);
  }

  closeAccount(cbParams: AffiliateTrackerCallbackParams) {
    // Close Account - Customer completed the close account process
    let customerId = cbParams.customer_id;
    if (!customerId && !!this.customer) {
      customerId = this.customer.customerId;
    }
    var eventData = {
      type: 'closeAccount',
      detail: {
        brand: this.brand,
        clientId: '',
        cms: '',
        currency: this.config.getConfig().DEFAULT_CURRENCY,
        jurisdiction: '',
        locale: '',
        timestamp: new Date().getTime(), //this is client timestamp Math.round(+new Date()/1000);
        userId: this.formatId(customerId), //id of a logged in user, this must allow to uniquely identify user
        reason: 'PLAYER_CLOSE_ACCOUNT', // This is to capture the reason that the customer selected to close the account. This needs to be in English for all Markets.
      },
    };
    this.track(eventData);
  }

  sendPageHistoryEvent(pageName) {
    let websiteUrl = document.location.origin;
    let virtualRoute = `${websiteUrl}/${pageName} `;
    let data = {
      type: 'pageView',
      url: virtualRoute,
    };
    this.track(data);
  }

  signupComplete(cbParams: AffiliateTrackerCallbackParams) {
    var eventData = {
      type: 'registrationSuccessful',
      detail: {
        timestamp: new Date().getTime(),
        userId: this.formatId(cbParams.customer_id),
        offerId: !!cbParams.offer ? cbParams.offer.campaignId : '', //set to the offer ID that was accepted during the registration - If applicable.
        offerName:
          !!cbParams.offer && 'description' in cbParams.offer
            ? cbParams.offer.description
            : '', //set to the offer Name that was accepted during the registration - If applicable.
        //promoCode: (!!cbParams.offer && 'signupcode' in cbParams.offer) ? cbParams.offer.signupcode: "", // set to the promo code that was input during the registration - If applicable.
        communication_optin: '', // set to true or false if customer has opted in jurisdictioning communications - If applicable.
        registration_method: 'regular', // 'trustly_paynplay', 'bankid', 'tupas', 'regular'
        promoCode: cbParams.promo_code,
      },
    };
    this.track(eventData);
  }

  deposit(cbParams: AffiliateTrackerCallbackParams) {
    let customerId = 'customer_id' in cbParams ? cbParams.customer_id : '';
    let sessionToken = 'sessionToken' in cbParams ? cbParams.sessionToken : '';
    this.googleTools
      .getEventData('deposit', customerId, sessionToken)
      .then((eventInfo: any) => {
        var eventData = {
          type: 'depositCompleted',
          detail: {
            userId: this.formatId(customerId),
            brand: this.brand,
            status: 'Completed', // Fixed value to indicate that the deposit is completed successfully.
            amount: cbParams.amount, // Amount of money deposited on the customer's wallet. The value should be always
            // numeric, two decimal digits, dot used as decimal separator always.
            currency: this.config.getConfig().DEFAULT_CURRENCY, //"[USD|GBP|...]", // Currency used during the deposit. This should reflect the currency used
            // by the customer.
            paymentTransactionId: cbParams.transaction_id, // Unique transaction ID generated during the deposit operation
            noOfDeposits: eventInfo.transactionNumber, // (!) The number of deposit that this customer made during his lifetime as a
            // customer. Needs to start at 1 and increment by 1 on each deposit performed by the customer. IMPORTANT!
            paymentMethod: cbParams.paymentMethod, //[DEBIT_CARD | CREDIT_CARD | PAYPAL |...], // Identifier to indicate the payment method used
            // by the customer
            firstDeposit: ~~eventInfo.transactionNumber > 0 ? false : true, // Boolean flag if this is the first deposit of the customer
            bonusCode:
              cbParams.bonusCode == null || cbParams.bonusCode == undefined
                ? 'N/A'
                : cbParams.bonusCode,
            bonusID:
              cbParams.bonusID == null || cbParams.bonusID == undefined
                ? 'N/A'
                : cbParams.bonusID,
          },
        };
        this.track(eventData);
      });
  }

  withdraw(cbParams: AffiliateTrackerCallbackParams) {}

  gameLaunched(cbParams: AffiliateTrackerCallbackParams) {
    let eventData = {
      type: 'gameLaunch',
      detail: {
        customerId:
          'customer_id' in cbParams ? this.formatId(cbParams.customer_id) : '',
        timestamp: Date.now(),
        gameId: cbParams.game_id,
        gameName: cbParams.game_name,
        keywords: '', //in case game launch is from search, we populate this with keywords used in search
        isPlayForFun: cbParams.is_demo,
        brand: this.brand,
      },
    };
    this.track(eventData);
  }

  gameClosed() {
    let eventData = {
      type: 'gameClosed',
    };
    this.track(eventData);
  }

  addToBetslip(eventData) {
    let data = {
      type: 'sportsbook event',
      detail: eventData,
      brand: this.brand,
    };

    this.track(data);
  }

  betPlaced(eventData) {
    let data = {
      type: 'sportsbook event',
      detail: eventData,
      brand: this.brand,
    };
    this.track(data);
  }

  setup() {
    let webTrackerUrl = this.config.getXtremeWebSdkUrl(); //https://us.webpu.sh/R_g0ng4TPw-8m9SGi_C5eQ7dKPkqU6u2/sdk.js
    let isMobileApp = this.platform.is('cordova');

    if (webTrackerUrl && webTrackerUrl !== '' && !isMobileApp) {
      const script = document.createElement('script');

      script.innerHTML = `(function(p,u,s,h,e,r,l,i,b) {p['XtremePushObject']=s;p[s]=function(){
                (p[s].q=p[s].q||[]).push(arguments)};i=u.createElement('script');i.async=1;
                i.src=h;b=u.getElementsByTagName('script')[0];b.parentNode.insertBefore(i,b);
              })(window,document,'xtremepush', '${webTrackerUrl}');`;

      document.head.appendChild(script);

      this.isTrackerSetup = true;

      let appConfigInfo = this.config.getAppConfigInfo();
      this.brand = '';
      this.jurisdiction = '';
    } else {
      this.isTrackerSetup = false;
    }

    if (this.platform.is('cordova')) {
      this.platform.ready().then((readySource) => {
        this.isTrackerSetup = true;
        this.registerMobileXtremePush();
      });
    }
  }

  registerMobileXtremePush() {
    let iosAppKey = this.config.getXtremeIosAppKey();
    let androidAppKey = this.config.getXtremeAndroidAppKey(); //zsz5uDONgh734AF8otcCtCzE8pqnmjBh
    let gcmProjectNumber = this.config.getXtremeGcmProjectNumber();
    let appKey = this.platform.is('ios') ? iosAppKey : androidAppKey;

    XtremePush.register({
      appKey: appKey,
      debugLogsEnabled: true,
      inappMessagingEnabled: true,
      inboxEnabled: true,
      serverUrl: 'https://sdk.us.xtremepush.com',
      ios: {
        pushPermissionsRequest: true,
        //if using locations
        locationsEnabled: false,
        beaconsEnabled: false,
        locationsPermissionsRequest: false,
      },
      android: {
        gcmProjectNumber: gcmProjectNumber,
        //if using locations
        geoEnabled: false,
        beaconsEnabled: false,
      },
    });
  }

  private track(data?: any) {
    if (!this.isTrackerSetup) {
      console.log('tracker config not set.');
      return;
    }

    if (
      !!this.trackerData &&
      data.type == 'pageView' &&
      this.trackerData.detail.navigationState == data.detail.navigationState
    ) {
      //skip if pageView url is the same;
    } else {
      if (!this.platform.is('cordova')) {
        window.xtremepush('event', data.type, data);
      } else {
        XtremePush.hitEvent(data.type, data);
      }
    }
  }

  private getDeviceOS() {
    let OSName = '';
    let navigator = window.navigator;
    if (navigator.appVersion.indexOf('Win') != -1) OSName = 'Windows';
    if (navigator.appVersion.indexOf('Mac') != -1) OSName = 'MacOS';
    if (navigator.appVersion.indexOf('X11') != -1) OSName = 'UNIX';
    if (navigator.appVersion.indexOf('Linux') != -1) OSName = 'Linux';
    if (navigator.appVersion.indexOf('Android ') != -1) OSName = 'Android ';
    if (navigator.appVersion.indexOf('iPhone ') != -1) OSName = 'IOS ';
    return OSName;
  }

  getDeviceType() {
    let deviceType = 'dekstop';
    if (this.platform.is('mobileweb') && !this.platform.is('cordova')) {
      deviceType = 'mobileWeb';
    } else if (this.platform.is('cordova')) {
      deviceType = 'mobileApp';
    }

    if (this.platform.is('tablet')) deviceType = 'tablet';

    console.log('Device type:', deviceType);
    return deviceType;
  }

  formatId = (customerId) => {
    let prefix = '';
    let config = this.config.getAppConfigInfo();
    if (!!config && 'playerIdPrefix' in config) {
      prefix = config.playerIdPrefix;
    }

    return prefix + customerId;
  };
}
