import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Config } from './../../../system/providers/configuration';
import { UserSessionProvider } from './../../../system/providers/user-session';
// import { isUndefined } from 'util';

import { CasinoGameModel } from './../models/casino-game-model';

@Injectable({
  providedIn: 'root',
})
export class GameHub {

    private _instances: Array<any> = [];
    private _instanceSubject: Subject<any>;

    public customer;

    constructor(public config: Config, public userSessionProvider: UserSessionProvider) {
        this._instanceSubject = new Subject();
        this.userSessionProvider.getCustomer().subscribe(
            (customer) => {
                this.customer = customer;
            }
        );
    }

    private addInstance(model, data?: Array<any>) {
        let index = new Date().getTime();
        this._instances[index] = new Array();
        this._instances[index]['model'] = model;

        if (data) {
            for (var key in data) {
                let value = data[key];
                this._instances[index][key] = value;
            }
        }
        this._instanceSubject.next(this._instances); // for subscribers to this.getInstanceSubject()
        return index;
    }

    public removeInstance(index) {
      this._instances = [];
      // index = parseInt(index);
      // delete this._instances[index];
      this._instanceSubject.next(this._instances);
    }

    focusInstance(instance_id = null) {
        let instance = null;
        // make all instances not active
        this.unfocusAllInstances();

        // make selected on active
        if (instance_id !== null) {
            instance = this.getInstance(instance_id);
            if (instance) {
                instance.isActive = true;
                instance.highlighted = false;
                this._instances[instance_id] = instance;
            }
        }
    }

    unfocusInstance(instance_id = null) {
        if (instance_id !== null) {
            let instance = this.getInstance(instance_id);
            if (instance) {
                instance.isActive = false;
                this._instances[instance_id] = instance;
            }
        }
    }

    getFocusedInstance() {
        let instance = null;
        let focusedInstance = null;
        let instances = this.getAllInstances();
        if (Object.keys(instances).length) {
            for (var id in instances) {
                instance = this.getInstance(id);
                if("isActive" in instance && instance.isActive === true) focusedInstance = id;
            }

        }
        return focusedInstance;
    }

    unfocusAllInstances() {
        let instance = null;
        let instances = this.getAllInstances();
        if (Object.keys(instances).length) {
            for (var id in instances) {
                instance = this.getInstance(id);
                instance.isActive = false;
                this._instances[id] = instance;
            }
        }
    }

    // mute all games except the one passed in
    muteGames(instance_id) {
        let instances = this._instances;
        Object.keys(instances).map(instanceId => {
            if (instanceId != instance_id) {
                if (instances[instanceId].viewContainer == 'IFRAME') {
                    try {
                        let iframe: any = document.getElementById(`iframe_${instanceId}`);
                        if(iframe && iframe.contentWindow)
                            iframe.contentWindow.postMessage("setGameToBackground", '*');
                    } catch (e) {}
                }

                if (instances[instanceId].viewContainer == 'NEWWINDOW') {
                    try {
                        instances[instanceId].windowInstance.postMessage("setGameToBackground", '*');
                    }
                    catch (e) {}
                }
            } else {
                if (instances[instanceId].viewContainer == 'IFRAME') {
                    try {
                        let iframe: any = document.getElementById(`iframe_${instanceId}`);
                        if(iframe && iframe.contentWindow)
                            iframe.contentWindow.postMessage("setGameToForeground", '*');
                    } catch (e) {}
                }

                if (instances[instanceId].viewContainer == 'NEWWINDOW') {
                    try {
                        instances[instanceId].windowInstance.postMessage("setGameToForeground", '*');
                    }
                    catch (e) {}
                }
            }
        });
    }

    // mute all games except the one passed in
    muteAllGames() {
        let instances = this._instances;
        Object.keys(instances).map(instanceId => {

            if (instances[instanceId].viewContainer == 'IFRAME') {
                try {
                    let iframe: any = document.getElementById(`iframe_${instanceId}`);
                    if (iframe && iframe.contentWindow)
                        iframe.contentWindow.postMessage("setGameToBackground", '*');
                } catch (e) { }
            }

            if (instances[instanceId].viewContainer == 'NEWWINDOW') {
                try {
                    instances[instanceId].windowInstance.postMessage("setGameToBackground", '*');
                }
                catch (e) { }
            }
        });
    }


    highlightInstance(instance_id = null) {
        if ( instance_id !== null ) {
            let instance = this.getInstance(instance_id);
            if( instance.highlighted !== true ) {
                instance.highlighted = true;
                this._instances[instance_id] = instance;
                setTimeout(() => {
                    instance.highlighted = false;
                    //instance might be removed already
                    if(this._instances[instance_id])  {
                        this._instances[instance_id] = instance;
                    } {
                        instance = null;
                    }
                }, 15000)
            }
        }
    }

    unHighlightInstance(instance_id = null) {
        if ( instance_id !== null ) {
            let instance = this.getInstance(instance_id);
            instance.highlighted = false;
            this._instances[instance_id] = instance;
        }
    }

    getInstance(id) {
        return this._instances[id];
    }

    updateInstance(instance_id, instance) {
        this._instances[instance_id] = instance;
    }

    getAllInstances() {
        return this._instances;
    }

    getNextInstance() {
        let instances = this._instances;
        let instance_id: number = -1;
        let length = Object.keys(instances).length;
        if (length > 0) {
            instance_id = parseInt(Object.keys(this._instances)[length - 1]);
        }
        return instance_id;
    }

    instanceExists(model) {
        let instances = this._instances;
        let id = -1;
        Object.keys(instances).map(instanceId => {
            if (model.getIdentifier() == instances[instanceId].model.getIdentifier()) {
                id = parseInt(instanceId);
            }
        });
        return id;
    }

    instanceExistsByGID(gid) {
        let match = false;
        let instances = this._instances;
        Object.keys(instances).map(instanceId => {
            if (gid == instances[instanceId].model.gid) {
                match = true;
            }
        });
        return match;
    }

    /**
     *
     * Methods to add "instances" for specific types of games, sublobbies...
     */


    addCasinoGame(model: CasinoGameModel, data?: object) {
        // check if game already loaded and just show it
        let instanceId = this.instanceExists(model);
        if (instanceId >= 0) {
            return instanceId;
        } else {
            instanceId = this.addInstance(model, data as Array<any>);
            return instanceId;
        }
    }


    /**
     * end Methods for "instances"
     */

    /**
     * Subjects that code can subscribe to
     */
    getInstanceSubject() {
        return this._instanceSubject;
    }

    updateInstanceSubject() {
        this._instanceSubject.next(this._instances);
    }

    /**
     * end Subjects
     */
    // how many casino games
    totalCasinoGames() {
        return this.getAllModels().filter(model => model.instanceCategoryType === "casino-game").length;
    }

    totalInstances() {
        let total = 0;
        for (var key in this._instances) {
            total++;
        }
        return total;
    }

    getInstanceByType( instanceCategoryType ) {
        let model = this.getAllModels().filter(model => model.instanceCategoryType === instanceCategoryType);
        return this.instanceExists(model[0]);
    }

    private getAllModels() {
        let models = [];
        for (var key in this._instances) {
            models.push(this._instances[key]['model'])
        }
        return models;
    }
}
