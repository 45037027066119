import { Component, Input } from '@angular/core';

@Component({
  selector: 'note-box',
  templateUrl: 'note-box.html',
})
export class NoteBoxComponent {

  @Input() note: string;
  @Input() iconName: string;

  constructor() {}
}
