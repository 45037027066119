import { Injectable } from '@angular/core';
import { Config } from './../system/providers/configuration';
import { Platform, PopoverController } from '@ionic/angular';
import { MyEvent } from '../service/myevent.services';

@Injectable({
  providedIn: 'root',
})
export class AppActions {

  public productVerticalsPages: Array<any>;

  constructor(
    public config: Config,
    public platform: Platform,
    private events: MyEvent,
    private popoverCtrl: PopoverController
  ) {}

  loadTabOnLogout() {
    // setTimeout(() => {
    //   this.events.publishSomeData({ event: 'event:load:sports:logout' });
    // }, 500);
  }

  public isSportsApp() {
    return false;
  }
}
