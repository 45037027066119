import { NgModule } from '@angular/core';
import { WithdrawalReverseWithdrawalPage } from './withdrawal-reverse-withdrawal';
import { SystemModule } from '../../../../../system/system.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    WithdrawalReverseWithdrawalPage,
  ],
  imports: [
    CommonModule,
    IonicModule,
    SystemModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
})
export class WithdrawalReverseWithdrawalPageModule {}
