import { Component, Input, OnInit } from '@angular/core';
import { Navigator } from 'src/app/system/components/menu/navigator';

@Component({
  selector: 'app-ref-with-depo',
  templateUrl: './ref-with-depo.component.html',
  styleUrls: ['./ref-with-depo.component.scss'],
})
export class RefWithDepoComponent implements OnInit {

  @Input() title: any;
  @Input() message: any;
  @Input() buttonTitle: any;
  @Input() action: any;
  @Input() showHeader = false;

  constructor() { }

  ngOnInit() {}

  close() {
    Navigator.back();
  }

}
