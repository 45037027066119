import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightSearch',
})
export class HighlightSearch implements PipeTransform {
  /**
   * Highlights a found string
   * Can be applied to search summary and has to be bound to innerHTML
   * ex. <div [innerHTML]= 'item.name | highlightSearch : keyWordsArray'></div>
   */
  transform(str: string, search): string {
    if (search && typeof search !== 'object' && str) {
      let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
      pattern = pattern.split(' ').filter((t) => {
        return t.length > 0;
      }).join('|');
      const regex = new RegExp(pattern, 'gi');

      return str.replace(regex, (strmatch) => `<span class="search-highlight">${strmatch}</span>`);
    } else {
      return str;
    }
  }
}
