import { Utils } from './../../utils';
import { Navigator } from './../../../system/components/menu/navigator';
import { Component, OnInit } from '@angular/core';
import { AffiliationEventProvider } from '../../../shared/providers/affiliation-event';
import { Config } from '../../../system/providers/configuration';
import { pathnameRegistered } from './../../../system/components/menu/stringToClassMapper';
import { MyEvent } from 'src/app/service/myevent.services';

@Component({
  selector: 'redirect',
  templateUrl: 'redirect.html',
  styleUrls: ['./redirect.scss']
})
export class RedirectComponent implements OnInit {

  href = "";

  constructor(
    public events: MyEvent,
    protected config: Config,
    protected utils: Utils,
    protected affiliationEvent: AffiliationEventProvider,
  ) { }

  ngOnInit() {    
    window.addEventListener('popstate', (event) => {
      let pathname = document.location.pathname;
      //back will not work on sports pages due to: KND-663
      if(!~pathname.indexOf('sports')) {
        let href = window.location.href;
        if( this.href != href ) {
          this.href = href;
          window.location.replace(document.location.toString());
        }
      }
    });

    let dialog = this.utils.getHashParam('load-dialog');
    console.log("   DIALOG1 = ", dialog);
  }
}
