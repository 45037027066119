import { Component, OnInit } from '@angular/core';
import { ResponsibleGamingProvider } from '../../../providers/responsible-gaming-provider';
import { Utils } from '../../../../../shared/utils';
import { Config } from '../../../../../system/providers/configuration';

@Component({
  selector: 'page-limits-history',
  templateUrl: 'limits-history.html',
  styleUrls: ['./limits-history.scss'],
})
export class LimitsHistoryPage implements OnInit {

  limitTypeArray: string[] = [
    'DEPOSIT_LIMIT',
    'LOSS',
    'TIME',
    'WAGER',
    'SPEND',
  ];
  limitTypeSelected: string = this.limitTypeArray[0];
  limitsHistory: any[] = [];
  filteredLimitHistory: any[] = [];
  showTable: boolean = false;
  error = null;

  public spendLimitFlag: boolean = false;

  constructor(
    private config: Config,
    private responsibleGamingProvider: ResponsibleGamingProvider,
    public utils: Utils
  ) {
    this.getLimitHistory(this.limitTypeArray[0]);
  }

  ngOnInit(): void {}

  ionViewDidEnter() {
    let appConfigInfo = this.config.getAppConfigInfo();
    let spendLimitAvailable = appConfigInfo.show_spend_limit;

    if (spendLimitAvailable == 'true') {
      this.spendLimitFlag = true;
    }
  }

  getLimitHistory(limitType: string) {
    this.limitTypeSelected = limitType;
    let limitSubType: string;

    //This code below looks a bit confusing but has to be done because both SPEND and WAGER limits use "WAGER" as the LIMIT TYPE from
    //the service call, so we have to differentiate between the TERM TYPE "SINGLE" which belongs to "WAGER" and the term types "DAILY/WEEKLY/MONTHLY"
    //which belong to "SPEND"

    if (limitType == 'SPEND') {
      limitType = 'WAGER';
      limitSubType = 'SPEND';
    } else if (limitType == 'WAGER') {
      limitSubType = 'WAGER';
    }

    this.responsibleGamingProvider.getLimitHistory().subscribe(
      (response: any) => {
        if (response && !!response.result) {
          let tempLimitsHistory: any = response.payload;
          this.limitsHistory = [];
          for (let i in tempLimitsHistory) {
            if (tempLimitsHistory[i] != null) {
              if (tempLimitsHistory[i].limitType === limitType) {
                if (tempLimitsHistory[i].limitSource != 'GLOBAL') {
                  if (limitSubType == 'SPEND') {
                    if (tempLimitsHistory[i].currentLimitTermType != 'SINGLE') {
                      this.limitsHistory.push(tempLimitsHistory[i]);
                    }
                  } else if (limitSubType == 'WAGER') {
                    if (tempLimitsHistory[i].currentLimitTermType == 'SINGLE') {
                      this.limitsHistory.push(tempLimitsHistory[i]);
                    }
                  } else {
                    this.limitsHistory.push(tempLimitsHistory[i]);
                  }
                }
              }
            }
          }

          //remove duplicated values
          this.filteredLimitHistory = this.limitsHistory.filter(
            (item, index, array) =>
              array.findIndex((pos) => {
                return (
                  pos.newLimit === item.newLimit &&
                  pos.currentLimitTermType === item.currentLimitTermType &&
                  pos.startPeriod === item.startPeriod &&
                  pos.endPeriod === item.endPeriod
                );
              }) === index
          );

          if (this.filteredLimitHistory.length > 0) {
            this.showTable = true;
          } else {
            this.showTable = false;
          }
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }

}
