import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { BannerProvider } from 'src/app/modules/lobby/providers/banner.provider';
import { RewardsProvider } from 'src/app/modules/rewards/providers/rewards-provider';
import { CommonService } from 'src/app/service/common.service';
import { MyEvent } from 'src/app/service/myevent.services';
import { TranslationService } from 'src/app/service/translation.service';
import { AppLinks } from 'src/app/shared/app-links';
import { Utils } from 'src/app/shared/utils';
import { Config } from 'src/app/system/providers/configuration';
import { UserSessionProvider } from 'src/app/system/providers/user-session';

@Component({
  selector: 'app-bonuses',
  templateUrl: './bonuses.page.html',
  styleUrls: ['./bonuses.page.scss'],
})
export class BonusesPage implements OnInit, OnDestroy {

  bonuses: any[] = [];
  public error = null;
  public showNoBonusText = false;
  customSub: any[] = [];

  appConfigInfo: any;

  constructor(
    public utils: Utils,
    private appLinks: AppLinks,
    public rewardsProvider: RewardsProvider,
    public comService: CommonService,
    private events: MyEvent,
    private navCtrl: NavController,
    private config: Config,
    private bannerProvider: BannerProvider,
    public userSession: UserSessionProvider,
    private cdr: ChangeDetectorRef,
    private transService: TranslationService
  ) { }

  ngOnInit() {
    this.appConfigInfo = this.config.getAppConfigInfo();

    this.customSub.push(this.userSession.getCustomer().subscribe((customer) => {
      this.error = null;
      this.showNoBonusText = false;
      this.bonuses = [];
      if (!customer) {
        this.onGetBonusOffers();
      } else {
        this.getBannerOffers();
      }
    }));

    this.customSub.push(this.transService.changeLangSubject.subscribe(lang => {
      if (this.userSession.isAuthenticated()) {
        this.getBannerOffers();
      } else {
        this.onGetBonusOffers();
      }
    }));
  }

  ionViewDidEnter() {
    this.setMeta();
  }

  setMeta() {
    if (!this.appConfigInfo['UPDATE_META'] || !this.appConfigInfo['UPDATE_META']['enable']) return;
    if (!this.appConfigInfo['UPDATE_META']['promotions']) return;

    var title, mTitle, oTitle, desc, oDesc, oUrl, keywords;

    title = this.appConfigInfo['UPDATE_META']['promotions']['title'];
    mTitle = this.appConfigInfo['UPDATE_META']['promotions']['mtitle'];
    oTitle = this.appConfigInfo['UPDATE_META']['promotions']['otitle'];
    desc = this.appConfigInfo['UPDATE_META']['promotions']['description'];
    oDesc = this.appConfigInfo['UPDATE_META']['promotions']['description'];
    keywords = this.appConfigInfo['UPDATE_META']['promotions']['keyword'];
    oUrl = window.location.origin + '/bonuses';

    this.comService.updateMetaTags(title, mTitle, oTitle, desc, oDesc, oUrl, keywords);
  }

  ionViewWillLeave() {

  }

  ngOnDestroy(): void {
    this.customSub.forEach(sub => {
      sub.unsubscribe();
    });
    this.customSub = [];
  }

  onGetBonusOffers(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.rewardsProvider.callGetBonusOffers({
        client_app_id: this.config.getClientApp(),
      }).subscribe(
        (response: any) => {
          this.showNoBonusText = true;
          console.log('onGetBonusOffers == ', response);
          if (response.code == 200 && response.result) {
            let data = (response.payload as Array<any>);
            this.bonuses = data;
            this.cdr.detectChanges();
            resolve(data);
          } else {
            resolve([]);
          }
        },
        (error) => {
          this.showNoBonusText = true;
          this.error = error;
          console.error('onGetBonusOffers error == ', error);
          resolve([]);
        }
      );
    });
  }

  getBannerOffers(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const lobbyName = this.config.getConfig()?.LOBBY_NAME;
      this.bannerProvider.getBanners(lobbyName).then(
        (res) => {
          this.showNoBonusText = true;
          console.log('banner res == ', res);
          if (res.code == 200 && res.result) {
            let data = (res.payload as Array<any>);
            this.bonuses = data;
            this.cdr.detectChanges();
            resolve(data);
          } else {
            resolve([]);
          }
        }
      ).catch(err => {
        this.showNoBonusText = true;
        console.error('banner err == ', err);
        resolve([]);
      });
    });
  }

  removeAnouncments = (item) =>
    item.campaign_type !== 'ANNOUNCEMENT' &&
    item.campaign_code != 'ZZ_NO_DEPOSIT' &&
    item.campaign_code != 'ZZ_NO_WELCOME';

  goBack() {
    this.events.publishSomeData({event: 'event:tabs:load', tab: 'dashboard'});
  }

  showDetail(bonus) {
    this.comService.sharedBonuseDetail = bonus;
    this.navCtrl.navigateForward(`${this.comService.currentTemplate}/bonus-detail/${bonus.id}`);
  }
}
