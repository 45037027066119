import { Component, OnInit } from '@angular/core';
import { Navigator } from 'src/app/system/components/menu/navigator';

@Component({
  selector: 'app-acheive-bonus',
  templateUrl: './acheive-bonus.component.html',
  styleUrls: ['./acheive-bonus.component.scss'],
})
export class AcheiveBonusComponent implements OnInit {

  levels: any[] = [
    {level: 1, people: 5, amount: 800},
    {level: 2, people: 20, amount: 4000},
    {level: 3, people: 50, amount: 12000},
    {level: 4, people: 100, amount: 28000},
    {level: 5, people: 500, amount: 110000},
    {level: 6, people: 1000, amount: 260000},
    {level: 7, people: 3000, amount: 730000},
    {level: 8, people: 5000, amount: 1520000},
    {level: 9, people: 10000, amount: 3100000},
    {level: 10, people: 50000, amount: 10950000},
    {level: 11, people: 100000, amount: 26670000},
    {level: 12, people: 200000, amount: 58110000}
  ];

  constructor() { }

  ngOnInit() {}

  close() {
    Navigator.back();
  }

}
