import { Component, Input, OnInit } from '@angular/core';
import { Navigator } from 'src/app/system/components/menu/navigator';
import { Config } from 'src/app/system/providers/configuration';

@Component({
  selector: 'app-logout-confirm',
  templateUrl: './logout-confirm.component.html',
  styleUrls: ['./logout-confirm.component.scss'],
})
export class LogoutConfirmComponent implements OnInit {

  @Input() reason: any;

  bannerImg: any

  constructor(
    private config: Config,
  ) { }

  ngOnInit() {
    this.bannerImg = this.config.getAppConfigInfo()['signout_banner'] || '';
  }

  close() {
    Navigator.back();
  }

  logout() {
    Navigator.back();
    Navigator.navigateTo("LogoutPage", {}, { reason: this.reason });
  }

}
