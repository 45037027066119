import { Config } from '../../../../../system/providers/configuration';
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ResponsibleGamingProvider } from '../../../providers/responsible-gaming-provider';
import { SuccessMessageProvider } from './../../../../../system/providers/success-message-provider';
import { Utils } from '../../../../../shared/utils';
import { Navigator } from './../../../../../system/components/menu/navigator';
import { IonContent, Platform } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';

@Component({
  selector: 'page-set-time-limits',
  templateUrl: 'set-time-limits.html',
  styleUrls: ['./set-time-limits.scss'],
})
export class SetTimeLimitsPage implements OnInit {

  @Input() data: any;

  @ViewChild('Content') content: IonContent;

  timeCurrentLimit = '(No Limit Set)';
  timeDelete: boolean;
  timeRolloverPeriod: boolean;
  timeRolloverPeriodContent: any;
  timeRemoved: boolean;
  timeRemovedContent: any;
  timeAmountUsed: boolean;
  timeAmountUsedContent: any;
  timeNewLimit: boolean = true;
  timeNewLimitError: boolean;
  timeNewLimitContent: any;
  timeNewLimitEffective: boolean;
  timeNewLimitEffectiveContent: any;

  public error = null;

  public timeLimitForm: FormGroup;

  public time_limit: AbstractControl;

  public timeLimits: any[] = [];

  public globalDailyLimit: any;

  public globalLimits: any[] = [];

  public totalLoginTime: string | number;

  public hours: any[] = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23,
  ];

  public isMobile: boolean = false;

  public previousPage: string = '';

  public confirm: boolean = false;
  public successScreen: boolean = true;

  public deletePrompt: boolean = false;
  public deletePromptScreen: boolean = true;
  public deleteTerm: string;

  public rejectPrompt: boolean = false;
  public rejectPromptScreen: boolean = true;

  public state: string;

  constructor(
    private config: Config,
    private responsibleGamingProvider: ResponsibleGamingProvider,
    public successMessageProvider: SuccessMessageProvider,
    private platform: Platform,
    private utils: Utils,
    public events: MyEvent
  ) {
    this.detectMobile();

    this.successMessageProvider.clearMessage();
    this.error = null;
  }

  ngOnInit() {
    let appConfigInfo = this.config.getAppConfigInfo();
    this.state = appConfigInfo.state.toLowerCase();

    this.timeLimitForm = new FormGroup(
      {
        time_limit: new FormControl('', [Validators.pattern('^[0-9]*')]),
      },
      this.atLeastOneFieldValidator
    );

    this.time_limit = this.timeLimitForm.get('time_limit');

    this.getGlobalLimits();
    this.getTotalLoginTime();

    this.previousPage = this.data && this.data.previousPage ? this.data.previousPage : '';
  }

  detectMobile() {
    if (this.platform.is('mobile')) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  getGlobalLimits() {
    this.responsibleGamingProvider.getGlobalLimits().subscribe(
      (response: any) => {
        if (response && !!response.result) {
          this.globalLimits = response.payload.filter((limit) => {
            return limit.limitType == 'TIME';
          });

          // console.log("time getGlobalLimits = ", this.globalLimits);

          this.globalLimits.forEach((limit) => {
            if (limit.limitTermType == 'DAILY') {
              this.globalDailyLimit = limit;
              this.time_limit.setValidators([
                Validators.pattern('^[0-9]*'),
                Validators.max(this.globalDailyLimit.currentLimit),
              ]);
            }
          });
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }

  getTotalLoginTime() {
    this.responsibleGamingProvider
      .getTotalLoginTime(this.config.getClientApp())
      .subscribe(
        (response: any) => {
          if (response && !!response.result) {
            this.totalLoginTime = this.secondsToTime(
              parseInt(response.payload.time)
            );

            this.getTimeLimits();
          }
        },
        (error) => {
          this.error = error;
        }
      );
  }

  secondsToTime(seconds: any) {
    let h: string | number = Math.floor(seconds / 3600);
    let m: string | number = Math.floor((seconds - h * 3600) / 60);
    let s: string | number = seconds - h * 3600 - m * 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;
    return `${h}:${m}:${s}`;
  }

  getTimeLimits() {
    var tempLimits: any[] = [];

    this.responsibleGamingProvider.getTimeLimit().subscribe(
      (response: any) => {
        if (response && !!response.result) {
          tempLimits = response.payload;

          // console.log("getTimeLimits = ", tempLimits);

          for (let index in tempLimits) {
            if (tempLimits[index].limitSource != 'GLOBAL') {
              tempLimits[index].cumulativeLimitValue = this.totalLoginTime;
              this.timeLimits.push(tempLimits[index]);
            }
          }

          this.updateTimeLimits(this.timeLimits);
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }

  updateTimeLimits(timeLimits: any[]) {
    this.time_limit.setValue('');
    this.time_limit.markAsPristine();

    this.timeNewLimitError = false;

    for (let i in timeLimits) {
      if (timeLimits[i] != null) {
        if (timeLimits[i].currentLimitTermType == 'DAILY') {
          if (
            timeLimits[i].currentLimit != null ||
            timeLimits[i].currentLimit != ''
          ) {
            this.timeCurrentLimit = timeLimits[i].currentLimit;
            this.timeDelete = true;
          } else {
            this.timeCurrentLimit = '(No Limit Set)';
            this.timeDelete = false;
          }

          if (
            timeLimits[i].startPeriod != null &&
            timeLimits[i].endPeriod != null
          ) {
            if (timeLimits[i].cumulativeLimitValue == null) {
              timeLimits[i].cumulativeLimitValue = '0';
            }

            this.timeRolloverPeriod = true;
            this.timeRolloverPeriodContent =
              this.utils.toAmericanDateStandardfromYYYY(
                timeLimits[i].startPeriod
              ) +
              ' TO ' +
              this.utils.toAmericanDateStandardfromYYYY(
                timeLimits[i].endPeriod
              );

            this.timeAmountUsed = true;
            this.timeAmountUsedContent = timeLimits[i].cumulativeLimitValue;
          }

          if (timeLimits[i].newLimit && timeLimits[i].endPeriod) {
            this.timeDelete = false;
            this.timeNewLimit = true;
            this.timeNewLimitContent = timeLimits[i].newLimit;
            this.timeNewLimitEffective = true;
            this.timeNewLimitEffectiveContent =
              this.utils.toAmericanDateStandardfromYYYY(
                timeLimits[i].endPeriod
              );
            this.timeRemoved = false;
          } else if (
            !timeLimits[i].newLimit &&
            timeLimits[i].deleteFlag == '1'
          ) {
            this.timeDelete = false;
            this.timeNewLimit = false;
            this.timeNewLimitEffective = false;
            this.timeRemoved = true;
            this.timeRemovedContent =
              this.utils.toAmericanDateStandardfromYYYY(
                timeLimits[i].endPeriod
              );
          }
        }
      }
    }
  }

  deleteLimit(term) {
    this.successMessageProvider.clearMessage();
    this.error = null;

    this.deletePrompt = false;
    this.deletePromptScreen = false;

    var requestObj: any = {};
    requestObj.termType = term.toUpperCase();

    this.responsibleGamingProvider.deleteTimeLimit(requestObj).subscribe(
      (response: any) => {
        if (response && !!response.result) {
          this.getTotalLoginTime();
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }

  setLimits() {
    this.successMessageProvider.clearMessage();
    this.error = null;

    this.successScreen = false;
    this.confirm = false;

    this.responsibleGamingProvider
      .setTimeLimit(this.timeLimitForm.value)
      .subscribe(
        (response: any) => {
          console.log('setTimeLimit response: ', response);
          if (response && !!response.result) {
            this.getTotalLoginTime();

            this.successMessageProvider.setMessage('Your time limit was set.');

            this.content.scrollToTop();
          }
        },
        (error) => {
          console.error('setTimeLimit error: ', error);

          switch (error.error_code) {
            case '':
              error.setErrorMessage('');
              break;
          }
          this.error = error;
          this.content.scrollToTop();
        }
      );
  }

  dailyLimitChanged() {
    if (this.globalDailyLimit != null) {
      if (
        parseInt(this.time_limit.value) >
        parseInt(this.globalDailyLimit.currentLimit)
      ) {
        this.timeNewLimitError = true;
      } else {
        this.timeNewLimitError = false;
      }
    }
  }

  atLeastOneFieldValidator(group: FormGroup): { [key: string]: any } {
    let isAtLeastOne = false;
    if (group && group.controls) {
      for (const control in group.controls) {
        if (
          group.controls.hasOwnProperty(control) &&
          group.controls[control].valid &&
          group.controls[control].value
        ) {
          isAtLeastOne = true;
          break;
        }
      }
    }
    return isAtLeastOne ? null : { required: true };
  }

  closeModal = () => {
    if (this.previousPage && this.previousPage == 'SignupResult') {
      setTimeout(() => {
        this.events.publishSomeData({event: 'event:load:sports:signup'});
      }, 500);
    } else {
      Navigator.toLobby();
    }
  };

  onButtonClick() {
    this.confirm = true;
    this.successScreen = true;
    this.successMessageProvider.clearMessage();
  }

  enterPressed() {
    if (!this.timeLimitForm.invalid) {
      this.onButtonClick();
    }
  }

  preventDefault(event) {
    event.preventDefault();
  }

  onDeleteLimitClicked(term) {
    this.deletePrompt = true;
    this.deletePromptScreen = true;
    this.deleteTerm = term;
    this.successMessageProvider.clearMessage();
  }

  onDeleteCancel() {
    this.onCancel();
  }

  onDeleteConfirm() {
    this.deleteLimit(this.deleteTerm);
  }

  onRejectClicked() {
    this.confirm = false;
    this.successScreen = false;
    this.rejectPrompt = true;
    this.rejectPromptScreen = true;
    this.successMessageProvider.clearMessage();
  }

  onCancelReject() {
    this.confirm = true;
    this.successScreen = true;
    this.rejectPrompt = false;
    this.rejectPromptScreen = false;
    this.successMessageProvider.clearMessage();
  }

  onRejectConfirm() {
    this.onCancel();
  }

  onCancel() {
    this.confirm = false;
    this.deletePrompt = false;
    this.rejectPrompt = false;
    this.getGlobalLimits();
    this.getTotalLoginTime();
  }

  onBack = () => {
    if (this.confirm || this.rejectPrompt || this.deletePrompt) {
      this.confirm = false;
      this.deletePrompt = false;
      this.rejectPrompt = false;
      this.getGlobalLimits();
      this.getTotalLoginTime();
    } else {
      Navigator.back();
    }
  };
}
