import { Component, Input } from '@angular/core';

/**
 * Generated class for the ErrorComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'errorComponent',
  templateUrl: 'error-component.html',
  styleUrls:['./error-component.scss']
})
export class ErrorComponent {

  @Input() errorObject: any;

  constructor() {}
}
