import { SystemModule } from './../../../../system/system.module';
import { NgModule } from '@angular/core';
import { ForgotPasswordPage } from './forgot-password';
import { SharedModule } from '../../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [
    ForgotPasswordPage,
  ],
  imports: [
    IonicModule,
    SharedModule,
    SystemModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    ComponentsModule,
    TranslateModule
  ],
})
export class ForgotPasswordPageModule {}
