import { BaseModel } from './base-model';
import { CustomerModel } from './../../account/models/customer-model';

export class CasinoGameModel extends BaseModel {

  private customer;
  private config;
  private userSessionProvider;
  public is_demo;
  public id: number;
  public casino_launch_id: number;
  public game_name: string;
  public url: any;
  public isMobileApp: boolean;
  public currency: any;

  constructor(
    data,
    config,
    customer: CustomerModel,
    userSessionProvider,
    isMobileApp: boolean,
    currency = 'NGN',
    isDemo: boolean
  ) {
    super();

    this.config = config;
    this.customer = customer;
    this.userSessionProvider = userSessionProvider;
    this.isMobileApp = isMobileApp;
    this.currency = currency;
    this.is_demo = isDemo;

    Object.assign(this, data);

    if (this.casino_launch_id) {
      this.id = this.casino_launch_id;
    }

    if (!this.game_name) this.game_name = data.name;

    this.url = this.generateBrowseWindowUrl();
    this.setIdentifier(this.id);
    this.setLabel(this.game_name);
    this.setInstanceCategoryType('casino-game');
  }

  public generateBrowseWindowUrl() {
    let url = '';
    let appUrl = this.config.getEnv().webservices_url;

    let params = new URLSearchParams();
    if (this.customer) {
      params.set('player_id', this.customer.customerId.toString());
      // if (this.isMobileApp) {
        params.set('session_id', this.userSessionProvider.getSessionToken());
      // }
    }
    params.set('no_redirect', 'true');
    params.set('game_launcher_id', this.id?.toString());
    params.set('gameName', this.game_name);
    let clientApp = this.config.getClientApp();
    params.set('client_app_id', clientApp);
    params.set('chip_type', this.currency);

    if (this.is_demo) {
      params.set('playMode', "Demo");
    }

    url = `${appUrl}/games/casino/launch?${params.toString()}`;
    return url;
  }
}
