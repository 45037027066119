import {
  ApplicationRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Device } from '@ionic-native/device/ngx';
import { LoadingController, NavController, Platform } from '@ionic/angular';
import { Observable, fromEvent } from 'rxjs';
import { CustomerModel } from 'src/app/modules/account/models/customer-model';
import { GameHubController } from 'src/app/modules/gameHub/game-hub-controller';
import { GameHub } from 'src/app/modules/gameHub/providers/game-hub';
import { MainProvider } from 'src/app/modules/lobby/providers/main.provider';
import { MyEvent } from 'src/app/service/myevent.services';
import { Config } from 'src/app/system/providers/configuration';
import { UserSessionProvider } from 'src/app/system/providers/user-session';
import { WebservicesProvider } from 'src/app/system/providers/webservices';
import { map, tap, takeUntil, switchMap, take, first, debounceTime } from 'rxjs/operators';
import { CommonService } from 'src/app/service/common.service';

declare var $;

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss'],
})
export class GameComponent implements OnInit, OnDestroy {

  @Input() instance_id;
  @Input() gameInstance;

  public game_url: any;
  public game_display: object = {};
  public game_height: object = {};
  public windowResizeSubscriber;
  public isMobile: boolean = false;
  public showPreloader: boolean = false;
  public timeOnlineEnabled: boolean;
  customer: CustomerModel;
  public environment: any;

  loadingTimeout: any;

  eventSub: any;

  constructor(
    public sanitizer: DomSanitizer,
    public gameHub: GameHub,
    public platform: Platform,
    private applicationRef: ApplicationRef,
    private events: MyEvent,
    public config: Config,
    private userSession: UserSessionProvider,
    public mainProvider: MainProvider,
    public webservices: WebservicesProvider,
    public gameHubController: GameHubController,
    public device: Device,
    private loading: LoadingController,
    private navCtrl: NavController,
    public comService: CommonService
  ) {
    this.userSession.getCustomer().subscribe((customer) => {
      this.customer = customer;
    });

    window.addEventListener('message', (event) => {
      if (event.data.method == 'gel.initialise') {
        this.environment = this.config.getEnv().webservices_url;
        this.iframePostMessageGame(
          {
            method: 'gel.initialise',
            data: {
              origin: this.environment || '',
            },
          },
          event
        );
      }
    });


    this.eventSub = this.events.getObservable().subscribe(data => {
      if (data.event == 'new:game:load') {
        setTimeout(() => {
          this.setPlaceholderHeight();
        }, 0);
        this.init();
        this.setGameData();
      } else if (data.event == 'event:gameHub:closeGame') {
        this.gameDestroy();
      }
    });
  }

  ngOnInit() {

  }

  init() {
    this.isMobile = this.comService.isMobileWeb;
    console.log('is mobile ? == ', this.isMobile);

    if (this.isMobile) {
      let playingdiv = document.getElementById('playing_game');
      if (!!playingdiv) {
        playingdiv.setAttribute('size', '12');
        playingdiv.style.padding = '0px';
      }

      let my_games = document.getElementById('my_games');
      if (!!my_games) my_games.setAttribute('style', 'display:none');
    }

    this.setPlaceholderHeight();

    this.showPreloader = true;

    document.addEventListener('fullscreenchange', (event) => {
      if (!document.fullscreenElement) {
        this.clearMobileLook();

        if (!this.isMobile) {
          let gameSec = document.getElementById('playing_game');
          if (!!gameSec) gameSec.setAttribute('size', '10');

          let myGameSec = document.getElementById('my_games');
          if (!!myGameSec) myGameSec.setAttribute('size', '2');
        }
      }
    });
  }

  setGameData() {
    setTimeout(() => {
      this.showPreloader = false;
    }, 9300);

    this.onWindowResize();

    if (this.gameInstance) {
      this.setGameUrl(
        this.gameInstance.url,
        this.gameInstance.userSessionProvider.getSessionToken(),
        this.gameInstance.is_demo
      );
      this.showGame(true);
    }
  }

  gameDestroy() {
    this.showGame(false);

    if (this.isMobile) {
      this.clearMobileLook();
    }
    if (this.windowResizeSubscriber) this.windowResizeSubscriber.unsubscribe();

    if (this.gameInstance) {
      let obj: any = {
        game_launcher_id: this.gameInstance.id
      };

      if (this.customer) {
        obj['player_id'] = this.customer.customerId;
        obj['session_id'] = this.userSession.getSessionToken();
      }

      this.webservices.postResourcesApi('games/close-game', obj).subscribe(() => {});
    }

    this.gameHub.muteAllGames();

    if (this.instance_id) this.gameHub.unfocusInstance(this.instance_id);

    if (document.getElementById('iframe_' + this.getInstanceId())) document.getElementById('iframe_' + this.getInstanceId()).setAttribute('src', '');

    if (this.loadingTimeout) clearTimeout(this.loadingTimeout);
    this.loadingTimeout = null;
    this.gameInstance = null;

    setTimeout(() => {
      this.game_url = null;
      this.instance_id = null;
      this.showPreloader = false;
    }, 100);
  }

  ngOnDestroy() {
    this.gameDestroy();
    if (this.eventSub) this.eventSub.unsubscribe();
    this.eventSub = null;
  }

  private iframePostMessageGame(message, event) {
    let iFrameID = `iframe_${this.instance_id}`;
    let elem = document.getElementById(iFrameID);
    if (elem) {
      let gameflexIframeWindow = (<HTMLIFrameElement>elem).contentWindow;
      if (gameflexIframeWindow) {
        gameflexIframeWindow.postMessage(
          {
            method: 'operator.ready',
            data: {
              origin: this.environment || '',
            },
          },
          event.origin
        );
      }
    }
  }

  onWindowResize = () => {
    if (this.windowResizeSubscriber) this.windowResizeSubscriber.unsubscribe();
    this.windowResizeSubscriber = null;

    this.windowResizeSubscriber = fromEvent(window, 'resize').pipe(debounceTime(100))
      .subscribe((event) => {
        this.setPlaceholderHeight();
      });
  };

  setInstanceId(value) {
    this.instance_id = value;
  }

  getInstanceId() {
    return this.instance_id;
  }

  setGameUrl(url, session_token, isDemo = false) {
    let deviceType = this.gameHubController.getDeviceType();
    console.log('DeviceType in game component == ', deviceType);

    let obj: any = {};
    if (this.userSession.isAuthenticated() && session_token) {
      obj['session_token'] = session_token;
    }

    console.log('url == ', url);

    this.webservices
      .getApiNoRedirect(url, deviceType, obj)
      .pipe(first())
      .subscribe(
        (resp) => {
          console.log('setGameUrl: ', resp);
          if (resp.code == 200 && resp.result && resp.payload) {
            let url = '';
            if ('href' in resp.payload) url = resp.payload.href;
            if ('url' in resp.payload) url = resp.payload.url;
            this.game_url = this.sanitizer.bypassSecurityTrustResourceUrl(
              url + '&windowID=' + this.instance_id
            );
            console.log('game url == ', this.game_url);

            if (this.loadingTimeout) {
              clearTimeout(this.loadingTimeout);
              this.loadingTimeout = null;
            }
            this.loadingTimeout = setTimeout(() => {
              $(`iframe_${this.getInstanceId()}`).ready(() => {
                this.showPreloader = false;
              });
            }, 250);
          }
        },
        (error) => {
          console.error('setGameUrl error: ', error);
        }
      );
  }

  showGame(show: boolean) {
    // if (show) {
    //   // this.gameHub.focusInstance(this.instance_id);
    //   this.game_display = { position: 'relative', top: '0', display: 'block' };
    // } else {
    //   this.game_display = { position: 'absolute', top: '-4460px' };
    // }
  }

  public setPlaceholderHeight() {
    if (this.isMobile) {
      this.setMobileLook();
    } else {
      let headerElement: any = document.querySelector('ion-header.main-header');
      let headerHeight: any = headerElement ? headerElement.offsetHeight : 0;
      let searchElement: any = document.querySelector('div.search-container');
      let searchHeight: any = searchElement ? searchElement.offsetHeight : 0;

      let gameBarElement: any = document.querySelector('ion-col.game-wrapper-bar');
      if (!!gameBarElement) gameBarElement.setAttribute('style', 'display:none');

      let gameBarHeight: any = gameBarElement ? gameBarElement.offsetHeight : 0;

      let height =
        window.innerHeight - searchHeight - gameBarHeight - 78; // - headerHeight - 75 is static value
      this.game_height = { height: `${height}px` };
    }

    // this.showPreloader = true;

    this.changeCssToAvoidBrowserLoadingBug();
  }

  changeCssToAvoidBrowserLoadingBug() {
    let tabbar = document.querySelector('body');
    let q = 100;
    let x = setInterval(() => {
      if (!!tabbar) tabbar.setAttribute('style', `max-width: ${q}%`);
      q = q == 100 ? 101 : 100;
    }, 100);

    setTimeout(() => {
      clearInterval(x);
    }, 3000);
  }

  public setMobileLook() {
    let topHeaderEl: any = document.querySelector('ion-header');
    if (!!topHeaderEl) topHeaderEl.setAttribute('style', 'display:none');

    let ionTabbar: any = document.querySelector('ion-tab-bar');
    if (!!ionTabbar) ionTabbar.setAttribute('style', 'display:none');

    let playingdiv = document.getElementById('playing_game');
    if (!!playingdiv) playingdiv.setAttribute('size', '12');

    let tabbar = document.querySelector('.tabbar');
    if (!!tabbar) tabbar.setAttribute('style', 'display:none');

    let my_games = document.getElementById('my_games');
    if (!!my_games) my_games.setAttribute('style', 'display:none');

    let mainFooter: any = document.querySelector('app-main-footer'); // betsplus
    if (!!mainFooter) mainFooter.setAttribute('style', 'display:none');

    let mainFooterBfy: any = document.querySelector('app-mobile-footer'); // bfy
    if (!!mainFooterBfy) mainFooterBfy.setAttribute('style', 'display:none');
    
    let mainFooterRbt: any = document.querySelector('app-rollbit-mobile-footer'); // rbt
    if (!!mainFooterRbt) mainFooterRbt.setAttribute('style', 'display:none');
    
    let mainFooterBcg: any = document.querySelector('app-bcg-mobile-footer'); // bcg
    if (!!mainFooterBcg) mainFooterBcg.setAttribute('style', 'display:none');

    let mainDiv: any = document.querySelector('.main_div');
    if (!!mainDiv) mainDiv.setAttribute('style', 'height: 100%');

    let gameBarElement: any = document.querySelector('ion-col.game-wrapper-bar');
    let gameBarHeight: any = gameBarElement ? gameBarElement.offsetHeight : 0;

    let scrollContent = document.querySelector('games-home .scroll-content');
    if (!!scrollContent) {
      scrollContent.setAttribute('style', 'overflow-y: hidden;');
      scrollContent.setAttribute('style', 'margin-top: 0!important;');
    }

    let liveChat: any = document.getElementById('MICHAT-FLOAT-WRAP');
    if (!!liveChat) liveChat.setAttribute('class', 'hidden');

    let safeAreaBottom: number = 0;
    if (window.innerHeight < 550) {
      safeAreaBottom = 25;
    }
    this.game_height = {
      height: `${window.innerHeight - gameBarHeight - safeAreaBottom - 15}px`, // 70 : 150
    };
    window.scrollTo(0, 0);
  }

  private clearMobileLook() {
    let scrollContent = document.querySelector('games-home .scroll-content');
    if (!!scrollContent) scrollContent.setAttribute('style', 'overflow-y: auto;');

    let topHeaderEl: any = document.querySelector('ion-header');
    if (!!topHeaderEl) topHeaderEl.setAttribute('style', 'display:block');

    let my_games = document.getElementById('my_games');
    if (!!my_games && !this.isMobile) my_games.setAttribute('style', 'display:block');

    let keywordsComponent = document.getElementById('keywords-casino');
    if (!!keywordsComponent) keywordsComponent.setAttribute('style', 'display:block');

    let gameBarElement: any = document.querySelector('ion-col.game-wrapper-bar');
    if (!!gameBarElement && !this.isMobile) gameBarElement.setAttribute('style', 'display:none');

    let ionTabbar: any = document.querySelector('ion-tab-bar');
    if (!!ionTabbar) ionTabbar.setAttribute('style', 'display:block');

    let mainFooter: any = document.querySelector('app-main-footer'); // bps
    if (!!mainFooter && this.isMobile) mainFooter.setAttribute('style', 'display:block');

    let mainFooterBfy: any = document.querySelector('app-mobile-footer'); // bfy
    if (!!mainFooterBfy && this.isMobile) mainFooterBfy.setAttribute('style', 'display:block');

    let mainFooterRbt: any = document.querySelector('app-rollbit-mobile-footer'); // rbt
    if (!!mainFooterRbt && this.isMobile) mainFooterRbt.setAttribute('style', 'display:block');
    
    let mainFooterBcg: any = document.querySelector('app-bcg-mobile-footer'); // bcg
    if (!!mainFooterBcg && this.isMobile) mainFooterBcg.setAttribute('style', 'display:block');

    let mainDiv: any = document.querySelector('.main_div');
    if (!!mainDiv && this.isMobile) mainDiv.removeAttribute('style');

    let liveChat: any = document.getElementById('MICHAT-FLOAT-WRAP');
    if (!!liveChat) liveChat.setAttribute('class', '');
  }

  toggleFullScreen(exit = false) {
    console.log('toggleFullScreen');

    var doc: any = window.document;
    var docEl: any = doc.documentElement;

    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    var cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (!!exit) {
      this.clearMobileLook();
      if (
        doc.fullscreenElement ||
        doc.webkitFullscreenElement ||
        doc.mozFullScreenElement
      ) {
        cancelFullScreen.call(doc).catch((error) => {
          console.log(error);
        });
      }
      return false;
    }

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      this.setMobileLook();
      requestFullScreen.call(docEl);
    } else {
      this.clearMobileLook();
      cancelFullScreen.call(doc);
    }

    this.applicationRef.tick();
  }

  onCloseBtn() {
    if (this.isMobile) this.clearMobileLook();
    if (document.getElementById('iframe_' + this.instance_id)) document.getElementById('iframe_' + this.instance_id).setAttribute('src', '');
    if (this.eventSub) this.eventSub.unsubscribe();
    this.eventSub = null;
    this.navCtrl.pop();
  }
}
