import { CustomerModel } from "./../../account/models/customer-model";
import { UserSessionProvider } from "./../../../system/providers/user-session";
import { WebservicesProvider } from "./../../../system/providers/webservices";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AccountProvider {
  
  public customer: CustomerModel;

  constructor(
    private userSession: UserSessionProvider,
    private webservice: WebservicesProvider
  ) {
    console.log("AccountProvider");

    this.userSession.getCustomer().subscribe((customer) => {
      this.customer = customer;
    });
  }

  /*
        POST /authentication/qualification
    */
  //@todo : add skin code
  public callGetQualification(context, skip?) {
    if (this.userSession.sessionToken) {
      let obj: any = {
        player_id: this.customer.customerId,
        session_id: this.userSession.sessionToken,
        context: context,
        skin_code: "whitelabelcasino",
        skip: skip,
      };

      return this.webservice.post("player/qualification", obj, false);
    } else {
      let response = new Subject<any>();
      response.next({});
      return response;
    }
  }
}
