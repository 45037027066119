import { Component } from '@angular/core';
import { Navigator } from './../../../../../../system/components/menu/navigator';
import { RewardsProvider } from './../../../../providers/rewards-provider';
import { Utils } from '../../../../../../shared/utils';

@Component({
  selector: 'page-casino-bonus-active',
  templateUrl: 'casino-bonus-active.html',
  styleUrls: ['./casino-bonus-active.scss'],
})
export class CasinoBonusActivePage {

  bonusArray: any[] = new Array();
  showTable = false;
  showNoResults: boolean = false;
  error = null;

  constructor(
    private rewardsProvider: RewardsProvider, public utils: Utils,
  ) {}

  ionViewDidEnter() {
    console.log('ionViewDidEnter CasinoBonusActivePage');

    this.rewardsProvider.callGetCustomerCasinoBonusesActive().subscribe(
      (response: any) => {
        console.log('active bonus == ', response);
        if (response.code == 200 && !!response.result) {
          this.bonusArray = response.payload;

          // console.log("bonusArray = ", this.bonusArray);

          this.bonusArray.forEach((element) => {
            let shortenedDisplayName: string = element.displayName;
            if (
              shortenedDisplayName.length > 9 &&
              shortenedDisplayName.substr(0, 12).indexOf(' ') == -1
            ) {
              shortenedDisplayName =
                shortenedDisplayName.substring(0, 7) + '...';
              element.shortenedDisplayName = shortenedDisplayName;
            } else {
              element.shortenedDisplayName = element.displayName;
            }
          });

          if (this.bonusArray.length > 0) {
            this.showTable = true;
            this.showNoResults = false;
          } else {
            this.showTable = false;
            this.showNoResults = true;
          }
        }
      },
      (error) => {
        this.showTable = false;
        this.error = error;
      }
    );
  }

  onTotalBonusClicked(bonus: any) {
    Navigator.navigateTo(
      'CasinoBonusDetailsPage',
      { multipleModals: true },
      { param1: bonus, param2: 'active' }
    );
    return false;
  }

  getBonusAmount(bonus: any): string {
    let bonusAmount: any;
    let currentBonus = parseFloat(bonus.currentBonus) || 0;
    let currentDeposit = parseFloat(bonus.currentDeposit) || 0;
    let currentWinning = parseFloat(bonus.currentWinning) || 0;
    bonusAmount = currentBonus + currentDeposit + currentWinning;
    return bonusAmount.toFixed(2);
  }

  onBack() {
    
  }
}
