import { NgModule } from '@angular/core';
import { CasinoBonusDetailsPage } from './casino-bonus-details';
import { SharedModule } from '../../../../../../shared/shared.module';
import { SystemModule } from '../../../../../../system/system.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    CasinoBonusDetailsPage,
  ],
  imports: [
    SystemModule,
    SharedModule,
    CommonModule,
    IonicModule,
    TranslateModule
  ],
})
export class CasinoBonusDetailsPageModule {}
