import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { WebservicesProvider } from '../../system/providers/webservices';
import { NotificationApiProvider } from '../../shared/providers/notification-api';
import { Config } from '../../system/providers/configuration';
import { UserSessionProvider } from '../../system/providers/user-session';
import { PopoverFactory } from '../../modules/popover/factory';

// additional imports to support GeoComplyProvider
import {
  GeoComplyProvider,
  GeoComplyStatus,
  GeoComplyReasons,
} from '../../shared/providers/geocomply';
import { CustomerModel } from '../../modules/account/models/customer-model';
import { MyEvent } from 'src/app/service/myevent.services';
import { Platform, PopoverController } from '@ionic/angular';
export module Jurisdiction {
  /**
   * List of all possible error conditions reported as errors to the Jurisdiction Observable:
   */
  export const GEOLOCATION_FAILED = 'GEOLOCATION_FAILED';
  export const JURISDICTION_NOTALLOWED = 'JURISDICTION_NOTALLOWED';

  /** @todo add codes for DGE GeoComply approved error messages */
}

export interface GeoError {
  error_code: string;
  error_message?: string;
  error_details?: string;
}

export interface GeoResponse {
  is_allowed: boolean;
  address: string;
  error?: GeoComplyStatus;
}

export interface Jurisdiction {
  status: Jurisdiction;
  message: string;
}

export interface JurisdictionProviderInterface {
  listen(): Observable<GeoResponse>;
  initialize(customer_id: any, session_token: string): void;
  unsubscribe(): void;
  resumeNotifications(): void;
}

/**
 * JurisdictionProvider
 */

@Injectable({
  providedIn: 'root',
})
export class JurisdictionProvider implements JurisdictionProviderInterface {
  private _jurisdictionSubject: Subject<GeoResponse>;
  private _nextWasCalled = false;
  private _cached_geoResponse: GeoResponse;
  private _cache_valid_until_ts: number;
  private _didUserAcceptReason: boolean = false; // user must accept reason for requesting geolocation permissions
  //
  private isAllowed: boolean = false;
  private customer: CustomerModel;
  //
  private nextScheduledTimeout: any;
  //
  private checkIntervalId: any;
  static GEOCOMPLY_IP_CHECK_INTERVAL = 30 * 1000;
  //
  private error_object: any;
  private initialized: boolean = false;
  private reason: string;
  //
  private tsLastResponse: number = 0;
  private has_been_verified: boolean = false;
  private uiAlert: any;

  constructor(
    private geoComply: GeoComplyProvider,
    private userSession: UserSessionProvider,
    private notificationApi: NotificationApiProvider,
    private events: MyEvent,
    private platform: Platform,
    private popoverCtrl: PopoverController,
    private config: Config,
    private webservices: WebservicesProvider
  ) {
    this._jurisdictionSubject = new Subject<GeoResponse>();
    userSession.getCustomer().subscribe((customer: CustomerModel) => {
      if (
        !this.customer ||
        !customer ||
        customer.customerId !== this.customer.customerId
      ) {
        if (this.nextScheduledTimeout) {
          clearTimeout(this.nextScheduledTimeout);
          this.nextScheduledTimeout = null;
        }
        if (this.checkIntervalId) {
          clearInterval(this.checkIntervalId);
          this.checkIntervalId = null;
        }

        this.customer = customer;
        this.reason = GeoComplyReasons.firstGeolocation;
        this.has_been_verified = false;
        this._cached_geoResponse = null;
        this._cache_valid_until_ts = 0;
      }
    });
  }

  public setUiAlert(uiAlert) {
    this.uiAlert = uiAlert;
  }

  public listen(): Observable<GeoResponse> {
    return this._jurisdictionSubject;
  }

  public verifyJurisdiction(
    retryFn: (g: GeoResponse) => any
  ): Promise<GeoResponse> {
    return new Promise((resolve, reject) => {
      if (
        0 === parseInt(this.config.getConfig().FEATURE_GEOLOCATION_ENFORCED)
      ) {
        resolve({ is_allowed: true, address: null } as GeoResponse);
        return;
      }
      const runGeolocationCheck = () => {
        if (this.isAllowedJurisdiction()) {
          resolve(this._cached_geoResponse);
        } else {
          this.events.publishSomeData({ event: 'event:geoComply:present' });
          var jurisdictionSubscriber = this._jurisdictionSubject.subscribe(
            (geoResponse: GeoResponse) => {
              this.events.publishSomeData({ event: 'event:geoComply:dismiss' });

              jurisdictionSubscriber.unsubscribe();
              if (geoResponse.is_allowed) {
                this.has_been_verified = true;
                resolve(geoResponse);
              } else {
                reject(geoResponse);
              }
            }
          );
          this.triggerGeolocation(retryFn);
        }
      };

      if (this.shouldDisplayPermissionReason()) {
        this.displayPermissionReason().then((acceptsReason) => {
          this._didUserAcceptReason = acceptsReason;
          if (acceptsReason) {
            runGeolocationCheck();
          }
        });
      } else {
        runGeolocationCheck();
      }
    });
  }

  private didAcceptReason() {
    var didAccept = window.localStorage.getItem(
      'geocomply_didAcceptPermissionReason'
    );
    return !!parseInt(didAccept);
  }

  private setAcceptReason(didAccept) {
    window.localStorage.setItem(
      'geocomply_didAcceptPermissionReason',
      0 + didAccept
    );
  }

  protected shouldDisplayPermissionReason(): boolean {
    /**
     * @note this pre-geo popup has not yet been approved in 724bet, therefore the feature is returning 'false' here.
     *
     * @todo Also the text in PreGeoInfoPopover is specific to 724bet so it needs to be updated.
     *
     */
    return (
      this.platform.is('cordova') &&
      this.platform.is('android') &&
      !this.didAcceptReason()
    );
    //return !this.didAcceptReason();
  }

  protected displayPermissionReason(): Promise<boolean> {
    return new Promise(async (resolve) => {
      let popoverView = PopoverFactory('PreGeoInfoPopover');
      let popover = await this.popoverCtrl.create({
        component: popoverView,
        componentProps: {
          title: '',
        },
        showBackdrop: true,
        backdropDismiss: true,
        cssClass: 'standard-popover webview marketing-notification',
      });

      popover.present();
      popover.onDidDismiss().then((data) => {
        console.log('jurisdiction.ts: popover onDidDismiss: ', data.data);
        this.setAcceptReason(!!data.data);
        resolve(!!data.data);
      });
    });
  }

  public hasBeenVerifiedBefore(): boolean {
    return (
      0 === parseInt(this.config.getConfig().FEATURE_GEOLOCATION_ENFORCED) ||
      this.has_been_verified
    );
  }
  public hasBeenVerifiedCheck(): boolean {
    return this.has_been_verified;
  }

  public reconnect() {
    this.geoComply.reconnect();
  }

  private funcToRetry: () => {} = null;
  protected triggerGeolocation(retryFn): void {
    this.funcToRetry = retryFn ? retryFn : null;

    let numtries: number = 0;
    let mythis = this;
    const tryGeolocation = () => {
      numtries++;

      if (!mythis.customer) {
        // customer logged out, stop re-trying
        return;
      }
      if (this.geoComply.isConnected()) {
        mythis.notificationApi.pause(); // pause SSE when Jurisdiction services are running
        mythis.geoComply.handleGeolocationRequest(
          mythis.customer.customerId,
          mythis.reason,
          false
        );
      } else {
        console.log('geo waiting for connection.');
        if (numtries > 9) {
          var error;
          if (this.platform.is('cordova')) {
            error = {
              /** @todo need codes specific to Cordova installation */
              error_code: 612,
              error_message: null,
              error_details: null,
              geolocate_in: 0,
            };
          } else if (this.geoComply.isMobileWeb) {
            error = {
              error_code: 103,
              error_message: null,
              error_details: null,
              geolocate_in: 0,
            };
          } else {
            error = {
              error_code: 612,
              error_message: null,
              error_details: null,
              geolocate_in: 0,
            };
          }

          this.emitGeoResponse({
            is_allowed: false,
            error: error,
          } as GeoResponse);
          return; // abort retries at this point
        }
        if (numtries % 10 == 0) {
          mythis.geoComply.reconnect();
        }
        setTimeout(() => {
          tryGeolocation();
        }, 750);
      }
    };
    this.initialize();
    tryGeolocation();
  }

  public killConnection() {
    this.initialized = false;
    this.geoComply.killConnection();
  }

  public getFuncToRetry(): (g: GeoResponse) => {} {
    return this.funcToRetry;
  }

  protected emitGeoResponse(geoResp: GeoResponse) {
    var was_allowed =
      this._cached_geoResponse && this._cached_geoResponse.is_allowed;
    this._cached_geoResponse = geoResp;

    let currTs = new Date().getTime();

    if (
      currTs - this.tsLastResponse > 4000 ||
      (was_allowed && !geoResp.is_allowed)
    ) {
      // going from pass to fail
      this._jurisdictionSubject.next(geoResp);
    } else {
      console.warn('jurisdiction.ts: quashing: ', geoResp);
    }
    this.tsLastResponse = currTs;
  }

  /*
   * determines from this._cached_geoReponse and this._cache_valid_until_ts whether the player is currently in a valid geolocation state
   */
  public isAllowedJurisdiction(): boolean {
    return (
      this._cache_valid_until_ts &&
      this._cache_valid_until_ts > new Date().getTime() &&
      this._cached_geoResponse &&
      this._cached_geoResponse.is_allowed
    );
  }

  private geoComplySubscription: Subscription;
  public initialize(): void {
    if (this.initialized) {
      // don't double bind if already initialized!
      return;
    }
    var customer_id = this.customer.customerId;

    if (this.geoComplySubscription) {
      this.geoComplySubscription.unsubscribe();
    }

    this.geoComplySubscription = this.geoComply
      .getGeoComplyStatusObservable()
      .subscribe((geoComplyStatus: GeoComplyStatus) => {
        if (geoComplyStatus) {
          // null is when event trigger while user logged out.
          if (geoComplyStatus.error_code === 0) {
            console.warn('jurisdiction.ts: PLAYER IS ALLOWED.');
            this.isAllowed = true;
            this.error_object = undefined;
            this.notificationApi.resume(); // resume SSE processing
          } else {
            console.warn('jurisdiction.ts: PLAYER IS DENIED.');
            this.isAllowed = false;
            this.error_object = geoComplyStatus;
          }
          if (geoComplyStatus.geolocate_in > 0) {
            if (this.nextScheduledTimeout) {
              console.log('jurisdiction.ts: clearing previously scheduled');
              clearTimeout(this.nextScheduledTimeout);
              this.nextScheduledTimeout = null;
            }
            if (this.checkIntervalId) {
              clearTimeout(this.checkIntervalId);
              this.checkIntervalId = null;
            }
            let ms_interval =
              (geoComplyStatus.geolocate_in -
                this.getBufferTime(geoComplyStatus.geolocate_in)) *
              1000;

            console.log(
              'jurisdiction.ts: scheduling geolocate in ' +
                ms_interval / 1000 +
                ' seconds.'
            );

            let didTryGeocomply: boolean = false;
            const tryGeocomply = (isBackground: boolean) => {
              console.log(
                'events: HANDLING: event:geoComply:didInvokeUserAction'
              );
              didTryGeocomply = true;
              if (this.customer && this.userSession.isAuthenticated()) {
                // guard against session timeout and checking after logout
                this.reason = GeoComplyReasons.periodic;
                console.log(
                  'jurisdiction.ts: (scheduled) doing handleGeolocationRequest'
                );
                this.notificationApi.pause(); // pause SSE when Jurisdiction services are running
                this.geoComply.handleGeolocationRequest(
                  customer_id,
                  this.reason,
                  isBackground
                );
              }
            };
            const tryGeocomplyBG = () => tryGeocomply(true);
            const tryGeocomplyFG = () => {
              console.log(
                'events: UNSUBSCRIBING: event:geoComply:didInvokeUserAction'
              );
              this.events.unsubscribe( 'event:geoComply:didInvokeUserAction');
              tryGeocomply(false);
            };

            if (this.platform.is('mobileweb') && this.platform.is('android')) {
              console.log(
                'events: SUBSCRIBING: event:geoComply:didInvokeUserAction'
              );
              this.events.getObservable().subscribe((data) => {
                if (data.event == 'event:geoComply:didInvokeUserAction')
                  tryGeocomplyFG;
              });
            }

            this._cache_valid_until_ts = new Date().getTime() + ms_interval;

            const doGeoCheck = () => {
              if (this.checkIntervalId) {
                clearInterval(this.checkIntervalId);
                this.checkIntervalId = null;
              }
              if (
                this.platform.is('mobileweb') &&
                this.platform.is('android')
              ) {
                console.log(
                  'events: PUBLISHING: event:geoComply:invokeUserAction'
                );
                this.events.publishSomeData({
                  event: 'event:geoComply:invokeUserAction',
                });
                setTimeout(() => {
                  if (!didTryGeocomply) {
                    if (this.uiAlert) {
                      this.uiAlert.dismiss();
                      this.uiAlert = undefined;
                    }
                    this._cache_valid_until_ts = null;
                    this._cached_geoResponse = null;

                    this.events.publishSomeData({
                      event: 'event:authentication:logout',
                    });
                    window.alert('Geolocation Timeout.  Session terminated.');
                    this.userSession.clear();
                    this.emitGeoResponse({
                      is_allowed: false,
                      address: null,
                      error: {
                        error_code: 9900,
                        error_message: 'User Interaction Timeout',
                        error_details:
                          'User failed to respond to geolocation user interaction within required time',
                        geolocate_in: 0,
                      },
                    } as GeoResponse);
                  }
                }, 120 * 1000);
              } else {
                tryGeocomplyBG();
              }
            };

            this.nextScheduledTimeout = setTimeout(doGeoCheck, ms_interval);
            this.checkIntervalId = setInterval(() => {
              this.check().then((geoIsOkay: boolean) => {
                if (geoIsOkay) {
                  console.log('IP is fine.');
                } else {
                  console.log('IP change detected!');
                  // reschedule the nextScheduledTimeout:
                  clearTimeout(this.nextScheduledTimeout);
                  this.nextScheduledTimeout = null;
                  doGeoCheck();
                }
              });
            }, JurisdictionProvider.GEOCOMPLY_IP_CHECK_INTERVAL);
          }
        } else {
          this.isAllowed = false;
        }
        console.log(
          'jurisdiction.ts: emitting GeoResponse: is_allowed=' +
            this.isAllowed +
            ' error_object: ' +
            JSON.stringify(this.error_object)
        );
        this.emitGeoResponse({
          is_allowed: this.isAllowed,
          address: null,
          error: this.error_object,
        } as GeoResponse);
      });

    this.geoComply.initialize();
    this.initialized = true;
  }
  public unsubscribe(): void {
    if (this.nextScheduledTimeout) {
      clearTimeout(this.nextScheduledTimeout);
      this.nextScheduledTimeout = null;
    }
    this.events.publishSomeData({ event: 'event:geoComply:dismiss' });
    this.notificationApi.resume();
  }
  public resumeNotifications(): void {
    this.notificationApi.resume();
  }

  public cancelGeolocation(): void {
    if (this.nextScheduledTimeout) {
      clearTimeout(this.nextScheduledTimeout);
      this.nextScheduledTimeout = null;
    }
  }

  protected check(): Promise<boolean> {
    return new Promise((resolve) => {
      this.webservices
        .post('geocomply/verify', {
          player_id: this.customer.customerId,
          session_id: this.userSession.getSessionToken(),
        })
        .toPromise()
        .then(() => {
          console.log('check passed');
          resolve(true);
        })
        .catch((reason: any) => {
          console.log('check failed!', reason);
          resolve(false);
        });
    });
  }

  /**
   * compute amount of buffer time recommended by Buffer Time Calculation section of documentation from GeoComply:
   * `GeoComply Geolocation Response, XML content v4.4.0.pdf`
   */
  protected getBufferTime(geolocate_in: number) {
    let bufferTimeForPopup =
      this.platform.is('mobileweb') && this.platform.is('android') ? 30 : 0;
    if (geolocate_in < 120) {
      return Math.min(geolocate_in * (30.0 / 100.0), bufferTimeForPopup + 120);
    } else {
      return Math.min(geolocate_in / 2, bufferTimeForPopup + 120);
    }
  }
}
