import { Component, Input, OnInit } from '@angular/core';
import { MyEvent } from 'src/app/service/myevent.services';
import { Navigator } from 'src/app/system/components/menu/navigator';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.page.html',
  styleUrls: ['./payments.page.scss'],
})
export class PaymentsPage implements OnInit {

  @Input() btnType = 'deposit';

  constructor(
    private events: MyEvent
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.events.publishSomeData({event: 'async:balance:interval'});
  }

  ionViewWillLeave() {
    this.events.publishSomeData({event: 'async:balance:interval'});
  }

  close() {
    Navigator.back();
  }

}
