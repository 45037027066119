import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Config } from './../../../system/providers/configuration';
import { UserSessionProvider } from './../../../system/providers/user-session';
import { BalanceProvider } from './../../../system/providers/balance-provider';
import { CustomerModel } from './../../../modules/account/models/customer-model';
import { Navigator } from './../../../system/components/menu/navigator';
import { MyEvent } from 'src/app/service/myevent.services';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'inactivity',
  templateUrl: 'inactivity.html',
  styleUrls: ['./inactivity.scss']
})
export class InactivityComponent implements OnInit {

  public customer: CustomerModel;

  public toastLoad: any;
  public timedOut: boolean = false;
  public idleTime: number;
  public timeOutTime: number;

  constructor(
    private events: MyEvent,
    private userSession: UserSessionProvider,
    private config: Config,
    private balanceProvider: BalanceProvider,
    private idle: Idle,
    private comService: CommonService
  ) {}

  ngOnInit() {
    // this.events.getObservable().subscribe((data) => {
    //   if (data.event == 'event:inactivity:reset') this.resetTimeOut();
    // });

    // this.events.getObservable().subscribe((data) => {
    //   if (data.event == 'event:mygames:highlight') this.resetTimeOut();
    // });

    this.balanceProvider.getBalance().subscribe((response) => {
      // this.resetTimeOut();
    });
    this.userSession.getCustomer().subscribe((customer) => {
      this.customer = customer;
      if (customer) {
        if (this.userSession.isAuthenticated()) {
          // this.timeOutSet();
        }
      } else {
        this.clearIdleObserversArray();
        try {
          if (this.toastLoad) {
            this.toastLoad.dismiss();
          }
        } catch (e) {
          console.log(e);
        }
      }
    });

    this.idleTime = Number(this.config.getConfig().INACTIVITY_IDLE_TIME) || 900;
    this.timeOutTime = Number(this.config.getConfig().INACTIVITY_TIMEOUT) || 15;
  }
  resetTimeOut() {
    console.log('Inactivity: resetTimeOut()');
    try {
      if (this.toastLoad) {
        this.toastLoad.dismiss();
      }
    } catch (e) {
      console.log(e);
    }
    this.idle.stop();
    this.idle.watch();
    this.timedOut = false;
  }
  timeOutSet() {
    console.log('Inactivity: timeOutSet()');
    this.idle.setIdle(this.idleTime); //starts if user is idle for a certain number of seconds
    this.idle.setTimeout(this.timeOutTime); //if user doesn't respond, he/she will be timed out and logged out

    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      if (this.toastLoad) {
        this.toastLoad.dismiss();
      }
    });
    this.idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      this.onTimeout();
    });
    this.idle.onIdleStart.subscribe(() => {
      try {
        if (this.toastLoad) {
          this.toastLoad.dismiss();
        }
      } catch (e) {
        console.log(e);
      }
      this.comService.showToast('Due to inactivity, you will be signed out.');
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      console.log('You will time out in ' + countdown + ' seconds!');
    });
  }
  clearIdleObserversArray() {
    this.idle.stop();
    this.idle.onTimeout.observers.length = 0;
    this.idle.onIdleEnd.observers.length = 0;
    this.idle.onIdleStart.observers.length = 0;
    this.idle.onTimeoutWarning.observers.length = 0;
    this.idle.onIdleEnd.observers.length = 0;
  }
  onTimeout() {
    console.log('Inactivity: onTimeout()');
    this.clearIdleObserversArray();
    Navigator.navigateTo(
      'LogoutPage',
      {},
      { code: 'INACTIVITY', reason: 'APP_INACTIVITY' }
    );
  }
}
