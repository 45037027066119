import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { TranslationService } from 'src/app/service/translation.service';
import { Config } from 'src/app/system/providers/configuration';

@Component({
  selector: 'app-country-codes',
  templateUrl: './country-codes.component.html',
  styleUrls: ['./country-codes.component.scss'],
})
export class CountryCodesComponent implements OnInit {

  @Input() code: any;
  @Output() countryChange = new EventEmitter<any>();

  countries: any[] = [];
  appConfigInfo: any;
  langs: any[] = [];
  langCode: any = 'en';

  constructor(
    public comService: CommonService,
    private config: Config,
    private transService: TranslationService,
  ) { }

  ngOnInit() {
    this.countries = this.comService.countries;
    this.appConfigInfo = this.config.getAppConfigInfo();
    if (this.appConfigInfo.languages) {
      this.langs = this.appConfigInfo.languages;
    }

    this.getCurrentLang();

    this.transService.changeLangSubject.subscribe(lang => {
      this.getCurrentLang();
    });
  }

  getCurrentLang() {
    var lang = this.transService.getSelectedLanguage();
    var cLang = this.langs.find(el => el.code == lang);
    if (cLang) {
      this.langCode = cLang.lang || 'en';
    }
    else {
      this.langCode = 'en';
    }
  }

  ngAfterViewInit() {

  }

  seelctCountry(country) {
    this.code = country.dial_code;

    setTimeout(() => {
      this.countryChange.emit({country_code: this.code});
    }, 260);
  }

}
