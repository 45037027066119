import { Inject, Injectable } from "@angular/core";
import { CashierProvider } from "../../modules/cashier/providers/cashier-provider";

@Injectable({
  providedIn: 'root'
})
export class MenuItemPreProcessor {
  constructor(
    public cashierProvider: CashierProvider
    ) {
  }

  menuItemPreProcessing(menuItem) {
    if (!!menuItem.preProcessAction) {
      this.process(menuItem);
    }
    if (!!menuItem.subItems && menuItem.subItems.length > 0) {
      menuItem.subItems.forEach((item) => {
        this.menuItemPreProcessing(item);
      });
    }
  }

  process(menuItem) {
    switch (menuItem.preProcessAction) {
      case "getSubItemsFromUrl":
        this.replaceSubItemsFromUrlIfExisting(menuItem);
        break;
      default:
        console.log("invalid preprocessor action");
    }
  }

  replaceSubItemsFromUrlIfExisting(menuItem) {


    if (!menuItem.subItemsUrl) {
      return;
    }
    // const url = this.webservices_url + menuItem.subItemsUrl;
    // console.log(url);
    try {
      // this.http
      //   .get(url)
      //   .map((res) => res.json()).
      //   subscribe((data) => {
        this.cashierProvider.getPayoutMethods()
        .subscribe((data) => {
          if (data.length > 0) {
            const responseItems = data;

            const formattedSubItems = responseItems
              .map((responseItem) => {
                return this.mapResponseItemToMenuItem(
                  menuItem.subItemsType,
                  responseItem
                );
              })
              .filter((a) => !!a);
            console.log(formattedSubItems);
            delete menuItem.subItemsUrl;
            delete menuItem.subItemsType;
            delete menuItem.preProcessAction;

            // replace default only if api did actually return data
            if (formattedSubItems.length > 0) {
              menuItem.subItems = formattedSubItems;
              // Add reverse withdrawal option
              menuItem.subItems.push(
                {
                  displayName: "Reverse Withdrawal",
                  component: "WithdrawalReverseWithdrawalPage"
                }
              );
            }
          }else {
            console.log('---------empty payout methods-----');
            delete menuItem.subItemsUrl;
            delete menuItem.subItemsType;
            delete menuItem.preProcessAction;
            menuItem.subItems = [];
              // Add reverse withdrawal option
            menuItem.subItems.push(
                {
                  displayName: "Reverse Withdrawal",
                  component: "WithdrawalReverseWithdrawalPage"
                }
            );
          }
        });
    } catch (e) {
      console.error("failed at getpayoutmethods api call", e);
    }
  }

  mapResponseItemToMenuItem(subItemsType: string, responseItem) {
    switch (subItemsType) {
      case "PayoutMethods":
        return this.mapWithdrawalResponseItemToMenuItem(responseItem);
      default:
        console.log("wrongly defined subItemsType");
        return;
    }
  }

  mapWithdrawalResponseItemToMenuItem(responseItem) {
    console.log(responseItem);
    let name:string;
    if(responseItem.PAYMENT_TYPE) {
      name = responseItem.PAYMENT_TYPE.name;
    }

    // we should not process, if init cap is required value should be initcap in db
    // name = name.toLowerCase().split( " ").map(word => word[0].toUpperCase() + word.slice(1)).join(" ");
    switch (responseItem.PAYMENT_TYPE && responseItem.PAYMENT_TYPE.code) {
      case "PPD":
        return {
          displayName:name ||  "Paypal",
          component: "WithdrawalPaypalPage",
        };
      case "ACH":
        return {
          displayName: "E-Cheque Bank Transfers",
          component: "WithdrawalEcheckSelectPage",
        };
      case "SL":
        return {
          displayName: "Marvel Tech Play+",
          component: "WithdrawalPrepaidPage",
        };
      case "CAGE":
        return {
          displayName: "Casino Cage",
          component: "WithdrawalBorgataPage",
          withdrawCheck: false
        };
      case 'IDS':
        return {
          displayName: "Instant Bank Transfer - iDebit",
          component: "WithdrawalIDebitPage",
        };
      case "MZACH":
        return {
          displayName: "ACH/E-Check",
          component: "WithdrawalEcheckSelectPage",
        };
      case "PWB":
        return {
          displayName: "Online Banking By Trustly",
          component: "WithdrawalPayWithMyBankPage",
        };
      case 'INSTA':
       return {
          displayName: "INSTADEBIT",
          component: "WithdrawalInstaDebitPage",
        }
      case "CH":
        return {
          displayName:"Check By Mail",
          component: "WithdrawalPolicyPage",
          custom: {
              "redirectPage": "WithdrawalMailPage"
          }
        };
      case "FAF":
          return {
            displayName: "FastAccess",
            component: "WithdrawlFastAccessPage",
          };
      case "GP":
        return {
          displayName: "VIP Preferred",
          component: "WithdrawalVipAchPage",
        }
      case 'MNI':
        return {
          displayName: "Interac Withdrawal",
          component: "WithdrawalInteracPage",
        }
      default:
        console.warn(
          'payment code not found or not mapped',
          {
            paymentId: responseItem.PAYOUT_METH_ID,
            code: responseItem.PAYMENT_TYPE && responseItem.PAYMENT_TYPE.code
          }
        );
        return undefined;
    }
  }
}
