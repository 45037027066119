import { Component, OnInit } from '@angular/core';
import { format } from 'date-fns';
import { CashierProvider } from 'src/app/modules/cashier/providers/cashier-provider';
import { CommonService } from 'src/app/service/common.service';
import { Navigator } from 'src/app/system/components/menu/navigator';
import { UserSessionProvider } from 'src/app/system/providers/user-session';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.page.html',
  styleUrls: ['./transactions.page.scss'],
})
export class TransactionsPage implements OnInit {

  displaySize = 10;

  data1: any[] = Array<any>(0);
  data2: any[] = Array<any>(0);
  data3: any[] = Array<any>(0);

  dataLoaded = false;
  pages = [];
  page = 1;
  totalCount = 0;

  tab;
  userSubscribe: any;

  constructor(
    public comService: CommonService,
    private cashierProvider: CashierProvider,
    public userSession: UserSessionProvider,
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.dataLoaded = false;
    if (!this.tab) {
      this.tab = 'deposit';
    }
    this.userSubscribe = this.userSession.getCustomer().subscribe(customer => {
      if (customer) {
        this.getData();
      }
    });
  }

  ionViewWillLeave() {
    this.tab = null;
    this.userSubscribe?.unsubscribe();
    this.userSubscribe = null;
  }

  getData(page = 1) { // last 14 days
    let date = new Date();
    let fromDate = format(new Date(date.setDate(date.getDate() - 14)), 'yyyy-MM-dd');
    let toDate = format(new Date(), 'yyyy-MM-dd');

    var transactionName = '';
    if (this.tab == 'deposit') {
      transactionName = 'Deposit';
    } else if (this.tab == 'withdraw') {
      transactionName = 'Withdrawal';
    } else {
      transactionName = 'Bonus';
    }

    var requestObj: any = {};
    // requestObj.currency_code = this.comService.defaulCurrency;
    requestObj.pageno = page;
    requestObj.date_from = fromDate;
    requestObj.date_to = toDate;
    requestObj.transaction_name = transactionName;

    this.cashierProvider.callGetTransactions(requestObj, transactionName).subscribe(
      (response: any) => {
        console.log('transactios == ', response);
        this.dataLoaded = true;
        if (response.code == 200 && response.result) {
          const serverPageSize = response.payload.page_size;
          if (serverPageSize) this.displaySize = serverPageSize;

          const serverTotalPages = response.payload.total_pages;
          const pageNumber = Number(response.payload.page_no);
          if (pageNumber) this.page = pageNumber;

          const data = response.payload.page_data || response.payload || [];;

          if (this.tab == 'deposit') {
            this.data1 = data;
          } else if (this.tab == 'withdraw') {
            this.data2 = data;
          } else {
            this.data3 = data;
          }

          if (this.page == 1) {
            this.getPageCount(serverTotalPages);
          }

          const totalCount = response.payload.count;
          this.totalCount = totalCount || (this.totalCount + data.length);
        }
      },
      (error) => {
        this.dataLoaded = true;
        console.error('transactios error == ', error);
      }
    );
  }

  getPageCount(pageCount = 0) {
    this.pages = [];
    if (pageCount) {
      for (let i = 0; i < pageCount; i++) {
        this.pages.push(i + 1);
      }
    } else {
      if (this.tab == 'deposit') {
        var tt = Math.floor(this.data1.length / this.displaySize);
        if (this.data1.length % this.displaySize > 0) tt++;
        for (let i = 0; i < tt; i++) {
          this.pages.push(i + 1);
        }
      } else if (this.tab == 'withdraw') {
        var tt = Math.floor(this.data2.length / this.displaySize);
        if (this.data2.length % this.displaySize > 0) tt++;
        for (let i = 0; i < tt; i++) {
          this.pages.push(i + 1);
        }
      } else if (this.tab == 'bonuses') {
        var tt = Math.floor(this.data3.length / this.displaySize);
        if (this.data3.length % this.displaySize > 0) tt++;
        for (let i = 0; i < tt; i++) {
          this.pages.push(i + 1);
        }
      }
    }
  }

  activePage(pp) {
    this.page = pp;
    this.getData(this.page);
  }

  changeTab(tab) {
    this.tab = tab;
    this.page = 1;
    this.totalCount = 0;
    this.dataLoaded = false;
    this.getData();
  }

  close() {
    Navigator.back();
  }

  showPrevPage() {
    if (this.page > 1) {
      this.page--;
      this.activePage(this.page);
    }
  }

  showNextPage() {
    if (this.page < this.pages.length) {
      this.page++;
      this.activePage(this.page);
    }
  }

}
