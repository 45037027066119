import { GameHubController } from './../../../gameHub/game-hub-controller';
import { Component, Input } from '@angular/core';
import { AuthenticationProvider } from '../../providers/authentication-provider';
import { Navigator } from './../../../../system/components/menu/navigator';
import { PopoverFactory } from '../../../../modules/popover/factory';
import { Config } from './../../../../system/providers/configuration';
import { JurisdictionProvider } from '../../../../shared/providers/jurisdiction';
import { MainProvider } from './../../../lobby/providers/main.provider';
import { NavParams, PopoverController } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';
import { CommonService } from 'src/app/service/common.service';
import { TranslationService } from 'src/app/service/translation.service';

@Component({
  selector: 'page-logout',
  template: '',
})
export class LogoutPage {

  data: any;

  private autoDismissTime = 3 * 1000;
  public responsibleGamingMessage: string;

  constructor(
    protected authProvider: AuthenticationProvider,
    protected popoverCtrl: PopoverController,
    private events: MyEvent,
    private config: Config,
    private jurisdiction: JurisdictionProvider,
    public gameHubController: GameHubController,
    private mainProvider: MainProvider,
    public comService: CommonService,
    private navParams: NavParams,
    private transService: TranslationService
  ) {
    if (this.navParams) {
      let {modal, ...others} = navParams.data;
      this.data = others;
    }

    this.getAppConfig();
    this.events.publishSomeData({ event: 'event:tracker:logout' });
    this.callLogout(this.data);
    this.events.publishSomeData({ event: 'event:lobby:show' });
  }

  callLogout(params: any = null) {
    console.log('logout param: ', params);

    // if playing a game on web, close the game and navigate to casino
    // if mobile - close the window
    if (this.gameHubController.gamesAreRunning()) {
      this.gameHubController.killAllInstances();
      // if (this.gameHubController.getDeviceType() == 'default') {
      //   this.events.publishSomeData({
      //     event: 'event:tabs:load',
      //     tab: 'dashboard',
      //   });
      // }
    }

    this.events.publishSomeData({
      event: 'event:tabs:load',
      tab: 'dashboard',
    });

    // this.events.publishSomeData({ event: 'event:geoComply:dismiss' });
    this.jurisdiction.unsubscribe();
    
    this.authProvider.callLogout(params).subscribe(
      async (response: any) => {
        console.log('logout resp == ', response);
        if (this.mainProvider.isIABApp()) {
          window['LOGOUT_EVENT'] = true;
        }

        setTimeout(() => {
          this.events.publishSomeData({ event: 'event:authentication:logout' });
        }, 1 * 1000);

        if (params && params.code) {
          switch (params.code) {
            case 10201:
              var msg = await this.transService.getTranslationWord('AUTH.LOGOUT.MULTI_DEVICE');
              this.showMessage(msg);
              break;
            case 'RUNNING_MULTIPLE_TABS':
              this.showMessage(
                `Your connection was severed. <br /><br />You are now logged out.<br /><br /> ${''}`
              );
              break;
            case 'INACTIVITY':
              var msg = await this.transService.getTranslationWord('AUTH.LOGOUT.INACTIVITY');
              this.showMessage(msg);
              break;
            case 'INVALID_DISCONNECT_TIME_ALLOWED':
              this.showLoginDialog();
              break;
            case 'QUALIFICATION_KBA_FAIL':
            case 'QUALIFICATION_ID_SCAN_FAIL':
              this.showMessage(
                `ID Verification failed.<br /><br />Please contact customer support.`
              );
              break;
            default:
              var msg = await this.transService.getTranslationWord('AUTH.LOGOUT.DEFAULT');
              this.showMessage(msg);
              break;
          }
        } else {
          var msg = await this.transService.getTranslationWord('AUTH.LOGOUT.DEFAULT');
          this.showMessage(msg);
        }
      },
      (error) => {
        console.error('logout error == ', error);
        Navigator.toLobby();
        // Navigator.toSports();
        this.events.publishSomeData({ event: 'event:lobby:ready' });
      }
    );
  }

  async showMessage(message = 'You have been logged out. Thanks for playing!') {
    var titleMsg = await this.transService.getTranslationWord('AUTH.LOGOUT.TITLE');
    let data = { title: titleMsg, message: message, closeButton: true };

    Navigator.toLobby();
    // Navigator.toSports();

    let page = PopoverFactory('PopoverContentPage');
    let popover = await this.popoverCtrl.create({
      component: page,
      componentProps: { data },
      showBackdrop: false,
      cssClass: 'small-popover',
    });
    popover.present();
    setTimeout(() => {
      popover.dismiss();
    }, this.autoDismissTime);
  }

  showLoginDialog() {
    Navigator.navigateTo('LoginPage', { forceModal: true, cssClass: 'login_modal' });
  }

  getAppConfig() {
    let appConfigInfo = this.config.getAppConfigInfo();

    if (appConfigInfo.env == 'colorado') {
      this.responsibleGamingMessage = 'Gambling problem? Call 1-800-522-4700';
    } else if (appConfigInfo.env == 'pennsylvania') {
      this.responsibleGamingMessage =
        'If you or someone you know has a gambling problem, help is available. Call us';
    } else if (appConfigInfo.env == 'iowa') {
      this.responsibleGamingMessage =
        'Gambling Problem? Call us';
    } else {
      this.responsibleGamingMessage =
        'If you or someone you know has a gambling problem<br>and wants help, call us';
    }
  }
}
