import { BannerProvider } from "./../../../../lobby/providers/banner.provider";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators, AbstractControl } from "@angular/forms";
import { RewardsProvider } from "./../../../providers/rewards-provider";
import { MainProvider } from "./../../../../../modules/lobby/providers/main.provider";
import * as moment from "moment";
import { SuccessMessageProvider } from "./../../../../../system/providers/success-message-provider";
import { IonContent, IonInput, NavParams } from "@ionic/angular";
import { TranslationService } from "src/app/service/translation.service";
import { Navigator } from "src/app/system/components/menu/navigator";

@Component({
  selector: "page-redeem-bonus-code",
  templateUrl: "redeem-bonus-code.html",
  styleUrls: ['./redeem-bonus-code.scss']
})
export class RedeemBonusCodePage implements OnInit {

  @ViewChild("Content") content: IonContent;
  @ViewChild('codeInput') codeInput: IonInput;

  public error = null;
  success = false;

  public redeemForm: FormGroup;

  public bonus_code: AbstractControl;

  constructor(
    private mainProvider: MainProvider,
    private rewardsProvider: RewardsProvider,
    public successMessageProvider: SuccessMessageProvider,
    public navParams: NavParams,
    private bannerProvider: BannerProvider,
    private transService: TranslationService
  ) {
    this.successMessageProvider.clearMessage();
    this.error = null;
    this.success = false;
  }

  ngOnInit() {
    this.redeemForm = new FormGroup({
      bonus_code: new FormControl("", [Validators.required]),
    });

    this.bonus_code = this.redeemForm.get("bonus_code");

    if (this.navParams.get("bonusCode")) {
      let bonusCode = this.navParams.get("bonusCode");
      this.redeemForm.patchValue({ bonus_code: bonusCode });
    }

    this.bannerProvider.fetchAllBanners();
  }

  ionViewDidLoad() {
    console.log("ionViewDidLoad RedeemBonusCodePage");
  }

  onInputChange($event) {
    const value = $event.target!.value;
    var filteredValue = value.replace(/[^0-9a-zA-Z]+/g, '');
    if(this.codeInput) this.codeInput.value = filteredValue;
    this.bonus_code.patchValue(filteredValue);
    this.error = null;
  }

  onSubmit() {
    this.error = null;
    this.success = false;
    console.log("onSubmit RedeemBonusCodePage", this.redeemForm.value);

    this.rewardsProvider
      .callRedeemBonus(this.redeemForm.get("bonus_code").value)
      .subscribe(
        (response: any) => {
          console.log("callRedeemBonus response = ", response);
          if (response.code == 200 && !!response.result) {
            this.bonus_code.setValue("");
            this.bonus_code.markAsPristine();
            let successMessage = "Your code was redeemed";
            if (!!response.payload && !!response.payload.granted_date) {
              successMessage += ` at ${moment(response.payload.granted_date).format('MM/DD/YY h:mm a')}`;
            }
            successMessage += ".";
            this.successMessageProvider.setMessage(successMessage);
            this.success = true;

            setTimeout(() => {
              this.content.scrollToTop();
            }, 320);
          }
          this.bannerProvider.fetchAllBanners();
        },
        async (error) => {
          console.error("callRedeemBonus error: ", error);

          // switch (error.getErrorCode()) {
          //   case 10316:
          //     var msg = await this.transService.getTranslationWord('REDEEM_BONUS.FAILD');
          //     error.setErrorMessage(msg);
          //     this.successMessageProvider.clearMessage();
          //     break;
          // }
          this.error = error;
          this.content.scrollToTop();
        }
      );
  }

  close() {
    Navigator.back();
  }
}
