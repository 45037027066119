import { TranslateService } from '@ngx-translate/core';
import { Config } from './configuration';
import { WebservicesProvider } from './webservices';
import { Injectable, SecurityContext } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MyEvent } from 'src/app/service/myevent.services';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationProvider {

  constructor(
    private webservice: WebservicesProvider,
    private config: Config,
    private translate: TranslateService,
    private comService: CommonService,
    public sanitizer: DomSanitizer,
    private events: MyEvent
  ) {

  }

  /*
    GET /configuration/get-all-countries
  */
  callGetAllCountries() {
    return this.webservice.get('configuration/get-all-countries');
  }

  /*
  GET /configuration/get-states-by-country
      country: string (country code)
  */
  callGetStatesByCountry(country_code: string) {
    return this.webservice.get('configuration/get-states-by-country', {
      country: country_code
    });
  }

  /*
  GET /configuration/get-avatars
  */
  callGetAvatars() {
    return this.webservice.get('configuration/get-avatars');
  }

  /*
  GET /configuration/time
  */
  callTime() {
    return this.webservice.get('configuration/time');
  }

  /*
  GET /configuration/get-form-metadata
  */
  callGetFormMetadata() {
    return this.webservice.get('configuration/get-form-metadata');
  }

  /*
  GET /configuration/get-form-metadata
  */
  getPageContent(page) {
    let webviewConfigUrl = this.config.getConfig().WORDPRESS_URL;

    if(page[0] != "/") page = "/" + page;

    let url = `${webviewConfigUrl}${page}`;
    const fullUrl = this.springbootCheck(url);
    var lang = '';
    if (this.translate.getDefaultLang() != 'en-US' && this.translate.getDefaultLang() != 'zh-CN') lang = '';
    else lang = this.translate.getDefaultLang() + '/';
    return this.webservice.get(`configuration/get-page-content?page=${fullUrl}${lang}`);
  }

  //Note: The url without a "/" at the end is not supported by springboot.
  springbootCheck(url: string): string{
    if (url[url.length - 1] != "/") return url = url + "/";
    return url;
  }

  /**
   * get footer content
   */
  getFooterContent() {
    this.comService.footerContent = '';
    let footerUrl = this.config.getFooterUrl();
    this.getPageContent(footerUrl).subscribe(
      (response) => {
        console.log('footer content == ', response);
        if (response.result == '0' || !response.payload.length) {
          console.log('---------No Footer Content in WP-----');
        } else {
          // let contentData = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(response.payload));
          // document.getElementById('footer_temp').innerHTML = contentData + '';
          this.comService.footerContent = this.sanitizer.bypassSecurityTrustHtml(response.payload);
          this.events.publishSomeData({event: 'footer:formatted:done', data: response.payload}); // footer:inited
        }
      },
      (error) => {
        console.error('footer error == ', error);
      }
    );
  }

}
