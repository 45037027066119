import { Injectable, ErrorHandler } from '@angular/core';

import { Navigator } from './../../system/components/menu/navigator';
import { MyEvent } from 'src/app/service/myevent.services';
import { TranslationService } from 'src/app/service/translation.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  private is_suppressed = false;
  private error_code;
  private error_message;
  private error_payload: any;
  private depositLimitMessageShow = true;

  constructor(
    private events: MyEvent,
    private transService: TranslationService
  ) {}

  handleError(error: any): GlobalErrorHandler {
    console.error('global error hadler: ', error);
    if (error.message) {
      console.log(error.message);
    }
    this.is_suppressed = false;
    if (error.payload) {
      this.error_payload = error.payload;
    } else {
      this.error_payload = null;
    }

    var translatedWord = this.transService.getTranslationWord(`ERRORS.${error.code}`);
    if (translatedWord) this.setErrorMessage(translatedWord);
    else this.setErrorMessage(error.message || error.payload);

    if (error && error.code) {
      switch (error.code) {
        case 10201:
          // logout
          this.is_suppressed = true;
          setTimeout(() => {
            Navigator.navigateTo(
              'LogoutPage',
              {},
              { code: 10201, reason: 'API_INVALID_SESSION_TOKEN' }
            );
          }, 3000);
          break;
        case 'INVALID_DISCONNECT_TIME_ALLOWED':
          this.is_suppressed = true;
          Navigator.navigateTo(
            'LogoutPage',
            {},
            {
              code: 'INVALID_DISCONNECT_TIME_ALLOWED',
              reason: 'INVALID_DISCONNECT_TIME_ALLOWED',
            }
          );
          break;
        case 'GEOLOCATION_PASSTOFAIL':
          break;
        default:
          break;
      }
      this.setErrorCode(error.code);
    } else {
      try {
        console.error(JSON.stringify(error));
      } catch (err) {
        console.error(
          '*************' + error + ', object type: ' + typeof error
        );
      }
    }

    //should at least send some kind of response if the above switch doesnt match any cases
    return this;
  }

  setErrorCode(error_code) {
    this.error_code = error_code;
  }

  getErrorCode() {
    return this.error_code;
  }

  setErrorMessage(error_message) {
    this.error_message = error_message;
  }

  getErrorMessage() {
    return this.error_message;
  }

  hasError() {
    return null != this.error_message;
  }

  getPayload() {
    if (this.error_payload) {
      if (this.error_payload.errors) {
        if (Array.isArray(this.error_payload.errors)) {
          return this.error_payload.errors[0].message;
        }
      }

      if (Array.isArray(this.error_payload)) {
        return this.error_payload[0].message;
      }

      if (this.error_payload.message) {
        return this.error_payload.message;
      }

      return this.error_payload;
    } else {
      return false;
    }
  }

  isSuppressed() {
    return this.is_suppressed;
  }
}
