import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MyEvent } from 'src/app/service/myevent.services';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/service/common.service';
import { UserSessionProvider } from '../../providers/user-session';
import { CashierContentProvider } from 'src/app/modules/cashier/providers/cashier-content-provider';
import { Config } from '../../providers/configuration';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'footer-component',
  templateUrl: './footer.html',
  styleUrls: ['./footer.scss'],
})
export class FooterComponent implements OnInit, OnDestroy, AfterViewInit {

  pageContent: any = '';

  subs: any[] = [];
  gameCategories: any[] = [];

  constructor(
    private events: MyEvent,
    public sanitizer: DomSanitizer,
    public comService: CommonService,
    public userSession: UserSessionProvider,
    private contentProvider: CashierContentProvider,
    private cdr: ChangeDetectorRef,
    private config: Config,
    private navCtrl: NavController
  ) {
    this.events.getObservable().subscribe(data => {
      if (data.event == 'footer:formatted:done') {
        setTimeout(() => {
          this.comService.footerContent = this.sanitizer.bypassSecurityTrustHtml(data.data); // document.getElementById('footer_temp').innerHTML;

          // this.gameCategories = comService.getGameKeywords;
          this.registerExternalLink();
        }, 500);
      }
    });
  }

  ngOnInit(): void {
    this.subs.push(this.userSession.getCustomer().subscribe(customer => {
      if (customer) {
        this.getPaymentDataFromServer();
      } else {
        this.comService.walletPaymentSub.next(null);
      }
    }));

    this.subs.push(this.comService.gameKeywordsSubject.subscribe(data => {
      this.gameCategories = data;
      console.log('gameCategories == ', this.gameCategories);
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
  }

  ionViewDidEnter() {}

  ngAfterViewInit(): void {
    this.registerExternalLink();
  }

  registerExternalLink() {
    setTimeout(() => {
      var eles = document.querySelectorAll('.external-footer-link');
      console.log('eles == ', eles);
      if (eles.length) {
        eles.forEach(ele => {
          ele.addEventListener('click', () => {
            var dataRoute = ele.getAttribute('data-route');
            console.log('dataRoute == ', dataRoute);
            if (dataRoute) {
              this.navCtrl.navigateForward(`${this.comService.currentTemplate}/${dataRoute}`);
              this.events.publishSomeData({event: 'event:sidebar:item', data: dataRoute});
            }

            var dataCategory = ele.getAttribute('data-category');
            if (dataCategory) {
              var ttm = this.gameCategories.find(el => el.keyword_code == dataCategory);
              if (ttm) {
                // this.events.publishSomeData({event: 'event:sidebar:item', data: ttm.keyword_code});

                // var cIndex = this.gameCategories.findIndex(el => el.keyword_code == dataCategory);
                // this.events.publishSomeData({event: 'event:tabs:load', tab: 'dashboard'});
                // setTimeout(() => {
                //   this.events.publishSomeData({event: 'event:casino:item', data: cIndex});
                // }, 150);
                this.navCtrl.navigateForward(`${this.comService.currentTemplate}/games/${ttm.keyword_code}`);
              }
              else if (dataCategory == 'sports') {
                this.events.publishSomeData({event: 'event:sidebar:item', data: ''});
                this.events.publishSomeData({event: 'event:load:sports'});
              }
            }
          });
        });
      }
    }, 2000);
  }

  getPaymentDataFromServer() {
    this.contentProvider.getActiveTypesCategories({ include_types: true }).subscribe(
      (response: any) => {
        console.log('payment methods == ', response);
        if (response.code == 200 && response.result) {
          var payload = response.payload;
          this.comService.walletPaymentSub.next(payload);
        }
        this.cdr.detectChanges();
      },
      (error) => {
        console.error('payment err == ', error);
      }
    );
  }
}
