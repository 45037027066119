import { AuthenticationProvider } from './../../providers/authentication-provider';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Config } from './../../../../system/providers/configuration';
import { Navigator } from './../../../../system/components/menu/navigator';
import { AppLinks } from './../../../../shared/app-links';
import { IonInput, NavController } from '@ionic/angular';
import { CommonService } from 'src/app/service/common.service';
import { TranslationService } from 'src/app/service/translation.service';
import { WebservicesProvider } from 'src/app/system/providers/webservices';

@Component({
  selector: 'page-forgot-password',
  templateUrl: 'forgot-password.html',
  styleUrls: ['./forgot-password.scss'],
})
export class ForgotPasswordPage implements OnInit {

  @ViewChild('verifyCodeInput') verifyCodeInput: IonInput;

  public isComplete: boolean = false;
  public email: AbstractControl;
  phone: AbstractControl;
  verifyCode: AbstractControl;
  public password: AbstractControl;
  public emailForm: FormGroup;
  public feautureCustomerSupportURL: string;
  mainLogo: any;

  websiteName: any;

  signinType = 'email';
  showCountrySelection = false;
  countryCode: any;

  error: any;

  appConfigInfo: any;

  canInputVerifyCode = false;
  disableGetCodeButton = false;
  resendInterval: any;
  resendTimePeriod = 60;

  verifiedStatus = false;
  verificationCode: any;

  constructor(
    public navCtrl: NavController,
    public authProvider: AuthenticationProvider,
    private config: Config,
    private appLinks: AppLinks,
    public comService: CommonService,
    private transService: TranslationService,
    private cdr: ChangeDetectorRef,
    private webService: WebservicesProvider
  ) {}

  ngOnInit() {
    this.websiteName = document.querySelector('title').text;

    this.appConfigInfo = this.config.getAppConfigInfo();
    this.mainLogo = this.appConfigInfo.main_logo || 'assets/imgs/logo.png';

    this.countryCode = this.appConfigInfo.country_code;

    this.emailForm = new FormGroup({
      email: new FormControl('', [
        Validators.pattern(
          '^([0-9a-zA-Z]+[-._+&amp;])*[0-9a-zA-Z]+@([-0-9a-zA-Z]+[.])+[a-zA-Z]{2,6}$'
        ),
        Validators.required,
        Validators.email
      ]),
      phone: new FormControl('', [
        Validators.pattern('^[0-9]+$')
      ]),
      verifycode: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(8)
      ]),
      password: new FormControl('', [
        Validators.minLength(6),
        Validators.required
      ]),
    });

    if (this.appConfigInfo.auth_type.reset_password_by_link) {
      this.emailForm.removeControl('verifycode');
      this.emailForm.removeControl('password');
    } else {
      this.verifyCode = this.emailForm.get('verifycode');
      this.password = this.emailForm.get('password');
    }

    this.email = this.emailForm.get('email');
    this.phone = this.emailForm.get('phone');
    this.feautureCustomerSupportURL = this.config.getConfig().FEATURE_CUSTOMER_SUPPORT_URL || '';
  }

  goToFeautureCustomerSupportURL() {
    if (!this.feautureCustomerSupportURL) return;
    this.appLinks.openExternalPage(this.feautureCustomerSupportURL);
    return false;
  }

  callForgotPassword() {
    this.error = null;
    this.emailForm.value.client_app_id = this.config.getClientApp();
    this.authProvider.callForgotPassword(this.emailForm.value).subscribe(
      async (response: any) => {
        if (response.code == 200 && response.result) {
          this.isComplete = true;
          var msg = await this.transService.getTranslationWord('AUTH.FORGOT_PASSWORD.SUCCESS_MSG');
          this.comService.showToast(msg);
          this.onBack();
        }
      },
      (error) => {
        this.error = error;
        this.isComplete = true;
        // this.onBack();
      }
    );
  }

  changeEmail($event) {
    this.error = null;
    this.canInputVerifyCode = false;
    this.disableGetCodeButton = false;
    this.verificationCode = '';
    if (this.verifyCodeInput) this.verifyCodeInput.value = '';
    this.verifyCode.patchValue('');
    this.verifyCode.markAsUntouched();
    this.verifyCode.markAsPristine();
    this.verifiedStatus = false;
  }

  inputVerificationCode($event) {
    this.verifyCode.setErrors(null);
    var val = $event.target.value;
    var filteredValue = val; //.replace(/[^0-9\s]*$/g, '');
    this.verificationCode = filteredValue;
    if (this.verifyCodeInput) this.verifyCodeInput.value = filteredValue;
    this.verifyCode.patchValue(filteredValue);
    this.verifyCode.markAsTouched();
    this.verifyCode.markAsDirty();
    this.verifiedStatus = false;

    if (this.verificationCode.length == 8) {
      this.verifyEmailCode();
    }
  }

  getCode() {
    this.webService.post('auth/password/forgot', {
      email: this.email.value,
      skin_id: 1
    }).subscribe(response => {
      if (response.code == 200 && response.result) {
        this.canInputVerifyCode = true;
        this.disableGetCodeButton = true;

        clearInterval(this.resendInterval);
        this.resendInterval = null;
        this.resendTimePeriod = 60;

        this.resendInterval = setInterval(() => {
          this.resendTimePeriod--;

          if (this.resendTimePeriod <= 0) {
            clearInterval(this.resendInterval);
            this.resendInterval = null;
            this.disableGetCodeButton = false;
            this.resendTimePeriod = 60;
          }

          this.cdr.detectChanges();
        }, 1000);
      }
    }, err => {
      console.error('err = ', err);
      this.error = err;
      this.email.setErrors({WRONG: true});
    });
  }

  verifyEmailCode() {
    this.webService.post('auth/forgot/password/validate/code', {
      email: this.email.value,
      code: this.verifyCode.value,
      skin_id: 1
    }, false).subscribe(response => {
      if (response.code == 200 && response.result) {
        this.verifiedStatus = true;
      }
    }, err => {
      this.verifyCode.setErrors({WRONG_CODE: true});
    });
  }

  callResetPassword() {
    this.webService.post('auth/password/reset/by-email/v2', {
      email: this.email.value,
      newpassword: this.password.value,
      code: this.verifyCode.value,
      skin_id: 1,
      clientapp: this.config.getClientApp()
    }).subscribe(async (response) => {
      this.isComplete = true;
      var msg = await this.transService.getTranslationWord('AUTH.FORGOT_PASSWORD.SUCCESS_RESET_MSG');
      this.comService.showToast(msg);
      Navigator.back();
      Navigator.navigateTo('LoginPage', { forceModal: true, cssClass: 'login_modal' });
    }, error => {
      this.password.setErrors({WRONG: true});
      this.cdr.detectChanges();
    });
  }

  onBack() {
    // if (this.isComplete === true) {
    //   Navigator.navigateTo('ForgotPasswordPage', {}, {});
    // } else {
      Navigator.back();
      Navigator.navigateTo('LoginPage', { forceModal: true, cssClass: 'login_modal' });
    // }
  }

  onSignup() {
    var code = null;
    if (localStorage.getItem('b_referral_code')) {
      var temp = localStorage.getItem('b_referral_code');
      code = temp.split(':')[0];
    }
    if (this.comService.isMobileWeb) {
      Navigator.navigateTo("LoginPage", { forceModal: true, cssClass: 'login_modal' }, { screenType: 'signup', signupCode: code });
    } else {
      Navigator.back();
      Navigator.navigateTo("SignupStepOnePage", { forceModal: true, cssClass: 'signup_modal', disableBackdrop: true }, { signupCode: code });
    }
  }

  googleLogin() {
    this.authProvider.googleLoginFunc();
  }

  changeSigninType(type) {
    this.signinType = type;
    if (type == 'email') {
      this.email.addValidators(Validators.required);
      this.emailForm.get('phone').removeValidators(Validators.required);
    } else {
      this.email.removeValidators(Validators.required);
      this.emailForm.get('phone').addValidators(Validators.required);
    }
    this.emailForm.get('verifycode').patchValue('');
    this.showCountrySelection = false;
  }

  showCountries() {
    this.showCountrySelection = !this.showCountrySelection;
  }

  changeCountry($event) {
    this.countryCode = $event.country_code;
    this.showCountrySelection = !this.showCountrySelection;
  }

  clickOutside() {
    setTimeout(() => {
      this.showCountrySelection = false;
    }, 670);
  }

  passType = 'password';
  changeTypeOfPassword() {
    if (this.passType == 'password') this.passType = 'text';
    else this.passType = 'password';
  }
}
