import { Injectable } from '@angular/core';
import { Utils } from '../utils';
//
import { UserSessionProvider } from '../../system/providers/user-session';
//
import { AppsflyerTracker } from './appsflyer';
import { GoogleTracker } from './google-tracker';
import { IncomeAccessTracker } from './incomeaccess-tracker';
import { IncomeAccessMobileTracker } from './incomeaccess-mobile-tracker';
import { FacebookAppevents } from './facebook-appevents';
import { CaptureMediatag } from './capture-mediatag';
import { XtremepushTracker } from './xtremepush-tracker';
//
import Cookies from 'js-cookie';
import pfRIC from 'request-idle-callback';
import { Platform } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';

export interface Offer {
  campaignId?: string;
  thumbnail_image?: string;
  description?: string;
  campaign_type?: string;
  signupcode?: string;
  tagline?: string;
  clientapp?: string;
}
export interface AffiliateTrackerCallbackParams {
  customer_id: number | string;
  amount?: number | string;
  paymentMethod?: string;
  transaction_id?: number | string;
  game_name?: string;
  game_id?: string;
  is_demo?: boolean;
  errorCode?: string;
  errorName?: string;
  offer?: Offer;
  url?: string;
  sessionToken?: string;
  game_provider?: string;
  wager_amount?: string;
  wager_trx_id?: string;
  deposit_count?: string;
  withdrawal_count?: string;
  total_deposit?: string;
  total_withdrawal?: string;
  registration_complete?: string;
  deposit?: string;
  bet_placed?: string;
  restricted_amount?: string;
  bonusCode?: string;
  bonusID?: string;
  promo_code?: string;
}

export interface AffiliateTrackerInterface {
  initialize(): Promise<AffiliateTrackerInterface>;
  getTrackerName?(): string;
  captureParameters?(): Array<string>;
  setAttributionData?(data: any): void;
  // supported events:
  signupStep1Complete?(cbParams: AffiliateTrackerCallbackParams): void;
  signupComplete?(cbParams: AffiliateTrackerCallbackParams): void;
  deposit?(cbParams: AffiliateTrackerCallbackParams): void;
  withdraw?(cbParams: AffiliateTrackerCallbackParams): void;
  login?(cbParams: AffiliateTrackerCallbackParams): void;
  gameLaunched?(cbParams: AffiliateTrackerCallbackParams): void;
  loginError?(cbParams: AffiliateTrackerCallbackParams): void;
  forgotPassword?(cbParams: AffiliateTrackerCallbackParams): void;
  closeAccount?(cbParams: AffiliateTrackerCallbackParams): void;
  addToBetslip?(data: any): void;
  betPlaced?(data: any): void;
  sendPageHistoryEvent?(data: any): void;
  conversion?(cbParams: AffiliateTrackerCallbackParams): void;
  gameClosed?(): void;
  logout?(): void;
  bannerClick?(cbParams: AffiliateTrackerCallbackParams): void;
  depositFailed?(cbParams: AffiliateTrackerCallbackParams): void;
}

@Injectable({
  providedIn: 'root',
})
export class AffiliationEventProvider {
  isInitialized: boolean = false;
  attributionData: any;

  constructor(
    private platform: Platform,
    private events: MyEvent,
    private utils: Utils,
    private userSession: UserSessionProvider,
    private appsFlyerTracker: AppsflyerTracker,
    private googleTracker: GoogleTracker,
    private incomeAccessTracker: IncomeAccessTracker,
    private incomeAccessMobileTracker: IncomeAccessMobileTracker,
    private facebookTracker: FacebookAppevents,
    private captureMediatag: CaptureMediatag,
    private xtremepushTracker: XtremepushTracker
  ) {
    this.events.getObservable().subscribe((data) => {
      if (data.event == 'event:affiliation:initialize') this.initialize();
    });
  }

  protected affiliateTrackers(): Array<AffiliateTrackerInterface> {
    return this.platform.is('cordova')
      ? [
          // MOBILE Trackers
          this.appsFlyerTracker, this.googleTracker, this.incomeAccessMobileTracker, this.facebookTracker, this.xtremepushTracker
        ]
      : [
          // WEB trackers
          this.googleTracker, this.incomeAccessTracker,  this.captureMediatag, this.xtremepushTracker
        ];
  }

  /**
   * Initialize the affiliate tracking depending on whether we are web (Cake) or mobile (Kochava)
   */
  initialize(): Promise<any> {
    if (!this.isInitialized) {
      this.captureAffiliateParams();
    }
    //
    this.events.unsubscribe('event:signupstep1complete');
    this.events.unsubscribe('event:signupcomplete');
    this.events.unsubscribe('event:deposit');
    this.events.unsubscribe('event:login');
    this.events.unsubscribe('event:withdraw');
    this.events.unsubscribe('event:gameLaunched');
    this.events.unsubscribe('event:login:error');
    this.events.unsubscribe('event:close:account');
    this.events.unsubscribe('password:reset');
    this.events.unsubscribe('sports:place:bet');
    this.events.unsubscribe('sports:add:bet');
    this.events.unsubscribe('event:history:push');
    this.events.unsubscribe('event:conversion:set');
    this.events.unsubscribe('event:tracker:gameClosed');
    this.events.unsubscribe('event:tracker:bannerClick');
    this.events.unsubscribe('event:tracker:logout');
    this.events.unsubscribe('event:depositFailed');

    return Promise.all(
      this.affiliateTrackers().map((tracker: AffiliateTrackerInterface) =>
        tracker
          .initialize()
          .then((tracker: AffiliateTrackerInterface) => {
            if (tracker) {
              if (tracker.getTrackerName) {
                console.log('tracker initialize: ', tracker.getTrackerName());
              }
              console.log('TRACKER', tracker);
              this.setupEvents(tracker);
            }
          })
          .catch((e) => {
            console.log('tracker initialize failure: ', e);
          })
      )
    );
  }

  protected setupEvents(tracker: AffiliateTrackerInterface) {
    // subscribe to all events that are support by each AffiliateTracker

    if ('function' === typeof tracker.signupStep1Complete) {
      this.events.getObservable().subscribe((data) => {
        if (data.event == 'event:signupstep1complete') {
          pfRIC.requestIdleCallback(() =>
            tracker.signupStep1Complete(data.data)
          );
        }
      });
    }
    if ('function' === typeof tracker.signupComplete) {
      this.events.getObservable().subscribe((data) => {
        if (data.event == 'event:signupcomplete') {
          pfRIC.requestIdleCallback(() => tracker.signupComplete(data.data));
        }
      });
    }
    if ('function' === typeof tracker.login) {
      this.events.getObservable().subscribe((data) => {
        if (data.event == 'event:login') {
          pfRIC.requestIdleCallback(() => tracker.login(data.data));
        }
      });
    }
    if ('function' === typeof tracker.loginError) {
      this.events.getObservable().subscribe((data) => {
        if (data.event == 'event:login:error') {
          pfRIC.requestIdleCallback(() => tracker.loginError(data.data));
        }
      });
    }
    if ('function' === typeof tracker.deposit) {
      this.events.getObservable().subscribe((data) => {
        if (data.event == 'event:deposit') {
          pfRIC.requestIdleCallback(() => tracker.deposit(data.data));
        }
      });
    }
    if ('function' === typeof tracker.withdraw) {
      this.events.getObservable().subscribe((data) => {
        if (data.event == 'event:withdraw') {
          pfRIC.requestIdleCallback(() => tracker.withdraw(data.data));
        }
      });
    }
    if ('function' === typeof tracker.gameLaunched) {
      this.events.getObservable().subscribe((data) => {
        if (data.event == 'event:gameLaunched') {
          pfRIC.requestIdleCallback(() => tracker.gameLaunched(data.data));
        }
      });
    }
    if ('function' === typeof tracker.forgotPassword) {
      this.events.getObservable().subscribe((data) => {
        if (data.event == 'event:password:reset') {
          pfRIC.requestIdleCallback(() => tracker.forgotPassword(data.data));
        }
      });
    }
    if ('function' === typeof tracker.closeAccount) {
      this.events.getObservable().subscribe((data) => {
        if (data.event == 'event:close:account') {
          pfRIC.requestIdleCallback(() => tracker.closeAccount(data.data));
        }
      });
    }

    if ('function' === typeof tracker.addToBetslip) {
      this.events.getObservable().subscribe((data) => {
        if (data.event == 'sports:place:bet') {
          pfRIC.requestIdleCallback(() => tracker.addToBetslip(data.data));
        }
      });
    }
    if ('function' === typeof tracker.betPlaced) {
      this.events.getObservable().subscribe((data) => {
        if (data.event == 'sports:add:bet') {
          pfRIC.requestIdleCallback(() => tracker.betPlaced(data.data));
        }
      });
    }
    if ('function' === typeof tracker.sendPageHistoryEvent) {
      this.events.getObservable().subscribe((data) => {
        if (data.event == 'event:history:push') {
          pfRIC.requestIdleCallback(() =>
            tracker.sendPageHistoryEvent(data.data)
          );
        }
      });
    }
    if ('function' === typeof tracker.conversion) {
      this.events.getObservable().subscribe((data) => {
        if (data.event == 'event:conversion:set') {
          pfRIC.requestIdleCallback(() => tracker.conversion(data.data));
        }
      });
    }
    if ('function' === typeof tracker.gameClosed) {
      this.events.getObservable().subscribe((data) => {
        if (data.event == 'event:tracker:gameClosed') {
          pfRIC.requestIdleCallback(() => tracker.gameClosed());
        }
      });
    }
    if ('function' === typeof tracker.logout) {
      this.events.getObservable().subscribe((data) => {
        if (data.event == 'event:tracker:logout') {
          pfRIC.requestIdleCallback(() => tracker.logout());
        }
      });
    }
    if ('function' === typeof tracker.depositFailed) {
      this.events.getObservable().subscribe((data) => {
        if (data.event == 'event:depositFailed') {
          pfRIC.requestIdleCallback(() => tracker.depositFailed(data.data));
        }
      });
    }
  }

  /**
   * get the affiliate data from the affiliateTracker (Cake or Kochava)
   */
  getAttributionData(): Promise<any> {
    if (!this.isInitialized) {
      return this.captureAffiliateParams$();
    } else {
      return Promise.resolve(this.attributionData);
    }
  }

  captureAffiliateParams(): any {
    this.captureAffiliateParams$().then((attributionData: any) => {
      if (attributionData) {
        this.affiliateTrackers()
          .filter(
            (tracker: AffiliateTrackerInterface) =>
              'function' === typeof tracker.setAttributionData
          )
          .map((tracker: AffiliateTrackerInterface) =>
            tracker.setAttributionData(attributionData)
          );
        this.attributionData = attributionData;
      }
    });
  }

  protected captureAffiliateParams$(): Promise<any> {
    this.isInitialized = true;

    this.userSession.getCustomer().subscribe((customer: any) => {
      if (customer && customer.customerAttributionData) {
        var attributionData =
          'string' === typeof customer.customerAttributionData
            ? JSON.parse(customer.customerAttributionData)
            : customer.customerAttributionData;

        this.affiliateTrackers()
          .filter(
            (tracker: AffiliateTrackerInterface) =>
              'function' === typeof tracker.setAttributionData
          )
          .map((tracker: AffiliateTrackerInterface) =>
            tracker.setAttributionData(attributionData)
          );
      }
    });

    var urlParams: any = this.utils.getUrlParams();

    const btag_cookie: string = this.utils.getCookieParams('btag');
    if (btag_cookie) {
      urlParams.btag = btag_cookie;
    }

    this.affiliateTrackers()
      .filter(
        (tracker: AffiliateTrackerInterface) =>
          'function' === typeof tracker.captureParameters
      )
      .map((tracker: AffiliateTrackerInterface) => tracker.captureParameters())
      .map((paramList: Array<string>) => {
        paramList.map((param: string) => {
          if ('undefined' !== typeof urlParams[param]) {
            Cookies.set('pala-affiliate', JSON.stringify(urlParams), {
              domain: this.utils.getDomain(),
            });
            return Promise.resolve(urlParams);
          }
        });
      });

    var raw_attr_data: string = this.utils.getCookieParams('pala-affiliate');
    var cookie_data: any = null;

    if (raw_attr_data) {
      try {
        cookie_data = JSON.parse(raw_attr_data);
      } catch {
        cookie_data = null;
      }
    }

    return Promise.resolve(cookie_data);
  }

  getAppnexusData() {
    return this.utils.getCookieParams('appnexus');
  }
}
