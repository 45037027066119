// import { isUndefined } from 'util';
import { CustomerModel } from './../../account/models/customer-model';
import { Injectable } from '@angular/core';
import { Config } from '../../../system/providers/configuration';
import {
  WebservicesProvider,
  PlatformResponse,
} from '../../../system/providers/webservices';
import { UserSessionProvider } from '../../../system/providers/user-session';
import { Campaign } from '../../../shared/campaign-interface';
import { MyEvent } from 'src/app/service/myevent.services';

@Injectable({
  providedIn: 'root',
})
export class BannerProvider {

  public customer: CustomerModel;
  // private banners;

  constructor(
    private config: Config,
    private webservice: WebservicesProvider,
    private userSession: UserSessionProvider,
    private events: MyEvent
  ) {
    // this.banners = new Map();
    this.userSession.getCustomer().subscribe((customer) => {
      this.customer = customer;
      //setTimeout(()=> this.fetchAllBanners(), 15000);
    });
    
  }

  getBanners(lobbyName): Promise<any> {
    return new Promise((resolve, reject) => {
      if (
        this.customer &&
        this.customer.customerId &&
        this.userSession &&
        this.userSession.sessionToken
      ) {
        this.webservice
          .get('lobby/' + lobbyName + '/bonus-offers', {
            player_id: this.customer.customerId,
            session_id: this.userSession.sessionToken,
          })
          .subscribe(
            (res) => {
              // this.banners.set(lobbyName, res);
              console.log('BANNERS from fetch:', res);
              resolve(res);
            },
            (err) => {
              reject(err);
            }
          );
      } else {
        // logged out state
        console.log('Banner get here?');
        this.webservice
          .get(
            'lobby/' + this.config.getConfig().LOBBY_NAME + '/bonus-offers',
            {
              client_app_id: this.config.getClientApp(),
            }
          )
          .subscribe(
            (res) => {
              // this.banners.set('_logoutState', res);
              console.log('BANNERS from fetch _logoutState:', res);
              resolve(res);
            },
            (err) => {
              reject(err);
            }
          );
      }
    });
  }

  // fetch all product verticals banners
  fetchAllBanners() {
    let productVerticals = this.config.getConfig().PRODUCTVERTICALS;
    if (
      this.customer &&
      this.customer.customerId &&
      this.userSession &&
      this.userSession.sessionToken
    ) {
      productVerticals.forEach((product, index) => {
        let lobbyName = product.lobby_name;
        this.webservice
          .get('lobby/' + lobbyName + '/bonus-offers', {
            player_id: this.customer.customerId,
            session_id: this.userSession.sessionToken,
          })
          .subscribe((res: PlatformResponse<Array<Campaign>>) => {
            console.log(index, 'BANNER INDEX', productVerticals.length - 1);
            // this.banners.set(lobbyName, res);
            console.log(
              'BANNERS from fetchAllBanners lobbyname:',
              lobbyName,
              res
            );
            if (index === productVerticals.length - 1) {
              setTimeout(() => {
                this.events.publishSomeData({ event: 'event:update:banners' });
                console.log('Banners from event was sent');
              }, 500);
            }
          });
      });
    } else {
      this.webservice
        .get('lobby/' + this.config.getConfig().LOBBY_NAME + '/bonus-offers', {
          client_app_id: this.config.getClientApp(),
        })
        .subscribe((res) => {
          // this.banners.set('_logoutState', res);
          console.log('BANNERS from fetchAllBanners _logoutState:', res);
          this.events.publishSomeData({ event: 'event:update:banners' });
        });
    }
  }
}
