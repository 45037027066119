import { NgModule } from '@angular/core';
import { MyTransactionMethodsConfirmPage } from './my-transaction-methods-confirm';
import { SharedModule } from '../../../../shared/shared.module';
import { SystemModule } from './../../../../system/system.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    MyTransactionMethodsConfirmPage,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SystemModule,
    IonicModule
  ],
})
export class MyTransactionMethodsConfirmPageModule {}
