// ENGLISH
export const locale = {
  lang: 'pt-BR',
  data: {
    LEFT_SIDE: {
      EARN_CATEGORY_TITLE: 'Ganhe',
      REFERRAL: 'Indicação',
      VIP: 'VIP Club',
      LANGS: {
        ENGLISH: 'English',
        CHINESE: '简体中文',
        PORTUGUES: 'Português',
        THAILIAND: 'ภาษาไทย',
        VIETNAME: 'Tiếng Việt',
        SPANISH: 'Español',
        INDONESIAN: 'Bahasa Indonesia',
        JAPANESE: '日本語'
      },
      INTEREST: "interest",
      REFER_EARN: "Refer & Earn",
      VIP_CLUB: '<span style="color: #ffb636;">VIP</span> Club'
    },
		HEADER: {
      SPORT_BUTTON_TEXT: "Esportes",
      CASINO_BUTTON_TEXT: "Cassino",
      PROMOTIONS: 'Promoções',
      SEARCH: "Search",
      SEARCH_PLACEHOLDER: "Pesquisar jogos ou provedores",
      SEARCH_PART_GAMES: "Jogos",
      SEARCH_PART_PROVIDERS: "Provedores",
      SEARCH_RESULT: "Search Results",
      SEARCH_NO_RESULT: "Nenhum resultado de pesquisa encontrado",
      SEARCH_TRY_GAMES: "Recommended",
      RECOMMENDED_GAMES: "Recommended for you",
      LOGIN_BUTTON_TEXT: "Entrar",
      REGISTER_BUTTON_TEXT: "Registrar-se",
      BALANCE_INGAME: "NO JOGO",
      DEPOSIT_BUTTON_TEXT: "Carteira",
      Settings: "Settings",
      ALL_GAMES: "All Games",
      SELECT_GAME: "Select Game"
    },
    BALANCES: {
      FIAT: 'Fiat',
      CRYPTO: 'Crypto',
      DISPLAY_FIAT: 'Exibir em Fiat'
    },
    MOBILE_FOOTER: {
      BETSPLUS: {
        SPORT_TAB_TEXT: 'Esportes',
        CASINO_TAB_TEXT: 'Cassino',
        PROMOTIONS_TAB_TEXT: 'Promoções',
        MENU_TAB_TEXT: 'Menu'
      }
    },
    DASHBOARD: {
      CATEGORY_LOBBY_NAME: "Lobby",
      LAST_PLAYED: "Recentes",
      VIEWALL_BUTTON_TEXT: "Todos",
      VIEWALL_BUTTON_TEXT1: "View All",
      ALL_GAMES_COUNT: "Todos os {{count}}",
      NO_GAMES: "Nenhum jogo encontrado",
      GAME_PROVIDERS_TITLE: "Provedores de jogos",
      RECORDS: {
        TITLE: "Registro",
        TABS: {
          MY_BETS_TITLE: "Minhas apostas",
          ALL_BETS_TITLE: "Todas as apostas",
          RARE_WINS_TITLE: 'Grandes vitórias',
        },
        DATA: {
          BETS_TABLE: {
            COLUMN1: 'Jogo',
            COLUMN2: 'Nome de usuário',
            COLUMN3: 'Hora',
            COLUMN4: 'Valor da aposta',
            COLUMN5: 'Multiplicador',
            COLUMN6: 'Pagamento'
          },
          WINS_TABLE: {
            COLUMN1: 'Jogo',
            COLUMN2: 'Nome de usuário',
            COLUMN3: 'Valor da aposta',
            COLUMN4: 'Multiplicador',
            COLUMN5: 'Pagamento'
          },
          WINS_TABLE_BETSPLUS: {
            COLUMN1: 'Classificação',
            COLUMN2: 'Nome de usuário',
            COLUMN3: 'Apostado',
            COLUMN4: 'Prêmio'
          },
          NO_MY_BETS_TEXT: "Nenhuma aposta minha",
          NO_ALL_BETS_TEXT: "Nenhuma aposta encontrada",
          NO_RARE_WINS_TEXT: "Nenhuma grande vitória encontrada"
        }
      }
    },
    AUTH: {
      TOPBAR: {
        LOGIN_BUTTON_TEXT: "Entrar",
        REGISTER_BUTTON_TEXT: "Registrar-se"
      },
      LOGIN: {
        TITLE: "Bem-vindo de volta!",
        EMAIL: 'E-mail',
        EMAIL_EMPTY_ERROR: "Por favor, digite um e-mail",
        EMAIL_INVALID_ERROR: 'Por favor, digite um e-mail válido',
        PHONE_NUMBER: "Número de Telefone",
        PHONE_EMPTY_ERROR: 'Por favor, digite seu número de telefone',
        PHONE_INVALID_ERROR: 'Por favor, digite um número de telefone válido',
        PASSWORD: 'Senha',
        PASSWORD_EMPTY_ERROR: 'A senha é obrigatória',
        PASSWORD_LENGTH_ERROR: 'A senha deve ter mais de 6 caracteres',
        SUBMIT_BUTTON_TEXT: 'Entrar',
        DONT_HAVE_ACCOUNT: "Ainda não tem uma conta?",
        CREATE_NEW_ONE: "Registrar-se",
        FORGOT_PASSWORD_BUTTON_TEXT: 'Esqueceu a senha?',
        ERRORS: {
          NOT_EXIST: "O e-mail não está registrado",
          WRONG_PASSWORD: 'Senha incorreta inserida',
          TOO_MANY_ATEMP: 'Sua conta foi desativada após muitas tentativas de login malsucedidas. Entre em contato com o suporte.',
          TIME_LIMIT: 'Limite de tempo excedido.',
          NOT_ACTIVE: "Sua conta não está no status 'Ativa'. Entre em contato com o suporte.",
          PERMANENT_EXCLUDE: 'Sua conta foi excluída permanentemente deste site.',
          SUSPENDED: 'Você se suspendeu do jogo.',
          TEMPORARY_SUSPENDED: 'Sua conta foi suspensa temporariamente. Se isso não for resolvido em 24 horas, entre em contato com o suporte.',
          ERROR_OCCURED: 'Ocorreu um erro ao fazer login. Por favor, tente novamente.',
          SUCCESS_LOGIN: 'Logado com sucesso',
          INACTIVITY: 'Sua última sessão expirou devido à inatividade'
        },
        USING_SOCIAL: 'Login using'
      },
      SIGNUP: {
        TITLE: 'Registrar-se no {{sitename}}',
        EMAIL: 'E-mail',
        EMAIL_EMPTY_ERROR: "Por favor, digite um e-mail",
        EMAIL_INVALID_ERROR: 'Por favor, digite um e-mail válido',
        EMAIL_ALREADY_USED_ERROR: 'Esse endereço de e-mail já está em uso. Se você estiver tentando completar seu registro, faça login para continuar de onde parou.',
        PHONE_NUMBER: "Número de telefone",
        PHONE_EMPTY_ERROR: 'Por favor, digite seu número de telefone',
        PHONE_INVALID_ERROR: 'Por favor, digite um número de telefone válido',
        PHONE_ALREADY_USED_ERROR: 'That phone number is already in use. If you are trying to complete your registration, please sign in to continue where you left off.',
        VERIFICATION_CODE: 'Código de verificação',
        VERIFY_BUTTON_TEXT: 'Verificar',
        GET_CODE_TXT: 'Obter código',
        CODE_SENT: 'Code Sent',
        RESEND: 'Resend',
        PASSWORD: 'Senha',
        PASSWORD_EMPTY_ERROR: 'A senha é obrigatória',
        PASSWORD_LENGTH_ERROR: 'A senha deve ter mais de 6 caracteres',
        PASSOWRD_PATTERN_ERROR: 'A senha deve conter pelo menos um número, uma letra minúscula, uma letra maiúscula e um caractere especial não alfabético: $ # ! @ - _',
        REFERRAL_CODE: 'Código de referência/promoção (opcional)',
        REFERAL_CODE_ERROR: 'O código de promoção é inválido',
        TERMS_DESC: 'Ao se inscrever, você concorda com os',
        TERMS_BUTTON_TEXT: 'Termos e Serviços',
        SUBMIT_BUTTON_TEXT: 'Começar',
        ALREADY_HAVE_ACCOUNT: "Já tem uma conta?",
        SIGNIN_BUTTON_TEXT: 'Entrar',
		    ERRORS: {
          ALREADY_EXIST: 'O e-mail já existe',
          NOT_CREATED: 'Não é possível criar uma conta. Entre em contato com o suporte ao cliente.'
        },
        VERIFY_CODE_REQUIRED: 'Código de verificação é obrigatório',
        VERIFICATION_CODE_LENGTH_ERROR: "O código de verificação deve ter 6 números",
        VERIFICATION_WRONG_CODE: "Código de verificação incorreto",
        SENT_VERIFY_EMAIL: "Enviamos o e-mail para verificação",
        SENT_VERIFY_SMS: "We've sent the sms code for verification",
        USING_SOCIAL: 'Register using',
        CAPTCHA: "CAPTCHA",
        CAPTCHA_REQUIRED: "CAPTCHA is required",
        CAPTCHA_PATTERN_ERROR: "Please enter valid CAPTCHA",
        CAPTCHA_WRONG: "Invalid CAPTCHA, please try again"
      },
      FORGOT_PASSWORD: {
        TITLE: "Esqueceu a senha?",
        DESCRIPTION: 'Esqueceu a senha?',
        EMAIL: 'E-mail',
        EMAIL_EMPTY_ERROR: "Por favor, digite um e-mail",
        EMAIL_INVALID_ERROR: 'Por favor, digite um e-mail válido',
        PHONE_NUMBER: "Número de telefone",
        PHONE_EMPTY_ERROR: 'Por favor, digite seu número de telefone',
        PHONE_INVALID_ERROR: 'Por favor, digite um número de telefone válido',
        VERIFICATION_CODE: 'Código de verificação',
        VERIFY_BUTTON_TEXT: 'Verificar',
        SUBMIT_BUTTON_TEXT: 'Enviar',
        SUCCESS_MSG: 'Link de e-mail de redefinição enviado com sucesso',
        VERIFICATION_CODE_LENGTH_ERROR: "Verification code must be 8 characters",
        SUCCESS_RESET_MSG: 'Your password has been reset successfully. Please login with new password again'
      },
      LOGOUT: {
        MULTI_DEVICE: "Você foi desconectado devido ao login em vários dispositivos",
        INACTIVITY: 'Você foi desconectado devido à inatividade',
        DEFAULT: 'Você está desconectado'
      },
      OR: 'OU',
      GOOGLE_BUTTON_TEXT: "Google"
    },
    DEPOSIT: {
      CURRENCY_TITLE: 'Moeda de depósito',
      CHOOSE_PAYMENT_TITLE: 'Rede',
      AMOUNT: 'Valor',
      INSTANT: 'Instantâneo',
      MIN: 'Mín.',
      MAX: 'Máx.',
      SUBMIT_BUTTON_TEXT: 'Depositar',
      NEED_HELP: 'Precisa de ajuda?',
      CONTACT_SUPPORT: 'Entre em contato com nosso suporte ao vivo',
      CRYPTO_DEPOSIT_TITLE: 'Depositar',
      CRYPTO_DEPOSIT_DESCRIPTION: 'Este é o seu endereço de depósito privado. Qualquer depósito que você fizer aparecerá em seu saldo após a confirmação do blockchain. Nós o notificaremos por e-mail assim que o depósito chegar.',
      MINIMUM_DEPOSIT_AMOUNT: 'O valor mínimo de depósito é {{amount}} {{currency}}',
      SEND_MONEY_TO: 'Envie qualquer quantia de {{currency}} (mais taxa de mineração) para:',
      NO_PAYMENT_METHOD: 'Nenhum método de pagamento disponível',
      ADDRESS_COPIED: "Copy and Deposit",
      CLUB_PAY_TYPE: "Pay Type"
    },
    WITHDRAW: {
      CLUB_PAY_TYPE: "Pay Type",
      CURRENCY_TITLE: 'Moeda de saque',
      CHOOSE_PAYMENT_TITLE: 'Rede',
      INFO_TITLE: 'Informações de saque',
      BANK_CODE_TITLE: 'Código do emitente',
      BANK_NAME_TITLE: 'Nome do beneficiário',
      ACCOUNT_TYPE: "Account Type",
      BANK_ACCOUNT_TITLE: 'Conta do beneficiário',
      AMOUNT: 'Valor',
      INSTANT: 'Instantâneo',
      MIN: 'Mín.',
      MAX: 'Máx.',
      SUBMIT_BUTTON_TEXT: 'Sacar',
      NEED_HELP: 'Precisa de ajuda?',
      CONTACT_SUPPORT: 'Entre em contato com nosso suporte ao vivo',
      EXIST_ACCOUNT_TITLE: 'Contas existentes',
      ADDRESS: 'Endereço',
      WITHDRAW_FEE_ESTIMATED: 'A taxa de saque é estimada em {{amount}} {{currency}}',
      MINIMUM_WITHDRAW_AMOUNT: 'O valor mínimo de saque é {{amount}} {{currency}}',
      WITHDRAW_DEDUCT_DESC: 'O saque deduzirá uma taxa de manuseio de 10%',
      COMPLETE_EMAIL_VERIFY: 'Por motivos de segurança, conclua a verificação de e-mail antes do saque',
      VERIFY_EMAIL_BUTTON: 'Verificar endereço de e-mail',
      COMPLETE_PHONE_VERIFY: 'For safety reasons, complete phone verification before withdrawal',
      VERIFY_PHONE_BUTTON: 'Verify Phone Address',
      COMPLETE_EMAIL_PHONE_VERIFY: 'For safety reasons, complete email and phone verification before withdrawal',
      VERIFY_EMAIL_PHONE_BUTTON: 'Verify Now',
      COMPLETE_PROFILE: 'Por motivos de segurança, complete as informações do seu perfil antes do saque',
      ACCOUNT_BUTTON: 'Conta',
      WITHDRAW_SUCCESS: 'O saque foi enviado, fique de olho na sua conta de saque, obrigado',
      HOME_BUTTON: 'Início',
      RESTRICTED_BALANCE: 'Você tem {{currency}} {{amount}} de saldo restrito.',
      RESTRICTED_BALANCE_VIEW: 'Ver',
      NO_PAYMENT_METHOD: 'Nenhum método de pagamento disponível',
      IFSC_TITLE: 'IFSC Number',
      INVALID_AMOUNT_ERROR: {
        EMPTY_AMOUNT: 'Please input amount',
        LESS_ZERO: 'Amount cannot be less than zero',
        LESS_MIN: 'Amount is less than minimum value',
        GREATER_MAX: 'Exceed max value',
        GREATER_BALANCE: 'Exceed balance',
        GREATER_AVAILABLE: 'Insufficient Balance'
      },
      ADDRESS_REQUIRED: "Address is required",
      ADDRESS_PATTERM_ERROR: "Address is not correct"
    },
    GAME_GALLEY: {
      NO_GAME_DATA: 'Nenhum jogo na sua lista. Clique no botão home e divirta-se com um dos muitos jogos.',
      DISPLAY_COUNT_GAMES: 'Exibindo {{showCount}} de {{totalCount}} jogos'
    },
    GAME: {
      LOADING: 'Carregando',
      PLAY_BUTTON_WITHOUT_LOGIN: 'Entrar',
      PLAY_BUTTON_WITH_LOGIN: 'Jogar',
      DEMO_PLAY_BUTTON: "Demo",
      RTP: 'RTP efetivo',
      RECENT_BIGGEST_WIN: 'Maior vitória recente',
      MY_GAMES_TITLE: 'Meus jogos'
    },
    ACTIVE_ACCOUNT: {
      TITLE: 'Verificar e-mail',
      EMAIL_VERIFY_DESCRIPTION: 'Faça login no seu e-mail e clique no link do e-mail para concluir a verificação',
      GET_CODE_TEXT: 'Send Code',
      RESEND_BUTTON_TEXT: 'Reenviar',
      SUBMIT_BUTTON_TEXT: 'Submit',
      NOT_RECEIVE_TXT: "Didn't receive the code?",
      CODE: 'Code',
      CODE_INPUT_PLACEHOLDER: 'Fill in the code here'
    },
    EMAIL_VERIFY: {
      DISPLAY_INFO: 'Verificando agora. Aguarde um momento...',
      ERROR_TITLE: 'Desculpe, houve um problema.',
      VERIFY_ERROR: 'Código de e-mail não encontrado',
      SUCCESS_TITLE: 'Sucesso!',
      VERIFY_SUCCESS: 'Seu e-mail foi verificado com sucesso'
    },
    BONUSES: {
      BONUSES_TITLE: 'Promoções',
      BONUS_TITLE: 'Bônus',
      NO_BONUSES_DATA: 'Nenhuma promoção disponível no momento',
      CHECK_LATER: 'Verifique novamente mais tarde!',
      MORE_DETAIL_BUTTON_TEXT: 'Saiba mais',
      APPLY_BUTTON_TEXT: 'Aplicar agora',
      TAB1: 'Casino',
      TAB2: 'Sport',
      ENDS_AT: 'Ends :',
      EVENT_ENDED: 'Event Ended'
    },
    EMPTY_PAGE: {
      NOT_FOUND: 'Ooops.. Página não encontrada.',
      REFRESH_OR_GOHOME: 'Por favor, atualize a página ou clique abaixo para retornar à página inicial',
      ACTION_BUTTON_TEXT: 'Início'
    },
    FOOTBALL_PAGE: {
      TITLE: 'Esportes',
      DESCRIPTION: 'As apostas esportivas estão chegando em breve. Volte mais tarde!',
      ACTION_BUTTON_TEXT: 'Início'
    },
    LOGOUT_CONFIRM: {
      TITLE: 'Sair',
      DESCRIPTION: "Não se esqueça de conferir nossa grande seleção de promoções de cassino e esportes antes de sair!",
      BUTTON_TEXT: 'Sair'
    },
    ACCOUNT_INFO: {
      UID: 'UID',
      USERNAME_TITLE: 'Nome de usuário',
      EMAIL_TITLE: 'E-mail',
      PHONE_TITLE: 'Número de telefone',
      PHONE_PLACEHOLDER: 'Add Phone number',
      PASSWORD_TITLE: 'Senha',
      EDIT_BUTTON_TEXT: 'Editar',
      VERIFY_BUTTON_TEXT: 'Verificar',
      ADD_EMAIL: 'Adicionar e-mail',
      COPY_BUTTON_TXT: 'Copy',
      SUMMARY_TEXT: ''
    },
    ADD_EMAIL: {
      TITLE: 'Adicionar e-mail',
      DESCRIPTION: 'Adicione seu e-mail para a segurança da conta',
      EMAIL_PLACEHOLDER: 'E-mail',
      VERIFICATION_CODE: 'Código de verificação',
      VERIFY_BUTTON_TEXT: 'Verificar',
      SUBMIT_BUTTON_TEXT: 'Salvar'
    },
    CHANGE_PASSWORD: {
      TITLE: 'Alterar senha',
      CURRENT_PW_PLACEHOLDER: 'Senha atual',
      CURRENT_PW_REQUIRED: 'Por favor, digite sua senha atual',
      CURRENT_PW_WRONG: 'Senha atual incorreta inserida',
      NEW_PW_PLACEHOLDER: 'Nova senha',
      NEW_PW_REQUIRED: 'Por favor, digite uma nova senha',
      NEW_PW_LENGTH_ERROR: 'A senha deve ter pelo menos 6 caracteres',
      CONFIRM_PW_PLACEHOLDER: 'Repita a nova senha',
      CONFIRM_PW_REQUIRED: 'Por favor, confirme sua nova senha',
      NEW_PW_NOT_MATCH: 'A senha inserida não corresponde',
      NEW_PW_NOT_CURRENT: 'A nova senha não pode corresponder à sua senha atual',
      SUBMIT_BUTTON_TEXT: 'Salvar',
      MESSAGES: {
        SUCCESS: 'Sua senha foi alterada',
        WRONG_CURRENT_PASSWORD: 'Você digitou incorretamente sua senha atual.',
        NEW_NOT_MATCH_CURRENT: 'Sua nova senha não pode corresponder à sua senha atual.'
      }
    },
    EDIT_USERNAME: {
      TITLE: 'Editar nome de usuário',
      PLACEHOLDER_TEXT: 'Nome de usuário',
      SUBMIT_BUTTON_TEXT: 'Salvar',
      MESSAGES: {
        USERNAME_REQUIRED: 'Por favor, digite o nome de usuário.',
        USERNAME_LENGTH: 'O nome de usuário deve ter mais de 3 caracteres'
      }
    },
    RESET_PASSWORD: {
      TITLE: 'Redefinir senha',
      DESCRIPTION: 'Redefinir senha',
      PASSWORD_PLACEHOLDER: 'Senha',
      PW_LENGTH_ERROR: 'A senha deve ter pelo menos 6 caracteres.',
      PW_REQUIRED: 'Por favor, digite uma nova senha.',
      PW_PATTERN_ERROR: 'A senha deve incluir número, letra maiúscula e símbolo.',
      CONFIRM_PW_PLACEHOLDER: 'Confirme a senha',
      CONFIRM_PW_REQUIRED: 'Por favor, confirme sua nova senha.',
      CONFIRM_PW_MISMATCH: 'As senhas não coincidem.',
      NEW_PW_NOT_CURRENT: 'Sua nova senha não pode corresponder à sua senha atual.',
      SUBMIT_BUTTON_TEXT: 'Redefinir',
      MESSAGES: {
        TOKEN_INVALID: 'O token fornecido é inválido',
        TOKEN_EXPIRED: 'O token fornecido expirou',
        SUCCESS: 'Sua senha foi redefinida. Faça login usando a senha',
        FAILD: 'Falha ao redefinir sua senha. Por favor, tente novamente'
      }
    },
    UPDATE_PHONE: {
      TITLE: 'Editar número de telefone',
      DESCRIPTION: 'Adicione seu número de telefone para a segurança da conta',
      PLACEHOLDER: 'apenas dígitos',
      PHONE_REQUIRED: 'Por favor, digite seu número de telefone',
      PHONE_INVALID: 'Por favor, digite um número de telefone válido',
      VERIFY_CODE: 'Código de verificação',
      VERIFY_BUTTON_TEXT: 'Verificar',
      SUBMIT_BUTTON_TEXT: 'Enviar',
      MESSAGES: {
        FAILD: 'Ocorreu um problema ao tentar atualizar o número de telefone. Entre em contato com o suporte ao cliente.'
      }
    },
    ACTIVE_BONUSES: {
      TITLE: 'Bônus ativos',
      NO_DATA: "Você não tem nenhum bônus ativo no momento.",
      SHOW_RESULT: 'Clique em um bônus para ver todos os detalhes sobre ele',
      BONUS_ACCOUNT: 'Conta bônus',
      DESC1: "Às vezes você pode receber um ‘Bônus’ como recompensa especial por uma ação, como fazer um depósito.",
      DESC2: "O 'Valor Total Restrito' (seu depósito + valor do bônus) é restrito apenas a apostas em jogos. Assim que os requisitos de aposta forem atendidos, o Valor Total Restrito é liberado e passa a fazer parte do seu saldo em dinheiro real.",
      TABLE_HEADER: {
        COLUMN1: 'BÔNUS TOTAL',
        COLUMN2: 'BÔNUS RESTANTE',
        COLUMN3: 'REFERÊNCIA',
        COLUMN4: 'DATA DE INÍCIO',
        COLUMN5: 'DATA DE VALIDADE',
        COLUMN6: 'STATUS',
        COLUMN7: 'VALOR RESTRITO'
      }
    },
    PAST_BONUSES: {
      TITLE: 'Bônus anteriores',
      DESCRIPTION: 'Abaixo você pode ver todos os bônus anteriores que recebeu. Selecione um período:',
      FROM_DATE_LABEL: 'De',
      INPUT_DATE_PLACEHOLDER: 'Selecione uma data',
      TO_DATE_LABEL: 'Para',
      SUBMIT_BUTTON_TEXT: 'ENVIAR',
      NO_RESULT: 'Nenhum resultado encontrado.',
      SHOW_RESULT: 'Selecione qualquer bônus abaixo para ver mais detalhes:',
      TABLE_HEADER: {
        COLUMN1: 'REFERÊNCIA',
        COLUMN2: 'BÔNUS TOTAL',
        COLUMN3: 'DATA DE INÍCIO',
        COLUMN4: 'EXP. DATA',
        COLUMN5: 'STATUS',
      }
    },
    BONUS_DETAIL: {
      TITLE: 'Bônus - Detalhes',
      TERMS_SERVICE: 'Termos e Condições',
      BONUS: 'Bônus',
      START_DATE: 'Data de início',
      EXPIRED_DATE: 'Exp. Data',
      PROGRESS: 'Progresso',
      CASINO: 'Cassino',
      SPORTS: 'Esportes',
      LIVE: 'Live',
      VALID_GAMES: 'Jogos válidos',
      ROLLOVER: 'Requisitos de Rollover',
      WAGERS_REMAIN: 'Apostas restantes',
      RESTRICT_AMOUNT: 'VALOR RESTRITO',
      WINNINGS: 'Ganhos',
      DEPOSIT: 'Depósito',
      TOTAL_RESTRICT_AMOUNT: 'Valor total restrito',
      FORFEIT_BUTTON_TEXT: 'Perder meu bônus',
      FORFEIT_DESC1: "Se você perder este bônus, removeremos:",
      FORFEIT_DESC2: '{{moeda}} {{ganhos}} de ganhos restritos.',
      FORFEIT_DESC3: '{{currency}} {{bonus}} de dinheiro de bônus restrito.',
      FORFEIT_DESC4: 'Isso deixará você com {{currency}} {{deposit}} do seu depósito.',
      FORFEIT_DESC5: 'Tem certeza que deseja perder este bônus?',
      CANCEL_BUTTON_TEXT: 'Cancelar',
      CONFIRM_BUTTON_TEXT: 'Sim, desistência do bônus',
      ERRORS: {
        CLOSE_OPENED_GAMES: 'Por favor, feche todos os jogos abertos para perder o bônus.',
        RESOLVE_SPORTS_GAME: 'Um evento esportivo reservou fundos deste bônus - aguarde primeiro sua resolução.',
        RESOLVE_SAVED_GAME: 'Um jogo salvo reservou fundos deste bônus - resolva-o primeiro.'
      },
      APPLY_BUTTON_TXT: {
        DEFAULT: 'Deposit Now',
        LABEL1: 'Apply Now',
        LABEL2: 'Deposit Now',
        LABEL3: 'Play Now',
        LABEL4: 'Redeem Now',
        LABEL5: 'More Detail'
      }
    },
    REDEEM_BONUS: {
      TITLE: 'Promo Code',
      DESCRIPTION: "Join {{name}} community and receive a promo code!",
      INPUT_LABEL: 'Código de bônus:',
      INPUT_PLACEHOLDER: 'Promo Code',
      SUBMIT_BUTTON_TEXT: 'Resgatar',
      FAILD: 'O código de bônus está errado ou expirou, tente outro.',
      NOVALID: "Promo code not available",
      SUCCESS: "Promo Code has been used successfully",
      SUCCESS_BUTTON_TXT: "Finish"
    },
    REFER_FRIEND: {
      TITLE: 'Indique um amigo',
      SUBTITLE1: 'Convide amigos, ganhe dinheiro',
      DESCRIPTION1: 'Receba saldo em dinheiro para cada amigo que você indicar para o {{website}}',
      TOTAL_REFERRED: 'Total de usuários indicados',
      SUBTITLE2: 'Compartilhe seu código de convite',
      DESCRIPTION2: 'Seu código de referência'
    },
    SPORTS_PAGE: {
      LOADING: 'Carregando'
    },
    GAME_HISTORY: {
      TITLE: "Histórico de jogos",
      TABLE: {
        COLUMN1: "Jogo",
        COLUMN2: "Resultado da aposta",
        COLUMN3: "Hora",
        COLUMN4: "Valor da aposta",
        COLUMN5: "Multiplicador",
        COLUMN6: "Pagamento"
      },
      NO_HISTORY: "Sem histórico de jogos",
      SHOW_DATA_DESC: 'Apenas os últimos 50 registros de jogos estão disponíveis',
      TOTAL: "Total",
      WIN: 'Ganhar',
      LOSS: 'Perder',
      DRAW: 'Empate',
      UNSETTLED: 'UNSETTLED',
      MOBILE: {
        CASH: 'SLOTS',
        BET_AMOUNT: 'Valor da aposta',
        MULTIPLIER: 'Multiplicador',
        PAYOUT: 'Pagamento'
      },
      SHAREING: {
        SUCCESS: {
          TITLE1: 'More than just wins,',
          TITLE2: 'earn extra tokens and dividends here.',
          BET_AMOUNT: 'by betting',
          MULTIPLIER: "Multiplier {{value}}x",
          PLACED_USER: 'Placed By: {{username}}',
          REFERRAL_CODE: 'Referral Code',
          CTA_SENTENCE2: 'Share NOW & win lottery rewards',
          DOWNLOAD_BTN: 'Download',
          SHARE_BTN: 'Share'
        },
        FAILD: {
          TITLE: 'Fail To Share',
          ALREADY_SHARED_GAME_LOG: 'This win has been shared by other people.',
          ABOVE_ONE_MULTIPLIER: 'Only transactions that are above 1x Multiplier can be shared.',
          EXCEEDED_DAILY: "You have reached your daily limit."
        }
      }
    },
    LEADERBOARD: {
      TITLE: 'Tabela de classificação',
      TABLE: {
        COLUMN1: "",
        COLUMN2: "",
        COLUMN3: "Jogador",
        COLUMN4: "Total de tokens $FUN",
        COLUMN5: "Recompensa"
      },
      NO_DATA: 'Nenhuma tabela de classificação'
    },
    NEW_BANK: {
      TITLE: 'Informações bancárias',
      PAYMENT_TYPE: 'Tipo de pagamento',
      SELECT_PAYMENT_TYPE: 'Selecione um tipo de pagamento',
      BANK_LIST: 'Lista de bancos',
      SELECT_BANK: 'Selecione o banco',
      NAME: 'Nome completo',
      ACCOUNT_NUMBER: 'Número da conta',
      ACCOUNT_NUMBER_DESC: 'Apenas 1 a 17 dígitos',
      SUBMIT_BUTTON_TXT: 'ENVIAR',
      ADD_NEW_BUTTON_TXT: 'Adicionar novo',
      CLOSE_BUTTON_TXT: 'Fechar'
    },
    PAYMENTS: {
      DEPOSIT_TXT: 'Depósito',
      WITHDRAW_TXT: 'Saque'
    },
    PROFILE_INFO: {
      TITLE: 'Informações pessoais',
      FIRST_NAME: 'Primeiro nome',
      FIRST_NAME_REQURIED_ERROR: 'Por favor, digite seu primeiro nome legal.',
      FIRST_NAME_PATTERN_ERROR: "O primeiro nome só pode conter caracteres alfabéticos, espaços, hifens e pontos. Por favor, digite pelo menos 2 e no máximo 20 caracteres.",
      LAST_NAME: 'Sobrenome',
      LAST_NAME_REQUIRED_ERROR: 'Por favor, digite seu sobrenome legal.',
      LAST_NAME_PATTERN_ERROR: "O sobrenome só pode conter caracteres alfabéticos, espaços, hifens e pontos. Por favor, digite pelo menos 2 e no máximo 20 caracteres.",
      BIRTHDAY: {
        TITLE: 'Data de nascimento',
        YEAR: 'Ano',
        MONTH: 'Mês',
        DAY: 'Dia',
        ERROR: 'Por favor, digite uma data de nascimento válida.'
      },
      ADDRESS: {
        STREET: 'Endereço da rua',
        STREET_REQURIED_ERROR: 'Por favor, digite seu endereço da rua.',
        PO_BOX_ERROR: 'Não é possível usar uma caixa postal.',
        COUNTRY: 'País',
        STATE: 'Estado',
        CITY: 'Cidade',
        CITY_REQURIED_ERROR: 'Por favor, digite sua cidade.',
        CITY_PATTERN_ERROR: 'Por favor, digite um nome de cidade válido. A cidade não deve incluir números.',
        ZIPCODE: 'Código postal',
        ZIPCODE_REQUIRED_ERROR: 'Por favor, digite seu código postal.',
        ZIPCODE_PATTERN_ERROR: 'Por favor, digite apenas letras e números. Sem espaços ou caracteres especiais.',
        ZIPCODE_MINLENGTH_ERROR: 'Zipcode must be 5 charactors at least'
      },
      SUBMIT_BUTTON_TXT: 'ENVIAR',
      CHOOSE_FILE: 'Proof of Identity/Choose file',
      FILE_NOTE: "*Document accepted : Passport, driver’s license or ID card.",
      ALERT: {
        SUCCESS: {
          TITLE: 'Verification',
          MSG: 'Successfully updated'
        }
      }
    },
    PROVIDERS: {
      TITLE: 'Provedores de jogos',
      GAMES_LENGTH: '{{count}} Jogos',
      GAMES: 'Jogos',
      CURRENT_DISPLAYING: 'Exibindo {{count}} de {{total}} provedores'
    },
    TERMS: {
      TITLE: 'Termos e Condições',
      PRINT_BUTTON_TXT: 'Imprimir',
      DOWNLOAD_BUTTON_TXT: 'Baixar'
    },
    TRANSACTIONS: {
      TITLE: 'Transações',
      DEPOSIT_BUTTON_TXT: 'Depósito',
      WITHDRAW_BUTTON_TXT: 'Saque',
      BONUS_BUTTON_TXT: 'Bônus',
      NO: 'Não',
      SHOW_DATA_DESC: 'Apenas as últimas 50 transações estão disponíveis',
      TOTAL: 'Total',
      deposit: 'Depósito',
      withdraw: 'Saque',
      bonuses: 'Bônus',
      STATE: {
        CONFIRMED: "Confirmed",
        UNCONFIRMED: "Unconfirmed",
        UNSUCCESSFUL: "Unsuccessful"
      },
      DEPOSIT_TABLE: {
        COLUMN1: 'ID do pedido',
        COLUMN2: 'Tempo',
        COLUMN3: 'Estado',
        COLUMN4: 'Moeda',
        COLUMN5: 'Depósito',
        COLUMN6: 'Saldo anterior',
        COLUMN7: 'Saldo posterior',
        COLUMN8: 'Amount'
      },
      WITHDRAW_TABLE: {
        COLUMN1: 'ID do pedido',
        COLUMN2: 'Tempo',
        COLUMN3: 'Estado',
        COLUMN4: 'Moeda',
        COLUMN5: 'Saque',
        COLUMN6: 'Saldo anterior',
        COLUMN7: 'Saldo posterior',
        COLUMN8: 'Amount'
      },
      BONUSES_TABLE: {
        COLUMN1: 'Tipo',
        COLUMN2: 'ID do pedido',
        COLUMN3: 'Tempo',
        COLUMN4: 'Valor do bônus',
        COLUMN5: 'Bonus Name'
      }
    },
    USER_MENU: {
      WALLET: 'Carteira',
      TRANSACTIONS: 'Transações',
      GAME_HIISTORY: 'Histórico de jogos',
      KYC_INFO: 'KYC',
      VIP: 'Clube VIP',
      REDEEM_COUPON: 'Resgatar cupom',
      REFERRAL: 'Indique um amigo',
      PROMOTIONS: 'Promoções',
      ACTIVE_BONUS: 'Bônus ativos',
      PAST_BONUS: 'Bônus anteriores',
      LIVE_SUPPORT: 'Suporte ao vivo',
      ACCOUNT: 'Perfil'
    },
    STEP_COMPONENT: {
      STEP_TXT: 'Etapa'
    },
    PLAYER_VIP: {
      MY_DATA: {
        VIP_PROGRESS: 'Seu progresso VIP',
        TOOLTIP: 'Cada aposta de $ 1 lhe dá 1 XP. Quanto mais você joga, mais rápido você alcança o nível mais alto.',
        RAKEBACK: 'Rakeback',
        RANK_UP_BUTTON_TXT: 'Subir de nível',
        TOTAL_CLAIMED: 'Total de rakeback reivindicado',
        CLAIM_BUTTON_TXT: 'Reivindicar',
        REMAIN_UNTIL_NEXT: '{{xp}} XP até VIP {{level}}',
        REACHED_MAX_LEVEL: "Você atingiu o nível máximo!"
      },
      BETSPLUS: {
        LEVEL: "Level",
        REQUIRED_XP: 'Required EXP {{amount}} {{currency}}',
        LEVELUP_DESC: 'Get rewarded every time you fill the progress bar. Leveling up entitles you to bigger and better rewards!',
        WITHDRAWN_AMOUNT: 'Amount that can be withdrawn',
        TOTAL_REWARDS: 'Total Rewards Available',
        EXCHANGE_BUTTON_TXT: 'Exchange',
        CLAIM_BUTTON_TXT: 'Claim',
        REWARD_REDEMPTION: "Reward Redemption",
        TOTAL_CLAIMED: 'Total Rewards Claimed',
        REWARD: "Reward"
      }
    },
    REFERRALS: {
      MY_REFERRALS: {
        TOTAL_COMMISSION_EARNING: 'Ganhos totais de comissões de referência',
        REFERRED_YOU: "Quem te indicou",
        AMOUNT_REFERRED: 'Quantidade de usuários que você indicou',
        NUMBER_OF_USERS: "Number of users"
      },
      BETSPLUS: {
        TITLE: 'Millionaire Project',
        DESCR1: 'GET UP TO <span style="color: #FFC83A;">${{value}}</span>',
        DESCR2: 'AND UP TO <span style="color: #FFC83A;">{{percent}}%</span> COMMISSION',
        HOWTOWORK: 'how it works',
        TOOLTIP_DETAIL: 'Step 1: Share referral code to friends and social media. <br> Step 2: Guide them to get their deposit bonus. <br> Step 3: Recommend some great games for them. <br> Step 4: Claim and spend your referral rewards. <br> Step 5: Repeat the steps!',
        INVITE_SECT: {
          TITLE: 'Invite Users',
          SHARES: 'Shares',
          INVITEES: 'Invitees',
          INVITE_LINK: 'Invite link',
          INVITE_QR_CODE: 'Invite QR code',
          QUICK_SHARE: 'Quick share',
          DOWNLOAD_BTN: 'Download'
        },
        DATA_CENTER: {
          TITLE: 'Data Center',
          TOTAL_REVENUE: 'Total revenue',
          INVITATION: 'Invitation',
          WAGER: 'Wager',
          ACHIEVEMENT: 'Achievement',
          VIP: 'VIP',
          MORE_BTN: 'More'
        },
        REWARD_SECT: {
          TITLE: 'Reward Redemption',
          AMOUNT_WITHDRAWN: 'Amount that can be claimed',
          EXCHANGE_BUTTON_TXT: 'Exchange',
          CLAIM_BUTTON_TXT: 'Claim',
          TOTAL_REWARD: 'Total Rewards Available : {{value}}'
        },
        ACTIVITY_RULES: {
          TITLE: 'Activity Rules',
          SEE_MORE: 'See More',
          DATA1: {
            LABEL: 'INVITATION BONUS',
            TEXT: 'Every time you invite a friend whose deposit reaches 20 USD, you will get, 5 USD rewards.'
          },
          DATA2: {
            LABEL: 'BETTING COMMISSION',
            TEXT: 'Bonus Rules: All players at the "Promoter" level receive a percentage of the platform advantage bonus for every bet.'
          },
          DATA3: {
            LABEL: 'ACHIEVEMENT BONUS',
            TEXT: 'If the cumulative number of invitations meets the conditions, you will receive additional achievement rewards. <br> The more people you invite, the higher the reward.'
          },
          DATA4: {
            LABEL: 'VIP LEVEL-UP BONUS',
            TEXT: 'Every user you invite reaches the specified level in the game, and you will get additional VIP level-up bonus. <br> USD is a special currency launched by Platform . You can …'
          }
        },
        AMONG_BEST: 'Among the best',
        LIVE_REWARD: 'Live Rewards',
        QA: {
          TITLE: 'Q&A'
        },
        EXCHANGE: {
          MESSAGE: 'Received successfully',
          CONFIRM_BUTTON_TXT: 'Confirm'
        },
        ACHIEVE_BONUS: {
          TITLE: 'Achievement Bonus',
          DESCRIPTION: 'If the cumulative number of invitations meets the conditions, you will receive additional achievement rewards. The more people you invite, the higher the reward.',
          TABLE: {
            COLUMN1: 'Friend’s Level',
            COLUMN2: 'Several people',
            COLUMN3: 'Rewards'
          }
        },
        BETTING_COMMISSION: {
          TITLE: 'Betting Commission',
          SHORT_DESC:'Bonus Rules: All players at the "Promoter" level receive a percentage of the platform advantage bonus for every bet.',
          LONG_DESC: `The proportions of the 3 levels are as follows <br>
          Level 1: Get 35% Of 1% wager <br>
          Level 2: Get 12.25% Of 1% wager <br>
          Level 3: Get 4.29% Of 1% wager <br>
          This will be your long-term income, and every time a player you refer places a bet, you will receive a different percentage of the commission regardless of whether he wins or loses.So what you have to do is to improve your game skills, think about how to win the game and share it with others, and help more people win with your method.We want all players to have fun in our platform, whether it's the fun of winning bets or the game itself!`
        },
        USER_LIST: {
          TITLE: "Users List",
          TABS: {
            TAB1: 'Sub-user',
            TAB2: 'Subordinate users'
          },
          SEARCH_PLACEHOLDER: 'Search User UID',
          SELECTS: {
            ITEM1: 'All',
            ITEM2: 'Today',
            ITEM3: 'Past 7 days',
            ITEM4: 'Past 30 days',
            ITEM5: 'Past 60 days'
          },
          TABLE: {
            COLUMN1: 'Email / Phone',
            COLUMN2: 'Registered At',
            COLUMN3: 'Bonus',
            COLUMN4: 'BETTING',
            COLUMN5: 'INVITATION',
          },
          TOTAL: 'Total',
          NO_DATA: "No user data"
        },
        VIP_LEVEL_BONUS: {
          TITLE: 'VIP Level-Up Bonus',
          DESC: 'Every user you invite reaches the specified level in the game, and you will get additional VIP level-up bonus. USD is a special currency launched by Platform . You can Swap USD into other currencies at any time.',
          TABLE: {
            COLUMN1: 'Friend’s Level',
            COLUMN2: 'Total Wager',
            COLUMN3: 'Rewards'
          }
        }
      }
    },
    KYC: {
      TITLE: "KYC",
      DESC: "Due to the regulatory requirement from National Lottery Regulatory Commission (NLRC) and Curaçao operating license,we require you to verify your Email Address and Phone number to prevent fraud, cheating and suspicious activity. If you are experiencing issue, you may also seek assistance from Live Chat.",
      BUTTON_TXT: "Account"
    },
    GOOGLE_LOGIN: {
      EERROR_MSG: 'Não foi possível fazer login com as informações da conta. Tente novamente com outras'
    },
    SUCCESS_MSG: {
      TITLE: 'Sucesso!'
    },
    ERROR_MSG: {
      TITLE: 'Desculpe, houve um problema'
    },
    COMMON: {
      COMING_SOON: "Em breve",
      LOAD_MORE: "Carregar mais",
      LOGOUT: 'Sair',
      DEPOSIT: 'Depositar',
      WITHDRAW: 'Sacar',
      INVALID_AMOUNT: 'Valor inválido',
      HOME_BUTTON_TXT: 'Início',
      BACK_BUTTON_TXT: 'Voltar',
      COPIED: 'Copied',
      OK: "OK",
      CANCEL: "Cancel",
      SEARCH: "Search"
    },
    LUCKSPIN: {
      TITLE: "Lucky Spin",
      TRY_AGAIN: "Try again",
      BUTTON_TEXT: "Spin",
      WIN_SPIN_TEXT: "LUCKY WIN SPIN NOW",
      BOTTOM_TEXT: "DAILY FREE SPINS",
      AVAILABLE_SPINS: "Available spins"
    },
    ERRORS: {
      400: 'Illegal status',
      401: 'Unauthorized',
      402: 'Payment required',
      404: 'Not found',
      500: 'Internal server error',
      503: '[Ezugi] Invalid game identifier',
      504: '[Ezugi] Invalid operation',
      506: '[Ezugi] Invalid currency',
      508: '[Ezugi] Invalid transaction identifier',
      511: '[Leander] Invalid token',
      1002: '[Evolution] Invalid session id',
      1007: '[NYX] RC unknown currency',
      6001: '[Vanguard] Game session invalid',
      6003: '[Vanguard] Authentication failed',
      40011: '[RGS] Invalid game type',
      40012: '[RGS] Invalid game ID',
      10000: 'Accounts limit exceeded',
      10001: 'Addacct failed',
      10002: 'Age verification failed',
      10004: 'Already has account',
      10005: 'Already registered',
      10006: 'Already sent',
      10010: 'Bad argument',
      10017: "Bonus code not found",
      10018: 'Bonus does not exist',
      10019: 'Bonus invalid',
      10021: 'Can not approve payout request',
      10022: 'Can not cancel payout request',
      10024: 'Can not process payout',
      10025: 'Can not process payout request',
      10034: 'Currency code not found',
      10037: 'Customer already in',
      10038: 'Customer does not exist', //
      10042: 'Customer in blacklist',
      10045: 'Customer not found', //
      10046: 'Customer not registered', //
      10047: 'Customer self excluded',
      10049: 'Customer suspended',
      10052: 'Customer verification not found',
      10055: 'Cust update failed',
      10059: 'Disabled acct',
      10064: 'Email already exists',
      10066: 'Email code not found',
      10069: 'Email in use',
      10071: 'Email not found',
      10143: 'File invalid format',
      10145: 'Game already started',
      10146: 'Game code is not valid',
      10150: 'Game not found',
      10154: 'Get balance',
      10155: 'Get balance error',
      10173: 'Invalid amount max',
      10174: 'Invalid amount min',
      10177: 'Invalid bonus id',
      10178: 'Invalid bonus type',
      10186: 'Invalid customer',
      10190: 'Invalid game id',
      10200: 'Invalid session',
      10207: 'Invalid token',
      10221: 'Login failure',
      10222: 'Login max logins reached',
      10223: 'Login wrong password',
      10224: 'Login wrong username',
      10225: 'Logout failed',
      10246: 'Password not match',
      10250: 'Phone already exists',
      10307: 'Username already exists',
      10308: 'Validate birthdate failed',
      10325: 'The email is not registered yet',
      10326: 'The email has already been used as google sso account',
      10327: 'Wrong referral code',
      10328: 'Customer has not disbursed referral commission',
      10329: 'cannot level up',
      10330: 'Failed to claim VIP cashback',
      10331: 'Invalid customer VIP level',
      10332: 'Failed to claim referral commission',
      10333: 'Failed to get referral achievement information',
      10334: 'Invalid phone number',
      10341: "Not enough value to claim bonus",
      11507: 'Country not found',
      11511: 'Invalid casino bonus',
      11512: 'Please close all opened games in order to forfeit the bonus.',
      11513: 'A saved game reserved funds from this bonus - please resolve it first.',
      11514: 'A sport event reserved funds from this bonus - please wait for its resolution first.',
      11515: 'Inactive casino bonus',
      11516: 'Could not forfeit Any Deposit Rollover casino bonus.',
      11517: "Insufficient player's daily credit",
      11518: 'Failed to acquire prize',
      11519: "Insufficient prize's daily count",
      11520: "Please wait for the cooldown period before sending another email.",
      11521: "Please wait for the cooldown period before sending another sms.",
      11522: "Reach maximum times for sending email for verification in a day.",
      11523: "Reach maximum times for sending sms for verification in a day.",
      11526: "ClubPay Exception",
      100000: 'VALIDATE_SIGNUP_CODE_FAILED',
      100002: 'SEND_SMS_VALIDATION_FAILED'
    },
    BONUS_TYPES: {
      "Deposit": "Deposit",
      "Withdrawal": "Withdrawal",
      "Table Buyin": "Table Buyin",
      "Table Rebuy": "Table Rebuy",
      "Table Leave": "Table Leave",
      "Place Bet": "Place Bet",
      "Tournament Buyin": "Tournament Buyin",
      "Tournament Fee": "Tournament Fee",
      "Tournament Buyin Refund": "Tournament Buyin Refund",
      "Tournament Fee Refund": "Tournament Fee Refund",
      "Manual Adjustment": "Manual Adjustment",
      "Recovery Refund": "Recovery Refund",
      "Transfer In": "Transfer In",
      "Transfer Out": "Transfer Out",
      "Round Win": "Round Win",
      "Bonus Win": "Bonus Win",
      "Deposit Bonus": "Deposit Bonus",
      "Account Creation Bonus": "Account Creation Bonus",
      "Referral Bonus": "Referral Bonus",
      "Bonus Conversion": "Bonus Conversion",
      "Login Grant": "Login Grant",
      "Initial Grant": "Initial Grant",
      "Virtual Goods Purchase": "Virtual Goods Purchase",
      "Loyalty": "Loyalty",
      "Winning in random scratcher": "Winning in random scratcher",
      "Mystery Gift Win": "Mystery Gift Win",
      "FB Credit Order": "FB Credit Order",
      "Leader Board Points": "Leader Board Points",
      "Tournament Award": "Tournament Award",
      "Returning Player Bonus": "Returning Player Bonus",
      "Cancelled round refund": "Cancelled round refund",
      "Sit & Go Award": "Sit & Go Award",
      "Sit & Go Buyin": "Sit & Go Buyin",
      "Sit & Go Fee": "Sit & Go Fee",
      "Sit & Go Buyin Refund": "Sit & Go Buyin Refund",
      "Sit & Go Fee Refund": "Sit & Go Fee Refund",
      "Fast Poker buyin": "Fast Poker buyin",
      "Fast Poker leave": "Fast Poker leave",
      "Fast Poker rebuy": "Fast Poker rebuy",
      "Tournament Rebuy": "Tournament Rebuy",
      "Tournament AddOn": "Tournament AddOn",
      "Sit & Go Rebuy": "Sit & Go Rebuy",
      "Sit & Go AddOn": "Sit & Go AddOn",
      "Bonus Deposit": "Bonus Deposit",
      "Bonus Released": "Bonus Released",
      "Bonus Deposit Released": "Bonus Deposit Released",
      "Bonus Forfeit": "Bonus Forfeit",
      "Bonus Expired": "Bonus Expired",
      "Bonus Winning Released": "Bonus Winning Released",
      "Void Deposit": "Void Deposit",
      "Bonus Deposit Forfeit": "Bonus Deposit Forfeit",
      "Bonus Winnings Forfeit": "Bonus Winnings Forfeit",
      "Bonus Deposit Expired": "Bonus Deposit Expired",
      "Bonus Winnings Expired": "Bonus Winnings Expired",
      "SnG Buyin Recovery Refund": "SnG Buyin Recovery Refund",
      "SnG Fee Recovery Refund": "SnG Fee Recovery Refund",
      "Tournament Buyin Recovery Refund": "Tournament Buyin Recovery Refund",
      "Tournament Fee Recovery Refund": "Tournament Fee Recovery Refund",
      "Clearing released bonus": "Clearing released bonus",
      "Clearing released bonus deposit": "Clearing released bonus deposit",
      "Clearing released bonus winnings": "Clearing released bonus winnings",
      "Void Withdrawal": "Void Withdrawal",
      "Bonus Deposit Forfeit Cleared": "Bonus Deposit Forfeit Cleared",
      "Bonus Deposit Expired Cleared": "Bonus Deposit Expired Cleared",
      "Bonus Cash Grant": "Bonus Cash Grant",
      "Place a Purchase": "Place a Purchase",
      "Cancel a Purchase": "Cancel a Purchase",
      "Tournament Rebuy Refund": "Tournament Rebuy Refund",
      "Tournament Rebuy Recovery Refund": "Tournament Rebuy Recovery Refund",
      "Tournament AddOn Refund": "Tournament AddOn Refund",
      "Tournament AddOn Recovery Refund": "Tournament AddOn Recovery Refund",
      "SnG Rebuy Refund": "SnG Rebuy Refund",
      "SnG Rebuy Recovery Refund": "SnG Rebuy Recovery Refund",
      "SnG AddOn Refund": "SnG AddOn Refund",
      "SnG AddOn Recovery Refund": "SnG AddOn Recovery Refund",
      "Bounty Tournament Award": "Bounty Tournament Award",
      "Jackpot winning": "Jackpot winning",
      "Collectible game award": "Collectible game award",
      "Claim reward": "Claim reward",
      "Cancel reward": "Cancel reward",
      "Coupon redeemed": "Coupon redeemed",
      "Tournament Bounty Buyin": "Tournament Bounty Buyin",
      "Tournament Bounty Buyin Refund": "Tournament Bounty Buyin Refund",
      "Tournament Bounty Buyin Recovery Refund": "Tournament Bounty Buyin Recovery Refund",
      "SnG Bounty Buyin": "SnG Bounty Buyin",
      "SnG Bounty Buyin Refund": "SnG Bounty Buyin Refund",
      "SnG Bounty Buyin Recovery Refund": "SnG Bounty Buyin Recovery Refund",
      "Reward Claimed Refund": "Reward Claimed Refund",
      "Void reward": "Void reward",
      "Tournament Prize Pool Distribution On Recovery": "Tournament Prize Pool Distribution On Recovery",
      "SnG Prize Pool Distribution On Recovery": "SnG Prize Pool Distribution On Recovery",
      "Chips Coupon Claimed": "Chips Coupon Claimed",
      "Milestone Bonus": "Milestone Bonus",
      "External Grant": "External Grant",
      "Void Bet": "Void Bet",
      "Daily TopOff": "Daily TopOff",
      "Bonus Grant": "Bonus Grant",
      "Bonus Used": "Bonus Used",
      "Sport Bet": "Sport Bet",
      "Sport Win": "Sport Win",
      "Sport Win Adjustment": "Sport Win Adjustment",
      "Sport Round Refund": "Sport Round Refund",
      "Sport Round Void": "Sport Round Void",
      "Round Win Bonus": "Round Win Bonus",
      "IBC Sport Unsettle": "IBC Sport Unsettle",
      "IBC Sport Adjust Balance": "IBC Sport Adjust Balance",
      "VIP Level rank-up bonus": "VIP Level rank-up bonus",
      "VIP Level cashback bonus": "VIP Level cashback bonus",
      "Referral commission bonus": "Referral commission bonus",
      "Any Deposit Rollover Bonus": "Any Deposit Rollover Bonus",
      "KYC bonus": "KYC bonus",
      "Daily Lose Rake Back": "Daily Lose Rake Back",
      "Daily lose cashback bonus": "Daily lose cashback bonus",
      "IBC Sport Settled": "IBC Sport Settled"
    }
  }
};
