import { NgModule } from '@angular/core';
import { LimitsHistoryPage } from './limits-history';
import { SharedModule } from '../../../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
@NgModule({
  declarations: [
    LimitsHistoryPage,
  ],
  imports: [
    CommonModule,
    SharedModule,
    IonicModule
  ],
})
export class LimitsHistoryPageModule {}
