
import { Injectable } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { Storage } from '@ionic/storage-angular';
import { Observable, BehaviorSubject } from 'rxjs';

import { Config } from '../../system/providers/configuration';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UniqueIDProvider {

    public advertisingID: any = false;
    protected uniqueIdPromise: Promise<any> = null;
    private mfaDeviceIdSubject:BehaviorSubject<string>;


    constructor(private config: Config, private device: Device, private platform: Platform, protected storage: Storage) {

    }

    getUniqueID(): Promise<any> {
        /*
            scrub the device id from kochavas system: https://go.kochava.com/accounts/4006/device_scrub.
            scrub 'android_id' for android
            scrub 'idfa' for ios

            DO NOT ADD ANY DEVICES TO WHITELIST! IT DOES NOT DO WHAT YOU MIGHT THINK IT DOES!
        */
        this.uniqueIdPromise = new Promise((resolve_uniqueid, reject_uniqueid) => {
            if ('cordova' in window) {
                const cordova = window['cordova'];

                if (this.platform.is('ios') && cordova) {
                    let plugins: any = cordova['plugins'];
                    if ("undefined" !== typeof plugins && "undefined" !== typeof plugins.idfa) {
                        plugins.idfa.getInfo().then((info) => {
                            console.log('limitAdTracking is', info.limitAdTracking);
                            if (!info.limitAdTracking) {
                                this.advertisingID = info.idfa || info.aaid;
                                console.log("uuid = ", this.device.uuid);
                                console.log("advertisingID = ", this.advertisingID);

                                resolve_uniqueid(this.advertisingID);
                            }
                            else {
                                //limit ad tracking is on so we will just bypass this and generate a unique id by ourselves
                                if(this.advertisingID == false) {
                                    //check if we already have an id stored
                                    this.storage.get('unique-id').then((uid) => {
                                        if (uid) {
                                            //we have an id stored
                                            this.advertisingID = uid;
                                            resolve_uniqueid(this.advertisingID)
                                        }
                                        else {
                                            //we need to generate and store a unique-id
                                            let id = Date.now() + "-" + Math.floor(Math.random() * 1000000);
                                            this.advertisingID = id;
                                            this.storage.set('unique-id', id);
                                            resolve_uniqueid(this.advertisingID)
                                        }
                                    });
                                }
                            }
                        });
                    }
                    else {
                        reject_uniqueid('window.cordova.plugins.idfa was not found.');
                    }
                }
                else {
                    console.log('advertisingID fallback to @ionic-native/device');
                    this.advertisingID = this.device.uuid;
                    console.log("advertisingID = ", this.advertisingID);

                    resolve_uniqueid(this.advertisingID);
                }
            }
            else {
                reject_uniqueid('advertisingID N/A')
            }
        });

        return this.uniqueIdPromise;
    }

    setMFADeviceIdAsObservable(deviceId: string) {
        if (deviceId) {
          if(!this.mfaDeviceIdSubject) {
            this.mfaDeviceIdSubject = new BehaviorSubject(deviceId)
          }
          this.mfaDeviceIdSubject.next(deviceId);
        }
    }
    getMFADeviceIdObservable():Observable<string> {
      return this.mfaDeviceIdSubject && this.mfaDeviceIdSubject.asObservable();
    }
    setMFADeviceId(deviceId: string) {
      if (deviceId) {
          localStorage.setItem('mfa_device_id', deviceId)
      }
    }

    getMFADeviceId(): string {
        let deviceId = localStorage.getItem('mfa_device_id');
        return deviceId
    }

    removeMFADeviceId(): void {
        localStorage.removeItem('mfa_device_id')
    }
}
