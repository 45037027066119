import { RedeemBonusCodePageModule } from './pages/bonuses/redeem-bonus-code/redeem-bonus-code.module';
import { CasinoBonusActivePageModule } from './pages/bonuses/casino-bonus/casino-bonus-active/casino-bonus-active.module';
import { CasinoBonusHistoryPageModule } from './pages/bonuses/casino-bonus/casino-bonus-history/casino-bonus-history.module';
import { CasinoBonusDetailsPageModule } from './pages/bonuses/casino-bonus/casino-bonus-details/casino-bonus-details.module';
import { NgModule } from '@angular/core';
import { SystemModule } from '../../system/system.module';
import { GetReferralCodePageModule } from './pages/get-referral-code/get-referral-code.module';

@NgModule({
  declarations: [],
  imports: [
    RedeemBonusCodePageModule,
    GetReferralCodePageModule,
    CasinoBonusActivePageModule,
    CasinoBonusHistoryPageModule,
    CasinoBonusDetailsPageModule,
    SystemModule,
  ],
  entryComponents: [],
  providers: [],
  exports: [],
})
export class RewardsModule {}
