import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationProvider } from 'src/app/modules/authentication/providers/authentication-provider';
import { CommonService } from 'src/app/service/common.service';
import { MyEvent } from 'src/app/service/myevent.services';
import { TranslationService } from 'src/app/service/translation.service';
import { NAME_PATTERN, POboxValidator, POboxValidatorGroup, birthdayValidator, coutryPostalMatchValidator } from 'src/app/shared/customFormValidators';
import { Utils } from 'src/app/shared/utils';
import { Navigator } from 'src/app/system/components/menu/navigator';
import { Config } from 'src/app/system/providers/configuration';
import { ConfigurationProvider } from 'src/app/system/providers/configuration-provider';
import { UserSessionProvider } from 'src/app/system/providers/user-session';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.page.html',
  styleUrls: ['./profile-info.page.scss'],
})
export class ProfileInfoPage implements OnInit {

  @Input() callBackFunc: any;

  infoForm: FormGroup;
  public firstName: AbstractControl;
  public lastName: AbstractControl;

  public countryInfoGroup: AbstractControl;
  public streetGroup: AbstractControl;

  public dateOfBirthGroup: AbstractControl;
  public dateOfBirthYear: AbstractControl;
  public dateOfBirthDay: AbstractControl;
  public dateOfBirthMonth: AbstractControl;

  public street: AbstractControl;
  public city: AbstractControl;
  public country: AbstractControl;
  public province: AbstractControl;
  public postalCode: AbstractControl;

  error;

  public selectedCountry: string = 'USA';

  appConfigInfo: any;
  provinces: any[] = [];
  csrf_token: string = '';

  buttonDisabled: boolean = false;

  get months() {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  }

  get days() {
    let days = Array.apply(null, { length: 31 }).map(Number.call, Number);
    return days
      .map((val) => val + 1)
      .map((val) => (val < 10 ? '0' + val.toString() : val));
  }

  get years() {
    let years = [];
    var min = new Date().getFullYear() - 100,
      max = new Date().getFullYear() - 21;
    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }

  constructor(
    private config: Config,
    public authProvider: AuthenticationProvider,
    public configProvider: ConfigurationProvider,
    private events: MyEvent,
    public utils: Utils,
    public userSession: UserSessionProvider,
    private translationService: TranslationService,
    public comService: CommonService
  ) {
    this.appConfigInfo = this.config.getAppConfigInfo();

    this.infoForm = new FormGroup({
      clientapp: new FormControl(''),
      csrf: new FormControl(),
      firstName: new FormControl('', [
        Validators.required,
        Validators.pattern(NAME_PATTERN),
        Validators.maxLength(20)
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.pattern(NAME_PATTERN),
        Validators.maxLength(20)
      ]),
      dateOfBirthGroup: new FormGroup(
        {
          dateOfBirthYear: new FormControl('', [Validators.required]),
          dateOfBirthDay: new FormControl('', [Validators.required]),
          dateOfBirthMonth: new FormControl('', [Validators.required]),
        },
        birthdayValidator
      ),
      streetGroup: new FormGroup(
        {
          street: new FormControl(
            '',
            [Validators.maxLength(200), POboxValidator]
          ),
          street2: new FormControl(
            ''
          )
        },
        POboxValidatorGroup
      ),
      countryInfoGroup: new FormGroup(
        {
          country: new FormControl(this.appConfigInfo.country, [Validators.required, Validators.maxLength(200)]),
          postalCode: new FormControl('', [Validators.required, Validators.minLength(5)]),
        },
        // coutryPostalMatchValidator
      ),
      city: new FormControl('', [
        Validators.maxLength(200),
        Validators.pattern(NAME_PATTERN),
      ]),
      province: new FormControl('PA', []),
    });
  }

  ionViewDidEnter() {
    this.authProvider.getCsrfToken().subscribe((response) => {
      if ( response.payload && response.payload.token ) {
        this.csrf_token = response.payload.token;
      }
    });
  }

  ngOnInit() {
    this.infoForm.get('clientapp').setValue(this.config.getClientApp());
    this.selectedCountry = this.appConfigInfo.country;
    this.getProvinces();

    this.firstName = this.infoForm.get('firstName');
    this.lastName = this.infoForm.get('lastName');

    this.dateOfBirthGroup = this.infoForm.get('dateOfBirthGroup');
    this.dateOfBirthYear = this.dateOfBirthGroup.get('dateOfBirthYear');
    this.dateOfBirthDay = this.dateOfBirthGroup.get('dateOfBirthDay');
    this.dateOfBirthMonth = this.dateOfBirthGroup.get('dateOfBirthMonth');

    this.streetGroup = this.infoForm.get('streetGroup');
    this.street = this.streetGroup.get('street');

    this.countryInfoGroup = this.infoForm.get('countryInfoGroup');
    this.country = this.countryInfoGroup.get('country');
    this.postalCode = this.countryInfoGroup.get('postalCode');

    this.city = this.infoForm.get('city');
    this.province = this.infoForm.get('province');

    this.prePopulateFields();
  }

  getProvinces() {
    this.configProvider.callGetStatesByCountry(this.selectedCountry).subscribe(
      (response: any) => {
        console.log('callGetStatesByCountry ', response);
        if (response && !!response.result) {
          let provs = response.payload;

          this.provinces = [];
          for (let p in provs) {
            this.provinces.push({ code: p, name: provs[p] });
          }
        }
      },
      (error) => {
        console.error('callGetStatesByCountry ', error);
      }
    );
  }
  prePopulateFields() {
    this.authProvider.callGetAccount().subscribe(
      (response) => {
        if (response.code == 200 && response.result) {
          let customerInfo = response.payload.customer;

          if (
            Object.keys(customerInfo.address).length > 0 &&
            customerInfo.address.constructor === Object
          ) {
            let selectProvince = this.provinces.filter((item) => {
                return item.code == customerInfo.address['state'];
              }).map((item) => {
                return item.code;
              }).join('') || 'PA';

            this.infoForm.patchValue({
              city: customerInfo.address['city'] || '',
              province: selectProvince,
              streetGroup: {
                street: customerInfo.address['street'] || '',
              },
              countryInfoGroup: {
                postalCode: customerInfo.address['postalCode'] || '',
              }
            });
          }

          if (customerInfo.birthDate) {
            var birthDateFormattedArray = customerInfo.birthDate.split('-');
            var usersYear = birthDateFormattedArray[0];
            var usersMonth = Number(birthDateFormattedArray[1]);
            var usersDay = birthDateFormattedArray[2];

            this.infoForm.patchValue({
              dateOfBirthGroup: {
                dateOfBirthMonth: customerInfo.birthDate ? usersMonth : '',
                dateOfBirthDay: customerInfo.birthDate ? usersDay : '',
                dateOfBirthYear: customerInfo.birthDate ? usersYear : '',
              }
            });
          }

          this.infoForm.patchValue({
            firstName: customerInfo.firstName || '',
            lastName: customerInfo.lastName || '',
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onChangeCountry(event) {
    this.getProvinces();
  }

  onSubmit() {
    this.infoForm.addControl(
      'street',
      new FormControl(this.streetGroup.get('street').value)
    );
    this.infoForm.addControl('street2', new FormControl(this.streetGroup.get('street2').value));

    this.authProvider.callSignupStep2(this.infoForm.value).subscribe(
      (response: any) => {
        console.log('signup step 2 response == ', response);
        if (response.code == 200 && response.result) {
          this.doMFA();
        }
      },
      async (error) => {
        console.log('callSignup error = ', error);
        this.authProvider.getCsrfToken().subscribe((response) => {
          if (response.payload && response.payload.token) {
            this.csrf_token = response.payload.token;
          }
        });
        this.comService.showToast(error.error_message, 'error_toast');
        this.error = error;
      }
    );
  }

  doMFA = () => {
    var customer_id = this.authProvider.customer.customerId;
    console.info('customer_id = ', customer_id);

    let promoCode = this.authProvider.customer.getCustomDataProperty('promo_code') == null ? '' : this.authProvider.customer.getCustomDataProperty('promo_code');

    this.events.publishSomeData({
      event: 'event:signupcomplete',
      data: {
        customer_id: customer_id,
        promo_code: promoCode,
      },
    });

    this.events.publishSomeData({
      event: 'event:login',
      data: { customer_id: customer_id },
    });

    this.userSession.refreshCustomer();

    let isMFA = this.config.getConfig().FEATURE_MFA_ENABLED == '1' ? true : false;
    let isSMS_OR_EMAIL = this.config.getConfig().FEATURE_MFA_TYPE ? this.config.getConfig().FEATURE_MFA_TYPE : 'SMS';

    if (isMFA) {
      this.utils.setCookieParam('userSession.mfa', 'true');
      this.utils.setCookieParam('MFA.signup', 'true');
    }

    setTimeout(() => {
      if (this.callBackFunc) {
        Navigator.back();
        this.callBackFunc();
      } else {
        Navigator.back();
      }
    }, 1350);
  };

}
