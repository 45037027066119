import { Subject } from 'rxjs/Subject';
import { isUndefined } from '../../../shared/codeUtils';
import { Storage } from '@ionic/storage-angular';
import { Injectable } from '@angular/core';
import { CustomerModel } from './../../account/models/customer-model';
import { UserSessionProvider } from './../../../system/providers/user-session';
import {
  PlatformResponse,
  WebservicesProvider,
} from './../../../system/providers/webservices';
import { NotificationApiProvider } from '../../../shared/providers/notification-api';
import { Config } from 'src/app/system/providers/configuration';
import { CommonService } from 'src/app/service/common.service';

export interface ILoginRequestData {
  csrf: string;
  username?: string;
  password: string;
  clientapp: string;
  device_id?: string;
  deviceDetails?: string;
  externalSource?: string;
  email?: string;
  phone?: string;
}
export enum LoginResponseComments {
  CUSTOMER_MFA_REQUIRED = 'CUSTOMER_MFA_REQUIRED',
}

export interface ILoginResponsePayload {
  sessionToken: string;
  customerId: string;
  comments?: string | LoginResponseComments; // "CUSTOMER_MFA_REQUIRED"
  lastIPAddress: string; // "10.25.5.1",
  lastLogoutReason: string; // "LOGOUT",
  deviceId: string;
  hasNotUpdatedToc?: boolean;
  hasNotUpdatedPrivacyPolicy?: boolean;
  hasNotUpdatedHouseRules?: boolean;
  hasExceededDepositLimit?: boolean;
}

export type ILoginResponse = PlatformResponse<ILoginResponsePayload>;


const USER_SESSION_STORE_KEY = 'userSession.sessionToken';
const USER_DATA_STORE_KEY = 'userSession.customer';


@Injectable({
  providedIn: 'root',
})
export class AuthenticationProvider {

  public customer: CustomerModel;

  constructor(
    private storage: Storage,
    private userSession: UserSessionProvider,
    private webservice: WebservicesProvider,
    private notificationApi: NotificationApiProvider,
    private config: Config,
    public comService: CommonService
  ) {
    this.userSession.getCustomer().subscribe((customer) => {
      this.customer = customer;
    });
  }

  checkSession() {
    return new Promise<any>((resolve, reject) => {
      let sessionToken = localStorage.getItem(USER_SESSION_STORE_KEY);
      let customerId = localStorage.getItem(USER_DATA_STORE_KEY) ? JSON.parse(localStorage.getItem(USER_DATA_STORE_KEY)).customerId : null;

      let obj: any = {
        player_id: customerId,
        session_id: sessionToken,
      };

      if (sessionToken && customerId) {
        resolve({
          subject: this.webservice.get('auth/player/status', obj),
          sessionToken: sessionToken,
        });
      } else {
        resolve({});
      }
    });
  }

  getCsrfToken() {
    return this.webservice.get('external-services/csrf-token');
  }

  callLogin(data: ILoginRequestData): Subject<ILoginResponse> {
    if (!data.device_id) delete data.device_id;
    return this.webservice.post('auth/sign-in', data);
  }

  callLogout(params) {
    let obj: any = {
      session_id: this.userSession.sessionToken,
    };
    if (!isUndefined(this.userSession.customerId)) {
      obj.player_id = this.userSession.customerId;
    }
    if (params && 'reason' in params) {
      obj.cause = params.reason;
    }

    if (params.code != 10201 && this.userSession.sessionToken && this.userSession.customerId) {
      this.webservice.post('pinnacle/logout', {
        session_id: this.userSession.sessionToken,
        customer_id: this.userSession.customerId,
        selected_currency: this.comService.defaulCurrency
      });
    }

    this.notificationApi.unsubscribe();
    this.userSession.clear();
    if (params.code == 10201) {
      let httpSubject = new Subject<any>();
      setTimeout(() => {
        httpSubject.next('');
      }, 1000);
      return httpSubject;
    } else {
      return this.webservice.postRaw('auth/sign-out', obj);
    }
  }

  callPin(data) {
    let obj: any = {
      player_id: data.customerId,
      session_id: this.userSession.sessionToken,
    };
    obj.pin_number = data.pinNumber;
    return this.webservice.post('auth/player/pin-number/verify', obj);
  }

  sendLoginCode(customerId) {
    let obj: any = {
      player_id: customerId,
      session_id: this.userSession.sessionToken,
    };
    return this.webservice.post('auth/player/login-code/send', obj);
  }

  validateLoginCode(data) {
    let obj: any = {
      player_id: data.customerId,
      session_id: this.userSession.sessionToken,
    };
    obj.login_code = data.pinNumber;
    obj.mfa = true;
    return this.webservice.post('auth/player/login-code/verify', obj);
  }


  checkEmail(email) {
    let obj: any = {
      email
    };

    return this.webservice.get('customer/email-check', obj);
  }

  sendEmailCode(token_code) {
    let obj: any = {
      token: token_code,
    };
    return this.webservice.post('player/email/verify-account-register', obj);
  }

  resendEmailCode(customerId) {
    let obj: any = {
      customer_id: customerId,
      session_id: this.userSession.sessionToken,
    };
    return this.webservice.post('player/email/init/verify', obj);
  }


  checkPhone(phone) {
    let obj: any = {
      phone_number: phone,
      skin_id: 1
    };

    return this.webservice.post('player/phone/check', obj);
  }



  callGetAccount() {
    let obj: any = {
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.get('auth/player/account', obj);
  }

  /*
        POST /authentication/signup
            username	            (required)
            password	            (required)
            clientapp	            (required)
            email	                (required)
            nickName                (required)
            bonusCode
            certOver18          	(optional)
            skin_code
            salutation	            (required)
            firstName	            (required)
            middleName
            lastName	            (required)
            birthDate	            (required)
            securityQuestion	    (required)
            securityAnswer	        (required)
            secondSecurityQuestion	(required)
            secondSecurityAnswer	(required)
            street	                (required)
            street2
            city	                (required)
            country	                (required)
            province	            (required)
            postalCode	            (required)
            phoneNumber             (required)
    */
  callSignup(data) {
    let obj: any = {
      clientapp: data.clientapp,

      email: data.emailGroup.email,
      password: data.passwordGroup.password,

      extAffiliateId: data.extAffiliateId,
      customerAttributionData: data.customerAttributionData,

      username: data.screenName,
      nickName: data.screenName,
      salutation: data.salutation,
      firstName: data.firstName,
      lastName: data.lastName,
      birthDate: data.dateOfBirth,

      street: data.street,
      city: data.city,
      country: data.countryInfoGroup.country,
      province: data.province,
      postalCode: data.countryInfoGroup.postalCode,
      phoneNumber: data.phoneNumber,

      securityQuestion: data.securityQuestion,
      securityAnswer: data.securityAnswer,
      secondSecurityQuestion: data.secondSecurityQuestion,
      secondSecurityAnswer: data.secondSecurityAnswer,

      certOver18: data.certOver18,

      promo_code: data.promo_code,
    };

    if (data.promoCode != null && data.promoCode != '') {
      obj.bonusCode = data.promoCode;
    }

    if (data.middleName != null && data.middleName != '') {
      obj.middleName = data.middleName;
    }

    if (data.street2 != null && data.street2 != '') {
      obj.street2 = data.street2;
    }

    return this.webservice.post('authentication/signup', obj);
  }

  /*
       POST /authentication/signup-step-one

           password	            (required)
           clientapp	            (required)
           email	                (required)
           certOver18              (required)
           skin_code                (optional)
           extAffiliateId	         (optional)
           customerAttributionData  (optional)

   */
  callSignupStep1(data) {
    if (data.promo_code != null && data.promo_code != '') {
      data.bonusCode = data.promo_code;
    }
    let obj: any = {
      csrf_token: data.csrf,
      client_app_id: data.clientapp,
      password: data.passwordGroup.password,
      external_affiliate_id: data.extAffiliateId,
      attribution_data: data.customerAttributionData,
      full_grown_verification: data.certOver18,
      promo_code: data.promo_code,
      code: data.code,
      email_check: data.email_check,
      phone_check: data.phone_check,
      skin_id: 1
    };

    if (data.phone) {
      //obj.phone = data.dialCode + '' + data.phone;
      obj.phone = data.phone
      obj.dialCode = data.dialCode;
    } else if (data.email) {
      obj.email = data.email;
    }

    if (data.captcha) {
      obj.captcha_response = data.captcha;
    }

    if (localStorage.getItem('channel')) {
      var temp_channel = localStorage.getItem('channel');
      var channelId = temp_channel.split(':')[0];
      obj.marketing_channel_id = channelId;
    }

    return this.webservice.post('auth/sign-up/step1', obj);
  }

  /*
        POST /authentication/signup
            password	            (required)
            clientapp	            (required)
            email	                (required)
            nickName                (required)
            bonusCode
            certOver18          	(optional)
            skin_code
            salutation	            (required)
            firstName	            (required)
            middleName
            lastName	            (required)
            birthDate	            (required)
            securityQuestion	    (required)
            securityAnswer	        (required)
            secondSecurityQuestion	(required)
            secondSecurityAnswer	(required)
            street	                (required)
            street2
            city	                (required)
            country	                (required)
            province	            (required)
            postalCode	            (required)
            phoneNumber             (required)
    */

  callSignupStep2(data) {
    // formatted DOB
    // add a 0 in front of the month
    // let month =
    //   ~~data.dateOfBirthGroup.dateOfBirthMonth < 10
    //     ? '0' + data.dateOfBirthGroup.dateOfBirthMonth
    //     : data.dateOfBirthGroup.dateOfBirthMonth;

    let dob = new Date(
      Number(data.dateOfBirthGroup.dateOfBirthYear),
      Number(data.dateOfBirthGroup.dateOfBirthMonth) - 1,
      Number(data.dateOfBirthGroup.dateOfBirthDay)
    );

    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,

      csrf_token: data.csrf,

      client_app_id: data.clientapp,
      // external_affiliate_id: data.extAffiliateId,
      // attribution_data: data.customerAttributionData,

      // welcome: !!data.salutation ? data.salutation : 'Mr',
      first_name: data.firstName,
      last_name: data.lastName,

      //DOB year-month-day

      dob: dob.toISOString().split('T')[0],

      address1: data.street,
      city: data.city,
      country: data.countryInfoGroup.country,
      state: data.province,
      postal_code: data.countryInfoGroup.postalCode,
      // phone_number: data.phoneNumber,

      // first_secret_question: data.securityQuestion,
      // first_secret_answer: data.securityAnswer,
      // second_secret_question: data.secondSecurityQuestion,
      // second_secret_answer: data.secondSecurityAnswer,
      // government_id: data.governmentId,
      // government_type: 'ssn',
      // send_email_after_login: data.sendEmailUponLogin,
      // allow_promo_sms: data.allowPromoSms,
      // allow_promo_emails: data.allowPromoEmails,
      // acknowledge_regulations1: data.ackRegulations1,
      // acknowledge_regulations2: data.ackRegulations2,
      // acknowledge_regulations3: data.ackRegulations3,
      // kyc_session_id: this.userSession.sessionToken,
    };

    if (data.middleName != null && data.middleName != '') {
      obj.middle_name = data.middleName;
    }

    if (data.street2 != null && data.street2 != '') {
      obj.address2 = data.street2;
    }

    return this.webservice.post('auth/sign-up/step2', obj);
  }


  /**
   * Google Login Func
   * @returns
   */
  googleLoginFunc() {
    var apiUrl = this.config.getAppConfigInfo()['google_login_url'];
    var url = `${apiUrl}/oauth2/authorization/google`;
    window.location.href = url;
  }

  sendReferrCode(referral) {
    let obj: any = {
      referral_code: referral,
      session_id: this.userSession.sessionToken
    };

    return this.webservice.post('auth/referral-code', obj);
  }

  /*
        POST /authentication/signup
            username	            (required)
            password	            (required)
            clientapp	            (required)
            email	                (required)
            certOver18          	(optional)
            skin_code
    */
  callSignupNet(data) {
    let obj: any = {
      client_app_id: data.clientapp,
      email: data.emailGroup.email,
      password: data.passwordGroup.password,
      secret_question: data.securityQuestion,
      secret_answer: data.securityAnswer,
      external_affiliate_id: data.extAffiliateId,
      attribution_data: data.customerAttributionData,
      full_grown_verification: data.certOver18,
    };
    return this.webservice.post('auth/sign-up/basic', obj);
  }

  defaultCampaign(data) {
    let obj: any = {
      client_app_id: data.clientapp,
    };

    if (!!data.skin_code) obj.skin_code = data.skin_code;

    return this.webservice.get('auth/default-campaign', obj);
  }

  /*
        POST /authentication/update-account
            customer_id	(required)
            session_token (required)
            street
            street2
            city
            country
            province
            postalCode
            phoneNumber
            securityQuestion
            securityAnswer
            firstName
            lastName
            birthDate
            allowPromoEmails (not used)
            gender (not used)
            preferredCurrency (not used)
            preferredLanguage (not used)
            preferredEmailFormat (not used)
            certOver18 (not used)
    */
  callUpdateAccount(data) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,

      address1: data.street,
      address2: !!data.street2 ? data.street2 : ' ',
      city: data.city,
      country: data.countryInfoGroup.country,
      postal_code: data.countryInfoGroup.postalCode,
      state: data.province,
      phoneNumber: data.phoneNumber,
      send_email_after_login: data.sendEmailUponLogin,
      allow_promo_emails: data.allowPromoEmails,
      allow_promo_sms: data.allowPromoSms,
      client_app_id: data.clientapp,
    };

    return this.webservice.post('auth/player/account', obj);
  }

  callSetSecurityQuestions(data) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      first_secret_question: data.securityQuestion,
      first_secret_answer: data.securityAnswer,
      client_app_id: data.clientapp,
    };

    return this.webservice.post('auth/player/account', obj);
  }

  updateCustomData(data) {
    data.player_id = this.customer.customerId;
    data.session_id = this.userSession.sessionToken;
    return this.webservice.post('auth/player/attributes', data);
  }

  callConfirmAuth(data) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      twoFactorAuth: true,
      confirmAuth: data.confirmAuth,
    };

    return this.webservice.post('auth/player/account', obj);
  }

  /*
        POST /authentication/change-password
    */
  callChangePassword(data) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      current_password: data.currentPassword,
      new_password: data.passwordGroup.newPassword,
      secret_answer_1: data.securityGroup.securityAnswer,
      secret_answer_2: data.securityGroup.secondSecurityAnswer,
    };

    return this.webservice.post('auth/password/change', obj);
  }

  callForgotPassword(data) {
    return this.webservice.post('auth/password/forgot', data);
  }

  callValidateToken(data) {
    let obj = {
      token: data,
    };
    return this.webservice.post('auth/password/verify-session-id', obj);
  }

  callResetPassword(data, customerid) {
    let obj: any = {
      player_id: customerid,
      new_password: data.passwordGroup.newPassword,
      secret_answer_1: data.securityGroup.securityAnswer,
      secret_answer_2: data.securityGroup.secondSecurityAnswer,
      skin_code: 'tc',
    };

    return this.webservice.post('auth/password/reset', obj);
  }

  callResetPasswordStep1(data) {
    let obj: any = {
      email: data.email,
      pin_number: data.pin,
      client_app_id: data.clientapp,
    };

    return this.webservice.post('auth/password/reset/step1', obj);
  }

  callResetPasswordStep2(data) {
    // formatted DOB
    // add a 0 in front of the month
    let month =
      ~~data.dateOfBirthGroup.dateOfBirthMonth < 10
        ? '0' + data.dateOfBirthGroup.dateOfBirthMonth
        : data.dateOfBirthGroup.dateOfBirthMonth;
    let dob = new Date(
      Number(data.dateOfBirthGroup.dateOfBirthYear),
      Number(data.dateOfBirthGroup.dateOfBirthMonth) - 1,
      Number(data.dateOfBirthGroup.dateOfBirthDay)
    );

    let obj: any = {
      email: data.email,
      last_four_ssn_digits: data.ssn,
      dob: dob.toISOString().split('T')[0],
      client_app_id: data.clientapp,
    };

    return this.webservice.post('auth/password/reset/step2', obj);
  }

  callResetPasswordByEmail(data) {
    let obj: any = {
      email: data.email,
      new_password: data.passwordGroup.newPassword,
      pin_number: data.pin,
      client_app_id: data.clientapp,
    };

    if (!!data.customerId) {
      obj.player_id = data.customerId;
    }

    return this.webservice.post('auth/password/reset/by-email', obj);
  }

  callResendPin(email: string, clientapp) {
    let obj: any = {
      email: email,
      client_app_id: clientapp,
    };

    return this.webservice.post('auth/player/pin-number/resend', obj);
  }

  callDefaultCampaign() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.post('auth/default-campaign', obj);
  }

  validateReferrerCode(bonusCode: string) {
    let obj: any = {
      referrer_code: bonusCode,
    };

    return this.webservice.get('auth/referral-code/verify', obj);
  }

  getReferralCode() {
    let obj: any = {
      customer_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.get('raf/referral-code', obj);
  }

  validateDOB(data) {
    let obj: any = {
      dob: data,
      session_id: this.userSession.sessionToken,
    };
    return this.webservice.post('auth/player/account/dob/verify', obj);
  }

  callUpdatePhoneNumber(data) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      phoneNumber: data,
    };
    return this.webservice.post('auth/player/account', obj);
  }
}
