import { NgModule } from '@angular/core';
import { CasinoBonusHistoryPage } from './casino-bonus-history';
import { SharedModule } from '../../../../../../shared/shared.module';
import { MyDatePickerModule } from 'mydatepicker';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    CasinoBonusHistoryPage,
  ],
  imports: [
    SharedModule,
    MyDatePickerModule,
    CommonModule,
    IonicModule,
    TranslateModule
  ],
})
export class CasinoBonusHistoryPageModule {}
