import { AfterViewInit, ChangeDetectorRef, Component, DoCheck, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import LuckyWheel from './lucky';
import { CommonService } from 'src/app/service/common.service';
import { WebservicesProvider } from 'src/app/system/providers/webservices';
import { UserSessionProvider } from 'src/app/system/providers/user-session';
import { BalanceProvider } from 'src/app/system/providers/balance-provider';
import {Navigator} from "../../system/components/menu/navigator";

@Component({
  selector: 'app-spin-wheel',
  templateUrl: './spin-wheel.component.html',
  styleUrls: ['./spin-wheel.component.scss'],
})
export class SpinWheelComponent implements OnInit, AfterViewInit, DoCheck {

  @Input() payload: any;

  luckyWheelRef: any;

  winPrice: any;
  running = false;
  prizes: any[] = [];

  constructor(
    public comService: CommonService,
    private webService: WebservicesProvider,
    public userSession: UserSessionProvider,
    private cdr: ChangeDetectorRef,
    private balanceProvider: BalanceProvider,
  ) {}

  ngDoCheck(): void {

  }

  ngOnInit() {
    this.prizes = this.payload?.prizes as Array<any>;
    if (this.prizes != null) {
      this.spinInit(true);
    }
    else if (this.userSession.isAuthenticated()) {
      this.spinInit(true);
    } else {
      this.prizes = [
        {id: 106, bonus_currency: "USDT", bonus_amount: 0.5},
        {id: 107, bonus_currency: "USDT", bonus_amount: 1.0},
        {id: 108, bonus_currency: "USDT", bonus_amount: 5.0},
        {id: 109, bonus_currency: "USDT", bonus_amount: 10.0},
        {id: 110, bonus_currency: "USDT", bonus_amount: 50.0},
        {id: 111, bonus_currency: "USDT", bonus_amount: 100.0},
        {id: 112, bonus_currency: "USDT", bonus_amount: 500.0},
        {id: 113, bonus_currency: "USDT", bonus_amount: 1000.0},
      ];
      this.drawSpin();
    }
  }

  handleClose() {
    if (!this.userSession.isAuthenticated()) {
      Navigator.navigateTo("SignupStepOnePage",
            { forceModal: true, cssClass: 'signup_modal', disableBackdrop: true });
    } else {
      setTimeout(() => {
        // Navigator.toLobby();
        Navigator.back();
      }, 0)
    }
  }

  redirectRegister() {
    Navigator.navigateTo("SignupStepOnePage",
      { forceModal: true, cssClass: 'signup_modal', disableBackdrop: true });
  }

  ngAfterViewInit(): void {

  }

  handleStart = () => {
    if (!this.userSession.isAuthenticated()) {
      this.redirectRegister()
      return
    }
    if (this.running) return;

    this.winPrice = null;
    this.running = true;
    this.webService.post('lucky-spin/run', {
      player_id: this.userSession.customerId,
      session_id: this.userSession.getSessionToken()
    }).subscribe(resp => {
      if (resp.code == 200) {
        this.luckyWheelRef.play();
        setTimeout(() => {
          this.handleEnd(resp.payload?.acquired_prize_id);
        }, 3500);
      }
    }, error => {
      this.running = false;
      console.error(error);
      this.comService.showToast(error.getErrorMessage(), 'error_toast');
    });
  };

  handleEnd = (id) => {
    var index = this.prizes.findIndex(el => el.id == id);
    if (index > -1) this.luckyWheelRef.stop(index);
    else this.luckyWheelRef.stop(0);
  };

  spinInit(createLuckyWheel: boolean) {
    this.webService.get('lucky-spin/init', {
      player_id: this.userSession.customerId,
      session_id: this.userSession.getSessionToken()
    }).subscribe(response => {
      if (response.code == 200) {
        this.payload = response.payload;
        this.prizes = this.payload?.prizes as Array<any>;
        if (createLuckyWheel) {
          this.drawSpin();
        }
      }
      this.cdr.detectChanges();
    }, error => {
      console.error('spin err == ', error);
    });
  }

  drawSpin() {
    this.prizes.forEach(el => {el.bonus_currency = (el.bonus_currency as string).toUpperCase()});
    this.luckyWheelRef = new LuckyWheel({
      selector: '.pie',
      len: this.prizes.length,
      onFinished: (index) => {
        this.winPrice = this.prizes[index];
        this.running = false;
        this.balanceProvider.updateBalance();
        this.spinInit(false);
      },
    });
  }
}
