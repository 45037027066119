import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomerModel } from 'src/app/modules/account/models/customer-model';
import { CashierContentProvider } from 'src/app/modules/cashier/providers/cashier-content-provider';
import { CashierProvider } from 'src/app/modules/cashier/providers/cashier-provider';
import { MainProvider } from 'src/app/modules/lobby/providers/main.provider';
import { RewardsProvider } from 'src/app/modules/rewards/providers/rewards-provider';
import { CommonService } from 'src/app/service/common.service';
import { Campaign } from 'src/app/shared/campaign-interface';
import { Utils } from 'src/app/shared/utils';
import { Navigator } from 'src/app/system/components/menu/navigator';
import { Config } from 'src/app/system/providers/configuration';
import { UserSessionProvider } from 'src/app/system/providers/user-session';
import { WebservicesProvider } from 'src/app/system/providers/webservices';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { BalanceProvider } from 'src/app/system/providers/balance-provider';
import { BalanceModel } from 'src/app/system/models/balance-model';
import { delay } from 'rxjs/operators';
import { IonInput } from '@ionic/angular';
import * as copy from 'copy-to-clipboard';
import { AppLinks } from 'src/app/shared/app-links';
import { TranslationService } from 'src/app/service/translation.service';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss'],
})
export class DepositComponent implements OnInit, OnDestroy {

  @ViewChild('amountInputEl') amountInputEl!: IonInput;
  @ViewChild('amountInputElm') amountInputElm!: IonInput;

  @Input() depositCode: any;
  @Input() depositMethod: any;

  customer: CustomerModel;

  allDepositMethods: any[] = [];
  depositMethods: any[] = [];

  currencyPaymentTypes: any;
  currencies: any[] = [];
  currency: any;
  currencySymbol: any;
  showOtherCurrencies = false;
  selectedBalance: any;
  addresses: any[] = [];
  selectedAddress: any;

  selectedPayment;
  showNormalForm = true;

  amount: any;
  minAmount: any = 0;
  minamounterror: any;

  qrcodeWidth = 145;

  appConfig: any;

  loaded = false;

  subs: any[] = [];
  currencyDone: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  balanceSub: any;

  showPayIDContent = false;
  payIdInfo: any;

  showPayType = false;
  clubPayType = 'ID-BANK';

  constructor(
    public comService: CommonService,
    private cdr: ChangeDetectorRef,
    private contentProvider: CashierContentProvider,
    private config: Config,
    public utils: Utils,
    private cashierProvider: CashierProvider,
    public rewardsProvider: RewardsProvider,
    private userSession: UserSessionProvider,
    private webService: WebservicesProvider,
    private mainProvider: MainProvider,
    private iab: InAppBrowser,
    private balanceProvider: BalanceProvider,
    private clipboard: Clipboard,
    private appLinks: AppLinks,
    private transalteService: TranslationService
  ) {

   }

  ngOnInit() {
    // if (this.comService.isMobileWeb) this.qrcodeWidth = 180;
    if (window.innerWidth < 768) this.qrcodeWidth = 180;

    this.appConfig = this.config.getAppConfigInfo();

    this.subs.push(this.userSession.getCustomer().subscribe(customer => {
      this.customer = customer;
    }));

    this.subs.push(this.comService.walletPaymentSub.subscribe(data => {
      if (data) this.getVendorData(data);
    }));

    this.subs.push(this.currencyDone.subscribe(complete => {
      if (complete && !this.balanceSub) {
        this.balanceSub = this.balanceProvider.getBalance().pipe(delay(350)).subscribe((response: BalanceModel) => {
          if (response) {
            Object.keys(response).forEach(key => {
              if (key != 'DEFAULT_CURRENCY') {
                var bal = response.getDisplayBalanceByCurrency(key);
                var ccu = this.currencies.find(cuc => cuc.currency == key);
                if (ccu) {
                  ccu.balance = bal;
                  ccu.amountInUSD = response.amountInUSD(key);
                }
              }
            });

            this.currencies.sort((a,b) => {
              if (a.amountInUSD > b.amountInUSD) return -1;
              else if (a.amountInUSD < b.amountInUSD) return 1;
              else return 0;
            });

            this.selectedBalance = this.currencies.find(cuc => cuc.currency == this.currency);
          }
        });
        this.subs.push(this.balanceSub);
      }
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.subs = [];
  }


  getVendorData(data) {
    this.currencyPaymentTypes = data.currencyPaymentTypesInfo;
    Object.keys(this.currencyPaymentTypes).forEach(key => {
      if (key == 'MATIC') {

      } else {
        this.currencies.push({
          currency: key,
          balance: 0,
          amountInUSD: 0,
          is_crypto: this.currencyPaymentTypes[key]['is_crypto']
        });
      }
    });
    console.log('currencies == ', this.currencies);

    if (!this.currency) {
      var tep = this.currencies.find(cuc => cuc.currency == this.config.getConfig().DEFAULT_CURRENCY);
      if (tep) {
        this.currency = tep.currency;
      }
      else if (this.currencies.length) {
        this.currency = this.currencies[0].currency;
      }
      else {
        this.currency = this.config.getConfig().DEFAULT_CURRENCY;
      }
    }    
    this.currencySymbol = this.comService.currencySymbols[this.currency] || this.currency;
    // this.selectedBalance = this.currencies.find(cuc => cuc.currency == this.currency);


    let vendors = data.paymentTypesInfo;
    let sortedDepositMethods: any[] = [];
    for (let vendor of vendors) {
      if (!vendor.name) vendor.name = (vendor.types && vendor.types.length) ? vendor.types[0].name : '';

      vendor.logo = this.utils.getCDNAsset(
        'imgs/' + (vendor.image_url || ((vendor.types && vendor.types.length) ? vendor.types[0].image_url : ''))
      );

      if (vendor.code == 'PS') {
        vendor.defaultAmount = this.getDefaultAmount('FEATURE_PAYSTACK_AMOUNTS');
      } else if (vendor.code == 'BST') {
        vendor.defaultAmount = this.getDefaultAmount('FEATURE_BSTPAY_AMOUNTS');
      } else if (vendor.code == 'MN') {
        vendor.defaultAmount = this.getDefaultAmount('FEATURE_MINA_AMOUNTS');
      } else if (vendor.code == 'JP') {
        vendor.defaultAmount = this.getDefaultAmount('FEATURE_JUNE_AMOUNTS');
      } else if (vendor.code == 'PID') {
        vendor.defaultAmount = this.getDefaultAmount('FEATURE_PAYID_AMOUNTS');
      } else if (vendor.code == 'IPCT') {
        vendor.defaultAmount = this.getDefaultAmount('FEATURE_IPAY_CRYPTO_USDT_AMOUNTS');
      } else if (vendor.code == 'CLUB') {
        vendor.defaultAmount = this.getDefaultAmount('FEATURE_CLUB_PAY_AMOUNTS');
      } else if (vendor.code == 'FASTPAY') {
        vendor.defaultAmount = this.getDefaultAmount('FEATURE_FAST_PAY_AMOUNTS');
      } else {
        vendor.defaultAmount = [100, 50, 25];
      }

      vendor.min = (vendor.types && vendor.types.length) ? vendor.types[0].min : 0;
      vendor.max = (vendor.types && vendor.types.length) ? vendor.types[0].max : 0;
      vendor.fee = '0%';

      sortedDepositMethods.push(vendor);
    }
    this.allDepositMethods = sortedDepositMethods;
    console.log('this.depositMethods == ', this.allDepositMethods);

    if (this.currencyPaymentTypes[this.currency] && this.currencyPaymentTypes[this.currency]['is_crypto']) {
      this.getCryptoAddresses(this.currency, this.currencyPaymentTypes[this.currency].paymentTypes[0]);
    } else {
      this.depositMethods = this.allDepositMethods.filter(method => (this.currencyPaymentTypes[this.currency]?.paymentTypes || []).includes(method.code));
      this.selectedPayment = this.depositMethods.length ? this.depositMethods[0] : null;
      if (this.selectedPayment && this.selectedPayment.code == 'HKP') {
        this.selectedPayment.min = this.appConfig['HKPPay'][this.currency]['MIN'];
        this.selectedPayment.max = this.appConfig['HKPPay'][this.currency]['MAX'];
        if (this.config.getConfig()[`FEATURE_HKP_${this.currency}_AMOUNTS`]) {
          this.selectedPayment.defaultAmount = this.getDefaultAmount(`FEATURE_HKP_${this.currency}_AMOUNTS`);
        } else {
          this.selectedPayment.defaultAmount = this.getDefaultAmount('FEATURE_BSTPAY_AMOUNTS');
        }
      }
      this.loaded = true;
      this.showNormalForm = true;
    }

    this.amount = null;
    this.minAmount = this.appConfig['DEPOSIT_MIN_AMOUNT'][this.currency] || 0;

    this.currencyDone.next(this.currencies);
  }

  getCryptoAddresses(currency, payment_type) {
    this.depositMethods = [];
    this.selectedPayment = null;
    this.showNormalForm = false;
    if (currency == 'USDT' && this.appConfig['IPAY_CRYPTO']) this.fetchIPayCrypto(payment_type);
    else {
      this.callCryptoAddressApi(currency, payment_type);
    }
  }

  callCryptoAddressApi(currency, payment_type) {
    this.webService.post('cashier/crypto-address/list', {
      payment_method_code: payment_type,
      currency_code: currency,
      customer_id: this.userSession.getCustomerId(),
      session_id: this.userSession.getSessionToken()
    }).subscribe(response => {
      console.log('crypto addresses == ', response);
      if (response.code == 200) {
        var adds = response.payload[currency] || [];
        let config_chains: any[] = this.appConfig.CHAIN_TOKEN[currency] || [];
        this.addresses = adds.filter(el => config_chains.includes(el.chain_name));
        this.selectedAddress = this.addresses.length ? this.addresses[0] : null;
      }
      this.loaded = true;
      this.depositMethods = [];
      this.selectedPayment = null;
      this.showNormalForm = false;
      this.cdr.detectChanges();
    }, error => {
      console.error('crypto addresses error == ', error);
      this.addresses = [];
      this.selectedAddress = null;
      this.loaded = true;
      this.depositMethods = [];
      this.selectedPayment = null;
      this.showNormalForm = false;
      this.cdr.detectChanges();
    });
  }

  fetchIPayCrypto(payment_type) {
    this.addresses = [];
    this.selectedAddress = null;
    this.depositMethods = this.allDepositMethods.filter(el => el.code == payment_type);
    this.selectedPayment = this.depositMethods.length ? this.depositMethods[0] : null;
    this.showNormalForm = true;
    this.loaded = true;
  }

  getDefaultAmount(featureVendorAmounts) {
    return String(this.config.getConfig()[featureVendorAmounts]).split(',').reverse();
  }

  selectPaymentMethod(vendor) {
    this.showNormalForm = true;
    this.selectedAddress = null;
    // if (this.selectedPayment?.code == vendor.code) {
    //   this.selectedPayment = null;
    //   return;
    // }
    // else {
      this.selectedPayment = vendor;
      if (this.selectedPayment.code == 'HKP') {
        this.selectedPayment.min = this.appConfig['HKPPay'][this.currency]['MIN'];
        this.selectedPayment.max = this.appConfig['HKPPay'][this.currency]['MAX'];
        if (this.config.getConfig()[`FEATURE_HKP_${this.currency}_AMOUNTS`]) {
          this.selectedPayment.defaultAmount = this.getDefaultAmount(`FEATURE_HKP_${this.currency}_AMOUNTS`);
        } else {
          this.selectedPayment.defaultAmount = this.getDefaultAmount('FEATURE_BSTPAY_AMOUNTS');
        }
      }
      if (this.amount) this.checkAmount();
    // }
  }

  selectAddress(add) {
    this.selectedAddress = add;
    this.selectedPayment = null;
    this.showNormalForm = false;
  }

  selectAmount(a) {
    this.amount = Number(a);
    if (this.amountInputEl) this.amountInputEl.value = this.amount;
    if (this.amountInputElm) this.amountInputElm.value = this.amount;
    this.minamounterror = null;
    this.checkAmount();
  }

  inputAmount($event) {
    const value = $event.target!.value;
    // Removes non alphanumeric characters
    var filteredValue = value.replace(/[^0-9.]+/g, '');
    var dots = (filteredValue as string).split('.');
    if (dots.length > 2) {
      var decimal = dots[1];
      for (let i = 2; i < dots.length; i++) {
        decimal += dots[i];
      }
      if (decimal.length > 8) {
        decimal = decimal.substring(0, 8);
      }

      var integ = dots[0];
      if (integ.length > 10) {
        integ = integ.substring(0, 10);
      }

      filteredValue = integ + '.' + decimal;
    } else if (dots.length == 2) {
      var decimal = dots[1];
      if (decimal.length > 8) {
        decimal = decimal.substring(0, 8);
      }

      var integ = dots[0];
      if (integ.length > 10) {
        integ = integ.substring(0, 10);
      }

      filteredValue = integ + '.' + decimal;
    } else {
      var integ = dots[0];
      if (integ.length > 10) {
        integ = integ.substring(0, 10);
      }
      filteredValue = integ;
    }

    if (this.amountInputEl) this.amountInputEl.value = this.amount = filteredValue;
    if (this.amountInputElm) this.amountInputElm.value = this.amount = filteredValue;
    this.minamounterror = null;

    this.checkAmount();
  }

  async checkAmount() {
    this.minamounterror = null;
    let amm = Number(this.amount);
    if (amm < 0) {
      this.minamounterror = await this.transalteService.getTranslationWord('COMMON.INVALID_AMOUNT');
    } else if (amm > this.selectedPayment.max) {
      this.minamounterror = await this.transalteService.getTranslationWord('WITHDRAW.INVALID_AMOUNT_ERROR.GREATER_MAX');
    } else if (amm < this.selectedPayment.min) {
      this.minamounterror = await this.transalteService.getTranslationWord('WITHDRAW.INVALID_AMOUNT_ERROR.LESS_MIN');
    }
  }

  selectCurrecy(cuc) {
    this.selectedBalance = cuc;
    this.currency = this.selectedBalance.currency;
    this.currencySymbol = this.comService.currencySymbols[this.currency] || this.currency;

    if (cuc['is_crypto']) {
      this.getCryptoAddresses(this.currency, this.currencyPaymentTypes[this.currency].paymentTypes[0]);
    } else {
      this.addresses = [];
      this.selectedAddress = null;
      this.depositMethods = this.allDepositMethods.filter(method => (this.currencyPaymentTypes[this.currency]?.paymentTypes || []).includes(method.code));
      console.log('this.depositMethods == ', this.depositMethods);
      this.selectedPayment = this.depositMethods.length ? this.depositMethods[0] : null;
      if (this.selectedPayment && this.selectedPayment.code == 'HKP') {
        this.selectedPayment.min = this.appConfig['HKPPay'][this.currency]['MIN'];
        this.selectedPayment.max = this.appConfig['HKPPay'][this.currency]['MAX'];
        if (this.config.getConfig()[`FEATURE_HKP_${this.currency}_AMOUNTS`]) {
          this.selectedPayment.defaultAmount = this.getDefaultAmount(`FEATURE_HKP_${this.currency}_AMOUNTS`);
        } else {
          this.selectedPayment.defaultAmount = this.getDefaultAmount('FEATURE_BSTPAY_AMOUNTS');
        }
      }
      this.showNormalForm = true;
    }

    this.amount = null;
    this.minAmount = this.appConfig['DEPOSIT_MIN_AMOUNT'][this.currency] || 0;

    setTimeout(() => {
      this.showOtherCurrencies = false;
    }, 100);
  }

  async copyAddress(address) {
    try {
      // await navigator.clipboard.writeText(address);
      copy(address);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
    var msg = await this.transalteService.getTranslationWord('DEPOSIT.ADDRESS_COPIED');
    this.comService.showToast(msg);
  }

  async onDeposit() {
    if(!this.selectedPayment || !this.amount || !!this.minamounterror) return;

    this.mainDepositFunc();
  }

  mainDepositFunc() {
    var sendParams: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      payment_method_code: this.selectedPayment.code,
      amount: Number(this.amount),
      email: this.customer.email,
      currency_code: this.currency
    };
    if (this.selectedPayment.code == 'CLUB') {
      sendParams['pay_type'] = this.clubPayType;
    }
    this.webService.post('cashier/deposit/create', sendParams).subscribe(resp => {
      console.log('deposit resp == ', resp);
      if (resp.code == 200) {
        if (this.currency == 'USDT' && this.appConfig['IPAY_CRYPTO']) {
          this.callCryptoAddressApi(this.currency, this.selectedPayment.code);
        }
        else if (this.currency == 'AUD' && this.selectedPayment.code == 'PID') {
          this.showPayIDContent = true;
          this.payIdInfo = resp.payload;
        }
        else if (resp.payload.redirectUrl) {
          if (!this.mainProvider.isIABApp()) {
            const options: InAppBrowserOptions = {
              clearcache: "yes",
              footer: "no",
              fullscreen: "yes",
              hardwareback: "yes",
              hidespinner: "no",
              presentationstyle: "pagesheet",
              toolbar: "no",
              hidden: "yes",
              closebuttoncaption: "Close",
              hidenavigationbuttons: "yes",
              hideurlbar: "yes",
              location: "yes"
            };
            const browser = this.iab.create(
              resp.payload.redirectUrl,
              '_system',
              options
            );
            console.log('inapp browser created');
            Navigator.dismissModal();

          } else if (this.mainProvider.isIABApp()) {
            Navigator.dismissModal();
            window.location.replace(resp.payload.redirectUrl);
          }
        }
        else if (!resp.payload.redirectUrl && resp.payload.parameters && resp.payload.parameters.type == 'form') {
          let {PayUrl, type, ...others} = resp.payload.parameters;
          others['token'] = 'VTBjc1pqTnZES3B1bUtKUGRmUSt0L3QxMGpmWWlPZmI3QjZyU3kyWWFTaz0=';

          var newWindow = window.open('about:blank', '_system');
          newWindow.document.write(
            `<style> body{ background:#FFFFFF !important;  position: relative; color: black;} div.container { margin: 0;position: absolute;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%);color: white;font-family: arial;font-size: 1.5em; }</style>`
          );
          var values = '';
          Object.keys(others).forEach(key => {
            values = values + `<input type="hidden" name="${key}" value="${others[key]}"> <br>`;
          });
          var formContent = `<body>
            <form id="vpayForm" action="${PayUrl}" method="post">
              ${values}
              <input type="submit" value="Submit" style="display: none;">
            </form>

            <script>
              var auto_refresh = setTimeout(function() { submitform(); }, 2000);

              function submitform()
              {
                var form = document.getElementById('vpayForm');
                if (form) {
                  form.submit();
                }
              }
            </script>
          </body>`;
          newWindow.document.write(formContent);
          newWindow.focus();

          Navigator.dismissModal();
        }
      }
      else {
        this.comService.showToast(resp.message, 'error_toast');
      }
    }, error => {
      console.error('deposit error = ', error);
      this.comService.showToast(error.error_message, 'error_toast');
    });
  }

  contactSupport() {
    let configuredURL = this.config.getConfig()['FEATURE_CUSTOMER_SUPPORT_URL'] || "";
    if (!configuredURL) return;
    this.appLinks.openExternalPage(configuredURL);
  }


  /**
   * Bonus part
   */

  offers: any[] = [];
  hideOffers: boolean = false;
  bonusCode: string;
  selectedBonusItem: any;
  bonusID: string;

  onGetBonusOffers() {
    return new Promise((resolve, reject) => {
      this.rewardsProvider.callGetBonusOffers().subscribe(
        (response: any) => {
          console.log('callGetBonusOffers == ', response);
          if (response && !!response.result) {
            let data = response.payload;
            this.offers = data.filter(this.onlyDepositBonus);

            this.hideOffers = (this.offers.length == 1 && this.offers.filter((bonus) => bonus.campaign_code == 'ZZ_NO_DEPOSIT').length == 1) ? true : false;

            this.selectBestOne(this.offers);
          }
          resolve('');
        },
        (error) => {
          resolve('');
        }
      );
    });
  }

  onlyDepositBonus = (item) => item.campaign_type == 'DEPOSIT';

  selectBestOne(data: Array<Campaign>) {
    if (data && typeof data != 'undefined' && data.length > 0) {
      let campaignId = this.utils.getCampaignId();
      if (!this.depositCode)
        if (campaignId) {
          let index = data.map((e) => e.id).indexOf(campaignId);
          this.onBonusSelect(index, data[index]);
        } else {
          let objectLenght = String(data.length - 1);
          for (let item in data) {
            if (
              data[item].tagline != null &&
              data[item].tagline.toUpperCase().includes('DEFAULT')
            ) {
              this.onBonusSelect(item, data[item]);
              return;
            }
            if (
              data[item].campaign_code != null &&
              data[item].campaign_code.toUpperCase().includes('ZZ_NO_DEPOSIT')
            ) {
              this.onBonusSelect(item, data[item]);
              return;
            }
            if (item == objectLenght) {
              this.onBonusSelect('0', data[0]);
              return;
            }
          }
        }
      else {
        let objectLenght = String(data.length - 1);
        for (let item in data) {
          if (
            data[item].campaign_category != null &&
            data[item].campaign_category == this.depositCode
          ) {
            this.onBonusSelect(item, data[item]);
            return;
          }
          if (item == objectLenght) {
            this.onBonusSelect('0', data[0]);
            return;
          }
        }
      }
    }
  }

  onBonusSelect(index, offer) {
    this.bonusCode = offer.campaign_code;
    this.selectedBonusItem = index;
    this.bonusID = offer.id;
  }

  /**
   * PayID processing
   */
  confirmPayID() {
    this.webService.post('cashier/notify-discord/deposit', {
      customer_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      payment_request_id: this.payIdInfo?.paymentRequestID
    }).subscribe(resp => {
      if (resp.code == 200) {
        Navigator.dismissModal();
      }
    }, error => {
      console.error(error);
    });
  }

}
