export class Currency {
  total?: any;
  amount?: any;
  amountReserved?: any;
  restrictedBalance?: any;
  availableBalance?: any;
  bonus?: any;
  restrictedTotal?: any;
  restrictedCash?: any;
  restrictedBonus?: any;
  totalWin?: any;
  totalLoss?: any;
  moneyAtTables?: any;
  pureUSD?: any;
  depositCount?: any;
  totalDepositsAmount?: any;
  totalWithdrawalsAmount?: any;
  bingoBalance?: any;
  label?: any;
  symbol?: any;

  constructor(data: Object = {}) {
    Object.assign(this, data);
  }
}

export class BalanceModel {
  USD?: Currency;
  CAD?: Currency;
  PLAY?: Currency;
  LBPOINTS?: Currency;
  LLTY?: Currency;
  RWP?: Currency;
  BGD?: Currency;
  TD?: Currency;
  NGN?: Currency;
  USDT?: Currency;

  DEFAULT_CURRENCY = null;

  constructor(data: any = {}, config) {
    Object.assign(this, data);
    this.setDefaultCurrency(config.DEFAULT_CURRENCY);
  }

  setDefaultCurrency(value) {
    this.DEFAULT_CURRENCY = value;
  }

  getDefaultCurrency() {
    return this.DEFAULT_CURRENCY;
  }

  getAvailableBalance() {
    return this[this.DEFAULT_CURRENCY].availableBalance * 100;
  }

  getAmount() {
    return this[this.DEFAULT_CURRENCY].amount * 100;
  }

  getBonus() {
    return this[this.DEFAULT_CURRENCY].bonus * 100;
  }

  getRestrictedTotal() {
    return this[this.DEFAULT_CURRENCY].restrictedTotal * 100;
  }

  getRestrictedCash() {
    return this[this.DEFAULT_CURRENCY].restrictedCash * 100;
  }

  getRestrictedBonus() {
    return this[this.DEFAULT_CURRENCY].restrictedBonus * 100;
  }

  getRealCash() {
    return this[this.DEFAULT_CURRENCY].availableBalance * 100;
  }

  getAvailableForWithdrawalBalance() {
    return this[this.DEFAULT_CURRENCY].availableBalance * 100;
  }

  getAvailableForWithdrawalBalanceByCurrency(currency) {
    return (this[currency].amount - this[currency].restrictedBalance) > 0 ? (this[currency].amount - this[currency].restrictedBalance) * 100 : 0;
  }

  forPlayCasino() {
    return (this[this.DEFAULT_CURRENCY].availableBalance * 100 + this[this.DEFAULT_CURRENCY].restrictedBalance * 100) || 0;
  }

  forPlayCasinoByCurrency(currency) {
    return (this[currency].availableBalance * 100 + this[currency].restrictedBalance * 100) || 0;
  }

  amountInUSD(currency) {
    return this[currency].amountInUSDT * 100;
  }

  getDisplayBalanceByCurrency(currency) {
    return this[currency].amount * 100;
  }


  forPlayRestrictedOnlyCasino() {
    return this[this.DEFAULT_CURRENCY].restrictedBalance * 100;
  }

  forPlayRestrictedOnlyCasinoByCurrency(currency) {
    return this[currency].restrictedBalance * 100;
  }

  forPlayPoker() {
    return this[this.DEFAULT_CURRENCY].availableBalance * 100;
  }

  playMoneyCasino() {
    return this.PLAY.availableBalance * 100 + this.PLAY.restrictedBalance * 100;
  }

  playMoneyPoker() {
    return this.PLAY.availableBalance * 100;
  }

  forPlayBingo() {
    if (this.BGD != null && this.BGD.amount != null)
      return (
        this[this.DEFAULT_CURRENCY].availableBalance * 100 +
        parseInt(this.BGD.amount) * 100
      );
    else return this[this.DEFAULT_CURRENCY].availableBalance * 100;
  }

  getBingoDollars() {
    return this.BGD.amount * 100;
  }

  getTournamentDollars() {
    return this.TD.amount * 100;
  }

  moneyAtTables() {
    return this[this.DEFAULT_CURRENCY].moneyAtTables * 100;
  }

  getDepositCount() {
    return this[this.DEFAULT_CURRENCY].depositCount;
  }
  getTotalDepositAmount() {
    return this[this.DEFAULT_CURRENCY].totalDepositsAmount * 100;
  }

  getWithdrawalCount() {
    return this[this.DEFAULT_CURRENCY].withdrawalCount;
  }
  getTotalWithdrawalAmount() {
    return this[this.DEFAULT_CURRENCY].totalWithdrawalsAmount * 100;
  }

  get curencyTitle() {
    let title;
    switch (this.DEFAULT_CURRENCY) {
      case 'PLAY':
        title = 'Playchips';
        break;
      case 'CAD':
      case 'USD':
      case 'NGN':
      case 'INR':
        title = 'Real Cash';
        break;
    }
    return title;
  }

  get isRealMoney() {
    let result;
    switch (this.DEFAULT_CURRENCY) {
      case 'PLAY':
        result = false;
        break;
      case 'CAD':
      case 'USD':
      case 'NGN':
      case 'INR':
        result = true;
        break;
    }
    return result;
  }
}
