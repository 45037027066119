import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
import { MyEvent } from 'src/app/service/myevent.services';

/**
 * Sticky Element
 */

@Directive({
  selector: '[stickyElement]', // Attribute selector
})
export class StickyElementDirective {
  public activateStickyPosition: number;
  public snappedToHeader: boolean;
  public windowWidth: number;
  public initialMenuWidth: number;

  resizeTimeoutSet = null;
  @HostListener('window:resize')
  onWindowResize() {
    if (this.resizeTimeoutSet) {
      clearTimeout(this.resizeTimeoutSet);
    }
    this.resizeTimeoutSet = setTimeout(
      (() => {
        this.initialMenuWidth = this.getInitialMenuWidth();
        if (this.snappedToHeader) {
          this.initialMenuWidth = this.getInitialMenuWidth();
          this.isSticky(this.el.nativeElement);
        }
      }).bind(this),
      500
    );
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    protected events: MyEvent
  ) {
    this.snappedToHeader = false;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.events.unsubscribe('event:lobby:scroll');
    this.notSticky(this.el.nativeElement);
    this.initDirective();
  }

  initDirective() {
    this.events.getObservable().subscribe((data) => {
      if (data.event == 'event:lobby:scroll') {
        let screenPosition = data.data;
        this.initialMenuWidth = this.getInitialMenuWidth();
        this.getHeaderHeight();
        this.activateStickyPosition = this.calculateFixedPositionBreakpoint(
          this.el.nativeElement
        );

        const screenPos = screenPosition;
        if (this.activateStickyPosition < 0 && !this.snappedToHeader) {
          this.snappedToHeader = true;
          this.isSticky(this.el.nativeElement);
        } else if (screenPos < 60 && this.snappedToHeader) {
          this.snappedToHeader = false;
          this.notSticky(this.el.nativeElement);
        }
      }
    });
  }

  calculateFixedPositionBreakpoint(parentElem) {
    if (!parentElem) return;
    let elementPosition = this.getPosition(parentElem);
    return elementPosition;
  }

  getPosition(element) {
    var rect = element.getBoundingClientRect();
    return rect.top;
  }

  getInitialMenuWidth() {
    const headerElement = document.getElementsByClassName('gallery-wrapper'); //use gallery wrapper since it's 100 percent width constantly
    if (!headerElement) return;
    if (headerElement[0]) {
      let snappingPoint = headerElement[0].clientWidth;
      return snappingPoint;
    }
  }

  getHeaderHeight() {
    // const headerElement = document.getElementsByClassName('main-navigation');
    // const segmentElement = document.getElementsByClassName('main_segment');
    // if (!headerElement) return;
    // if(headerElement[0]){
    //   if (segmentElement) {
    //     if (segmentElement[0]) {
    //       let snappingPoint = headerElement[0].clientHeight + segmentElement[0].clientHeight;
    //       return snappingPoint;
    //     }
    //   } else {
    //     let snappingPoint = headerElement[0].clientHeight;
    //     return snappingPoint;
    //   }
    // }

    let headerNav = document.getElementById('main-navigation');
    if (headerNav) {
      return headerNav.clientHeight;
    }
  }

  isSticky(parentElem: HTMLElement) {
    if (!parentElem) return;
    this.renderer.addClass(parentElem, 'sticky-menu');
    parentElem.style.top = '0px'; // this.getHeaderHeight() + 'px';
    parentElem.style.width = this.initialMenuWidth + 'px';
    console.log('added sticky header: ', this.getHeaderHeight());
  }

  notSticky(parentElem: HTMLElement) {
    if (!parentElem) return;
    this.renderer.removeClass(parentElem, 'sticky-menu');
    parentElem.style.width = '100%';
  }
}
