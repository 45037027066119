import { CashierProvider } from '../../../providers/cashier-provider';
import { Component, ViewChild } from '@angular/core';
import { Navigator } from './../../../../../system/components/menu/navigator';
import { Config } from './../../../../../system/providers/configuration';
import { Utils } from '../../../../../shared/utils';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'page-pending-withdrawals',
  templateUrl: 'pending-withdrawals.html',
  styleUrls: ['./pending-withdrawals.scss'],
})
export class PendingWithdrawalsPage {

  @ViewChild('Content') content: IonContent;

  public error = null;
  public getComplete;
  public pendingWithdrawals: any[] = [];
  public emailMeDisabled: boolean = false;
  public confirm: boolean = false;
  private widthdrawalData: any = [];
  public payoutLock: boolean;

  constructor(
    private cashierProvider: CashierProvider,
    private config: Config,
    public utils: Utils
  ) {
    let appConfigInfo = this.config.getAppConfigInfo();
    this.payoutLock = appConfigInfo.payoutLock;
  }

  ionViewDidEnter() {
    console.log('ionViewDidEnter PendingWithdrawalsPage');
    this.onGetPendingWithdrawals();
  }

  formatPendingWithdrawals(withdrawals) {
    var tempTransactions: any = withdrawals;
    for (let index in tempTransactions) {
      this.pendingWithdrawals.push(tempTransactions[index]);
    }
  }

  pendingWithdrawalsEmiter(data) {
    if (data.success) {
      this.onGetPendingWithdrawals();
    }
    if (data.error) {
      this.error = data.error;
    }
  }

  onGetPendingWithdrawals() {
    this.pendingWithdrawals = [];
    this.cashierProvider.callGetPendingWithdrawals().subscribe(
      (response: any) => {
        console.log('callGetPendingWithdrawals ', response);
        if (response && !!response.result) {
          this.formatPendingWithdrawals(response.payload);
        }
        this.getComplete = true;
      },
      (error) => {
        this.error = error;
        this.getComplete = false;
      }
    );
  }

  onEmailMeClicked() {
    this.emailMeDisabled = true;

    // this.cashierProvider.callSendCasinoGameHistoryDetails().subscribe(
    //     (response) => {
    //         console.log('callSendCasinoGameHistory response = ', response);
    //         if (response.result == 1) {
    //             this.content.scrollToTop();

    //             this.successMessageProvider.setMessage("An email with all results has been sent.");
    //         }
    //     },
    //     (error) => {
    //         console.log('callSendCasinoGameHistory error = ', error);
    //         this.successMessageProvider.clearMessage();
    //     }
    // )
  }

  onReverseClick(withdrawal) {
    Navigator.navigateTo(
      'WithdrawalReverseConfirmPage',
      { multipleModals: false },
      { payoutRequestID: withdrawal.payoutRequestID, amount: withdrawal.amount }
    );
  }
}
