import { JurisdictionProvider } from "./../../../shared/providers/jurisdiction";
import { Utils } from "./../../../shared/utils";
import { CashierProvider } from "./../../../modules/cashier/providers/cashier-provider";
import { Config } from "./../../../system/providers/configuration";
import { UserSessionProvider } from "./../../../system/providers/user-session";
import { Navigator } from "./../../../system/components/menu/navigator";
import { Component, OnInit, Input, Inject } from "@angular/core";
import { AccountProvider } from "src/app/modules/account/providers/account-provider";

@Component({
  selector: "deposit-btn",
  templateUrl: "deposit-btn.html",
  styleUrls: ['./deposit-btn.scss']
})
export class DepositBtnComponent implements OnInit {
  //the pageName of the page you want to return when clicking the back button
  @Input() navigateBackPage;
  @Input() buttonName;
  @Input() campaignId; // used to get the bonus code and the bonus image
  @Input() size;
  @Input() depositMethod;
  @Input() isIOSApp: boolean;
  @Input() activeTabName;

  private redirectPage;
  private lastDeposit;
  public showButton = false;
  public title = "";
  public isSportsApp: Boolean = false;

  constructor(
    public userSession: UserSessionProvider,
    private cashierProvider: CashierProvider,
    public config: Config,
    public utils: Utils,
    public accountProvider: AccountProvider,
    protected jurisdiction: JurisdictionProvider,
  ) {
    
  }

  ngOnInit() {
    this.showButton = true;
    this.title = this.buttonName;
  }

  onDeposit() {
    this.redirect();

    // if (this.isDeposit()) {
    //   this.accountProvider
    //     .callGetQualification("deposit")
    //     .subscribe((customrQualification) => {
    //       let qualifyParams = {
    //         finalDestination: this.defaultDepositPage,
    //         params: { qualification: "deposit" },
    //       };
    //       console.log('customrQualification : ', customrQualification);
    //       if (this.utils.isQualified(customrQualification).result) {
    //         this.redirect();
    //       } else {
    //         if (
    //           this.utils.isQualified(customrQualification).pageToNavigate ===
    //           "InvalidGeoPage"
    //         ) {
    //           this.jurisdiction
    //             .listen()
    //             .subscribe(
    //               (geoResp) => {
    //                 if (geoResp.is_allowed) {
    //                   this.redirect();
    //                 } else {
    //                   Navigator.navigateTo(
    //                     this.utils.isQualified(customrQualification)
    //                       .pageToNavigate,
    //                     {},
    //                     qualifyParams
    //                   );
    //                 }
    //               },
    //               (err) => {
    //                 if (!!err && err.message && err.code) {
    //                   Navigator.navigateTo("GeoNotEnabledPage");
    //                 } else {
    //                   Navigator.navigateTo(
    //                     this.utils.isQualified(customrQualification)
    //                       .pageToNavigate,
    //                     {},
    //                     qualifyParams
    //                   );
    //                 }
    //               }
    //             );
    //         } else {
    //           Navigator.navigateTo(
    //             this.utils.isQualified(customrQualification).pageToNavigate,
    //             {},
    //             qualifyParams
    //           );
    //         }
    //       }
    //     });
    // } else {
    //   Navigator.navigateTo("BuyChipsPage");
    // }
  }

  getLastDeposit() {
    this.cashierProvider.getLastDeposit().subscribe(
      (response: any) => {
        this.lastDeposit = response;
        this.redirect();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  isDeposit() {
    return true;
  }

  redirect() {
    //This now redirects to the deposit main page menu since both CC and ACH pages need an amount to be passed in from that page
    //this.utils.setBonusCode(this.bonusCode);
    this.utils.setCampaignId(this.campaignId);

    //  navigateBackPage = > name of the page to navigate back, e.g Bonus Offers
    let navParams: any = {};
    if (!!this.navigateBackPage)
      navParams.navigateBackPage = this.navigateBackPage;
    if (!!this.depositMethod) navParams.depositMethod = this.depositMethod;
    Navigator.navigateTo("PaymentsPage", { cssClass: 'wide_modal'}, navParams);
  }

  getDepositPage(method) {
    let page = "";
    switch (method) {
      case "ACH":
      case "EC":
      case "EFT":
      case "BD":
        page = "DepositECheckPage";
        break;
      case "GP":
        //return $this->_forward('deposit-ach-vip');
        break;
      case "NETL":
        //return $this->_forward('deposit-neteller', null, null, $params);
        break;
      case "VI":
      case "MC":
      case "AM":
        page = "DepositCreditCardPage";
        break;
      case "NS":
        page = "DepositNeosurfPage";
        break;
      case "PNM":
        //deposit-paynearme
        break;
      case "PPD":
        //deposit-paypal
        break;
      case "SL":
        //deposit-sightline
        break;
      case "MZM":
        //deposit-mazooma
        break;
      case "PNM":
        //deposit-paynearme
        break;
    }
    return page;
  }
}
