import { NgModule } from '@angular/core';
import { PendingWithdrawalsPage } from './pending-withdrawals';
import { SharedModule } from '../../../../../shared/shared.module';
import { SystemModule } from './../../../../../system/system.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    PendingWithdrawalsPage,
  ],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    SystemModule
  ],
})
export class PendingWithdrawalsPageModule {}
