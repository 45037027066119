// Spanish
export const locale = {
  lang: 'es-ES',
  data: {
    LEFT_SIDE: {
      EARN_CATEGORY_TITLE: 'Ganar',
      REFERRAL: 'Referido',
      VIP: 'VIP Club',
      LANGS: {
        ENGLISH: 'English',
        CHINESE: '简体中文',
        PORTUGUES: 'Português',
        THAILIAND: 'ภาษาไทย',
        VIETNAME: 'Tiếng Việt',
        SPANISH: 'Español',
        INDONESIAN: 'Bahasa Indonesia',
        JAPANESE: '日本語'
      },
      INTEREST: "interest",
      REFER_EARN: "Refer & Earn",
      VIP_CLUB: '<span style="color: #ffb636;">VIP</span> Club'
    },
    HEADER: {
      SPORT_BUTTON_TEXT: "Deportes",
      CASINO_BUTTON_TEXT: "Casino",
      PROMOTIONS: 'Promociones',
      SEARCH: "Search",
      SEARCH_PLACEHOLDER: "Buscar juegos o proveedores",
      SEARCH_PART_GAMES: "Juegos",
      SEARCH_PART_PROVIDERS: "Proveedores",
      SEARCH_RESULT: "Search Results",
      SEARCH_NO_RESULT: "Sin resultados de búsqueda",
      SEARCH_TRY_GAMES: "Recommended",
      RECOMMENDED_GAMES: "Recommended for you",
      LOGIN_BUTTON_TEXT: "Iniciar sesión",
      REGISTER_BUTTON_TEXT: "Registrarse",
      BALANCE_INGAME: "EN JUEGO",
      DEPOSIT_BUTTON_TEXT: "Depósito",
      Settings: "Settings",
      ALL_GAMES: "All Games",
      SELECT_GAME: "Select Game"
    },
    BALANCES: {
      FIAT: 'Fiat',
      CRYPTO: 'Cripto',
      DISPLAY_FIAT: 'Mostrar en Fiat'
    },
    MOBILE_FOOTER: {
      BETSPLUS: {
        SPORT_TAB_TEXT: 'Deportes',
        CASINO_TAB_TEXT: 'Casino',
        PROMOTIONS_TAB_TEXT: 'Promociones',
        MENU_TAB_TEXT: 'Menú'
      }
    },
    DASHBOARD: {
      CATEGORY_LOBBY_NAME: "Vestíbulo",
      LAST_PLAYED: "Recientes",
      VIEWALL_BUTTON_TEXT: "Todos",
      VIEWALL_BUTTON_TEXT1: "View All",
      ALL_GAMES_COUNT: "Todos los {{count}}",
      NO_GAMES: "Sin datos de juegos",
      GAME_PROVIDERS_TITLE: "Proveedores de juegos",
      RECORDS: {
        TITLE: "Registro",
        TABS: {
          MY_BETS_TITLE: "Mis apuestas",
          ALL_BETS_TITLE: "Todas las apuestas",
          RARE_WINS_TITLE: 'Grandes ganancias',
        },
        DATA: {
          BETS_TABLE: {
            COLUMN1: 'Juego',
            COLUMN2: 'Nombre de usuario',
            COLUMN3: 'Hora',
            COLUMN4: 'Monto de la apuesta',
            COLUMN5: 'Multiplicador',
            COLUMN6: 'Pago'
          },
          WINS_TABLE: {
            COLUMN1: 'Juego',
            COLUMN2: 'Nombre de usuario',
            COLUMN3: 'Monto de la apuesta',
            COLUMN4: 'Multiplicador',
            COLUMN5: 'Pago'
          },
          WINS_TABLE_BETSPLUS: {
            COLUMN1: 'Rango',
            COLUMN2: 'Nombre de usuario',
            COLUMN3: 'Apostado',
            COLUMN4: 'Premio'
          },
          NO_MY_BETS_TEXT: "No hay mis apuestas",
          NO_ALL_BETS_TEXT: "No hay todas las apuestas",
          NO_RARE_WINS_TEXT: "No hay grandes ganancias"
        }
      }
    },
    AUTH: {
      TOPBAR: {
        LOGIN_BUTTON_TEXT: "Iniciar sesión",
        REGISTER_BUTTON_TEXT: "Registrarse"
      },
      LOGIN: {
        TITLE: "¡Bienvenido de nuevo!",
        EMAIL: 'Correo electrónico',
        EMAIL_EMPTY_ERROR: "Por favor, ingrese un correo electrónico",
        EMAIL_INVALID_ERROR: 'Por favor, ingrese un correo electrónico válido',
        PHONE_NUMBER: "Número de teléfono",
        PHONE_EMPTY_ERROR: 'Por favor, ingrese su número de teléfono',
        PHONE_INVALID_ERROR: 'Por favor, ingrese un número de teléfono válido',
        PASSWORD: 'Contraseña',
        PASSWORD_EMPTY_ERROR: 'Se requiere contraseña',
        PASSWORD_LENGTH_ERROR: 'La contraseña debe tener más de 6 caracteres',
        SUBMIT_BUTTON_TEXT: 'Iniciar sesión',
        DONT_HAVE_ACCOUNT: "¿Aún no tienes una cuenta?",
        CREATE_NEW_ONE: "Registrarse",
        FORGOT_PASSWORD_BUTTON_TEXT: '¿Olvidó su contraseña?',
        ERRORS: {
          NOT_EXIST: "El correo electrónico no está registrado",
          WRONG_PASSWORD: 'Contraseña incorrecta insertada',
          TOO_MANY_ATEMP: 'Su cuenta fue deshabilitada debido a demasiados intentos fallidos de inicio de sesión, por favor, contacte con soporte.',
          TIME_LIMIT: 'Tiempo límite excedido.',
          NOT_ACTIVE: "Su cuenta no está en estado 'Activo'. Por favor, contacte con soporte.",
          PERMANENT_EXCLUDE: 'Su cuenta ha sido excluida permanentemente de este sitio.',
          SUSPENDED: 'Se ha suspendido a sí mismo de jugar.',
          TEMPORARY_SUSPENDED: 'Su cuenta ha sido temporalmente suspendida. Si esto no se resuelve en 24 horas, por favor, contacte con soporte.',
          ERROR_OCCURED: 'Se ha producido un error al iniciar sesión. Por favor, inténtelo de nuevo.',
          SUCCESS_LOGIN: 'Ha iniciado sesión con éxito',
          INACTIVITY: 'Su última sesión expiró debido a la inactividad'
        },
        USING_SOCIAL: 'Login using'
      },
      SIGNUP: {
        TITLE: 'Registrarse en {{sitename}}',
        EMAIL: 'Correo electrónico',
        EMAIL_EMPTY_ERROR: "Por favor, ingrese un correo electrónico",
        EMAIL_INVALID_ERROR: 'Por favor, ingrese un correo electrónico válido',
        EMAIL_ALREADY_USED_ERROR: 'Esa dirección de correo electrónico ya está en uso. Si está tratando de completar su registro, inicie sesión para continuar desde donde lo dejó.',
        PHONE_NUMBER: "Número de teléfono",
        PHONE_EMPTY_ERROR: 'Por favor, ingrese su número de teléfono',
        PHONE_INVALID_ERROR: 'Por favor, ingrese un número de teléfono válido',
        PHONE_ALREADY_USED_ERROR: 'That phone number is already in use. If you are trying to complete your registration, please sign in to continue where you left off.',
        VERIFICATION_CODE: 'Código de verificación',
        VERIFY_BUTTON_TEXT: 'Verificar',
        GET_CODE_TXT: 'Obtener Código',
        CODE_SENT: 'Code Sent',
        RESEND: 'Resend',
        PASSWORD: 'Contraseña',
        PASSWORD_EMPTY_ERROR: 'Se requiere contraseña',
        PASSWORD_LENGTH_ERROR: 'La contraseña debe tener más de 6 caracteres',
        PASSOWRD_PATTERN_ERROR: 'La contraseña debe contener al menos un número, una letra minúscula, una letra mayúscula y un carácter especial no alfabético: $ # ! @ - _',
        REFERRAL_CODE: 'Código de Referencia/Promoción (Opcional)',
        REFERAL_CODE_ERROR: 'El código de promoción no es válido',
        TERMS_DESC: 'Al registrarse, usted acepta los',
        TERMS_BUTTON_TEXT: 'Términos y Servicios',
        SUBMIT_BUTTON_TEXT: 'Empezar',
        ALREADY_HAVE_ACCOUNT: "¿Ya tienes una cuenta?",
        SIGNIN_BUTTON_TEXT: 'Iniciar sesión',
        ERRORS: {
          ALREADY_EXIST: 'El correo electrónico ya existe',
          NOT_CREATED: 'No se puede crear la cuenta, por favor, contacte con el servicio de atención al cliente.'
        },
        VERIFY_CODE_REQUIRED: 'Se requiere el código de verificación',
        VERIFICATION_CODE_LENGTH_ERROR: "El código de verificación debe tener 6 números",
        VERIFICATION_WRONG_CODE: "Código de verificación incorrecto",
        SENT_VERIFY_EMAIL: "Hemos enviado el correo electrónico de verificación",
        SENT_VERIFY_SMS: "We've sent the sms code for verification",
        USING_SOCIAL: 'Register using',
        CAPTCHA: "CAPTCHA",
        CAPTCHA_REQUIRED: "CAPTCHA is required",
        CAPTCHA_PATTERN_ERROR: "Please enter valid CAPTCHA",
        CAPTCHA_WRONG: "Invalid CAPTCHA, please try again"
      },
      FORGOT_PASSWORD: {
        TITLE: "¿Olvidó su contraseña?",
        DESCRIPTION: '¿Olvidó su contraseña?',
        EMAIL: 'Correo electrónico',
        EMAIL_EMPTY_ERROR: "Por favor, ingrese un correo electrónico",
        EMAIL_INVALID_ERROR: 'Por favor, ingrese un correo electrónico válido',
        PHONE_NUMBER: "Número de teléfono",
        PHONE_EMPTY_ERROR: 'Por favor, ingrese su número de teléfono',
        PHONE_INVALID_ERROR: 'Por favor, ingrese un número de teléfono válido',
        VERIFICATION_CODE: 'Código de verificación',
        VERIFY_BUTTON_TEXT: 'Verificar',
        SUBMIT_BUTTON_TEXT: 'Enviar',
        SUCCESS_MSG: 'Se ha enviado el enlace de restablecimiento de contraseña con éxito',
        VERIFICATION_CODE_LENGTH_ERROR: "Verification code must be 8 characters",
        SUCCESS_RESET_MSG: 'Your password has been reset successfully. Please login with new password again'
      },
      LOGOUT: {
        MULTI_DEVICE: "Ha cerrado sesión debido al inicio de sesión en múltiples dispositivos",
        INACTIVITY: 'Ha cerrado sesión debido a la inactividad',
        DEFAULT: 'Ha cerrado sesión'
      },
      OR: 'O',
      GOOGLE_BUTTON_TEXT: "Google"
    },
    DEPOSIT: {
      CURRENCY_TITLE: 'Moneda de Depósito',
      CHOOSE_PAYMENT_TITLE: 'Elija un Método de Pago',
      AMOUNT: 'Cantidad',
      INSTANT: 'Instantáneo',
      MIN: 'Mínimo',
      MAX: 'Máximo',
      SUBMIT_BUTTON_TEXT: 'Depositar',
      NEED_HELP: '¿Necesitas ayuda?',
      CONTACT_SUPPORT: 'Contacta a nuestro soporte en vivo',
      CRYPTO_DEPOSIT_TITLE: 'Depósito',
      CRYPTO_DEPOSIT_DESCRIPTION: 'Esta es su dirección privada de depósito. Cualquier depósito que realice aparecerá en su saldo después de la confirmación de la cadena de bloques. Le notificaremos por correo electrónico una vez que el depósito llegue.',
      MINIMUM_DEPOSIT_AMOUNT: 'La cantidad mínima de depósito es de {{amount}} {{currency}}',
      SEND_MONEY_TO: 'Envíe cualquier cantidad de {{currency}} (más tarifa de minero) a:',
      NO_PAYMENT_METHOD: 'No hay método de pago disponible',
      ADDRESS_COPIED: "Copy and Deposit",
      CLUB_PAY_TYPE: "Pay Type"
    },
    WITHDRAW: {
      CLUB_PAY_TYPE: "Pay Type",
      CURRENCY_TITLE: 'Moneda de Retiro',
      CHOOSE_PAYMENT_TITLE: 'Elija un Método de Pago',
      INFO_TITLE: 'Información de Retiro',
      BANK_CODE_TITLE: 'Código del Emisor',
      BANK_NAME_TITLE: 'Nombre del Beneficiario',
      ACCOUNT_TYPE: "Account Type",
      BANK_ACCOUNT_TITLE: 'Cuenta del Beneficiario',
      AMOUNT: 'Cantidad',
      INSTANT: 'Instantáneo',
      MIN: 'Mínimo',
      MAX: 'Máximo',
      SUBMIT_BUTTON_TEXT: 'Retirar',
      NEED_HELP: '¿Necesitas ayuda?',
      CONTACT_SUPPORT: 'Contacta a nuestro soporte en vivo',
      EXIST_ACCOUNT_TITLE: 'Cuentas Existentes',
      ADDRESS: 'Dirección',
      WITHDRAW_FEE_ESTIMATED: 'La tarifa de retiro se estima en {{amount}} {{currency}}',
      MINIMUM_WITHDRAW_AMOUNT: 'La cantidad mínima de retiro es de {{amount}} {{currency}}',
      WITHDRAW_DEDUCT_DESC: 'El retiro deducirá una tarifa de manejo del 10%',
      COMPLETE_EMAIL_VERIFY: 'Por razones de seguridad, complete la verificación de correo electrónico antes del retiro',
      VERIFY_EMAIL_BUTTON: 'Verificar dirección de correo electrónico',
      COMPLETE_PHONE_VERIFY: 'For safety reasons, complete phone verification before withdrawal',
      VERIFY_PHONE_BUTTON: 'Verify Phone Address',
      COMPLETE_EMAIL_PHONE_VERIFY: 'For safety reasons, complete email and phone verification before withdrawal',
      VERIFY_EMAIL_PHONE_BUTTON: 'Verify Now',
      COMPLETE_PROFILE: 'Por razones de seguridad, complete la información de su perfil antes del retiro',
      ACCOUNT_BUTTON: 'Cuenta',
      WITHDRAW_SUCCESS: 'El retiro ha sido enviado, por favor, mantenga un ojo en su cuenta de retiro, gracias',
      HOME_BUTTON: 'Inicio',
      RESTRICTED_BALANCE: 'Tiene un saldo restringido de {{currency}} {{amount}}.',
      RESTRICTED_BALANCE_VIEW: 'Ver',
      NO_PAYMENT_METHOD: 'No hay método de pago disponible',
      IFSC_TITLE: 'IFSC Number',
      INVALID_AMOUNT_ERROR: {
        EMPTY_AMOUNT: 'Please input amount',
        LESS_ZERO: 'Amount cannot be less than zero',
        LESS_MIN: 'Amount is less than minimum value',
        GREATER_MAX: 'Exceed max value',
        GREATER_BALANCE: 'Exceed balance',
        GREATER_AVAILABLE: 'Insufficient Balance'
      },
      ADDRESS_REQUIRED: "Address is required",
      ADDRESS_PATTERM_ERROR: "Address is not correct"
    },
    GAME_GALLEY: {
      NO_GAME_DATA: 'No hay juegos en su lista. Por favor, haga clic en el botón de inicio y disfrute de uno de los muchos juegos disponibles.',
      DISPLAY_COUNT_GAMES: 'Mostrando {{showCount}} de {{totalCount}} juegos'
    },
    GAME: {
      LOADING: 'Cargando',
      PLAY_BUTTON_WITHOUT_LOGIN: 'Iniciar sesión',
      PLAY_BUTTON_WITH_LOGIN: 'Jugar',
      DEMO_PLAY_BUTTON: "Demo",
      RTP: 'RTP',
      RECENT_BIGGEST_WIN: 'La ganancia más grande reciente',
      MY_GAMES_TITLE: 'Mis Juegos'
    },
    ACTIVE_ACCOUNT: {
      TITLE: 'Verificar Correo Electrónico',
      EMAIL_VERIFY_DESCRIPTION: 'Por favor, inicie sesión en su correo electrónico, haga clic en el enlace en el correo electrónico para completar la verificación',
      GET_CODE_TEXT: 'Send Code',
      RESEND_BUTTON_TEXT: 'Reenviar',
      SUBMIT_BUTTON_TEXT: 'Submit',
      NOT_RECEIVE_TXT: "Didn't receive the code?",
      CODE: 'Code',
      CODE_INPUT_PLACEHOLDER: 'Fill in the code here'
    },
    EMAIL_VERIFY: {
      DISPLAY_INFO: 'Verificando ahora. Por favor, espere un momento...',
      ERROR_TITLE: 'Lo siento, hubo un problema.',
      VERIFY_ERROR: 'Código de correo electrónico no encontrado',
      SUCCESS_TITLE: '¡Éxito!',
      VERIFY_SUCCESS: 'Su correo electrónico se verificó exitosamente'
    },
    BONUSES: {
      BONUSES_TITLE: 'Promociones',
      BONUS_TITLE: 'Bono',
      NO_BONUSES_DATA: 'No hay promociones disponibles en este momento',
      CHECK_LATER: '¡Vuelve más tarde!',
      MORE_DETAIL_BUTTON_TEXT: 'Aprende Más',
      APPLY_BUTTON_TEXT: 'Aplicar Ahora',
      TAB1: 'Casino',
      TAB2: 'Sport',
      ENDS_AT: 'Ends :',
      EVENT_ENDED: 'Event Ended'
    },
    EMPTY_PAGE: {
      NOT_FOUND: '¡Ups... Página no encontrada.',
      REFRESH_OR_GOHOME: 'Por favor, actualice la página o haga clic a continuación para volver a la página de inicio',
      ACTION_BUTTON_TEXT: 'Inicio'
    },
    FOOTBALL_PAGE: {
      TITLE: 'Deportes',
      DESCRIPTION: '¡Pronto llegará a los deportes! ¡Vuelve más tarde!',
      ACTION_BUTTON_TEXT: 'Inicio'
    },
    LOGOUT_CONFIRM: {
      TITLE: 'Cerrar Sesión',
      DESCRIPTION: "¡No olvides revisar nuestras amplias selecciones de promociones de casino y deportivas antes de irte!",
      BUTTON_TEXT: 'Cerrar sesión'
    },
    ACCOUNT_INFO: {
      UID: 'UID',
      USERNAME_TITLE: 'Nombre de Usuario',
      EMAIL_TITLE: 'Correo Electrónico',
      PHONE_TITLE: 'Número de Teléfono',
      PHONE_PLACEHOLDER: 'Add Phone number',
      PASSWORD_TITLE: 'Contraseña',
      EDIT_BUTTON_TEXT: 'Editar',
      VERIFY_BUTTON_TEXT: 'Verificar',
      ADD_EMAIL: 'Agregar Correo Electrónico',
      COPY_BUTTON_TXT: 'Copy',
      SUMMARY_TEXT: ''
    },
    ADD_EMAIL: {
      TITLE: 'Agregar Correo Electrónico',
      DESCRIPTION: 'Agrega tu Correo Electrónico para la seguridad de la cuenta',
      EMAIL_PLACEHOLDER: 'Correo Electrónico',
      VERIFICATION_CODE: 'Código de Verificación',
      VERIFY_BUTTON_TEXT: 'Verificar',
      SUBMIT_BUTTON_TEXT: 'Guardar'
    },
    CHANGE_PASSWORD: {
      TITLE: 'Cambiar Contraseña',
      CURRENT_PW_PLACEHOLDER: 'Contraseña Actual',
      CURRENT_PW_REQUIRED: 'Por favor, ingrese su contraseña actual',
      CURRENT_PW_WRONG: 'Contraseña actual incorrecta insertada',
      NEW_PW_PLACEHOLDER: 'Nueva Contraseña',
      NEW_PW_REQUIRED: 'Por favor, ingrese una nueva contraseña',
      NEW_PW_LENGTH_ERROR: 'La contraseña debe tener al menos 6 caracteres',
      CONFIRM_PW_PLACEHOLDER: 'Repita la Nueva Contraseña',
      CONFIRM_PW_REQUIRED: 'Por favor, confirme su nueva contraseña',
      NEW_PW_NOT_MATCH: 'Las contraseñas ingresadas no coinciden',
      NEW_PW_NOT_CURRENT: 'La nueva contraseña no puede coincidir con su contraseña actual',
      SUBMIT_BUTTON_TEXT: 'Guardar',
      MESSAGES: {
        SUCCESS: 'Tu contraseña ha sido cambiada',
        WRONG_CURRENT_PASSWORD: 'Has ingresado incorrectamente tu contraseña actual.',
        NEW_NOT_MATCH_CURRENT: 'Tu nueva contraseña no puede coincidir con tu contraseña actual.'
      }
    },
    EDIT_USERNAME: {
      TITLE: 'Editar Nombre de Usuario',
      PLACEHOLDER_TEXT: 'Nombre de Usuario',
      SUBMIT_BUTTON_TEXT: 'Guardar',
      MESSAGES: {
        USERNAME_REQUIRED: 'Por favor, ingrese un nombre de usuario.',
        USERNAME_LENGTH: 'El nombre de usuario debe tener más de 3 caracteres'
      }
    },
    RESET_PASSWORD: {
      TITLE: 'Restablecer Contraseña',
      DESCRIPTION: 'Restablecer Contraseña',
      PASSWORD_PLACEHOLDER: 'Contraseña',
      PW_LENGTH_ERROR: 'La contraseña debe tener al menos 6 caracteres.',
      PW_REQUIRED: 'Por favor, ingrese una nueva contraseña.',
      PW_PATTERN_ERROR: 'La contraseña debe incluir número, mayúscula y símbolo.',
      CONFIRM_PW_PLACEHOLDER: 'Confirmar Contraseña',
      CONFIRM_PW_REQUIRED: 'Por favor, confirme su nueva contraseña.',
      CONFIRM_PW_MISMATCH: 'Las contraseñas no coinciden.',
      NEW_PW_NOT_CURRENT: 'Tu nueva contraseña no puede coincidir con tu contraseña actual.',
      SUBMIT_BUTTON_TEXT: 'Restablecer',
      MESSAGES: {
        TOKEN_INVALID: 'El token proporcionado no es válido',
        TOKEN_EXPIRED: 'El token proporcionado ha caducado',
        SUCCESS: 'Tu contraseña ha sido restablecida. Por favor, inicia sesión utilizando la contraseña',
        FAILD: 'Error al restablecer tu contraseña. Por favor, inténtalo de nuevo'
      }
    },
    UPDATE_PHONE: {
      TITLE: 'Editar Número de Teléfono',
      DESCRIPTION: 'Agrega tu número de teléfono para la seguridad de la cuenta',
      PLACEHOLDER: 'solo dígitos',
      PHONE_REQUIRED: 'Por favor, ingrese su número de teléfono',
      PHONE_INVALID: 'Por favor, ingrese un número de teléfono válido',
      VERIFY_CODE: 'Código de Verificación',
      VERIFY_BUTTON_TEXT: 'Verificar',
      SUBMIT_BUTTON_TEXT: 'Enviar',
      MESSAGES: {
        FAILD: 'Hubo un problema al intentar actualizar el número de teléfono. Por favor, contacte con el servicio de atención al cliente.'
      }
    },
    ACTIVE_BONUSES: {
      TITLE: 'Bonos Activos',
      NO_DATA: "No tienes bonos activos en este momento.",
      SHOW_RESULT: 'Haz clic en un bono para ver todos los detalles',
      BONUS_ACCOUNT: 'Cuenta de Bonos',
      DESC1: "A veces puedes obtener un 'Bono' como una recompensa especial por una acción, como hacer un depósito.",
      DESC2: "El 'Monto Total Restringido' (tu depósito + la cantidad del bono) está restringido a apuestas en juegos solamente. Una vez que se cumplan los requisitos de apuesta, el Monto Total Restringido se libera y se convierte en parte de tu saldo de dinero real.",
      TABLE_HEADER: {
        COLUMN1: 'BONO TOTAL',
        COLUMN2: 'BONO RESTANTE',
        COLUMN3: 'REFERENCIA',
        COLUMN4: 'FECHA DE INICIO',
        COLUMN5: 'FECHA DE VENCIMIENTO',
        COLUMN6: 'ESTADO',
        COLUMN7: 'MONTO RESTRINGIDO'
      }
    },
    PAST_BONUSES: {
      TITLE: 'Bonos Pasados',
      DESCRIPTION: 'A continuación puedes ver todos los bonos anteriores que has recibido. Por favor, selecciona un rango de fechas:',
      FROM_DATE_LABEL: 'Desde',
      INPUT_DATE_PLACEHOLDER: 'Selecciona una Fecha',
      TO_DATE_LABEL: 'Hasta',
      SUBMIT_BUTTON_TEXT: 'ENVIAR',
      NO_RESULT: 'No se encontraron resultados.',
      SHOW_RESULT: 'Selecciona cualquier bono a continuación para ver más detalles:',
      TABLE_HEADER: {
        COLUMN1: 'REFERENCIA',
        COLUMN2: 'BONO TOTAL',
        COLUMN3: 'FECHA DE INICIO',
        COLUMN4: 'FECHA DE VENCIMIENTO',
        COLUMN5: 'ESTADO'
      }
    },
    BONUS_DETAIL: {
      TITLE: 'Detalles del Bono',
      TERMS_SERVICE: 'Términos y Condiciones',
      BONUS: 'Bono',
      START_DATE: 'Fecha de Inicio',
      EXPIRED_DATE: 'Fecha de Vencimiento',
      PROGRESS: 'Progreso',
      CASINO: 'Casino',
      SPORTS: 'Deportes',
      LIVE: 'Live',
      VALID_GAMES: 'Juegos Válidos',
      ROLLOVER: 'Requisitos de Apuesta',
      WAGERS_REMAIN: 'Apuestas Restantes',
      RESTRICT_AMOUNT: 'MONTO RESTRINGIDO',
      WINNINGS: 'Ganancias',
      DEPOSIT: 'Depósito',
      TOTAL_RESTRICT_AMOUNT: 'Monto Total Restringido',
      FORFEIT_BUTTON_TEXT: 'Renunciar a Mi Bono',
      FORFEIT_DESC1: 'Si renuncias a este bono, eliminaremos:',
      FORFEIT_DESC2: '{{currency}} {{winning}} de ganancias restringidas.',
      FORFEIT_DESC3: '{{currency}} {{bonus}} de dinero de bono restringido.',
      FORFEIT_DESC4: 'Esto te dejará con {{currency}} {{deposit}} de tu depósito.',
      FORFEIT_DESC5: '¿Estás seguro de que quieres renunciar a este bono?',
      CANCEL_BUTTON_TEXT: 'Cancelar',
      CONFIRM_BUTTON_TEXT: 'Sí, Renunciar al Bono',
      ERRORS: {
        CLOSE_OPENED_GAMES: 'Por favor, cierra todos los juegos abiertos para renunciar al bono.',
        RESOLVE_SPORTS_GAME: 'Un evento deportivo reservó fondos de este bono; por favor, espera a que se resuelva primero.',
        RESOLVE_SAVED_GAME: 'Un juego guardado reservó fondos de este bono; por favor, resuélvelo primero.'
      },
      APPLY_BUTTON_TXT: {
        DEFAULT: 'Deposit Now',
        LABEL1: 'Apply Now',
        LABEL2: 'Deposit Now',
        LABEL3: 'Play Now',
        LABEL4: 'Redeem Now',
        LABEL5: 'More Detail'
      }
    },
    REDEEM_BONUS: {
      TITLE: 'Promo Code',
      DESCRIPTION: "Join {{name}} community and receive a promo code!",
      INPUT_LABEL: 'Código de Bono:',
      INPUT_PLACEHOLDER: 'Promo Code',
      SUBMIT_BUTTON_TEXT: 'Canjear',
      FAILD: 'El código de bono es incorrecto o ha caducado; por favor, prueba otro.',
      NOVALID: "Promo code not available",
      SUCCESS: "Promo Code has been used successfully",
      SUCCESS_BUTTON_TXT: "Finish"
    },
    REFER_FRIEND: {
      TITLE: 'Referir a un Amigo',
      SUBTITLE1: 'Invita Amigos, Gana Dinero',
      DESCRIPTION1: 'Recibe saldo en efectivo por cada amigo que refieras a {{website}}',
      TOTAL_REFERRED: 'Total de Usuarios a los que Has Referido',
      SUBTITLE2: 'Comparte tu código de invitación',
      DESCRIPTION2: 'Tu Código de Referencia'
    },
    SPORTS_PAGE: {
      LOADING: 'Cargando'
    },
    GAME_HISTORY: {
      TITLE: "Historial de Juegos",
      TABLE: {
        COLUMN1: 'Juego',
        COLUMN2: 'Resultado de Apuesta',
        COLUMN3: 'Hora',
        COLUMN4: 'Monto de Apuesta',
        COLUMN5: 'Multiplicador',
        COLUMN6: 'Pago'
      },
      NO_HISTORY: 'No hay Historial de Juegos',
      SHOW_DATA_DESC: 'Solo están disponibles los últimos 50 registros de juegos',
      TOTAL: 'Total',
      WIN: 'Ganancia',
      LOSS: 'Pérdida',
      DRAW: 'Empate',
      UNSETTLED: 'UNSETTLED',
      MOBILE: {
        CASH: 'SLOTS',
        BET_AMOUNT: 'Monto de Apuesta',
        MULTIPLIER: 'Multiplicador',
        PAYOUT: 'Pago'
      },
      SHAREING: {
        SUCCESS: {
          TITLE1: 'More than just wins,',
          TITLE2: 'earn extra tokens and dividends here.',
          BET_AMOUNT: 'by betting',
          MULTIPLIER: "Multiplier {{value}}x",
          PLACED_USER: 'Placed By: {{username}}',
          REFERRAL_CODE: 'Referral Code',
          CTA_SENTENCE2: 'Share NOW & win lottery rewards',
          DOWNLOAD_BTN: 'Download',
          SHARE_BTN: 'Share'
        },
        FAILD: {
          TITLE: 'Fail To Share',
          ALREADY_SHARED_GAME_LOG: 'This win has been shared by other people.',
          ABOVE_ONE_MULTIPLIER: 'Only transactions that are above 1x Multiplier can be shared.',
          EXCEEDED_DAILY: "You have reached your daily limit."
        }
      }
    },
    LEADERBOARD: {
      TITLE: 'Tabla de Clasificación',
      TABLE: {
        COLUMN1: "",
        COLUMN2: "",
        COLUMN3: "Jugador",
        COLUMN4: "Total $FUN Token",
        COLUMN5: "Recompensa"
      },
      NO_DATA: 'Sin tabla de clasificación'
    },
    NEW_BANK: {
      TITLE: 'Información Bancaria',
      PAYMENT_TYPE: 'Tipo de Pago',
      SELECT_PAYMENT_TYPE: 'Selecciona un Tipo de Pago',
      BANK_LIST: 'Lista de Bancos',
      SELECT_BANK: 'Selecciona un Banco',
      NAME: 'Nombre Completo',
      ACCOUNT_NUMBER: 'Número de Cuenta',
      ACCOUNT_NUMBER_DESC: 'Solo de 1 a 17 dígitos',
      SUBMIT_BUTTON_TXT: 'ENVIAR',
      ADD_NEW_BUTTON_TXT: 'Agregar Nuevo',
      CLOSE_BUTTON_TXT: 'Cerrar'
    },
    PAYMENTS: {
      DEPOSIT_TXT: 'Depósito',
      WITHDRAW_TXT: 'Retiro'
    },
    PROFILE_INFO: {
      TITLE: 'Información Personal',
      FIRST_NAME: 'Nombre',
      FIRST_NAME_REQURIED_ERROR: 'Por favor, ingresa tu nombre legal.',
      FIRST_NAME_PATTERN_ERROR: "El nombre solo puede contener caracteres alfabéticos, espacios, guiones y puntos. Por favor, ingresa al menos 2 y un máximo de 20 caracteres.",
      LAST_NAME: 'Apellido',
      LAST_NAME_REQUIRED_ERROR: 'Por favor, ingresa tu apellido legal.',
      LAST_NAME_PATTERN_ERROR: "El apellido solo puede contener caracteres alfabéticos, espacios, guiones y puntos. Por favor, ingresa al menos 2 y un máximo de 20 caracteres.",
      BIRTHDAY: {
        TITLE: 'Fecha de Nacimiento',
        YEAR: 'Año',
        MONTH: 'Mes',
        DAY: 'Día',
        ERROR: 'Por favor, ingresa una fecha de nacimiento válida.'
      },
      ADDRESS: {
        STREET: 'Dirección',
        STREET_REQURIED_ERROR: 'Por favor, ingresa tu dirección.',
        PO_BOX_ERROR: 'No se puede usar un apartado postal.',
        COUNTRY: 'País',
        STATE: 'Estado',
        CITY: 'Ciudad',
        CITY_REQURIED_ERROR: 'Por favor, ingresa tu ciudad.',
        CITY_PATTERN_ERROR: 'Por favor, ingresa un nombre de ciudad válido. La ciudad no debe incluir números.',
        ZIPCODE: 'Código Postal',
        ZIPCODE_REQUIRED_ERROR: 'Por favor, ingresa tu código postal.',
        ZIPCODE_PATTERN_ERROR: 'Por favor, ingresa solo letras y números. Sin espacios ni caracteres especiales.',
        ZIPCODE_MINLENGTH_ERROR: 'Zipcode must be 5 charactors at least'
      },
      SUBMIT_BUTTON_TXT: 'ENVIAR',
      CHOOSE_FILE: 'Proof of Identity/Choose file',
      FILE_NOTE: "*Document accepted : Passport, driver’s license or ID card.",
      ALERT: {
        SUCCESS: {
          TITLE: 'Verification',
          MSG: 'Successfully updated'
        }
      }
    },
    PROVIDERS: {
      TITLE: 'Proveedores de Juegos',
      GAMES_LENGTH: '{{count}} Juegos',
      GAMES: 'Juegos',
      CURRENT_DISPLAYING: 'Mostrando {{count}} de {{total}} proveedores'
    },
    TERMS: {
      TITLE: 'Términos y Condiciones',
      PRINT_BUTTON_TXT: 'Imprimir',
      DOWNLOAD_BUTTON_TXT: 'Descargar'
    },
    TRANSACTIONS: {
      TITLE: 'Transacciones',
      DEPOSIT_BUTTON_TXT: 'Depósito',
      WITHDRAW_BUTTON_TXT: 'Retiro',
      BONUS_BUTTON_TXT: 'Bonos',
      NO: 'No',
      SHOW_DATA_DESC: 'Solo están disponibles las últimas 50 transacciones',
      TOTAL: 'Total',
      deposit: 'Depósito',
      withdraw: 'Retiro',
      bonuses: 'Bonos',
      STATE: {
        CONFIRMED: "Confirmed",
        UNCONFIRMED: "Unconfirmed",
        UNSUCCESSFUL: "Unsuccessful"
      },
      DEPOSIT_TABLE: {
        COLUMN1: 'ID de Pedido',
        COLUMN2: 'Hora',
        COLUMN3: 'Estado',
        COLUMN4: 'Moneda',
        COLUMN5: 'Depósito',
        COLUMN6: 'Saldo Antes',
        COLUMN7: 'Saldo Después',
        COLUMN8: 'Amount'
      },
      WITHDRAW_TABLE: {
        COLUMN1: 'ID de Pedido',
        COLUMN2: 'Hora',
        COLUMN3: 'Estado',
        COLUMN4: 'Moneda',
        COLUMN5: 'Retiro',
        COLUMN6: 'Saldo Antes',
        COLUMN7: 'Saldo Después',
        COLUMN8: 'Amount'
      },
      BONUSES_TABLE: {
        COLUMN1: 'Tipo',
        COLUMN2: 'ID de Pedido',
        COLUMN3: 'Hora',
        COLUMN4: 'Monto del Bono',
        COLUMN5: 'Bonus Name'
      }
    },
    USER_MENU: {
      WALLET: 'Billetera',
      TRANSACTIONS: 'Transacciones',
      GAME_HIISTORY: 'Historial de Juegos',
      KYC_INFO: 'KYC',
      VIP: 'Club VIP',
      REDEEM_COUPON: 'Canjear Cupón',
      REFERRAL: 'Referir a un Amigo',
      PROMOTIONS: 'Promociones',
      ACTIVE_BONUS: 'Bonos Activos',
      PAST_BONUS: 'Bonos Anteriores',
      LIVE_SUPPORT: 'Soporte en Vivo',
      ACCOUNT: 'Perfil'
    },
    STEP_COMPONENT: {
      STEP_TXT: 'Paso'
    },
    PLAYER_VIP: {
      MY_DATA: {
        VIP_PROGRESS: "Tu Progreso VIP",
        TOOLTIP: 'Cada $1 apostado te otorga 1XP. Cuanto más juegues, más rápido alcanzarás el rango más alto.',
        RAKEBACK: 'Devolución de Rake',
        RANK_UP_BUTTON_TXT: 'Ascender de Rango',
        TOTAL_CLAIMED: 'Total Reclamado de Devolución de Rake',
        CLAIM_BUTTON_TXT: 'Reclamar',
        REMAIN_UNTIL_NEXT: "{{xp}} XP hasta VIP {{level}}"
      },
      BETSPLUS: {
        LEVEL: "Level",
        REQUIRED_XP: 'Required EXP {{amount}} {{currency}}',
        LEVELUP_DESC: 'Get rewarded every time you fill the progress bar. Leveling up entitles you to bigger and better rewards!',
        WITHDRAWN_AMOUNT: 'Amount that can be withdrawn',
        TOTAL_REWARDS: 'Total Rewards Available',
        EXCHANGE_BUTTON_TXT: 'Exchange',
        CLAIM_BUTTON_TXT: 'Claim',
        REWARD_REDEMPTION: "Reward Redemption",
        TOTAL_CLAIMED: 'Total Rewards Claimed',
        REWARD: "Reward"
      }
    },
    REFERRALS: {
      MY_REFERRALS: {
        TOTAL_COMMISSION_EARNING: 'Ganancias Totales de Comisión por Referidos',
        REFERRED_YOU: "Quién te Refirió",
        AMOUNT_REFERRED: 'Cantidad de usuarios que has referido',
        NUMBER_OF_USERS: "Number of users"
      },
      BETSPLUS: {
        TITLE: 'Millionaire Project',
        DESCR1: 'GET UP TO <span style="color: #FFC83A;">${{value}}</span>',
        DESCR2: 'AND UP TO <span style="color: #FFC83A;">{{percent}}%</span> COMMISSION',
        HOWTOWORK: 'how it works',
        TOOLTIP_DETAIL: 'Step 1: Share referral code to friends and social media. <br> Step 2: Guide them to get their deposit bonus. <br> Step 3: Recommend some great games for them. <br> Step 4: Claim and spend your referral rewards. <br> Step 5: Repeat the steps!',
        INVITE_SECT: {
          TITLE: 'Invite Users',
          SHARES: 'Shares',
          INVITEES: 'Invitees',
          INVITE_LINK: 'Invite link',
          INVITE_QR_CODE: 'Invite QR code',
          QUICK_SHARE: 'Quick share',
          DOWNLOAD_BTN: 'Download'
        },
        DATA_CENTER: {
          TITLE: 'Data Center',
          TOTAL_REVENUE: 'Total revenue',
          INVITATION: 'Invitation',
          WAGER: 'Wager',
          ACHIEVEMENT: 'Achievement',
          VIP: 'VIP',
          MORE_BTN: 'More'
        },
        REWARD_SECT: {
          TITLE: 'Reward Redemption',
          AMOUNT_WITHDRAWN: 'Amount that can be claimed',
          EXCHANGE_BUTTON_TXT: 'Exchange',
          CLAIM_BUTTON_TXT: 'Claim',
          TOTAL_REWARD: 'Total Rewards Available : {{value}}'
        },
        ACTIVITY_RULES: {
          TITLE: 'Activity Rules',
          SEE_MORE: 'See More',
          DATA1: {
            LABEL: 'INVITATION BONUS',
            TEXT: 'Every time you invite a friend whose deposit reaches 20 USD, you will get, 5 USD rewards.'
          },
          DATA2: {
            LABEL: 'BETTING COMMISSION',
            TEXT: 'Bonus Rules: All players at the "Promoter" level receive a percentage of the platform advantage bonus for every bet.'
          },
          DATA3: {
            LABEL: 'ACHIEVEMENT BONUS',
            TEXT: 'If the cumulative number of invitations meets the conditions, you will receive additional achievement rewards. <br> The more people you invite, the higher the reward.'
          },
          DATA4: {
            LABEL: 'VIP LEVEL-UP BONUS',
            TEXT: 'Every user you invite reaches the specified level in the game, and you will get additional VIP level-up bonus. <br> USD is a special currency launched by Platform . You can …'
          }
        },
        AMONG_BEST: 'Among the best',
        LIVE_REWARD: 'Live Rewards',
        QA: {
          TITLE: 'Q&A'
        },
        EXCHANGE: {
          MESSAGE: 'Received successfully',
          CONFIRM_BUTTON_TXT: 'Confirm'
        },
        ACHIEVE_BONUS: {
          TITLE: 'Achievement Bonus',
          DESCRIPTION: 'If the cumulative number of invitations meets the conditions, you will receive additional achievement rewards. The more people you invite, the higher the reward.',
          TABLE: {
            COLUMN1: 'Friend’s Level',
            COLUMN2: 'Several people',
            COLUMN3: 'Rewards'
          }
        },
        BETTING_COMMISSION: {
          TITLE: 'Betting Commission',
          SHORT_DESC:'Bonus Rules: All players at the "Promoter" level receive a percentage of the platform advantage bonus for every bet.',
          LONG_DESC: `The proportions of the 3 levels are as follows <br>
          Level 1: Get 35% Of 1% wager <br>
          Level 2: Get 12.25% Of 1% wager <br>
          Level 3: Get 4.29% Of 1% wager <br>
          This will be your long-term income, and every time a player you refer places a bet, you will receive a different percentage of the commission regardless of whether he wins or loses.So what you have to do is to improve your game skills, think about how to win the game and share it with others, and help more people win with your method.We want all players to have fun in our platform, whether it's the fun of winning bets or the game itself!`
        },
        USER_LIST: {
          TITLE: "Users List",
          TABS: {
            TAB1: 'Sub-user',
            TAB2: 'Subordinate users'
          },
          SEARCH_PLACEHOLDER: 'Search User UID',
          SELECTS: {
            ITEM1: 'All',
            ITEM2: 'Today',
            ITEM3: 'Past 7 days',
            ITEM4: 'Past 30 days',
            ITEM5: 'Past 60 days'
          },
          TABLE: {
            COLUMN1: 'Email / Phone',
            COLUMN2: 'Registered At',
            COLUMN3: 'Bonus',
            COLUMN4: 'BETTING',
            COLUMN5: 'INVITATION',
          },
          TOTAL: 'Total',
          NO_DATA: "No user data"
        },
        VIP_LEVEL_BONUS: {
          TITLE: 'VIP Level-Up Bonus',
          DESC: 'Every user you invite reaches the specified level in the game, and you will get additional VIP level-up bonus. USD is a special currency launched by Platform . You can Swap USD into other currencies at any time.',
          TABLE: {
            COLUMN1: 'Friend’s Level',
            COLUMN2: 'Total Wager',
            COLUMN3: 'Rewards'
          }
        }
      }
    },
    KYC: {
      TITLE: "KYC",
      DESC: "Due to the regulatory requirement from National Lottery Regulatory Commission (NLRC) and Curaçao operating license,we require you to verify your Email Address and Phone number to prevent fraud, cheating and suspicious activity. If you are experiencing issue, you may also seek assistance from Live Chat.",
      BUTTON_TXT: "Account"
    },
    GOOGLE_LOGIN: {
      EERROR_MSG: 'No se pudo iniciar sesión con la información de la cuenta. Por favor, inténtalo de nuevo con otra cuenta.'
    },
    SUCCESS_MSG: {
      TITLE: '¡Éxito!'
    },
    ERROR_MSG: {
      TITLE: 'Lo siento, hubo un problema'
    },
    COMMON: {
      COMING_SOON: "Próximamente",
      LOAD_MORE: "Cargar Más",
      LOGOUT: 'Cerrar Sesión',
      DEPOSIT: 'Depósito',
      WITHDRAW: 'Retirar',
      INVALID_AMOUNT: 'Cantidad no válida',
      HOME_BUTTON_TXT: 'Inicio',
      BACK_BUTTON_TXT: 'Atrás',
      COPIED: 'Copied',
      OK: "OK",
      CANCEL: "Cancel",
      SEARCH: "Search"
    },
    LUCKSPIN: {
      TITLE: "Lucky Spin",
      TRY_AGAIN: "Try again",
      BUTTON_TEXT: "Spin",
      WIN_SPIN_TEXT: "LUCKY WIN SPIN NOW",
      BOTTOM_TEXT: "DAILY FREE SPINS",
      AVAILABLE_SPINS: "Available spins"
    },
    ERRORS: {
      400: 'Illegal status',
      401: 'Unauthorized',
      402: 'Payment required',
      404: 'Not found',
      500: 'Internal server error',
      503: '[Ezugi] Invalid game identifier',
      504: '[Ezugi] Invalid operation',
      506: '[Ezugi] Invalid currency',
      508: '[Ezugi] Invalid transaction identifier',
      511: '[Leander] Invalid token',
      1002: '[Evolution] Invalid session id',
      1007: '[NYX] RC unknown currency',
      6001: '[Vanguard] Game session invalid',
      6003: '[Vanguard] Authentication failed',
      40011: '[RGS] Invalid game type',
      40012: '[RGS] Invalid game ID',
      10000: 'Accounts limit exceeded',
      10001: 'Addacct failed',
      10002: 'Age verification failed',
      10004: 'Already has account',
      10005: 'Already registered',
      10006: 'Already sent',
      10010: 'Bad argument',
      10017: "Bonus code not found",
      10018: 'Bonus does not exist',
      10019: 'Bonus invalid',
      10021: 'Can not approve payout request',
      10022: 'Can not cancel payout request',
      10024: 'Can not process payout',
      10025: 'Can not process payout request',
      10034: 'Currency code not found',
      10037: 'Customer already in',
      10038: 'Customer does not exist', //
      10042: 'Customer in blacklist',
      10045: 'Customer not found', //
      10046: 'Customer not registered', //
      10047: 'Customer self excluded',
      10049: 'Customer suspended',
      10052: 'Customer verification not found',
      10055: 'Cust update failed',
      10059: 'Disabled acct',
      10064: 'Email already exists',
      10066: 'Email code not found',
      10069: 'Email in use',
      10071: 'Email not found',
      10143: 'File invalid format',
      10145: 'Game already started',
      10146: 'Game code is not valid',
      10150: 'Game not found',
      10154: 'Get balance',
      10155: 'Get balance error',
      10173: 'Invalid amount max',
      10174: 'Invalid amount min',
      10177: 'Invalid bonus id',
      10178: 'Invalid bonus type',
      10186: 'Invalid customer',
      10190: 'Invalid game id',
      10200: 'Invalid session',
      10207: 'Invalid token',
      10221: 'Login failure',
      10222: 'Login max logins reached',
      10223: 'Login wrong password',
      10224: 'Login wrong username',
      10225: 'Logout failed',
      10246: 'Password not match',
      10250: 'Phone already exists',
      10307: 'Username already exists',
      10308: 'Validate birthdate failed',
      10325: 'The email is not registered yet',
      10326: 'The email has already been used as google sso account',
      10327: 'Wrong referral code',
      10328: 'Customer has not disbursed referral commission',
      10329: 'cannot level up',
      10330: 'Failed to claim VIP cashback',
      10331: 'Invalid customer VIP level',
      10332: 'Failed to claim referral commission',
      10333: 'Failed to get referral achievement information',
      10334: 'Invalid phone number',
      10341: "Not enough value to claim bonus",
      11507: 'Country not found',
      11511: 'Invalid casino bonus',
      11512: 'Please close all opened games in order to forfeit the bonus.',
      11513: 'A saved game reserved funds from this bonus - please resolve it first.',
      11514: 'A sport event reserved funds from this bonus - please wait for its resolution first.',
      11515: 'Inactive casino bonus',
      11516: 'Could not forfeit Any Deposit Rollover casino bonus.',
      11517: "Insufficient player's daily credit",
      11518: 'Failed to acquire prize',
      11519: "Insufficient prize's daily count",
      11520: "Please wait for the cooldown period before sending another email.",
      11521: "Please wait for the cooldown period before sending another sms.",
      11522: "Reach maximum times for sending email for verification in a day.",
      11523: "Reach maximum times for sending sms for verification in a day.",
      11526: "ClubPay Exception",
      100000: 'VALIDATE_SIGNUP_CODE_FAILED',
      100002: 'SEND_SMS_VALIDATION_FAILED'
    },
    BONUS_TYPES: {
      "Deposit": "Deposit",
      "Withdrawal": "Withdrawal",
      "Table Buyin": "Table Buyin",
      "Table Rebuy": "Table Rebuy",
      "Table Leave": "Table Leave",
      "Place Bet": "Place Bet",
      "Tournament Buyin": "Tournament Buyin",
      "Tournament Fee": "Tournament Fee",
      "Tournament Buyin Refund": "Tournament Buyin Refund",
      "Tournament Fee Refund": "Tournament Fee Refund",
      "Manual Adjustment": "Manual Adjustment",
      "Recovery Refund": "Recovery Refund",
      "Transfer In": "Transfer In",
      "Transfer Out": "Transfer Out",
      "Round Win": "Round Win",
      "Bonus Win": "Bonus Win",
      "Deposit Bonus": "Deposit Bonus",
      "Account Creation Bonus": "Account Creation Bonus",
      "Referral Bonus": "Referral Bonus",
      "Bonus Conversion": "Bonus Conversion",
      "Login Grant": "Login Grant",
      "Initial Grant": "Initial Grant",
      "Virtual Goods Purchase": "Virtual Goods Purchase",
      "Loyalty": "Loyalty",
      "Winning in random scratcher": "Winning in random scratcher",
      "Mystery Gift Win": "Mystery Gift Win",
      "FB Credit Order": "FB Credit Order",
      "Leader Board Points": "Leader Board Points",
      "Tournament Award": "Tournament Award",
      "Returning Player Bonus": "Returning Player Bonus",
      "Cancelled round refund": "Cancelled round refund",
      "Sit & Go Award": "Sit & Go Award",
      "Sit & Go Buyin": "Sit & Go Buyin",
      "Sit & Go Fee": "Sit & Go Fee",
      "Sit & Go Buyin Refund": "Sit & Go Buyin Refund",
      "Sit & Go Fee Refund": "Sit & Go Fee Refund",
      "Fast Poker buyin": "Fast Poker buyin",
      "Fast Poker leave": "Fast Poker leave",
      "Fast Poker rebuy": "Fast Poker rebuy",
      "Tournament Rebuy": "Tournament Rebuy",
      "Tournament AddOn": "Tournament AddOn",
      "Sit & Go Rebuy": "Sit & Go Rebuy",
      "Sit & Go AddOn": "Sit & Go AddOn",
      "Bonus Deposit": "Bonus Deposit",
      "Bonus Released": "Bonus Released",
      "Bonus Deposit Released": "Bonus Deposit Released",
      "Bonus Forfeit": "Bonus Forfeit",
      "Bonus Expired": "Bonus Expired",
      "Bonus Winning Released": "Bonus Winning Released",
      "Void Deposit": "Void Deposit",
      "Bonus Deposit Forfeit": "Bonus Deposit Forfeit",
      "Bonus Winnings Forfeit": "Bonus Winnings Forfeit",
      "Bonus Deposit Expired": "Bonus Deposit Expired",
      "Bonus Winnings Expired": "Bonus Winnings Expired",
      "SnG Buyin Recovery Refund": "SnG Buyin Recovery Refund",
      "SnG Fee Recovery Refund": "SnG Fee Recovery Refund",
      "Tournament Buyin Recovery Refund": "Tournament Buyin Recovery Refund",
      "Tournament Fee Recovery Refund": "Tournament Fee Recovery Refund",
      "Clearing released bonus": "Clearing released bonus",
      "Clearing released bonus deposit": "Clearing released bonus deposit",
      "Clearing released bonus winnings": "Clearing released bonus winnings",
      "Void Withdrawal": "Void Withdrawal",
      "Bonus Deposit Forfeit Cleared": "Bonus Deposit Forfeit Cleared",
      "Bonus Deposit Expired Cleared": "Bonus Deposit Expired Cleared",
      "Bonus Cash Grant": "Bonus Cash Grant",
      "Place a Purchase": "Place a Purchase",
      "Cancel a Purchase": "Cancel a Purchase",
      "Tournament Rebuy Refund": "Tournament Rebuy Refund",
      "Tournament Rebuy Recovery Refund": "Tournament Rebuy Recovery Refund",
      "Tournament AddOn Refund": "Tournament AddOn Refund",
      "Tournament AddOn Recovery Refund": "Tournament AddOn Recovery Refund",
      "SnG Rebuy Refund": "SnG Rebuy Refund",
      "SnG Rebuy Recovery Refund": "SnG Rebuy Recovery Refund",
      "SnG AddOn Refund": "SnG AddOn Refund",
      "SnG AddOn Recovery Refund": "SnG AddOn Recovery Refund",
      "Bounty Tournament Award": "Bounty Tournament Award",
      "Jackpot winning": "Jackpot winning",
      "Collectible game award": "Collectible game award",
      "Claim reward": "Claim reward",
      "Cancel reward": "Cancel reward",
      "Coupon redeemed": "Coupon redeemed",
      "Tournament Bounty Buyin": "Tournament Bounty Buyin",
      "Tournament Bounty Buyin Refund": "Tournament Bounty Buyin Refund",
      "Tournament Bounty Buyin Recovery Refund": "Tournament Bounty Buyin Recovery Refund",
      "SnG Bounty Buyin": "SnG Bounty Buyin",
      "SnG Bounty Buyin Refund": "SnG Bounty Buyin Refund",
      "SnG Bounty Buyin Recovery Refund": "SnG Bounty Buyin Recovery Refund",
      "Reward Claimed Refund": "Reward Claimed Refund",
      "Void reward": "Void reward",
      "Tournament Prize Pool Distribution On Recovery": "Tournament Prize Pool Distribution On Recovery",
      "SnG Prize Pool Distribution On Recovery": "SnG Prize Pool Distribution On Recovery",
      "Chips Coupon Claimed": "Chips Coupon Claimed",
      "Milestone Bonus": "Milestone Bonus",
      "External Grant": "External Grant",
      "Void Bet": "Void Bet",
      "Daily TopOff": "Daily TopOff",
      "Bonus Grant": "Bonus Grant",
      "Bonus Used": "Bonus Used",
      "Sport Bet": "Sport Bet",
      "Sport Win": "Sport Win",
      "Sport Win Adjustment": "Sport Win Adjustment",
      "Sport Round Refund": "Sport Round Refund",
      "Sport Round Void": "Sport Round Void",
      "Round Win Bonus": "Round Win Bonus",
      "IBC Sport Unsettle": "IBC Sport Unsettle",
      "IBC Sport Adjust Balance": "IBC Sport Adjust Balance",
      "VIP Level rank-up bonus": "VIP Level rank-up bonus",
      "VIP Level cashback bonus": "VIP Level cashback bonus",
      "Referral commission bonus": "Referral commission bonus",
      "Any Deposit Rollover Bonus": "Any Deposit Rollover Bonus",
      "KYC bonus": "KYC bonus",
      "Daily Lose Rake Back": "Daily Lose Rake Back",
      "Daily lose cashback bonus": "Daily lose cashback bonus",
      "IBC Sport Settled": "IBC Sport Settled"
    }
  }
};
