import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CashierProvider } from '../../providers/cashier-provider';
import { Navigator } from './../../../../system/components/menu/navigator';
import { PopoverFactory } from './../../../../modules/popover/factory';
import { PopoverController } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';

@Component({
  selector: 'payout-lock',
  templateUrl: 'payout-lock.html',
  styleUrls: ['./payout-lock.scss'],
})
export class PayoutLockComponent implements OnInit {

  @Input() withdrawal: any;
  @Output() pendingWithdrawalsEmiter = new EventEmitter<any>();
  private widthdrawalData: any = [];
  public emittedObject: { success: boolean; error: any };

  constructor(
    private cashierProvider: CashierProvider,
    private popoverCtrl: PopoverController,
    private events: MyEvent
  ) {
    this.events.getObservable().subscribe((data) => {
      if (data.event == 'event:confirm-lock') {
        if (
          data.data &&
          data.data.payoutID &&
          this.widthdrawalData &&
          this.widthdrawalData.payoutRequestID
        ) {
          if (data.data.payoutID == this.widthdrawalData.payoutRequestID) {
            this.updatePayoutLock();
          }
        }
      }
      this.events.unsubscribe("event:confirm-lock");
    });
  }

  ngOnInit() {}

  onLockPayout(withdrawal) {
    this.widthdrawalData = withdrawal;
    let data = {
      widthdrawaPayoutRequestID: this.widthdrawalData.payoutRequestID,
    };
    let payout_popover_factory = PopoverFactory('PayoutLockPopover');
    this.popoverCtrl
      .create({
        component: payout_popover_factory,
        componentProps: { data },
        showBackdrop: true,
        backdropDismiss: false,
        cssClass: 'standard-popover marketing-notification',
      })
      .then((payout_popover) => payout_popover.present());
  }

  onReverseClick(withdrawal) {
    Navigator.navigateTo(
      'WithdrawalReverseConfirmPage',
      { multipleModals: false },
      { payoutRequestID: withdrawal.payoutRequestID, amount: withdrawal.amount }
    );
  }

  updatePayoutLock() {
    this.cashierProvider
      .callUpdatePayoutLock(this.widthdrawalData.payoutRequestID)
      .subscribe(
        (response: any) => {
          if (response && !!response.result) {
            this.emittedObject = {
              success: true,
              error: null,
            };
            //format the pending withdrawals on page
            this.pendingWithdrawalsEmiter.emit(this.emittedObject);
          }
        },
        (error) => {
          this.widthdrawalData = [];
          this.emittedObject = {
            success: false,
            error: error,
          };
          this.pendingWithdrawalsEmiter.emit(this.emittedObject);
        }
      );
  }
}
