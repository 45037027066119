import { ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Config } from './system/providers/configuration';
import { CommonService } from './service/common.service';
import { IonRouterOutlet, MenuController, ModalController, NavController, Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { MyEvent } from './service/myevent.services';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { WebservicesProvider } from './system/providers/webservices';
import { Navigator } from './system/components/menu/navigator';
import { AuthenticationProvider } from './modules/authentication/providers/authentication-provider';
import { UserSessionProvider } from './system/providers/user-session';
import { CustomerModel } from './modules/account/models/customer-model';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MainProvider } from './modules/lobby/providers/main.provider';
import { BannerProvider } from './modules/lobby/providers/banner.provider';
import { TouchUserProvider } from './modules/authentication/providers/touch-user-provider';
import { GeoResponse, JurisdictionProvider } from './shared/providers/jurisdiction';
import { GameHubController } from './modules/gameHub/game-hub-controller';
import { AppActions } from './shared/app-actions';
import { KeywordsProvider } from './modules/lobby/providers/keywords.provider';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AppLinks } from './shared/app-links';

import { TranslationService } from './service/translation.service';
import { ConfigurationProvider } from './system/providers/configuration-provider';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { RestrictedAppPage } from './pages/restricted-app/restricted-app.page';
import { CookieService } from 'ngx-cookie-service';

declare global {
  interface Window {
    dataLayer: any;
    LOAD_TAB: any;
    LOGOUT_EVENT: any;
    EXTERNAL_LINK_URL: any;
    APPSFLYER_EVENT: any;
    INACTIVITY_TIME: any;
    BWCIP: any;
  }
}

const LOCALIZATION_LOCAL_STORAGE_KEY = 'casino_lang';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;

  public selectedTheme: String = 'bfy';
  sidebarSize = 'large';
  template = 'bfy';

  private jurisdictionSubscription: Subscription;
  public updateTimeout: any;
  public touchTimeout: any;

  appConfigInfo: any;

  constructor(
    private config: Config,
    private cdr: ChangeDetectorRef,
    public comService: CommonService,
    private platform: Platform,
    private statusbar: StatusBar,
    private splash: SplashScreen,
    private navCtrl: NavController,
    private events: MyEvent,
    private router: Router,
    private location: Location,
    private http: HttpClient,
    private webservices: WebservicesProvider,
    private authenticationProvider: AuthenticationProvider,
    public userSession: UserSessionProvider,
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private menuCtrl: MenuController,
    private mainProvider: MainProvider,
    private bannerProvider: BannerProvider,
    private touchUserProvider: TouchUserProvider,
    private jurisdiction: JurisdictionProvider,
    private gameHubController: GameHubController,
    private appActions: AppActions,
    private keywordsProvider: KeywordsProvider,
    private appVersion: AppVersion,
    private appLinks: AppLinks,
    private translationService: TranslationService,
    private configurationProvider: ConfigurationProvider,
    private cookieService: CookieService,
  ) {

    Navigator.platform = this.platform;
    Navigator.modalCtrl = this.modalCtrl;
    Navigator.navCtrl = this.navCtrl;
    Navigator.menuCtrl = this.menuCtrl;
    Navigator.events = this.events;

    this.router.events.subscribe(events => {
      if (events instanceof NavigationEnd) {
        setTimeout(() => {
          this.generateUrlQueryParam();
        }, 500);
      } else if (events instanceof NavigationError) {
        navCtrl.navigateRoot(`${this.template}/dashboard`);
      }
    });

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('channel')) {
      let channel = searchParams.get('channel');
      if (channel) {
        if (!localStorage.getItem('channel')) {

          localStorage.setItem('channel', channel + ':' + new Date().getTime());

        } else if (localStorage.getItem('channel')) {

          var temp_channel = localStorage.getItem('channel');
          var channelId = temp_channel.split(':')[0];
          var storedTime = Number(temp_channel.split(':')[1]);
          if (channel != channelId) {
            localStorage.setItem('channel', channel + ':' + new Date().getTime());
          }
        }
        this.calCountChannelApi(channel);
      }
    }

    if (searchParams.has('token')) {
      let token = searchParams.get('token');
      console.log('token == ', token);
      if (token) {
        sessionStorage.setItem('reset_token', token);
        this.events.getObservable().subscribe(data => {
          if (data.event == 'dashboard:inited') {
            this.openResetPassword();
          }
        });
      }
    }

    if (searchParams.has('referral')) {
      let referral = searchParams.get('referral');
      if (referral) {
        if (!localStorage.getItem('b_referral_code')) {

          localStorage.setItem('b_referral_code', referral + ':' + new Date().getTime());

        } else if (localStorage.getItem('b_referral_code')) {
          var temp_ref = localStorage.getItem('b_referral_code');
          var storeId = temp_ref.split(':')[0];
          var storedTime = Number(temp_ref.split(':')[1]);
          if (referral != storeId) {
            localStorage.setItem('b_referral_code', referral + ':' + new Date().getTime());
          }
        }
      }
    }

    if (searchParams.has('lang')) {
      let lang = searchParams.get('lang');
      if (lang) {
        this.translationService.setLanguage(lang);
      }
    }

    if (localStorage.getItem('channel')) {
      var temp_channel = localStorage.getItem('channel');
      var storedTime = Number(temp_channel.split(':')[1]);
      if ( new Date().getTime() - storedTime > 30 * 24 * 60 * 60 * 1000) {
        localStorage.removeItem('channel');
      }
    }

    if (localStorage.getItem('b_referral_code')) {
      var temp_ref = localStorage.getItem('b_referral_code');
      var storeTime = Number(temp_ref.split(':')[1]);
      if ( new Date().getTime() - storeTime > 30 * 24 * 60 * 60 * 1000) {
        localStorage.removeItem('b_referral_code');
      }
    }

    this.initialize();
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    if (this.jurisdictionSubscription) {
      this.jurisdictionSubscription.unsubscribe();
      this.jurisdiction.cancelGeolocation();
    }
  }

  initialize() {
    this.platform.ready().then((device) => {
      console.log('platform ready device: ', device);
      this.comService.checkForMobile();

      if (this.statusbar.isVisible) {
        this.statusbar.styleLightContent();
      }
      if (device == 'cordova') {
        this.splash.hide();
      }

      this.config.reload().then(() => {
      });

      this.webservices.getCoutries().then(countries => {
        this.webservices.get('configuration/get-is-allowed').subscribe(resp => {
          console.log('allowed == ', resp);
          if (!resp.payload || !(resp.payload.geo.eligib === "true")) {
            this.modalCtrl.create({
              component: RestrictedAppPage,
              backdropDismiss: false,
              showBackdrop: true,
              canDismiss: false,
              cssClass: 'restricted'
            }).then(modal => {
              modal.present();
              let outlet: any = document.querySelector('ion-router-outlet');
              if (!!outlet) outlet.remove(); // .setAttribute('style', 'display: none;');
            });
          }
          else if (resp.code == 200) {
            var country_code = resp.payload.geo.country;
            var tpp = countries.find(el => el.code == country_code);
            if (tpp && tpp.currency) {
              this.comService.setDefaultCurrency(tpp.currency);
            }
            else {
              this.comService.setDefaultCurrency(this.config.getConfig().DEFAULT_CURRENCY);
            }
          }
          else {
            this.comService.setDefaultCurrency(this.config.getConfig().DEFAULT_CURRENCY);
          }
        }, error => {
          this.comService.setDefaultCurrency(this.config.getConfig().DEFAULT_CURRENCY);
        });
      });

      this.appConfigInfo = this.config.getAppConfigInfo();
      this.selectedTheme = this.appConfigInfo.theme;
      this.template = this.appConfigInfo.template || 'bfy';
      this.comService.currentTemplate = this.template;

      Navigator.comService = this.comService;

      if (this.appConfigInfo.maintenance) {
        this.navCtrl.navigateRoot('maintenance');
        return;
      }
      else if (window.location.pathname == '/') {
        this.navCtrl.navigateRoot(`${this.template}/dashboard`);
      }

      this.http.get('https://api.ipdata.co/?api-key=d1fbf793e03ff6eb43e89909236736c5f7c2d76662b8e08e3672f55e').subscribe((resp: any) => {
        console.log('ip data == ', resp);
        // resp.ip
        // this.comService.setDefaultCurrency(resp?.currency?.code || 'NGN');
      });

      ///////  footer and external script part
      // if (this.config.getConfig()['FEATURE_HOMEPAGE_SCRIPT_SRC']) {
      //   var seal_script = document.createElement('script');
      //   seal_script.setAttribute('src', this.config.getConfig()['FEATURE_HOMEPAGE_SCRIPT_SRC']);
      //   document.body.appendChild(seal_script);
      // }

      this.events.getObservable().subscribe(data => {
        if (data.event == 'event:tabs:load') {
          if (this.comService.checkTabRoute(data.tab.toLowerCase())) {
            this.navCtrl.navigateForward(`${this.template}/${data.tab.toLowerCase()}`);
          } else {
            this.navCtrl.navigateForward(`${this.template}/empty`);
          }
        } else if (data.event == 'footer:inited') {
          console.log('footer inited event occures');
          setTimeout(() => {
            // try {
            //   let livescript = this.config.getConfig()['FEATURE_HOMEPAGE_SCRIPT'];
            //   livescript = (livescript as string).replace('<script type="text/javascript">', '');
            //   livescript = (livescript as string).replace('</script>', '');
            //   var inlineCode = eval(livescript);
            //   var my_awesome_script = document.createElement('script');
            //   my_awesome_script.append(inlineCode);
            //   document.body.appendChild(my_awesome_script);
            // } catch (error) {
            //   console.error(error);
            // }

            this.events.publishSomeData({event: 'footer:formatted:done'});
          }, 1550);
        }
        else if (data.event == "event:gameHub:embedGame") this.embedGame(data.data.instanceId);
        else if (data.event == "event:gameHub:closeGame") this.closeGame();
        else if (data.event == "event:authentication:logout") {
          this.appActions.loadTabOnLogout();
        }
        else if (data.event == 'event:tabs:load:nogeo') {

        }
        else if (data.event == 'event:config:reload') {
          this.keywordsProvider.forceFetch();
          this.bannerProvider.fetchAllBanners();
          this.setUpdateDelay();
        }
        else if (data.event == 'event:lobby:show') {
          this.mainProvider.setShowLobby(true);
        }
        else if (data.event == 'event:lobby:ready') {

        }
        else if (data.event == 'event:IAB:SelfExclusion') {
          this.isIABSelfExclusion();
        }
        else if (data.event == 'custom:back:action') {
          this.customBackAction();
        }
      });
      ///////  footer and external script part

      this.userSession.getCustomer().subscribe(customer => {
        console.log('customer == ', customer);
        if (customer) {
          if (this.userSession.isAuthenticated()) {
            this.initGeoComply();
          }
        }
      });

      this.checkSession();

      // this.translationService.changeLangSubject.pipe(map((pipeLang: any) => pipeLang), debounceTime(700), distinctUntilChanged())
      this.translationService.changeLangSubject.subscribe((lang: any) => {
        var appRoot = document.querySelector('app-root');
        if (!!appRoot) {
          if (appRoot.classList.length) {
            appRoot.classList.forEach(el => {
              appRoot.classList.remove(el);
            });
          }
          appRoot.classList.add(lang);
        }

        this.configurationProvider.getFooterContent();

        this.generateUrlQueryParam();
      });

      this.config.isLoaded().then(() => {
        this.configurationProvider.getFooterContent();
      });

      var monday = new Date().getUTCDay();
      if (monday == 1 && !this.cookieService.check(`reset_on_monday`)) { // Monday
        localStorage.removeItem(`min_balance_status_USDT`);
        localStorage.removeItem(`min_balance_times_USDT`);
        this.cookieService.delete(`min_balance_referral_bonus_USDT`);
        this.cookieService.set(`reset_on_monday`, 'reset', {expires: 1, sameSite: 'Lax', secure: true});
      }
    });
  }

  generateUrlQueryParam() {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('lang')) {
      searchParams.set('lang', this.translationService.getSelectedLanguage());
    }
    // else {
    //   searchParams.append('lang', this.translationService.getSelectedLanguage());
    // }

    if (localStorage.getItem('channel')) {
      var temp_channel = localStorage.getItem('channel');
      var channelId = temp_channel.split(':')[0];
      if (!searchParams.has('channel')) searchParams.append('channel', channelId);
    }

    // if (localStorage.getItem('b_referral_code')) {
    //   var temp = localStorage.getItem('b_referral_code');
    //   var code = temp.split(':')[0];
    //   param += '&referral=' + code;
    // }

    var uurl = this.router.url.substring(0, this.router.url.split('?')[0].length);
    this.location.go(uurl, searchParams.toString());
  }

  changeSize($event) {
    this.sidebarSize = $event;
    this.cdr.detectChanges();
  }

  calCountChannelApi(channelID) {
    this.http.get("https://api.ipify.org/?format=json").subscribe((data: any) => {
      console.log('ip data == ', data);
      let ipAddress = data.ip;
      let os;
      if (this.platform.is('android')) {
        os = 'android';
      } else if (this.platform.is('ios') && this.platform.is('desktop')) {
        os = 'macos';
      } else if (this.platform.is('ios')) {
        os = 'ios';
      } else if (this.platform.is('desktop')) {
        os = 'windows';
      }
      this.webservices.post('marketing-channel/record-visit', {
        marketing_channel_id: channelID,
        ip: ipAddress,
        device_identifier: this.comService.isMobileWeb ? "mobilde" : 'desktop',
        is_mobile_device: this.comService.isMobileApp || this.comService.isMobileWeb,
        os_info: os,
        // create_time : moment(new Date().getTime()).format('yyyy-MM-d HH:mm:ss') //yyyy-MM-dd HH:mm:ss
      }, false);
    }, error => {
      let ipAddress = '127.0.0.1';
      let os;
      if (this.platform.is('android')) {
        os = 'android';
      } else if (this.platform.is('ios') && this.platform.is('desktop')) {
        os = 'macos';
      } else if (this.platform.is('ios')) {
        os = 'ios';
      } else if (this.platform.is('desktop')) {
        os = 'windows';
      }
      this.webservices.post('marketing-channel/record-visit', {
        marketing_channel_id: channelID,
        ip: ipAddress,
        device_identifier: this.comService.isMobileWeb ? "mobilde" : 'desktop',
        is_mobile_device: this.comService.isMobileApp || this.comService.isMobileWeb,
        os_info: os,
        // create_time : moment(new Date().getTime()).format('yyyy-MM-d HH:mm:ss') //yyyy-MM-dd HH:mm:ss
      });
    });
  }

  openResetPassword() {
    let token = sessionStorage.getItem('reset_token');
    Navigator.navigateTo("ResetPasswordPage", {forceModal: true, disableBackdrop: false, cssClass: 'change_pass_modal'}, {token});
  }

  checkSession() {
    this.authenticationProvider.checkSession().then((sessionResult) => {
      // if storage has the customer and the session token gets the observable to make the call,
      if (!!sessionResult.subject) {
        sessionResult.subject.subscribe(
          (response) => {
            if (response.payload) {
              this.userSession.setSessionToken(sessionResult.sessionToken);
              this.userSession
                .refreshCustomer()
                .subscribe((customer: CustomerModel) => {
                  if ("customerId" in customer) this.userSession.setCustomerId(customer.customerId);
                  this.events.publishSomeData({event: "event:appInit:init", data: {
                    triggeredBy: "REFRESH",
                  }});
                  this.events.publishSomeData({event: "event:mainPageRefresh"});
                  // let luckySpinKey = `LuckySpinDaily_${this.userSession.customerId}_${String(new Date().getDate())}`
                  // if ("1" != localStorage.getItem(luckySpinKey)) {
                  //   Navigator.navigateTo('SpinWheelComponent', {forceModal: true, cssClass: 'spin-wheel'});
                  //   localStorage.setItem(luckySpinKey, "1")
                  // }
                });
            } else {
              this.userSession.clear();
            }
          },
          (error) => {
            if (error && "error_code" in error && error.error_code == "hasExceededDepositLimit") {
              this.userSession.clear();
            }
          }
        );
      } else {
        // Navigator.navigateTo('SpinWheelComponent', {forceModal: true, cssClass: 'spin-wheel'});
        this.userSession.clear();
      }
    });
  }


  async customBackAction() {
    this.routerOutlets.forEach(async (outlet: IonRouterOutlet) => {
      if (outlet && outlet.canGoBack()) {
        outlet.pop();
      } else {
        this.navCtrl.navigateRoot(`${this.template}/dashboard`, {animated: true, animationDirection: 'back'});
      }
    });
  }


  initGeoComply(): void {
    var errorDialog: boolean = true;

    this.events.getObservable().subscribe((data) => {
      if (data.event == "event:geoComply:SuppressErrorDialog") errorDialog = false;
      else if (data.event =="event:geoComply:EnableErrorDialog") errorDialog = true;
    });

    if (this.jurisdictionSubscription) {
      this.jurisdictionSubscription.unsubscribe();
    }
    this.jurisdictionSubscription = this.jurisdiction
      .listen()
      .subscribe((geoResp: GeoResponse) => {
        if (!geoResp.is_allowed && errorDialog) {
          Navigator.navigateTo(
            "InvalidGeoComplyPage",
            {},
            {
              geoComplyStatus: geoResp.error,
              funcToRetry: this.jurisdiction.getFuncToRetry(),
            }
          );
        } else {
          this.jurisdiction.resumeNotifications();
        }
      });
  }

  embedGame(instanceId) {
    this.navCtrl.navigateForward(`${this.template}/launch-game`, {queryParams: {instanceId: instanceId}});
  }

  closeGame() {
    this.gameHubController.killAllInstances();
  }

  setUpdateDelay() {
    // if (this.platform.is("android")) {
      // this.checkPlayStoreRestricted();
    // }

    // if (this.platform.is("cordova")) {
    //   if (this.updateTimeout != null) {
    //     clearTimeout(this.updateTimeout);
    //   }

    //   this.updateTimeout = setTimeout(this.checkForUpdate.bind(this), 1000);
    // }
  }

  checkForUpdate() {
    console.log("checkForUpdate: ", this.config.getConfig().CDN_BASEURL);

    let ts = Date.now();
    // this.appConfigInfo = this.config.getAppConfigInfo();
    let folder = this.appConfigInfo != null && this.appConfigInfo.update_folder != null ? this.appConfigInfo.update_folder : "betwaynj";

    this.http.get(this.config.getConfig().CDN_BASEURL + "/assets/downloadable/" + folder + "/update.config?" + ts).subscribe((data: any) => {
      console.log("update.config = ", data);

      this.appVersion.getVersionNumber().then((v) => {
        console.log("app.version = ", v);
        let version, url, message, btnLabel;
        if (data.version_ios != null && data.version_android != null) version = this.platform.is("ios") ? data.version_ios : data.version_android;
        else version = data.version;

        if (data.url_android != null && data.url_ios != null) {
          url = this.platform.is("ios") ? data.url_ios : data.url_android;
        }
        if (data.message_android != null && data.message_ios != null) {
          message = this.platform.is("ios") ? data.message_ios : data.message_android;
        }
        if (
          data.button_label_android != null &&
          data.button_label_ios != null
        ) {
          btnLabel = this.platform.is("ios") ? data.button_label_ios : data.button_label_android;
        }

        let currentParts = v.split(".");
        let checkingParts = version.split(".");

        let shouldUpdate = false;

        if (parseInt(currentParts[0]) < parseInt(checkingParts[0])) {
          shouldUpdate = true;
        } else if (
          parseInt(currentParts[0]) == parseInt(checkingParts[0]) &&
          parseInt(currentParts[1]) < parseInt(checkingParts[1])
        ) {
          shouldUpdate = true;
        } else if (
          parseInt(currentParts[0]) == parseInt(checkingParts[0]) &&
          parseInt(currentParts[1]) == parseInt(checkingParts[1]) &&
          parseInt(currentParts[2]) < parseInt(checkingParts[2])
        ) {
          shouldUpdate = true;
        }
        if (!shouldUpdate) {
          console.log("UP TO DATE!");
        } else {
          console.log("NEEDS UPDATE!");

          if (this.touchTimeout != null) {
            clearTimeout(this.touchTimeout);
          }

          Navigator.navigateTo(
            "AppUpdatePage",
            {},
            {
              appversion: version,
              appURL: url,
              appMessage: message,
              btnLabel: btnLabel,
            }
          );
        }
      });
    });

    if (this.touchTimeout != null) {
      clearTimeout(this.touchTimeout);
    }
    this.touchTimeout = setTimeout(
      this.touchUserProvider.checkForTouchID.bind(this),
      1000
    );
  }

  // checkPlayStoreRestricted() {
  //   this.webservices.get("configuration/get-is-allowed", {}).subscribe((response: any) => {
  //     console.log('is allowed == ', response)
  //     if (!response.payload || !(response.payload.geo.eligib === "true")) {
  //       Navigator.navigateTo("RestrictedAppPage", {}, {});
  //     }
  //   });
  // }

  isIABSelfExclusion() {
    Navigator.navigateTo(
      "LogoutPage",
      {},
      { code: "10201", reason: "PLAYER_SELF_EXCLUDE" }
    );
    setTimeout(() => {
      let url = this.config.getConfig().FEATURE_BOARD_EXCLUSION_SITE_URL;
      this.appLinks.openExternalPage(url);
    }, 200);
  }
}
