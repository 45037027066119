import { CashierProvider } from '../../providers/cashier-provider';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { Utils } from './../../../../shared/utils';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { Config } from './../../../../system/providers/configuration';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'page-account-summary',
  templateUrl: 'account-summary.html',
  styleUrls: ['./account-summary.scss'],
})
export class AccountSummaryPage {

  @ViewChild('content') content: IonContent;
  @ViewChild('fixed') mapElement: ElementRef;

  tempSummary: any = {};

  public error = null;
  public summary: any[] = [];
  public fixedY: any = 50;
  public fixedHeight: any = 50;

  public selectedCurrency: any = '';
  public totalRecords: number = 0;
  public emailDisabled = false;
  public submitDisabled = false;
  public searchDisabled = false;
  public showTable = false;
  public showNoResults = false;
  public showPagination = false;
  public environmentFlag: boolean = true;

  public toDate;
  public fromDate;
  public todayDate = '2018-01-01';
  public currentPage: number = 1;
  public maxPage: number = 1;
  public itemsPerPage = 20;

  //set options for the first datepicker
  public DatePickerOptionsFrom: IMyDpOptions = {
    dateFormat: 'mm-dd-yyyy',
    showClearDateBtn: false,
    editableDateField: false,
    disableSince: { year: 0, month: 0, day: 0 },
    disableUntil: { year: 0, month: 0, day: 0 },
  };

  //set options for the seconds datepicker
  public DatePickerOptionsTo: IMyDpOptions = {
    dateFormat: 'mm-dd-yyyy',
    showClearDateBtn: false,
    editableDateField: false,
    disableSince: { year: 0, month: 0, day: 0 },
    disableUntil: { year: 0, month: 0, day: 0 },
  };

  public jsDateObject: any;
  public startDate: any;
  public searchInit: boolean = false;
  public isSportsApp: Boolean = false;

  serverConfig: any;

  constructor(
    public cashierProvider: CashierProvider,
    public utils: Utils,
    public config: Config
  ) {
    
  }

  ionViewDidEnter() {
    let today = new Date();
    let date = new Date();
    this.startDate = new Date(date.setDate(date.getDate() - 1)); //set start date 2 weeks ago to dynamically add 2 weeks range
    this.todayDate, (this.toDate = this.utils.formatDate_MM_DD_YYYY(today)); //setting today date fallback and initial value for 'To' data passed to webservices
    this.fromDate = this.utils.formatDate_MM_DD_YYYY(date); //'From' data passed to webservice

    this.disableSinceFrom();
    this.disableSinceTo();

    this.disableUntilTo();
    this.disableUntilFrom();

    this.serverConfig = this.config.getConfig();
    this.selectedCurrency = this.serverConfig?.DEFAULT_CURRENCY;
  }

  onDateChangedFrom(event: IMyDateModel) {
    this.fromDate = event.formatted;
    this.jsDateObject = event.jsdate;
    let toDateForward = new Date(this.jsDateObject);
    toDateForward.setDate(toDateForward.getDate() + 1); //add 2 weeks to 'toDate' automatically when user picks a date
    this.disableSinceTo();
    this.disableUntilTo();

    this.searchDisabled = false;
  }

  onDateChangedTo(event: IMyDateModel) {
    this.toDate = event.formatted;
    this.searchDisabled = false;
  }

  disableSinceFrom() {
    let d: Date = new Date();
    d.setDate(d.getDate() + 1);
    let copy = this.getCopyOfOptionsFrom();
    copy.disableSince = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.DatePickerOptionsFrom = copy;
  }

  disableUntilFrom() {
    let d: Date = new Date();
    d.setDate(d.getDate() - 185);
    let copy = this.getCopyOfOptionsFrom();
    copy.disableUntil = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.DatePickerOptionsFrom = copy;
  }

  getCopyOfOptionsFrom(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.DatePickerOptionsFrom));
  }

  disableSinceTo() {
    let d: Date = new Date(this.startDate);
    d.setDate(d.getDate() + 2);
    let copy = this.getCopyOfOptionsTo();
    copy.disableSince = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.DatePickerOptionsTo = copy;
  }

  disableUntilTo() {
    let d: Date =
      this.jsDateObject !== undefined
        ? new Date(this.jsDateObject)
        : new Date(this.startDate);
    d.setDate(d.getDate());
    let copy = this.getCopyOfOptionsTo();
    copy.disableUntil = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.DatePickerOptionsTo = copy;
  }

  getCopyOfOptionsTo(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.DatePickerOptionsTo));
  }

  isSubmitDisabled() {
    return this.submitDisabled;
  }

  isEmailDisabled() {
    return this.emailDisabled;
  }

  onChangeFrom(event) {
    console.log('onChangeFrom AccountSummaryPage', event);
    this.submitDisabled = false;
  }

  onChangeTo(event) {
    console.log('onChangeTo AccountSummaryPage', event);
    this.submitDisabled = false;
  }

  onChangeCurrency(event) {
    this.searchInit = false;
    console.log('onChangeCurrency AccountSummaryPage', event);
    this.selectedCurrency = event.target.value;
    this.submitDisabled = false;
    this.searchDisabled = false;
  }

  onEmailMe(event) {
    this.emailDisabled = true;
  }

  onPageChange(event) {
    console.log('onPageChange AccountSummaryPage', event);
    //this.currentPage = Number(event);

    this.onSearch(Number(event));
  }

  onSearch(page?) {
    this.submitDisabled = true;
    this.searchDisabled = true;
    this.searchInit = true;
    this.summary = [];

    var requestObj: any = {};
    requestObj.dateFrom = this.utils.formatDate_from_MM_DD_YYYY_to_YYYY_MM_DD(
      this.fromDate
    );
    requestObj.dateTo = this.utils.formatDate_from_MM_DD_YYYY_to_YYYY_MM_DD(
      this.toDate
    );

    requestObj.currency_code = this.selectedCurrency;

    requestObj.pageno = !!page ? page : this.currentPage;

    this.cashierProvider.callGetAccountSummary(requestObj).subscribe(
      (response: any) => {
        if (response && !!response.result) {
          let pastAccountSummary = response.payload.past_account_summary;
          // make an array for iterations.
          this.summary = Object.keys(pastAccountSummary).map(
            (k) => pastAccountSummary[k]
          );
          const serverPageSize = response.payload.page_size;
          const serverTotalPages = response.payload.total_pages;
          const pageNumber = Number(response.payload.page_no);

          this.totalRecords = response.payload.count;
          this.currentPage = pageNumber;
          this.maxPage = response.payload.total_pages;

          if (serverTotalPages > 1) {
            this.showPagination = true;
          }

          if (this.totalRecords > 0) {
            this.showTable = true;
            this.showNoResults = false;
          } else {
            this.showNoResults = true;
            this.showTable = false;
          }
        }
      },
      (error) => {
        this.error = error;
        console.error('err == ', this.error);
        this.showNoResults = true;
      }
    );

    this.fixedY = this.mapElement.nativeElement.offsetHeight;
    // this.fixedHeight = this.content.contentHeight - this.fixedY;
  }

  numberFormat(number: any): string {
    if (!!number) {
      var num = number,
        rounded = number.rounded;
      return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    } else {
      return '';
    }
  }
}
