import { CustomerModel } from './../../account/models/customer-model';
import { UserSessionProvider } from './../../../system/providers/user-session';
import { WebservicesProvider } from './../../../system/providers/webservices';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GamesProvider {

  public customer: CustomerModel;

  constructor(
    private userSession: UserSessionProvider,
    private webservice: WebservicesProvider
  ) {
    this.userSession.getCustomer().subscribe(
      (customer) => {
        this.customer = customer;
      }
    );
  }  

  callGameLogHistory(date_from: string, date_to: string, page: number = 1, type?: string) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      start_date: date_from,
      end_date: date_to,
      page_number: page
    }

    return this.webservice.get('games/casino/game-log-history', obj);
  }
  
}
