import { Pipe, PipeTransform } from '@angular/core';
import { isUndefined } from '../../../shared/codeUtils';
/**
 * Generated class for the TruncateTextPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */

@Pipe({
 name: 'truncateText'
})

export class TruncateText implements PipeTransform {

transform(value: string, args: any[]): string {
    if(!isUndefined(value)) {
        const limit = args.length > 0 ? parseInt(args[0], 10) : 10;
        const ending = args.length > 1 ? args[1] : '...';
        return value.length > limit ? value.substring(0, limit) + ending : value;
    }
   }
}