import { ClickOutsideDirective } from './directives/click-outside/click-outside';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CurrencyFormatter } from '../system/pipes/currency/currency';
import { CustomNavbarComponent } from './components/custom-navbar/custom-navbar';
import { NotificationPopoverComponent } from './components/notification-popover/notification-popover.component';
import { PopoverModule } from '../modules/popover/popover.module';
import { SearchSliderDirective } from './directives/search-slider/search-slider';
import { HoverEffectDirective } from './directives/hover-effect/hover-effect';
import { StickyElementDirective } from './directives/sticky-element/sticky-element';
import { FilterData } from './pipes/search-games/search-games';
import { HighlightSearch } from './pipes/highlight-search/highlight-search';
import { TruncateText } from './pipes/truncateText/truncate-text';
import { FilterKeywords } from './pipes/filterKeywords/filter-keywords';
import {  PhoneNumber } from './pipes/PhoneNumber/PhoneNumber';
import { RedirectComponent } from './components/redirect/redirect';
import { AppInitComponent } from './components/app-init/app-init';
import { SystemModule } from '../system/system.module';
import { InactivityComponent } from './components/inactivity/inactivity';
import { PayoutLockComponent } from '../modules/cashier/components/payout-lock/payout-lock';
import { ElementHeightDirective } from './directives/set-element-height/set-element-height';
import { NoteBoxComponent } from './components/note-box/note-box';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        CurrencyFormatter,
        CustomNavbarComponent,
        NotificationPopoverComponent,
        RedirectComponent,
        AppInitComponent,
        SearchSliderDirective,
        FilterData,
        HighlightSearch,
        PhoneNumber,
        TruncateText,
        FilterKeywords,
        ElementHeightDirective,
        HoverEffectDirective,
        StickyElementDirective,
        ClickOutsideDirective,
        InactivityComponent,
        PayoutLockComponent,
        NoteBoxComponent
    ],
    imports: [
      IonicModule.forRoot(),
      PopoverModule,
      SystemModule,
      CommonModule,
      TranslateModule
    ],
    entryComponents: [],
    providers: [
    ],
    exports: [
        CurrencyFormatter,
        CustomNavbarComponent,
        NotificationPopoverComponent,
        PopoverModule,
        RedirectComponent,
        AppInitComponent,
        SearchSliderDirective,
        StickyElementDirective,
        FilterData,
        HighlightSearch,
        PhoneNumber,
        TruncateText,
        FilterKeywords,
        ElementHeightDirective,
        HoverEffectDirective,
        ClickOutsideDirective,
        InactivityComponent,
        PayoutLockComponent,
        NoteBoxComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SharedModule {}
