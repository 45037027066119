import { Utils } from './../../../shared/utils';
import { Injectable } from '@angular/core';
import { Config } from "../../../system/providers/configuration";
import { UserSessionProvider } from '../../../system/providers/user-session';
import { CustomerModel } from './../../account/models/customer-model';
import { BehaviorSubject, Subject } from 'rxjs';
import { WebservicesProvider } from '../../../system/providers/webservices';
import { Meta, Title } from '@angular/platform-browser';
import { Storage } from '@ionic/storage-angular';
import { ConfigurationProvider } from '../../../system/providers/configuration-provider';
import { MenuItemPreProcessor } from '../../../system/providers/menu-item-pre-processor-helper';
import { CashierProvider } from '../../cashier/providers/cashier-provider';
import { Platform } from '@ionic/angular';
import { CommonService } from 'src/app/service/common.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MainProvider {

  private theme: BehaviorSubject<String>;
  protected lobbyName: string;
  public customer: CustomerModel;
  protected _recentGamesSubject: Subject<any>;
  public sportsBookURL;
  private webviewUrl;
  private waitingGameData = null;
  private waitingDialogPage = null;
  private appConfigInfo = null;
  public showLobby:boolean = true;
  public isIphoneWithNotch:boolean = false;
  public timeOnline:any;
  public serverTime:any;

  private isIAB: boolean  = false;

  public firstLoginTime:any;
  public onlineTime:any;
  public iosSliderEnabled: boolean = false;

  public currentTab: string;
  public gameSessionToken: any;
  public currentLoginTime: any;
  public mfaRequired: boolean = false;
  public strongAuthRequired: boolean = false;

  constructor(
    private config: Config,
    private userSession: UserSessionProvider,
    private webservices: WebservicesProvider,
    private title: Title, private meta: Meta,
    private utils: Utils,
    private http: HttpClient,
    public platform: Platform,
    public comService: CommonService,
    public configurationProvider:ConfigurationProvider,
    public cashierProvider:CashierProvider,
  ) {
    this.userSession.getCustomer().subscribe(
      (customer) => {
        this.customer = customer;
        if (!customer) {
          this.firstLoginTime = 0;
        }
      }
    );

    this.theme = new BehaviorSubject('SPORTS');

    let hash = document.location.hash;
    if (~hash.indexOf('#iab') && this.platform.is('ios')) {
      this.isIAB = true;
    }
  }


  public galleryImagePath = this.config.getConfig()?.CDN_BASEURL + '/assets/social/games/';  //main image path

  getSidemenuData(menuSelected) {
    let siteMenuFile = "assets/menu/user/menu.json";
    let menuParsed;
    try {
      menuParsed  = JSON.parse(siteMenuFile);
    } catch (e) {
      menuParsed = JSON.parse(`{"desktop": "${siteMenuFile}", "mobile": "${siteMenuFile}" }`);
    }
    let selectedLink = (menuSelected === "mobile" ? menuParsed.mobile : menuParsed.desktop);
    let menu_url = this.config.loadCDNAsset(selectedLink);

    return this.http.get(menu_url).toPromise()
      .then((res: any) => {
        console.log('user menu res == ', res);
        const menuOpts = res;
        const menuItemPreProcessor = new MenuItemPreProcessor(this.cashierProvider);
        menuOpts.forEach(menuItem => {
          menuItemPreProcessor.menuItemPreProcessing(menuItem);
        })
        return menuOpts;
      }, err => console.error(err));
  }

  public isVerticalEnabled = verticalCode => {
    let productVerticals = {
      casino: true,
      sports: true,
      livegames: true
    }
    return (verticalCode in productVerticals) ? productVerticals[verticalCode] : true;
  }

  /**
   *
   * @param
   */
  callSportsApi(url) {
    var sendData: any = {
      platform: this.comService.isMobileWeb ? 2 : 1
    };
    if (this.customer) {
      sendData = {
        customer_id: this.customer?.customerId,
        session_id: this.userSession.sessionToken,
        selected_currency: this.comService.defaulCurrency,
        platform: this.comService.isMobileWeb ? 2 : 1
      }
    }
    return this.webservices.postByApiUrl(url, sendData);
  }

  // Multiple themes handling
  setActiveTheme(val) {
    this.theme.next(val);
  }

  getActiveTheme() {
    return this.theme.asObservable();
  }
  setCurrentLoginTime(currentLoginTime: string) {
    this.currentLoginTime = currentLoginTime;
  }

  getCurrentLoginTime(){
    return this.currentLoginTime;
  }

  getSportsTabURL() {
    return this.sportsBookURL;
  }

  setSportsTabURL(value) {
    this.sportsBookURL = value;
  }

  checkBingoSchedule() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken
    }
    return this.webservices.get('games/bingo/schedule', obj)
  }

  //SEO Stuff
  updateMeta(url) {
    let links = this.config.getExternalLinks();

    url = this.utils.cleanPathName(url);

    let link = links.filter(link => this.utils.cleanPathName(link.url) == url);
    link = (!!link.length) ? link[0] : {};

    if (!!link.title) this.updateTitle(link.title);
    if (!!link.description) this.updateDescription(link.description);
    if (!!link.keywords) this.updateKeywords(link.keywords);

    this.removeExtraTags();

    if(!!link.extraTags) {
      this.addExtraTags(link.extraTags);
    }

  }


  setWebviewUrl(url) {
    this.webviewUrl = url;
  }

  getWebviewUrl() {
    return this.webviewUrl;
  }

  setShowLobby(showLobby) {
    this.showLobby = showLobby;
  }

  getShowLobby() {
    return this.showLobby;
  }

  //SEO Stuff
  // updateMeta(url) {
  //   let links = this.config.getExternalLinks();

  //   url = this.utils.cleanPathName(url);

  //   let link = links.filter(link => this.utils.cleanPathName(link.url) == url);
  //   link = (!!link.length) ? link[0] : {};

  //   if (!!link.title) this.updateTitle(link.title);
  //   if (!!link.description) this.updateDescription(link.description);
  //   if (!!link.keywords) this.updateKeywords(link.keywords);

  //   this.removeExtraTags();

  //   if(!!link.extraTags) {
  //     this.addExtraTags(link.extraTags);
  //   }

  // }


  updateTitle(title: string) {
    this.title.setTitle(title);
  }
  //og:url basically tells the FB scraper "ignore anything on this page, and scrape this url instead"
  //So it's doing exactly what it's supposed to do. If you want the like button to point to a different url,
  //use the href parameter and have it point to a different url.
  //@todo
  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url })
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }

  updateKeywords(keywords: string) {
    this.meta.updateTag({ name: 'keywords', content: keywords })
  }

  //launch the game after you login
  setWaitingGameData(id) {
    this.waitingGameData = id;
  }

  getWaitingGameData() {
    return this.waitingGameData;
  }

  clearWaitingGameData() {
    this.waitingGameData = null;
  }

  private loading;
  //launch the game after you login
  setWaitingDialogPage(dialogName) {
    this.waitingDialogPage = dialogName;
    if(dialogName == 'depositpaypal' && this.isIABApp()) {
      this.comService.showLoader('Updating...');
    }
  }

  getWaitingDialogPage(checkValue = false) {
    if(this.waitingDialogPage == 'depositpaypal' && this.isIABApp() && !!checkValue) {
      this.loading.dismiss();
    }
    return this.waitingDialogPage;
  }

  clearWaitingDialogPage() {
    this.waitingDialogPage = null;
  }

  addExtraTags(extraTags) {
    var el = document.createElement( 'html' );
    el.innerHTML = extraTags;
    let linkElements:any  = el.getElementsByTagName( 'link' );
    let metaElements:any = el.getElementsByTagName( 'meta' );
    let headElement:any = document.getElementsByTagName("head")[0];
    console.log("linkElements", linkElements )
    if(!!linkElements && !!linkElements.length) {
      for(let i=0; i < linkElements.length; i++ ) {
        let element = linkElements[i];
        let cln = element.cloneNode(true);
        //cln.setAttribute("id", "extra_0t")
        cln.setAttribute("extra", "1")
        headElement.appendChild(cln);

      }
    }
    if(!!metaElements && !!metaElements.length) {
      for(let i=0; i < metaElements.length; i++ ) {
        let element = metaElements[i];
        let cln = element.cloneNode(true);
        cln.setAttribute("extra", "1")
        headElement.appendChild(cln);
      }
    }

  }
  removeExtraTags() {
    let extra = document.querySelectorAll(`[extra="1"]`);
    for(let i=0; i < extra.length; i++ ) {
      extra[i].remove();
    }
  }

  setIphoneWithNotch(isIphoneWithNotch) {
    this.isIphoneWithNotch = isIphoneWithNotch;
  }

  getIphoneWithNotch() {
    return this.isIphoneWithNotch;
  }

  //#############################
  //IAB METHODS
  //#############################


  isIABApp():boolean {
    return this.isIAB;
  }

  public sportSubpage: string;
  setSportSubpage(configValue) {
    this.sportSubpage = configValue;
  }

  getSportSubpage() {
    return this.sportSubpage;
  }

  setIAB(value) {
    this.isIAB = value;
  }

  public subPage;


  setOnlineTime(onlineTime) {
    this.timeOnline = onlineTime;
  }

  getOnlineTime() {
    return this.timeOnline;
  }

  setServerTime(time) {
    this.serverTime = time;
  }

  getServerTime() {
    return this.serverTime;
  }

  setIOSslider(enabled) {
    this.iosSliderEnabled = enabled;
  }

  getIOSslider() {
    return this.iosSliderEnabled;
  }

  setMFARequired(isRequired:boolean){
    this.mfaRequired = isRequired;
  }

  getMFARequired(){
    return this.mfaRequired;
  }
  setStrongAuthRequired(isEnabled:boolean){
    this.strongAuthRequired = isEnabled;
  }
  getStrongAuthRequired(){
    return this.strongAuthRequired;
  }

  getLiveDealerUrl(isMobile, category) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      game_name: "evolution",
      client_app_id: this.config.getClientApp(),
      no_redirect : "1",
      is_mobile : isMobile,
      category: category
    }
    return this.webservices.get('games/lobby/launch', obj)
  }

  setCurrentTab(tab){
    this.currentTab = tab;
    return;
  }

  getCurrentTab(){
    return this.currentTab;
  }

  setGameSessionID(game_token)
  {
    this.gameSessionToken = game_token;
  }

  getGameSessionID () {
    return this.gameSessionToken;
  }

  clearGameSessionID () {
    this.gameSessionToken = null;
  }

}
