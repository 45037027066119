import { Navigator } from './../../../system/components/menu/navigator';
import { Component, Input, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { isUndefined } from '../../codeUtils';
import { IonNav } from '@ionic/angular';
import {UserSessionProvider} from "../../../system/providers/user-session";

/**
 * Generated class for the  CustomNavbarComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'custom-navbar',
  templateUrl: 'custom-navbar.html',
  styleUrls: ['./custom-navbar.scss']
})
export class CustomNavbarComponent {

  public showCloseButton: boolean = true;
  @Input() title: string;
  @Input() overrideBackBtnFunction: any;
  @Input() hideBackButton: boolean;
  @Input() extendCloseBtnFunction: any;
  @Input() overrideCloseBtnFunction: any;
  // @ViewChild('theNavBar', {static: true}) theNavBar: ElementRef;
  // @ViewChild(IonNav) navBar: IonNav;

  constructor(
    public userSession: UserSessionProvider,
    // private renderer: Renderer2,
  ) { }

  ngAfterViewInit() {
    // if (!isUndefined(this.hideBackButton) && this.hideBackButton === true) {
    //   let backButton = this.theNavBar.nativeElement.querySelector('.back-button');
    //   this.renderer.setStyle(backButton, "display", "none");

    // } else {
    //   let backButton = this.theNavBar.nativeElement.querySelector('.back-button');
    //   this.renderer.setStyle(backButton, "display", "block");
    // }
  }

  onCloseBtn() {
    if (!isUndefined(this.extendCloseBtnFunction)) {
      try {
        this.extendCloseBtnFunction()
      }
      catch (error) {
        console.error(error);
      }
    }

    if (!isUndefined(this.overrideCloseBtnFunction)) {
      try {
        this.overrideCloseBtnFunction()
      }
      catch (error) {
        console.error(error);
      }
    } else {
      setTimeout(() => {
        // Navigator.toLobby();
        Navigator.back();
      }, 0)
    }
  }

  back() {
    if (!isUndefined(this.overrideBackBtnFunction)) {
      try {
        this.overrideBackBtnFunction();
      }
      catch (error) {
        console.error(error);
      }
    } else {
      Navigator.back();
    }
  }

}
