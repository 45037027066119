import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';

import { CommonModule, DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DepositBtnComponent } from './components/deposit-btn/deposit-btn';
import { FooterComponent } from './components/footer/footer';
import { ErrorComponent } from './components/error/error-component';
import { SuccessMessageComponent } from './components/success-message/success-message';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    DepositBtnComponent,
    FooterComponent,
    ErrorComponent,
    SuccessMessageComponent
  ],
  imports: [
    HttpClientModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    CommonModule,
    TranslateModule
  ],
  entryComponents: [],
  providers: [
    DatePipe,
  ],
  exports: [
    DepositBtnComponent,
    FooterComponent,
    ErrorComponent,
    SuccessMessageComponent
  ],
})
export class SystemModule {}
