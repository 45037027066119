import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CustomerModel } from 'src/app/modules/account/models/customer-model';
import { TouchUserProvider } from 'src/app/modules/authentication/providers/touch-user-provider';
import { GameHubController } from 'src/app/modules/gameHub/game-hub-controller';
import { MainProvider } from 'src/app/modules/lobby/providers/main.provider';
import { CommonService } from 'src/app/service/common.service';
import { MyEvent } from 'src/app/service/myevent.services';
import { Config } from 'src/app/system/providers/configuration';
import { UserSessionProvider } from 'src/app/system/providers/user-session';

@Component({
  selector: 'app-game-item',
  templateUrl: './game-item.component.html',
  styleUrls: ['./game-item.component.scss'],
})
export class GameItemComponent implements OnInit {

  @Input() gameData: any;
  @Input() tooltipPosition = 'top-right'; // top-left

  isLoggedIn = false;
  isDemoEnabled = false;

  customer: CustomerModel;
  placeholderImg: any;

  constructor(
    public userSession: UserSessionProvider,
    public config: Config,
    private cdr: ChangeDetectorRef,
    public gameHubController: GameHubController,
    private events: MyEvent,
    private mainProvider: MainProvider,
    private touchUserProvider: TouchUserProvider,
    public comService: CommonService
  ) { }

  ngOnInit() {
    let appConfigInfo = this.config.getAppConfigInfo();
    this.placeholderImg = appConfigInfo?.placeholder_img;
    // this.isDemoEnabled = appConfigInfo.experimental['GAME_DEMO_LAUNCH'];
    this.isDemoEnabled = this.gameData.demo_enable == '1';
  }

  ngAfterViewInit() {
    this.userSession.getCustomer().subscribe((customer: CustomerModel) => {
      this.customer = customer;
      this.isLoggedIn = this.userSession.isAuthenticated();
    });
  }

  launchCasinoGame() {
    if (!this.isAuthenticated()) {
      this.mainProvider.setWaitingGameData(this.gameData);
      this.onLogin();
    } else {
      this.events.publishSomeData({event: "event:gameHub:closeGame"});
      setTimeout(() => {
        this.gameHubController.launchCasinoGame(this.gameData);
      }, 500);
    }
  }

  launchCasinoGameDemo() {
    this.events.publishSomeData({event: "event:gameHub:closeGame"});
    setTimeout(() => {
      this.gameHubController.launchCasinoGameDemo(this.gameData);
    }, 500);
  }

  onLogin() {
    this.touchUserProvider.onLogin({ hideBackButton: true });
  }

  isAuthenticated() {
    return this.isLoggedIn;
  }

  loadImg() {
    this.gameData.loaded = true;
    this.cdr.detectChanges();
  }

  errorImg() {
    this.gameData.loaded = 'error';
    this.cdr.detectChanges();
  }

}
