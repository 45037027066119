import { CashierProvider } from '../../providers/cashier-provider';
import { Component, Input, OnInit } from '@angular/core';
import { SuccessMessageProvider } from './../../../../system/providers/success-message-provider';
import { Utils } from '../../../../shared/utils';
import { MyEvent } from 'src/app/service/myevent.services';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'page-my-transaction-methods-confirm',
  templateUrl: 'my-transaction-methods-confirm.html',
  styleUrls: ['./my-transaction-methods-confirm.scss'],
})
export class MyTransactionMethodsConfirmPage implements OnInit {

  @Input() data: any;

  error = null;

  account: any;

  constructor(
    private events: MyEvent,
    public utils: Utils,
    private cashierProvider: CashierProvider,
    public successMessageProvider: SuccessMessageProvider,
    private navParam: NavParams
  ) {
    if (this.navParam) {
      this.data = this.navParam.data;
    }
    console.log('data == ', this.data);

    this.account = this.data.account;

    this.successMessageProvider.clearMessage();
    this.error = null;
  }

  ngOnInit(): void {
    
  }

  onRemoveAccount(customerAccountID: string) {
    this.cashierProvider.callRemoveAccount(customerAccountID).subscribe(
      (response: any) => {
        if (response && !!response.result) {
          this.successMessageProvider.setMessage(
            'Your payment method was deleted successfully.'
          );
        }
      },
      (error) => {
        
      }
    );
  }

  onGoBack() {
    
  }
}
