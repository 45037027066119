import {
  Directive,
  ElementRef,
  AfterViewInit,
  HostListener,
  Input
} from '@angular/core';

/**
 * Generated class for the SearchSliderDirective directive.
 *
 * See https://angular.io/api/core/Directive for more info on Angular
 * Directives.
 */
@Directive({
  selector: '[searchSlider]' // Attribute selector
})
export class SearchSliderDirective implements AfterViewInit{
  @Input() searchBarWidth: number = 315;     //default width
  @Input() windowBreakPoint:number = 576;    //default breakpoint
  windowWidth:number;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
      this.windowWidth = window.innerWidth;
      this.setSliderWidth(this.el.nativeElement, this.windowWidth, this.searchBarWidth, this.windowBreakPoint);
  }

  resizeTimeout = null;
  @HostListener('window:resize', ['$event']) 
  onWindowResize(event) {
    if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
        this.windowWidth = event.target.innerWidth;
        this.setSliderWidth(this.el.nativeElement, this.windowWidth, this.searchBarWidth, this.windowBreakPoint);
    }).bind(this), 500);
  }

  setSliderWidth(parentElem: HTMLElement, winWidth: number, definedBarWidth:number, winBreakPoint:number) {
    if (!winWidth) return;
    if (!parentElem) return;
    
    const barRightMargin = definedBarWidth - 50;          //shows search bar icon when the bar is in hidden state
    var barWidth = definedBarWidth;                       //on desktop search bar width is defined in view or uses default value                                      
    var searchBarSizeIncrease = 0;

    if(winWidth <= winBreakPoint) {
      barWidth = winWidth;                           //width of search bar on mobile devices
      searchBarSizeIncrease = barWidth - definedBarWidth;                 
    }

    const slideRight = barRightMargin + searchBarSizeIncrease; 
    const slideInner = -(slideRight);

    const innerSlideContainer = parentElem.getElementsByClassName('inner-slide-container'); //element used to set search bar width
    if (!innerSlideContainer) return;

    const slideOut =  parentElem.getElementsByClassName('slideout'); //element used to set search bar position when search bar is visible
    if (!slideOut) return;

    const slideoutInner = parentElem.getElementsByClassName('slideout_inner'); //element used to set search bar position when search bar is hidden 
    if (!slideoutInner) return;

    //set search bar width and margins
    Array.from(innerSlideContainer).forEach((x: HTMLElement) => {
      x.style.width = barWidth + 'px';
    })

    Array.from(slideOut).forEach((x: HTMLElement) => {
      x.style.right = slideRight + 'px';
    })

    Array.from(slideoutInner).forEach((x: HTMLElement) => {
      x.style.right = slideInner + 'px';
    })
  }

}
