import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/bfy',
    pathMatch: 'full'
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/refer-signup/refer-signup.module').then( m => m.ReferSignupPageModule)
  },
  {
    path: 'gameback',
    loadChildren: () => import('./pages/gameback/gameback.module').then( m => m.GamebackPageModule)
  },
  {
    path: 'oauth',
    loadChildren: () => import('./pages/oauth/oauth.module').then( m => m.OauthPageModule)
  },
  {
    path: 'bps',
    loadChildren: () => import('./templates/betsplus/betsplus.module').then( m => m.BetsplusPageModule)
  },
  {
    path: 'bfy',
    loadChildren: () => import('./templates/betfury/betfury.module').then( m => m.BetfuryPageModule)
  },
  {
    path: 'rbt',
    loadChildren: () => import('./templates/rollbit/rollbit.module').then( m => m.RollbitPageModule)
  },
  {
    path: 'bcg',
    loadChildren: () => import('./templates/bcg/bcg.module').then( m => m.BcgPageModule)
  },
  {
    path: 'restricted-app',
    loadChildren: () => import('./pages/restricted-app/restricted-app.module').then( m => m.RestrictedAppPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
