import { AccountInformationPage } from './account-information';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    AccountInformationPage,
  ],
  imports: [
    CommonModule,
    SharedModule,
    IonicModule,
    TranslateModule
  ],
})
export class AccountInformationPageModule {}
