import { AffiliateTrackerCallbackParams } from './../../providers/affiliation-event';
import { Component, Inject, OnInit } from '@angular/core';
import {
  NotificationApiProvider,
  NotificationEventInterface,
} from '../../providers/notification-api';
import { UserSessionProvider } from './../../../system/providers/user-session';
import { JackpotCounterProvider } from './../../providers/jackpot-notification-counter';
import { GlobalErrorHandler } from './../../../system/providers/global-error-handler';
import { PopoverFactory } from './../../../modules/popover/factory';
import { Config } from '../../../system/providers/configuration';
import { Utils } from '../../utils';
import { MainProvider } from '../../../modules/lobby/providers/main.provider';
import { Platform, PopoverController, ToastController } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';

@Component({
  selector: 'notification-popover',
  templateUrl: 'notification-popover.html',
})
export class NotificationPopoverComponent implements OnInit {

  public Que = [];
  public activeNotifications = 0;

  constructor(
    private platform: Platform,
    private config: Config,
    private notificationApi: NotificationApiProvider,
    private popoverCtrl: PopoverController,
    private userSession: UserSessionProvider,
    private globalErrorHandler: GlobalErrorHandler,
    private jackpotCounter: JackpotCounterProvider,
    private events: MyEvent,
    private toastCtrl: ToastController,
    private utils: Utils,
    private mainProvider: MainProvider
  ) {}

  ngOnInit() {
    this.subscribeToNotifications();
    this.events.getObservable().subscribe((data) => {
      if (data.event == 'event:notifications:start') this.showNotifications();
      else if (data.event == 'event:authentication:logout') {
        this.Que = [];
        this.activeNotifications = 0;
      }
    });
  }

  subscribeToNotifications() {
    let page;
    this.notificationApi.listen().subscribe((e: NotificationEventInterface) => {
      console.log('noti event == ', e);
      this.events.publishSomeData({ event: 'event:geoComply:dismiss' });
      
      switch (e.type) {
        case 'PUSH_APP_SESSION_STATS':
          // page = PopoverFactory('SessionStatsPopover');
          break;
        case 'LOYALTY_NEW_TIER':
        case 'MILESTONE_AWARD':
          page = PopoverFactory('LoyaltyPopover');
          this.popoverCtrl
            .create({
              component: page,
              componentProps: {
                e,
              },
              showBackdrop: false,
              cssClass: 'standard-popover levelup',
            })
            .then((pop) => pop.present());
          break;
        case 'LOYALTY_TIER_WILL_EXPIRY':
          break;
        case 'MARKETING_OFFER':
          this.addNotificationToQue(e);
          break;
        case 'PLAYER_CONVERSION':
          this.pushPlayerConversionEvent(e);
          break;
        case 'PLAYER_BET':
          this.pushPlayerBetEvent(e);
          break;
        case 'MARKETING_MESSAGE':
          this.addNotificationToQue(e);
          break;
        case 'MARKETING_PROMO':
          break;
        case 'BAGDE_AWARDED':
          break;
        case 'BALANCE':
          break;
        case 'LOGOUT':
          if (this.userSession.isAuthenticated()) {
            if (this.mainProvider.isIABApp()) {
              window.LOGOUT_EVENT = true;
            }
            this.globalErrorHandler.handleError({ code: '10201' });
          }
          break;
        case 'PREVIOUS_DISCONNECTION':
          if (this.userSession.isAuthenticated()) {
            console.log('In disconnect user authenticated');

            if ('customField2' in e) {
              let disconnectionTimeframe = e['customField2'];
              let disconnectionTimeframeAllowed = this.config.getConfig().DISCONNECT_TIME_ALLOWED || 2592000000;
              // let params = this.utils.getUrlParams();
              // let isPaypalDisconnect = 'paypal' in params ? true : false;
              // let isIAB = this.mainProvider.isIABApp();
              if (Number(disconnectionTimeframe) > Number(disconnectionTimeframeAllowed)) {
                if ((this.config.getConfig().FEATURE_MFA_ENABLED && this.mainProvider.getMFARequired()) || this.mainProvider.getStrongAuthRequired()) {
                  let currentLogin = new Date(this.mainProvider.getCurrentLoginTime());
                  // Adding 5 Minutes after current login time
                  let waitConfigTime = Number(this.config.getConfig().FEATURE_DISCONNECT_WAIT_TIME) || 43200;
                  console.log('In mfa enabled OR Strong Auth required');
                  let waitTime = new Date(
                    currentLogin.setMinutes(
                      currentLogin.getMinutes() + waitConfigTime
                    )
                  );
                  // Check if current time greater than last-login + waitTime(Configurable), logout
                  if (new Date() > waitTime) {
                    this.globalErrorHandler.handleError({
                      code: 'INVALID_DISCONNECT_TIME_ALLOWED',
                    });
                  }
                } else {
                  this.globalErrorHandler.handleError({
                    code: 'INVALID_DISCONNECT_TIME_ALLOWED',
                  });
                }
              }
            }
          }
          break;
        case 'JACKPOT':
          let gameTypeId = e.parametersMap ? e.parametersMap.gameTypeIds : '';
          let currency = e.parametersMap ? e.parametersMap.currency : '';
          let jackpotID = e.customField3 ? e.customField3 : '';
          let siteJackpotID = this.config.getConfig().FEATURE_JACKPOT_ID;

          if (jackpotID == siteJackpotID && gameTypeId === '333') {
            if (currency == this.config.getConfig().DEFAULT_CURRENCY) {
              let data: any = {
                amount: e.parametersMap.amount,
                currency: currency,
              };
              this.jackpotCounter.sendCounterValue(data);
            }
          }
          break;
        case 'JACKPOT_HIT':
          // skip for current player
          let customerId = e.parametersMap ? e.parametersMap.winner : '';
          this.userSession.getCustomer().subscribe((customer) => {
            if (
              e.customField4 == this.config.getConfig().DEFAULT_CURRENCY &&
              customer &&
              customer.customerId.toString() != customerId
            ) {
              let jackpotText = e.text;
              this.toastCtrl
                .create({
                  message: jackpotText,
                  duration: 8000,
                  position: 'bottom',
                })
                .then((toast) => toast.present());
            }
          });
          break;
        case 'ALERT':
        case 'MESSAGE':
          this.toastCtrl.create({
            message: e.text,
            duration: 5000,
            position: 'top',
            cssClass: 'custom_toast'
          }).then((toast) => toast.present());
          break;
        case 'MANUAL':
        default:
          // let alert_page = PopoverFactory('NotificationsPopoverPage');
          // this.popoverCtrl
          //   .create({
          //     component: alert_page,
          //     componentProps: {
          //       e,
          //     },
          //     showBackdrop: false,
          //     backdropDismiss: true,
          //     cssClass: 'custom-popover',
          //   })
          //   .then((alt) => alt.present());
          this.toastCtrl.create({
            message: e.text,
            duration: 5000,
            position: 'top',
            cssClass: 'custom_toast'
          }).then((toast) => toast.present());
          break;
      }
    });
  }

  addNotificationToQue(e: NotificationEventInterface) {
    this.Que.push(e);
  }

  showNotifications() {
    if (this.Que.length) {
      let e = this.Que[0];
      this.Que.shift();
      this.showNotification(e);
    }
  }

  showNotification(e) {
    e.onCloseFunc = this.nextNotification;

    let marketing_offer = PopoverFactory('MarketingNotificationPopover');
    this.popoverCtrl
      .create({
        component: marketing_offer,
        componentProps: {
          e,
        },
        showBackdrop: true,
        backdropDismiss: false,
        cssClass: 'standard-popover marketing-notification',
      })
      .then((pop) => pop.present());

    this.activeNotifications = 1;
  }

  nextNotification = (emmiter = null) => {
    this.activeNotifications = 0;
    if (emmiter === 'closeButton') {
      this.showNotifications();
    }
  };

  pushPlayerConversionEvent(event) {
    let conversionEventData: AffiliateTrackerCallbackParams = {
      customer_id: this.userSession.getCustomerId(),
      registration_complete: '1',
      deposit: '1',
      bet_placed: '1',
    };
    setTimeout(
      () =>
        this.events.publishSomeData({
          event: 'event:conversion:set',
          data: conversionEventData,
        }),
      2000
    );
  }

  pushPlayerBetEvent(event) {
    let data;
    let hasMap: boolean = false;
    if ('parametersMap' in event) {
      data = event.parametersMap;
      hasMap = true;
    }
    let betEventData: AffiliateTrackerCallbackParams = {
      customer_id: this.userSession.getCustomerId(),
      game_provider:
        hasMap && 'game_provider' in data ? data.game_provider : '',
      restricted_amount:
        hasMap && 'restricted_amount' in data ? data.restricted_amount : '',
      wager_amount: hasMap && 'wager_amount' in data ? data.wager_amount : '',
      wager_trx_id: hasMap && 'wager_trx_id' in data ? data.wager_trx_id : '',
      deposit_count:
        hasMap && 'deposit_count' in data ? data.deposit_count : '',
      withdrawal_count:
        hasMap && 'withdrawal_count' in data ? data.withdrawal_count : '',
      total_deposit:
        hasMap && 'total_deposit' in data ? data.total_deposit : '',
      total_withdrawal:
        hasMap && 'total_withdrawal' in data ? data.total_withdrawal : '',
      game_name: hasMap && 'game_name' in data ? data.game_name : '',
    };
    setTimeout(
      () =>
        this.events.publishSomeData({
          event: 'sports:add:bet',
          data: betEventData,
        }),
      1000
    );
  }
}
