import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import { WebservicesProvider } from './webservices';
import { PlatformResponse } from './webservices';
import { CustomerModel } from '../../modules/account/models/customer-model';

import 'rxjs/add/operator/map';
import 'rxjs/add/observable/from';


@Injectable({
  providedIn: 'root'
})
export class UserSessionProvider {

  USER_SESSION_STORE_KEY = 'userSession.sessionToken';
  USER_DATA_STORE_KEY = 'userSession.customer';

  sessionToken: string;
  customerId: string;
  _customerSubject: BehaviorSubject<CustomerModel> = new BehaviorSubject<CustomerModel>(null);
  _accounts: any;
  customerIPAddress: any;

  constructor(
    private storage: Storage,
    public webservices: WebservicesProvider
  ) {
    // this._customerSubject = new Subject();
  }

  refreshCustomer() {
    let response = new Subject();
    if (this.sessionToken) {
      this.webservices
      .get('auth/player/account', { session_id: this.sessionToken })
      .subscribe((resp: any) => {
        console.log('refreshCustomer resp == ', resp);
        if (resp.code == 200 && resp.payload && resp.payload.customer) {
          this.setCustomer(resp.payload.customer);
          response.next(resp.payload.customer);
        } else {
          this.setCustomer(null);
          response.next(null);
        }
      }, error => {
        response.error(null);
      });
    } else {
      response.error(null);
    }

    return response;
  }

  updateAccount(customer_id: string, newProps: any): Promise<void> {
    return new Promise((resolve, error) => {
      newProps.session_id = this.sessionToken;
      newProps.player_id = customer_id;
      //
      console.warn('user-session.ts: updateAccount() setting');
      this.webservices.post('auth/player/account', newProps).subscribe(
        (response) => {
          resolve(response);
        },
        (err: any) => {
          error(err);
        }
      );
    });
  }

  acceptTerms(customerId) {
    let obj: any = {
      player_id: customerId,
      session_id: this.sessionToken,
    };

    return this.webservices.post('player/term/approve', obj);
  }

  acceptHouseRules(customerId) {
    let obj: any = {
      player_id: customerId,
      session_id: this.sessionToken,
    };

    return this.webservices.post('player/house-policy/approve', obj);
  }

  acceptPrivacyPolicy(customerId) {
    let obj: any = {
      player_id: customerId,
      session_id: this.sessionToken,
    };

    return this.webservices.post('player/privacy-policy/approve', obj);
  }

  public clear() {
    this.sessionToken = null;
    localStorage.removeItem(this.USER_SESSION_STORE_KEY);
    localStorage.removeItem(this.USER_DATA_STORE_KEY);
    this._customerSubject.next(null);
  }

  public isAuthenticated() {
    return !!this.sessionToken;
  }

  public setCustomerId(customerId: any) {
    this.customerId = customerId;
  }

  public getCustomerId() {
    return this.customerId;
  }

  public setSessionToken(sessionToken: any) {
    this.sessionToken = sessionToken;
    localStorage.setItem(this.USER_SESSION_STORE_KEY, sessionToken);
  }

  public getSessionToken() {
    return this.sessionToken;
  }

  public setCustomer(customer) {
    // store json data to keep storage space minimal
    localStorage.setItem(this.USER_DATA_STORE_KEY, JSON.stringify(customer));
    let customerModel = new CustomerModel(customer);
    this._customerSubject.next(customerModel);
  }

  public getCustomer() {
    return this._customerSubject;
  }
  public unsubscribe() {
    this._customerSubject.complete();
  }

  public getCustomerIPAddress() {
    return this.customerIPAddress;
  }

  public setCustomerIPAddress(IPAddress: any) {
    this.storage.set('lastIPAddress', IPAddress);
    this.customerIPAddress = IPAddress;
  }
}
