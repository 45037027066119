import { CashierProvider } from './../../../../cashier/providers/cashier-provider';
import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ResponsibleGamingProvider } from '../../../providers/responsible-gaming-provider';
import { SuccessMessageProvider } from './../../../../../system/providers/success-message-provider';
import { Utils } from '../../../../../shared/utils';
import { Navigator } from './../../../../../system/components/menu/navigator';
import { Config } from '../../../../../system/providers/configuration';
import { IonContent } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'page-set-deposit-limits',
  templateUrl: 'set-deposit-limits.html',
  styleUrls: ['./set-deposit-limits.scss'],
})
export class SetDepositLimitsPage implements OnInit {

  @Input() data: any;

  @ViewChild('Content') content: IonContent;

  depositDailyCurrentLimit = '(No Limit Set)';
  depositDailyDelete: boolean;
  depositDailyRolloverPeriod: boolean;
  depositDailyRolloverPeriodContent: any;
  depositDailyRemoved: boolean;
  depositDailyRemovedContent: any;
  depositDailyAmountUsed: boolean;
  depositDailyAmountUsedContent: any;
  depositDailyNewLimit: boolean = true;
  depositDailyNewLimitError: boolean;
  depositDailyNewLimitContent: any;
  depositDailyNewLimitEffective: boolean;
  depositDailyNewLimitEffectiveContent: any;

  depositWeeklyCurrentLimit = '(No Limit Set)';
  depositWeeklyDelete: boolean;
  depositWeeklyRolloverPeriod: boolean;
  depositWeeklyRolloverPeriodContent: any;
  depositWeeklyRemoved: boolean;
  depositWeeklyRemovedContent: any;
  depositWeeklyAmountUsed: boolean;
  depositWeeklyAmountUsedContent: any;
  depositWeeklyNewLimit: boolean = true;
  depositWeeklyNewLimitError: boolean;
  depositWeeklyNewLimitContent: any;
  depositWeeklyNewLimitEffective: boolean;
  depositWeeklyNewLimitEffectiveContent: any;

  depositMonthlyCurrentLimit = '(No Limit Set)';
  depositMonthlyDelete: boolean;
  depositMonthlyRolloverPeriod: boolean;
  depositMonthlyRolloverPeriodContent: any;
  depositMonthlyRemoved: boolean;
  depositMonthlyRemovedContent: any;
  depositMonthlyAmountUsed: boolean;
  depositMonthlyAmountUsedContent: any;
  depositMonthlyNewLimit: boolean = true;
  depositMonthlyNewLimitError: boolean;
  depositMonthlyNewLimitContent: any;
  depositMonthlyNewLimitEffective: boolean;
  depositMonthlyNewLimitEffectiveContent: any;

  public error = null;

  public depositLimitsForm: FormGroup;

  public deposit_daily_limit: AbstractControl;
  public deposit_weekly_limit: AbstractControl;
  public deposit_monthly_limit: AbstractControl;

  public depositLimits: any[] = [];

  public globalDailyLimit: any;
  public globalWeeklyLimit: any;
  public globalMonthlyLimit: any;

  public globalLimits: any[] = [];
  public previousPage: string = '';

  public confirm: boolean = false;
  public successScreen: boolean = true;

  public deletePrompt: boolean = false;
  public deletePromptScreen: boolean = true;
  public deleteTerm: string;

  public rejectPrompt: boolean = false;
  public rejectPromptScreen: boolean = true;

  public state: string;

  constructor(
    private cashierProvider: CashierProvider,
    private responsibleGamingProvider: ResponsibleGamingProvider,
    public successMessageProvider: SuccessMessageProvider,
    private utils: Utils,
    public events: MyEvent,
    private config: Config,
    public comService: CommonService
  ) {
    this.successMessageProvider.clearMessage();
    this.error = null;
  }

  ngOnInit() {
    let appConfigInfo = this.config.getAppConfigInfo();
    this.state = appConfigInfo.state.toLowerCase();

    this.depositLimitsForm = new FormGroup(
      {
        deposit_daily_limit: new FormControl('', [
          Validators.pattern('^[0-9]*'),
        ]),
        deposit_weekly_limit: new FormControl('', [
          Validators.pattern('^[0-9]*'),
        ]),
        deposit_monthly_limit: new FormControl('', [
          Validators.pattern('^[0-9]*'),
        ]),
      },
      this.atLeastOneFieldValidator
    );

    this.deposit_daily_limit = this.depositLimitsForm.get(
      'deposit_daily_limit'
    );
    this.deposit_weekly_limit = this.depositLimitsForm.get(
      'deposit_weekly_limit'
    );
    this.deposit_monthly_limit = this.depositLimitsForm.get(
      'deposit_monthly_limit'
    );

    this.getGlobalLimits();
    this.getDepositLimits();

    this.previousPage = this.data && this.data.previousPage ? this.data.previousPage : '';
  }

  getGlobalLimits() {
    this.cashierProvider.callGetGlobalPaymentLimits().subscribe(
      (response: any) => {
        console.log("getGlobalLimits = ", response);
        if (response && !!response.result) {
          let tempLimits = response.payload;

          for (let key in tempLimits) {
            if (tempLimits.hasOwnProperty(key)) {
              let obj = tempLimits[key];
              for (let prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                  if (obj[prop].LMT_TRM_IN_DAYS == 'DAILY') {
                    this.globalDailyLimit = obj[prop].LMT_TRM_MAX_AMT;
                    this.deposit_daily_limit.setValidators([
                      Validators.pattern('^[0-9]*'),
                      Validators.max(this.globalDailyLimit),
                    ]);
                  } else if (obj[prop].LMT_TRM_IN_DAYS == 'WEEKLY') {
                    this.globalWeeklyLimit = obj[prop].LMT_TRM_MAX_AMT;
                    this.deposit_weekly_limit.setValidators([
                      Validators.pattern('^[0-9]*'),
                      Validators.max(this.globalWeeklyLimit),
                    ]);
                  } else if (obj[prop].LMT_TRM_IN_DAYS == 'MONTHLY') {
                    this.globalMonthlyLimit = obj[prop].LMT_TRM_MAX_AMT;
                    this.deposit_monthly_limit.setValidators([
                      Validators.pattern('^[0-9]*'),
                      Validators.max(this.globalMonthlyLimit),
                    ]);
                  }
                }
              }
            }
          }

          this.updateDepositLimits(this.depositLimits);
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }

  getDepositLimits() {
    var tempLimits = [];

    this.responsibleGamingProvider.getDepositLimit().subscribe(
      (response: any) => {
        console.log("getDepositLimits = ", response);
        if (response && !!response.result) {
          tempLimits = response.payload;

          for (let index in tempLimits) {
            if (tempLimits[index].limitSource != 'GLOBAL') {
              this.depositLimits.push(tempLimits[index]);
            }
          }

          this.updateDepositLimits(this.depositLimits);
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }

  updateDepositLimits(depositLimits: any[]) {
    this.deposit_daily_limit.setValue('');
    this.deposit_weekly_limit.setValue('');
    this.deposit_monthly_limit.setValue('');

    this.deposit_daily_limit.markAsPristine();
    this.deposit_weekly_limit.markAsPristine();
    this.deposit_monthly_limit.markAsPristine();

    this.depositDailyNewLimitError = false;
    this.depositWeeklyNewLimitError = false;
    this.depositMonthlyNewLimitError = false;

    for (let i in depositLimits) {
      if (depositLimits[i] != null) {
        if (depositLimits[i].currentLimitTermType == 'DAILY') {
          if (
            depositLimits[i].currentLimit != null ||
            depositLimits[i].currentLimit != ''
          ) {
            this.depositDailyCurrentLimit = this.comService.defaulCurrencySymbol + depositLimits[i].currentLimit;
            this.depositDailyDelete = true;
          } else {
            this.depositDailyCurrentLimit = '(No Limit Set)';
            this.depositDailyDelete = false;
          }

          if (
            depositLimits[i].startPeriod != null &&
            depositLimits[i].endPeriod != null
          ) {
            if (depositLimits[i].cumulativeLimitValue == null) {
              depositLimits[i].cumulativeLimitValue = '0';
            }

            this.depositDailyRolloverPeriod = true;
            this.depositDailyRolloverPeriodContent =
              this.utils.toAmericanDateStandardfromYYYY(
                depositLimits[i].startPeriod
              ) +
              ' TO ' +
              this.utils.toAmericanDateStandardfromYYYY(
                depositLimits[i].endPeriod
              );

            this.depositDailyAmountUsed = true;
            this.depositDailyAmountUsedContent = this.comService.defaulCurrencySymbol + depositLimits[i].cumulativeLimitValue;
          }

          if (depositLimits[i].newLimit && depositLimits[i].endPeriod) {
            this.depositDailyDelete = false;
            this.depositDailyNewLimit = true;
            this.depositDailyNewLimitContent = this.comService.defaulCurrencySymbol + depositLimits[i].newLimit;
            this.depositDailyNewLimitEffective = true;
            this.depositDailyNewLimitEffectiveContent =
              this.utils.toAmericanDateStandardfromYYYY(
                depositLimits[i].endPeriod
              );
            this.depositDailyRemoved = false;
          } else if (
            !depositLimits[i].newLimit &&
            !!depositLimits[i].deleteFlag
          ) {
            this.depositDailyDelete = false;
            this.depositDailyNewLimit = true;
            this.depositDailyNewLimitContent = null;
            this.depositDailyNewLimitEffective = false;
            this.depositDailyRemoved = true;
            this.depositDailyRemovedContent =
              this.utils.toAmericanDateStandardfromYYYY(
                depositLimits[i].endPeriod
              );
          }
        }

        if (depositLimits[i].currentLimitTermType == 'WEEKLY') {
          if (
            depositLimits[i].currentLimit != null ||
            depositLimits[i].currentLimit != ''
          ) {
            this.depositWeeklyCurrentLimit = this.comService.defaulCurrencySymbol + depositLimits[i].currentLimit;
            this.depositWeeklyDelete = true;
          } else {
            this.depositWeeklyCurrentLimit = '(No Limit Set)';
            this.depositWeeklyDelete = false;
          }

          if (
            depositLimits[i].startPeriod != null &&
            depositLimits[i].endPeriod != null
          ) {
            if (depositLimits[i].cumulativeLimitValue == null) {
              depositLimits[i].cumulativeLimitValue = '0';
            }

            this.depositWeeklyRolloverPeriod = true;
            this.depositWeeklyRolloverPeriodContent =
              this.utils.toAmericanDateStandardfromYYYY(
                depositLimits[i].startPeriod
              ) +
              ' TO ' +
              this.utils.toAmericanDateStandardfromYYYY(
                depositLimits[i].endPeriod
              );

            this.depositWeeklyAmountUsed = true;
            this.depositWeeklyAmountUsedContent = this.comService.defaulCurrencySymbol + depositLimits[i].cumulativeLimitValue;
          }

          if (depositLimits[i].newLimit && depositLimits[i].endPeriod) {
            this.depositWeeklyDelete = false;
            this.depositWeeklyNewLimit = true;
            this.depositWeeklyNewLimitContent = this.comService.defaulCurrencySymbol + depositLimits[i].newLimit;
            this.depositWeeklyNewLimitEffective = true;
            this.depositWeeklyNewLimitEffectiveContent =
              this.utils.toAmericanDateStandardfromYYYY(
                depositLimits[i].endPeriod
              );
            this.depositWeeklyRemoved = false;
          } else if (
            !depositLimits[i].newLimit &&
            depositLimits[i].deleteFlag == '1'
          ) {
            this.depositWeeklyDelete = false;
            this.depositWeeklyNewLimit = false;
            this.depositWeeklyNewLimitEffective = false;
            this.depositWeeklyRemoved = true;
            this.depositWeeklyRemovedContent =
              this.utils.toAmericanDateStandardfromYYYY(
                depositLimits[i].endPeriod
              );
          }
        }

        if (depositLimits[i].currentLimitTermType == 'MONTHLY') {
          if (
            depositLimits[i].currentLimit != null ||
            depositLimits[i].currentLimit != ''
          ) {
            this.depositMonthlyCurrentLimit = this.comService.defaulCurrencySymbol + depositLimits[i].currentLimit;
            this.depositMonthlyDelete = true;
          } else {
            this.depositMonthlyCurrentLimit = '(No Limit Set)';
            this.depositMonthlyDelete = false;
          }

          if (
            depositLimits[i].startPeriod != null &&
            depositLimits[i].endPeriod != null
          ) {
            if (depositLimits[i].cumulativeLimitValue == null) {
              depositLimits[i].cumulativeLimitValue = '0';
            }

            this.depositMonthlyRolloverPeriod = true;
            this.depositMonthlyRolloverPeriodContent =
              this.utils.toAmericanDateStandardfromYYYY(
                depositLimits[i].startPeriod
              ) +
              ' TO ' +
              this.utils.toAmericanDateStandardfromYYYY(
                depositLimits[i].endPeriod
              );

            this.depositMonthlyAmountUsed = true;
            this.depositMonthlyAmountUsedContent = this.comService.defaulCurrencySymbol + depositLimits[i].cumulativeLimitValue;
          }

          if (depositLimits[i].newLimit && depositLimits[i].endPeriod) {
            this.depositMonthlyDelete = false;
            this.depositMonthlyNewLimit = true;
            this.depositMonthlyNewLimitContent = this.comService.defaulCurrencySymbol + depositLimits[i].newLimit;
            this.depositMonthlyNewLimitEffective = true;
            this.depositMonthlyNewLimitEffectiveContent =
              this.utils.toAmericanDateStandardfromYYYY(
                depositLimits[i].endPeriod
              );
            this.depositMonthlyRemoved = false;
          } else if (
            !depositLimits[i].newLimit &&
            depositLimits[i].deleteFlag == '1'
          ) {
            this.depositMonthlyDelete = false;
            this.depositMonthlyNewLimit = false;
            this.depositMonthlyNewLimitEffective = false;
            this.depositMonthlyRemoved = true;
            this.depositMonthlyRemovedContent =
              this.utils.toAmericanDateStandardfromYYYY(
                depositLimits[i].endPeriod
              );
          }
        }
      }
    }
  }

  deleteLimit(term) {
    this.successMessageProvider.clearMessage();
    this.error = null;

    this.deletePrompt = false;
    this.deletePromptScreen = false;

    var requestObj: any = {};
    requestObj.termType = term.toUpperCase();

    this.responsibleGamingProvider.deleteDepositLimit(requestObj).subscribe(
      (response: any) => {
        if (response && !!response.result) {
          this.getDepositLimits();
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }

  setLimits() {
    this.successMessageProvider.clearMessage();
    this.error = null;

    this.successScreen = false;
    this.confirm = false;

    this.responsibleGamingProvider
      .setDepositLimit(this.depositLimitsForm.value)
      .subscribe(
        (response: any) => {
          console.log('setDepositLimit response: ', response);
          if (response && !!response.result) {
            this.getDepositLimits();

            this.successMessageProvider.setMessage(
              'Your deposit limits were set.'
            );

            this.content.scrollToTop();
          }
        },
        (error) => {
          console.log('setDepositLimit error: ', error);

          switch (error.error_code) {
            case '':
              error.setErrorMessage('');
              break;
          }
          this.error = error;
          this.content.scrollToTop();
        }
      );
  }

  dailyLimitChanged() {
    if (this.globalDailyLimit != null) {
      if (
        parseInt(this.deposit_daily_limit.value) >
        parseInt(this.globalDailyLimit.currentLimit)
      ) {
        this.depositDailyNewLimitError = true;
      } else {
        this.depositDailyNewLimitError = false;
      }
    }
  }

  weeklyLimitChanged() {
    if (this.globalWeeklyLimit != null) {
      if (
        parseInt(this.deposit_weekly_limit.value) >
        parseInt(this.globalWeeklyLimit.currentLimit)
      ) {
        this.depositWeeklyNewLimitError = true;
      } else {
        this.depositWeeklyNewLimitError = false;
      }
    }
  }

  monthlyLimitChanged() {
    if (this.globalMonthlyLimit != null) {
      if (
        parseInt(this.deposit_monthly_limit.value) >
        parseInt(this.globalMonthlyLimit.currentLimit)
      ) {
        this.depositMonthlyNewLimitError = true;
      } else {
        this.depositMonthlyNewLimitError = false;
      }
    }
  }

  atLeastOneFieldValidator(group: FormGroup): { [key: string]: any } {
    let isAtLeastOne = false;
    if (group && group.controls) {
      for (const control in group.controls) {
        if (
          group.controls.hasOwnProperty(control) &&
          group.controls[control].valid &&
          group.controls[control].value
        ) {
          isAtLeastOne = true;
          break;
        }
      }
    }
    return isAtLeastOne ? null : { required: true };
  }

  formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  closeModal = () => {
    if (this.previousPage && this.previousPage == 'SignupResult') {
      setTimeout(() => {
        this.events.publishSomeData({event: 'event:load:sports:signup'});
      }, 500);
    } else {
      Navigator.toLobby();
    }
  };

  onButtonClick() {
    this.confirm = true;
    this.successScreen = true;
    this.successMessageProvider.clearMessage();
  }

  onDeleteLimitClicked(term) {
    this.deletePrompt = true;
    this.deletePromptScreen = true;
    this.deleteTerm = term;
    this.successMessageProvider.clearMessage();
  }

  onDeleteCancel() {
    this.onCancel();
  }

  onDeleteConfirm() {
    this.deleteLimit(this.deleteTerm);
  }

  onRejectClicked() {
    this.confirm = false;
    this.successScreen = false;
    this.rejectPrompt = true;
    this.rejectPromptScreen = true;
    this.successMessageProvider.clearMessage();
  }

  onCancelReject() {
    this.confirm = true;
    this.successScreen = true;
    this.rejectPrompt = false;
    this.rejectPromptScreen = false;
    this.successMessageProvider.clearMessage();
  }

  onRejectConfirm() {
    this.onCancel();
  }

  onCancel() {
    this.confirm = false;
    this.deletePrompt = false;
    this.rejectPrompt = false;
    this.getGlobalLimits();
  }

  onBack = () => {
    if (this.confirm || this.rejectPrompt || this.deletePrompt) {
      this.confirm = false;
      this.deletePrompt = false;
      this.rejectPrompt = false;
      this.getGlobalLimits();
    } else {
      Navigator.back();
    }
  };

  enterPressed() {
    if (!this.depositLimitsForm.invalid) {
      this.onButtonClick();
    }
  }

  preventDefault(event) {
    event.preventDefault();
  }
}
