import { Injectable } from '@angular/core';
import { CustomerModel } from '../../modules/account/models/customer-model';
import {
  AffiliateTrackerInterface,
  AffiliateTrackerCallbackParams,
} from './affiliation-event';
import { GoogleToolsProvider } from './googleTools';

@Injectable({
  providedIn: 'root',
})
export class GoogleTracker implements AffiliateTrackerInterface {
  public customer: CustomerModel;
  protected configVars: any;
  protected params: any;

  constructor(private googleTools: GoogleToolsProvider) {}

  initialize(): Promise<AffiliateTrackerInterface> {
    this.googleTools.setup();
    return Promise.resolve(this);
  }

  login(cbParams: AffiliateTrackerCallbackParams) {
    this.googleTools.loginEvent(cbParams.customer_id);
  }
  signupStep1Complete(cbParams: AffiliateTrackerCallbackParams) {
    this.googleTools.createAccountEvent(cbParams.customer_id);
  }

  signupComplete(cbParams: AffiliateTrackerCallbackParams) {
    this.googleTools.createAccountCompleteEvent(cbParams);
  }

  deposit(cbParams: AffiliateTrackerCallbackParams) {
    this.googleTools.depositEvent(cbParams);
  }

  withdraw(cbParams: AffiliateTrackerCallbackParams) {
    this.googleTools.withdrawalEvent(cbParams);
  }

  gameLaunched(cbParams: AffiliateTrackerCallbackParams) {
    this.googleTools.gameLaunched(cbParams);
  }

  betPlaced(cbParams: AffiliateTrackerCallbackParams) {
    this.googleTools.betPlaced(cbParams);
  }

  conversion(cbParams: AffiliateTrackerCallbackParams) {
    this.googleTools.conversion(cbParams);
  }

  logout() {
    this.googleTools.logout();
  }

  gameClosed() {
    this.googleTools.gameClosed();
  }

  bannerClick(cbParams) {
    this.googleTools.bannerClick(cbParams);
  }

  depositFailed(cbParams: AffiliateTrackerCallbackParams) {
    this.googleTools.depositFailed(cbParams);
  }

  loginError(cbParams: AffiliateTrackerCallbackParams) {
    this.googleTools.loginError(cbParams);
  }

  forgotPassword(cbParams: AffiliateTrackerCallbackParams) {
    this.googleTools.forgotPassword(cbParams);
  }
}
