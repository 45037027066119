import { Component, OnInit } from '@angular/core';
import { AuthenticationProvider } from 'src/app/modules/authentication/providers/authentication-provider';
import * as copy from 'copy-to-clipboard';
import { CommonService } from 'src/app/service/common.service';
import { TranslationService } from 'src/app/service/translation.service';

@Component({
  selector: 'app-get-referral-code',
  templateUrl: './get-referral-code.page.html',
  styleUrls: ['./get-referral-code.page.scss'],
})
export class GetReferralCodePage implements OnInit {

  referralCode: any;
  loaded = false;

  websiteName: any;

  hostName: any;

  constructor(
    public authProvider: AuthenticationProvider,
    public comService: CommonService,
    private transService: TranslationService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.getReferrCode();
    }, 1000);

    this.websiteName = document.querySelector('title').text;

    this.hostName = `${window.location.protocol}//${window.location.host}`;
  }

  getReferrCode() {
    this.authProvider.getReferralCode().subscribe(resp => {
      console.log('refer resp == ', resp);
      this.loaded = true;
      if (resp.code == 200 && resp.result) {
        this.referralCode = resp.payload?.code;
      }
    }, err => {
      console.error('refer err == ', err);
      this.loaded = true;
    });
  }

  async copyReferrCode() {
    if (!this.referralCode) return;

    try {
      // await navigator.clipboard.writeText(address);
      copy(this.referralCode);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
    var msg = await this.transService.getTranslationWord('COMMON.COPIED');
    this.comService.showToast(msg);
  }

  async copyReferrLink(link?) {
    if (!link) return;

    try {
      copy(link);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
    var msg = await this.transService.getTranslationWord('COMMON.COPIED');
    this.comService.showToast(msg);
  }

}
