
import { SystemModule } from './../../../system/system.module';

import { NgModule } from '@angular/core';
import { PopoverContentPage } from './popover/popover';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { UserMenuPageModule } from './user-menu/user-menu.module';

@NgModule({
  declarations: [
    PopoverContentPage
  ],
  imports: [
    SystemModule,
    CommonModule,
    IonicModule,
    UserMenuPageModule
  ],
})
export class PopoverPageModule {}
