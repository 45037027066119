import { TouchID } from '@ionic-native/touch-id/ngx';
import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Navigator } from './../../../system/components/menu/navigator';
import { Config } from './../../../system/providers/configuration';
import { UniqueIDProvider } from '../../../shared/providers/unique-id-provider';
import {
  AuthenticationProvider,
  ILoginResponsePayload,
  LoginResponseComments,
} from './../../authentication/providers/authentication-provider';
import { UserSessionProvider } from '../../../system/providers/user-session';
import { isUndefined } from '../../../shared/codeUtils';
import { CustomerModel } from '../../account/models/customer-model';
import { Utils } from './../../../shared/utils';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';
import { CommonService } from 'src/app/service/common.service';

@Injectable({
  providedIn: 'root',
})
export class TouchUserProvider {

  loginResponse;
  public alert;
  public csrf_token: string = '';
  public touchIDLabel: string = 'Touch/Face ID';
  public state: string;
  public toastMessageQue: any = [];

  constructor(
    public platform: Platform,
    protected storage: Storage,
    public touchID: TouchID,
    public alertController: AlertController,
    private config: Config,
    private uniqueIDProvider: UniqueIDProvider,
    private authenticationProvider: AuthenticationProvider,
    private userSession: UserSessionProvider,
    protected toastController: ToastController,
    public datePipe: DatePipe,
    private events: MyEvent,
    private utils: Utils,
    public comService: CommonService
  ) {}

  ionViewDidEnter() {
    let appConfigInfo = this.config.getAppConfigInfo();
    this.state = appConfigInfo.state;
  }

  onLogin(navigatorParams: any = {}) {
    Navigator.navigateTo('LoginPage', { forceModal: true, cssClass: 'login_modal' }, navigatorParams);
  }

  public checkForTouchID() {
    console.log('checkForTouchID');

    if (this.platform.is('cordova') && this.platform.is('ios')) {
      var rememberUser: string = '';
      var rememberPass: string = '';
      this.storage.get('touch.user').then((user) => {
        if (user) {
          rememberUser = user;
          console.log('we have a touch user!');
          this.storage.get('touch.pass').then((pass) => {
            if (pass) {
              rememberPass = pass;
              console.log('we have a touch pass!');
              this.touchID.isAvailable().then(
                (type) => {
                  console.log('TouchID is available!', type);
                  if (type == 'touch') {
                    this.touchIDLabel = 'Touch ID';
                  } else if (type == 'face') {
                    this.touchIDLabel = 'Face ID';
                  } else {
                    this.touchIDLabel = 'Touch/Face ID';
                  }

                  this.checkForTouchIDChange(rememberUser, rememberPass);
                },
                (msg) => {
                  console.log(
                    'TouchID not available, message: ',
                    JSON.stringify(msg)
                  );
                  this.storage.set('touch.user', null);
                  this.storage.set('touch.pass', null);
                }
              );
            }
          });
        }
      });
    }
  }
  private checkForTouchIDChange(rememberUser, rememberPass) {
    console.log('checkForTouchIDChange');
    this.touchID.didFingerprintDatabaseChange().then((changed) => {
      if (changed) {
        // re-auth the user by asking for his credentials before allowing a fingerprint scan again
        this.onAlertTouchIDChanged();

        this.storage.set('touch.user', null);
        this.storage.set('touch.pass', null);
      } else {
        this.onPromptForTouchID(rememberUser, rememberPass);
      }
    });
  }

  private onAlertTouchIDChanged() {
    if (this.alert != null) {
      this.alert.dismiss();
    }

    this.comService.showAlert(
      `We have noticed a change in your ${this.touchIDLabel} settings. Please login with your full credentials.`,
      `${this.touchIDLabel} Changed`
    );
  }

  private onPromptForTouchID(rememberUser, rememberPass) {
    console.log('onPromptForTouchID');
    if (this.alert != null) {
      this.alert.dismiss();
    }

    this.touchID
      .verifyFingerprintWithCustomPasswordFallbackAndEnterPasswordLabel(
        `Please authenticate with your ${this.touchIDLabel} to login.`,
        'Enter credentials'
      )
      .then(
        (msg) => {
          console.log('finger good! ' + msg);

          this.onRememberMeLogin(rememberUser, rememberPass);
        }, // success handler: fingerprint accepted
        (msg) => {
          console.log('finger not ok: ' + JSON.stringify(msg));

          if (msg.code === -3) {
            this.storage.set('touch.user', null);
            this.storage.set('touch.pass', null);

            setTimeout(this.onLogin.bind(this), 500);
          } else if (msg.code === -8) {
            this.storage.set('touch.user', null);
            this.storage.set('touch.pass', null);

            this.onAlertTouchIDLocked();
          } else {
            this.onAlertToSaveCredentials();
          }
        } // error handler with errorcode and localised reason
      );
  }

  private onRememberMeLogin(rememberUser, rememberPass) {
    console.log('onRememberMeLogin', rememberUser, rememberPass);

    this.utils.setCookieParam('userSession.mfa', 'false');

    let obj: any = {
      username: rememberUser,
      password: rememberPass,
      clientapp: this.config.getClientApp(),
      useTouch: 1,
    };

    const device = this.uniqueIDProvider.getMFADeviceId();
    if (device) {
      obj.device_id = device;
    }

    this.generateCsrfToken().then((csrf) => {
      console.log('csrf', csrf);
      obj.csrf_token = csrf;
      this.authenticationProvider.callLogin(obj).subscribe(
        (response: any) => {
          if (response && !!response.result) {
            this.userSession.setSessionToken(response.payload.sessionToken);
            this.loginResponse = response.payload;

            //Adding Toast Message Queue
            if (
              !isUndefined(response.payload.lastLogoutReason) &&
              (response.payload.lastLogoutReason == 'GAME_CLT_LOGOUT' ||
                response.payload.lastLogoutReason == 'APP_INACTIVITY')
            ) {
              this.toastMessageQue.push('INACTIVITY');
            }

            if ('lastIPAddress' in this.loginResponse) {
              this.storage.set(
                'lastIPAddress',
                this.loginResponse.lastIPAddress
              );
              this.userSession.setCustomerIPAddress(
                this.loginResponse.lastIPAddress
              );
            }

            if ('lastLogoutReason' in this.loginResponse) {
              this.storage.set(
                'lastLogoutReason',
                this.loginResponse.lastLogoutReason
              );
            }

            //Adding BWPA strongAuthentication F5 fix - Store the value in storage and then clear the storage value once user validate the strong auth code
            if ('strongAuthenticationRequired' in this.loginResponse) {
              this.storage.set(
                'strongAuthenticationRequired',
                this.loginResponse.strongAuthenticationRequired
              );
            } else {
              this.storage.set('strongAuthenticationRequired', false);
            }

            const loginInfo = response.payload as ILoginResponsePayload;
            if (
              loginInfo &&
              loginInfo.comments &&
              loginInfo.comments.indexOf(
                LoginResponseComments.CUSTOMER_MFA_REQUIRED
              ) > -1
            ) {
              if (loginInfo.deviceId) {
                this.uniqueIDProvider.setMFADeviceIdAsObservable(
                  loginInfo.deviceId
                );
              }
              this.doMFA();
            } else {
              this.checkLoginActions();
            }
          }
        },
        (error) => {
          switch (error.error_code) {
            case 10224:
              error.setErrorMessage('Form has errors, please see below');
              break;
            case 10223:
              error.setErrorMessage('Form has errors, please see below');
              break;
            case 10078:
            case 10222:
              error.setErrorMessage(
                'Your account was disabled upon too many failed logins, please contact support.'
              );
              break;
            case 10288:
              error.setErrorMessage('Time limit exceeded.');
              break;
            case 10232:
              error.setErrorMessage(
                'You are logging from an unauthorized client app version.'
              );
              break;
            case 10040:
              error.setErrorMessage(
                'Your account has been excluded from this site. Please contact the State of ' +
                  this.state +
                  ' Division of Gaming Enforcement for further information.'
              );
              break;
            case 10054:
              error.setErrorMessage(
                "Your account is not in 'Active' status. Please contact support."
              );
              break;
            case 10047:
              error.setErrorMessage(
                'Your account has been permanently excluded from this site. Please contact support for further information.'
              );
              break;
            case 10048:
              error.setErrorMessage('You have suspended yourself from play.');
              break;
            case 10049:
              error.setErrorMessage(
                'Your account has been temporarily suspended. If this is not resolved in 24 hours please contact support.'
              );
              break;
            default:
              error.setErrorMessage(
                'An error has occurred logging you in. Please try again.'
              );
              break;
          }

          this.storage.set('touch.user', null);
          this.storage.set('touch.pass', null);

          console.log('LOGIN ERROR', error);
        }
      );
    });
  }

  generateCsrfToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.authenticationProvider.getCsrfToken().subscribe((response) => {
        if (
          !isUndefined(response.payload) &&
          !isUndefined(response.payload.token)
        ) {
          this.csrf_token = response.payload.token;
          resolve(this.csrf_token);
        }
      });
    });
  }

  private doMFA = () => {
    this.afterLoginActions();
  };

  private checkLoginActions = () => {
    this.afterLoginActions();
  };

  private afterLoginActions = () => {
    this.userSession.refreshCustomer().subscribe((customer: CustomerModel) => {
      console.log(
        'touch-user-provider.ts: customer.customerId = ' + customer.customerId
      );
      this.events.publishSomeData({
        event: 'event:login',
        data: { customer_id: customer.customerId },
      });
      this.events.publishSomeData({ event: 'event:update:banners' });

      Navigator.toHomePage();

      this.initToastMessages();

      this.events.publishSomeData({ event: 'event:appInit:init' });
    });
  };
  initToastMessages() {
    this.userSession
      .getCustomer()
      .subscribe((customer) => {
        if (customer.lastLoginTime) {
          this.toastMessageQue.push('LASTLOGIN');
        }
        if (!isUndefined(customer.sendEmailUponLogin)) {
          if (customer.sendEmailUponLogin == true) {
            this.toastMessageQue.push('OPTEDINEMAIL');
          }
        }
        this.runToastMessages();
      })
      .unsubscribe();
  }

  runToastMessages() {
    if (this.toastMessageQue.length) {
      let e = this.toastMessageQue[0];
      this.toastMessageQue.shift();
      this.showToastMessages(e);
    }
  }

  showToastMessages(e) {
    let toast;

    switch (e) {
      case 'LASTLOGIN':
        this.userSession
          .getCustomer()
          .subscribe((customer) => {
            let date = this.utils.toAmericanDateStandardfromYYYY(
              customer.lastLoginTime
            );
            this.comService.showToast('Last login time: ' + date);
          })
          .unsubscribe();
        break;
      case 'INACTIVITY':
        this.comService.showToast(
          'Your last session expired due to Inactivity'
        );
        break;
      case 'OPTEDINEMAIL':
        this.comService.showToast(
          'You are opted in to receiving an email upon every login'
        );
        break;
    }

    toast.onDidDismiss(() => {
      this.runToastMessages();
    });
  }

  private onAlertTouchIDLocked() {
    if (this.alert != null) {
      this.alert.dismiss();
    }

    this.comService.showAlert(
      `Your ${this.touchIDLabel} settings have been locked. Please login with your full credentials.`,
      `${this.touchIDLabel} Lock`
    );
  }

  async onAlertToSaveCredentials() {
    if (this.alert != null) {
      this.alert.dismiss();
    }

    this.alert = await this.alertController.create({
      header: `Login with ${this.touchIDLabel}`,
      message: `Would you like us to save your credentials for your next login attempt with ${this.touchIDLabel}?`,
      buttons: [
        {
          text: 'No',
          handler: () => {
            console.log('No clicked');
            //clear stored user and pass
            this.storage.set('touch.user', null);
            this.storage.set('touch.pass', null);
          },
        },
        {
          text: 'Yes',
          handler: () => {
            console.log('Yes clicked');
          },
        },
      ],
    });
    this.alert.present();
  }
}
