import { AuthenticationProvider } from './../../../../authentication/providers/authentication-provider';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Config } from './../../../../../system/providers/configuration';
import { CustomerModel } from './../../../models/customer-model';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Navigator } from './../../../../../system/components/menu/navigator';
import { PASSWORD_PATTERN } from './../../../../../shared/customFormValidators';
import { SuccessMessageProvider } from './../../../../../system/providers/success-message-provider';
import { UserSessionProvider } from './../../../../../system/providers/user-session';
import { Utils } from './../../../../../shared/utils';
import { NavController, NavParams } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { TranslationService } from 'src/app/service/translation.service';

@Component({
  selector: 'page-reset-password',
  templateUrl: 'reset-password.html',
  styleUrls: ['./reset-password.scss'],
})
export class ResetPasswordPage implements OnInit {

  public loginUrl;
  public error: any = null;
  public resetPasswordForm: FormGroup;

  public customer: CustomerModel;

  public passwordGroup: AbstractControl;
  public newPassword: AbstractControl;
  public confirmPassword: AbstractControl;
  public securityGroup: AbstractControl;
  public securityAnswer: AbstractControl;
  public showPasswordReset: boolean;
  public hasToken: boolean;
  public securityQuestionFirst: any;
  public customerid: any;
  public showForm: boolean = true;
  public updateSuccess: boolean = false;
  public showIncomplete: boolean = false;

  mainLogo: any;

  constructor(
    public navCtrl: NavController,
    public authProvider: AuthenticationProvider,
    public userSession: UserSessionProvider,
    public successMessageProvider: SuccessMessageProvider,
    public config: Config,
    public utils: Utils,
    private events: MyEvent,
    private navParams: NavParams,
    public comService: CommonService,
    private transService: TranslationService
  ) {
    this.successMessageProvider.clearMessage();
    this.loginUrl = `${
      this.config.getConfig().LOBBY_URL
    }?r=0#load-dialog=login`;
  }

  ngOnInit() {
    let appConfigInfo = this.config.getAppConfigInfo();
    this.mainLogo = appConfigInfo.main_logo || 'assets/imgs/logo.png';

    this.getToken();

    this.resetPasswordForm = new FormGroup({
      securityGroup: new FormGroup({
        securityAnswer: new FormControl(''),
      }),
      passwordGroup: new FormGroup(
        {
          newPassword: new FormControl('', [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(48),
            Validators.pattern(appConfigInfo.password_pattern || ''),
          ]),
          confirmPassword: new FormControl('', [
            Validators.required,
            Validators.pattern(appConfigInfo.password_pattern || ''),
          ]),
        },
        this.passwordMatchValidator
      ),
    });

    this.securityGroup = this.resetPasswordForm.get('securityGroup');
    this.securityAnswer = this.securityGroup.get('securityAnswer');

    this.passwordGroup = this.resetPasswordForm.get('passwordGroup');
    this.newPassword = this.passwordGroup.get('newPassword');
    this.confirmPassword = this.passwordGroup.get('confirmPassword');
  }

  getToken() {
    let token = this.navParams.get('token');
    console.log('token data == ', token);
    if (token) {
      this.utils.removeUrlParams();
      this.onValidateToken(token);
    } else {
    }
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('newPassword').value === g.get('confirmPassword').value
      ? null
      : { mismatch: true };
  }

  ionViewDidEnter() {
    this.config.setHideSportsLoader(true);
  }

  onValidateToken(token) {
    this.authProvider.callValidateToken(token).subscribe(
      (response: any) => {
        this.successMessageProvider.clearMessage();
        this.error = null;
        if (response.code == 200 && response.payload) {
          this.error = null;
          this.customerid = response.payload.customer_id;
          this.showForm = true;

          if (response.payload.security_questions === null) {
            // If user has only completed step 1 of registration, but not step 2, direct user to customer support
            // Step 2 contains the security question/answer pair, which is not set until step 2 is submitted
            // this.showForm = false;
            this.showIncomplete = true;
          } else if (response.payload.security_questions[0] === null) {
            // Ensure the first question is not null
            // this.showForm = false;
            this.showIncomplete = true;
          } else {
            this.securityQuestionFirst = response.payload.security_questions[0];
            this.customerid = response.payload.customer_id;
            this.hasToken = true;
            this.showForm = true;
          }
        }
      },
      async (error) => {
        switch (error.error_code) {
          case 10207:
            var msg = await this.transService.getTranslationWord('RESET_PASSWORD.MESSAGES.TOKEN_INVALID');
            error.setErrorMessage(msg);
            this.error = error;
            this.showForm = false;
            this.showIncomplete = true;
            break;
          case 10290:
            var msg = await this.transService.getTranslationWord('RESET_PASSWORD.MESSAGES.TOKEN_EXPIRED');
            error.setErrorMessage(msg);
            this.error = error;
            this.showForm = false;
            this.showIncomplete = true;
            break;
        }
      }
    );
  }

  onSubmit() {
    this.successMessageProvider.clearMessage();
    if (!this.showForm) return;

    this.authProvider
      .callResetPassword(this.resetPasswordForm.value, this.customerid)
      .subscribe(
        async (response: any) => {
          this.error = null;
          if (response.code == 200 && response.result) {
            var msg = await this.transService.getTranslationWord('RESET_PASSWORD.MESSAGES.SUCCESS');
            this.successMessageProvider.setMessage(msg);
            this.events.publishSomeData({event: 'event:password:reset', data: {}});
            // this.showForm = false;
            this.updateSuccess = true;

            this.comService.showToast(msg);
            Navigator.back();
            Navigator.navigateTo('LoginPage', { forceModal: true, cssClass: 'login_modal' });
          }
        },
        async (error) => {
          this.successMessageProvider.clearMessage();
          this.showForm = true;
          var msg = await this.transService.getTranslationWord('RESET_PASSWORD.MESSAGES.FAILD');
          error.setErrorMessage(msg);
          this.error = error;
        }
      );
  }

  forgotSecurityAnswers() {
    Navigator.navigateTo(
      'ResetPasswordVerifyStep1Page',
      {},
      { customerId: this.customerid }
    );
  }

  goTologin() {
    window.location.assign(
      `${this.config.getConfig().LOBBY_URL}#load-dialog=login`
    );
    return false;
  }

  passType = 'password';
  changeTypeOfPassword() {
    if (this.passType == 'password') this.passType = 'text';
    else this.passType = 'password';
  }

  cpassType = 'password';
  changeTypeOfCPassword() {
    if (this.cpassType == 'password') this.cpassType = 'text';
    else this.cpassType = 'password';
  }
}
