import { Component, Input, OnInit } from '@angular/core';
import { Navigator } from 'src/app/system/components/menu/navigator';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss'],
})
export class QRCodeComponent implements OnInit {

  @Input() link: any;

  constructor() { }

  ngOnInit() {}

  close() {
    Navigator.back();
  }

}
