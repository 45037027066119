import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/service/common.service';
import { MyEvent } from 'src/app/service/myevent.services';
import { TranslationService } from 'src/app/service/translation.service';
import { Navigator } from 'src/app/system/components/menu/navigator';
import { Config } from 'src/app/system/providers/configuration';
import { ConfigurationProvider } from 'src/app/system/providers/configuration-provider';
import { UserSessionProvider } from 'src/app/system/providers/user-session';
import { WebservicesProvider } from 'src/app/system/providers/webservices';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.page.html',
  styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {

  category: any = 'casino';
  keywords$: Observable<any[]>;
  keywords: any[] = [];

  headerBtn: any;

  footerContent: any;
  contentCampaign: any;

  appConfigInfo: any;

  langs: any[] = [
    {
      "code": "en-US",
      "flag": "US",
      "name": "LEFT_SIDE.LANGS.ENGLISH"
    }
  ];
  currentLang: any;

  sportGames: any[] = [];

  constructor(
    public comService: CommonService,
    private cdr: ChangeDetectorRef,
    private events: MyEvent,
    private configurationProvider: ConfigurationProvider,
    private navCtrl: NavController,
    public sanitizer: DomSanitizer,
    private config: Config,
    private transService: TranslationService,
    private webService: WebservicesProvider,
    private userSession: UserSessionProvider
  ) { }

  ngOnInit() {
    this.appConfigInfo = this.config.getAppConfigInfo();

    this.keywords$ = this.comService.gameKeywordsSubject.asObservable();
    this.keywords$.subscribe(data => {
      this.keywords = data;
      this.cdr.detectChanges();
    });

    if (this.appConfigInfo.languages) {
      this.langs = this.appConfigInfo.languages;
    }

    this.getCurrentLang();
    this.getSportsData();

    this.transService.changeLangSubject.subscribe(lang => {
      this.getCurrentLang();
      this.getSportsData();
    });


    this.configurationProvider.getPageContent('sidebar-footer').subscribe(
      (response) => {
        console.log('sidebar footer == ', response);
        if (response.code == 200 && response.result) {
          this.footerContent = this.sanitizer.bypassSecurityTrustHtml(response.payload);
        }
      },
      (error) => {
        console.error('sidebar footer error == ', error);
      }
    );

    this.configurationProvider.getPageContent('sidebar-campaign').subscribe(
      (response) => {
        console.log('sidebar campaign == ', response);
        if (response.code == 200 && response.result) {
          this.contentCampaign = this.sanitizer.bypassSecurityTrustHtml(response.payload);

          setTimeout(() => {
            var eles = document.querySelectorAll('.open-campaign');
            if (eles.length) {
              eles.forEach(ele => {
                ele.addEventListener('click', () => {
                  var data = ele.getAttribute('data-campaign-id');
                  console.log('data == ', data);
                  Navigator.back();
                  if (data) this.navCtrl.navigateForward(`${this.comService.currentTemplate}/bonus-detail/${data}`);
                  this.events.publishSomeData({event: 'event:bonusdetail'});
                }, { passive: true});
              });
            }

            var spins = document.querySelectorAll('.open-spin');
            if (spins.length) {
              spins.forEach(ele => {
                ele.addEventListener('click', () => {
                  this.openLuckySpin();
                });
              });
            }

            var earns = document.querySelectorAll('.open-earn');
            if (earns.length) {
              earns.forEach(ele => {
                ele.addEventListener('click', () => {
                  this.openPage('earn-interest');
                });
              });
            }
          }, 2000);
        }
      },
      (error) => {
        console.error('sidebar campaign error == ', error);
      }
    );
  }

  ionViewDidEnter() {

  }

  getCurrentLang() {
    var langCode = this.transService.getSelectedLanguage();
    var cLang = this.langs.find(el => el.code == langCode);
    if (cLang) this.currentLang = cLang;
  }

  changeAccordion($event) {
    this.category = $event.target.value;
  }

  close() {
    Navigator.back();
  }

  clickHeader(type) {
    this.headerBtn = type;

    if (this.headerBtn == 'sports') {

      if (this.appConfigInfo.experimental.sports) {

        if (!this.sportGames.length) {
          Navigator.back();
          Navigator.openModal('FootballPage', 'football_modal');
        }
        else {
          Navigator.back();
          var sport = this.sportGames[0];
          sessionStorage.setItem('sport_game_api', sport.sportGameLink);
          this.events.publishSomeData({event: 'event:tabs:load', tab: 'sports'});
        }

      } else {
        Navigator.back();
        Navigator.openModal('FootballPage', 'football_modal');
      }

    } else {
      setTimeout(() => {
        Navigator.back();
        this.events.publishSomeData({event: 'event:tabs:load', tab: type});
        this.events.publishSomeData({event: 'event:main:button'});
      }, 650);
    }

  }


  async selectMenu(menu, index) {
    this.events.publishSomeData({event: 'event:tabs:load', tab: 'dashboard'});
    setTimeout(() => {
      this.events.publishSomeData({event: 'event:casino:item', data: index});
      Navigator.back();
    }, 150);
  }

  lang: any;
  changeAccordionLang($event) {
    this.lang = $event.target.value;
  }

  changeLang(lang) {
    this.currentLang = lang;
    this.transService.setLanguage(lang.code);
    setTimeout(() => {
      this.lang = null;
    }, 100);
  }

  getSportsData() {
    this.webService.get('sports/games').subscribe(response => {
      console.log('sports games == ', response);
      if (response.code == 200) {
        this.sportGames = response.payload;
      }
    }, error => {
      console.error('sports err == ', error);
    });
  }

  goSport(sport?) {
    if (!sport) {

      if (!this.sportGames.length) {
        Navigator.back();
        Navigator.openModal('FootballPage', 'football_modal');
      }
      else {
        Navigator.back();
        sessionStorage.setItem('sport_game_api', this.sportGames[0].sportGameLink);
        this.events.publishSomeData({event: 'event:tabs:load', tab: 'sports'});
      }

    } else {
      if (this.appConfigInfo.experimental.sports) {
        Navigator.back();
        sessionStorage.setItem('sport_game_api', sport.sportGameLink);
        this.events.publishSomeData({event: 'event:tabs:load', tab: 'sports'});
      } else {
        Navigator.back();
        Navigator.openModal('FootballPage', 'football_modal');
      }
    }
  }

  /**
   *
   */
  openLuckySpin() {
    Navigator.back();
    Navigator.navigateTo('SpinWheelComponent', {forceModal: true, cssClass: 'spin-wheel'});
  }

  /**
   *
   */
  openPage(page: any, needLogin = false) {
    if (needLogin && !this.userSession.isAuthenticated()) {
      Navigator.back();
      Navigator.navigateTo("LoginPage", { forceModal: true, cssClass: 'login_modal' });
    }
    else {
      Navigator.back();
      this.navCtrl.navigateForward(`${this.comService.currentTemplate}/${page}`);
    }
  }

}
