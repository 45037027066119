import { PopoverContentPage }           from './pages/popover/popover';

export const PopoverFactory = className => {

    let classFactory: Object = {
        "PopoverContentPage"           : PopoverContentPage
    }

    if(className in classFactory) {
        return classFactory[className];
    }
}
