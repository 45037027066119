// ENGLISH
export const locale = {
  lang: 'zh-CN',
  data:{
    LEFT_SIDE:{
      EARN_CATEGORY_TITLE: '赚币',
      REFERRAL: '推荐奖金',
      VIP: 'VIP等级',
      LANGS:{
        // https://www.omniglot.com/language/names.htm
        ENGLISH: 'English',
        CHINESE: '简体中文',
        PORTUGUES: 'Português',
        THAILIAND: 'ภาษาไทย',
        VIETNAME: 'Tiếng Việt',
        SPANISH: 'Español',
        INDONESIAN: 'Bahasa Indonesia',
        JAPANESE: '日本語'
      },
      INTEREST: "年化收益",
      REFER_EARN: "推荐并赚取佣金",
      VIP_CLUB: '<span style="color: #ffb636;">VIP</span> 等级'
    },
		HEADER:{
      SPORT_BUTTON_TEXT:  '体育',
      CASINO_BUTTON_TEXT: '赌场',
      PROMOTIONS: '活动',
      SEARCH: "搜索",
      SEARCH_PLACEHOLDER: '搜索游戏或提供商',
      SEARCH_PART_GAMES: '游戏',
      SEARCH_PART_PROVIDERS: '提供商',
      SEARCH_RESULT: "搜索结果",
      SEARCH_NO_RESULT: '没有搜索结果',
      SEARCH_TRY_GAMES: "推荐",
      RECOMMENDED_GAMES: "为您推荐",
      LOGIN_BUTTON_TEXT:  '登录',
      REGISTER_BUTTON_TEXT:  '注册',
      BALANCE_INGAME:  '游戏中',
      DEPOSIT_BUTTON_TEXT:  '钱包',
      Settings: "设置",
      ALL_GAMES: "所有游戏",
      SELECT_GAME: "选择游戏"
    },
    BALANCES:{
      FIAT: '法币',
      CRYPTO: '加密货币',
      DISPLAY_FIAT: '以法币显示'
    },
    MOBILE_FOOTER:{
      BETSPLUS: {
        SPORT_TAB_TEXT: '体育',
        CASINO_TAB_TEXT: '赌场',
        PROMOTIONS_TAB_TEXT: '优惠',
        MENU_TAB_TEXT: '菜单'
      }
    },
    DASHBOARD:{
      CATEGORY_LOBBY_NAME: "大厅",
      LAST_PLAYED:  '最近的',
      VIEWALL_BUTTON_TEXT:  '全部',
      VIEWALL_BUTTON_TEXT1: "查看全部",
      ALL_GAMES_COUNT: '共 {{count}}',
      NO_GAMES: '没有游戏数据',
      GAME_PROVIDERS_TITLE: '游戏提供商',
      RECORDS:{
        TITLE:  '记录',
        TABS:{
          MY_BETS_TITLE: '我的投注',
          ALL_BETS_TITLE: '所有投注',
          RARE_WINS_TITLE: '大赢家',
        },
        DATA:{
          BETS_TABLE:{
            COLUMN1: '游戏',
            COLUMN2: '用户名',
            COLUMN3: '时间',
            COLUMN4: '投注金额',
            COLUMN5:'乘数',
            COLUMN6: '派彩'
          },
          WINS_TABLE: {
            COLUMN1: '游戏',
            COLUMN2: '用户名',
            COLUMN3: '投注金额',
            COLUMN4: '乘数',
            COLUMN5: '派彩'
          },
          WINS_TABLE_BETSPLUS:{
            COLUMN1: '排名',
            COLUMN2: '用户名',
            COLUMN3: '投注',
            COLUMN4:'奖品'
          },
          NO_MY_BETS_TEXT: '没有数据',
          NO_ALL_BETS_TEXT: '没有数据',
          NO_RARE_WINS_TEXT: '没有数据'
        }
      }
    },
    AUTH:{
      TOPBAR:{
        LOGIN_BUTTON_TEXT:  '登录',
        REGISTER_BUTTON_TEXT:  '注册'
      },
      LOGIN:{
        TITLE:  '欢迎回来！',
        EMAIL: '电子邮件',
        EMAIL_EMPTY_ERROR: '请输入电子邮件',
        EMAIL_INVALID_ERROR: '请输入有效电子邮件',
        PHONE_NUMBER:  '电话号码',
        PHONE_EMPTY_ERROR: '请输入您的电话号码',
        PHONE_INVALID_ERROR: '请输入一个有效的电话号码',
        PASSWORD: '密码',
        PASSWORD_EMPTY_ERROR: '密码是必需的',
        PASSWORD_LENGTH_ERROR: '密码必须大于6个字符',
        SUBMIT_BUTTON_TEXT: '登录',
        DONT_HAVE_ACCOUNT: '还没有帐户吗？',
        CREATE_NEW_ONE:  '注册',
        FORGOT_PASSWORD_BUTTON_TEXT: '忘记密码？',
        ERRORS:{
          NOT_EXIST: '该邮箱未注册',
          WRONG_PASSWORD: '输入的密码错误',
          TOO_MANY_ATEMP: '您的帐户因登录失败次数过多而被禁用,请联系支持人员。',
          TIME_LIMIT: '超出时间限制。',
          NOT_ACTIVE: '您的帐户未处于活动状态。请联系支持人员。',
          PERMANENT_EXCLUDE: '您的帐户已被永久排除在本网站之外。',
          SUSPENDED: '您已被禁止游戏。',
          TEMPORARY_SUSPENDED: '您的帐户已被暂时停用。 如果24小时内未解决此问题,请联系支持人员。',
          ERROR_OCCURED: '登录失败，请检查您输入的用户名和密码。',
          SUCCESS_LOGIN: '登录成功',
          INACTIVITY: '您的上次会话由于不活动而过期'
        },
        USING_SOCIAL: '通过以下方式登录'
      },
      SIGNUP:{
        TITLE: '在{{sitename}}注册',
        EMAIL: '电子邮件',
        EMAIL_EMPTY_ERROR: '请输入电子邮件',
        EMAIL_INVALID_ERROR: '请输入有效电子邮件',
        EMAIL_ALREADY_USED_ERROR: '该电子邮件地址已被使用。 如果您尝试完成注册, 请登录并从上次中断的地方继续。',
        PHONE_NUMBER: '电话号码',
        PHONE_EMPTY_ERROR: '请输入您的电话号码',
        PHONE_INVALID_ERROR: '请输入一个有效的电话号码',
        PHONE_ALREADY_USED_ERROR: '该电话号码已被使用。如果您正在尝试完成注册，请登录以继续您之前的操作.',
        VERIFICATION_CODE: '验证码',
        VERIFY_BUTTON_TEXT: '验证',
        GET_CODE_TXT: '获取代码',
        CODE_SENT: '代码已发送',
        RESEND: '重新发送',
        PASSWORD: '密码',
        PASSWORD_EMPTY_ERROR: '密码是必填的',
        PASSWORD_LENGTH_ERROR: '密码必须大于6个字符',
        PASSOWRD_PATTERN_ERROR: '密码应至少包含1个数字, 1个小写字母, 1个大写字母和1个特殊非字母字符如 $#!@-_',
        REFERRAL_CODE: '推荐/优惠代码（可选）',
        REFERAL_CODE_ERROR: '优惠代码无效',
        TERMS_DESC: '注册即表示您同意',
        TERMS_BUTTON_TEXT: '条款和服务',
        SUBMIT_BUTTON_TEXT: '开始',
        ALREADY_HAVE_ACCOUNT: '已有账户？',
        SIGNIN_BUTTON_TEXT: '登入',
        ERRORS:{
          ALREADY_EXIST: '该电子邮件已存在',
          NOT_CREATED: '无法创建帐户,请联系在线客服。'
        },
        VERIFY_CODE_REQUIRED: '需要验证码',
        VERIFICATION_CODE_LENGTH_ERROR: '验证码必须为6位数字',
        VERIFICATION_WRONG_CODE: '验证码错误',
        SENT_VERIFY_EMAIL: '我们已发送电子邮件进行验证',
        SENT_VERIFY_SMS: "我们已发送短信验证码以进行验证",
        USING_SOCIAL: '通过以下方式注册',
        CAPTCHA: "验证码",
        CAPTCHA_REQUIRED: "需要进行验证码验证",
        CAPTCHA_PATTERN_ERROR: "请输入有效的验证码",
        CAPTCHA_WRONG: "验证码无效，请再试一次"
      },
      FORGOT_PASSWORD:{
        TITLE: '忘记密码？',
        DESCRIPTION: '忘记密码？',
        EMAIL: '电子邮件',
        EMAIL_EMPTY_ERROR: '请输入电子邮件',
        EMAIL_INVALID_ERROR: '请输入有效电子邮件',
        PHONE_NUMBER: '电话号码',
        PHONE_EMPTY_ERROR: '请输入您的电话号码',
        PHONE_INVALID_ERROR: '请输入一个有效的电话号码',
        VERIFICATION_CODE: '验证码',
        VERIFY_BUTTON_TEXT: '验证',
        SUBMIT_BUTTON_TEXT: '提交',
        SUCCESS_MSG: '重置电子邮件链接已成功发送',
        VERIFICATION_CODE_LENGTH_ERROR: "验证码必须为 8 位字符",
        SUCCESS_RESET_MSG: '您的密码已成功重置。请使用新密码重新登录'
      },
      LOGOUT:{
        TITLE: '退出登录',
        MULTI_DEVICE: '由于多设备登录,您已退出登录',
        INACTIVITY: '由于不活动,您已退出登录',
        DEFAULT: '您现在已退出登录'
      },
      OR: '其他登录方式',
      GOOGLE_BUTTON_TEXT: '谷歌'
    },
    DEPOSIT: {
      CURRENCY_TITLE: '存款货币',
      CHOOSE_PAYMENT_TITLE: '选择支付方式',
      AMOUNT: '数量',
      INSTANT: '即时到账',
      MIN: '最小',
      MAX: '最大',
      SUBMIT_BUTTON_TEXT: '存款',
      NEED_HELP: '需要帮忙？',
      CONTACT_SUPPORT: '联系我们的在线客服',
      CRYPTO_DEPOSIT_TITLE: '存款',
      CRYPTO_DEPOSIT_DESCRIPTION: '这是您的私人存款地址。 您存入的任何存款将在区块链确认后显示在您的余额中。 存款到账后, 我们将通过电子邮件通知您。',
      MINIMUM_DEPOSIT_AMOUNT: '最低存款金额为 {{amount}} {{currency}}',
      SEND_MONEY_TO: '将任意数量的 {{currency}}（加上矿工费）发送至:',
      NO_PAYMENT_METHOD: '没有可用的支付方式',
      ADDRESS_COPIED: "复制并存入",
      CLUB_PAY_TYPE: "支付方式"
    },
    WITHDRAW:{
      CLUB_PAY_TYPE: "支付方式",
      CURRENCY_TITLE: '提款货币',
      CHOOSE_PAYMENT_TITLE: '选择支付方式',
      INFO_TITLE: '提款信息',
      BANK_CODE_TITLE: '银行代码',
      BANK_NAME_TITLE: '收款人姓名',
      ACCOUNT_TYPE: "账户类型",
      BANK_ACCOUNT_TITLE: '收款人帐户',
      AMOUNT: '金额',
      INSTANT: '即时到账',
      MIN: '最小',
      MAX: '最大',
      SUBMIT_BUTTON_TEXT: '提款',
      NEED_HELP: '需要帮忙？',
      CONTACT_SUPPORT: '联系我们的在线客服',
      EXIST_ACCOUNT_TITLE: '现有账户',
      ADDRESS: '地址',
      WITHDRAW_FEE_ESTIMATED: '提款费用预计为 {{amount}} {{currency}}',
      MINIMUM_WITHDRAW_AMOUNT: '最低提款金额为 {{amount}} {{currency}}',
      NOT_DEPOSIT_YET: '请先存入押金',
      WITHDRAW_DEDUCT_DESC: '提现将扣除10%的手续费',
      COMPLETE_EMAIL_VERIFY: '出于安全考虑,提现前请完成邮箱验证',
      VERIFY_EMAIL_BUTTON: '确认电子邮件地址',
      COMPLETE_PHONE_VERIFY: '出于安全原因，请在提现之前完成手机验证',
      VERIFY_PHONE_BUTTON: '验证电话号码',
      COMPLETE_EMAIL_PHONE_VERIFY: '出于安全原因，请在提现之前完成电子邮件和手机验证',
      VERIFY_EMAIL_PHONE_BUTTON: '立即验证',
      COMPLETE_PROFILE: '出于安全原因,请在提款前填写您的个人资料信息',
      ACCOUNT_BUTTON: '帐户',
      WITHDRAW_SUCCESS: '提款已提交, 请留意您的收款账户,谢谢',
      HOME_BUTTON: '首页',
      RESTRICTED_BALANCE: '您的余额有 {{currency}} {{amount}} 受限。',
      RESTRICTED_BALANCE_VIEW: '查看',
      NO_PAYMENT_METHOD: '没有可用的支付方式',
      IFSC_TITLE: 'IFSC号码',
      INVALID_AMOUNT_ERROR: {
        EMPTY_AMOUNT: '请输入金额',
        LESS_ZERO: '金额不能低于零',
        LESS_MIN: '金额低于最低值',
        GREATER_MAX: '超出最大值',
        GREATER_BALANCE: '超出余额',
        GREATER_AVAILABLE: '余额不足'
      },
      ADDRESS_REQUIRED: "需要填写地址",
      ADDRESS_PATTERM_ERROR: "地址不正确"
    },
    GAME_GALLEY:{
      NO_GAME_DATA: '您的列表中没有游戏。请单击主页按钮并享受众多游戏。',
      DISPLAY_COUNT_GAMES: '{{showCount}} 款游戏, 共 {{totalCount}} 款游戏'
    },
    GAME:{
      LOADING: '加载中',
      PLAY_BUTTON_WITHOUT_LOGIN: '登录',
      PLAY_BUTTON_WITH_LOGIN: '玩',
      DEMO_PLAY_BUTTON: "试玩",
      RTP: 'RTP',
      RECENT_BIGGEST_WIN: '最近大赢家',
      MY_GAMES_TITLE: '我的游戏'
    },
    ACTIVE_ACCOUNT:{
      TITLE: '验证邮件',
      EMAIL_VERIFY_DESCRIPTION: '请登录您的邮箱,点击邮件中的链接完成验证',
      GET_CODE_TEXT: '发送验证码',
      RESEND_BUTTON_TEXT: '重发',
      SUBMIT_BUTTON_TEXT: '提交',
      NOT_RECEIVE_TXT: "没有收到验证码？",
      CODE: '验证码',
      CODE_INPUT_PLACEHOLDER: '在这里输入验证码'
    },
    EMAIL_VERIFY:{
      DISPLAY_INFO: '现在正在验证。 请稍等...',
      ERROR_TITLE: '抱歉,出现问题。',
      VERIFY_ERROR: '未找到电子邮件代码',
      SUCCESS_TITLE: '成功！',
      VERIFY_SUCCESS: '您的电子邮件已成功验证'
    },
    BONUSES: {
      BONUSES_TITLE: '优惠',
      BONUS_TITLE: '奖金',
      NO_BONUSES_DATA: '目前没有优惠活动',
      CHECK_LATER: '敬请期待！',
      MORE_DETAIL_BUTTON_TEXT: '了解更多',
      APPLY_BUTTON_TEXT: '现在申请',
      TAB1: '赌场',
      TAB2: '体育',
      ENDS_AT: '结束时间:',
      EVENT_ENDED: '活动已结束'
    },
    EMPTY_PAGE:{
      NOT_FOUND: '哎呀..找不到页面。',
      REFRESH_OR_GOHOME: '请刷新页面或点击下方返回首页',
      ACTION_BUTTON_TEXT: '首页'
    },
    FOOTBALL_PAGE:{
      TITLE: '体育',
      DESCRIPTION: '体育博彩即将推出。 敬请期待！',
      ACTION_BUTTON_TEXT: '首页'
    },
    LOGOUT_CONFIRM:{
      TITLE: '登出',
      DESCRIPTION: '在您离开之前,别忘了查看我们精选的赌场和体育优惠活动！',
      BUTTON_TEXT: '登出'
    },
    ACCOUNT_INFO: {
      UID: 'UID',
      USERNAME_TITLE: '用户名',
      EMAIL_TITLE: '电子邮件',
      PHONE_TITLE: '电话号码',
      PHONE_PLACEHOLDER: '添加手机号码',
      PASSWORD_TITLE: '密码',
      EDIT_BUTTON_TEXT: '编辑',
      VERIFY_BUTTON_TEXT: '验证',
      ADD_EMAIL: '添加电子邮件',
      COPY_BUTTON_TXT: '复制',
      SUMMARY_TEXT: ''
    },
    ADD_EMAIL:{
      TITLE: '添加电子邮件',
      DESCRIPTION: '添加您的电子邮件以确保帐户安全',
      EMAIL_PLACEHOLDER: '电子邮件',
      VERIFICATION_CODE: '验证码',
      VERIFY_BUTTON_TEXT: '验证',
      SUBMIT_BUTTON_TEXT: '保存'
    },
    CHANGE_PASSWORD:{
      TITLE: '更改密码',
      CURRENT_PW_PLACEHOLDER: '当前密码',
      CURRENT_PW_REQUIRED: '请输入您当前的密码',
      CURRENT_PW_WRONG: '插入的当前密码错误',
      NEW_PW_PLACEHOLDER: '新密码',
      NEW_PW_REQUIRED: '请输入新密码',
      NEW_PW_LENGTH_ERROR: '密码必须至少6个字符',
      CONFIRM_PW_PLACEHOLDER: '重复新密码',
      CONFIRM_PW_REQUIRED: '请确认您的新密码',
      NEW_PW_NOT_MATCH: '输入的密码不匹配',
      NEW_PW_NOT_CURRENT: '新密码不能与您当前的密码匹配',
      SUBMIT_BUTTON_TEXT: '保存',
      MESSAGES:{
        SUCCESS: '您的密码已被更改',
        WRONG_CURRENT_PASSWORD: '您输入的当前密码不正确。',
        NEW_NOT_MATCH_CURRENT: '您的新密码不能与您当前的密码匹配。'
      }
    },
    EDIT_USERNAME:{
      TITLE: '编辑用户名',
      PLACEHOLDER_TEXT: '用户名',
      SUBMIT_BUTTON_TEXT: '保存',
      MESSAGES:{
        USERNAME_REQUIRED: '请输入用户名。',
        USERNAME_LENGTH: '用户名长度必须大于3个字符'
      }
    },
    RESET_PASSWORD:{
      TITLE: '重设密码',
      DESCRIPTION: '重设密码',
      PASSWORD_PLACEHOLDER: '密码',
      PW_LENGTH_ERROR: '密码必须至少6个字符。',
      PW_REQUIRED: '请输入新密码。',
      PW_PATTERN_ERROR: '密码必须包含数字、大写字母和符号。',
      CONFIRM_PW_PLACEHOLDER: '再次确认密码',
      CONFIRM_PW_REQUIRED: '请确认您的新密码。',
      CONFIRM_PW_MISMATCH: '密码不匹配。',
      NEW_PW_NOT_CURRENT: '您的新密码不能与您当前的密码匹配。',
      SUBMIT_BUTTON_TEXT: '重置',
      MESSAGES:{
        TOKEN_INVALID: '提供的令牌无效',
        TOKEN_EXPIRED: '提供的令牌已过期',
        SUCCESS: '您的密码已重置。 请使用密码登录',
        FAILD: '重置密码失败。请再试一次'
      }
    },
    UPDATE_PHONE:{
      TITLE: '编辑电话号码',
      DESCRIPTION: '添加您的电话号码以确保帐户安全',
      PLACEHOLDER: '仅限数字',
      PHONE_REQUIRED: '请输入您的电话号码',
      PHONE_INVALID: '请输入一个有效的电话号码',
      VERIFY_CODE: '验证码',
      VERIFY_BUTTON_TEXT: '验证',
      SUBMIT_BUTTON_TEXT: '提交',
      MESSAGES:{
        FAILD: '尝试更新电话号码时出现问题。请联系在线客服。'
      }
    },
    ACTIVE_BONUSES:{
      TITLE: '当前奖金',
      NO_DATA: '您目前没有任何有效的奖金。',
      SHOW_RESULT: '点击奖金即可查看有关它的完整详细信息',
      BONUS_ACCOUNT: '奖金账户',
      DESC1: '有时您可能会获得奖金作为某项操作的特殊奖金,例如存款。',
      DESC2: '总受限金额（您的存款+奖金金额）仅限于游戏下注。一旦满足下注要求,总受限金额将被释放,并成为您真实货币余额的一部分。',
      TABLE_HEADER:{
        COLUMN1: '总奖金',
        COLUMN2: '剩余奖金',
        COLUMN3: '参考',
        COLUMN4: '开始日期',
        COLUMN5: '过期日期',
        COLUMN6: '状态',
        COLUMN7: '受限金额'
      }
    },
    PAST_BONUSES:{
      TITLE: '过去的奖金',
      DESCRIPTION: '在下面您可以看到您之前收到的奖金。请选择日期范围:',
      FROM_DATE_LABEL: '从',
      INPUT_DATE_PLACEHOLDER: '选择日期',
      TO_DATE_LABEL: '到',
      SUBMIT_BUTTON_TEXT: '提交',
      NO_RESULT: '未找到结果。',
      SHOW_RESULT: '选择下面的奖金以查看更多详细信息:',
      ACTIVE: '进行中',
      FORFEITED: '已放弃',
      RELEASED: '完成',
      EXHAUSTED: '耗尽',
      EXPIRED: '过期',
      TABLE_HEADER:{
        COLUMN1: '参考',
        COLUMN2: '总奖金',
        COLUMN3: '开始日期',
        COLUMN4: '过期日期',
        COLUMN5: '状态',
      }
    },
    BONUS_DETAIL:{
      ACTIVE: '激活',
      EXPIRED: '已过期',
      EXHAUSTED: '已用尽',
      RELEASED: '已发布',
      RELEASE: '发布',
      FORFEITED: '已取消',
      TITLE: '奖金 - 详细信息',
      TERMS_SERVICE: '条款和条件',
      BONUS: '奖金',
      START_DATE: '开始日期',
      EXPIRED_DATE: '过期日期',
      PROGRESS: '进度',
      CASINO: '赌场',
      SPORTS: '体育',
      LIVE: '真人',
      VALID_GAMES: '有效游戏',
      ROLLOVER: '投注要求',
      WAGERS_REMAIN: '剩余投注',
      RESTRICT_AMOUNT: '受限金额',
      WINNINGS: '赢得金额',
      DEPOSIT: '存款',
      TOTAL_RESTRICT_AMOUNT: '总受限金额',
      FORFEIT_BUTTON_TEXT: '放弃我的奖金',
      FORFEIT_DESC1: '如果您放弃此奖金,我们将删除:',
      FORFEIT_DESC2: '{{currency}} {{writing}} 的受限奖金。',
      FORFEIT_DESC3: '{{currency}} {{bonus}} 的受限奖金。',
      FORFEIT_DESC4: '这将从您的存款中扣除 {{currency}} {{deposit}}。',
      FORFEIT_DESC5: '您确定要放弃这笔奖金吗？',
      CANCEL_BUTTON_TEXT: '取消',
      CONFIRM_BUTTON_TEXT: '是的,放弃奖金',
      ERRORS:{
        CLOSE_OPENED_GAMES: '请关闭所有打开的游戏,否则将失去奖金。',
        RESOLVE_SPORTS_GAME: '一项体育赛事从该奖金中保留了资金 - 请先等待其解决。',
        RESOLVE_SAVED_GAME: '已保存的游戏从该奖金中保留了资金 - 请先解决它。'
      },
      APPLY_BUTTON_TXT: {
        DEFAULT: '立即存款',
        LABEL1: '立即申请',
        LABEL2: '立即存款',
        LABEL3: '立即游戏',
        LABEL4: '立即兑换',
        LABEL5: '更多详情'
      }
    },
    REDEEM_BONUS:{
      TITLE: '优惠码',
      DESCRIPTION: "加入{{name}} 社区并获取优惠码!",
      INPUT_LABEL: '奖金代码:',
      INPUT_PLACEHOLDER: '优惠码',
      SUBMIT_BUTTON_TEXT: '赎回',
      FAILD: '奖金代码错误或已过期,请检查您的输入是否正确。',
      NOVALID: "优惠码不可用",
      SUCCESS: "优惠码已成功使用",
      SUCCESS_BUTTON_TXT: "完成"
    },
    REFER_FRIEND:{
      TITLE: '邀请好友',
      SUBTITLE1: '邀请好友赚钱',
      DESCRIPTION1: '您推荐的每个朋友将给您带来现金奖金 {{website}}',
      TOTAL_REFERRED: '您推荐的总用户',
      SUBTITLE2: '分享您的邀请码',
      DESCRIPTION2: '您的邀请码'
    },
    SPORTS_PAGE:{
      LOADING: '加载中'
    },
    GAME_HISTORY:{
      TITLE: '游戏历史',
      TABLE:{
        COLUMN1: '游戏',
        COLUMN2: '投注结果',
        COLUMN3: '时间',
        COLUMN4: '投注金额',
        COLUMN5: '乘数',
        COLUMN6: '支付'
      },
      NO_HISTORY: '没有游戏历史',
      SHOW_DATA_DESC: '只可查询最近50场比赛记录',
      TOTAL: '全部的',
      WIN: '赢',
      LOSS: '输',
      DRAW: '平局',
      CANCEL: '取消',
      UNSETTLED: '未结算',
      MOBILE:{
        CASH: '老虎机',
        BET_AMOUNT: '投注金额',
        MULTIPLIER: '乘数',
        PAYOUT: '派彩'
      },
      SHAREING: {
        SUCCESS: {
          TITLE1: '不仅仅是赢得奖品,',
          TITLE2: '在这里赚取额外的代币和红利.',
          BET_AMOUNT: '通过投注',
          MULTIPLIER: "倍率{{value}}x",
          PLACED_USER: '由: {{username}}下单',
          REFERRAL_CODE: '推荐码',
          CTA_SENTENCE2: '立即分享 & 赢取彩票奖励',
          DOWNLOAD_BTN: '下载',
          SHARE_BTN: '分享'
        },
        FAILD: {
          TITLE: '分享失败',
          ALREADY_SHARED_GAME_LOG: '此赢奖已被其他人分享过.',
          ABOVE_ONE_MULTIPLIER: '仅倍率高于 1x 的交易可以分享.',
          EXCEEDED_DAILY: "您已达到每日分享限制."
        }
      }
    },
    LEADERBOARD:{
      TITLE: '排行榜',
      TABLE:{
        COLUMN1: '',
        COLUMN2: '',
        COLUMN3: '玩家',
        COLUMN4: '$FUN 代币总额',
        COLUMN5:  '报酬'
      },
      NO_DATA: '没有数据'
    },
    NEW_BANK:{
      TITLE: '银行信息',
      PAYMENT_TYPE: '支付方式',
      SELECT_PAYMENT_TYPE: '选择支付类型',
      BANK_LIST: '银行名单',
      SELECT_BANK: '选择银行',
      NAME: '全名',
      ACCOUNT_NUMBER: '帐号',
      ACCOUNT_NUMBER_DESC: '仅限 1 到 17 位数字',
      SUBMIT_BUTTON_TXT: '提交',
      ADD_NEW_BUTTON_TXT: '新增',
      CLOSE_BUTTON_TXT: '关闭'
    },
    PAYMENTS:{
      DEPOSIT_TXT: '存款',
      WITHDRAW_TXT: '提取'
    },
    PROFILE_INFO:{
      TITLE: '个人信息',
      FIRST_NAME: '名',
      FIRST_NAME_REQURIED_ERROR: '请输入您的合法名字。',
      FIRST_NAME_PATTERN_ERROR: '名字只能包含字母字符、空格、连字符和句点。请输入至少 2 个、最多 20 个字符。',
      LAST_NAME: '姓',
      LAST_NAME_REQUIRED_ERROR: '请输入您的合法姓氏。',
      LAST_NAME_PATTERN_ERROR: '姓氏只能包含字母字符、空格、连字符和句点。请输入至少 2 个、最多 20 个字符。',
      BIRTHDAY:{
        TITLE: '出生日期',
        YEAR: '年',
        MONTH: '月',
        DAY: '天',
        ERROR: '请输入有效的出生日期。'
      },
      ADDRESS:{
        STREET: '街道地址',
        STREET_REQURIED_ERROR: '请输入您的街道地址。',
        PO_BOX_ERROR: '不能使用邮政信箱。',
        COUNTRY: '国家',
        STATE: '状态',
        CITY: '城市',
        CITY_REQURIED_ERROR: '请输入您的城市。',
        CITY_PATTERN_ERROR: '请输入有效的城市名称。 城市不应包含数字。',
        ZIPCODE: '邮政编码',
        ZIPCODE_REQUIRED_ERROR: '请输入您的邮政编码。',
        ZIPCODE_PATTERN_ERROR:'请仅输入字母和数字。 无空格或特殊字符。',
        ZIPCODE_MINLENGTH_ERROR: '邮政编码必须至少为 5 个字符'
      },
      SUBMIT_BUTTON_TXT: '提交',
      CHOOSE_FILE: '身份证明/选择文件',
      FILE_NOTE: "*接受的文件：护照、驾驶执照或身份证.",
      ALERT: {
        SUCCESS: {
          TITLE: '验证',
          MSG: '更新成功'
        }
      }
    },
    PROVIDERS:{
      TITLE: '游戏提供商',
      GAMES_LENGTH: '{{count}} 款游戏',
      GAMES: '款游戏',
      CURRENT_DISPLAYING: ' {{count}} 家提供商,共 {{total}} 家'
    },
    TERMS:{
      TITLE: '条款和条件',
      PRINT_BUTTON_TXT: '打印',
      DOWNLOAD_BUTTON_TXT: '下载'
    },
    TRANSACTIONS:{
      TITLE: '交易',
      DEPOSIT_BUTTON_TXT: '存款',
      WITHDRAW_BUTTON_TXT: '提取',
      BONUS_BUTTON_TXT: '奖金',
      NO: '没有',
      SHOW_DATA_DESC: '只可查看最近 50 笔交易',
      TOTAL: '全部的',
      deposit: '存款',
      withdraw: '提取',
      bonuses: '奖金',
      STATE: {
        CONFIRMED: "成功",
        UNCONFIRMED: "待确认",
        UNSUCCESSFUL: "失败"
      },
      DEPOSIT_TABLE:{
        COLUMN1: '订单 ID',
        COLUMN2: '时间',
        COLUMN3: '状态',
        COLUMN4: '货币',
        COLUMN5: '存款',
        COLUMN6: '初始余额',
        COLUMN7: '余额',
        COLUMN8: 'Amount'
      },
      WITHDRAW_TABLE:{
        COLUMN1: '订单 ID',
        COLUMN2: '时间',
        COLUMN3: '状态',
        COLUMN4: '货币',
        COLUMN5: '提取',
        COLUMN6: '初始余额',
        COLUMN7: '余额',
        COLUMN8: 'Amount'
      },
      BONUSES_TABLE:{
        COLUMN1: '类型',
        COLUMN2: '订单 ID',
        COLUMN3: '时间',
        COLUMN4: '奖金金额',
        COLUMN5: '奖金名称'
      }
    },
    USER_MENU:{
      WALLET: '钱包',
      TRANSACTIONS: '交易',
      GAME_HIISTORY: '游戏历史',
      KYC_INFO: 'KYC',
      VIP: 'VIP俱乐部',
      REDEEM_COUPON: '兑换优惠券',
      REFERRAL: '介绍朋友',
      PROMOTIONS: '优惠',
      ACTIVE_BONUS:'当前奖金',
      PAST_BONUS: '过去的奖金',
      LIVE_SUPPORT: '在线客服',
      ACCOUNT: '用户资料'
    },
    STEP_COMPONENT:{
      STEP_TXT: '步骤'
    },
    PLAYER_VIP:{
      MY_DATA:{
        VIP_PROGRESS: '您的 VIP 进度',
        TOOLTIP: '每 1 美元的投注可为您赢得 1XP。 你玩的越多,达到最高排名的速度就越快。',
        RAKEBACK: '返水',
        RANK_UP_BUTTON_TXT: '提升等级',
        TOTAL_CLAIMED: '要求的总返水',
        CLAIM_BUTTON_TXT: '宣称',
        REMAIN_UNTIL_NEXT: '距离 VIP {{level}} 还有 {{xp}} XP',
        REACHED_MAX_LEVEL: '你已经达到最高等级了！'
      },
      BETSPLUS: {
        LEVEL: "等级",
        REQUIRED_XP: '所需经验值 {{amount}} {{currency}}',
        LEVELUP_DESC: '每次填满进度条都能获得奖励。升级将使您获得更大更好的奖励!',
        WITHDRAWN_AMOUNT: '可提取金额',
        TOTAL_REWARDS: '可用总奖励',
        EXCHANGE_BUTTON_TXT: '兑换',
        CLAIM_BUTTON_TXT: '领取',
        REWARD_REDEMPTION: "奖励兑换",
        TOTAL_CLAIMED: '已领取总奖励',
        UNCLAIMABLE_MSG: '领取需要最少 {{currency}} {{amount}} 奖金',
        REWARD: "奖励"
      }
    },
    REFERRALS:{
      MY_REFERRALS:{
        TOTAL_COMMISSION_EARNING: '推荐佣金总收入',
        REFERRED_YOU: '谁推荐了你',
        AMOUNT_REFERRED: '您推荐的用户数量',
        NUMBER_OF_USERS: "用户数量"
      },
      BETSPLUS: {
        TITLE: '推荐好友并赚取佣金',
        DESCR1: '您推荐的每一位好友都将为您带来丰厚的佣金',
        DESCR2: '',
        HOWTOWORK: '如何运作',
        TOOLTIP_DETAIL: '步骤1：将推荐码分享给朋友和社交媒体. <br> 步骤2：引导他们注册. <br> 步骤3：引导他们进行首次存款. <br> 步骤4：领取并使用您的推荐奖励. <br> 步骤5：重复以上步骤!',
        INVITE_SECT: {
          TITLE: '邀请用户',
          SHARES: '分享次数',
          INVITEES: '被邀请者',
          INVITE_LINK: '邀请链接',
          INVITE_QR_CODE: '邀请二维码',
          QUICK_SHARE: '快速分享',
          DOWNLOAD_BTN: '下载'
        },
        DATA_CENTER: {
          TITLE: '数据中心',
          TOTAL_REVENUE: '总收入',
          INVITATION: '邀请',
          WAGER: '投注',
          ACHIEVEMENT: '成就',
          VIP: 'VIP',
          MORE_BTN: '更多'
        },
        REWARD_SECT: {
          TITLE: '奖励兑换',
          AMOUNT_WITHDRAWN: '可领取金额',
          EXCHANGE_BUTTON_TXT: '兑换',
          CLAIM_BUTTON_TXT: '领取',
          TOTAL_REWARD: '可用总奖励 : {{value}}'
        },
        ACTIVITY_RULES: {
          TITLE: '活动规则',
          SEE_MORE: '查看更多',
          DATA1: {
            LABEL: '邀请奖金',
            TEXT: '每次邀请一位存款达到 20 美元的朋友，您将获得 5 美元的奖励.'
          },
          DATA2: {
            LABEL: '投注佣金',
            TEXT: '奖金规则：所有在“推广者”级别的玩家，每次投注都会获得平台优势奖金的一定百分比.'
          },
          DATA3: {
            LABEL: '成就奖金',
            TEXT: '如果累计邀请人数满足条件，您将获得额外的成就奖励。<br> 邀请的人越多，奖励越高.'
          },
          DATA4: {
            LABEL: 'VIP 升级奖金',
            TEXT: '每当您邀请的用户在游戏中达到指定等级时，您将获得额外的 VIP 升级奖金。<br> USD 是平台推出的特殊货币。您可以…'
          }
        },
        AMONG_BEST: '最佳之一',
        LIVE_REWARD: '实时奖励',
        QA: {
          TITLE: '问答'
        },
        EXCHANGE: {
          MESSAGE: '接收成功',
          CONFIRM_BUTTON_TXT: '确认'
        },
        ACHIEVE_BONUS: {
          TITLE: '成就奖金',
          DESCRIPTION: '如果累计邀请人数满足条件，您将获得额外的成就奖励。邀请的人越多，奖励越高.',
          TABLE: {
            COLUMN1: '朋友的等级',
            COLUMN2: '人数',
            COLUMN3: '奖励'
          }
        },
        BETTING_COMMISSION: {
          TITLE: '投注佣金',
          SHORT_DESC:'奖金规则：所有在“推广者”级别的玩家，每次投注都会获得平台优势奖金的一定百分比.',
          LONG_DESC: `三个等级的比例如下 <br>
          等级 1: 获得 1% 投注额的 35%<br>
          等级 2: 获得 1% 投注额的 12.25% <br> <br>
          等级 3: 获得 1% 投注额的 4.29% <br>  <br>
          这将是您的长期收入，每当您推荐的玩家进行投注时，您将获得不同百分比的佣金，无论他赢或输。因此，您需要做的是提升您的游戏技巧，思考如何赢得游戏并与他人分享，帮助更多的人通过您的方法获胜。我们希望所有玩家在我们的平台上都能享受到乐趣，无论是赢得投注的乐趣还是游戏本身的乐趣!`
        },
        USER_LIST: {
          TITLE: "用户列表",
          TABS: {
            TAB1: '子用户',
            TAB2: '下属用户'
          },
          SEARCH_PLACEHOLDER: '搜索用户 UID',
          SELECTS: {
            ITEM1: '全部',
            ITEM2: '今天',
            ITEM3: '过去 7 天',
            ITEM4: '过去 30 天',
            ITEM5: '过去 60 天'
          },
          TABLE: {
            COLUMN1: '邮箱 / 电话',
            COLUMN2: '注册时间',
            COLUMN3: '奖金',
            COLUMN4: '投注',
            COLUMN5: '邀请',
          },
          TOTAL: '总计',
          NO_DATA: "没有用户数据"
        },
        VIP_LEVEL_BONUS: {
          TITLE: 'VIP 升级奖金',
          DESC: '每当您邀请的用户在游戏中达到指定等级时，您将获得额外的 VIP 升级奖金。USD 是平台推出的特殊货币。您可以随时将 USD 兑换成其他货币.',
          TABLE: {
            COLUMN1: '朋友的等级',
            COLUMN2: '总投注额',
            COLUMN3: '奖励'
          }
        }
      }
    },
    KYC: {
      TITLE: "KYC",
      DESC: "由于国家彩票监管委员会（NLRC）和库拉索运营许可证的监管要求，我们需要您验证您的电子邮件地址和电话号码，以防止欺诈、作弊和可疑活动。如果您遇到问题，您也可以通过在线聊天寻求帮助.",
      BUTTON_TXT: "账户"
    },
    GOOGLE_LOGIN:{
      EERROR_MSG: '无法使用帐户信息登录。 请重试'
    },
    SUCCESS_MSG:{
      TITLE: '成功！'
    },
    ERROR_MSG:{
      TITLE: '抱歉,出现问题'
    },
    COMMON:{
      COMING_SOON:  '即将推出',
      LOAD_MORE:  '更多内容',
      LOGOUT: '登出',
      DEPOSIT: '存款',
      WITHDRAW: '提款',
      INVALID_AMOUNT: '无效金额',
      HOME_BUTTON_TXT: '首页',
      BACK_BUTTON_TXT: '后退',
      COPIED: '已复制',
      OK: "确定",
      CANCEL: "取消",
      SEARCH: "搜索"
    },
    LUCKSPIN: {
      TITLE: "幸运旋转",
      TRY_AGAIN: "再试一次",
      BUTTON_TEXT: "旋转",
      WIN_SPIN_TEXT: "现在幸运旋转",
      BOTTOM_TEXT: "现在旋转",
      AVAILABLE_SPINS: "可用旋转次数"
    },
    ERRORS: {
      400: '非法状态',
      401: '未授权',
      402: '需要付款',
      404: '未找到',
      500: '内部服务器错误',
      503: '[Ezugi] 无效的游戏标识符',
      504: '[Ezugi] 无效的操作',
      506: '[Ezugi] 无效的货币',
      508: '[Ezugi] 无效的交易标识符',
      511: '[Leander] 无效的令牌',
      1002: '[Evolution] 无效的会话 ID',
      1007: '[NYX] RC 未知货币',
      6001: '[Vanguard] 游戏会话无效',
      6003: '[Vanguard] 身份验证失败',
      40011: '[RGS] 无效的游戏类型',
      40012: '[RGS] 无效的游戏 ID',
      10000: '账户数量超出限制',
      10001: '添加账户失败',
      10002: '年龄验证失败',
      10004: '已存在账户',
      10005: '已注册',
      10006: '已发送',
      10010: '参数错误',
      10017: "奖金代码未找到",
      10018: '奖金不存在',
      10019: '奖金无效',
      10021: '无法批准提款请求',
      10022: '无法取消提款请求',
      10024: '无法处理提款',
      10025: '无法处理提款请求',
      10034: '货币代码未找到',
      10037: '客户已存在',
      10038: '客户不存在', //
      10042: '客户在黑名单中',
      10045: '客户未找到', //
      10046: '客户未注册', //
      10047: '客户自我排除',
      10049: '客户已暂停',
      10052: '客户验证未找到',
      10055: '客户更新失败',
      10059: '账户已禁用',
      10064: '电子邮件已存在',
      10066: '电子邮件代码未找到',
      10069: '电子邮件正在使用中',
      10071: '电子邮件未找到',
      10143: '文件格式无效',
      10145: '游戏已开始',
      10146: '游戏代码无效',
      10150: '游戏未找到',
      10154: '获取余额',
      10155: '获取余额错误',
      10173: '无效的最大金额',
      10174: '无效的最小金额',
      10177: '无效的奖金 ID',
      10178: '无效的奖金类型',
      10186: '无效的客户',
      10190: '无效的游戏 ID',
      10200: '无效的会话',
      10207: '无效的令牌',
      10221: '登录失败',
      10222: '登录次数达到上限',
      10223: '登录密码错误',
      10224: '登录用户名错误',
      10225: '退出登录失败',
      10246: '密码不匹配',
      10250: '手机号码已存在',
      10307: '用户名已存在',
      10308: '出生日期验证失败',
      10325: '电子邮件尚未注册',
      10326: '电子邮件已被用作 Google 单点登录账户',
      10327: '推荐码错误',
      10328: '客户尚未发放推荐佣金',
      10329: '无法升级',
      10330: '无法领取 VIP 现金返还',
      10331: '无效的客户 VIP 等级',
      10332: '无法领取推荐佣金',
      10333: '无法获取推荐成就信息',
      10334: '无效的手机号码',
      10341: "奖金金额不足以领取",
      11507: '国家未找到',
      11511: '无效的赌场奖金',
      11512: '请关闭所有已打开的游戏以放弃奖金.',
      11513: '一个保存的游戏保留了此奖金的资金 - 请先解决此问题.',
      11514: '一个体育事件保留了此奖金的资金 - 请先等待其解决.',
      11515: '赌场奖金不活跃',
      11516: '无法放弃任何存款滚动赌场奖金.',
      11517: "玩家的每日信用不足",
      11518: '获取奖品失败',
      11519: "奖品每日数量不足",
      11520: "请等待冷却时间后再发送另一封电子邮件.",
      11521: "请等待冷却时间后再发送另一条短信.",
      11522: "当天发送电子邮件验证次数达到上限.",
      11523: "当天发送短信验证次数达到上限.",
      11526: "ClubPay 异常",
      100000: '验证注册代码失败',
      100002: '发送短信验证失败',
      40088: "标签已分配且无法删除"
    },
    BONUS_TYPES: {
      "Deposit": "存款",
      "Withdrawal": "提款",
      "Table Buyin": "桌台买入",
      "Table Rebuy": "桌台重新买入",
      "Table Leave": "离开桌台",
      "Place Bet": "下注",
      "Tournament Buyin": "锦标赛买入",
      "Tournament Fee": "锦标赛费用",
      "Tournament Buyin Refund": "锦标赛买入退款",
      "Tournament Fee Refund": "锦标赛费用退款",
      "Manual Adjustment": "手动调整",
      "Recovery Refund": "恢复退款",
      "Transfer In": "转入",
      "Transfer Out": "转出",
      "Round Win": "回合胜利",
      "Bonus Win": "获胜奖金",
      "Deposit Bonus": "存款奖金",
      "Account Creation Bonus": "账户创建奖金",
      "Referral Bonus": "推荐奖金",
      "Bonus Conversion": "奖金转换",
      "Login Grant": "登录奖励",
      "Initial Grant": "初始奖励",
      "Virtual Goods Purchase": "虚拟商品购买",
      "Loyalty": "忠诚度",
      "Winning in random scratcher": "随机刮奖中奖",
      "Mystery Gift Win": "神秘礼物中奖",
      "FB Credit Order": "FB 信用订单",
      "Leader Board Points": "排行榜积分",
      "Tournament Award": "锦标赛奖品",
      "Returning Player Bonus": "回归玩家奖金",
      "Cancelled round refund": "取消回合退款",
      "Sit & Go Award": "Sit & Go奖品",
      "Sit & Go Buyin": "Sit & Go买入",
      "Sit & Go Fee": "Sit & Go费用",
      "Sit & Go Buyin Refund": "Sit & Go买入退款",
      "Sit & Go Fee Refund": "Sit & Go费用退款",
      "Fast Poker buyin": "快速扑克买入",
      "Fast Poker leave": "快速扑克离开",
      "Fast Poker rebuy": "快速扑克重新买入",
      "Tournament Rebuy": "锦标赛重新买入",
      "Tournament AddOn": "锦标赛附加买入",
      "Sit & Go Rebuy": "Sit & Go重新买入",
      "Sit & Go AddOn": "Sit & Go附加买入",
      "Bonus Deposit": "存款奖金",
      "Bonus Released": "奖金释放",
      "Bonus Deposit Released": "存款奖金释放",
      "Bonus Forfeit": "放弃奖金",
      "Bonus Expired": "奖金过期",
      "Bonus Winning Released": "奖金胜利释放",
      "Void Deposit": "作废存款",
      "Bonus Deposit Forfeit": "存款奖金放弃",
      "Bonus Winnings Forfeit": "获胜奖金放弃",
      "Bonus Deposit Expired": "存款奖金已过期",
      "Bonus Winnings Expired": "获胜奖金已过期",
      "SnG Buyin Recovery Refund": "SnG买入恢复退款",
      "SnG Fee Recovery Refund": "SnG费用恢复退款",
      "Tournament Buyin Recovery Refund": "锦标赛买入恢复退款",
      "Tournament Fee Recovery Refund": "锦标赛费用恢复退款",
      "Clearing released bonus": "清理已释放的奖金",
      "Clearing released bonus deposit": "清理已释放的存款奖金",
      "Clearing released bonus winnings": "清理已释放的获胜奖金",
      "Void Withdrawal": "作废提款",
      "Bonus Deposit Forfeit Cleared": "存款奖金放弃已清理",
      "Bonus Deposit Expired Cleared": "存款奖金过期已清理",
      "Bonus Cash Grant": "奖金现金补助",
      "Place a Purchase": "进行购买",
      "Cancel a Purchase": "取消购买",
      "Tournament Rebuy Refund": "锦标赛重新买入退款",
      "Tournament Rebuy Recovery Refund": "锦标赛重新买入恢复退款",
      "Tournament AddOn Refund": "锦标赛附加买入退款",
      "Tournament AddOn Recovery Refund": "锦标赛附加买入恢复退款",
      "SnG Rebuy Refund": "SnG重新买入退款",
      "SnG Rebuy Recovery Refund": "SnG重新买入恢复退款",
      "SnG AddOn Refund": "SnG附加买入退款",
      "SnG AddOn Recovery Refund": "SnG附加买入恢复退款",
      "Bounty Tournament Award": "赏金锦标赛奖品",
      "Jackpot winning": "赢得大奖",
      "Collectible game award": "收藏游戏奖品",
      "Claim reward": "领取奖励",
      "Cancel reward": "取消奖励",
      "Coupon redeemed": "优惠券已兑换",
      "Tournament Bounty Buyin": "锦标赛赏金买入",
      "Tournament Bounty Buyin Refund": "锦标赛赏金买入退款",
      "Tournament Bounty Buyin Recovery Refund": "锦标赛赏金买入恢复退款",
      "SnG Bounty Buyin": "SnG赏金买入",
      "SnG Bounty Buyin Refund": "SnG赏金买入退款",
      "SnG Bounty Buyin Recovery Refund": "SnG赏金买入恢复退款",
      "Reward Claimed Refund": "奖励已申请退款",
      "Void reward": "作废奖励",
      "Tournament Prize Pool Distribution On Recovery": "锦标赛奖池恢复分配",
      "SnG Prize Pool Distribution On Recovery": "SnG奖池恢复分配",
      "Chips Coupon Claimed": "筹码优惠券已领取",
      "Milestone Bonus": "里程碑奖金",
      "External Grant": "外部补助",
      "Void Bet": "作废下注",
      "Daily TopOff": "每日补充",
      "Bonus Grant": "奖金补助",
      "Bonus Used": "奖金使用",
      "Sport Bet": "体育下注",
      "Sport Win": "体育获胜",
      "Sport Win Adjustment": "体育胜利调整",
      "Sport Round Refund": "体育回合退款",
      "Sport Round Void": "体育回合作废",
      "Round Win Bonus": "回合胜利奖金",
      "IBC Sport Unsettle": "IBC体育未结算",
      "IBC Sport Adjust Balance": "IBC体育调整余额",
      "VIP Level rank-up bonus": "VIP等级升级奖金",
      "VIP Level cashback bonus": "VIP等级返现奖金",
      "Referral commission bonus": "推荐佣金奖金",
      "Any Deposit Rollover Bonus": "任何存款流水奖金",
      "KYC bonus": "KYC奖金",
      "Daily Lose Rake Back": "每日亏损返佣",
      "Daily lose cashback bonus": "每日亏损返现奖金",
      "IBC Sport Settled": "IBC体育已结算"
    }
	}
};
