import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CustomerModel } from '../../../models/customer-model';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { AuthenticationProvider } from 'src/app/modules/authentication/providers/authentication-provider';
import { WebservicesProvider } from 'src/app/system/providers/webservices';
import { TranslationService } from 'src/app/service/translation.service';
import { CommonService } from 'src/app/service/common.service';
import { UserSessionProvider } from 'src/app/system/providers/user-session';

@Component({
  selector: 'app-add-email',
  templateUrl: './add-email.page.html',
  styleUrls: ['./add-email.page.scss'],
})
export class AddEmailPage implements OnInit {

  @Input() callback: any;
  @ViewChild('verifyCodeInput') verifyCodeInput: IonInput;

  emailForm: FormGroup;
  email: AbstractControl;
  verifyCode: AbstractControl;

  verifiedStatus = false;
  disableGetCodeButton = false;
  canInputVerificationCode = false;

  resendInterval: any;
  resendTimePeriod = 60;

  checkEmailStatus = false;

  customer: CustomerModel;

  constructor(
    public authProvider: AuthenticationProvider,
    private webService: WebservicesProvider,
    private transService: TranslationService,
    public comService: CommonService,
    private cdr: ChangeDetectorRef,
    private userSession: UserSessionProvider
  ) {
    this.emailForm = new FormGroup({
      email: new FormControl('', [
        Validators.pattern(
          '^([0-9a-zA-Z]+[-._+&amp;])*[0-9a-zA-Z]+@([-0-9a-zA-Z]+[.])+[a-zA-Z]{2,6}$'
        ),
        Validators.required,
        Validators.email
      ]),
      verifycode: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6)
      ])
    });

    this.email = this.emailForm.get('email');
    this.verifyCode = this.emailForm.get('verifycode');
  }

  ngOnInit() {
    this.userSession.getCustomer().subscribe(customer => {
      if (customer) {
        this.customer = customer;
      }
    });
  }

  checkEmail() {
    if (!this.email.valid) return;
    this.checkEmailStatus = false;
    this.authProvider.checkEmail(this.email.value).subscribe(resp => {
      console.log('check email == ', resp);
      if (resp.code == 200 && resp.result) {
        this.checkEmailStatus = resp.payload;
      }
    }, error => {
      console.error('check email error == ', error);
    });
  }

  getCode() {
    this.webService.post('player/email/send-validation-code', {
      email: this.email.value,
      session_id: this.userSession.getSessionToken()
    }).subscribe(async (response) => {
      console.log('get code == ', response);
      if (response.code == 200) {
        var msg = await this.transService.getTranslationWord('AUTH.SIGNUP.SENT_VERIFY_EMAIL');
        this.comService.showToast(msg);
        this.canInputVerificationCode = true;
        this.disableGetCodeButton = true;

        if (this.verifyCodeInput) this.verifyCodeInput.value = '';
        this.verifyCode.patchValue('');
        this.verifyCode.markAsUntouched();
        this.verifyCode.markAsPristine();
        this.verifiedStatus = false;

        this.emailForm.markAsDirty();

        clearInterval(this.resendInterval);
        this.resendInterval = null;
        this.resendTimePeriod = 60;

        this.resendInterval = setInterval(() => {
          this.resendTimePeriod--;

          if (this.resendTimePeriod <= 0) {
            clearInterval(this.resendInterval);
            this.resendInterval = null;
            this.disableGetCodeButton = false;
            this.resendTimePeriod = 60;
          }

          this.cdr.detectChanges();
        }, 1000);

        this.cdr.detectChanges();
      }
    }, err => {
      console.error('code err == ', err);
      this.email.setErrors({EMAIL_ALREADY_EXISTS: true});
      this.canInputVerificationCode = false;
      this.disableGetCodeButton = false;
    });
  }

  inputVerificationCode($event) {
    this.verifyCode.setErrors(null);
    var val = $event.target.value;
    var filteredValue = val.replace(/[^0-9\s]*$/g, '');

    if (this.verifyCodeInput) this.verifyCodeInput.value = filteredValue;
    this.verifyCode.patchValue(filteredValue);
    this.verifyCode.markAsTouched();
    this.verifyCode.markAsDirty();
    this.verifiedStatus = false;

    var verificationCode = filteredValue;
    if (verificationCode.length == 6) {
      this.validateCode();
    }
  }

  validateCode() {
    this.webService.post('player/email/validate-code', {
      email: this.email.value,
      code: this.verifyCode.value,
      session_id: this.userSession.getSessionToken()
    }).subscribe(async (response) => {
      console.log('validate code == ', response);
      if (response.code == 200) {
        this.verifiedStatus = true;
      }
    }, async (error) => {
      console.error('validate err == ', error);
      this.verifiedStatus = false;
      this.verifyCode.setErrors({WRONG_CODE: true});
    });
  }

  save() {
    this.webService.post('auth/player/account', {
      email: this.email.value,
      customer_id: this.customer.customerId,
      session_id: this.userSession.getSessionToken(),
      code: this.verifyCode.value,
      email_check: true
    }).subscribe((response) => {
      if (response.code == 200) {
        this.callback();
      }
    }, (err: any) => {
      console.error('update err == ', err);
      this.comService.showToast(err.error_payload, 'error_toast');
    });
  }

}
