import { Navigator } from './../components/menu/navigator';
import { BalanceModel } from './../models/balance-model';
import { CustomerModel } from './../../modules/account/models/customer-model';
import { UserSessionProvider } from './user-session';
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/observable/from';
import { Config } from './../../system/providers/configuration';
import { WebservicesProvider } from './webservices';
import { CookieService } from 'ngx-cookie-service';
import { MyEvent } from 'src/app/service/myevent.services';

@Injectable({
  providedIn: 'root',
})
export class BalanceProvider {

  balanceSubject: BehaviorSubject<BalanceModel>;
  balances: any;
  customer: CustomerModel;
  appConfig: any;
  inGame = false;

  constructor(
    private config: Config,
    public webservices: WebservicesProvider,
    protected userSession: UserSessionProvider,
    private cookieService: CookieService,
    private events: MyEvent,
  ) {
    this.balanceSubject = new BehaviorSubject<BalanceModel>(null);

    this.userSession.getCustomer().subscribe((customer) => {
      if (customer) {
        this.customer = customer;
        this.updateBalance();
      } else {
        this.setBalance({});
      }
    });

    this.appConfig = this.config.getAppConfigInfo();

    this.events.getObservable().subscribe(data => {
      if (data.event == "event:balanceState") {
        if (data.data.code == "IN_GAME") this.inGame = true;
        else this.inGame = false;
      }
    });
  }

  updateBalance(params: any = {}) {
    // !!! Careful using this, it causes a reset of Inactivity timer
    console.log('refreshing balance...');

    let obj: any = {
      player_id: this.customer?.customerId,
      session_id: this.userSession.getSessionToken(),
    };

    if (params.check_over_limit && params.check_over_limit == 1) {
      obj.check_over_limit = 1;
    }

    this.webservices.get('player/balance', obj).subscribe((resp: any) => {
      if (!!resp.result && resp.payload) {
        this.setBalance(resp.payload);
      }
    });
  }

  public setBalance(balance) {
    let config = this.config.getConfig();
    // store json data to keep storage space minimal
    let balanceModel = new BalanceModel(balance, config);
    if (balanceModel && balanceModel.USDT) {
      if (balanceModel.USDT.amount < this.appConfig['BALANCE_MIN_AMOUNT']['USDT']) {
        this.checkBlanceCookie('USDT');
      }
      else {
        this.deleteBalanceCookie('USDT');
      }
    }
    this.balanceSubject.next(balanceModel);
  }

  public getBalance() {
    return this.balanceSubject;
  }

  public getDefaultCurrency() {
    return this.config.getConfig().DEFAULT_CURRENCY;
  }

  checkBlanceCookie(currency: any) {
    if (!this.cookieService.check(`min_balance_${currency}`) && !this.cookieService.check("DoNotShowPaymentsPageThisTime")) {
      var now = new Date();
      var time = now.getTime();
      var expireTime = time + 1000*60*5; // 5mins
      now.setTime(expireTime);

      this.cookieService.set(`min_balance_${currency}`, 'LOW', {expires: 1, sameSite: 'None', secure: true});
      Navigator.navigateTo("PaymentsPage", { cssClass: 'wide_modal'});
    }
    this.cookieService.delete("DoNotShowPaymentsPageThisTime")
    if (!localStorage.getItem(`min_balance_status_${currency}`)) {
      localStorage.setItem(`min_balance_status_${currency}`, 'low_balance');

      var times = localStorage.getItem(`min_balance_times_${currency}`) ? Number(localStorage.getItem(`min_balance_times_${currency}`)) : 0;

      if (!this.inGame && (this.appConfig['BALANCE_MIN_TIMES']['USDT'] < times + 1) && !this.cookieService.check(`min_balance_referral_bonus_${currency}`)) {
        times = 0;
      }

      if (!this.inGame) localStorage.setItem(`min_balance_times_${currency}`, `${times + 1}`);

      if (this.appConfig['BALANCE_MIN_TIMES']['USDT'] <= times + 1) {
        if (!this.cookieService.check(`min_balance_referral_bonus_${currency}`)) {
          if (!this.inGame) {
            this.cookieService.set(`min_balance_referral_bonus_${currency}`, 'SHOWN', {expires: 7, sameSite: 'Lax', secure: true});
            setTimeout(() => {
              this.events.publishSomeData({event: 'event:tabs:load', tab: 'referrals'});
            }, 3700);
          }
        }
      }
    }
  }

  deleteBalanceCookie(currency: any) {
    // this.cookieService.delete(`min_balance_${currency}`);
    localStorage.removeItem(`min_balance_status_${currency}`);
  }
}
