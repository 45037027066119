import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angularx-qrcode';

import { LeftSideComponent } from './left-side/left-side.component';
import { SharedModule } from '../shared/shared.module';
import { GalleryCasinoComponent } from './gallery-casino/gallery-casino.component';
import { MyGamesComponent } from './my-games/my-games.component';
import { GameComponent } from './game/game.component';
import { MainFooterComponent } from './main-footer/main-footer.component';
import { SystemModule } from '../system/system.module';
import { MainHeaderComponent } from './main-header/main-header.component';
import { GameItemComponent } from './game-item/game-item.component';
import { DepositComponent } from './deposit/deposit.component';
import { WithdrawComponent } from './withdraw/withdraw.component';
import { BalancesComponent } from './balances/balances.component';
import { RefWithDepoComponent } from './ref-with-depo/ref-with-depo.component';
import { SignupStepOnePage } from '../modules/authentication/pages/signup-step-one/signup-step-one';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeaderRangComponent } from './leader-rang/leader-rang.component';
import { CountryCodesComponent } from './country-codes/country-codes.component';
import { TranslateModule } from '@ngx-translate/core';
import { SpinWheelComponent } from './spin-wheel/spin-wheel.component';
import { CustomButtonComponent } from './custom-button/custom-button.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    LeftSideComponent,
    GalleryCasinoComponent,
    MyGamesComponent,
    GameComponent,
    MainFooterComponent,
    MainHeaderComponent,
    GameItemComponent,
    DepositComponent,
    WithdrawComponent,
    BalancesComponent,
    RefWithDepoComponent,
    SignupStepOnePage,
    LeaderRangComponent,
    CountryCodesComponent,
    SpinWheelComponent,
    CustomButtonComponent
  ],
  exports:[
    LeftSideComponent,
    GalleryCasinoComponent,
    MyGamesComponent,
    GameComponent,
    MainFooterComponent,
    MainHeaderComponent,
    GameItemComponent,
    DepositComponent,
    WithdrawComponent,
    BalancesComponent,
    RefWithDepoComponent,
    SignupStepOnePage,
    LeaderRangComponent,
    CountryCodesComponent,
    SpinWheelComponent,
    CustomButtonComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    SystemModule,
    NgbTooltipModule,
    QRCodeModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
