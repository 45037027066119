import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CustomerModel } from 'src/app/modules/account/models/customer-model';
import { GameHubController } from 'src/app/modules/gameHub/game-hub-controller';
import { MyEvent } from 'src/app/service/myevent.services';
import { TranslationService } from 'src/app/service/translation.service';
import { Config } from 'src/app/system/providers/configuration';
import { UserSessionProvider } from 'src/app/system/providers/user-session';
import { WebservicesProvider } from 'src/app/system/providers/webservices';

@Component({
  selector: 'app-my-games',
  templateUrl: './my-games.component.html',
  styleUrls: ['./my-games.component.scss'],
})
export class MyGamesComponent implements OnInit {

  @Input() componentType: any;

  private lobbyName: string;
  public customer: CustomerModel;
  public lastPlayedGamesArray: any = [];
  public heading: string = "";

  placeholderImg: any;

  eventSub: any;

  constructor(
    public userSession: UserSessionProvider,
    private webservice: WebservicesProvider,
    public config: Config,
    public gameHubController: GameHubController,
    private events: MyEvent,
    private cdr: ChangeDetectorRef,
    private transService: TranslationService
  ) {
    let appConfigInfo = this.config.getAppConfigInfo();
    this.placeholderImg = appConfigInfo.placeholder_img;

    this.eventSub = this.events.getObservable().subscribe((data) => {
      if (data.event == 'new:game:load') {
        setTimeout(() => {
          this.onGetLastPlayedGames();
        }, 50);
      }
    });
   }

  async ngOnInit() {
    this.lobbyName = this.config.getConfig().LOBBY_NAME;

    this.userSession.getCustomer().subscribe((customer) => {
      this.customer = customer;
    });

    this.heading = await this.transService.getTranslationWord('GAME.MY_GAMES_TITLE');
  }

  ngOnDestroy() {
    if (this.eventSub) this.eventSub.unsubscribe();
    this.eventSub = null;
  }

  launchCasinoGame(gameData) {
    this.events.publishSomeData({event: "event:gameHub:closeGame"});
    setTimeout(() => {
      this.gameHubController.launchCasinoGame(gameData);
    }, 500);
  }

  onGetLastPlayedGames() {
    if (this.customer) {
      this.webservice.get("lobby/" + this.lobbyName + "/recent-games?player_id=" + this.customer.customerId + "&return_objects=1", {
        client_app_id: this.config.getClientApp()
      }).subscribe(data => {
        if (data.code == 200) {
          this.lastPlayedGamesArray = data.payload["recent-games"] || [];
        }
      });
    }
  }

  /**
   * @param gameData
   */
  loadImg(gameData) {
    gameData.loaded = true;
    this.cdr.detectChanges();
  }

  errorImg(gameData) {
    gameData.loaded = "error";
    this.cdr.detectChanges();
  }

}
