import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SuccessMessageProvider {

    public message: string = '';

    constructor() { }

    setMessage(message) {
        this.message = message;
    }

    getMessage() {
        return this.message;
    }

    hasMessage() {
        return ('' != this.message);
    }

    clearMessage() {
       this.message = '';
    }

}
