import { HttpClient } from "@angular/common/http";
import { Device } from "@ionic-native/device/ngx";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { MyEvent } from "src/app/service/myevent.services";
import { TranslationService } from "src/app/service/translation.service";
import { locale as enLang } from '../../../assets/i18n/en';
import { locale as cnLang } from '../../../assets/i18n/cn';
import { locale as esLang } from '../../../assets/i18n/es';
import { locale as vtLang } from '../../../assets/i18n/vi';
import { locale as ptLang } from '../../../assets/i18n/pt';
import { locale as thaiLang } from '../../../assets/i18n/th';
import { locale as indoLang } from '../../../assets/i18n/id';
import { locale as jaLang } from '../../../assets/i18n/jp';

declare var window: any;
const LOCALIZATION_LOCAL_STORAGE_KEY = 'casino_lang';

@Injectable({
  providedIn: 'root'
})
export class Config {
  private environment: any;
  private config: any;
  private configPromise: Promise<any>;
  private externalLinks: any = [];
  private homePageUrl;
  private footerUrl;
  private appConfigInfo;

  private hideSportsLoader: boolean = false;

  constructor(
    private http: HttpClient,
    private platform: Platform,
    protected events: MyEvent,
    public appVersion: AppVersion,
    public device: Device,
    private translationService: TranslationService,
  ) {
    this.translationService.loadTranslations(
      enLang,
      cnLang,
      esLang,
      vtLang,
      ptLang,
      thaiLang,
      indoLang,
      jaLang
    );

    if (!this.config) {
      this.getReadyConfig();
    }
  }

  getReadyConfig() {
    this.configPromise = new Promise((resolve) => {
      console.log("loading config");
      this.http.get("/assets/environment.config").subscribe((data: any) => {
        this.environment = data;

        // this adjusting of clientapp potentially fails on mobile apps when plugins are not loaded yet.
        // this will give us a temp clientapp for the first configuration/get-config-data call.
        // reload() is then called in platform.ready().then from app.component.ts. This should fix the clientapp now that the plugins are ready.
        this.adjustClientApp().then(() => {
          let config_url = this.environment.webservices_url + "/configuration/get-config-data?clientapp=" + this.environment.clientapp;
          this.http.get(config_url).subscribe( // from server
            (config: any) => {
              console.log('config == ', config);

              let configData: Object = {};

              config.payload.map(function (data) {
                configData[data.name] = data.value;
              });

              this.config = configData; // json data from server
              console.log('this.config == ', this.config);

              // get the state config first, then external links
              this.getAsyncAppConfigInfo().then((data: any) => { // get from newjesey.config file
                console.log('getAsyncAppConfigInfo == ', data);
                this.setAppConfigInfo(data);

                // get browser lang
                var langs: any[] = this.appConfigInfo.languages || [];
                var userLang: any;
                if (localStorage.getItem(LOCALIZATION_LOCAL_STORAGE_KEY)) {
                  var savedLang = localStorage.getItem(LOCALIZATION_LOCAL_STORAGE_KEY);
                  const lg = langs.find(el => el.code == savedLang);
                  if (lg) {
                    userLang = lg.code;
                  } else {
                    userLang = 'en-US';
                  }
                } else {
                  var browserLang: string = navigator.language || navigator['userLanguage'];
                  console.log('browser lang == ', browserLang);
                  if (browserLang) {
                    const lg = langs.find(el => el.code == browserLang);
                    if (lg) {
                      userLang = lg.code;
                    } else {
                      userLang = 'en-US';
                    }
                  } else {
                    userLang = 'en-US';
                  }
                }
                this.translationService.setSessionLang(userLang);
                var appRoot = document.querySelector('app-root');
                if (!!appRoot) {
                  if (appRoot.classList.length) {
                    appRoot.classList.forEach(el => {
                      appRoot.classList.remove(el);
                    });
                  }
                  appRoot.classList.add(userLang);
                }
                // end browser lang


                this.callGetExternalLinks().then((res: any) => { // from server
                  console.log('external links == ', res);
                  let response = res;
                  if ("payload" in response) {
                    this.externalLinks = response.payload;

                    let homePage = this.externalLinks.filter(
                      (link) => link.code == "homepage"
                    )[0];
                    this.homePageUrl = !!homePage && "url" in homePage ? homePage.url : "home";

                    let footer = this.externalLinks.filter(
                      (link) => link.code == "footer"
                    )[0];
                    this.footerUrl = !!footer && "url" in footer ? footer.url : "/footer-2/";
                    resolve(configData);
                  } else {
                    this.externalLinks = [];
                    resolve(configData);
                  }
                }).catch(err => {
                  console.error('external error == ', err);
                });
              });
            },
            (error) => {
              console.error("err == ", error);
            }
          );
        });
      });
    });
  }

  // this is required because of a potential issue on mobile where the app-version plugin has failed.
  // this causes a bad clientapp for the initial configuration/get-config-data call found in the constructor of this class.
  // then in app.component.ts right before we set the rootPage we call this reload to fix the clientapp.
  reload(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get("/assets/environment.config").subscribe((data: any) => {
        this.environment = data;

        this.adjustClientApp().then(() => {
          let config_url = this.environment.webservices_url + "/configuration/get-config-data?clientapp=" + this.environment.clientapp;
          this.http.get(config_url).subscribe((config: any) => {
            let configData: Object = {};

            config.payload.map(function (data) {
              configData[data.name] = data.value;
            });

            this.config = configData;

            resolve(configData);
          });
        });
      });
    });
  }

  // ONLY used when changing to an environment other than production via the environment selector.
  reloadConfig(env: string = "test") {
    this.http
      .get("/assets/environment." + env + ".config")
      .subscribe((data: any) => {
        this.environment = data;

        this.adjustClientApp().then(() => {
          let config_url = this.environment.webservices_url + "/configuration/get-config-data?clientapp=" + this.environment.clientapp;
          console.log("configuration is", config_url);
          this.http.get(config_url).subscribe((config: any) => {
            let configData: Object = {};

            config.payload.map(function (data) {
              configData[data.name] = data.value;
            });

            this.config = configData;

            this.events.publishSomeData({event: "event:config:reload"});
            this.events.publishSomeData({event: "event:affiliation:initialize"});
          });
        });
      });
  }

  private callGetExternalLinks() { // from server
    let v = new Date().getTime();
    let serviceUrl =
      this.environment.webservices_url +
      "/configuration/get-external-links?v=" +
      v;
    return this.http.get(serviceUrl).toPromise();
  }

  getExternalLinks() {
    return this.externalLinks;
  }

  getSidemenuData(menuSelected) {
    let v = new Date().getTime();
    let menu_url = `assets/menu/user/menu.json?v=${v}`;
    return this.http
      .get(menu_url)
      .toPromise()
      .then(
        (res: any) => res,
        (err) => console.log(err)
      );
  }
  // adjusts the client app based on multiple factors but mainly mobileapp, mobileweb and web
  adjustClientApp(): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log("adjustClientApp");
      console.log("   platforms =", this.platform.platforms());
      console.log("     cordova =", this.platform.is("cordova"));
      console.log("         ios =", this.platform.is("ios"));
      console.log("     android =", this.platform.is("android"));
      console.log("      tablet =", this.platform.is("tablet"));
      console.log("      hybrid =", this.platform.is("hybrid"));
      console.log("      desktop =", this.platform.is("desktop"));
      console.log("      pwa =", this.platform.is("pwa"));
      console.log("      electron =", this.platform.is("electron"));

      resolve(this.environment);
    });
  }

  isLoaded(): Promise<any> {
    return this.configPromise;
  }

  getConfig() {
    return this.config;
  }

  getEnv() {
    return this.environment;
  }

  getClientApp() {
    return this.environment.clientapp;
  }

  getGTMKey() {
    return this.config.FEATURE_GTM_KEY || "";
  }

  getGoogleAnalyticsKey() {
    return this.config.FEATURE_GANALYTICS_KEY || '';
  }

  getGoogleABTestingKey() {
    return this.config.FEATURE_GABTEST_KEY || "";
  }

  getTealiumUrl() {
    return this.config.FEATURE_TEALIUM_URL || "";
  }

  getState() {
    return 'web';
  }

  cache_buster() {
    let v = new Date().getTime();
    return "undefined" !== typeof window.CACHE_BUSTER ? window.CACHE_BUSTER : v;
  }

  loadCDNAsset(path) {
    let v = new Date().getTime();
    return `${path}?v=${v}`;
  }

  public getBaseUrl = () => this.config?.CDN_BASEURL;

  public getLobbyImagePath = (imageName) => {
    if (imageName.startsWith('http')) return imageName;
    return `${this.getBaseUrl()}/assets/social/games/${imageName}`
  };

  getCampaignBanerImgUrl(data) {
    let imgLocation = `${this.getBaseUrl()}/assets/`;
    return {
      sm: data.banner_image_small.includes('http') ? data.banner_image_small : `${imgLocation}notifications/${data.banner_image_small}`,
      md: data.banner_image_medium.includes('http') ? data.banner_image_medium : `${imgLocation}notifications/${data.banner_image_medium}`,
      lg: data.banner_image_large.includes('http') ? data.banner_image_large : `${imgLocation}notifications/${data.banner_image_large}`,
    };
  }
  getDefaultBanerImgUrl(data) {
    let imgLocation = `${this.getBaseUrl()}/assets/`;
    return {
      sm: `${imgLocation}'banners/sm/'${data.bg_path}`,
      md: `${imgLocation}'banners/sm/'${data.bg_path}`,
      lg: `${imgLocation}'banners/sm/'${data.bg_path}`,
    };
  }

  private getAsyncAppConfigInfo() {
    return this.http.get("/assets/web.config?v=" + new Date().getTime()).toPromise();
  }

  getHomePageUrl() {
    return this.homePageUrl;
  }

  getFooterUrl() {
    return this.footerUrl;
  }

  isSportsApp() {
    return false;
  }

  public setAppConfigInfo(data) { // set json data from newjesey.config file
    this.appConfigInfo = data;
  }

  public getAppConfigInfo() { // get json data from newjesey.config file
    return this.appConfigInfo;
  }

  /* XtremePush configs */
  getXtremeWebSdkUrl() {
    return this.config.FEATURE_XTREME_WEB_SDK_URL || "";
  }

  getXtremeIosAppKey() {
    return this.config.FEATURE_XTREME_IOS_KEY || "";
  }

  getXtremeAndroidAppKey() {
    return this.config.FEATURE_XTREME_ANDROID_KEY || "";
  }

  getXtremeGcmProjectNumber() {
    return this.config.FEATURE_XTREME_GCM_NR || "";
  }

  // Sports loader dialog
  // Used in sports.ts and reset-password.ts
  getHideSportsLoader() {
    return this.hideSportsLoader;
  }

  setHideSportsLoader(value: boolean) {
    this.hideSportsLoader = value;
  }
}
