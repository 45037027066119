import { Observable} from 'rxjs';
import { CustomerModel } from './../../account/models/customer-model';
import { UserSessionProvider } from './../../../system/providers/user-session';
import { WebservicesProvider,PlatformResponse } from './../../../system/providers/webservices';
import { BalanceProvider } from './../../../system/providers/balance-provider';
import { BalanceModel } from './../../../system/models/balance-model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RewardsProvider {
    public customer: CustomerModel;
    accountBalances: BalanceModel = null;

    constructor(
        private webservice: WebservicesProvider,
        private userSession: UserSessionProvider,
        public balanceProvider: BalanceProvider
    ) {
        console.log("RewardsProvider");

        this.userSession.getCustomer().subscribe(
            (customer) => {
                this.customer = customer;
            }
        );
        this.balanceProvider.getBalance().subscribe(
            (response) => {
                this.accountBalances = response;
            }
        );
    }

    /*
      GET /customer/loyalty-points
    */
    callLoyaltyPoints() {
        let obj: any = {
          player_id: this.customer.customerId,
          session_id: this.userSession.getSessionToken()
        }

        return this.webservice.get('player/membership-points', obj);
    }

    /*
      GET /customer/achievements
    */
    callAchievements(category: string) {
        let obj: any = {
          player_id: this.customer.customerId,
          session_id: this.userSession.getSessionToken(),
          category: category
        }

        return this.webservice.get('player/accomplishments', obj);
    }

    /*
      GET /rewards/get-customer-casino-bonuses-active
    */
    callGetCustomerCasinoBonusesActive() {
        let obj: any = {
          player_id: this.customer.customerId,
          session_id: this.userSession.getSessionToken()
        }

        return this.webservice.get('gift/bonus/casino/active', obj);
    }

    /*
    GET /rewards/get-customer-casino-bonuses-history
    */
    callGetCustomerCasinoBonusesHistory(dateFrom: string, dateTo: string, page?: string) {
        let obj: any = {
          player_id: this.customer.customerId,
          session_id: this.userSession.getSessionToken(),
        }

        if (dateFrom) {
            obj.start_date = dateFrom;
        }

        if (dateTo) {
            obj.end_date = dateTo;
        }

        if (page) {
            obj.page = page;
        }

        return this.webservice.get('gift/bonus/casino/history', obj);
    }

    /*
      GET /gift/bonus/details
    */
    callGetBonusDetails(bonus_id: string) {
        let obj: any = {
          player_id: this.customer.customerId,
          session_id: this.userSession.getSessionToken(),
            bonus_id: bonus_id
        }

        return this.webservice.get('gift/bonus/details', obj);
    }

    /*
      GET /gift/bonus/revoke
    */
    callForfeitBonus(bonus_id: string) {
        let obj: any = {
          player_id: this.customer.customerId,
          session_id: this.userSession.getSessionToken(),
            bonus_id: bonus_id
        }

        return this.webservice.get('gift/bonus/revoke', obj);
    }

    /*
      GET
    */
    callGetCustomerPokerBonusesActive() {
        let obj: any = {
          player_id: this.customer.customerId,
          session_id: this.userSession.getSessionToken()
        }

        return this.webservice.get('gift/bonus/poker/active', obj);
    }

    /*
    GET
    */
    callGetCustomerPokerBonusesHistory(dateFrom: string, dateTo: string, page?: string) {
        let obj: any = {
          player_id: this.customer.customerId,
          session_id: this.userSession.getSessionToken(),
        }

        if (dateFrom) {
            obj.start_date = dateFrom;
        }

        if (dateTo) {
            obj.end_date = dateTo;
        }

        if (page) {
            obj.page = page;
        }

        return this.webservice.get('gift/bonus/poker/history', obj);
    }

    /*
      GET
    */
    callGetPokerBonusDetails(bonus_id: string) {
        let obj: any = {
          player_id: this.customer.customerId,
          session_id: this.userSession.getSessionToken(),
            bonus_id: bonus_id
        }

        return this.webservice.get('gift/bonus/poker/details', obj);
    }

    /*
      GET
    */
    callSetPokerBonusActive(bonus_id: string) {
        let obj: any = {
            player_id: this.customer.customerId,
            session_id: this.userSession.getSessionToken(),
            bonus_id: bonus_id
        }

        return this.webservice.get('rewards/set-customer-poker-bonus-active', obj);
    }

    /*
      GET /rewards/get-bonus-offers
    */
    callGetBonusOffers(data = {}) {
        let obj: any = data

        if (this.customer) {
          // obj.player_id = this.customer?.customerId;
          // obj.session_id = this.userSession.getSessionToken();
          obj = {
            player_id: this.customer?.customerId,
            session_id: this.userSession.getSessionToken()
          }
        }

        return this.webservice.get('gift/bonus-offers', obj);
    }

    /*
      GET /rewards/get-bonus-free-spins
    */
    callGetBonusFreeSpins() {
        let obj: any = {
          player_id: this.customer.customerId,
          session_id: this.userSession.getSessionToken()
        }

        return this.webservice.get('gift/bonus/free-spins', obj);
    }

    /*
      GET /rewards/redeem-bonus
    */
    callRedeemBonus(bonus_code: string) {
        let obj: any = {
          player_id: this.customer.customerId,
          session_id: this.userSession.getSessionToken(),
            bonus_code: bonus_code
        }

        return this.webservice.get('gift/bonus/regain', obj);
    }

    /*
      GET /rewards/get-loyalty-points
    */
    callGetLoyaltyPoints() {
        let obj: any = {
          player_id: this.customer.customerId,
          session_id: this.userSession.getSessionToken()
        }

        return this.webservice.get('gift/membership-points', obj);
    }

    /*
      GET /rewards/get-milestones
    */
    callGetMilestones() {
        let obj: any = {
          player_id: this.customer.customerId,
          session_id: this.userSession.getSessionToken()
        }

        return this.webservice.get('gift/milestones', obj);
    }

    /*
      GET /rewards/get-reward-exchange-rate
    */
    callGetRewardExchangeRate(conversion_to?: string) {
        if (typeof conversion_to == 'undefined') {
            conversion_to = this.accountBalances.getDefaultCurrency();
        }

        let obj: any = {
            conversion_to: conversion_to
        }
        return this.webservice.get('gift/exchange-rate', obj);
    }

    /*
      GET /rewards/get-signup-offers
        conversion_to = USD *DEFAULT
        conversion_to = TD
    */
    callConvertRewardsPoints(amount: number) {
        let obj: any = {
          player_id: this.customer.customerId,
          session_id: this.userSession.getSessionToken(),
            conversion: amount,
            convert_to: this.accountBalances.getDefaultCurrency(),
        }

        return this.webservice.get('gift/gift-points/convert', obj);
    }

    callGetSignupOffers(data):Observable<PlatformResponse<any>> {
        return this.webservice.get('gift/signup-offers');
    }
}
