import { FormGroup, AbstractControl, FormControl } from '@angular/forms';


export function POboxValidator(control: FormControl) {
    let streetAddress = control.value;
    let POBoxpattern = new RegExp('[PO.]*\\s?B(ox)?.\\d+', 'i');
    if (streetAddress.match(POBoxpattern)) {
        return { 'isPOBox': true };
    } else {
        return null;
    }
}

export function POboxValidatorGroup(formGroup: FormGroup) {
    let street1 = (!!formGroup.get('street')) ? formGroup.get('street').value : "";
    let street2 = (!!formGroup.get('street2').value) ? formGroup.get('street2').value : "";
    let streetsCombined = street1 + street2;
    let POBoxpattern = new RegExp('[PO.]*\\s?B(ox)?.\\d+', 'i');
    if (streetsCombined.match(POBoxpattern)) {
        return { 'isPOBoxGroup': true };
    } else {
        return null;
    }
}

/**
* Returns null if pattern is matched
*/
export function coutryPostalMatchValidator(formGroup: FormGroup) {
    let rule = true;
    let canRegex = new RegExp(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    let usRegex = new RegExp(/^[0-9]{5}$/);
    let postal = formGroup.get('postalCode').value;

    switch (formGroup.get('country').value) {
        case "USA":
            rule = usRegex.test(postal)
            break;
        case "CAN":
            rule = canRegex.test(postal)
            break;
        default:
            rule = usRegex.test(postal)
            break;
    }

    return rule ? null : { 'mismatch': true };
}

/**
* Returns null if pattern is matched
*/
export function birthdayValidator(formGroup: FormGroup) {
    var daysInMonth = function (m, y) {
        switch (m) {
            case 1 :
                return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28;
            case 8 : case 3 : case 5 : case 10 :
                return 30;
            default :
                return 31
        }
    };

    var isValidDate = function (y, m, d) {
        return m >= 0 && m < 12 && d > 0 && d <= daysInMonth(m, y);
    };

    let day = formGroup.get('dateOfBirthDay').value,
        year = formGroup.get('dateOfBirthYear').value,
        month = formGroup.get('dateOfBirthMonth').value;
    let valid = true;
    let age = 21;

    if(day && month && year) {
        valid = false;
        let birthmonth = parseInt(month, 10);
        let birthday = parseInt(day, 10);
        let birthyear = parseInt(year, 10);

        // check valid date
        if (isValidDate(birthyear, birthmonth - 1, birthday)) {
            // adding a custom check fo 21 yrs old, checking the months and days
            let JSbirthdate: any = new Date(birthyear, birthmonth - 1, birthday);
            // Set current date
            let JScurrdate: any = new Date();
            // Subtract age from current year
            JScurrdate.setFullYear(JScurrdate.getFullYear() - age);
            // compare dates and return boolean
            valid = (JScurrdate - JSbirthdate) >= 0;
            // check date is valid
            let d = Date.parse(birthyear + "-" + birthmonth + "-" + birthday);

            if (null === d) {
                valid = false;
            }
        }
    }
    return valid ? null : { 'mismatch': true };
}


export function mustBeTruthy(c: AbstractControl): { [key: string]: boolean } {
    let rv: { [key: string]: boolean } = {};
    if (!c.value) {
        rv['notChecked'] = true;
    }
    return rv;
}

export function emailMatchValidator(g: FormGroup) {
    return g.get('email').value.toLowerCase() === g.get('emailConfirm').value.toLowerCase() ? null : { 'mismatch': true };
}

export function passwordMatchValidator(g: FormGroup) {

    if(!!g.get('password') && !!g.get('passwordConfirm')) {
        if (g.get('password').value === g.get('passwordConfirm').value) {
            g.get('passwordConfirm').setErrors(null);
            return null;
        }
        else {
            g.get('passwordConfirm').setErrors({ 'incorrect': true });
            return { 'mismatch': true };
        }
    } else {
        return null;
    }

}

export function idNumberGroupMatchValidator(g: FormGroup) {
    if (g.get('idNumber').value === g.get('idNumber2').value) {
        g.get('idNumber2').setErrors(null);
        return null;
    }
    else {
        g.get('idNumber2').setErrors({ 'incorrect': true });
        return { 'mismatch': true };
    }
}

export function creditCardExpiryValidator(formGroup: FormGroup) {
    let today = new Date();
    let currentMonth = today.getMonth() + 1;
    let currentYear = today.getFullYear();

    if (formGroup.get('cardExpMonth').value != "" && formGroup.get('cardExpYear').value != "") {
        if (formGroup.get('cardExpYear').value < currentYear || (formGroup.get('cardExpYear').value == currentYear && formGroup.get('cardExpMonth').value < currentMonth)) {
            formGroup.get('cardExpMonth').setErrors({ 'isExpired': true });
            return { 'isExpired': true };
        }
        else {
            formGroup.get('cardExpMonth').setErrors(null);
            return null;
        }
    }
}

export function rangeValidator(minNumber: number, maxNumber: number) {
    return (control: AbstractControl): { [key: string]: boolean } => {
        if( (control && control.value && (!isNaN(control.value))) && (minNumber && maxNumber && !isNaN(minNumber) && !isNaN(maxNumber)) )  {
            if (control.value >= minNumber && control.value <= maxNumber) {
                return null;
            } else {
                return { 'out_of_range': true };
            }
        } else {
            return { 'out_of_range': true };
        }
    }
}

export function noWhitespaceOnlyValidator(control: FormControl) {
    let value = control.value;
    if (value !== null && value.match(WHITESPACE_ONLY_PATTERN)) {
        return { 'whitespaceOnly': true };
    }
    return null;
}

export function noTrailingOrStartingWhitespaces(control: FormControl) {
    let value = control.value;
    if (value !== null && value.match(WHITESPACES_AT_START_OR_TRAIL)) {
        return { 'noTrailingOrStartingWhitespaces': true };
    }
    return null;
}

// export const PASSWORD_PATTERN = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,30}/;
export const PASSWORD_PATTERN = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-!\$@#%^&*()_+|~={}]).{8,30}/;

export const SCREENNAME_PATTERN = /^[a-z0-9]+$/i;

export const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NAME_PATTERN = "^[a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF][a-zA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF ,/./'‘’-]+$";

export const NAME_PATTERNV2 = /^[a-zA-Z][a-zA-Z ,/./‘’'-]{2,100}$/;

export const WHITESPACE_ONLY_PATTERN = /^[\s]+$/;

export const WHITESPACES_AT_START_OR_TRAIL = /^\s.|\s$/;
export const DEPOSIT_PATTERN = /^([0-9]+\.?[0-9]*|\.[0-9]+)$/;
export const ID_PATTERN = /^[^-]+$/;
