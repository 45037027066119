import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { LogoutConfirmComponent } from './logout-confirm/logout-confirm.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	declarations: [
    LogoutConfirmComponent
	],
	imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
	],
	exports: [
    LogoutConfirmComponent
	]
})
export class AccountComponentsModule {}
