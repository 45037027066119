import { Inject, Injectable } from '@angular/core';
import { Config } from '../../system/providers/configuration';
import { Device } from '@ionic-native/device/ngx';
import { Appsflyer, AppsflyerEvent } from '@ionic-native/appsflyer/ngx';
import {
  AffiliateTrackerInterface,
  AffiliateTrackerCallbackParams,
} from './affiliation-event';
import { MainProvider } from '../../modules/lobby/providers/main.provider';
import { Platform } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';

declare var window: any;

@Injectable({
  providedIn: 'root',
})
export class AppsflyerTracker implements AffiliateTrackerInterface {
  private configVars: any;
  private attributionData: any = null;
  private appsflyer_id: number | string;
  private shouldAnonymizeUser: boolean = false;
  private ios_strategies = {
    DEFAULT: this.iosStrategyAllowDeny,
    'ALLOW-DENY': this.iosStrategyAllowDeny,
    MINIMAL: this.iosStrategyMinimal,
  };
  private appsFlyerEventID: any = null;

  constructor(
    private platform: Platform,
    private config: Config,
    private device: Device,
    private appsflyer: Appsflyer,
    private events: MyEvent,
    private mainProvider: MainProvider
  ) {
    this.config.isLoaded().then(() => {
      this.configVars = this.config.getConfig();
    });
  }

  protected getIDFAInfo(): Promise<any> {
    var cordova: any;
    if ((cordova = window['cordova']) && cordova.plugins.idfa) {
      const idfaPlugin = cordova.plugins.idfa;
      return idfaPlugin.getInfo().then((info) => {
        // if (!info.trackingLimited) {
        //     return info.idfa || info.aaid;
        // } else
        if (
          info.trackingPermission ===
            idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED ||
          info.trackingPermission === idfaPlugin.TRACKING_PERMISSION_DENIED
        ) {
          // ask for permission first
          return idfaPlugin.requestPermission().then((result) => {
            if (result === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED) {
              return idfaPlugin.getInfo().then((info) => {
                return info.idfa || info.aaid;
              });
            } else {
              return Promise.resolve(null);
            }
          });
        } else if (
          info.trackingPermission === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED
        ) {
          // just get the data
          idfaPlugin.getInfo().then((info) => {
            return info.idfa || info.aaid;
          });
        } else {
          // we are not allowing it
          return Promise.resolve(null);
        }
      });
    } else {
      // no cordova plugin, succeed with no identifier
      return Promise.resolve(null);
    }
  }

  protected appsflyerInitSDK(options): Promise<any> {
    return new Promise((resolve, reject) => {
      var initWasSuccessful = window.sessionStorage.getItem(
        'appsFlyer_initWasSuccessful'
      );
      if (initWasSuccessful === 'true') {
        resolve(this);
      } else {
        var mythis = this; // weird initSdk callback seems to kill 'this' in below block
        window.plugins.appsFlyer.initSdk(
          options,
          (result) => {
            if (mythis.shouldAnonymizeUser) {
              window.plugins.appsFlyer.anonymizeUser(true);
            }
            let attr_obj = JSON.parse(result);
            if (
              'undefined' !== typeof attr_obj.data &&
              attr_obj.status == 'success' &&
              attr_obj.data.is_first_launch
            ) {
              console.log(
                'appsFlyer.ts: attribution_data',
                JSON.stringify(attr_obj.data)
              );
              mythis.attributionData = attr_obj.data;
            }
            this.appsflyer.getAppsFlyerUID().then((data) => {
              mythis.appsflyer_id = data;
            });
            window.sessionStorage.setItem(
              'appsFlyer_initWasSuccessful',
              'true'
            );
            resolve(mythis);
          },
          (error: any) => {
            console.log('initSdk: error: ', error);
            reject(error);
          }
        );
      }
    });
  }

  protected iosStrategyDefault(config, options): Promise<any> {
    console.log('appsflyer.ts:: AppsFlyer strategy: DEFAULT');
    return Promise.reject('FEATURE_APPSFLYER_IOS_STRATEGY not configured');
  }

  protected iosStrategyAllowDeny(config, options): Promise<any> {
    console.log('appsflyer.ts:: AppsFlyer strategy: ALLOW-DENY');
    options.waitForATTUserAuthorization = 60;
    return this.getIDFAInfo().then((idfaInfo) => {
      if (!idfaInfo) {
        this.shouldAnonymizeUser = true;
      }
      console.log('appsflyer.ts: initSDK iOS');
      console.log('appsFlyer.ts: idfaInfo:', JSON.stringify(idfaInfo));
      console.log('appsFlyer.ts: options:', JSON.stringify(options));
      return this.appsflyerInitSDK(options);
    });
  }

  protected iosStrategyMinimal(config, options): Promise<any> {
    console.log('appsflyer.ts:: AppsFlyer strategy: MINIMAL');
    console.log('appsflyer.ts: initSDK iOS');
    console.log('appsFlyer.ts: options:', JSON.stringify(options));
    return this.appsflyerInitSDK(options);
  }

  initialize(): Promise<AffiliateTrackerInterface> {
    return new Promise((resolve_tracker, reject_tracker) => {
      Promise.all([this.platform.ready(), this.config.isLoaded()]).then(
        ([ready, _notused]) => {
          var config = this.config.getConfig();

          console.log('AppsFlyer: ***** INITIALIZING *****');
          console.log('appsflyer.ts: config: ', config);
          var options: any = {
            devKey: config.FEATURE_APPSFLYER_DEVKEY,
            onInstallConversionDataListener: true,
          };
          options.isDebug =
            config.FEATURE_APPSFLYER_DEBUG &&
            config.FEATURE_APPSFLYER_DEBUG == '1';
          if (this.platform.is('ios')) {
            options.appId = config.FEATURE_APPSFLYER_IOS_APPID; // iOS app store id
            // if FEATURE_APPSFLYER_IOS_STRATEGY === 'IDFA' OTHER VALUES 'SKADNETWORK'

            var strategy =
              this.ios_strategies[config.FEATURE_APPSFLYER_IOS_STRATEGY];

            if (!strategy) {
              strategy = this.ios_strategies['DEFAULT'];
            }

            strategy
              .apply(this, [config, options])
              .then(() => {
                resolve_tracker(this);
              })
              .catch((e) => {
                reject_tracker(e);
              });
          } else if (this.platform.is('android')) {
            console.log('appsflyer.ts: initSDK Android');
            console.log('appsFlyer.ts: options:', JSON.stringify(options));
            this.appsflyerInitSDK(options)
              .then(() => {
                resolve_tracker(this);
              })
              .catch((e) => {
                reject_tracker(e);
              });
          }
        }
      );
    });
  }

  getAttributionData(customer_id?: string): Promise<any> {
    return Promise.resolve({
      appsflyer: this.attributionData,
    });
  }

  signupStep1Complete(cbParams: AffiliateTrackerCallbackParams) {
    console.log('appsflyer.ts: event: signupStep1Complete', cbParams);
    let af_params: AppsflyerEvent = cbParams as AppsflyerEvent;
    window.plugins.appsFlyer.logEvent('signup_step1_complete', af_params);
  }
  signupComplete(cbParams: AffiliateTrackerCallbackParams) {
    if (cbParams.customer_id) {
      this.appsflyer.setAppUserId(cbParams.customer_id.toString());
    }
    console.log('appsflyer.ts: event: signupComplete', cbParams);
    let af_params: AppsflyerEvent = cbParams as AppsflyerEvent;
    window.plugins.appsFlyer.logEvent('signup_complete', af_params);
  }

  deposit(cbParams: AffiliateTrackerCallbackParams) {
    console.log('appsflyer.ts: event: deposit', cbParams);
    let af_params: AppsflyerEvent = cbParams as AppsflyerEvent;

    if (
      'undefined' !== typeof cbParams.amount &&
      'undefined' === typeof af_params['af_revenue']
    ) {
      af_params['af_revenue'] = cbParams.amount;
    }
    window.plugins.appsFlyer.logEvent('deposit', af_params);
  }

  withdraw(cbParams: AffiliateTrackerCallbackParams) {
    console.log('appsflyer.ts: event: withdraw', cbParams);
    let af_params: AppsflyerEvent = cbParams as AppsflyerEvent;

    if (
      'undefined' !== typeof cbParams.amount &&
      'undefined' === typeof af_params['af_revenue']
    ) {
      af_params['af_revenue'] = -cbParams.amount;
    }
    window.plugins.appsFlyer.logEvent('withdraw', af_params);
  }

  login(cbParams: AffiliateTrackerCallbackParams) {
    if (cbParams.customer_id) {
      this.appsflyer.setAppUserId(cbParams.customer_id.toString());
    }
    console.log('appsflyer.ts: event: login', cbParams);
    let af_params: AppsflyerEvent = cbParams as AppsflyerEvent;
    window.plugins.appsFlyer.logEvent('login', af_params);
  }

  gameLaunched(cbParams: AffiliateTrackerCallbackParams) {
    console.log('appsflyer.ts: event: gameLaunched', cbParams);
    let af_params: AppsflyerEvent = cbParams as AppsflyerEvent;
    window.plugins.appsFlyer.logEvent('game_launched', af_params);
  }

  betPlaced(cbParams: AffiliateTrackerCallbackParams) {
    console.log('appsflyer.ts: event: betPlaces', cbParams);
    let af_params: AppsflyerEvent = cbParams as AppsflyerEvent;
    window.plugins.appsFlyer.logEvent('bet_placed', af_params);
  }

  conversion(cbParams: AffiliateTrackerCallbackParams) {
    console.log('appsflyer.ts: event: betPlaces', cbParams);
    let af_params: AppsflyerEvent = cbParams as AppsflyerEvent;
    window.plugins.appsFlyer.logEvent('conversion_succeeded', af_params);
  }
}
