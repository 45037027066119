import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import "./polyfills.js";

import { ComponentsModule } from './components/components.module';

import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { AndroidFullScreen } from "@ionic-native/android-full-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Appsflyer } from "@ionic-native/appsflyer/ngx";
import { Facebook } from "@ionic-native/facebook/ngx";
import { Device } from "@ionic-native/device/ngx";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { TouchID } from "@ionic-native/touch-id/ngx";
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Printer } from '@ionic-native/printer/ngx';
import { PDFGenerator } from '@ionic-native/pdf-generator/ngx';
import { NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SystemModule } from './system/system.module';
import { SharedModule } from './shared/shared.module';
import { Config } from './system/providers/configuration';
import { GlobalErrorHandler } from './system/providers/global-error-handler';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { AccountModule } from './modules/account/account.module';
import { RewardsModule } from './modules/rewards/rewards.module';
import { Idle } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { PopoverModule } from './modules/popover/popover.module';
import { CashierModule } from './modules/cashier/cashier.module';

import { QRCodeModule } from 'angularx-qrcode';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';

export function ConfigLoader(config: Config) {
  return () => config.isLoaded();
}

// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// };

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient]
    //   }
    // }),
    TranslateModule.forRoot(),
    AppRoutingModule,
    ComponentsModule,
    HttpClientModule,
    SystemModule,
    SharedModule,
    AuthenticationModule,
    AccountModule,
    RewardsModule,
    NgIdleKeepaliveModule.forRoot(),
    PopoverModule,
    CashierModule,
    NgbModule,
    NgbToastModule,
    QRCodeModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [
    [
      Config,
      {
        provide: APP_INITIALIZER,
        useFactory: ConfigLoader,
        deps: [Config],
        multi: true,
      },
      GlobalErrorHandler,
    ],
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
    StatusBar,
    SplashScreen,
    ScreenOrientation,
    AndroidFullScreen,
    Device,
    AppVersion,
    TouchID,
    Appsflyer,
    Facebook,
    InAppBrowser,
    Idle,
    Printer,
    PDFGenerator,
    Clipboard,
    CookieService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
