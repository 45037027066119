import { Directive, ElementRef } from '@angular/core';
import { MyEvent } from 'src/app/service/myevent.services';

/**
 * Set height of an element
 */
@Directive({
  selector: '[setElementHeight]',
})
export class ElementHeightDirective {

  constructor(private el: ElementRef, public events: MyEvent) {
    setTimeout(
      (() => {
        this.equalHeight(this.el.nativeElement, 725); // 900
      }).bind(this),
      500
    );

    this.events.getObservable().subscribe((data) => {
      if (data.event == 'event:iframeheight') {
        if (data.data && data.data.height) {
          this.equalHeight(this.el.nativeElement, data.data.height);
        }
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.events.unsubscribe('event:iframeheight');
  }

  equalHeight(parentElem: HTMLElement, height) {
    if (!parentElem) return;
    parentElem.style.height = height + 'px';
  }
}
