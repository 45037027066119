import { SystemModule } from './../../../../../system/system.module';
import { NgModule } from '@angular/core';
import { ResetPasswordPage } from './reset-password';
import { SharedModule } from '../../../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    ResetPasswordPage,
  ],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    SystemModule,
    ReactiveFormsModule,
    TranslateModule
  ],
})
export class ResetPasswordPageModule {}
