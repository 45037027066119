export const locale = {
  lang: 'ja-JP',
  data: {
    LEFT_SIDE: {
      EARN_CATEGORY_TITLE: '稼ぐ',
      REFERRAL: '紹介で稼ぐ',
      VIP: 'VIPクラブ',
      LANGS: {
        // https://www.omniglot.com/language/names.htm
        ENGLISH: 'English',
        CHINESE: '简体中文',
        PORTUGUES: 'Português',
        THAILIAND: 'ภาษาไทย',
        VIETNAME: 'Tiếng Việt',
        SPANISH: 'Español',
        INDONESIAN: 'Bahasa Indonesia',
        JAPANESE: '日本語'
      },
      INTEREST: "interest",
      REFER_EARN: "Refer & Earn",
      VIP_CLUB: '<span style="color: #ffb636;">VIP</span> クラブ'
    },
    HEADER: {
      SPORT_BUTTON_TEXT: "スポーツ",
      CASINO_BUTTON_TEXT: "カジノ",
      PROMOTIONS: 'プロモーション',
      SEARCH: "Search",
      SEARCH_PLACEHOLDER: "ゲームまたはプロバイダを検索",
      SEARCH_PART_GAMES: "ゲーム",
      SEARCH_PART_PROVIDERS: "プロバイダ",
      SEARCH_RESULT: "Search Results",
      SEARCH_NO_RESULT: "検索結果なし",
      SEARCH_TRY_GAMES: "Recommended",
      RECOMMENDED_GAMES: "Recommended for you",
      LOGIN_BUTTON_TEXT: "ログイン",
      REGISTER_BUTTON_TEXT: "登録",
      BALANCE_INGAME: "ゲーム内残高",
      DEPOSIT_BUTTON_TEXT: "ウォレット",
      Settings: "Settings",
      ALL_GAMES: "All Games",
      SELECT_GAME: "Select Game"
    },
    BALANCES: {
      FIAT: '通常通貨',
      CRYPTO: '暗号通貨',
      DISPLAY_FIAT: '通常通貨で表示'
    },
    MOBILE_FOOTER: {
      BETSPLUS: {
        SPORT_TAB_TEXT: 'スポーツ',
        CASINO_TAB_TEXT: 'カジノ',
        PROMOTIONS_TAB_TEXT: 'プロモーション',
        MENU_TAB_TEXT: 'メニュー'
      }
    },
    DASHBOARD: {
      CATEGORY_LOBBY_NAME: "ロビー",
      LAST_PLAYED: "最近プレイしたゲーム",
      VIEWALL_BUTTON_TEXT: "全て",
      VIEWALL_BUTTON_TEXT1: "View All",
      ALL_GAMES_COUNT: "全{{count}}ゲーム",
      NO_GAMES: "ゲームデータがありません",
      GAME_PROVIDERS_TITLE: "ゲームプロバイダ",
      RECORDS: {
        TITLE: "記録",
        TABS: {
          MY_BETS_TITLE: "私のベット",
          ALL_BETS_TITLE: "すべてのベット",
          RARE_WINS_TITLE: '大当たり',
        },
        DATA: {
          BETS_TABLE: {
            COLUMN1: 'ゲーム',
            COLUMN2: 'ユーザー名',
            COLUMN3: '時間',
            COLUMN4: '賭け金',
            COLUMN5: '乗数',
            COLUMN6: '払戻し'
          },
          WINS_TABLE: {
            COLUMN1: 'ゲーム',
            COLUMN2: 'ユーザー名',
            COLUMN3: '賭け金',
            COLUMN4: '乗数',
            COLUMN5: '払戻し'
          },
          WINS_TABLE_BETSPLUS: {
            COLUMN1: 'ランク',
            COLUMN2: 'ユーザー名',
            COLUMN3: '賭け金',
            COLUMN4: '賞品'
          },
          NO_MY_BETS_TEXT: "記録なし",
          NO_ALL_BETS_TEXT: "全てのベットなし",
          NO_RARE_WINS_TEXT: "大当たりなし"
        }
      }
    },
    AUTH: {
      TOPBAR: {
        LOGIN_BUTTON_TEXT: "ログイン",
        REGISTER_BUTTON_TEXT: "登録"
      },
      LOGIN: {
        TITLE: "お帰りなさい！",
        EMAIL: 'メールアドレス',
        EMAIL_EMPTY_ERROR: "メールアドレスを入力してください",
        EMAIL_INVALID_ERROR: '有効なメールアドレスを入力してください',
        PHONE_NUMBER: "電話番号",
        PHONE_EMPTY_ERROR: '電話番号を入力してください',
        PHONE_INVALID_ERROR: '有効な電話番号を入力してください',
        PASSWORD: 'パスワード',
        PASSWORD_EMPTY_ERROR: 'パスワードは必須です',
        PASSWORD_LENGTH_ERROR: 'パスワードは6文字以上である必要があります',
        SUBMIT_BUTTON_TEXT: 'ログイン',
        DONT_HAVE_ACCOUNT: "まだアカウントをお持ちでないですか。",
        CREATE_NEW_ONE: "新規登録",
        FORGOT_PASSWORD_BUTTON_TEXT: 'パスワードをお忘れですか。',
        ERRORS: {
          NOT_EXIST: "そのメールアドレスは登録されていません",
          WRONG_PASSWORD: '誤ったパスワードが入力されました',
          TOO_MANY_ATEMP: '多くの失敗したログインのため、アカウントが無効になりました。サポートにお問い合わせください。',
          TIME_LIMIT: '制限時間を超過しました。',
          NOT_ACTIVE: "アカウントは'アクティブ'ステータスではありません。サポートにお問い合わせください。",
          PERMANENT_EXCLUDE: 'アカウントはこのサイトから永久に除外されました。',
          SUSPENDED: '自己排除されています。',
          TEMPORARY_SUSPENDED: 'アカウントが一時的に停止されました。これが24時間以内に解決しない場合は、サポートにお問い合わせください。',
          ERROR_OCCURED: 'ログイン中にエラーが発生しました。もう一度お試しください。',
          SUCCESS_LOGIN: 'ログインに成功しました',
          INACTIVITY: '不活動により前回のセッションが期限切れになりました'
        },
        USING_SOCIAL: '以下を使用してログイン'
      },
      SIGNUP: {
        TITLE: '{{sitename}}で登録',
        EMAIL: 'メールアドレス',
        EMAIL_EMPTY_ERROR: "メールアドレスを入力してください",
        EMAIL_INVALID_ERROR: '有効なメールアドレスを入力してください',
        EMAIL_ALREADY_USED_ERROR: 'そのメールアドレスはすでに使用されています。登録を完了しようとしている場合は、続きを行うためにサインインしてください。',
        PHONE_NUMBER: "電話番号",
        PHONE_EMPTY_ERROR: '電話番号を入力してください',
        PHONE_INVALID_ERROR: '有効な電話番号を入力してください',
        PHONE_ALREADY_USED_ERROR: 'その電話番号はすでに使用されています。登録を完了しようとしている場合は、続きを行うためにサインインしてください。',
        VERIFICATION_CODE: '確認コード',
        VERIFY_BUTTON_TEXT: '確認',
        GET_CODE_TXT: 'コード取得',
        CODE_SENT: 'コードが送信されました',
        RESEND: '再送信',
        PASSWORD: 'パスワード',
        PASSWORD_EMPTY_ERROR: 'パスワードは必須です',
        PASSWORD_LENGTH_ERROR: 'パスワードは6文字以上である必要があります',
        PASSOWRD_PATTERN_ERROR: 'パスワードは少なくとも1つの数字、1つの小文字、1つの大文字、1つの特殊文字（$ # ! @ - _）を含む必要があります',
        REFERRAL_CODE: '紹介コード',
        REFERAL_CODE_ERROR: 'プロモーションコードが無効です',
        TERMS_DESC: '登録することで、次のに同意するものとみなされます',
        TERMS_BUTTON_TEXT: '利用規約',
        SUBMIT_BUTTON_TEXT: 'はじめる',
        ALREADY_HAVE_ACCOUNT: "すでにアカウントをお持ちですか。",
        SIGNIN_BUTTON_TEXT: 'サインイン',
        ERRORS: {
          ALREADY_EXIST: 'そのメールアドレスはすでに存在します',
          NOT_CREATED: 'アカウントを作成できません。カスタマーサポートにお問い合わせください。'
        },
        VERIFY_CODE_REQUIRED: '確認コードは必須です',
        VERIFICATION_CODE_LENGTH_ERROR: "確認コードは6桁である必要があります",
        VERIFICATION_WRONG_CODE: "不正確な確認コード",
        SENT_VERIFY_EMAIL: "確認のためのメールを送信しました",
        SENT_VERIFY_SMS: "確認のためのSMSコードを送信しました",
        USING_SOCIAL: '以下を使用して登録',
        CAPTCHA: "CAPTCHA",
        CAPTCHA_REQUIRED: "CAPTCHAは必須です",
        CAPTCHA_PATTERN_ERROR: "有効なCAPTCHAを入力してください",
        CAPTCHA_WRONG: "無効なCAPTCHAです。もう一度お試しください"
      },
      FORGOT_PASSWORD: {
        TITLE: "パスワードを忘れましたか。",
        DESCRIPTION: 'パスワードを忘れましたか。',
        EMAIL: 'メールアドレス',
        EMAIL_EMPTY_ERROR: "メールアドレスを入力してください",
        EMAIL_INVALID_ERROR: '有効なメールアドレスを入力してください',
        PHONE_NUMBER: "電話番号",
        PHONE_EMPTY_ERROR: '電話番号を入力してください',
        PHONE_INVALID_ERROR: '有効な電話番号を入力してください',
        VERIFICATION_CODE: '確認コード',
        VERIFY_BUTTON_TEXT: '確認',
        SUBMIT_BUTTON_TEXT: '送信',
        SUCCESS_MSG: 'リセット用のメールリンクが正常に送信されました',
        VERIFICATION_CODE_LENGTH_ERROR: "確認コードは8文字である必要があります",
        SUCCESS_RESET_MSG: 'パスワードは正常にリセットされました。新しいパスワードで再ログインしてください'
      },
      LOGOUT: {
        MULTI_DEVICE: "複数のデバイスでのログインのため、ログアウトしました",
        INACTIVITY: '不活動のため、ログアウトしました',
        DEFAULT: 'ログアウトしました'
      },
      OR: 'または',
      GOOGLE_BUTTON_TEXT: "Google"
    },
    DEPOSIT: {
      CURRENCY_TITLE: '入金通貨',
      CHOOSE_PAYMENT_TITLE: '支払い方法を選択',
      AMOUNT: '金額',
      INSTANT: '即時',
      MIN: '最小',
      MAX: '最大',
      SUBMIT_BUTTON_TEXT: '入金',
      NEED_HELP: '助けが必要ですか。',
      CONTACT_SUPPORT: 'ライブサポートにお問い合わせください',
      CRYPTO_DEPOSIT_TITLE: '入金',
      CRYPTO_DEPOSIT_DESCRIPTION: 'これはあなたのプライベートデポジットアドレスです。ブロックチェーンの確認後、入金した金額はバランスに表示されます。入金が到着した際にはメールでお知らせいたします。',
      MINIMUM_DEPOSIT_AMOUNT: '最小入金額は{{amount}} {{currency}}です',
      SEND_MONEY_TO: '{{currency}}（プラスマイナーフィー）を以下に送信してください：',
      NO_PAYMENT_METHOD: '利用可能な支払い方法はありません',
      ADDRESS_COPIED: "コピーして入金"
    },
    WITHDRAW: {
      CURRENCY_TITLE: '引き出し通貨',
      CHOOSE_PAYMENT_TITLE: '支払い方法を選択',
      INFO_TITLE: '引き出し情報',
      BANK_CODE_TITLE: '発行者コード',
      BANK_NAME_TITLE: '受取人名',
      ACCOUNT_TYPE: "アカウントタイプ",
      BANK_ACCOUNT_TITLE: '口座番号',
      AMOUNT: '金額',
      INSTANT: '即時',
      MIN: '最小',
      MAX: '最大',
      SUBMIT_BUTTON_TEXT: '引き出し',
      NEED_HELP: '助けが必要ですか。',
      CONTACT_SUPPORT: 'ライブサポートにお問い合わせください',
      EXIST_ACCOUNT_TITLE: '既存のアカウント',
      ADDRESS: '住所',
      WITHDRAW_FEE_ESTIMATED: '引き出し手数料は{{amount}} {{currency}}に見積もられます',
      MINIMUM_WITHDRAW_AMOUNT: '最小引き出し額は{{amount}} {{currency}}です',
      WITHDRAW_DEDUCT_DESC: '引き出しには10％の手数料が差し引かれます',
      COMPLETE_EMAIL_VERIFY: '安全のため、引き出し前にメール認証を完了してください',
      VERIFY_EMAIL_BUTTON: 'メールアドレスの確認',
      COMPLETE_PHONE_VERIFY: '安全のため、引き出し前に電話認証を完了してください',
      VERIFY_PHONE_BUTTON: '電話の確認',
      COMPLETE_EMAIL_PHONE_VERIFY: '安全のため、引き出し前にメールと電話の認証を完了してください',
      VERIFY_EMAIL_PHONE_BUTTON: '今すぐ確認',
      COMPLETE_PROFILE: '安全のため、引き出し前にプロファイル情報を完了してください',
      ACCOUNT_BUTTON: 'アカウント',
      WITHDRAW_SUCCESS: '引き出しは送信されました。引き出し先のアカウントに注意してください。ありがとうございます',
      HOME_BUTTON: 'ホーム',
      RESTRICTED_BALANCE: '制限された残高があります。{{currency}} {{amount}}。',
      RESTRICTED_BALANCE_VIEW: '表示',
      NO_PAYMENT_METHOD: '利用可能な支払い方法はありません',
      IFSC_TITLE: 'IFSC番号',
      INVALID_AMOUNT_ERROR: {
        EMPTY_AMOUNT: '金額を入力してください',
        LESS_ZERO: '金額はゼロ未満にすることはできません',
        LESS_MIN: '金額が最小値未満です',
        GREATER_MAX: '最大値を超えています',
        GREATER_BALANCE: '残高を超えています',
        GREATER_AVAILABLE: '利用可能な残高が不足しています'
      },
      ADDRESS_REQUIRED: "アドレスは必須です",
      ADDRESS_PATTERM_ERROR: "アドレスが正しくありません"
    },
    GAME_GALLERY: {
      NO_GAME_DATA: 'リストにゲームはありません。ホームボタンをクリックして、たくさんのゲームのうちの1つをお楽しみください。',
      DISPLAY_COUNT_GAMES: '{{showCount}}件を表示中/{{totalCount}}件のゲームがあります'
    },
    GAME: {
      LOADING: '読み込み中',
      PLAY_BUTTON_WITHOUT_LOGIN: 'ログイン',
      PLAY_BUTTON_WITH_LOGIN: '実際のプレイ',
      DEMO_PLAY_BUTTON: "デモ",
      RTP: 'RTP',
      RECENT_BIGGEST_WIN: '最近の最大勝利',
      MY_GAMES_TITLE: '私のゲーム'
    },
    ACTIVE_ACCOUNT: {
      TITLE: 'メールの確認',
      EMAIL_VERIFY_DESCRIPTION: 'メールにログインして、メール内のリンクをクリックして確認を完了してください',
      GET_CODE_TEXT: 'コードを送信',
      RESEND_BUTTON_TEXT: 'コードを再送信',
      SUBMIT_BUTTON_TEXT: '送信',
      NOT_RECEIVE_TXT: "コードを受け取れませんでしたか。",
      CODE: 'コード',
      CODE_INPUT_PLACEHOLDER: 'ここにコードを入力してください'
    },
    EMAIL_VERIFY: {
      DISPLAY_INFO: '現在確認中です。しばらくお待ちください...',
      ERROR_TITLE: '申し訳ありませんが、問題が発生しました。',
      VERIFY_ERROR: 'メールコードが見つかりません',
      SUCCESS_TITLE: '成功！',
      VERIFY_SUCCESS: 'メールの確認が成功しました'
    },
    BONUSES: {
      BONUSES_TITLE: 'プロモーション',
      BONUS_TITLE: 'ボーナス',
      NO_BONUSES_DATA: '現在利用可能なプロモーションはありません',
      CHECK_LATER: '後で再確認してください！',
      MORE_DETAIL_BUTTON_TEXT: '詳細を見る',
      APPLY_BUTTON_TEXT: '今すぐ申し込む',
      TAB1: 'カジノ',
      TAB2: 'スポーツ',
      ENDS_AT: '終了：',
      EVENT_ENDED: 'イベント終了'
    },
    EMPTY_PAGE: {
      NOT_FOUND: 'おっと... ページが見つかりません。',
      REFRESH_OR_GOHOME: 'ページを更新するか、以下をクリックしてホームページに戻ってください',
      ACTION_BUTTON_TEXT: 'ホーム'
    },
    FOOTBALL_PAGE: {
      TITLE: 'スポーツ',
      DESCRIPTION: 'スポーツブックは近日公開予定です。後で再確認してください！',
      ACTION_BUTTON_TEXT: 'ホーム'
    },
    LOGOUT_CONFIRM: {
      TITLE: 'サインアウト',
      DESCRIPTION: '離れる前に、当社の豊富なカジノとスポーツのプロモーションをチェックしてください！',
      BUTTON_TEXT: 'ログアウト'
    },
    ACCOUNT_INFO: {
      UID: 'UID',
      USERNAME_TITLE: 'ユーザー名',
      EMAIL_TITLE: 'メール',
      PHONE_TITLE: '電話番号',
      PHONE_PLACEHOLDER: '電話番号を追加',
      PASSWORD_TITLE: 'パスワード',
      EDIT_BUTTON_TEXT: '編集',
      VERIFY_BUTTON_TEXT: '確認',
      ADD_EMAIL: 'メールを追加',
      COPY_BUTTON_TXT: 'コピー',
      SUMMARY_TEXT: ''
    },
    ADD_EMAIL: {
      TITLE: 'メールを追加',
      DESCRIPTION: 'アカウントのセキュリティのためにメールを追加してください',
      EMAIL_PLACEHOLDER: 'メール',
      VERIFICATION_CODE: '確認コード',
      VERIFY_BUTTON_TEXT: '確認',
      SUBMIT_BUTTON_TEXT: '保存'
    },
    CHANGE_PASSWORD: {
      TITLE: 'パスワード変更',
      CURRENT_PW_PLACEHOLDER: '現在のパスワード',
      CURRENT_PW_REQUIRED: '現在のパスワードを入力してください',
      CURRENT_PW_WRONG: '誤った現在のパスワードが挿入されました',
      NEW_PW_PLACEHOLDER: '新しいパスワード',
      NEW_PW_REQUIRED: '新しいパスワードを入力してください',
      NEW_PW_LENGTH_ERROR: 'パスワードは少なくとも6文字である必要があります',
      CONFIRM_PW_PLACEHOLDER: '新しいパスワードを再入力',
      CONFIRM_PW_REQUIRED: '新しいパスワードを確認してください',
      NEW_PW_NOT_MATCH: '挿入されたパスワードが一致しません',
      NEW_PW_NOT_CURRENT: '新しいパスワードは現在のパスワードと一致してはいけません',
      SUBMIT_BUTTON_TEXT: '保存',
      MESSAGES: {
        SUCCESS: 'パスワードが変更されました',
        WRONG_CURRENT_PASSWORD: '現在のパスワードが正しくありません。',
        NEW_NOT_MATCH_CURRENT: '新しいパスワードは現在のパスワードと一致してはいけません。'
      }
    },
    EDIT_USERNAME: {
      TITLE: 'ユーザー名を編集',
      PLACEHOLDER_TEXT: 'ユーザー名',
      SUBMIT_BUTTON_TEXT: '保存',
      MESSAGES: {
        USERNAME_REQUIRED: 'ユーザー名を入力してください。',
        USERNAME_LENGTH: 'ユーザー名は3文字以上である必要があります。'
      }
    },
    RESET_PASSWORD: {
      TITLE: 'パスワードをリセット',
      DESCRIPTION: 'パスワードをリセット',
      PASSWORD_PLACEHOLDER: 'パスワード',
      PW_LENGTH_ERROR: 'パスワードは少なくとも6文字である必要があります。',
      PW_REQUIRED: '新しいパスワードを入力してください。',
      PW_PATTERN_ERROR: 'パスワードには数字、大文字、および記号を含める必要があります。',
      CONFIRM_PW_PLACEHOLDER: 'パスワードを再確認',
      CONFIRM_PW_REQUIRED: '新しいパスワードを確認してください。',
      CONFIRM_PW_MISMATCH: 'パスワードが一致しません。',
      NEW_PW_NOT_CURRENT: '新しいパスワードは現在のパスワードと一致してはいけません。',
      SUBMIT_BUTTON_TEXT: 'リセット',
      MESSAGES: {
        TOKEN_INVALID: '提供されたトークンは無効です',
        TOKEN_EXPIRED: '提供されたトークンは期限切れです',
        SUCCESS: 'パスワードがリセットされました。パスワードを使用してログインしてください',
        FAILD: 'パスワードのリセットに失敗しました。もう一度お試しください'
      }
    },
    UPDATE_PHONE: {
      TITLE: '電話番号を編集',
      DESCRIPTION: 'アカウントのセキュリティのために電話番号を追加してください',
      PLACEHOLDER: '数字のみ',
      PHONE_REQUIRED: '電話番号を入力してください',
      PHONE_INVALID: '有効な電話番号を入力してください',
      VERIFY_CODE: '確認コード',
      VERIFY_BUTTON_TEXT: '確認',
      SUBMIT_BUTTON_TEXT: '送信',
      MESSAGES: {
        FAILD: '電話番号の更新中に問題が発生しました。カスタマーサポートにお問い合わせください。'
      }
    },
    ACTIVE_BONUSES: {
      TITLE: '有効なボーナス',
      NO_DATA: '現在、有効なボーナスはありません。',
      SHOW_RESULT: 'ボーナスの詳細を表示するには、ボーナスをクリックしてください',
      BONUS_ACCOUNT: 'ボーナスアカウント',
      DESC1: '時折、デポジットなどの特別な行動に対する報酬として「ボーナス」を受け取ることがあります。',
      DESC2: '「合計制限額」（デポジット額+ボーナス額）はゲームへのベットにのみ制限されます。ベット条件を満たすと、合計制限額は解除され、実際のお金の残高の一部となります。',
      TABLE_HEADER: {
        COLUMN1: '合計ボーナス',
        COLUMN2: '残りのボーナス',
        COLUMN3: '参照',
        COLUMN4: '開始日',
        COLUMN5: '有効期限日',
        COLUMN6: 'ステータス',
        COLUMN7: '制限額'
      }
    },
    PAST_BONUSES: {
      TITLE: '過去のボーナス',
      DESCRIPTION: '以下に、過去に受け取ったボーナスが表示されます。日付範囲を選択してください:',
      FROM_DATE_LABEL: '開始日',
      INPUT_DATE_PLACEHOLDER: '日付を選択',
      TO_DATE_LABEL: '終了日',
      SUBMIT_BUTTON_TEXT: '送信',
      NO_RESULT: '結果が見つかりませんでした。',
      SHOW_RESULT: '以下からボーナスを選択して詳細を表示:',
      TABLE_HEADER: {
        COLUMN1: '参照',
        COLUMN2: '合計ボーナス',
        COLUMN3: '開始日',
        COLUMN4: '有効期限日',
        COLUMN5: 'ステータス'
      }
    },
    BONUS_DETAIL: {
      TITLE: 'ボーナス - 詳細',
      TERMS_SERVICE: '利用規約',
      BONUS: 'ボーナス',
      START_DATE: '開始日',
      EXPIRED_DATE: '有効期限日',
      PROGRESS: '進捗',
      CASINO: 'カジノ',
      SPORTS: 'スポーツ',
      LIVE: 'Live',
      VALID_GAMES: '有効なゲーム',
      ROLLOVER: '賭け条件',
      WAGERS_REMAIN: '残りの賭け回数',
      RESTRICT_AMOUNT: '制限額',
      WINNINGS: '獲得金額',
      DEPOSIT: 'デポジット',
      TOTAL_RESTRICT_AMOUNT: '合計制限額',
      FORFEIT_BUTTON_TEXT: 'ボーナスを放棄する',
      FORFEIT_DESC1: 'このボーナスを放棄すると、以下が削除されます:',
      FORFEIT_DESC2: '{{currency}} {{winning}} の制限された獲得額。',
      FORFEIT_DESC3: '{{currency}} {{bonus}} の制限されたボーナスマネー。',
      FORFEIT_DESC4: 'これにより、デポジットから {{currency}} {{deposit}} が残ります。',
      FORFEIT_DESC5: '本当にこのボーナスを放棄しますか。',
      CANCEL_BUTTON_TEXT: 'キャンセル',
      CONFIRM_BUTTON_TEXT: 'はい、ボーナスを放棄する',
      ERRORS: {
        CLOSE_OPENED_GAMES: 'ボーナスを放棄するためには、すべての開かれているゲームを閉じてください。',
        RESOLVE_SPORTS_GAME: 'このボーナスからの資金がスポーツイベントに予約されました - まずイベントの解決をお待ちください。',
        RESOLVE_SAVED_GAME: 'このボーナスからの資金が保存されたゲームに予約されました - まずそれを解決してください。'
      },
      APPLY_BUTTON_TXT: {
        DEFAULT: '今すぐデポジット',
        LABEL1: '今すぐ申し込む',
        LABEL2: '今すぐデポジット',
        LABEL3: '今すぐプレイ',
        LABEL4: '今すぐ引き換える',
        LABEL5: '詳細を見る'
      }
    },
    REDEEM_BONUS: {
      TITLE: 'プロモーションコード',
      DESCRIPTION: '{{name}}コミュニティに参加して、プロモーションコードを受け取ろう！',
      INPUT_LABEL: 'ボーナスコード：',
      INPUT_PLACEHOLDER: 'プロモーションコード',
      SUBMIT_BUTTON_TEXT: '引き換える',
      FAILD: 'ボーナスコードが間違っているか期限切れです。別のものをお試しください。',
      NOVALID: 'プロモーションコードは利用できません',
      SUCCESS: 'プロモーションコードが正常に使用されました',
      SUCCESS_BUTTON_TXT: '終了'
    },
    REFER_FRIEND: {
      TITLE: '友達を紹介する',
      SUBTITLE1: '友達を招待してお金を稼ぐ',
      DESCRIPTION1: '{{website}}に友達を紹介するたびにキャッシュバランスを受け取ります',
      TOTAL_REFERRED: '紹介した合計ユーザー数',
      SUBTITLE2: '招待コードを共有する',
      DESCRIPTION2: 'あなたの紹介コード'
    },
    SPORTS_PAGE: {
      LOADING: '読み込み中'
    },
    GAME_HISTORY: {
      TITLE: "ゲーム履歴",
      TABLE: {
        COLUMN1: "ゲーム",
        COLUMN2: "ベット結果",
        COLUMN3: "時間",
        COLUMN4: "ベット金額",
        COLUMN5: "マルチプライヤー",
        COLUMN6: "払い戻し"
      },
      NO_HISTORY: "ゲーム履歴なし",
      SHOW_DATA_DESC: '最後の50件のゲーム履歴のみ利用可能です',
      TOTAL: "合計",
      WIN: '勝ち',
      LOSS: '負け',
      DRAW: '引き分け',
      UNSETTLED: '未解決',
      MOBILE: {
        CASH: 'スロット',
        BET_AMOUNT: 'ベット金額',
        MULTIPLIER: 'マルチプライヤー',
        PAYOUT: '払い戻し'
      },
      SHAREING: {
        SUCCESS: {
          TITLE1: '単なる勝利以上、',
          TITLE2: 'ここで追加のトークンと配当を獲得してください。',
          BET_AMOUNT: 'ベットによって',
          MULTIPLIER: "{{value}}倍のマルチプライヤー",
          PLACED_USER: '投稿者: {{username}}',
          REFERRAL_CODE: '紹介コード',
          CTA_SENTENCE2: '今すぐ共有して抽選リワードを獲得',
          DOWNLOAD_BTN: 'ダウンロード',
          SHARE_BTN: '共有'
        },
        FAILD: {
          TITLE: '共有に失敗',
          ALREADY_SHARED_GAME_LOG: 'この勝利は他の人によって共有されています。',
          ABOVE_ONE_MULTIPLIER: '1倍以上のマルチプライヤーのトランザクションのみ共有できます。',
          EXCEEDED_DAILY: '日次制限に達しました。'
        }
      }
    },
    LEADERBOARD: {
      TITLE: 'リーダーボード',
      TABLE: {
        COLUMN1: "",
        COLUMN2: "",
        COLUMN3: "プレイヤー",
        COLUMN4: "合計 $FUN トークン",
        COLUMN5: "リワード"
      },
      NO_DATA: 'リーダーボードなし'
    },
    NEW_BANK: {
      TITLE: '銀行情報',
      PAYMENT_TYPE: '支払いタイプ',
      SELECT_PAYMENT_TYPE: '支払いタイプを選択',
      BANK_LIST: '銀行リスト',
      SELECT_BANK: '銀行を選択',
      NAME: 'フルネーム',
      ACCOUNT_NUMBER: '口座番号',
      ACCOUNT_NUMBER_DESC: '1 から 17 桁の数字のみ',
      SUBMIT_BUTTON_TXT: '提出',
      ADD_NEW_BUTTON_TXT: '新しい追加',
      CLOSE_BUTTON_TXT: '閉じる'
    },
    PAYMENTS: {
      DEPOSIT_TXT: 'デポジット',
      WITHDRAW_TXT: '引き出し'
    },
    PROFILE_INFO: {
      TITLE: '個人情報',
      FIRST_NAME: '名',
      FIRST_NAME_REQURIED_ERROR: '正式な名前を入力してください。',
      FIRST_NAME_PATTERN_ERROR: '名前にはアルファベット、スペース、ハイフン、ピリオドのみ使用できます。最低2文字、最大20文字入力してください。',
      LAST_NAME: '姓',
      LAST_NAME_REQUIRED_ERROR: '正式な姓を入力してください。',
      LAST_NAME_PATTERN_ERROR: '姓にはアルファベット、スペース、ハイフン、ピリオドのみ使用できます。最低2文字、最大20文字入力してください。',
      BIRTHDAY: {
        TITLE: '生年月日',
        YEAR: '年',
        MONTH: '月',
        DAY: '日',
        ERROR: '無効な生年月日です。'
      },
      ADDRESS: {
        STREET: '住所',
        STREET_REQURIED_ERROR: '住所を入力してください。',
        PO_BOX_ERROR: 'POボックスは使用できません。',
        COUNTRY: '国',
        STATE: '都道府県',
        CITY: '市区町村',
        CITY_REQURIED_ERROR: '市区町村を入力してください。',
        CITY_PATTERN_ERROR: '有効な市名を入力してください。市名には数字を含めないでください。',
        ZIPCODE: '郵便番号',
        ZIPCODE_REQUIRED_ERROR: '郵便番号を入力してください。',
        ZIPCODE_PATTERN_ERROR: '文字と数字のみを入力してください。スペースや特殊文字は使用できません。',
        ZIPCODE_MINLENGTH_ERROR: '郵便番号は最低でも5文字必要です。'
      },
      SUBMIT_BUTTON_TXT: '送信',
      CHOOSE_FILE: '本人確認書類/ファイルを選択',
      FILE_NOTE: "*提出可能な書類: パスポート、運転免許証、またはIDカード。",
      ALERT: {
        SUCCESS: {
          TITLE: '確認',
          MSG: '更新が正常に完了しました'
        }
      }
    },
    PROVIDERS: {
      TITLE: 'ゲームプロバイダー',
      GAMES_LENGTH: '{{count}} ゲーム',
      GAMES: 'ゲーム',
      CURRENT_DISPLAYING: '全{{total}}プロバイダーの中から{{count}}を表示中'
    },
    TERMS: {
      TITLE: '利用規約',
      PRINT_BUTTON_TXT: '印刷',
      DOWNLOAD_BUTTON_TXT: 'ダウンロード'
    },
    TRANSACTIONS: {
      TITLE: '取引',
      DEPOSIT_BUTTON_TXT: '入金',
      WITHDRAW_BUTTON_TXT: '出金',
      BONUS_BUTTON_TXT: 'ボーナス',
      NO: 'なし',
      SHOW_DATA_DESC: '最後の50件の取引のみ表示可能',
      TOTAL: '合計',
      deposit: '入金',
      withdraw: '出金',
      bonuses: 'ボーナス',
      STATE: {
        CONFIRMED: "Confirmed",
        UNCONFIRMED: "Unconfirmed",
        UNSUCCESSFUL: "Unsuccessful"
      },
      DEPOSIT_TABLE: {
        COLUMN1: '注文ID',
        COLUMN2: '時間',
        COLUMN3: 'ステータス',
        COLUMN4: '通貨',
        COLUMN5: '入金',
        COLUMN6: '入金前の残高',
        COLUMN7: '入金後の残高',
        COLUMN8: '金額'
      },
      WITHDRAW_TABLE: {
        COLUMN1: '注文ID',
        COLUMN2: '時間',
        COLUMN3: 'ステータス',
        COLUMN4: '通貨',
        COLUMN5: '引き出し',
        COLUMN6: '引き出し前の残高',
        COLUMN7: '引き出し後の残高',
        COLUMN8: '金額'
      },
      BONUSES_TABLE: {
        COLUMN1: 'タイプ',
        COLUMN2: '注文ID',
        COLUMN3: '時間',
        COLUMN4: 'ボーナス金額',
        COLUMN5: 'Bonus Name'
      }
    },
    USER_MENU: {
      WALLET: 'ウォレット',
      TRANSACTIONS: '取引',
      GAME_HIISTORY: 'ゲーム履歴',
      KYC_INFO: 'KYC',
      VIP: 'VIPクラブ',
      REDEEM_COUPON: 'クーポンを引き換える',
      REFERRAL: '紹介して稼ぐ',
      PROMOTIONS: 'プロモーション',
      ACTIVE_BONUS: 'アクティブボーナス',
      PAST_BONUS: '過去のボーナス',
      LIVE_SUPPORT: 'ライブサポート',
      ACCOUNT: 'プロフィール'
    },
    STEP_COMPONENT: {
      STEP_TXT: 'ステップ'
    },
    PLAYER_VIP: {
      MY_DATA: {
        VIP_PROGRESS: "あなたのVIP進捗",
        TOOLTIP: '各$1の賭けで1XPが獲得されます。プレイすればするほど、最高ランクに迅速に到達します。',
        RAKEBACK: 'レーキバック',
        RANK_UP_BUTTON_TXT: 'ランクアップ',
        TOTAL_CLAIMED: '獲得した総レーキバック',
        CLAIM_BUTTON_TXT: '請求',
        REMAIN_UNTIL_NEXT: "{{xp}} XPでVIP {{level}}まで",
        REACHED_MAX_LEVEL: "最大レベルに到達しました！"
      },
      BETSPLUS: {
        LEVEL: "レベル",
        REQUIRED_XP: '必要XP {{amount}} {{currency}}',
        LEVELUP_DESC: 'プログレスバーを満たすたびに報酬がもらえます。 レベルアップすると、より大きな報酬が得られます！',
        WITHDRAWN_AMOUNT: '引き出し可能な金額',
        TOTAL_REWARDS: '利用可能な合計報酬',
        EXCHANGE_BUTTON_TXT: '交換',
        CLAIM_BUTTON_TXT: '請求',
        REWARD_REDEMPTION: "報酬の引き換え",
        TOTAL_CLAIMED: '総報酬請求額',
        REWARD: "報酬"
      }
    },
    REFERRALS: {
      MY_REFERRALS: {
        TOTAL_COMMISSION_EARNING: '総紹介手数料の収益',
        REFERRED_YOU: 'あなたを紹介した人',
        AMOUNT_REFERRED: '紹介したユーザー数',
        NUMBER_OF_USERS: "Number of users"
      },
      BETSPLUS: {
        TITLE: 'Millionaire Project',
        DESCR1: '最大 <span style="color: #FFC83A;">₦{{value}}</span> を獲得',
        DESCR2: 'および最大 <span style="color: #FFC83A;">{{percent}}%</span> の手数料',
        HOWTOWORK: '動作方法',
        TOOLTIP_DETAIL: 'Step 1: Share referral code to friends and social media. <br> Step 2: Guide them to get their deposit bonus. <br> Step 3: Recommend some great games for them. <br> Step 4: Claim and spend your referral rewards. <br> Step 5: Repeat the steps!',
        INVITE_SECT: {
          TITLE: 'ユーザーを招待',
          SHARES: 'シェア',
          INVITEES: '招待者',
          INVITE_LINK: '招待リンク',
          INVITE_QR_CODE: '招待QRコード',
          QUICK_SHARE: 'クイックシェア',
          DOWNLOAD_BTN: 'ダウンロード'
        },
        DATA_CENTER: {
          TITLE: 'データセンター',
          TOTAL_REVENUE: '総収益',
          INVITATION: '招待',
          WAGER: '賭け金',
          ACHIEVEMENT: '達成',
          VIP: 'VIP',
          MORE_BTN: '詳細'
        },
        REWARD_SECT: {
          TITLE: '報酬の引き換え',
          AMOUNT_WITHDRAWN: '請求可能な金額',
          EXCHANGE_BUTTON_TXT: '交換',
          CLAIM_BUTTON_TXT: '請求',
          TOTAL_REWARD: '利用可能な総報酬: {{value}}'
        },
        ACTIVITY_RULES: {
          TITLE: 'アクティビティのルール',
          SEE_MORE: '詳細を見る',
          DATA1: {
            LABEL: '招待ボーナス',
            TEXT: '友達を招待し、その友達が20ドルに達するデポジットを行うたびに、5ドルの報酬がもらえます。'
          },
          DATA2: {
            LABEL: 'ベッティング手数料',
            TEXT: 'ボーナスのルール: "プロモーター"レベルのすべてのプレイヤーは、すべてのベットに対してプラットフォームのアドバンテージボーナスの一部を受け取ります。'
          },
          DATA3: {
            LABEL: '達成ボーナス',
            TEXT: '累積招待数が条件を満たす場合、追加の達成報酬がもらえます。 <br> 招待する人数が増えるほど、報酬が高くなります。'
          },
          DATA4: {
            LABEL: 'VIPレベルアップボーナス',
            TEXT: '招待したすべてのユーザーがゲーム内で指定されたレベルに到達すると、追加のVIPレベルアップボーナスがもらえます。 <br> USDはプラットフォームによって導入された特別な通貨です。 あなたは…'
          }
        },
        AMONG_BEST: '最高の中に',
        LIVE_REWARD: 'ライブ報酬',
        QA: {
          TITLE: 'Q&A'
        },
        EXCHANGE: {
          MESSAGE: '受信が成功しました',
          CONFIRM_BUTTON_TXT: '確認'
        },
        ACHIEVE_BONUS: {
          TITLE: '達成ボーナス',
          DESCRIPTION: '累積招待数が条件を満たす場合、追加の達成報酬がもらえます。 <br> 招待する人数が増えるほど、報酬が高くなります。',
          TABLE: {
            COLUMN1: '友達のレベル',
            COLUMN2: '数人',
            COLUMN3: '報酬'
          }
        },
        BETTING_COMMISSION: {
          TITLE: 'ベッティング手数料',
          SHORT_DESC: 'ボーナスルール: "Promoter" レベルのすべてのプレイヤーは、各ベットに対してプラットフォームのアドバンテージボーナスの一部を受け取ります。',
          LONG_DESC: `3つのレベルの比率は以下のとおりです <br>
          レベル1: 1%賭け金の35%を取得 <br>
          レベル2: 1%賭け金の12.25%を取得 <br>
          レベル3: 1%賭け金の4.29%を取得 <br>
          これはあなたの長期的な収入となり、紹介したプレイヤーがベットをするたびに、彼が勝つか負けるかに関係なく、異なる割合の手数料を受け取ります。ですので、あなたがやるべきことは、ゲームのスキルを向上させ、どのようにゲームに勝利するかを考え、それを他の人と共有し、あなたの方法でより多くの人が勝利するのを助けることです。私たちは、すべてのプレイヤーが当社のプラットフォームで楽しんでほしいと考えています。それがベットに勝つ楽しみであろうと、ゲームそのものであろうと！`
        },
        USER_LIST: {
          TITLE: "Users List",
          TABS: {
            TAB1: 'サブユーザー',
            TAB2: '下位ユーザー'
          },
          SEARCH_PLACEHOLDER: 'ユーザーUIDで検索',
          SELECTS: {
            ITEM1: '全て',
            ITEM2: '今日',
            ITEM3: '過去7日間',
            ITEM4: '過去30日間',
            ITEM5: '過去60日間'
          },
          TABLE: {
            COLUMN1: 'Email / Phone',
            COLUMN2: '時間',
            COLUMN3: 'デポジット',
            COLUMN4: 'ベッティング',
            COLUMN5: '招待'
          },
          TOTAL: '合計',
          NO_DATA: "No user data"
        },
        VIP_LEVEL_BONUS: {
          TITLE: 'VIP レベルアップボーナス',
          DESC: '招待したすべてのユーザーがゲーム内の指定されたレベルに達すると、追加の VIP レベルアップボーナスがもらえます。USDはプラットフォームによって発行された特別な通貨です。いつでもUSDを他の通貨に交換できます。',
          TABLE: {
            COLUMN1: '友達のレベル',
            COLUMN2: '総賭け金',
            COLUMN3: '報酬'
          }
        }
      }
    },
    KYC: {
      TITLE: "KYC（本人確認）",
      DESC: "ナイジェリア国立宝くじ規制委員会（NLRC）およびキュラソー運営ライセンスの規制要件により、の詐欺、不正行為、および不審な活動を防ぐために、メールアドレスと電話番号の確認が必要です。問題が発生した場合は、ライブチャットからもサポートを受けることができます。",
      BUTTON_TXT: "アカウント"
    },
    GOOGLE_LOGIN: {
      EERROR_MSG: 'アカウント情報でログインできませんでした。他のアカウントで再試行してください。'
    },
    SUCCESS_MSG: {
      TITLE: '成功！'
    },
    ERROR_MSG: {
      TITLE: '申し訳ありませんが、問題が発生しました'
    },
    COMMON: {
      COMING_SOON: "近日公開",
      LOAD_MORE: "もっと読む",
      LOGOUT: 'ログアウト',
      DEPOSIT: 'デポジット',
      WITHDRAW: '引き出す',
      INVALID_AMOUNT: '無効な金額',
      HOME_BUTTON_TXT: 'ホーム',
      BACK_BUTTON_TXT: '戻る',
      COPIED: 'コピーしました',
      OK: "OK",
      CANCEL: "キャンセル",
      SEARCH: "検索"
    },
    LUCKSPIN: {
      TITLE: "Lucky Spin",
      TRY_AGAIN: "Try again",
      BUTTON_TEXT: "Spin",
      WIN_SPIN_TEXT: "LUCKY WIN SPIN NOW",
      BOTTOM_TEXT: "DAILY FREE SPINS",
      AVAILABLE_SPINS: "Available spins"
    },
    ERRORS: {
      400: '不正なステータス',
      401: '認証されていません',
      402: '支払いが必要です',
      404: '見つかりません',
      500: '内部サーバーエラー',
      503: '[Ezugi] ゲーム識別子が無効です',
      504: '[Ezugi] 操作が無効です',
      506: '[Ezugi] 通貨が無効です',
      508: '[Ezugi] トランザクション識別子が無効です',
      511: '[Leander] トークンが無効です',
      1002: '[Evolution] セッション ID が無効です',
      1007: '[NYX] RC 不明な通貨',
      6001: '[Vanguard] ゲームセッションが無効です',
      6003: '[Vanguard] 認証に失敗しました',
      40011: '[RGS] ゲームタイプが無効です',
      40012: '[RGS] ゲーム ID が無効です',
      10000: 'アカウント制限超過',
      10001: 'Addacct 失敗',
      10002: '年齢確認失敗',
      10004: '既にアカウントが存在します',
      10005: '既に登録済み',
      10006: '既に送信済み',
      10010: '不正な引数',
      10017: 'ボーナスコードが見つかりません',
      10018: 'ボーナスは存在しません',
      10019: 'ボーナスが無効です',
      10021: '承認できない支払いリクエスト',
      10022: '支払いリクエストをキャンセルできません',
      10024: '支払いを処理できません',
      10025: '支払いリクエストを処理できません',
      10034: '通貨コードが見つかりません',
      10037: '顧客は既に登録済み',
      10038: '顧客は存在しません', //
      10042: '顧客はブラックリストに載っています',
      10045: '顧客が見つかりません', //
      10046: '顧客が登録されていません', //
      10047: '顧客が自己除外しています',
      10049: '顧客が停止しています',
      10052: '顧客確認が見つかりません',
      10055: 'Cust 更新に失敗しました',
      10059: '無効なアカウント',
      10064: 'メールアドレスは既に存在します',
      10066: 'メールコードが見つかりません',
      10069: 'メールが使用中です',
      10071: 'メールが見つかりません',
      10143: 'ファイル形式が無効です',
      10145: 'ゲームは既に開始されています',
      10146: 'ゲームコードが無効です',
      10150: 'ゲームが見つかりません',
      10154: '残高を取得する',
      10155: '残高取得エラー',
      10170: '資金不足',
      10173: '無効な最大金額',
      10174: '無効な最小金額',
      10177: '無効なボーナス ID',
      10178: '無効なボーナスタイプ',
      10186: '無効な顧客',
      10190: '無効なゲーム ID',
      10200: '無効なセッション',
      10207: '無効なトークン',
      10221: 'ログイン失敗',
      10222: '最大ログイン数に達しました',
      10223: 'ログインのパスワードが間違っています',
      10224: 'ログインのユーザー名が間違っています',
      10225: 'ログアウト失敗',
      10246: 'パスワードが一致しません',
      10250: '電話番号は既に存在します',
      10307: 'ユーザー名は既に存在します',
      10308: '生年月日の検証に失敗しました',
      10325: 'そのメールアドレスはまだ登録されていません',
      10326: 'そのメールアドレスは既にGoogle SSOアカウントとして使用されています',
      10327: '誤った紹介コード',
      10328: '顧客は紹介手数料を支払っていません',
      10329: 'レベルアップできません',
      10330: 'VIPキャッシュバックの請求に失敗しました',
      10331: '無効な顧客VIPレベル',
      10332: '紹介手数料の請求に失敗しました',
      10333: '紹介実績情報の取得に失敗しました',
      10334: '無効な電話番号',
      10341: "Not enough value to claim bonus",
      11507: '国が見つかりません',
      11511: '無効なカジノボーナス',
      11512: 'ボーナスを失効するにはすべての開いているゲームを閉じてください',
      11513: 'このボーナスからの保存されたゲームは解決する必要があります',
      11514: 'このボーナスからのスポーツイベントの予約資金は解決を待ってください',
      11515: '無効なカジノボーナス',
      11516: '入金なしのボーナスを失効できませんでした',
      11517: 'プレイヤーのデイリークレジットが不足しています',
      11518: '賞を取得できませんでした',
      11519: '賞のデイリーカウントが不足しています',
      11520: '別のメールを送信する前にクールダウン期間を待ってください',
      11521: '別のSMSを送信する前にクールダウン期間を待ってください',
      11522: '1日に送信するためのメールの最大回数に達しました',
      11523: '1日に送信するためのSMSの最大回数に達しました',
      11526: "ClubPay Exception",
      100000: 'サインアップコードの検証に失敗しました',
      100002: 'SMS検証の送信に失敗しました'
    },
    BONUS_TYPES: {
      "Deposit": "Deposit",
      "Withdrawal": "Withdrawal",
      "Table Buyin": "Table Buyin",
      "Table Rebuy": "Table Rebuy",
      "Table Leave": "Table Leave",
      "Place Bet": "Place Bet",
      "Tournament Buyin": "Tournament Buyin",
      "Tournament Fee": "Tournament Fee",
      "Tournament Buyin Refund": "Tournament Buyin Refund",
      "Tournament Fee Refund": "Tournament Fee Refund",
      "Manual Adjustment": "Manual Adjustment",
      "Recovery Refund": "Recovery Refund",
      "Transfer In": "Transfer In",
      "Transfer Out": "Transfer Out",
      "Round Win": "Round Win",
      "Bonus Win": "Bonus Win",
      "Deposit Bonus": "Deposit Bonus",
      "Account Creation Bonus": "Account Creation Bonus",
      "Referral Bonus": "Referral Bonus",
      "Bonus Conversion": "Bonus Conversion",
      "Login Grant": "Login Grant",
      "Initial Grant": "Initial Grant",
      "Virtual Goods Purchase": "Virtual Goods Purchase",
      "Loyalty": "Loyalty",
      "Winning in random scratcher": "Winning in random scratcher",
      "Mystery Gift Win": "Mystery Gift Win",
      "FB Credit Order": "FB Credit Order",
      "Leader Board Points": "Leader Board Points",
      "Tournament Award": "Tournament Award",
      "Returning Player Bonus": "Returning Player Bonus",
      "Cancelled round refund": "Cancelled round refund",
      "Sit & Go Award": "Sit & Go Award",
      "Sit & Go Buyin": "Sit & Go Buyin",
      "Sit & Go Fee": "Sit & Go Fee",
      "Sit & Go Buyin Refund": "Sit & Go Buyin Refund",
      "Sit & Go Fee Refund": "Sit & Go Fee Refund",
      "Fast Poker buyin": "Fast Poker buyin",
      "Fast Poker leave": "Fast Poker leave",
      "Fast Poker rebuy": "Fast Poker rebuy",
      "Tournament Rebuy": "Tournament Rebuy",
      "Tournament AddOn": "Tournament AddOn",
      "Sit & Go Rebuy": "Sit & Go Rebuy",
      "Sit & Go AddOn": "Sit & Go AddOn",
      "Bonus Deposit": "Bonus Deposit",
      "Bonus Released": "Bonus Released",
      "Bonus Deposit Released": "Bonus Deposit Released",
      "Bonus Forfeit": "Bonus Forfeit",
      "Bonus Expired": "Bonus Expired",
      "Bonus Winning Released": "Bonus Winning Released",
      "Void Deposit": "Void Deposit",
      "Bonus Deposit Forfeit": "Bonus Deposit Forfeit",
      "Bonus Winnings Forfeit": "Bonus Winnings Forfeit",
      "Bonus Deposit Expired": "Bonus Deposit Expired",
      "Bonus Winnings Expired": "Bonus Winnings Expired",
      "SnG Buyin Recovery Refund": "SnG Buyin Recovery Refund",
      "SnG Fee Recovery Refund": "SnG Fee Recovery Refund",
      "Tournament Buyin Recovery Refund": "Tournament Buyin Recovery Refund",
      "Tournament Fee Recovery Refund": "Tournament Fee Recovery Refund",
      "Clearing released bonus": "Clearing released bonus",
      "Clearing released bonus deposit": "Clearing released bonus deposit",
      "Clearing released bonus winnings": "Clearing released bonus winnings",
      "Void Withdrawal": "Void Withdrawal",
      "Bonus Deposit Forfeit Cleared": "Bonus Deposit Forfeit Cleared",
      "Bonus Deposit Expired Cleared": "Bonus Deposit Expired Cleared",
      "Bonus Cash Grant": "Bonus Cash Grant",
      "Place a Purchase": "Place a Purchase",
      "Cancel a Purchase": "Cancel a Purchase",
      "Tournament Rebuy Refund": "Tournament Rebuy Refund",
      "Tournament Rebuy Recovery Refund": "Tournament Rebuy Recovery Refund",
      "Tournament AddOn Refund": "Tournament AddOn Refund",
      "Tournament AddOn Recovery Refund": "Tournament AddOn Recovery Refund",
      "SnG Rebuy Refund": "SnG Rebuy Refund",
      "SnG Rebuy Recovery Refund": "SnG Rebuy Recovery Refund",
      "SnG AddOn Refund": "SnG AddOn Refund",
      "SnG AddOn Recovery Refund": "SnG AddOn Recovery Refund",
      "Bounty Tournament Award": "Bounty Tournament Award",
      "Jackpot winning": "Jackpot winning",
      "Collectible game award": "Collectible game award",
      "Claim reward": "Claim reward",
      "Cancel reward": "Cancel reward",
      "Coupon redeemed": "Coupon redeemed",
      "Tournament Bounty Buyin": "Tournament Bounty Buyin",
      "Tournament Bounty Buyin Refund": "Tournament Bounty Buyin Refund",
      "Tournament Bounty Buyin Recovery Refund": "Tournament Bounty Buyin Recovery Refund",
      "SnG Bounty Buyin": "SnG Bounty Buyin",
      "SnG Bounty Buyin Refund": "SnG Bounty Buyin Refund",
      "SnG Bounty Buyin Recovery Refund": "SnG Bounty Buyin Recovery Refund",
      "Reward Claimed Refund": "Reward Claimed Refund",
      "Void reward": "Void reward",
      "Tournament Prize Pool Distribution On Recovery": "Tournament Prize Pool Distribution On Recovery",
      "SnG Prize Pool Distribution On Recovery": "SnG Prize Pool Distribution On Recovery",
      "Chips Coupon Claimed": "Chips Coupon Claimed",
      "Milestone Bonus": "Milestone Bonus",
      "External Grant": "External Grant",
      "Void Bet": "Void Bet",
      "Daily TopOff": "Daily TopOff",
      "Bonus Grant": "Bonus Grant",
      "Bonus Used": "Bonus Used",
      "Sport Bet": "Sport Bet",
      "Sport Win": "Sport Win",
      "Sport Win Adjustment": "Sport Win Adjustment",
      "Sport Round Refund": "Sport Round Refund",
      "Sport Round Void": "Sport Round Void",
      "Round Win Bonus": "Round Win Bonus",
      "IBC Sport Unsettle": "IBC Sport Unsettle",
      "IBC Sport Adjust Balance": "IBC Sport Adjust Balance",
      "VIP Level rank-up bonus": "VIP Level rank-up bonus",
      "VIP Level cashback bonus": "VIP Level cashback bonus",
      "Referral commission bonus": "Referral commission bonus",
      "Any Deposit Rollover Bonus": "Any Deposit Rollover Bonus",
      "KYC bonus": "KYC bonus",
      "Daily Lose Rake Back": "Daily Lose Rake Back",
      "Daily lose cashback bonus": "Daily lose cashback bonus",
      "IBC Sport Settled": "IBC Sport Settled"
    }
  }
};
