import { Component, Input } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';

@Component({
  selector: 'app-popover',
  templateUrl: 'popover.html',
})
export class PopoverContentPage {
  @Input() data: any;

  logoutButtonFlag: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    public popCtrl: PopoverController,
    private events: MyEvent
  ) {}

  closePopover() {
    if (this.logoutButtonFlag) {
      this.events.publishSomeData({ event: 'event:authentication:logout' });
    } else {
      this.modalCtrl.dismiss();
      this.popCtrl.dismiss();
    }
  }
}
