import { CashierProvider } from '../../providers/cashier-provider';
import { Component, ViewChild, OnInit } from '@angular/core';
import { Navigator } from './../../../../system/components/menu/navigator';
import { SuccessMessageProvider } from './../../../../system/providers/success-message-provider';
import { Utils } from '../../../../shared/utils';
import { IonContent } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';
import { CustomerModel } from 'src/app/modules/account/models/customer-model';
import { UserSessionProvider } from 'src/app/system/providers/user-session';

@Component({
  selector: 'page-my-transaction-methods',
  templateUrl: 'my-transaction-methods.html',
  styleUrls: ['./my-transaction-methods.scss'],
})
export class MyTransactionMethodsPage implements OnInit {

  @ViewChild('Content') content: IonContent;

  error = null;
  accounts: any = [];
  eventSub: any;

  customer: CustomerModel;

  constructor(
    private events: MyEvent,
    public utils: Utils,
    private cashierProvider: CashierProvider,
    public successMessageProvider: SuccessMessageProvider,
    public userSession: UserSessionProvider,
  ) {
    this.successMessageProvider.clearMessage();
    this.error = null;

    this.eventSub = this.events.getObservable().subscribe(data => {
      if (data.event == 'new:bank:added') {
        this.onGetAccounts();
      }
    });

    this.userSession.getCustomer().subscribe((customer) => {
      if(customer) {
        this.customer = customer;
      }
    });
  }

  ngOnInit() {

  }

  ionViewDidEnter() {
    this.successMessageProvider.clearMessage();
    this.onGetAccounts();
  }

  ionViewWillLeave() {
    this.eventSub.unsubscribe();
  }

  onGetAccounts() {
    this.accounts = [];
    this.cashierProvider.callListAccounts().subscribe(
      (response: any) => {
        if (response.code == 200 && !!response.result) {
          let tempAccounts: any = response.payload;
          let filtered_methods = ['IDEBIT', 'INSTADEBIT', 'INTERAC'];
          for (let index in tempAccounts) {
            if (
              filtered_methods.indexOf(tempAccounts[index].paymentTypeName) ==
              -1
            ) {
              this.accounts.push(tempAccounts[index]);
            }
          }
        }
      },
      (error) => {
        console.error('transaction method error == ', error);
        this.error = error;
      }
    );
  }

  onRemoveAccount(account: object) {
    Navigator.navigateTo(
      'MyTransactionMethodsConfirmPage',
      { multipleModals: true },
      { account: account }
    );
  }

  onGoToDeposit() {
    Navigator.navigateTo('PaymentsPage');
  }

  addNewBank() {
    if (!this.customer.emailValidated) {
      Navigator.navigateTo(
        "ActiveAccountPage",
        {
          forceModal: true,
          disableBackdrop: false,
          cssClass: "add_email_modal"
        },
        {
          callback: () => {
            Navigator.back();
            this.userSession.refreshCustomer();
          }
        }
      );
    }
    else if (!this.customer.firstName || !this.customer.lastName || !this.customer.birthDate) {
      Navigator.navigateTo(
        "ProfileInfoPage",
        {
          multipleModals: true,
          disableBackdrop: false
        },
        {
          callBackFunc: () => {
            this.openAddBank();
          }
        }
      );
    } else if (this.customer.status.toLowerCase() == 'active') {
      this.openAddBank();
    }
  }

  openAddBank() {
    Navigator.navigateTo(
      'NewBankPage',
      { multipleModals: true },
      {}
    );
  }
}
