import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { Navigator } from 'src/app/system/components/menu/navigator';

@Component({
  selector: 'app-exchange-confirm',
  templateUrl: './exchange-confirm.component.html',
  styleUrls: ['./exchange-confirm.component.scss'],
})
export class ExchangeConfirmComponent implements OnInit {

  @Input() amount: any;
  @Input() currency: any;

  constructor(
    public comService: CommonService
  ) { }

  ngOnInit() {}

  close() {
    Navigator.back();
  }

}
