
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
//
import { Device } from '@ionic-native/device/ngx';
import { WebservicesProvider, PlatformResponse }  from '../../system/providers/webservices';
import {Config} from '../../system/providers/configuration';
import {UserSessionProvider} from '../../system/providers/user-session';
import { CustomerModel } from '../../modules/account/models/customer-model';
import { MyEvent } from 'src/app/service/myevent.services';
import { Platform } from '@ionic/angular';
declare var require: any;
declare var window: any;
var GeoComply = require("../../vendor/geocomply/geocomply-client.min.js");
// DISABLED BECAUSE THIS JS FILE IS LOADED BY CORDOVA: //require( "../../vendor/geocomply/geocomply_sdk_wrapper.js");
declare var GCOobee: any;

export class GeoComplyReasons
{
    static readonly firstGeolocation: string = "FIRST_GEOLOCATION";
    static readonly periodic: string = "PERIODIC";
}

export class GeoComplyAppStores
{
    static readonly ios: string = 'https://itunes.apple.com/us/app/oobee/id921993633';
    static readonly android: string = 'https://play.google.com/store/apps/details?id=com.geocomply.oobeeapp&hl=en';
    //static readonly android: string ='https://play.google.com/store/apps/details?id=com.geocomply.plv';
}

export interface GeoComplyStatus
{
    error_code: number | string;
    error_message: string;
    error_details: string;
    geolocate_in: number;
    hint: GeoComplyHint;
}

class OobeeOptions
{
    static readonly enablePlayStorePopUp:boolean = false;
}

export interface GeoComplyHint
{
    reason: string;
    message: string;
    iosWakeupUrl: string;
    androidWakeupUrl: string;
    androidWakeupIntent: string;
    wakeupUrl?: string;
}

const coerceInt = (a: string|number) => ( "string" === typeof a ? parseInt(a) : a);

@Injectable({
  providedIn: 'root'
})
export class GeoComplyProvider
{
    private gc_installer_id: string;
    private gc_env_id: string;
    private gc_license: string;
    private gc_expires: number;
    private user_id: string;
    private reason: string;
    private latestResult: object;
    private next_required: number;        // timestamp when next geo is required to be run
    private lastError: string;
    private now_ts: number;
    private customer: CustomerModel;
    private geoComplyStatus: Subject<GeoComplyStatus>;
    private geoComplySdkWrapper: any;
    public isMobileWeb: boolean = false;
    private mobileWebClient: any;
    private numClientConnectionErrors: number = 0;
    private deviceUid: string;
    private pairCode: string;
    private oobee_serviceurl: string;
    private oobee_oobeeurl: string;
    private _oobeeEventListeners: Array< Array<any>>;
    private hint: GeoComplyHint;

    constructor(
        private config: Config,
        private events: MyEvent,
        private platform: Platform,
        private device: Device,
        private webservices: WebservicesProvider,
        private userSession: UserSessionProvider
    ) {
        this.platform.ready().then( () => {
            if( platform.is('cordova')) {
                this.geoComplySdkWrapper = window.cordova.plugins.geoComplySdk;
            } else if( platform.is( 'mobile')) {        // mobile && !cordova means mobile-web
                this.isMobileWeb = true;
            }
        });
        this.geoComplyStatus = new Subject<GeoComplyStatus>();
        //
        this.config.isLoaded().then( () => {
            let config = this.config.getConfig();
            this.gc_installer_id = config.FEATURE_GEOCOMPLY_OPERATOR_ID;
            this.gc_env_id = config.FEATURE_GEOCOMPLY_ENV_ID;
            this.oobee_serviceurl = config.FEATURE_GEOCOMPLY_OOBEE_SERVICEURL;
            this.oobee_oobeeurl = config.FEATURE_GEOCOMPLY_OOBEE_OOBEEURL;
        });
        //
        this.userSession.getCustomer().subscribe(
            (customer) => {
                if (!this.customer || !customer || (customer.customerId !== this.customer.customerId)) {
                    this.customer = customer;
                }
            }
        );
   }

    protected setTimestamp()
    {
        this.now_ts = (new Date()).getTime();
    }

    protected getTimestamp()
    {
        return this.now_ts;
    }

    protected initialized: boolean = false;
    protected installed:boolean = false;
    protected connected:boolean = false;

    public isInstalled(): boolean {
        return this.installed;
    }

    public isConnected(): boolean {
        return this.connected;
    }

    /**
     * initialize GeoComply client
     */
    public initialize(): void
    {
        this.connected = false;
        if (this.initialized) {
            return;
        }
        if (this.isMobileWeb) {
            this.initializeMobileWeb();
            this.initialized = true;
            return;
        }
        if( this.platform.is( 'cordova')) {
            this.initialized = true;
            this.connected = true;
            return;
        }

        var mythis = this;
        GeoComply.Client.on('connect', () => {
            console.log( 'geocomply.ts: connected');
            mythis.connected = true;
            mythis.installed = true;
            mythis.numClientConnectionErrors = 0;
        }).on('error', function( errorCode:any, errorMessage:string) {
            switch (parseInt( errorCode)) {
                case GeoComply.Client.CLNT_ERROR_LOCAL_SERVICE_COMMUNICATION:
                  console.log( 'geocomply.ts: ErrCode=[CLNT_ERROR_LOCAL_SERVICE_COMMUNICATION]; ErrMessage=[' + errorMessage + ']');
                    GeoComply.Client.connect(this.gc_installer_id, this.gc_env_id);
                    this.initialized = true;
                    return;
                case GeoComply.Client.CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS:
                  console.warn( 'geocomply.ts: geolocation in progress, waiting...');
                    return;
                case GeoComply.Client.CLNT_ERROR_LOCAL_SERVICE_UNSUP_VER:
                  console.error( 'geocomply.ts: Connection to GeoComply Client failed. Details: ErrCode=[CLNT_ERROR_LOCAL_SERVICE_UNSUP_VER]; ErrMessage=[' + errorMessage + ']');
                    mythis.installed = false;
                    break;
                case GeoComply.Client.CLNT_ERROR_LOCAL_SERVICE_UNAVAILABLE:
                  console.log( 'geocomply.ts: Connection to GeoComply Client failed. Details: ErrCode=[CLNT_ERROR_LOCAL_SERVICE_UNAVAILABLE]; ErrMessage=[' + errorMessage + ']');
                    mythis.installed = false;
                    break;
                case GeoComply.Client.CLNT_ERROR_TRANSACTION_TIMEOUT:
                  console.error( 'geocomply.ts: Connection to GeoComply Client failed. Details: ErrCode=[CLNT_ERROR_TRANSACTION_TIMEOUT]; ErrMessage=[' + errorMessage + ']');
                    break;
                // Missing required parameters
                case GeoComply.Client.CLNT_ERROR_WRONG_OR_MISSING_PARAMETER:
                  console.error( 'geocomply.ts: GeoLocation failed. Details: ErrCode=[CLNT_ERROR_WRONG_OR_MISSING_PARAMETER]; ErrMessage=[' + errorMessage + ']');
                    break;
                case GeoComply.Client.CLNT_ERROR_LICENSE_EXPIRED:
                case GeoComply.Client.CLNT_ERROR_INVALID_LICENSE_FORMAT:
                  console.error( 'geocomply.ts: GeoLocation failed. Details: ErrCode=[CLNT_ERROR_LICENSE_EXPIRED,CLNT_ERROR_INVALID_LICENSE_FORMAT (' + errorCode + ')]; ErrMessage=[' + errorMessage + ']');
                    mythis.gc_expires = null;
                    mythis.triggerGeolocation( mythis.user_id, mythis.reason, false);
                    return;
                case GeoComply.Client.CLNT_ERROR_SERVER_COMMUNICATION:
                  console.error( 'geocomply.ts: GeoLocation failed. Details: ErrCode=[CLNT_ERROR_SERVER_COMMUNICATION]; ErrMessage=[' + errorMessage + ']');
                    break;
                default:
                  console.error( 'geocomply.ts: UNKNOWN ERROR CODE. Details: ErrCode=[' + errorCode + ']; ErrMessage=[' + errorMessage + ']');
                  console.error( 'geocomply.ts: UNKNOWN ERROR CODE. ignoring...');
                    return;
            }
            mythis.geoComplyStatus.next( {
                error_code: parseInt( errorCode),
                error_message: errorMessage,
                error_details: '',
                geolocate_in: 0,
                hint: mythis.hint
            } as GeoComplyStatus);
        }).on('geolocation', function(data: string) {
            mythis.onGeoComplyPacket( data);
        }).on('log', function(l) {
            return console.log(l);
        });
        console.log( 'geocomply.ts: GeoComply.Client.connect:');
        GeoComply.Client.connect(this.gc_installer_id, this.gc_env_id);
        this.initialized = true;
    }

    public killConnection(): void {
      console.log( 'geocomply.ts: killConnection()');
        if( this.platform.is( 'cordova')) {
            /** @todo cordova implementation needs work */
        } else if (this.isMobileWeb) {
//            for( var ename in this._oobeeEventListeners) {
//                this.mobileWebClient.events.off( this._oobeeEventListeners[ename][0], this._oobeeEventListeners[ename][1]);
//            }
        } else {
            GeoComply.Client.off( 'connect');
            GeoComply.Client.off( 'error');
            GeoComply.Client.off( 'geolocation');
            GeoComply.Client.off( 'log');
            GeoComply.Client.disconnect();

        }
        this.initialized = false;
        this.connected = false;
    }

    public reconnect()
    {
        if( this.platform.is( 'cordova')) {
          console.log( 'geocomply.ts: Cordova: reconnect(): NOT IMPLEMENTED');
            /** @todo cordova plugin reconnect? */

        } else if (this.isMobileWeb) {
          console.log( 'geocomply.ts: oobee: reconnect()');
            this.initializeMobileWeb();
        } else {
          console.log( 'geocomply.ts: web: reconnect()');
            GeoComply.Client.connect(this.gc_installer_id, this.gc_env_id);
        }
    }

    protected initializeMobileWeb()
    {
      console.log( 'geocomply.ts: initializeMobileWeb()');
        this.mobileWebClient = GCOobee.createClient();

        var mythis = this;
//        window.confirm = function() {
//            return mythis.isInstalled();
//        };

        var events = this.events;
        var reason = this.reason;

        this.mobileWebClient.events
        .on( '**', function() {
          console.log( 'oobeelog: ['+this.event+']');
            if( this.event.endsWith( '.pending')) {
                if (reason === GeoComplyReasons.firstGeolocation) {
                    events.publishSomeData( {event: 'event:geoComply:present'});
                }
            } else if( this.event.endsWith( 'geolocation.success') || this.event.endsWith( 'geolocation.failed')) {
                events.publishSomeData( {event: 'event:geoComply:dismiss'});
            }
        })
        .on( this.mobileWebClient.EVENTS.REGISTER_SUCCESS, function( deviceUid, pairCode) {
          console.info( 'oobeelog: [register.success]: deviceUid == '+deviceUid+', pairCode == '+pairCode);
        })
        .on( this.mobileWebClient.EVENTS.GEOLOCATION_SUCCESS, function(data) {
            events.publishSomeData( {event: 'event:geoComply:dismiss'});
            mythis.onGeoComplyPacket( data);
        })
        .on( 'init.success', function() {
            mythis.connected = true;
        })
        .on( this.mobileWebClient.EVENTS.GEOLOCATION_FAILED, function( code, message) {
          console.log( 'geocomply.ts: oobee event: geolocation.failed: errorCode:', code);
            if( parseInt( code) == 106) {
                mythis.gc_expires = null;
                mythis.triggerGeolocation(mythis.user_id, mythis.reason, true);
            } else {
                events.publishSomeData( {event: 'event:geoComply:dismiss'});
                mythis.geoComplyStatus.next({
                        error_code: 103,
                        error_message: 'Connection to Oobee failed. Please start Oobee and re-try.',
                        error_details: null,
                        geolocate_in: 0,
                        hint: mythis.hint
                    } as GeoComplyStatus);
            }
        })
        .on( '*.failed', function( code, message) {
          console.error( '['+this.event+'] : '+JSON.stringify( {
                code: code,
                message: message
            }));
        });
//        this._oobeeEventListeners = [
//            ['init.success', function() {
//                mythis.connected = true;
//            }],
//            ['register.failed', function(errorCode:any, errorMessage:any) {
//                mythis.events.publish( 'event:geoComply:dismiss');
//                mythis.geoComplyStatus.next({
//                        error_code: 103,
//                        error_message: 'Connection to Oobee failed. Please start Oobee and re-try.',
//                        error_details: null,
//                        geolocate_in: 0,
//                        hint: mythis.hint
//                    } as GeoComplyStatus);
//                return false;
//            }],
//            ['geolocation.failed', function(errorCode:any) {
//                mythis.logger.log( 'geocomply.ts: oobee event: geolocation.failed: errorCode:', errorCode);
//                mythis.events.publish( 'event:geoComply:dismiss');
//                mythis.geoComplyStatus.next({
//                        error_code: 103,
//                        error_message: 'Connection to Oobee failed. Please start Oobee and re-try.',
//                        error_details: null,
//                        geolocate_in: 0,
//                        hint: mythis.hint
//                    } as GeoComplyStatus);
//                return false;
//            }],
//            ['hint', function( hint:any) {
//                mythis.logger.log( 'geocomply.ts (hints): ', hint);
////                mythis.hint = hint;
//                return false;
//            }],
//            []
//        ];

//        for( var ename in this._oobeeEventListeners) {
//            this.mobileWebClient.events.on( this._oobeeEventListeners[ename][0], this._oobeeEventListeners[ename][1]);
//        }

        this.mobileWebClient.configure( {
            serviceUrl: this.oobee_serviceurl,
            oobeeUrl: this.oobee_oobeeurl,
            enablePlayStorePopUp: OobeeOptions.enablePlayStorePopUp,
        });

    }

/*
 * deprecated
 */
    protected oobeeInitialConnection()
    {
        var i=0;
        const retry = () => {
            this.mobileWebClient.ping( () => {
                this.mobileWebClient.request();
            }, () => {
                if( i < 20) {
                    i++;
                    setTimeout( () => {
                        retry();
                    }, 1000);
                } else {
                  console.error( 'oobeelog: (retry.failed) Can not connect to the app.');
                }
            });
        };
        retry();
    }

    protected onGeoComplyPacket( data: string) {
        if (this.customer) {
            this.webservices.post('geocomply/data', {
              player_id: this.customer.customerId,
              session_id: this.userSession.getSessionToken(),
              encrypted_data: encodeURIComponent(data)
            }).toPromise().then((response: PlatformResponse<any>) => {
              console.log('geocomply.ts: geoStatus: ' + JSON.stringify(response));
                this.events.publishSomeData( {event: 'event:geoComply:dismiss'});
                this.geoComplyStatus.next({
                    error_code: coerceInt(response.payload.error_code),
                    error_message: response.payload.error_message,
                    error_details: response.payload.error_details,
                    geolocate_in: coerceInt(response.payload.geolocate_in),
                    hint: this.hint
                } as GeoComplyStatus);
            }).catch((e: any) => {
              console.warn('geocomply.ts: EXCEPTION: ', e);
            });
        } else {
            this.geoComplyStatus.next(null);
        }
    }


    /**
     * Returns a promise of a valid license, or an error; fetches new license according to expiry timestamp
     */
    protected getGeoComplyLicense(): Promise<any> {
        var licensePromise = new Promise<any>( (resolve, error) => {
            let now = (new Date()).getTime();
            if( !this.gc_expires || (now >= this.gc_expires)) {
                this.webservices.get('geocomply/license').subscribe((resp: any) => {
                    if (resp.result) {
                        this.gc_license = resp.payload.certificate;
                        if ("undefined" !== typeof resp.payload.expires) {
                            this.gc_expires = resp.payload.expires*1000;    // convert seconds to milliseconds
                            resolve( this.gc_license);
                        } else {
                            this.gc_expires = 0;        // expire immediately, certificate is no good
                            error( 'invalid expiry on certificate');
                        }
                    } else {
                        error( 'invalid response from license server');
                    }
                });
            } else {
                resolve( this.gc_license);
            }
        });
        return licensePromise;
    }

    /**
     * returns reason string to explain why recheck is required or null if latestResult should be used.
     *
     * This method should be used by clients to decide whether they need to bring up a dialog to
     * perform an asyncronous operation, or, if null, it is okay to proceed with the geocomply data
     * that was already processed.
     */
    protected recheckRequired( username: string): string
    {
        this.setTimestamp();
        if( (!this.user_id || (username !== this.user_id))
          || !this.latestResult
          || !this.next_required) {
            if( this.reason) {
                return this.reason;
            } else {
                return GeoComplyReasons.firstGeolocation;
            }
        } else if( this.getTimestamp() >= this.next_required) {
            return GeoComplyReasons.periodic;
        }
        return null;
    }

    protected triggerGeolocation( username: any, reason:string, isBackground: boolean): void
    {
        this.getGeoComplyLicense().then( (license) => {
            this.user_id = ''+username;
            this.reason = reason;

            if( this.platform.is( 'cordova')) {
              console.log( 'geocomply.ts: requestCordovaGeoComply() <cordova>');
                this.requestCordovaGeoComply( this.user_id, reason, license);
            } else if (this.isMobileWeb) {
              console.log( 'geocomply.ts: requestMobileWebGeoComply() <oobee>');
                this.requestMobileWebGeoComply( this.user_id, reason, license, isBackground);
            } else {
              console.log( 'geocomply.ts: GeoComply.Client.requestGeolocation() <browser plugin>');
                GeoComply.Client
                    .setLicense( license)
                    .setGeolocationReason( reason)
                    .setUserId( this.user_id);
                GeoComply.Client.requestGeolocation()
            }
        });
    }

    /**
     * handleGeolocationRequest
     *
     * This method should be called to cause a geolocation check to be requested.
     */
    public handleGeolocationRequest( username: any, reasonOverride?: string, isBackground?: boolean)
    {
        var reason: string;
        if (reason = this.recheckRequired(username)) {
            if (reason === GeoComplyReasons.firstGeolocation) {
                this.events.publishSomeData( {event: 'event:geoComply:present'});
            }
            this.triggerGeolocation( username, (reasonOverride || reason), isBackground);
        } else {
            console.log( 'geocomply.ts: recheckRequired FALSE !!!!');
        }
    }

    /**
     * getGeoComplyStatusObservable
     */
    public getGeoComplyStatusObservable(): Subject<GeoComplyStatus>
    {
        return this.geoComplyStatus;
    }

    /**
     * ------------------ cordova methods -----------------------
     */
    protected requestRuntimePermissions(onSuccess, onError) {
        this.geoComplySdkWrapper.requestRuntimePermissions(
            function (success) {
                if (typeof (onSuccess) === 'function') {
                    onSuccess(success);
                }
            },
            function (error) {
                if (typeof (onError) === 'function') {
                    onError(error);
                }
            },
            function (logMessage) {
              console.log(logMessage);
            }, [{
                "request_precise_location_permission": true
            }]
        );
    }
    protected requestCordovaGeoComply( username: string, reason: string, license: string) {
      console.log( 'geocomply.ts: requestCordovaGeoComply()');
        setTimeout( () => {
            var mythis = this;
            mythis.requestRuntimePermissions( (success:any) => {
              console.log( 'geocomply.ts: requestRuntimePermissions: ', success);
                mythis.geoComplySdkWrapper.requestGeolocation(
                    function( data) {
                        mythis.onGeoComplyPacket( data);
                    },
                    function( message) {
                      console.log('geocomply message callback: ' + JSON.stringify( message));
//                        mythis.geoComplyStatus.next({
//                            error_code: coerceInt(message.code),
//                            error_message: message.message,
//                            error_details: null,
//                            geolocate_in: 0,
//                            hint: null
//                        } as GeoComplyStatus);
                    },
                    function( logMessage) {
                      console.log( 'geocomply.ts: '+logMessage);
                    },
                    [
                        {
                            user_id: username,
                            reason: reason,
                            license: license
                        }
                    ]
                );
            }, (error: any) => {
                let message:string = "Can not do geolocation: " + error;
                console.log( 'geocomply.ts: error: '+message);
                mythis.geoComplyStatus.next({
                    error_code: error.code,
                    error_message: 'Permissions not granted to run Geolocation.',
                    error_details: null,
                    geolocate_in: 0,
                    hint: null
                } as GeoComplyStatus);

            });
        }, 0);              // setTimeout 1
    }
    /**
     * --------------------------- Mobile Web GeoComply --------------------
     */
    protected requestMobileWebGeoComply( username: string, reason: string, license: string, isBackground: boolean) {
        var mythis = this;
        console.log( 'geocomply.ts: requesting geo (MobileWeb)');
        if (reason === GeoComplyReasons.firstGeolocation) {
            this.events.publishSomeData( {event: 'event:geoComply:present'});
        }
        var userAction = (reason === GeoComplyReasons.firstGeolocation);
        this.mobileWebClient.setUserId( username);
        this.mobileWebClient.setReason( reason);
        this.mobileWebClient.setLicense( license);
        if( this.platform.is( 'android')) {
            console.log( 'geocomply.ts: ****** isBackground: ', isBackground);
            this.mobileWebClient.connect(
                {
                    timeout: 15000,
                    userAction: (typeof isBackground === 'undefined') || !isBackground,
                    appStoreURL: GeoComplyAppStores.android,
                }, function() {      //success callback
                mythis.mobileWebClient.request();
            }, function() {                                 //error callback
                // emit Oobee not installed
                console.info( 'geocomply.ts: OOBEE NOT INSTALLED');
                mythis.geoComplyStatus.next({
                        error_code: 103,
                        error_message: 'Connection to Oobee failed. Please start Oobee and re-try.',
                        error_details: null,
                        geolocate_in: 0,
                        hint: mythis.hint
                    } as GeoComplyStatus);
            });
        } else if( this.platform.is( 'ios')) {
            if( this.mobileWebClient.hasRegisteredRecently()) {
              console.log( 'oobeelog: request()');
                this.mobileWebClient.request();
            } else {
              console.log( 'oobeelog: connect()');
                this.mobileWebClient.connect(
                {
                    timeout: 15000,
                    userAction: true, // userAction
                    appStoreURL: GeoComplyAppStores.ios,
                },function() {      //success callback
                  console.log( 'oobeelog: request()');
                    mythis.mobileWebClient.request();
                }, function() {                                 //error callback
                    // emit Oobee not installed
                    console.error( 'oobeelog: connect error');
                    mythis.geoComplyStatus.next({
                            error_code: 103,
                            error_message: 'Connection to Oobee failed. Please start Oobee and re-try.',
                            error_details: null,
                            geolocate_in: 0,
                            hint: mythis.hint
                        } as GeoComplyStatus);
                });
            }
        } else {
            console.error('geocomply.ts: ERROR: unrecognised platform: ', this.platform.platforms());
            this.mobileWebClient.request();
        }
    }
}
