import { Pipe, PipeTransform } from '@angular/core';
import { isUndefined } from '../../../shared/codeUtils';
import { Utils } from './../../utils';
/**
 * Generated class for the SearchGamesPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'filterData',
  pure: true
})
export class FilterData implements PipeTransform {
    constructor(private utils: Utils) {}
    transform(items:any[], args:string[], param2?):any[] {
      if (typeof items === 'object') {
          var resultArray = [];
          if(!isUndefined(args)) {
            if (args.length === 0) {
                return null; //if user didn't type in anything show empty search bar
            }
            else {
                args = this.utils.escapeSpecialCharacters(args);
                if(param2 != '' && !isUndefined(param2) && param2 == 'idsearch') { //check the second parameter if ids are being used instead of game names
                    if(typeof args === 'string') { //search 1 id
                        for (let item of items) {
                            if (item.id != null && item.id == args) {
                                resultArray.push(item);
                            }
                        }
                    }
                    else { //search an array of ids
                        for(let j = 0; j < args.length; j++){
                            for (let item of items) {
                                if (item.id != null && item.id == args[j] && resultArray.indexOf(item) <= -1) {
                                    resultArray.push(item);
                                }
                            }
                        }
                    }

                } else {
                    if(typeof args === 'string') {
                        for (let item of items) {
                            if (item.game_name != null && item.game_name.match(new RegExp(''+args, 'i')) && resultArray.indexOf(item) <= -1) {
                                resultArray.push(item);
                            }
                            else if(item.tags != 'undefined' && item.tags != null && item.tags.length > 0){
                                for(let tag of item.tags){
                                    if(tag != null && tag.match(new RegExp(''+args, 'i')) && resultArray.indexOf(item) <= -1) {
                                        resultArray.push(item);
                                    }
                                }
                            } else if (item.name != null && item.name.match(new RegExp(''+args, 'i')) && resultArray.indexOf(item) <= -1) {
                              resultArray.push(item);
                            }
                        }
                    } else {
                        //if array of strings then loop through all
                        for(let j = 0; j < args.length; j++){
                            for (let item of items) {
                              if (item.game_name != null && item.game_name.match(new RegExp(''+args[j], 'i')) && resultArray.indexOf(item) <= -1) {
                                  resultArray.push(item);
                              }
                              else if(item.tags != 'undefined' && item.tags != null && item.tags.length > 0){
                                  for(let tag of item.tags){
                                      if(tag != null && tag.match(new RegExp(''+args[j], 'i')) && resultArray.indexOf(item) <= -1) {
                                          resultArray.push(item);
                                      }
                                  }
                              } else if (item.name != null && item.name.match(new RegExp(''+args[j], 'i')) && resultArray.indexOf(item) <= -1) {
                                resultArray.push(item);
                              }
                            }
                        }
                    }
                }

            }
          } else {
              return null;
          }
          //remove dupes by id
          var emptyContainer = {};
          var noDupesArray = resultArray.filter((entry) => {
            if (emptyContainer[entry.id]) {
                return false;
            }
            emptyContainer[entry.id] = true;
            return true;
            })
            // and sort the response
            .sort((a: any, b: any) => {
                if (a.game_name < b.game_name) {
                  return -1;
                } else if (a.game_name > b.game_name) {
                  return 1;
                } else {
                  return 0;
                }
              });

            return noDupesArray;
      }
      else {
          return null;
      }

  }

}
