export const locale = {
  lang: 'en-US',
  data: {
    LEFT_SIDE: {
      EARN_CATEGORY_TITLE: 'Earn',
      REFERRAL: 'Refer and Earn',
      VIP: 'VIP Club',
      LANGS: {
        // https://www.omniglot.com/language/names.htm
        ENGLISH: 'English',
        CHINESE: '简体中文',
        PORTUGUES: 'Português',
        THAILIAND: 'ภาษาไทย',
        VIETNAME: 'Tiếng Việt',
        SPANISH: 'Español',
        INDONESIAN: 'Bahasa Indonesia',
        JAPANESE: '日本語'
      },
      INTEREST: "interest",
      REFER_EARN: "Refer & Earn",
      VIP_CLUB: '<span style="color: #ffb636;">VIP</span> Club'
    },
		HEADER: {
      SPORT_BUTTON_TEXT: "Sport",
      CASINO_BUTTON_TEXT: "Casino",
      PROMOTIONS: 'Promotions',
      SEARCH: "Search",
      SEARCH_PLACEHOLDER: "Search Games or Providers",
      SEARCH_PART_GAMES: "Games",
      SEARCH_PART_PROVIDERS: "Providers",
      SEARCH_RESULT: "Search Results",
      SEARCH_NO_RESULT: "No search result",
      SEARCH_TRY_GAMES: "Recommended",
      RECOMMENDED_GAMES: "Recommended for you",
      LOGIN_BUTTON_TEXT: "Login",
      REGISTER_BUTTON_TEXT: "Register",
      BALANCE_INGAME: "IN GAME",
      DEPOSIT_BUTTON_TEXT: "Wallet",
      Settings: "Settings",
      ALL_GAMES: "All Games",
      SELECT_GAME: "Select Game"
    },
    BALANCES: {
      FIAT: 'Fiat',
      CRYPTO: 'Crypto',
      DISPLAY_FIAT: 'Display in Fiat'
    },
    MOBILE_FOOTER: {
      BETSPLUS: {
        SPORT_TAB_TEXT: 'Sport',
        CASINO_TAB_TEXT: 'Casino',
        PROMOTIONS_TAB_TEXT: 'Promotions',
        MENU_TAB_TEXT: 'Menu'
      }
    },
    DASHBOARD: {
      CATEGORY_LOBBY_NAME: "Lobby",
      LAST_PLAYED: "Recent",
      VIEWALL_BUTTON_TEXT: "All",
      VIEWALL_BUTTON_TEXT1: "View All",
      ALL_GAMES_COUNT: "All {{count}}",
      NO_GAMES: "No game data",
      GAME_PROVIDERS_TITLE: "Game Providers",
      RECORDS: {
        TITLE: "Record",
        TABS: {
          MY_BETS_TITLE: "My bets",
          ALL_BETS_TITLE: "All bets",
          RARE_WINS_TITLE: 'Big wins',
        },
        DATA: {
          BETS_TABLE: {
            COLUMN1: 'Game',
            COLUMN2: 'Username',
            COLUMN3: 'Time',
            COLUMN4: 'Wagered',
            COLUMN5: 'Multiplier',
            COLUMN6: 'Payout'
          },
          WINS_TABLE: {
            COLUMN1: 'Game',
            COLUMN2: 'Username',
            COLUMN3: 'Wagered',
            COLUMN4: 'Multiplier',
            COLUMN5: 'Payout'
          },
          WINS_TABLE_BETSPLUS: {
            COLUMN1: 'Rank',
            COLUMN2: 'Username',
            COLUMN3: 'Wagered',
            COLUMN4: 'Prize'
          },
          NO_MY_BETS_TEXT: "None on Records",
          NO_ALL_BETS_TEXT: "No all bets",
          NO_RARE_WINS_TEXT: "No big wins"
        }
      }
    },
    AUTH: {
      TOPBAR: {
        LOGIN_BUTTON_TEXT: "Login",
        REGISTER_BUTTON_TEXT: "Register"
      },
      LOGIN: {
        TITLE: "Welcome Back!",
        EMAIL: 'Email',
        EMAIL_EMPTY_ERROR: "Please enter an email",
        EMAIL_INVALID_ERROR: 'Please enter a valid email',
        PHONE_NUMBER: "Phone Number",
        PHONE_EMPTY_ERROR: 'Please enter your phone number',
        PHONE_INVALID_ERROR: 'Please enter a valid phone number',
        PASSWORD: 'Password',
        PASSWORD_EMPTY_ERROR: 'Password is required',
        PASSWORD_LENGTH_ERROR: 'Password must be greater than 6 characters',
        SUBMIT_BUTTON_TEXT: 'Log In',
        DONT_HAVE_ACCOUNT: "Don't have an account yet?",
        CREATE_NEW_ONE: "Register",
        FORGOT_PASSWORD_BUTTON_TEXT: 'Forgot password?',
        ERRORS: {
          NOT_EXIST: "The email is not registered",
          WRONG_PASSWORD: 'Wrong password inserted',
          TOO_MANY_ATEMP: 'Your account was disabled upon too many failed logins, please contact support.',
          TIME_LIMIT: 'Time limit exceeded.',
          NOT_ACTIVE: "Your account is not in 'Active' status. Please contact support.",
          PERMANENT_EXCLUDE: 'Your account has been permanently excluded from this site.',
          SUSPENDED: 'You have suspended yourself from play.',
          TEMPORARY_SUSPENDED: 'Your account has been temporarily suspended. If this is not resolved in 24 hours please contact support.',
          ERROR_OCCURED: 'Authentication failed. Please check your username and password and try again.',
          SUCCESS_LOGIN: 'Logged in successfully',
          INACTIVITY: 'Your last session expired due to Inactivity'
        },
        USING_SOCIAL: 'Login using'
      },
      SIGNUP: {
        TITLE: 'Register at {{sitename}}',
        EMAIL: 'Email',
        EMAIL_EMPTY_ERROR: "Please enter an email",
        EMAIL_INVALID_ERROR: 'Please enter a valid email',
        EMAIL_ALREADY_USED_ERROR: 'That email address is already in use. If you are trying to complete your registration, please sign in to continue where you left off.',
        PHONE_NUMBER: "Phone Number",
        PHONE_EMPTY_ERROR: 'Please enter your phone number',
        PHONE_INVALID_ERROR: 'Please enter a valid phone number',
        PHONE_ALREADY_USED_ERROR: 'That phone number is already in use. If you are trying to complete your registration, please sign in to continue where you left off.',
        VERIFICATION_CODE: 'Verification Code',
        VERIFY_BUTTON_TEXT: 'Verify',
        GET_CODE_TXT: 'Get Code',
        CODE_SENT: 'Code Sent',
        RESEND: 'Resend',
        PASSWORD: 'Password',
        PASSWORD_EMPTY_ERROR: 'Password is required',
        PASSWORD_LENGTH_ERROR: 'Password must be greater than 6 characters',
        PASSOWRD_PATTERN_ERROR: 'Password should contain at least one number, one lower case letter, one upper case letter and one special non-alphabetical character: $ # ! @ - _',
        REFERRAL_CODE: 'Referral Code',
        REFERAL_CODE_ERROR: 'Promo code is invalid',
        TERMS_DESC: 'By signing up you agree to the',
        TERMS_BUTTON_TEXT: 'Terms & Service',
        SUBMIT_BUTTON_TEXT: 'Get Started',
        ALREADY_HAVE_ACCOUNT: "Already have an account?",
        SIGNIN_BUTTON_TEXT: 'Sign in',
        ERRORS: {
          ALREADY_EXIST: 'The email already exists',
          NOT_CREATED: 'Cannot create account please contact customer support.'
        },
        VERIFY_CODE_REQUIRED: 'Verification code is requried',
        VERIFICATION_CODE_LENGTH_ERROR: "Verification code must be 6 numbers",
        VERIFICATION_WRONG_CODE: "Incorrect verification code",
        SENT_VERIFY_EMAIL: "We've sent the email for verification",
        SENT_VERIFY_SMS: "We've sent the sms code for verification",
        USING_SOCIAL: 'Register using',
        CAPTCHA: "CAPTCHA",
        CAPTCHA_REQUIRED: "CAPTCHA is required",
        CAPTCHA_PATTERN_ERROR: "Please enter valid CAPTCHA",
        CAPTCHA_WRONG: "Invalid CAPTCHA, please try again"
      },
      FORGOT_PASSWORD: {
        TITLE: "Forgot password?",
        DESCRIPTION: 'Forgot password?',
        EMAIL: 'Email',
        EMAIL_EMPTY_ERROR: "Please enter an email address",
        EMAIL_INVALID_ERROR: 'Please enter a valid email address',
        PHONE_NUMBER: "Phone Number",
        PHONE_EMPTY_ERROR: 'Please enter your phone number',
        PHONE_INVALID_ERROR: 'Please enter a valid phone number',
        VERIFICATION_CODE: 'Verification Code',
        VERIFY_BUTTON_TEXT: 'Verify',
        SUBMIT_BUTTON_TEXT: 'Submit',
        SUCCESS_MSG: 'Reset email link sent successfully',
        VERIFICATION_CODE_LENGTH_ERROR: "Verification code must be 8 characters",
        SUCCESS_RESET_MSG: 'Your password has been reset successfully. Please login with new password again'
      },
      LOGOUT: {
        TITLE: "Logout",
        MULTI_DEVICE: "You have been logged out due to multiple device login",
        INACTIVITY: 'You are now logged out due to inactivity',
        DEFAULT: 'You are now logged out'
      },
      OR: 'OR',
      GOOGLE_BUTTON_TEXT: "Google"
    },
    DEPOSIT: {
      CURRENCY_TITLE: 'Deposit Currency',
      CHOOSE_PAYMENT_TITLE: 'Choose a Payment Method',
      AMOUNT: 'Amount',
      INSTANT: 'Instant',
      MIN: 'Min',
      MAX: 'Max',
      SUBMIT_BUTTON_TEXT: 'Deposit',
      NEED_HELP: 'Need help?',
      CONTACT_SUPPORT: 'Contact our Live support',
      CRYPTO_DEPOSIT_TITLE: 'Deposit',
      CRYPTO_DEPOSIT_DESCRIPTION: 'This is your private depositing address. Any deposit you make will show up in your balance after blockchain confirmation. We will notify you via email once the deposit arrives.',
      MINIMUM_DEPOSIT_AMOUNT: 'The minimum deposit amount is {{amount}} {{currency}}',
      SEND_MONEY_TO: 'Send any amount of {{currency}} (plus miner fee) to:',
      NO_PAYMENT_METHOD: 'No available payment method',
      ADDRESS_COPIED: "Copy and Deposit",
      CLUB_PAY_TYPE: "Pay Type"
    },
    WITHDRAW: {
      CLUB_PAY_TYPE: "Pay Type",
      CURRENCY_TITLE: 'Withdraw Currency',
      CHOOSE_PAYMENT_TITLE: 'Choose a Payment Method',
      INFO_TITLE: 'Withdraw information',
      BANK_CODE_TITLE: 'Issuer Code',
      BANK_NAME_TITLE: 'Beneficiary Name',
      ACCOUNT_TYPE: "Account Type",
      BANK_ACCOUNT_TITLE: 'Account Number',
      AMOUNT: 'Amount',
      INSTANT: 'Instant',
      MIN: 'Min',
      MAX: 'Max',
      SUBMIT_BUTTON_TEXT: 'Withdraw',
      NEED_HELP: 'Need help?',
      CONTACT_SUPPORT: 'Contact our Live support',
      EXIST_ACCOUNT_TITLE: 'Exist Accounts',
      ADDRESS: 'Address',
      WITHDRAW_FEE_ESTIMATED: 'Withdrawal fee is estimated to be {{amount}} {{currency}}',
      MINIMUM_WITHDRAW_AMOUNT: 'The minimum withdraw amount is {{amount}} {{currency}}',
      NOT_DEPOSIT_YET: 'Please make a deposit first',
      WITHDRAW_DEDUCT_DESC: 'Withdrawal will deduct 10% handling fee',
      COMPLETE_EMAIL_VERIFY: 'For safety reasons, complete email verification before withdrawal',
      VERIFY_EMAIL_BUTTON: 'Verify Email Address',
      COMPLETE_PHONE_VERIFY: 'For safety reasons, complete phone verification before withdrawal',
      VERIFY_PHONE_BUTTON: 'Verify Phone Address',
      COMPLETE_EMAIL_PHONE_VERIFY: 'For safety reasons, complete email and phone verification before withdrawal',
      VERIFY_EMAIL_PHONE_BUTTON: 'Verify Now',
      COMPLETE_PROFILE: 'For safety reasons, complete your profile info before withdrawal',
      ACCOUNT_BUTTON: 'Account',
      WITHDRAW_SUCCESS: 'The withdrawal has been submitted, please keep an eye on your withdrawal account, thank you',
      HOME_BUTTON: 'Home',
      RESTRICTED_BALANCE: 'You have {{currency}} {{amount}} restricted balance.',
      RESTRICTED_BALANCE_VIEW: 'View',
      NO_PAYMENT_METHOD: 'No available payment method',
      IFSC_TITLE: 'IFSC Number',
      INVALID_AMOUNT_ERROR: {
        EMPTY_AMOUNT: 'Please input amount',
        LESS_ZERO: 'Amount cannot be less than zero',
        LESS_MIN: 'Amount is less than minimum value',
        GREATER_MAX: 'Exceed max value',
        GREATER_BALANCE: 'Exceed balance',
        GREATER_AVAILABLE: 'Insufficient Balance'
      },
      ADDRESS_REQUIRED: "Address is required",
      ADDRESS_PATTERM_ERROR: "Address is not correct"
    },
    GAME_GALLEY: {
      NO_GAME_DATA: 'No games in your list. Please click the home button and enjoy one of many games.',
      DISPLAY_COUNT_GAMES: 'Displaying {{showCount}} of {{totalCount}} games'
    },
    GAME: {
      LOADING: 'Loading',
      PLAY_BUTTON_WITHOUT_LOGIN: 'Log In',
      PLAY_BUTTON_WITH_LOGIN: 'Real Play',
      DEMO_PLAY_BUTTON: "Demo",
      RTP: 'RTP',
      RECENT_BIGGEST_WIN: 'Recent biggest win',
      MY_GAMES_TITLE: 'My Games'
    },
    ACTIVE_ACCOUNT: {
      TITLE: 'Verify Email',
      EMAIL_VERIFY_DESCRIPTION: 'Please log in to your email, click the link in the email to complete the verification',
      GET_CODE_TEXT: 'Send Code',
      RESEND_BUTTON_TEXT: 'Resend Code',
      SUBMIT_BUTTON_TEXT: 'Submit',
      NOT_RECEIVE_TXT: "Didn't receive the code?",
      CODE: 'Code',
      CODE_INPUT_PLACEHOLDER: 'Fill in the code here'
    },
    EMAIL_VERIFY: {
      DISPLAY_INFO: 'Verifing now. Please wait for a moment...',
      ERROR_TITLE: 'Sorry, there was a problem.',
      VERIFY_ERROR: 'Email code not found',
      SUCCESS_TITLE: 'Success!',
      VERIFY_SUCCESS: 'Your email verified successfully'
    },
    BONUSES: {
      BONUSES_TITLE: 'Promotions',
      BONUS_TITLE: 'Bonus',
      NO_BONUSES_DATA: 'No promotions available at the moment',
      CHECK_LATER: 'Check back later!',
      MORE_DETAIL_BUTTON_TEXT: 'Learn More',
      APPLY_BUTTON_TEXT: 'Apply Now',
      TAB1: 'Casino',
      TAB2: 'Sport',
      ENDS_AT: 'Ends :',
      EVENT_ENDED: 'Event Ended'
    },
    EMPTY_PAGE: {
      NOT_FOUND: 'Oops.. Page not found.',
      REFRESH_OR_GOHOME: 'Please refresh the page or click below to return to the home page',
      ACTION_BUTTON_TEXT: 'Home'
    },
    FOOTBALL_PAGE: {
      TITLE: 'Sports',
      DESCRIPTION: 'Sportsbooks coming soon. Check back later!',
      ACTION_BUTTON_TEXT: 'Home'
    },
    LOGOUT_CONFIRM: {
      TITLE: 'Sign Out',
      DESCRIPTION: "Don't forget to check out our huge selection of casino and sports promotions before you leave!",
      BUTTON_TEXT: 'Logout'
    },
    ACCOUNT_INFO: {
      UID: 'UID',
      USERNAME_TITLE: 'Name',
      EMAIL_TITLE: 'Email',
      PHONE_TITLE: 'Phone Number',
      PHONE_PLACEHOLDER: 'Add Phone number',
      PASSWORD_TITLE: 'Password',
      EDIT_BUTTON_TEXT: 'Edit',
      VERIFY_BUTTON_TEXT: 'Verify',
      ADD_EMAIL: 'Add Email',
      COPY_BUTTON_TXT: 'Copy',
      SUMMARY_TEXT: ''
    },
    ADD_EMAIL: {
      TITLE: 'Add Email',
      DESCRIPTION: 'Add your Email for account security',
      EMAIL_PLACEHOLDER: 'Email',
      VERIFICATION_CODE: 'Verification Code',
      VERIFY_BUTTON_TEXT: 'Verify',
      SUBMIT_BUTTON_TEXT: 'Save'
    },
    CHANGE_PASSWORD: {
      TITLE: 'Change Password',
      CURRENT_PW_PLACEHOLDER: 'Current Password',
      CURRENT_PW_REQUIRED: 'Please enter your current password',
      CURRENT_PW_WRONG: 'Wrong current password inserted',
      NEW_PW_PLACEHOLDER: 'New Password',
      NEW_PW_REQUIRED: 'Please enter a new password',
      NEW_PW_LENGTH_ERROR: 'Password must be at least 6 characters',
      CONFIRM_PW_PLACEHOLDER: 'Repeat New Password',
      CONFIRM_PW_REQUIRED: 'Please confirm your new password',
      NEW_PW_NOT_MATCH: 'Password inserted do not match',
      NEW_PW_NOT_CURRENT: 'New password can not match your current password',
      SUBMIT_BUTTON_TEXT: 'Save',
      MESSAGES: {
        SUCCESS: 'Your password has been changed',
        WRONG_CURRENT_PASSWORD: 'You have incorrectly entered your current password.',
        NEW_NOT_MATCH_CURRENT: 'Your new password can not match your current password.'
      }
    },
    EDIT_USERNAME: {
      TITLE: 'Edit Username',
      PLACEHOLDER_TEXT: 'Username',
      SUBMIT_BUTTON_TEXT: 'Save',
      MESSAGES: {
        USERNAME_REQUIRED: 'Please enter username.',
        USERNAME_LENGTH: 'The username must be greater than 3 character long'
      }
    },
    RESET_PASSWORD: {
      TITLE: 'Reset Password',
      DESCRIPTION: 'Reset Password',
      PASSWORD_PLACEHOLDER: 'Password',
      PW_LENGTH_ERROR: 'Password must be at least 6 characters.',
      PW_REQUIRED: 'Please enter a new password.',
      PW_PATTERN_ERROR: 'Password has to include number, upper, and symbol.',
      CONFIRM_PW_PLACEHOLDER: 'Reconfirm password',
      CONFIRM_PW_REQUIRED: 'Please confirm your new password.',
      CONFIRM_PW_MISMATCH: 'The passwords do not match.',
      NEW_PW_NOT_CURRENT: 'Your new password can not match your current password.',
      SUBMIT_BUTTON_TEXT: 'Reset',
      MESSAGES: {
        TOKEN_INVALID: 'The token provided is invalid',
        TOKEN_EXPIRED: 'The token provided is expired',
        SUCCESS: 'Your password has been reset. Please login using the password',
        FAILD: 'Faild reset your password. Please try again'
      }
    },
    UPDATE_PHONE: {
      TITLE: 'Edit Phone Number',
      DESCRIPTION: 'Add your phone number for account security',
      PLACEHOLDER: 'digits only',
      PHONE_REQUIRED: 'Please enter your phone number',
      PHONE_INVALID: 'Please enter a valid phone number',
      VERIFY_CODE: 'Verification Code',
      VERIFY_BUTTON_TEXT: 'Verify',
      SUBMIT_BUTTON_TEXT: 'Submit',
      MESSAGES: {
        FAILD: 'There was an issue trying to update the phone number. Please contact customer support.'
      }
    },
    ACTIVE_BONUSES: {
      TITLE: 'Active Bonuses',
      NO_DATA: "You don't have any active bonuses at the moment.",
      SHOW_RESULT: 'Click on a bonus to see the full details about it',
      BONUS_ACCOUNT: 'Bonus Account',
      DESC1: "Sometimes you may get a ‘Bonus’ as a special reward for an action, like making a deposit.",
      DESC2: "The ‘Total Restricted Amount’ (your deposit + the bonus amount) is restricted to wagers on games only. Once the wagering requirements are met the Total Restricted Amount is released and becomes part of your real money balance.",
      TABLE_HEADER: {
        COLUMN1: 'TOTAL BONUS',
        COLUMN2: 'REMAINING BONUS',
        COLUMN3: 'REFERENCE',
        COLUMN4: 'START DATE',
        COLUMN5: 'EXPIRY DATE',
        COLUMN6: 'STATUS',
        COLUMN7: 'RESTRICTED AMOUNT'
      }
    },
    PAST_BONUSES: {
      TITLE: 'Past Bonuses',
      DESCRIPTION: 'Below you can see any previous bonuses you have received. Please select a date range:',
      FROM_DATE_LABEL: 'From',
      INPUT_DATE_PLACEHOLDER: 'Select a Date',
      TO_DATE_LABEL: 'To',
      SUBMIT_BUTTON_TEXT: 'SUBMIT',
      NO_RESULT: 'No results found.',
      SHOW_RESULT: 'Select any bonus below to see more details:',
      ACTIVE: 'Active',
      FORFEITED: 'Forfeited',
      RELEASED: 'Released',
      EXHAUSTED: 'Exhausted',
      EXPIRED: 'Expired',
      TABLE_HEADER: {
        COLUMN1: 'REFERENCE',
        COLUMN2: 'TOTAL BONUS',
        COLUMN3: 'START DATE',
        COLUMN4: 'EXP. DATE',
        COLUMN5: 'STATUS',
      }
    },
    BONUS_DETAIL: {
      ACTIVE: 'ACTIVE',
      EXPIRED: 'EXPIRED',
      EXHAUSTED: 'EXHAUSTED',
      RELEASED: 'RELEASED',
      RELEASE: 'RELEASE',
      FORFEITED: 'FORFEITED',
      TITLE: 'Bonus - Details',
      TERMS_SERVICE: 'Terms & Conditions',
      BONUS: 'Bonus',
      START_DATE: 'Start Date',
      EXPIRED_DATE: 'Exp. Date',
      PROGRESS: 'Progress',
      CASINO: 'Casino',
      SPORTS: 'Sports',
      LIVE: 'Live',
      VALID_GAMES: 'Valid Games',
      ROLLOVER: 'Rollover Requirements',
      WAGERS_REMAIN: 'Wagers Remaining',
      RESTRICT_AMOUNT: 'RESTRICTED AMOUNT',
      WINNINGS: 'Winnings',
      DEPOSIT: 'Deposit',
      TOTAL_RESTRICT_AMOUNT: 'Total Restricted Amount',
      FORFEIT_BUTTON_TEXT: 'Forfeit My Bonus',
      FORFEIT_DESC1: "If you forfeit this bonus we will remove:",
      FORFEIT_DESC2: '{{currency}} {{winning}} of restricted winnings.',
      FORFEIT_DESC3: '{{currency}} {{bonus}} of restricted bonus money.',
      FORFEIT_DESC4: 'This will leave you with {{currency}} {{deposit}} from your deposit.',
      FORFEIT_DESC5: 'Are you sure you want to forfeit this bonus?',
      CANCEL_BUTTON_TEXT: 'Cancel',
      CONFIRM_BUTTON_TEXT: 'Yes, Forfeit Bonus',
      ERRORS: {
        CLOSE_OPENED_GAMES: 'Please close all opened games in order to forfeit the bonus.',
        RESOLVE_SPORTS_GAME: 'A sport event reserved funds from this bonus - please wait for its resolution first.',
        RESOLVE_SAVED_GAME: 'A saved game reserved funds from this bonus - please resolve it first.'
      },
      APPLY_BUTTON_TXT: {
        DEFAULT: 'Deposit Now',
        LABEL1: 'Apply Now',
        LABEL2: 'Deposit Now',
        LABEL3: 'Play Now',
        LABEL4: 'Redeem Now',
        LABEL5: 'More Detail'
      }
    },
    REDEEM_BONUS: {
      TITLE: 'Promo Code',
      DESCRIPTION: "Join {{name}} community and receive a promo code!",
      INPUT_LABEL: 'Bonus Code:',
      INPUT_PLACEHOLDER: 'Promo Code',
      SUBMIT_BUTTON_TEXT: 'Redeem',
      FAILD: 'The bonus code is wrong or expired, please try another one.',
      NOVALID: "Promo code not available",
      SUCCESS: "Promo Code has been used successfully",
      SUCCESS_BUTTON_TXT: "Finish"
    },
    REFER_FRIEND: {
      TITLE: 'Refer a friend',
      SUBTITLE1: 'Invite Friends, Earn Money',
      DESCRIPTION1: 'Receive cash balance for every friend you refer to {{website}}',
      TOTAL_REFERRED: 'Total Users You Referred',
      SUBTITLE2: 'Share your invite code',
      DESCRIPTION2: 'Your Referral Code'
    },
    SPORTS_PAGE: {
      LOADING: 'Loading'
    },
    GAME_HISTORY: {
      TITLE: "Game History",
      TABLE: {
        COLUMN1: "Game",
        COLUMN2: "Bet result",
        COLUMN3: "Time",
        COLUMN4: "Bet Amount",
        COLUMN5: "Multiplier",
        COLUMN6: "Payout"
      },
      NO_HISTORY: "No Game History",
      SHOW_DATA_DESC: 'Only last 50 game records are available',
      TOTAL: "Total",
      WIN: 'Win',
      LOSS: 'Loss',
      DRAW: 'Draw / Cancel',
      UNSETTLED: 'UNSETTLED',
      MOBILE: {
        CASH: 'SLOTS',
        BET_AMOUNT: 'Bet Amount',
        MULTIPLIER: 'Multiplier',
        PAYOUT: 'Payout'
      },
      SHAREING: {
        SUCCESS: {
          TITLE1: 'More than just wins,',
          TITLE2: 'earn extra tokens and dividends here.',
          BET_AMOUNT: 'by betting',
          MULTIPLIER: "Multiplier {{value}}x",
          PLACED_USER: 'Placed By: {{username}}',
          REFERRAL_CODE: 'Referral Code',
          CTA_SENTENCE2: 'Share NOW & win lottery rewards',
          DOWNLOAD_BTN: 'Download',
          SHARE_BTN: 'Share'
        },
        FAILD: {
          TITLE: 'Fail To Share',
          ALREADY_SHARED_GAME_LOG: 'This win has been shared by other people.',
          ABOVE_ONE_MULTIPLIER: 'Only transactions that are above 1x Multiplier can be shared.',
          EXCEEDED_DAILY: "You have reached your daily limit."
        }
      }
    },
    LEADERBOARD: {
      TITLE: 'Leaderboard',
      TABLE: {
        COLUMN1: "",
        COLUMN2: "",
        COLUMN3: "Player",
        COLUMN4: "Total $FUN Token",
        COLUMN5: "Reward"
      },
      NO_DATA: 'No Leaderboard'
    },
    NEW_BANK: {
      TITLE: 'Bank Infomation',
      PAYMENT_TYPE: 'Payment Type',
      SELECT_PAYMENT_TYPE: 'Select a Payment Type',
      BANK_LIST: 'Bank List',
      SELECT_BANK: 'Select Bank',
      NAME: 'Full Name',
      ACCOUNT_NUMBER: 'Account Number',
      ACCOUNT_NUMBER_DESC: '1 to 17 digits only',
      SUBMIT_BUTTON_TXT: 'SUBMIT',
      ADD_NEW_BUTTON_TXT: 'Add New',
      CLOSE_BUTTON_TXT: 'Close'
    },
    PAYMENTS: {
      DEPOSIT_TXT: 'Deposit',
      WITHDRAW_TXT: 'Withdraw'
    },
    PROFILE_INFO: {
      TITLE: 'Personal Info',
      FIRST_NAME: 'First Name',
      FIRST_NAME_REQURIED_ERROR: 'Please enter your legal first name.',
      FIRST_NAME_PATTERN_ERROR: "First Name can only contain alphabetic characters, spaces, hyphens and periods. Please enter at least 2 and maximum 20 characters.",
      LAST_NAME: 'Last Name',
      LAST_NAME_REQUIRED_ERROR: 'Please enter your legal last name.',
      LAST_NAME_PATTERN_ERROR: "Last Name can only contain alphabetic characters, spaces, hyphens and periods. Please enter at least 2 and maximum 20 characters.",
      BIRTHDAY: {
        TITLE: 'Date of Birth',
        YEAR: 'Year',
        MONTH: 'Month',
        DAY: 'Day',
        ERROR: 'Invalid date of birth.'
      },
      ADDRESS: {
        STREET: 'Street Address',
        STREET_REQURIED_ERROR: 'Please enter your street address.',
        PO_BOX_ERROR: 'Cannot use a PO box.',
        COUNTRY: 'Country',
        STATE: 'State',
        CITY: 'City',
        CITY_REQURIED_ERROR: 'Please enter your city.',
        CITY_PATTERN_ERROR: 'Please enter valid city name. City should not include numbers.',
        ZIPCODE: 'Postal Code',
        ZIPCODE_REQUIRED_ERROR: 'Please enter your zip code.',
        ZIPCODE_PATTERN_ERROR: 'Please enter letters and numbers only. No spaces or special characters.',
        ZIPCODE_MINLENGTH_ERROR: 'zipcode must at least 5 characters'
      },
      SUBMIT_BUTTON_TXT: 'SUBMIT',
      CHOOSE_FILE: 'Proof of Identity/Choose file',
      FILE_NOTE: "*Document accepted : Passport, driver’s license or ID card.",
      ALERT: {
        SUCCESS: {
          TITLE: 'Verification',
          MSG: 'Successfully updated'
        }
      }
    },
    PROVIDERS: {
      TITLE: 'Game Providers',
      GAMES_LENGTH: '{{count}} Games',
      GAMES: 'Games',
      CURRENT_DISPLAYING: 'Displaying {{count}} of {{total}} providers'
    },
    TERMS: {
      TITLE: 'Terms and Conditions',
      PRINT_BUTTON_TXT: 'Print',
      DOWNLOAD_BUTTON_TXT: 'Download'
    },
    TRANSACTIONS: {
      TITLE: 'Transactions',
      DEPOSIT_BUTTON_TXT: 'Deposit',
      WITHDRAW_BUTTON_TXT: 'Withdraw',
      BONUS_BUTTON_TXT: 'Bonuses',
      NO: 'No',
      SHOW_DATA_DESC: 'Only last 50 transactions are available',
      TOTAL: 'Total',
      deposit: 'Deposit',
      withdraw: 'Withdraw',
      bonuses: 'Bonuses',
      STATE: {
        CONFIRMED: "Confirmed",
        UNCONFIRMED: "Unconfirmed",
        UNSUCCESSFUL: "Unsuccessful"
      },
      DEPOSIT_TABLE: {
        COLUMN1: 'Order ID',
        COLUMN2: 'Time',
        COLUMN3: 'States',
        COLUMN4: 'Currency',
        COLUMN5: 'Deposit',
        COLUMN6: 'Before Balance',
        COLUMN7: 'After Balance',
        COLUMN8: 'Amount'
      },
      WITHDRAW_TABLE: {
        COLUMN1: 'Order ID',
        COLUMN2: 'Time',
        COLUMN3: 'States',
        COLUMN4: 'Currency',
        COLUMN5: 'Withdraw',
        COLUMN6: 'Before Balance',
        COLUMN7: 'After Balance',
        COLUMN8: 'Amount'
      },
      BONUSES_TABLE: {
        COLUMN1: 'Type',
        COLUMN2: 'Order ID',
        COLUMN3: 'Time',
        COLUMN4: 'Bonus Amount',
        COLUMN5: 'Bonus Name'
      }
    },
    USER_MENU: {
      WALLET: 'Wallet',
      TRANSACTIONS: 'Transactions',
      GAME_HIISTORY: 'Game History',
      KYC_INFO: 'KYC',
      VIP: 'VIP Club',
      REDEEM_COUPON: 'Redeem Coupon',
      REFERRAL: 'Refer and Earn',
      PROMOTIONS: 'Promotions',
      ACTIVE_BONUS:'Active Bonuses',
      PAST_BONUS: 'Past Bonuses',
      LIVE_SUPPORT: 'Live Support',
      ACCOUNT: 'Profile'
    },
    STEP_COMPONENT: {
      STEP_TXT: 'Step'
    },
    PLAYER_VIP: {
      MY_DATA: {
        VIP_PROGRESS: "Your VIP Progress",
        TOOLTIP: 'Each $1 wager earns you 1EXP. The more you play, the faster you reach the highest rank.',
        RAKEBACK: 'Rakeback',
        RANK_UP_BUTTON_TXT: 'Up Rank',
        TOTAL_CLAIMED: 'Total Rakeback Claimed',
        CLAIM_BUTTON_TXT: 'Claim',
        REMAIN_UNTIL_NEXT: "{{xp}} XP until VIP {{level}}",
        REACHED_MAX_LEVEL: "You reached max level!"
      },
      BETSPLUS: {
        LEVEL: "Level",
        REQUIRED_XP: 'Required EXP {{amount}} {{currency}}',
        LEVELUP_DESC: 'Get rewarded every time you fill the progress bar. Leveling up entitles you to bigger and better rewards!',
        WITHDRAWN_AMOUNT: 'Amount that can be withdrawn',
        TOTAL_REWARDS: 'Total Rewards Available',
        EXCHANGE_BUTTON_TXT: 'Exchange',
        CLAIM_BUTTON_TXT: 'Claim',
        REWARD_REDEMPTION: "Reward Redemption",
        TOTAL_CLAIMED: 'Total Rewards Claimed',
        UNCLAIMABLE_MSG: 'Required minimum {{amount}} {{currency}} to claim',
        REWARD: "Reward"
      }
    },
    REFERRALS: {
      MY_REFERRALS: {
        TOTAL_COMMISSION_EARNING: 'Total Referral Bonuses',
        REFERRED_YOU: "Who Referred You",
        AMOUNT_REFERRED: 'Amount of users you have referred',
        NUMBER_OF_USERS: "Number of users"
      },
      BETSPLUS: {
        TITLE: 'Refer and Earn',
        DESCR1: 'EARN COMMISSION FOR EVERY PLAYER YOU REFER',
        DESCR2: '',
        HOWTOWORK: 'how it works',
        TOOLTIP_DETAIL: 'Step 1: Share referral code to friends and social media. <br> Step 2: Guide them to register. <br> Step 3: They make the first deposit. <br> Step 4: Claim and spend your referral rewards. <br> Step 5: Repeat the steps!',
        INVITE_SECT: {
          TITLE: 'Invite Users',
          SHARES: 'Shares',
          INVITEES: 'Invitees',
          INVITE_LINK: 'Invite link',
          INVITE_QR_CODE: 'Invite QR code',
          QUICK_SHARE: 'Quick share',
          DOWNLOAD_BTN: 'Download'
        },
        DATA_CENTER: {
          TITLE: 'Data Center',
          TOTAL_REVENUE: 'Total revenue',
          INVITATION: 'Invitation',
          WAGER: 'Wager',
          ACHIEVEMENT: 'Achievement',
          VIP: 'VIP',
          MORE_BTN: 'More'
        },
        REWARD_SECT: {
          TITLE: 'Reward Redemption',
          AMOUNT_WITHDRAWN: 'Amount that can be claimed',
          EXCHANGE_BUTTON_TXT: 'Exchange',
          CLAIM_BUTTON_TXT: 'Claim',
          TOTAL_REWARD: 'Total Rewards Available : {{value}}'
        },
        ACTIVITY_RULES: {
          TITLE: 'Activity Rules',
          SEE_MORE: 'See More',
          DATA1: {
            LABEL: 'INVITATION BONUS',
            TEXT: 'Every time you invite a friend whose deposit reaches 20 USD, you will get, 5 USD rewards.'
          },
          DATA2: {
            LABEL: 'BETTING COMMISSION',
            TEXT: 'Bonus Rules: All players at the "Promoter" level receive a percentage of the platform advantage bonus for every bet.'
          },
          DATA3: {
            LABEL: 'ACHIEVEMENT BONUS',
            TEXT: 'If the cumulative number of invitations meets the conditions, you will receive additional achievement rewards. <br> The more people you invite, the higher the reward.'
          },
          DATA4: {
            LABEL: 'VIP LEVEL-UP BONUS',
            TEXT: 'Every user you invite reaches the specified level in the game, and you will get additional VIP level-up bonus. <br> USD is a special currency launched by Platform . You can …'
          }
        },
        AMONG_BEST: 'Among the best',
        LIVE_REWARD: 'Live Rewards',
        QA: {
          TITLE: 'Q&A'
        },
        EXCHANGE: {
          MESSAGE: 'Received successfully',
          CONFIRM_BUTTON_TXT: 'Confirm'
        },
        ACHIEVE_BONUS: {
          TITLE: 'Achievement Bonus',
          DESCRIPTION: 'If the cumulative number of invitations meets the conditions, you will receive additional achievement rewards. <br> The more people you invite, the higher the reward.',
          TABLE: {
            COLUMN1: 'Friend’s Level',
            COLUMN2: 'Several people',
            COLUMN3: 'Rewards'
          }
        },
        BETTING_COMMISSION: {
          TITLE: 'Betting Commission',
          SHORT_DESC:'Bonus Rules: All players at the "Promoter" level receive a percentage of the platform advantage bonus for every bet.',
          LONG_DESC: `The proportions of the 3 levels are as follows <br>
          Level 1: Get 35% Of 1% wager <br>
          Level 2: Get 12.25% Of 1% wager <br>
          Level 3: Get 4.29% Of 1% wager <br>
          This will be your long-term income, and every time a player you refer places a bet, you will receive a different percentage of the commission regardless of whether he wins or loses.So what you have to do is to improve your game skills, think about how to win the game and share it with others, and help more people win with your method.We want all players to have fun in our platform, whether it's the fun of winning bets or the game itself!`
        },
        USER_LIST: {
          TITLE: "Users List",
          TABS: {
            TAB1: 'Sub-user',
            TAB2: 'Subordinate users'
          },
          SEARCH_PLACEHOLDER: 'Search User UID',
          SELECTS: {
            ITEM1: 'All',
            ITEM2: 'Today',
            ITEM3: 'Past 7 days',
            ITEM4: 'Past 30 days',
            ITEM5: 'Past 60 days'
          },
          TABLE: {
            COLUMN1: 'Email / Phone',
            COLUMN2: 'Registered At',
            COLUMN3: 'Bonus',
            COLUMN4: 'BETTING',
            COLUMN5: 'INVITATION',
          },
          TOTAL: 'Total',
          NO_DATA: "No user data"
        },
        VIP_LEVEL_BONUS: {
          TITLE: 'VIP Level-Up Bonus',
          DESC: 'Every user you invite reaches the specified level in the game, and you will get additional VIP level-up bonus. USD is a special currency launched by Platform . You can Swap USD into other currencies at any time.',
          TABLE: {
            COLUMN1: 'Friend’s Level',
            COLUMN2: 'Total Wager',
            COLUMN3: 'Rewards'
          }
        }
      }
    },
    KYC: {
      TITLE: "KYC",
      DESC: "Due to the regulatory requirement from National Lottery Regulatory Commission (NLRC) and Curaçao operating license,we require you to verify your Email Address and Phone number to prevent fraud, cheating and suspicious activity. If you are experiencing issue, you may also seek assistance from Live Chat.",
      BUTTON_TXT: "Account"
    },
    GOOGLE_LOGIN: {
      EERROR_MSG: 'Could not login with the account info. Please try again with others'
    },
    SUCCESS_MSG: {
      TITLE: 'Success!'
    },
    ERROR_MSG: {
      TITLE: 'Sorry, there was a problem'
    },
    COMMON: {
      COMING_SOON: "Coming Soon",
      LOAD_MORE: "Load More",
      LOGOUT: 'Logout',
      DEPOSIT: 'Deposit',
      WITHDRAW: 'Withdraw',
      INVALID_AMOUNT: 'Invalid amount',
      HOME_BUTTON_TXT: 'Home',
      BACK_BUTTON_TXT: 'Back',
      COPIED: 'Copied',
      OK: "OK",
      CANCEL: "Cancel",
      SEARCH: "Search"
    },
    LUCKSPIN: {
      TITLE: "Lucky Spin",
      TRY_AGAIN: "Try again",
      BUTTON_TEXT: "Spin",
      WIN_SPIN_TEXT: "LUCKY WIN SPIN NOW",
      BOTTOM_TEXT: "DAILY FREE SPINS",
      AVAILABLE_SPINS: "Available spins"
    },
    ERRORS: {
      400: 'Illegal status',
      401: 'Unauthorized',
      402: 'Payment required',
      404: 'Not found',
      500: 'Internal server error',
      503: '[Ezugi] Invalid game identifier',
      504: '[Ezugi] Invalid operation',
      506: '[Ezugi] Invalid currency',
      508: '[Ezugi] Invalid transaction identifier',
      511: '[Leander] Invalid token',
      1002: '[Evolution] Invalid session id',
      1007: '[NYX] RC unknown currency',
      6001: '[Vanguard] Game session invalid',
      6003: '[Vanguard] Authentication failed',
      40011: '[RGS] Invalid game type',
      40012: '[RGS] Invalid game ID',
      10000: 'Accounts limit exceeded',
      10001: 'Addacct failed',
      10002: 'Age verification failed',
      10004: 'Already has account',
      10005: 'Already registered',
      10006: 'Already sent',
      10010: 'Bad argument',
      10017: "Bonus code not found",
      10018: 'Bonus does not exist',
      10019: 'Bonus invalid',
      10021: 'Can not approve payout request',
      10022: 'Can not cancel payout request',
      10024: 'Can not process payout',
      10025: 'Can not process payout request',
      10034: 'Currency code not found',
      10037: 'Customer already in',
      10038: 'Customer does not exist', //
      10042: 'Customer in blacklist',
      10045: 'Customer not found', //
      10046: 'Customer not registered', //
      10047: 'Customer self excluded',
      10049: 'Customer suspended',
      10052: 'Customer verification not found',
      10055: 'Cust update failed',
      10059: 'Disabled acct',
      10064: 'Email already exists',
      10066: 'Email code not found',
      10069: 'Email in use',
      10071: 'Email not found',
      10143: 'File invalid format',
      10145: 'Game already started',
      10146: 'Game code is not valid',
      10150: 'Game not found',
      10154: 'Get balance',
      10155: 'Get balance error',
      10170: 'Insufficient funds',
      10173: 'Invalid amount max',
      10174: 'Invalid amount min',
      10177: 'Invalid bonus id',
      10178: 'Invalid bonus type',
      10186: 'Invalid customer',
      10190: 'Invalid game id',
      10200: 'Invalid session',
      10207: 'Invalid token',
      10221: 'Login failure',
      10222: 'Login max logins reached',
      10223: 'Login wrong password',
      10224: 'Login wrong username',
      10225: 'Logout failed',
      10246: 'Password not match',
      10250: 'Phone already exists',
      10307: 'Username already exists',
      10308: 'Validate birthdate failed',
      10325: 'The email is not registered yet',
      10326: 'The email has already been used as google sso account',
      10327: 'Wrong referral code',
      10328: 'Customer has not disbursed referral commission',
      10329: 'cannot level up',
      10330: 'Failed to claim VIP cashback',
      10331: 'Invalid customer VIP level',
      10332: 'Failed to claim referral commission',
      10333: 'Failed to get referral achievement information',
      10334: 'Invalid phone number',
      10341: "Not enough value to claim bonus",
      11507: 'Country not found',
      11511: 'Invalid casino bonus',
      11512: 'Please close all opened games in order to forfeit the bonus.',
      11513: 'A saved game reserved funds from this bonus - please resolve it first.',
      11514: 'A sport event reserved funds from this bonus - please wait for its resolution first.',
      11515: 'Inactive casino bonus',
      11516: 'Could not forfeit Any Deposit Rollover casino bonus.',
      11517: "Insufficient player's daily credit",
      11518: 'Failed to acquire prize',
      11519: "Insufficient prize's daily count",
      11520: "Please wait for the cooldown period before sending another email.",
      11521: "Please wait for the cooldown period before sending another sms.",
      11522: "Reach maximum times for sending email for verification in a day.",
      11523: "Reach maximum times for sending sms for verification in a day.",
      11526: "ClubPay Exception",
      100000: 'VALIDATE_SIGNUP_CODE_FAILED',
      100002: 'SEND_SMS_VALIDATION_FAILED',
      40088: "Tag is assigned and can't be deleted"
    },
    BONUS_TYPES: {
      "Deposit": "Deposit",
      "Withdrawal": "Withdrawal",
      "Table Buyin": "Table Buyin",
      "Table Rebuy": "Table Rebuy",
      "Table Leave": "Table Leave",
      "Place Bet": "Place Bet",
      "Tournament Buyin": "Tournament Buyin",
      "Tournament Fee": "Tournament Fee",
      "Tournament Buyin Refund": "Tournament Buyin Refund",
      "Tournament Fee Refund": "Tournament Fee Refund",
      "Manual Adjustment": "Manual Adjustment",
      "Recovery Refund": "Recovery Refund",
      "Transfer In": "Transfer In",
      "Transfer Out": "Transfer Out",
      "Round Win": "Round Win",
      "Bonus Win": "Bonus Win",
      "Deposit Bonus": "Deposit Bonus",
      "Account Creation Bonus": "Account Creation Bonus",
      "Referral Bonus": "Referral Bonus",
      "Bonus Conversion": "Bonus Conversion",
      "Login Grant": "Login Grant",
      "Initial Grant": "Initial Grant",
      "Virtual Goods Purchase": "Virtual Goods Purchase",
      "Loyalty": "Loyalty",
      "Winning in random scratcher": "Winning in random scratcher",
      "Mystery Gift Win": "Mystery Gift Win",
      "FB Credit Order": "FB Credit Order",
      "Leader Board Points": "Leader Board Points",
      "Tournament Award": "Tournament Award",
      "Returning Player Bonus": "Returning Player Bonus",
      "Cancelled round refund": "Cancelled round refund",
      "Sit & Go Award": "Sit & Go Award",
      "Sit & Go Buyin": "Sit & Go Buyin",
      "Sit & Go Fee": "Sit & Go Fee",
      "Sit & Go Buyin Refund": "Sit & Go Buyin Refund",
      "Sit & Go Fee Refund": "Sit & Go Fee Refund",
      "Fast Poker buyin": "Fast Poker buyin",
      "Fast Poker leave": "Fast Poker leave",
      "Fast Poker rebuy": "Fast Poker rebuy",
      "Tournament Rebuy": "Tournament Rebuy",
      "Tournament AddOn": "Tournament AddOn",
      "Sit & Go Rebuy": "Sit & Go Rebuy",
      "Sit & Go AddOn": "Sit & Go AddOn",
      "Bonus Deposit": "Bonus Deposit",
      "Bonus Released": "Bonus Released",
      "Bonus Deposit Released": "Bonus Deposit Released",
      "Bonus Forfeit": "Bonus Forfeit",
      "Bonus Expired": "Bonus Expired",
      "Bonus Winning Released": "Bonus Winning Released",
      "Void Deposit": "Void Deposit",
      "Bonus Deposit Forfeit": "Bonus Deposit Forfeit",
      "Bonus Winnings Forfeit": "Bonus Winnings Forfeit",
      "Bonus Deposit Expired": "Bonus Deposit Expired",
      "Bonus Winnings Expired": "Bonus Winnings Expired",
      "SnG Buyin Recovery Refund": "SnG Buyin Recovery Refund",
      "SnG Fee Recovery Refund": "SnG Fee Recovery Refund",
      "Tournament Buyin Recovery Refund": "Tournament Buyin Recovery Refund",
      "Tournament Fee Recovery Refund": "Tournament Fee Recovery Refund",
      "Clearing released bonus": "Clearing released bonus",
      "Clearing released bonus deposit": "Clearing released bonus deposit",
      "Clearing released bonus winnings": "Clearing released bonus winnings",
      "Void Withdrawal": "Void Withdrawal",
      "Bonus Deposit Forfeit Cleared": "Bonus Deposit Forfeit Cleared",
      "Bonus Deposit Expired Cleared": "Bonus Deposit Expired Cleared",
      "Bonus Cash Grant": "Bonus Cash Grant",
      "Place a Purchase": "Place a Purchase",
      "Cancel a Purchase": "Cancel a Purchase",
      "Tournament Rebuy Refund": "Tournament Rebuy Refund",
      "Tournament Rebuy Recovery Refund": "Tournament Rebuy Recovery Refund",
      "Tournament AddOn Refund": "Tournament AddOn Refund",
      "Tournament AddOn Recovery Refund": "Tournament AddOn Recovery Refund",
      "SnG Rebuy Refund": "SnG Rebuy Refund",
      "SnG Rebuy Recovery Refund": "SnG Rebuy Recovery Refund",
      "SnG AddOn Refund": "SnG AddOn Refund",
      "SnG AddOn Recovery Refund": "SnG AddOn Recovery Refund",
      "Bounty Tournament Award": "Bounty Tournament Award",
      "Jackpot winning": "Jackpot winning",
      "Collectible game award": "Collectible game award",
      "Claim reward": "Claim reward",
      "Cancel reward": "Cancel reward",
      "Coupon redeemed": "Coupon redeemed",
      "Tournament Bounty Buyin": "Tournament Bounty Buyin",
      "Tournament Bounty Buyin Refund": "Tournament Bounty Buyin Refund",
      "Tournament Bounty Buyin Recovery Refund": "Tournament Bounty Buyin Recovery Refund",
      "SnG Bounty Buyin": "SnG Bounty Buyin",
      "SnG Bounty Buyin Refund": "SnG Bounty Buyin Refund",
      "SnG Bounty Buyin Recovery Refund": "SnG Bounty Buyin Recovery Refund",
      "Reward Claimed Refund": "Reward Claimed Refund",
      "Void reward": "Void reward",
      "Tournament Prize Pool Distribution On Recovery": "Tournament Prize Pool Distribution On Recovery",
      "SnG Prize Pool Distribution On Recovery": "SnG Prize Pool Distribution On Recovery",
      "Chips Coupon Claimed": "Chips Coupon Claimed",
      "Milestone Bonus": "Milestone Bonus",
      "External Grant": "External Grant",
      "Void Bet": "Void Bet",
      "Daily TopOff": "Daily TopOff",
      "Bonus Grant": "Bonus Grant",
      "Bonus Used": "Bonus Used",
      "Sport Bet": "Sport Bet",
      "Sport Win": "Sport Win",
      "Sport Win Adjustment": "Sport Win Adjustment",
      "Sport Round Refund": "Sport Round Refund",
      "Sport Round Void": "Sport Round Void",
      "Round Win Bonus": "Round Win Bonus",
      "IBC Sport Unsettle": "IBC Sport Unsettle",
      "IBC Sport Adjust Balance": "IBC Sport Adjust Balance",
      "VIP Level rank-up bonus": "VIP Level rank-up bonus",
      "VIP Level cashback bonus": "VIP Level cashback bonus",
      "Referral commission bonus": "Referral commission bonus",
      "Any Deposit Rollover Bonus": "Any Deposit Rollover Bonus",
      "KYC bonus": "KYC bonus",
      "Daily Lose Rake Back": "Daily Lose Rake Back",
      "Daily lose cashback bonus": "Daily lose cashback bonus",
      "IBC Sport Settled": "IBC Sport Settled"
    }
	}
};
