import { Navigator } from './../../system/components/menu/navigator';
import { Storage } from '@ionic/storage-angular';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Config } from "../../system/providers/configuration";
import { UserSessionProvider } from "../../system/providers/user-session";
import { WebservicesProvider, PlatformResponse } from "../../system/providers/webservices";
import { CustomerModel } from '../../modules/account/models/customer-model';
import { MyEvent } from 'src/app/service/myevent.services';

declare var EventSourcePolyfill: any;

export interface NotificationEventInterface {
    id: number;
    status: string;
    text: string;
    title: string;
    type: string;
    customField3: string;
    customField4: string;
    customField5: string;
    parametersMap: {
        gameTypeIds: string;
        amount: string;
        currency: string;
        winner: string;
    }
}

export interface NotificationErrorInterface {

}

/**
 * NotificationApiProvider
 *
 * listen() method provides an Observable event stream of messages that bubble to the UI.
 * Other message types may invoke globalErrorHandler behaviour or other behaviour not
 * related to the containing page.
 *
 * no other methods are public.
 */

@Injectable({
  providedIn: 'root'
})
export class NotificationApiProvider {
    readonly LISTENER_EVENT_TYPE = 'instant_notification';
    USER_DATA_STORE_KEY = 'userSession.customer';

    protected _eventSubject: Subject<any>;
    protected _eventSource: any;// EventSource;
    protected _sessionToken = "0";
    private isPaused: boolean = false;
    private messageBuffer: Array<any>;

    protected retryNr = 0; //@todo config
    protected retryTime = null; //@todo config
    protected retryNrAllowed; //@todo config
    protected retryTimeAllowed; //@todo config


    constructor(
        private storage: Storage,
        private config: Config,
        private userSession: UserSessionProvider,
        private webservice: WebservicesProvider,
        private events: MyEvent,
    ) {
        this._eventSubject = new Subject<any>();

        this.retryNrAllowed =   ~~this.config.getConfig().FEATURE_NOTIFICATIONS_RETRY_NR || 10;
        this.retryTimeAllowed = ~~this.config.getConfig().FEATURE_NOTIFICATIONS_RETRY_TIME || 15000;
    }

    /**
     * Returns an Observable event stream of messages that shouold be displayed to the user.
     */
    public listen(skipCheckSessionDup = false) {
        this.messageBuffer = [];
        this.userSession.getCustomer().subscribe((customer) => {
            let session_token = this.userSession.getSessionToken();
            if (session_token && (this._sessionToken !== session_token || skipCheckSessionDup)) {
                this._sessionToken = session_token;
                this.initialize(this.LISTENER_EVENT_TYPE, session_token);
            }
        });

        return this._eventSubject;
    }

    public startOver() {
        this.messageBuffer = [];
                this.initialize(this.LISTENER_EVENT_TYPE, this._sessionToken);
    }

    public pause() {
        console.warn('notification-api: pausing');
        this.isPaused = true;
    }

    public resume() {
        console.warn('notification-api: resuming');
        this.isPaused = false;
        this.drainBuffer();
    }

    protected emit(msg) {
        if (this.isPaused) {
            this.messageBuffer.push(msg);
        } else {
            this._eventSubject.next(msg);
        }
    }

    protected drainBuffer() {
        this.isPaused = false;
        setTimeout(() => {
            console.warn('notification-api: draining...');
            while (this.messageBuffer.length > 0) {
                let msg = this.messageBuffer.shift();
                this._eventSubject.next(msg);
            }
        }, 1 * 1000);
    }

    public unsubscribe() {
        if (this._eventSource) {
            this._eventSource.close();
        }
    }
    theSubject() {
        return this._eventSubject;
    }
    //
    protected check_session_retries: number = 0;
    protected checkSession(): Promise<any> {
        return new Promise((resolve, reject) => {
          if (localStorage.getItem(this.USER_DATA_STORE_KEY)) {
            let customer = JSON.parse(localStorage.getItem(this.USER_DATA_STORE_KEY));
            this.webservice.get('auth/player/status', {
              player_id: customer.customerId,
              session_id: this.userSession.sessionToken
            }).subscribe((response: any) => {
              this.check_session_retries = 0;
              console.log('notification-api.ts: check-session response: ', response);
              if (response.payload) {
                resolve(response);
              } else {
                reject(10201);
              }
            }, (err) => {
              console.log( 'notification-api.ts: webservice error: ', err);
            });
          } else {
            reject(10201);
          }
        });
    }
    protected initialize(event_type: string, session_token: string) {
        //
        let EventSource = window['EventSource'] || EventSourcePolyfill;

        //
        var url = this.config.getConfig().NOTIFICATION_BASEURL + '/notificationapi/resources/player_notification/subscribe?session_id=' + session_token;
        console.warn('notification-api.ts: opening notification channel:');
        this._eventSource = new EventSource(url, { withCredentials: false });

        this._eventSource.onerror = (e: any) => {
            console.warn('notification-api.ts: subscription got error, error:', e);
            this._eventSource.close();
            this.checkSession().then((response) => {
                console.log('notification-api.ts: response: ', response);

                if (this.isAllowedToReInit()) {
                    this.initialize(event_type, session_token);
                }
            }).catch((err) => {
                console.error('notification-api.ts: err:', err);
                this.events.publishSomeData( {event: 'event:authentication:logout'});
            });
        };

        this._eventSource.onopen = (e: any) => {
            console.log("ON _eventSource OPEN")
            this._eventSource.addEventListener(event_type, (e: any) => {
                this.dispatch(JSON.parse(e.data));
            }, false);
            this._eventSource.addEventListener('close', (e: any) => {
                this._eventSource = null;
            }, false);
        };

        //        this._eventSource.addEventListener( 'error', (e: any) => {
        //            console.warn( 'notification-api.ts: subscription was closed, event:', e);
        //            this.initialize( event_type, session_token);
        //        }, false);

    }

    private isAllowedToReInit() {
        if (this.retryTime == null) this.retryTime = Date.now();
        console.info("BlackWidow: skip logout check")
        // if ( Date.now() - this.retryTime < this.retryTimeAllowed ) {
        //     if( this.retryNr >= this.retryNrAllowed ) {
        //         this.retryTime = null;
        //         this.retryNr = 0;
        //         Navigator.navigateTo("LogoutPage", {}, { code: 'RUNNING_MULTIPLE_TABS', reason: 'RUNNING_MULTIPLE_TABS' });
        //         return false;
        //     }
        // } else {
        //     this.retryTime = null;
        //     this.retryNr = 0;
        // }

        // this.retryNr++;
        return false;
    }

    protected dispatch(e: NotificationEventInterface) {
        // dispatch event to listener (notification component can listen and manage event workflow)
        this.emit(e);
    }
}


