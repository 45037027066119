import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { CommonService } from 'src/app/service/common.service';
import { BalanceProvider } from 'src/app/system/providers/balance-provider';
import { debounceTime, distinctUntilChanged, filter, map, delay } from 'rxjs/operators';
import { BalanceModel } from 'src/app/system/models/balance-model';
import { Config } from 'src/app/system/providers/configuration';

@Component({
  selector: 'app-balances',
  templateUrl: './balances.component.html',
  styleUrls: ['./balances.component.scss'],
})
export class BalancesComponent implements OnInit {

  @Input() balances: any[] = [];

  flats: any[] = [];
  cryptos: any[] = [];

  displayInUSD: boolean = false;
  hideFiatConvert = false;

  constructor(
    private popCtrl: PopoverController,
    public comService: CommonService,
    private cdr: ChangeDetectorRef,
    private balanceProvider: BalanceProvider,
    private config: Config
  ) { }

  ngOnInit() {}

  ngAfterViewInit() {
    var appConfig = this.config.getAppConfigInfo();
    this.hideFiatConvert = appConfig['HIDE_FIAT_CONVERT'];
    this.initBalance();
  }

  ionViewDidEnter() {

  }

  initBalance() {
    this.balances = [];

    this.balanceProvider
      .getBalance().pipe(delay(350))
      .subscribe((response: BalanceModel) => {
        if (response) {
          this.balances = [];
          Object.keys(response).forEach(key => {
            if (key != 'DEFAULT_CURRENCY' && key != 'FIFI') {
              let bb = {
                currency: key,
                balance: response.getDisplayBalanceByCurrency(key),
                amountInUSD: response.amountInUSD(key)
              }
              this.balances.push(bb);
            }
          });

          this.balances.sort((a,b) => {
            if (a.amountInUSD > b.amountInUSD) return -1;
            else if (a.amountInUSD < b.amountInUSD) return 1;
            else return 0;
          });

          this.flats = this.balances.filter(bl => this.comService.checkNormalCurrency(bl.currency));
          this.cryptos = this.balances.filter(bl => !this.comService.checkNormalCurrency(bl.currency));

          this.cdr.detectChanges();
        }
      });
  }

  selectBalance(bal) {
    setTimeout(() => {
      this.popCtrl.dismiss({balance: bal}, 'changed');
    }, 300);
  }

  toggleSwitch($event) {
    this.displayInUSD = $event.detail.checked;
  }

}
