import { PopoverFactory } from './../modules/popover/factory';
import { Inject, Injectable } from '@angular/core';
import { Config } from './../system/providers/configuration';
import { Navigator } from './../system/components/menu/navigator';
import { UserSessionProvider } from '../system/providers/user-session';
import { Platform, PopoverController } from '@ionic/angular';
import { MyEvent } from '../service/myevent.services';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppLinks {

  public stateLoaded;

  constructor(
    public config: Config,
    public platform: Platform,
    private events: MyEvent,
    private popoverCtrl: PopoverController,
    private iab: InAppBrowser,
    public http: HttpClient,
    private userSession: UserSessionProvider,
  ) {
    this.http.get('/assets/web.config').subscribe((data: any) => {
      this.stateLoaded = data.env;
    });
  }

  public openExternalPage(url) {
    if (!url) return;
    if (this.platform.is('cordova') && this.platform.is('ios')) {
      this.iab.create(url, "_system", "location=yes,enableviewportscale=yes,fullscreen=no");
      return false;
    } else if (this.platform.is('mobileweb')) {
      window.open(url, '_blank');
      return false;
    } else {
      window.open(url, '_system');
    }
  }

  openInNewWindow(url, newTab = false) {
    if (this.platform.is('cordova')) {
      this.iab.create(url, '', 'location=yes,footer=no,hardwareback=yes,hidenavigationbuttons=no,fullscreen=no');
    } else if (this.platform.is('mobileweb') && !this.platform.is('cordova')) {
      window.open(url, '_system');
      return false;
    } else {
      let w = window.innerWidth;
      let h = window.innerHeight;
      if (newTab) {
        window.open(url, '_blank');
      } else {
        window.open(url, '_blank', `height=${h},width=${w}`);
      }

      return false;
    }
  }

  async goToNetentHistoryPage(url, title) {
    let popoverView = PopoverFactory('PopoveriFrame');
    let popover = await this.popoverCtrl.create({
      component: popoverView,
      componentProps: { url: url, title: title },
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: 'standard-popover iframe-content full-screen'
    });

    popover.present();
    return false;
  }

  checkIfAuthenticated(data) {
    if (this.userSession.isAuthenticated()) {
      Navigator.navigateTo(data, { multipleModals: true });
    } else {
      Navigator.navigateTo("LoginPage", { multipleModals: true, cssClass: 'login_modal' });
    }
  }

  goToHelpAndInfoUrl() {
    let helpUrl = this.config.getConfig().FEATURE_HELP_INFO_URL || "https://help.whitelabelcompany.com/en-ca";
    this.openInNewWindow(helpUrl);
  }


  // only it has www or http
  openSidemenuExternalLink(url) {
    if (!~url.indexOf("http") || !~url.indexOf("www")) {
      let cleanUrl = this.concatWithLobbyUrl(url);
      this.events.publishSomeData({ event: "event:webview:load", data: url });
    } else {
      this.openExternalPage(url);
    }

  }


  privacyPolicy() {
    this.openInternalWordpressPages("privacy")
    return false;
  }

  private openInternalWordpressPages(code) {
    let link = this.config.getExternalLinks().find(link => link.code == code);
    let url = this.cleanUrl(link.url);
    Navigator.toLobby();
    this.events.publishSomeData({ event: "event:webview:load", data: url });
  }

  private cleanUrl(url) {
    if (url[0] != "/") {
      url = "/" + url;
    }
    if (url[url.length - 1] == "/") {
      url = url.substring(0, url.length - 1)
    }
    //return this.config.getConfig().LOBBY_URL + url;
    return url;
  }

  private concatWithLobbyUrl(url) {
    this.cleanUrl(url)
    return this.config.getConfig().LOBBY_URL + url;
  }
}
