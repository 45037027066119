import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { MyEvent } from 'src/app/service/myevent.services';
import { TranslationService } from 'src/app/service/translation.service';
import { Navigator } from 'src/app/system/components/menu/navigator';
import { Config } from 'src/app/system/providers/configuration';
import { WebservicesProvider } from 'src/app/system/providers/webservices';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss'],
})
export class MainFooterComponent implements OnInit {

  activeTab: any;

  sportGames: any[] = [];
  appConfigInfo: any;

  constructor(
    private events: MyEvent,
    public comService: CommonService,
    private webService: WebservicesProvider,
    private transService: TranslationService,
    private config: Config,
  ) { }

  ngOnInit() {
    this.appConfigInfo = this.config.getAppConfigInfo();

    this.events.getObservable().subscribe(data => {
      if (data.event == 'event:tabs:load') {
        this.activeTab = data.tab.toLowerCase();
      } else if (data.event == 'event:bonusdetail') {
        this.activeTab = null;
      } else if (data.event == 'event:load:sports') {
        this.goSports();
      }
    });

    this.getSportsData();

    this.transService.changeLangSubject.subscribe(lang => {
      this.getSportsData();
    });
  }

  getSportsData() {
    this.webService.get('sports/games').subscribe(response => {
      if (response.code == 200) {
        this.sportGames = response.payload;
      }
    }, error => {
    });
  }

  tabClick(tab) {
    if (tab == 'football') {

      this.goSports();

    } else {
      this.events.publishSomeData({event: 'event:tabs:load', tab: tab});
      this.events.publishSomeData({event: 'event:main:button'});
    }
  }

  toggleMenu() {
    // this.menuCtrl.toggle();
    Navigator.navigateTo('MenuPage');
  }

  showBonus() {
    this.events.publishSomeData({event: 'event:main:button'});
    this.events.publishSomeData({event: 'event:tabs:load', tab: 'bonuses'});
  }

  goSports() {
    if (!this.sportGames.length) {
      Navigator.openModal('FootballPage', 'football_modal');
    } else {
      if (this.appConfigInfo.experimental['sports']) {
        var sport = this.sportGames[0];
        sessionStorage.setItem('sport_game_api', sport.sportGameLink);
        this.events.publishSomeData({event: 'event:tabs:load', tab: 'sports'});
      } else {
        Navigator.openModal('FootballPage', 'football_modal');
      }
    }
  }

}
