import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { IonInput } from '@ionic/angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map } from 'rxjs/operators';
import { CustomerModel } from 'src/app/modules/account/models/customer-model';
import { CashierContentProvider } from 'src/app/modules/cashier/providers/cashier-content-provider';
import { CashierProvider } from 'src/app/modules/cashier/providers/cashier-provider';
import { MainProvider } from 'src/app/modules/lobby/providers/main.provider';
import { RewardsProvider } from 'src/app/modules/rewards/providers/rewards-provider';
import { CommonService } from 'src/app/service/common.service';
import { MyEvent } from 'src/app/service/myevent.services';
import { TranslationService } from 'src/app/service/translation.service';
import { AppLinks } from 'src/app/shared/app-links';
import { AffiliateTrackerCallbackParams } from 'src/app/shared/providers/affiliation-event';
import { Utils } from 'src/app/shared/utils';
import { Navigator } from 'src/app/system/components/menu/navigator';
import { BalanceModel } from 'src/app/system/models/balance-model';
import { BalanceProvider } from 'src/app/system/providers/balance-provider';
import { Config } from 'src/app/system/providers/configuration';
import { UserSessionProvider } from 'src/app/system/providers/user-session';
import { WebservicesProvider } from 'src/app/system/providers/webservices';
import * as copy from 'copy-to-clipboard';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.scss'],
})
export class WithdrawComponent implements OnInit, OnDestroy {

  @ViewChild('amountInputElDnc') amountInputElDnc!: IonInput;
  @ViewChild('amountInputElDc') amountInputElDc!: IonInput;
  @ViewChild('amountInputElMnc') amountInputElMnc!: IonInput;
  @ViewChild('amountInputElMc') amountInputElMc!: IonInput;

  customer: CustomerModel;
  customSubs: any[] = [];

  allDepositMethods: any[] = [];
  depositMethods: any[] = [];

  currencyPaymentTypes: any;
  currencies: any[] = [];
  currency: any;
  currencySymbol: any;
  showOtherCurrencies = false;
  selectedBalance: any;
  addresses: any[] = [];
  selectedAddress: any;

  selectedPayment;

  amount: any;
  minAmount: any = 0;
  beneAccount: any;
  readonlyBeneAccount = false;

  cryptoFee: any = 0;
  amountSub: BehaviorSubject<string> = new BehaviorSubject<string>('');
  feeSub: Subject<string> = new Subject<string>();

  allBankList: any;
  bankList: any[] = [];
  showBankList = false;
  selectedBank: any;

  allExistAccounts: any[] = [];
  accounts: any[] = [];

  cryptoAddress: AbstractControl;
  cryptoAddressForm: FormGroup;

  appConfig: any;
  minamounterror: any;

  loaded = false;

  ifscNumber: any;
  cpfNumber: any;
  accountType: any;
  allAccountTypes: any[] = [];
  accountTypes: any[] = [];
  showAccountType: any;

  currencyDone: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  balanceSub: any;

  showPayType = false;
  clubPayType = 'BANKCARD';

  allowWithdraw = false;

  constructor(
    public comService: CommonService,
    private cdr: ChangeDetectorRef,
    private contentProvider: CashierContentProvider,
    private config: Config,
    public utils: Utils,
    private cashierProvider: CashierProvider,
    public rewardsProvider: RewardsProvider,
    private userSession: UserSessionProvider,
    private webService: WebservicesProvider,
    private balanceProvider: BalanceProvider,
    private clipboard: Clipboard,
    private events: MyEvent,
    private appLinks: AppLinks,
    private transService: TranslationService,
    private fb: FormBuilder,
  ) {
    this.cryptoAddressForm = this.fb.group({
      address: new FormControl('', [
        Validators.required
      ])
    });
    this.cryptoAddress = this.cryptoAddressForm.get('address');
  }

  ngOnInit() {
    this.customSubs.push(this.userSession.getCustomer().subscribe(customer => {
      this.customer = customer;
    }));

    this.appConfig = this.config.getAppConfigInfo();

    this.allAccountTypes = this.appConfig.HKP_TYPES;

    Promise.all([
      this.getBankCodes(),
      this.onGetAccounts()
    ]).then((values) => {
      this.customSubs.push(this.comService.walletPaymentSub.subscribe(data => {
        if (data) this.getWithdrawMethods(data);
      }));
    });

    this.customSubs.push(this.amountSub.pipe(map((amountText: any) => amountText), debounceTime(500)).subscribe((text: string) => {
      this.getWithdrawFee(text);
    }));

    this.customSubs.push(this.feeSub.subscribe(fee => this.fillInputField(fee)));

    this.customSubs.push(this.currencyDone.subscribe(complete => {
      if (complete && !this.balanceSub) {
        this.balanceSub = this.balanceProvider.getBalance().pipe(delay(350)).subscribe((response: BalanceModel) => {
          if (response) {
            Object.keys(response).forEach(key => {
              if (key != 'DEFAULT_CURRENCY') {
                var bal = response.getAvailableForWithdrawalBalanceByCurrency(key);
                var ccu = this.currencies.find(cuc => cuc.currency == key);
                if (ccu) {
                  ccu.balance = bal;
                  ccu.amountInUSD = response.amountInUSD(key);
                  ccu.restrictedBalance = response.forPlayRestrictedOnlyCasinoByCurrency(key);
                }
              }
            });

            this.currencies.sort((a,b) => {
              if (a.amountInUSD > b.amountInUSD) return -1;
              else if (a.amountInUSD < b.amountInUSD) return 1;
              else return 0;
            });

            this.selectedBalance = this.currencies.find(cuc => cuc.currency == this.currency);
          }
        });
        this.customSubs.push(this.balanceSub);
      }
    }));
  }

  ngOnDestroy(): void {
    this.customSubs.forEach(sub => sub.unsubscribe());
    this.customSubs = [];
  }

  ionViewWillLeave() {

  }

  getBankCodes() {
    return new Promise((resolve, reject) => {
      this.webService.get('cashier/bankcode/list/static', {
        player_id: this.customer.customerId,
        session_id: this.userSession.sessionToken,
      }).subscribe(data => {
        console.log('bank codes == ', data);
        if (data.code == 200 && data.result) {
          this.allBankList = data.payload;
        }
        resolve(this.allBankList);
      });
    });
  }

  onGetAccounts() {
    return new Promise((resolve, reject) => {
      this.allExistAccounts = [];
      this.cashierProvider.callListAccounts().subscribe((response) => {
        console.log('account list: ', response);
        if (response.result) {
          var tempAccounts: any = response.payload || [];
          this.allExistAccounts = tempAccounts.map((account, key) => {
            account.id = key;
            return account;
          });
        } else {
          console.error('Failed to get accounts');
        }
        resolve(this.allExistAccounts);
      }, error => {
        console.error('Failed to get accounts: ', error);
      });
    });
  }

  getWithdrawMethods(data) {
    this.currencyPaymentTypes = data.currencyPaymentTypesInfo;
    this.allowWithdraw = data.allowWithdraw
    Object.keys(this.currencyPaymentTypes).forEach(key => {
      if (key == 'MATIC') {

      } else {
        this.currencies.push({
          currency: key,
          balance: 0,
          amountInUSD: 0,
          restrictedBalance: 0,
          is_crypto: this.currencyPaymentTypes[key]['is_crypto']
        });
      }
    });

    if (!this.currency) {
      var tep = this.currencies.find(cuc => cuc.currency == this.config.getConfig().DEFAULT_CURRENCY);
      if (tep) {
        this.currency = tep.currency;
      }
      else if (this.currencies.length) {
        this.currency = this.currencies[0].currency;
      }
      else {
        this.currency = this.config.getConfig().DEFAULT_CURRENCY;
      }
    }
    this.currencySymbol = this.comService.currencySymbols[this.currency] || this.currency;


    let vendors = (data.paymentTypesInfo as Array<any>).filter(el => el.enabled_flg == 1);
    let sortedDepositMethods: any[] = [];
    for (let vendor of vendors) {
      if (!vendor.name) vendor.name = (vendor.types && vendor.types.length) ? vendor.types[0].name : '';

      vendor.logo = this.utils.getCDNAsset(
        'imgs/' + (vendor.image_url || ((vendor.types && vendor.types.length) ? vendor.types[0].image_url : ''))
      );

      if (vendor.code == 'PS') {
        vendor.defaultAmount = this.getDefaultAmount('FEATURE_PAYSTACK_AMOUNTS');
      } else if (vendor.code == 'BST') {
        vendor.defaultAmount = this.getDefaultAmount('FEATURE_BSTPAY_AMOUNTS');
      } else if (vendor.code == 'MN') {
        vendor.defaultAmount = this.getDefaultAmount('FEATURE_MINA_AMOUNTS');
      } else if (vendor.code == 'JP') {
        vendor.defaultAmount = this.getDefaultAmount('FEATURE_JUNE_AMOUNTS');
      } else if (vendor.code == 'PID') {
        vendor.defaultAmount = this.getDefaultAmount('FEATURE_PAYID_AMOUNTS');
      } else if (vendor.code == 'IPCT') {
        vendor.defaultAmount = this.getDefaultAmount('FEATURE_IPAY_CRYPTO_USDT_AMOUNTS');
      } else if (vendor.code == 'CLUB') {
        vendor.defaultAmount = this.getDefaultAmount('FEATURE_CLUB_PAY_AMOUNTS');
      } else if (vendor.code == 'FASTPAY') {
        vendor.defaultAmount = this.getDefaultAmount('FEATURE_FAST_PAY_AMOUNTS');
      } else {
        vendor.defaultAmount = [100, 50, 25];
      }

      vendor.min = (vendor.types && vendor.types.length) ? vendor.types[0].min : 0;
      vendor.max = (vendor.types && vendor.types.length) ? vendor.types[0].max : 0;
      vendor.fee = '0%';

      sortedDepositMethods.push(vendor);
    }
    this.allDepositMethods = sortedDepositMethods;

    if (this.currencyPaymentTypes[this.currency] && this.currencyPaymentTypes[this.currency]['is_crypto']) {
      this.getCryptoAddresses(this.currency, this.currencyPaymentTypes[this.currency].paymentTypes[0]);
    } else {
      this.depositMethods = this.allDepositMethods.filter(method => (this.currencyPaymentTypes[this.currency]?.paymentTypes || []).includes(method.code));
      this.selectPaymentMethod(this.depositMethods.length ? this.depositMethods[0] : null);
      this.loaded = true;
    }

    this.amount = null;
    this.minAmount = this.appConfig['WITHDRAW_MIN_AMOUNT'][this.currency] || 0;
    this.selectedBank = null;
    this.beneAccount = null;
    this.cryptoFee = 0;

    this.currencyDone.next(this.currencies);
  }

  getCryptoAddresses(currency, payment_type) {
    this.webService.post('cashier/crypto-address/list', {
      payment_method_code: payment_type,
      currency_code: currency,
      customer_id: this.userSession.getCustomerId(),
      session_id: this.userSession.getSessionToken()
    }).subscribe(response => {
      console.log('crypto addresses == ', response);
      if (response.code == 200) {
        var adds = response.payload[currency] || [];
        let config_chains: any[] = this.appConfig.CHAIN_TOKEN[currency] || [];
        this.addresses = adds.filter(el => config_chains.includes(el.chain_name));
        this.selectedAddress = this.addresses.length ? this.addresses[0] : null;
        this.selectAddress(this.selectedAddress);

        // var existCryptoAccount = this.allExistAccounts.find((account) => account.paymentTypeCode == this.selectedAddress.payment_type_code);
        // if (existCryptoAccount && existCryptoAccount.accountAdditionalInfo2 == this.selectedAddress.chain_id && existCryptoAccount.accountAdditionalInfo1 == this.selectedAddress.token_id) {
        //   this.cryptoAddress = existCryptoAccount.accountInfo;
        // }
        this.selectedPayment = null;
        this.depositMethods = [];
      }
      this.loaded = true;
      this.cdr.detectChanges();
    }, error => {
      console.error('crypto addresses error == ', error);
      this.addresses = [];
      this.selectedAddress = null;
      this.selectedPayment = null;
      this.depositMethods = [];
      this.loaded = true;
      this.cdr.detectChanges();
    });
  }


  getDefaultAmount(featureVendorAmounts) {
    return String(this.config.getConfig()[featureVendorAmounts]).split(',').reverse();
  }

  selectPaymentMethod(vendor) {
    // this.amount = null;
    if (!vendor) return;

    this.cryptoFee = 0;
    this.selectedBank = null;
    this.beneAccount = null;
    this.readonlyBeneAccount = false;
    this.selectedPayment = vendor;

    if (this.selectedPayment && this.selectedPayment.code == 'HKP') {
      this.selectedPayment.min = this.appConfig['HKPPay'][this.currency]['MIN'];
      this.selectedPayment.max = this.appConfig['HKPPay'][this.currency]['MAX'];
      if (this.config.getConfig()[`FEATURE_HKP_${this.currency}_AMOUNTS`]) {
        this.selectedPayment.defaultAmount = this.getDefaultAmount(`FEATURE_HKP_${this.currency}_AMOUNTS`);
      } else {
        this.selectedPayment.defaultAmount = this.getDefaultAmount('FEATURE_BSTPAY_AMOUNTS');
      }
    }

    this.bankList = [];
    if (this.currency == 'BRL' && this.selectedPayment?.code == 'IP') {
      this.bankList = (this.appConfig['IPAY_BANKCODE'] as Array<any>).filter(el => el.currency == this.currency);
      this.selectedBank = this.bankList.find(el => el.is_default);
    } else {
      var bankCodes = this.allBankList[this.selectedPayment?.code];
      if (bankCodes) {
        Object.keys(bankCodes).forEach(key => {
          var temp = {
            code: key,
            name: bankCodes[key]
          }
          this.bankList.push(temp);
        });
      }
    }


    this.accounts = this.allExistAccounts.filter((account) => account.paymentTypeCode == this.selectedPayment.code);
    this.accounts.forEach(acc => {
      var bk = this.bankList.find(bb => bb.code == acc.accountAdditionalInfo5);
      var bk_index = this.bankList.findIndex(bb => bb.code == acc.accountAdditionalInfo5);
      if (bk) {
        acc.name = bk.name;
        acc.code = bk.code;
        this.bankList.splice(bk_index, 1);
      }
    });
    this.accounts = this.accounts.filter(bl => bl.code && bl.name);


    if (this.selectedPayment?.code == 'CLUB') {
      this.accountType = null;
      this.accountTypes = [
        {
          code: 1,
          name: 'Private'
        },
        {
          code: 2,
          name: 'Public'
        }
      ];
      this.accountType = this.accountTypes[0];
    }
  }

  selectAddress(add) {
    this.selectedAddress = add;
    this.amount = null;
    this.selectedBank = null;
    this.beneAccount = null;
    this.minamounterror = null;
    this.cryptoFee = 0;
    this.amountSub.next('0');

    this.cryptoAddress = null;
    if (!add) return;

    var regex = this.appConfig['CHAIN_ADDRESS_REGEX'][add.chain_name];
    if (regex) {
      this.cryptoAddressForm.removeControl('address');

      this.cryptoAddressForm.addControl('address', new FormControl('', [
        Validators.required,
        Validators.pattern(regex)
      ]));
      this.cryptoAddress = this.cryptoAddressForm.get('address');
      this.cryptoAddressForm.reset();
    }
    else {
      this.cryptoAddressForm.removeControl('address');

      this.cryptoAddressForm.addControl('address', new FormControl('', [
        Validators.required
      ]));
      this.cryptoAddress = this.cryptoAddressForm.get('address');
      this.cryptoAddressForm.reset();
    }

    var existCryptoAccount = this.allExistAccounts.find((account) => account.paymentTypeCode == this.selectedAddress.payment_type_code);
    if (existCryptoAccount && existCryptoAccount.accountAdditionalInfo2 == this.selectedAddress.chain_id && existCryptoAccount.accountAdditionalInfo1 == this.selectedAddress.token_id) {
      this.cryptoAddress.patchValue(existCryptoAccount.accountInfo);
    }
  }

  inputAmount($event) {
    // this.amount = $event.target.value;
    const value = $event.target!.value;
    // Removes non alphanumeric characters
    var filteredValue = value.replace(/[^0-9.]+/g, '');
    var dots = (filteredValue as string).split('.');
    if (dots.length > 2) {
      var decimal = dots[1];
      for (let i = 2; i < dots.length; i++) {
        decimal += dots[i];
      }
      if (decimal.length > 8) {
        decimal = decimal.substring(0, 8);
      }

      var integ = dots[0];
      if (integ.length > 10) {
        integ = integ.substring(0, 10);
      }

      filteredValue = integ + '.' + decimal;
    } else if (dots.length == 2) {
      var decimal = dots[1];
      if (decimal.length > 8) {
        decimal = decimal.substring(0, 8);
      }

      var integ = dots[0];
      if (integ.length > 10) {
        integ = integ.substring(0, 10);
      }

      filteredValue = integ + '.' + decimal;
    } else {
      var integ = dots[0];
      if (integ.length > 10) {
        integ = integ.substring(0, 10);
      }
      filteredValue = integ;
    }

    this.amount = filteredValue;

    if (this.amountInputElDnc) this.amountInputElDnc.value = filteredValue;
    if (this.amountInputElDc) this.amountInputElDc.value = filteredValue;
    if (this.amountInputElMnc) this.amountInputElMnc.value = filteredValue;
    if (this.amountInputElMc) this.amountInputElMc.value = filteredValue;

    this.minamounterror = null;

    this.amountSub.next(this.amount);
  }

  amountInputDone($event) {

  }

  beneInput($event) {
    this.beneAccount = $event.target.value;
  }

  ifscInput($event) {
    this.ifscNumber = $event.target.value;
  }

  cpfInput($event) {
    this.cpfNumber = $event.target.value;
  }

  selectCurrecy(cuc) {
    this.selectedBalance = cuc;
    this.currency = this.selectedBalance.currency;
    this.currencySymbol = this.comService.currencySymbols[this.currency];

    this.accountType = null;
    this.accountTypes = this.allAccountTypes.filter(el => el.currency == this.currency);
    this.accountType = this.accountTypes.find(el => el.is_default);

    if (cuc['is_crypto']) {
      this.selectedBank = null;
      this.beneAccount = null;
      this.readonlyBeneAccount = false;

      this.getCryptoAddresses(this.currency, this.currencyPaymentTypes[this.currency].paymentTypes[0]);
    }
    else {
      this.addresses = [];
      this.selectedAddress = null;
      this.depositMethods = this.allDepositMethods.filter(method => (this.currencyPaymentTypes[this.currency]?.paymentTypes || []).includes(method.code));
      this.selectPaymentMethod(this.depositMethods.length ? this.depositMethods[0] : null);
    }

    this.amount = null;
    this.minAmount = this.appConfig['WITHDRAW_MIN_AMOUNT'][this.currency] || 0;
    this.cryptoFee = 0;

    this.amountSub.next('0');

    setTimeout(() => {
      this.showOtherCurrencies = false;
    }, 100);
  }

  selectHKPType(type) {
    this.accountType = type;
  }

  fillMaxAmount() {
    var maxAmount;
    if (!this.comService.checkCryptoCurrency(this.currency)) {
      maxAmount = this.selectedBalance?.balance / 100;
    } else {
      maxAmount = Number(((this.selectedBalance?.balance / 100) / Math.pow(10, 6)).toFixed(8));
    }

    this.getWithdrawFee(maxAmount, true);
  }

  fillInputField(fee) {
    var maxAmount;
    if (!this.comService.checkCryptoCurrency(this.currency)) {
      maxAmount = this.selectedBalance?.balance / 100;
    } else {
      maxAmount = Number(((this.selectedBalance?.balance / 100) / Math.pow(10, 6)).toFixed(8));
    }

    var tempAmount = Number(maxAmount) - Number(fee);
    var dots = ('' + tempAmount).split('.');
    var decimal = dots[1];
    if (decimal.length > 8) {
      decimal = decimal.substring(0, 8);
    }
    var integ = dots[0];
    if (integ.length > 10) {
      integ = integ.substring(0, 10);
    }

    this.amount = Number(integ + '.' + decimal);

    if (this.amountInputElDnc) this.amountInputElDnc.value = this.amount;
    if (this.amountInputElDc) this.amountInputElDc.value = this.amount;
    if (this.amountInputElMnc) this.amountInputElMnc.value = this.amount;
    if (this.amountInputElMc) this.amountInputElMc.value = this.amount;

    this.minamounterror = null;
  }

  async copyAddress(address) {
    // this.clipboard.copy(address);
    try {
      // await navigator.clipboard.writeText(address);
      copy(address);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
    var msg = await this.transService.getTranslationWord('COMMON.COPIED');
    this.comService.showToast(msg);
  }

  selectBank(bank, exist = false) {
    this.selectedBank = bank;
    this.beneAccount = null;

    if (exist) {
      this.readonlyBeneAccount = true;
      this.beneAccount = this.selectedBank.accountInfo;
    } else {
      this.readonlyBeneAccount = false;
    }

    setTimeout(() => {
      this.showBankList = false;
    }, 100);
  }

  inputAddress($event) {
    // this.cryptoAddress = $event.target.value;
  }


  async checkAcccountStatus(type?) {
    var title = this.transService.getTranslationWord('KYC.TITLE');
    var message = this.transService.getTranslationWord('KYC.DESC');
    var buttonTitle = this.transService.getTranslationWord('KYC.BUTTON_TXT');
    Navigator.openModal('RefWithDepoComponent', 'kyc_modal', {
      title: title,
      message: message,
      buttonTitle: buttonTitle,
      showHeader: true,
      action: () => {
        Navigator.back();
        this.activeAccount();
      }
    });


    // if (type == 'email') {
    //   var message = await this.transService.getTranslationWord('WITHDRAW.COMPLETE_EMAIL_VERIFY');
    //   var buttonTitle = await this.transService.getTranslationWord('WITHDRAW.VERIFY_EMAIL_BUTTON');

    //   Navigator.openModal('RefWithDepoComponent', 'football_modal', {
    //     title: title,
    //     message: message,
    //     buttonTitle: buttonTitle,
    //     showHeader: true,
    //     action: () => {
    //       Navigator.back();
    //       this.activeAccount();
    //     }
    //   });
    // }

    // if (type == 'phone') {
    //   var message = await this.transService.getTranslationWord('WITHDRAW.COMPLETE_PHONE_VERIFY');
    //   var buttonTitle = await this.transService.getTranslationWord('WITHDRAW.VERIFY_PHONE_BUTTON');

    //   Navigator.openModal('RefWithDepoComponent', 'football_modal', {
    //     title: title,
    //     message: message,
    //     buttonTitle: buttonTitle,
    //     showHeader: true,
    //     action: () => {
    //       Navigator.back();
    //       this.activeAccount();
    //     }
    //   });
    // }

    // if (type == 'both') {
    //   var message = await this.transService.getTranslationWord('WITHDRAW.COMPLETE_EMAIL_PHONE_VERIFY');
    //   var buttonTitle = await this.transService.getTranslationWord('WITHDRAW.VERIFY_EMAIL_PHONE_BUTTON');

    //   Navigator.openModal('RefWithDepoComponent', 'football_modal', {
    //     title: title,
    //     message: message,
    //     buttonTitle: buttonTitle,
    //     showHeader: true,
    //     action: () => {
    //       Navigator.back();
    //       this.activeAccount();
    //     }
    //   });
    // }
  }

  activeAccount() {
    // Navigator.navigateTo(
    //   "ActiveAccountPage",
    //   {
    //     multipleModals: true,
    //     disableBackdrop: false,
    //     cssClass: "add_email_modal"
    //   },
    //   {
    //     callback: () => {
    //       Navigator.back();
    //       this.userSession.refreshCustomer();
    //     }
    //   }
    // );
    Navigator.navigateTo("AccountInformationPage", { cssClass: "account_info" });
  }

  updateAccount() {
    Navigator.navigateTo(
      "ProfileInfoPage",
      {
        multipleModals: true,
        disableBackdrop: false
      },
      {
        callBackFunc: () => {
          Navigator.back();
        }
      }
    );
  }

  async onWithdrawNonCrypto() {

    if (!this.selectedPayment || !this.beneAccount || !this.amount || (this.comService.needBankForWithdraw(this.currency, this.selectedPayment?.code) && !this.selectedBank) || (this.currency == 'INR' && !this.ifscNumber)) {
      return;
    }

    if (Number(this.amount) <= 0) {
      this.minamounterror = await this.transService.getTranslationWord('WITHDRAW.INVALID_AMOUNT_ERROR.LESS_ZERO');
      return;
    }

    if (Number(this.amount) > (!this.comService.checkCryptoCurrency(this.selectedBalance.currency) ? this.selectedBalance.balance / 100 : Number(this.comService.formatCrypto(this.selectedBalance.balance / 100)))) {
      this.minamounterror = await this.transService.getTranslationWord('WITHDRAW.INVALID_AMOUNT_ERROR.GREATER_BALANCE');
      return;
    } else if (Number(this.amount) > (!this.comService.checkCryptoCurrency(this.selectedBalance.currency) ? (this.selectedBalance.balance / 100 - Number(this.cryptoFee)) : (Number(this.comService.formatCrypto(this.selectedBalance.balance / 100)) - Number(this.cryptoFee)))) {
      this.minamounterror = await this.transService.getTranslationWord('WITHDRAW.INVALID_AMOUNT_ERROR.GREATER_AVAILABLE');
      return;
    }

    if (Number(this.amount) < this.selectedPayment.min) {
      this.minamounterror = await this.transService.getTranslationWord('WITHDRAW.INVALID_AMOUNT_ERROR.LESS_MIN');
      return;
    }

    if (Number(this.amount) > this.selectedPayment.max) {
      this.minamounterror = await this.transService.getTranslationWord('WITHDRAW.INVALID_AMOUNT_ERROR.GREATER_MAX');
      return;
    }

    if (this.appConfig['WITHDRAW_KYC']['require_email_verify'] && !this.appConfig['WITHDRAW_KYC']['require_phone_verify']) {
      if (!this.customer.emailValidated) {
        this.checkAcccountStatus('email');
        return;
      }
    } else if (!this.appConfig['WITHDRAW_KYC']['require_email_verify'] && this.appConfig['WITHDRAW_KYC']['require_phone_verify']) {
      if (!this.customer.phoneValidated) {
        this.checkAcccountStatus('phone');
        return;
      }
    } else if (this.appConfig['WITHDRAW_KYC']['require_email_verify'] && this.appConfig['WITHDRAW_KYC']['require_phone_verify']) {
      if (!this.customer.emailValidated && this.customer.phoneValidated) {
        this.checkAcccountStatus('email');
        return;
      } else if (this.customer.emailValidated && !this.customer.phoneValidated) {
        this.checkAcccountStatus('phone');
        return;
      } else if (!this.customer.emailValidated && !this.customer.phoneValidated) {
        this.checkAcccountStatus('both');
        return;
      }
    }

    let data: any = {};
    data.payment_type_code = this.selectedPayment.code;
    data.currency_code = this.currency;
    data.amount = Number(this.amount);
    data.account_number = this.beneAccount;
    if (this.comService.needBankForWithdraw(this.currency, this.selectedPayment?.code)) data.bank_code = this.selectedBank.code;
    else if (this.currency == 'INR') data.ifsc = this.ifscNumber;

    if (this.selectedPayment.code == 'HKP' || this.selectedPayment?.code == 'CLUB') {
      data.account_type = this.accountType.code;
    }
    if (this.selectedPayment?.code == 'CLUB') {
      data.pay_type = this.clubPayType;
    }

    this.cashierProvider
      .callWithdraw(data)
      .pipe(debounceTime(300))
      .subscribe(
        async (response: any) => {
          console.log('callWithdraw response = ', response);
          if (response.code == 200 && response.result) {
            let depositEventData: AffiliateTrackerCallbackParams = {
              customer_id: this.cashierProvider.customer.customerId,
              amount: this.amount,
              transaction_id: response.payload.payoutID,
            };
            setTimeout(
              () => this.events.publishSomeData({event: 'event:withdraw', data: depositEventData}),
              2000
            );

            var title = await this.transService.getTranslationWord('COMMON.WITHDRAW');
            var message = await this.transService.getTranslationWord('WITHDRAW.WITHDRAW_SUCCESS');
            var buttonTitle = await this.transService.getTranslationWord('WITHDRAW.HOME_BUTTON');

            Navigator.openModal('RefWithDepoComponent', 'football_modal', {
              title,
              message,
              buttonTitle,
              showHeader: true,
              action: () => {
                Navigator.toLobby();
                this.events.publishSomeData({event: 'event:tabs:load', tab: 'dashboard'});
                this.events.publishSomeData({event: 'event:main:button'});
              }
            });

            this.balanceProvider.updateBalance();
          }
        },
        (error) => {
          console.log('callWithdraw error = ', error);
          this.comService.showToast(error.error_payload, 'error_toast');
        }
      );
  }


  async onWithdrawCrypto() {

    if (!this.selectedAddress || this.cryptoAddressForm.invalid || !this.amount || !this.allowWithdraw) {
      return;
    }

    if (Number(this.amount) < 0) {
      this.minamounterror = await this.transService.getTranslationWord('WITHDRAW.INVALID_AMOUNT_ERROR.LESS_ZERO');
      return;
    }

    if (Number(this.amount) < this.minAmount) {
      this.minamounterror = await this.transService.getTranslationWord('WITHDRAW.INVALID_AMOUNT_ERROR.LESS_MIN');
      return;
    }

    if (Number(this.amount) > (!this.comService.checkCryptoCurrency(this.selectedBalance.currency) ? this.selectedBalance.balance / 100 : Number(this.comService.formatCrypto(this.selectedBalance.balance / 100)))) {
      this.minamounterror = await this.transService.getTranslationWord('WITHDRAW.INVALID_AMOUNT_ERROR.GREATER_BALANCE');
      return;
    }

    if (this.appConfig['WITHDRAW_KYC']['require_email_verify'] && !this.appConfig['WITHDRAW_KYC']['require_phone_verify']) {
      if (!this.customer.emailValidated) {
        this.checkAcccountStatus('email');
        return;
      }
    } else if (!this.appConfig['WITHDRAW_KYC']['require_email_verify'] && this.appConfig['WITHDRAW_KYC']['require_phone_verify']) {
      if (!this.customer.phoneValidated) {
        this.checkAcccountStatus('phone');
        return;
      }
    } else if (this.appConfig['WITHDRAW_KYC']['require_email_verify'] && this.appConfig['WITHDRAW_KYC']['require_phone_verify']) {
      if (!this.customer.emailValidated && this.customer.phoneValidated) {
        this.checkAcccountStatus('email');
        return;
      } else if (this.customer.emailValidated && !this.customer.phoneValidated) {
        this.checkAcccountStatus('phone');
        return;
      } else if (!this.customer.emailValidated && !this.customer.phoneValidated) {
        this.checkAcccountStatus('both');
        return;
      }
    }

    // if (!this.customer.firstName || !this.customer.lastName || !this.customer.birthDate) {
    //   this.checkAcccountStatus();
    //   return;
    // }

    let data: any = {};
    data.payment_type_code = this.selectedAddress.payment_type_code;
    data.currency_code = this.currency;
    data.amount = Number(this.amount);
    data.account_number = this.cryptoAddress.value;
    data.chain_id = this.selectedAddress.chain_id;
    data.token_id = this.selectedAddress.token_id;
    data.token_name = this.currency;
    data.chain_name = this.selectedAddress.chain_name;

    this.cashierProvider
      .callWithdraw(data)
      .pipe(debounceTime(300))
      .subscribe(
        async (response: any) => {
          console.log('callWithdraw response = ', response);
          if (response.code == 200 && response.result) {
            let depositEventData: AffiliateTrackerCallbackParams = {
              customer_id: this.cashierProvider.customer.customerId,
              amount: this.amount,
              transaction_id: response.payload.payoutID,
            };
            setTimeout(
              () => this.events.publishSomeData({event: 'event:withdraw', data: depositEventData}),
              2000
            );

            var title = await this.transService.getTranslationWord('COMMON.WITHDRAW');
            var message = await this.transService.getTranslationWord('WITHDRAW.WITHDRAW_SUCCESS');
            var buttonTitle = await this.transService.getTranslationWord('WITHDRAW.HOME_BUTTON');

            Navigator.openModal('RefWithDepoComponent', 'football_modal', {
              title,
              message,
              buttonTitle,
              showHeader: true,
              action: () => {
                Navigator.toLobby();
                this.events.publishSomeData({event: 'event:tabs:load', tab: 'dashboard'});
                this.events.publishSomeData({event: 'event:main:button'});
              }
            });

            this.balanceProvider.updateBalance();
          }
        },
        (error) => {
          console.log('callWithdraw error = ', error);
          this.comService.showToast(error.error_payload, 'error_toast');
        }
      );
  }

  contactSupport() {
    let configuredURL = this.config.getConfig()['FEATURE_CUSTOMER_SUPPORT_URL'] || "";
    if (!configuredURL) return;
    this.appLinks.openExternalPage(configuredURL);
  }

  getWithdrawFee(amount, maxAmount = false) {
    // if (this.comService.checkNormalCurrency(this.currency)) return;
    if (!amount || Number(amount) == 0) {
      this.cryptoFee = 0;
      return;
    }

    var sendData: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      payment_method_code: this.comService.checkNormalCurrency(this.currency) ? this.selectedPayment.code : this.selectedAddress.payment_type_code,
      amount: Number(amount),
    };

    if (!this.comService.checkNormalCurrency(this.currency)) {
      sendData['token_name'] = this.currency;
      sendData['chain_name'] = this.selectedAddress.chain_name;
    }

    this.webService.get('cashier/calculate/payout-fee', sendData).subscribe(response => {
      console.log('fee == ', response);
      if (response.code == 200 && response.result) {
        this.cryptoFee = response.payload.payout_fee;
        if (maxAmount) this.feeSub.next('' + this.cryptoFee);
      }
    }, error => {
      console.error('fee error  == ', error);
    });
  }

  restrictedView() {
    Navigator.navigateTo("CasinoBonusActivePage", {forceModal: true});
  }

}
