import { SystemModule } from './../../../../../system/system.module';
import { NgModule } from '@angular/core';
import { ChangePasswordPage } from './change-password';
import { SharedModule } from '../../../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [
    ChangePasswordPage,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SystemModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
})
export class ChangePasswordPageModule {}
