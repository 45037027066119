import { SetDepositLimitsPageModule } from './pages/responsible-gaming/set-deposit-limits/set-deposit-limits.module';
import { SetTimeLimitsPageModule } from './pages/responsible-gaming/set-time-limits/set-time-limits.module';
import { SetWagerLimitsPageModule } from './pages/responsible-gaming/set-wager-limits/set-wager-limits.module';
import { LimitsHistoryPageModule } from './pages/responsible-gaming/limits-history/limits-history.module';
import { UpdatePhoneNumberPageModule } from './pages/information-security/update-phone-number/update-phone-number.module';
import { AccountInformationPageModule } from './pages/information-security/account-information/account-information.module';
import { PersonalInformationPageModule } from './pages/information-security/personal-information/personal-information.module';
import { ChangePasswordPageModule } from './pages/information-security/change-password/change-password.module';
import { ResetPasswordPageModule } from './pages/information-security/reset-password/reset-password.module';
import { NgModule } from '@angular/core';
import { SystemModule } from '../../system/system.module';
import { SetLossLimitsPageModule } from './pages/responsible-gaming/set-loss-limits/set-loss-limits.module';
import { EditNamePageModule } from './pages/information-security/edit-name/edit-name.module';
import { AccountComponentsModule } from './components/components.module';

@NgModule({
  declarations: [],
  imports: [
    AccountInformationPageModule,
    ChangePasswordPageModule,
    ResetPasswordPageModule,
    PersonalInformationPageModule,
    UpdatePhoneNumberPageModule,
    EditNamePageModule,
    SetDepositLimitsPageModule,
    SetTimeLimitsPageModule,
    SetWagerLimitsPageModule,
    LimitsHistoryPageModule,
    SetLossLimitsPageModule,
    SystemModule,
    AccountComponentsModule
  ],
  entryComponents: [],
  providers: [],
  exports: [],
})
export class AccountModule {}
