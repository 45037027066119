import { Injectable } from '@angular/core';
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
import {
  AffiliateTrackerInterface,
  AffiliateTrackerCallbackParams,
} from './affiliation-event';

@Injectable({
  providedIn: 'root',
})
export class FacebookAppevents implements AffiliateTrackerInterface {
  constructor(protected fb: Facebook) {}

  public initialize(): Promise<AffiliateTrackerInterface> {
    return new Promise((resolve, reject) => {
      if ('cordova' in window) {
        // this.fb.login( [])
        // .then( (res: FacebookLoginResponse) => {
        //     console.log( 'facebook-appevents.ts: logged into Facebook!', res);
        //     this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_ACTIVATED_APP);
        //     resolve( this);
        // }).catch( e => {
        //     console.log( 'facebook-appevents.ts: error logging into facebook: ', e);
        //     reject( e);
        // });
        resolve(this);
      } else {
        resolve(null); // don't initialize this tracker
      }
    });
  }

  public login(cbParams: AffiliateTrackerCallbackParams) {
    this.fb.logEvent('login', cbParams);
  }

  public signupComplete(cbParams: AffiliateTrackerCallbackParams) {
    this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_COMPLETED_REGISTRATION);
  }

  public deposit(cbParams: AffiliateTrackerCallbackParams) {}

  public withdraw(cbParams: AffiliateTrackerCallbackParams) {}

  public gameLaunched(cbParams: AffiliateTrackerCallbackParams) {
    this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_VIEWED_CONTENT);
  }
}
