import { BannerProvider } from './../../lobby/providers/banner.provider';
import { Subject } from 'rxjs';
import { Observable, ReplaySubject } from 'rxjs';
import { CustomerModel } from './../../account/models/customer-model';
import { UserSessionProvider } from './../../../system/providers/user-session';
import { WebservicesProvider } from './../../../system/providers/webservices';
import { Config } from '../../../system/providers/configuration';
import { Injectable } from '@angular/core';
import { isUndefined } from '../../../shared/codeUtils';
import { MyEvent } from 'src/app/service/myevent.services';

@Injectable({
  providedIn: 'root'
})
export class CashierProvider {

  public customer: CustomerModel;
  payoutMethodSubject: Subject<Object[]>;
  balances: any;

  constructor(
    private events: MyEvent,
    private userSession: UserSessionProvider,
    private webservice: WebservicesProvider,
    private bannerProvider: BannerProvider,
    private config: Config
  ) {

    this.payoutMethodSubject = new ReplaySubject(1);

    this.userSession.getCustomer().subscribe((customer) => {
      this.customer = customer;
      if (this.customer) {
        this.callGetPayoutMethods();
      }
    });

    this.events.getObservable().subscribe((data) => {
      if (data.event == 'event:deposit')
        setTimeout(() => {
          this.bannerProvider.fetchAllBanners();
        }, 2000);
    });
    this.events.getObservable().subscribe((data) => {
      if (data.event == 'event:login') this.clearCashierCache();
    });
  }

  callListAccounts() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };
    return this.webservice.post('cashier/deposit/account/list', obj);
  }
  onGetExternalAccountsVIP() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };
    return this.webservice.post('cashier/external-services/account/list', obj);
  }

  callAddAccount(data: any) {
    console.log(data);
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      payment_method_code: data.paymentType,
      bank_code: data.bankCode,
      account_number: data.accountNumber,
      // ssn: data.ssn,
      // accountType: data.accountType,
      // bankName: data.bankName,
    };
    console.log('send data: ', obj);

    // obj.routingNumber = data.routingNumber;
    // obj.accountNumber = data.accountNumber;
    // obj.idType = data.idType;
    // obj.idNumber = data.idNumber;
    // obj.idState = data.idState;

    // if (data.payment_type_code != null) {
    //   if (data.payment_type_code == 'GP' || data.payment_type_code == 'PS' || data.payment_type_code == 'BST' || data.payment_type_code == 'FF') {
    //     //VIP ACH
    //     obj.routingNumber = data.routingNumber;
    //     obj.accountNumber = data.accountNumber;
    //     obj.idType = data.idType;
    //     obj.idNumber = data.idNumber;
    //     obj.idState = data.idState;
    //   } else {
    //     //Credit Card
    //     //obj.firstName = data.firstName;
    //     //obj.lastName = data.lastName;
    //     obj.name = data.name;
    //     obj.cardNumber = data.cardNumber;
    //     obj.cardExpMonth = data.cardExpMonth;
    //     obj.cardExpYear = data.cardExpYear;
    //     obj.address1 = data.streetGroup.street;
    //     obj.address2 = data.streetGroup.street2;
    //     obj.city = data.city;
    //     obj.state = data.province;
    //     obj.country = data.countryInfoGroup.country;
    //     obj.postalCode = data.countryInfoGroup.postalCode;
    //   }
    // }
    return this.webservice.post('cashier/deposit/account/add', obj);
  }

  checkDepositLimits(data) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      deposit_amount: data.amount,
      currency_code: data.currency_code,
      payment_method_code: data.payment_type_code,
    };

    return this.webservice.post(
      'accountable-gaming/player-limits/deposit/verify',
      obj
    );
  }

  callDeposit(data: any) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      amount: data.amount,
      currency_code: data.currency_code,
      payment_method_code: data.payment_type_code,
    };

    if (data.bonusCode != '' && data.bonusCode != null) {
      obj.bonusCode = data.bonusCode;
    }

    if (data.name != '' && data.name != null) {
      obj.name = data.name;
    }

    if (data.accountNumber != '' && data.accountNumber != null) {
      obj.accountNumber = data.accountNumber;
    }

    //paypal - special isIAB params needs to pass if user does deposit from Sports Tab on IOS App.
    if (data.isIAB != '' && data.isIAB != null) {
      obj.isIAB = data.isIAB;
    }

    //netell
    if (data.netAccountNumber != '' && data.netAccountNumber != null) {
      obj.net_account_number = data.netAccountNumber;
    }
    if (data.netSecureId != '' && data.netSecureId != null) {
      obj.net_secure_id = data.netSecureId;
    }

    if (data.payment_type_code != null) {
      if (data.payment_type_code == 'ACH') {
        //ACH (E-Check) Deposit
        obj.customerAccountID = data.customerAccountID;
      } else {
        //Credit Card Deposit
        obj.customerAccountID = data.customerAccountID;
        obj.cardExpMonth = data.cardExpMonth;
        obj.cardExpYear = data.cardExpYear;
        obj.cardCvd = data.cardCvd;
      }
    }

    //GP Deposit-VIP
    if (data.key != '' && data.key != null) {
      obj.account_key = data.key;
    }

    if (data.routingNumber != '' && data.routingNumber != null) {
      obj.routingNumber = data.routingNumber;
    }
    //end GP Deposit-VIP

    console.log('cashier/deposit/create', obj);

    this.clearCashierCache();

    return this.webservice.post('cashier/deposit/create', obj);
  }

  callGetAccountHistory() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.post('cashier/get-account-history', obj);
  }

  callDepositLimitConfirm(customerId) {
    customerId = !customerId ? this.customer.customerId : customerId;
    let obj: any = {
      player_id: customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.post('cashier/deposit/confirm-over-limit', obj);
  }

  callHasSightlineAccount() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.get(
      'cashier/external-services/sightline/account/exist',
      obj
    );
  }

  callGetAccountSummary(data: any) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      currency_code: data.currency_code,
      start_date: data.dateFrom,
      end_date: data.dateTo,
      page_number: data.pageno,
    };

    return this.webservice.post('cashier/past-account-summary/get', obj);
  }

  callGetBalance() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };
    return this.webservice.get('player/balance', obj);
  }

  callPaypalConfirm(obj) {
    return this.webservice.post(
      'cashier/external-services/paypal/confirm',
      obj
    );
  }

  callGetPendingWithdrawals() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.post('cashier/payout/list/pending', obj);
  }

  callUpdatePayoutLock(paymentRequestId) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      payout_request_id: paymentRequestId,
    };

    return this.webservice.post('cashier/payout/lock', obj);
  }

  callGetRejectedTransactions() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.post('cashier/payout/list/rejected', obj);
  }

  callGetTransactions(data: any, type?) {
    if (type == 'Deposit' || type == 'Withdrawal') {
      let obj: any = {
        player_id: this.customer.customerId,
        session_id: this.userSession.sessionToken,
        // currency_code: data.currency_code || '',
        transaction_name: data.transaction_name,
        page_number: data.pageno,
        date_from: data.date_from,
        date_to: data.date_to,
      };

      return this.webservice.post('cashier/get-payment-transaction-history', obj);
    }
    else {
      let obj: any = {
        player_id: this.customer.customerId,
        session_id: this.userSession.sessionToken,
        // currency_code: data.currency_code || '',
        transaction_name: data.transaction_name,
        page_number: data.pageno,
        start_date: data.date_from,
        end_date: data.date_to,
      };

      return this.webservice.post('cashier/transaction-history/list', obj);
    }
  }

  sendTransactionsEmail(data: any) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      currency_code: data.currency_code,
      start_date: data.date_from,
      end_date: data.date_to,
    };

    return this.webservice.post('cashier/transaction-history/send-mail', obj);
  }

  callReverseWithdrawal(data: any) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      payout_request_id: data.payoutRequestID,
    };

    return this.webservice.post('cashier/payout/cancel', obj);
  }

  callRemoveAccount(customerAccountID: string) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      account_id: customerAccountID,
    };

    return this.webservice.post('cashier/deposit/account/remove', obj);
  }

  callWithdraw(data: any) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      amount: data.amount,
      currency_code: data.currency_code,
      payment_method_code: data.payment_type_code,
      account_number: data.account_number,
      chain_id: data.chain_id,
      token_id: data.token_id,
      token_name: data.token_name,
      chain_name: data.chain_name,
      bank_code: data.bank_code,
      ifsc: data.ifsc,
      account_type: data.account_type
    };
    if (!isUndefined(data.isCagePayout)) {
      obj.isCagePayout = data.isCagePayout;
    }
    if (data.pay_type) {
      obj.pay_type = data.pay_type;
    }
    return this.webservice.post('cashier/payout/create', obj);
  }

  /*
        POST /cashier/get-total-deposits
    */
  callGetTotalDeposits() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.post('cashier/deposit/total/get', obj);
  }
  /*
        POST /cashier/send-total-deposits
    */
  callSendTotalDeposits() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.post('cashier/deposit/total/send-mail', obj);
  }

  /*
        POST /cashier/get-total-withdrawals
    */
  callGetTotalWithdrawals() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.post('cashier/payout/total/get', obj);
  }

  /*
        POST /cashier/send-total-withdrawals
    */
  callSendTotalWithdrawals() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };

    return this.webservice.post('cashier/payout/total/send-mail', obj);
  }

  public lastDeposit: any = null;
  /*
        POST /cashier/get-last-deposit
    */
  getLastDeposit() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };
    let httpSubject = new Subject<any>();
    if (!this.lastDeposit) {
      this.webservice
        .post('cashier/deposit/last-payment-method/get', obj)
        .subscribe(
          (response: any) => {
            this.lastDeposit =
              typeof response.payload != 'undefined' ? response.payload : [];
            httpSubject.next(this.lastDeposit);
          },
          (error) => {
            httpSubject.error(error);
          }
        );
      // return httpSubject;
    } else {
      setTimeout(() => {
        httpSubject.next(this.lastDeposit);
      }, 560);
    }
    return httpSubject;
  }

  checkDeposit(payment_request_id) {
    let obj: any = {
      payment_request_id: payment_request_id,
    };
    return this.webservice.post('cashier/deposit/check', obj);
  }

  /**
   * checkDepositStatus was added Sep 2019, to have a method that does not change the transaction, instead
   * just reads the deposit record.
   */
  checkDepositStatus(payment_request_id) {
    let obj: any = {
      payment_request_id: payment_request_id,
    };
    return this.webservice.post('cashier/deposit/status/check', obj);
  }

  clearCashierCache() {
    this.lastDeposit = null;
  }

  public paymentLimits: any = null;
  /*
        POST /cashier/get-payment-limits
    */
  getPaymentLimits() {
    let httpSubject = new Subject<any>();
    if (!this.paymentLimits) {
      this.webservice.get('cashier/player-limits/payment-method').subscribe(
        (response: any) => {
          this.paymentLimits =
            typeof response.payload != 'undefined' ? response.payload : [];
          httpSubject.next(this.paymentLimits);
        },
        (error) => {
          httpSubject.error(error);
        }
      );
    } else {
      setTimeout(() => {
        httpSubject.next(this.paymentLimits);
      }, 780);
    }
    return httpSubject;
  }

  defaultCampaign(campaignId = null) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };
    if (campaignId !== null) {
      obj.campaign_id = campaignId;
    }
    return this.webservice.get('customer/get-deposit-campaign', obj);
  }

  getPaypalToken(amount, currency = 'USD', confirmLink = '', cancelLink = '') {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };
    obj.amount = amount;
    obj.currency_code = currency;
    obj.confirm_url = confirmLink;
    obj.cancel_url = cancelLink;

    return this.webservice.get('cashier/external-services/paypal/token', obj);
  }
  getPaynearmeUrl(bonusCode, bonus_type = 'CASINO', theme = 'web') {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };
    if (bonusCode != '' && bonusCode != null) {
      obj.bonus_code_auxiliary = bonusCode;
    }
    obj.bonus_type = bonus_type;
    obj.theme = theme;
    return this.webservice.get('cashier/external-services/paynearme/url', obj);
  }

  /*
        GET cashier/get-tax-reports
    */
  public callGetTaxReport(
    date_from: string,
    date_to: string,
    page: number = 1,
    numRecords: number = 25
  ) {
    console.log('customer id', this.customer.customerId);
    console.log('customer session token', this.userSession.sessionToken);
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      start_date: date_from,
      end_date: date_to,
      page_number: page,
      invalidate_cache: 1,
      //num: numRecords
    };

    return this.webservice.get('cashier/tax-report/list', obj);
  }

  /*
        POST cashier/tax-report-confirm
    */
  public callPostTaxConfirm(report) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      tax_report_id: report.id,
    };
    return this.webservice.post('cashier/tax-report/confirm', obj);
  }

  public getTaxReportUrl(report) {
    let url = '';
    let webServiceUrl = this.config.getEnv().webservices_url;
    let params = new URLSearchParams();
    params.set('player_id', this.customer.customerId.toString());
    params.set('session_id', this.userSession.sessionToken);
    params.set('tax_report_id', report.id);
    url = `${webServiceUrl}/cashier/tax-report/export?` + params.toString();
    return url;
  }

  /*
   *  POST /cashier/send-tax-report
   */
  public callPostSendTaxReport(report) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
      tax_report_id: report.id,
      client_app_id: this.config.getClientApp(),
    };
    return this.webservice.post('cashier/tax-report/send-mail', obj);
  }

  /*
   *  GET /cashier/get-global-payment-limits
   */
  callGetGlobalPaymentLimits() {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    };
    return this.webservice.get(
      'cashier/player-limits/global-payment-method',
      obj
    );
  }

  /*
   * Get cashier/get-payout-methods
   */
  public callGetPayoutMethods() {
    console.log('callgetPayoutMethod called');
    this.webservice.get('cashier/payout-method/list').subscribe((resp: any) => {
      if (!!resp.result && resp.payload) {
        this.setPayoutMethods(resp.payload);
      }
    });
  }
  setPayoutMethods(payload: any) {
    this.payoutMethodSubject.next(payload);
  }
  public getPayoutMethods() {
    return this.payoutMethodSubject;
  }
}
