import { Injectable } from '@angular/core';

import { AffiliateTrackerInterface } from './affiliation-event';

@Injectable({
  providedIn: 'root',
})
export class CaptureMediatag implements AffiliateTrackerInterface {

  attributionData: any;

  constructor() {}

  initialize(): Promise<AffiliateTrackerInterface> {
    return Promise.resolve(this);
  }

  captureParameters(): Array<string> {
    return ['mediatag'];
  }

  setAttributionData(attr_data: any): void {
    console.log('capture-mediatag.ts: setAttributionData: ', attr_data);
    this.attributionData = attr_data;
  }
}
