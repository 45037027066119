import { Injectable } from '@angular/core';
import { ToastController, LoadingController, AlertController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { format } from 'date-fns';
import { formatDate } from '@angular/common';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Config } from '../system/providers/configuration';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  currentDataEntryModeSubject: BehaviorSubject<any> = new BehaviorSubject<any>('NGN');

  public get currentDataEntryMode(): any { return this.currentDataEntryModeSubject.value; }

  public isMobileWeb: boolean = false;
  public isIOSApp: boolean = false;
  isMobileApp: boolean = false;

  gameKeywordsSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  get getGameKeywords(): any[] { return this.gameKeywordsSubject.value; }

  allGamesSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  gamesDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  sharedBonuseDetail: any;

  defaulCurrencySymbol = '$';
  defaulCurrency: any;

  currencySymbols: any = {
    USD: '$',
    NGN: '₦',
    INR: '₹',
    BRL: 'R$',
    AUD: 'A$'
  };

  allProvidersSub: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  countries: any[] = [];

  footerContent: any = '';

  defaultLang = 'en-US';

  walletPaymentSub: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  currentTemplate = 'bps';

  constructor(
    private toastCtrl: ToastController,
    private loadCtrl: LoadingController,
    private alertCtrl: AlertController,
    private platform: Platform,
    private statusbar: StatusBar,
    private iab: InAppBrowser,
    private config: Config,
    private titleS: Title,
    private metaS: Meta
  ) { }

  setDefaultCurrency(value) {
    this.defaulCurrency = value;
    this.defaulCurrencySymbol = this.currencySymbols[value] || value;
    this.currentDataEntryModeSubject.next(this.defaulCurrency);
  }

  showToast(msg, class_name = 'custom_toast') {
    this.toastCtrl.create({
      message: msg,
      cssClass: class_name,
      duration: 3000,
      position: 'top'
    }).then(res => res.present());
  }

  showAlert(msg, title = null) {
    this.alertCtrl.create({
      header: title,
      message: msg,
      buttons: ['OK']
    }).then(res => res.present());
  }

  async createAlertInterface(message, header) {
    var alert = await this.alertCtrl.create({
      header,
      message,
      buttons: ['OK']
    });
    return alert;
  }

  async showLoader(msg) {
    const check = await this.loadCtrl.getTop();
    if (check) {
      check.dismiss();
    }
    this.loadCtrl.create({
      spinner: 'circular',
      message: msg,
      duration: 5000,
      mode: 'ios'
    }).then(res => {
      res.present();
    });
  }

  async hideLoader() {
    this.loadCtrl.getTop().then(loading => {
      if (loading) {
        loading.dismiss();
      }
    }).catch(err => {
      console.error('loading hide error: ', err);
    });
  }

  checkForMobile() {
    console.log('statusbar visible == ', this.statusbar.isVisible);
    if (this.platform.is('mobile') || this.platform.is('mobileweb')) {
      this.isMobileWeb = true;
    } else {
      this.isMobileWeb = false;
    }
    if (this.platform.is("ios")) { // (this.platform.is("cordova") || this.platform.is("hybrid")) &&
      this.isIOSApp = true;
    } else {
      this.isIOSApp = false;
    }
    if (this.statusbar.isVisible) {
      this.isMobileApp = true;
    } else {
      this.isMobileApp = false;
    }
  }

  checkTabRoute(tab) {
    var validRoutes = [
      '/','dashboard','livegames','sports','bingoplus','terms','faq','providers','bonuses','football','transactions','game-history','launch-game/back','leaderboard','referrals','vip-club','bonus-detail','games','earn-interest'
    ];
    if (tab.includes('bonus-detail')) return true;
    return validRoutes.includes(tab);
  }

  openLink(url: string) {
    if (this.isMobileApp) this.iab.create(url, '_system');
    else window.open(url, '_blank');
  }


  /**
   *
   * @param number
   * @param maxDecimals
   * @param forceDecimals
   * @param siStyle
   * @returns
   */

  numberFormat(number: any, maxDecimals: number = 2, forceDecimals: boolean = false, siStyle: boolean = false): string {
    if (!number) {
      return '00.00';
    }

    let str: string = '';
    var i: number = 0;
    var inc: number = Math.pow(10, maxDecimals);
    if (maxDecimals > 0) {
      str = (Math.round(inc * Number(number)) / inc).toFixed(2);
    } else {
      str = (Math.trunc(inc * Number(number)) / inc).toFixed(0);
    }

    var hasSep: boolean = str.indexOf(".") == -1,
        sep: number = hasSep ? str.length : str.indexOf(".");
    var ret: String = (hasSep && !forceDecimals ? "" : (siStyle ? "," : ".")) + str.substr(sep + 1);

    ret = (hasSep && !forceDecimals) ? ret + '.00' : ret;

    if (forceDecimals) {
      for (var j: number = 0; j <= maxDecimals - (str.length - (hasSep ? sep - 1 : sep)); j++)
        ret += "0";
    }

    while (i + 3 < (str.substr(0, 1) == "-" ? sep - 1 : sep))
      ret = (siStyle ? "." : ",") + str.substr(sep - (i += 3), 3) + ret;
    return str.substr(0, sep - i) + ret;
  }

  numberFormatForDecimal(number: any, maxDecimals: number = 2, forceDecimals: boolean = false, siStyle: boolean = false): string {
    if (!number) {
      return '0';
    }

    let str: string = '';
    var i: number = 0;
    var inc: number = Math.pow(10, maxDecimals);
    if (maxDecimals > 0) {
      str = (Math.round(inc * Number(number)) / inc).toFixed(maxDecimals);
    } else {
      str = (Math.trunc(inc * Number(number)) / inc).toFixed(0);
    }

    var hasSep: boolean = str.indexOf(".") == -1, sep: number = hasSep ? str.length : str.indexOf(".");
    var ret: String = (hasSep && !forceDecimals ? "" : (siStyle ? "," : ".")) + str.substr(sep + 1);
    if (forceDecimals) {
      for (var j: number = 0; j <= maxDecimals - (str.length - (hasSep ? sep - 1 : sep)); j++)
        ret += "0";
    }
    while (i + 3 < (str.substr(0, 1) == "-" ? sep - 1 : sep))
      ret = (siStyle ? "." : ",") + str.substr(sep - (i += 3), 3) + ret;
    return str.substr(0, sep - i) + ret;
  }

  numberFormatForCurrency(number: any): string {
    if (!number) {
      return '0';
    }

    let str: string = '';
    var i: number = 0;
    var inc: number = Math.pow(10, 2);
    str = '' + (Math.round(inc * Number(number)) / inc);

    var hasSep: boolean = str.indexOf(".") == -1,
        sep: number = hasSep ? str.length : str.indexOf(".");
    var ret: String = (hasSep ? "" : ".") + str.substr(sep + 1);

    while (i + 3 < (str.substr(0, 1) == "-" ? sep - 1 : sep))
      ret = "," + str.substr(sep - (i += 3), 3) + ret;

    var returnVal = str.substr(0, sep - i) + ret;
    if (returnVal.includes('.')) {
      var deci = returnVal.split('.')[1];
      if (deci.length < 2) {
        returnVal += '0';
      }
    }

    return returnVal;
  }



  formatCrypto(number: any) {
    if (!number) return '0.00000000';

    var temp = Number(number) / Math.pow(10, 6);
    return temp.toFixed(8);
  }

  checkNormalCurrency(currency): boolean {
    var currencies = this.config.getAppConfigInfo()['SUPPORTED_NONCRYPTO_CURRECIES'] || [
      'NGN', 'USD', 'INR', 'BRL'
    ];
    return currencies.includes(currency);
  }

  needBankForWithdraw(currency, payment_code?): boolean {
    var currencies = [
      'NGN', 'USD', 'BRL', 'IDR'
    ];
    if (currency == 'BRL') {
      if (payment_code == 'IP') return true;
    }
    else if (currency == 'IDR' && payment_code == 'CLUB') return true;
    else return currencies.includes(currency);
  }

  checkCryptoCurrency(currency): boolean {
    var cryptos = [
      'BNB', 'ETH', 'BTC', 'LTC'
    ];

    return cryptos.includes(currency);
  }

  getChainIcon(name: string, token_name: string) {
    if (name.toLowerCase().includes('erc20') || name.toLowerCase().includes('ethereum')) {
      return 'eth.svg';
    } else if (name.toLowerCase().includes('trc20') || name.toLowerCase().includes('tron')) {
      return 'trx.svg';
    } else if (name.toLowerCase().includes('polygon')) {
      return 'matic.svg';
    } else if (name.toLowerCase().includes('bep20')) {
      return 'BNB.png';
    } else if (name.toLowerCase().includes('tt')) {
      return 'TT.png';
    } else if (name.toLowerCase().includes('bitcoin')) {
      return 'BTC.png';
    } else if (name.toLowerCase().includes('ltc')) {
      return 'LTC.png';
    } else if (name.toLowerCase().includes('matic')) {
      return 'matic.svg';
    } else if (name.toLowerCase().includes('doge')) {
      return 'DOGE.png';
    } else if (name.toLowerCase().includes('lrc20')) {
      return 'LRC20.png';
    } else if (name.toLowerCase().includes('drc20')) {
      return 'DRC20.png';
    } else if (name.toLowerCase().includes('native')) {
      return token_name + '.png';
    }
  }



  /// date time format part
  ///
  ///
  getTimeMinOfDate(date: string, time = '') {
    var yy, mm, dd, hh, ii, ss;
    if (date.includes(' ') && date.includes(':')) {
      var temp = date.split(' ');
      yy = Number(temp[0].split('/')[2]);
      mm = Number(temp[0].split('/')[1]) - 1;
      dd = Number(temp[0].split('/')[0]);

      hh = Number(temp[1].split(':')[0]);
      ii = Number(temp[1].split(':')[1]);
      ss = Number(temp[1].split(':')[2]);
    } else if (!date.includes(' ') && time) {
      yy = Number(date.split('/')[2]);
      mm = Number(date.split('/')[1]) - 1;
      dd = Number(date.split('/')[0]);

      hh = Number(time.split(':')[0]);
      ii = Number(time.split(':')[1]);
      ss = Number(time.split(':')[2]);
    }

    // let time_diff = new Date().getTimezoneOffset();
    var iosDate = new Date(yy, mm, dd, hh, ii, ss);//.toISOString();
    // var localTime = this.getTimeStampFromFormatTime(iosDate) - time_diff * 60;

    // return moment(iosDate).format('MMM D, hh:mm:ss A');
    return moment(iosDate).format('YYYY-MM-DD hh:mm:ss A');
  }


  getTimeMinOnlyOfDate(date: string, time = '') {
    var yy, mm, dd, hh, ii, ss;
    if (date.includes(' ') && date.includes(':')) {
      var temp = date.split(' ');
      yy = Number(temp[0].split('/')[2]);
      mm = Number(temp[0].split('/')[1]) - 1;
      dd = Number(temp[0].split('/')[0]);

      hh = Number(temp[1].split(':')[0]);
      ii = Number(temp[1].split(':')[1]);
      ss = Number(temp[1].split(':')[2]);
    } else if (!date.includes(' ') && time) {
      yy = Number(date.split('/')[2]);
      mm = Number(date.split('/')[1]) - 1;
      dd = Number(date.split('/')[0]);

      hh = Number(time.split(':')[0]);
      ii = Number(time.split(':')[1]);
      ss = Number(time.split(':')[2]);
    }

    // let time_diff = new Date().getTimezoneOffset();
    var iosDate = new Date(yy, mm, dd, hh, ii, ss);//.toISOString();
    // var localTime = this.getTimeStampFromFormatTime(iosDate) - time_diff * 60;

    return moment(iosDate).format('hh:mm A');
  }


  convertTimeToLocal(date) {
    let time_diff = new Date().getTimezoneOffset();
    let c_timestamp = this.getTimeStampFromFormatTime(date);// - time_diff * 60;
    const newDate = moment(c_timestamp * 1000).format('YYYY-MM-DD hh:mm:ss A');
    return newDate;
  }

  convertTimeToLocalByFormat(date, format = 'YYYY-MM-DD hh:mm:ss A') {
    let time_diff = new Date().getTimezoneOffset();
    let c_timestamp = this.getTimeStampFromFormatTime(date);// - time_diff * 60;
    const newDate = moment(c_timestamp * 1000).format(format);
    return newDate;
  }

  getTimeStampFromFormatTime(datetime) {
    var datum = new Date(datetime).getTime(); // Date.parse(datetime);
    return datum / 1000;
  }

  timeFormat1(date: string, time = '') {
    var yy, mm, dd, hh, ii, ss;
    if (date.includes(' ') && date.includes(':')) {
      var temp = date.split(' ');
      yy = Number(temp[0].split('/')[2]);
      mm = Number(temp[0].split('/')[1]) - 1;
      dd = Number(temp[0].split('/')[0]);

      hh = Number(temp[1].split(':')[0]);
      ii = Number(temp[1].split(':')[1]);
      ss = Number(temp[1].split(':')[2]);
    } else if (!date.includes(' ') && time) {
      yy = Number(date.split('/')[2]);
      mm = Number(date.split('/')[1]) - 1;
      dd = Number(date.split('/')[0]);

      hh = Number(time.split(':')[0]);
      ii = Number(time.split(':')[1]);
      ss = Number(time.split(':')[2]);
    }

    // let time_diff = new Date().getTimezoneOffset();
    var iosDate = new Date(yy, mm, dd, hh, ii, ss);//.toISOString();
    // var localTime = this.getTimeStampFromFormatTime(iosDate) - time_diff * 60;

    return moment(iosDate).format('YYYY-MM-DD hh:mm A');
  }

  timeFormat2(date: string) {
    try {
      var datum = new Date(date);
      return moment(datum).format('YYYY-MM-DD hh:mm A');
    } catch (error) {
      return date;
    }
  }

  timeFormat3(date: string, format = 'YYYY-MM-DD hh:mm:ss A') {
    try {
      var datum = new Date(date);
      return moment(datum).format(format);
    } catch (error) {
      return date;
    }
  }

  /**
   *
   */
  updateMetaTags(title, mTitle, oTitle, description, oDescription, oUrl, keywords) {
    this.titleS.setTitle(title);
    this.metaS.updateTag({ name: 'title', content: mTitle });
    this.metaS.updateTag({ name: 'description', content: description });
    this.metaS.updateTag({ name: 'keywords', content: keywords });
    this.metaS.updateTag({ property: 'og:title', content: oTitle });
    this.metaS.updateTag({ property: 'og:description', content: oDescription });
    this.metaS.updateTag({ property: 'og:url', content: oUrl });
  }

}
