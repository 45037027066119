import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Config } from 'src/app/system/providers/configuration';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';
import { CommonService } from 'src/app/service/common.service';
import { KeywordsProvider } from 'src/app/modules/lobby/providers/keywords.provider';
import { UserSessionProvider } from 'src/app/system/providers/user-session';
import { ConfigurationProvider } from 'src/app/system/providers/configuration-provider';
import { DomSanitizer } from '@angular/platform-browser';
import { Navigator } from 'src/app/system/components/menu/navigator';
import { WebservicesProvider } from 'src/app/system/providers/webservices';
import { TranslationService } from 'src/app/service/translation.service';

@Component({
  selector: 'app-left-side',
  templateUrl: './left-side.component.html',
  styleUrls: ['./left-side.component.scss'],
})
export class LeftSideComponent implements OnInit {

  @Input() device: any = 'desktop';
  @Input() size: any = 'large';
  @Output() changeSize = new EventEmitter<any>();

  mainLogo: string = '';

  activeMenu: any = '';

  keywordList: any[] = [];

  footerContent: any;
  contentCampaign: any;

  appConfigInfo: any;

  sportGames: any[] = [];

  langs: any[] = [
    {
      "code": "en-US",
      "flag": "US",
      "name": "LEFT_SIDE.LANGS.ENGLISH"
    }
  ];
  currentLang;

  customerSub: any;

  constructor(
    private config: Config,
    private events: MyEvent,
    private menuCtrl: MenuController,
    private platform: Platform,
    public comService: CommonService,
    private keywordsProvider: KeywordsProvider,
    public userSession: UserSessionProvider,
    private configurationProvider: ConfigurationProvider,
    private navCtrl: NavController,
    public sanitizer: DomSanitizer,
    private webService: WebservicesProvider,
    private transService: TranslationService
  ) {

  }

  ngOnInit() {
    this.appConfigInfo = this.config.getAppConfigInfo();
    this.mainLogo = this.appConfigInfo?.main_logo || 'assets/imgs/logo.png';

    if (this.appConfigInfo.languages) {
      this.langs = this.appConfigInfo.languages;
    }

    this.getCurrentLang();

    this.events.getObservable().subscribe((data) => {
      if (data.event == 'event:main:button') {
        this.activeMenu = '';
      } else if (data.event == 'event:sidebar:item') {
        this.activeMenu = data.data;
      } else if (data.event == 'event:casino:category') {
        this.activeMenu = data.data;
      }
    });
    let lobbyName = this.config.getConfig()?.LOBBY_NAME;
    this.loadCasinoLobby(lobbyName);

    this.getSportsData();
    this.getSidebarFooter();
    this.getCampaignData();

    this.transService.changeLangSubject.subscribe(lang => {
      this.getCurrentLang();

      let lobbyName = this.config.getConfig()?.LOBBY_NAME;
      this.loadCasinoLobby(lobbyName);

      this.getSportsData();
      this.getSidebarFooter();
      this.getCampaignData();
    });

  }

  getCurrentLang() {
    var langCode = this.transService.getSelectedLanguage();
    var cLang = this.langs.find(el => el.code == langCode);
    if (cLang) this.currentLang = cLang;
  }

  getSidebarFooter() {
    this.configurationProvider.getPageContent('sidebar-footer').subscribe(
      (response) => {
        console.log('sidebar footer == ', response);
        if (response.code == 200 && response.result) {
          this.footerContent = this.sanitizer.bypassSecurityTrustHtml(response.payload);
        }
      },
      (error) => {
        console.error('sidebar footer error == ', error);
      }
    );
  }

  getCampaignData() {
    if (!this.comService.isMobileWeb && this.device == 'desktop') {
      this.configurationProvider.getPageContent('sidebar-campaign').subscribe(
        (response) => {
          console.log('sidebar campaign == ', response);
          if (response.code == 200 && response.result) {
            this.contentCampaign = this.sanitizer.bypassSecurityTrustHtml(response.payload);

            setTimeout(() => {
              var eles = document.querySelectorAll('.open-campaign');
              if (eles.length) {
                eles.forEach(ele => {
                  ele.addEventListener('click', () => {
                    var data = ele.getAttribute('data-campaign-id');
                    console.log('data == ', data);
                    if (data) this.navCtrl.navigateForward(`${this.comService.currentTemplate}/bonus-detail/${data}`);
                  });
                });
              }

              var spins = document.querySelectorAll('.open-spin');
              if (spins.length) {
                spins.forEach(ele => {
                  ele.addEventListener('click', () => {
                    this.openLuckySpin();
                  });
                });
              }
            }, 2000);
          }
        },
        (error) => {
          console.error('sidebar campaign error == ', error);
        }
      );
    }
  }

  loadCasinoLobby(lobbyName) {
    if (this.customerSub) this.customerSub.unsubscribe();
    this.customerSub = null;

    this.keywordsProvider.getKeywordsAndGames(lobbyName).then(
      (response: any) => {
        if ((response || []).length) {
          let keywords = response[0].payload;
          var allCasinoGames = response[1].payload;
          this.comService.gamesDataSubject.next(allCasinoGames);
          this.allGamesDataList(allCasinoGames);

          this.customerSub = this.userSession.getCustomer().subscribe((customer) => {
            this.filterKeywordGames(keywords, allCasinoGames);
          });
        }
      }, (error) => console.log(error)
    );
  }

  filterKeywordGames(keywords, allGames) {
    var categories = [];
    if (!this.userSession.isAuthenticated()) {
      categories = keywords.filter((data) => {
        return data.action_type != "lastplayed";
      });
    } else {
      categories = keywords;
    }

    categories.forEach(element => {
      element.keyword_code = element.keyword_code.toLowerCase();
      if (element.keyword_code == 'featured') {
        element.iconname = 'fa-solid fa-fire';
      } else if (element.keyword_code == 'slots') {
        element.iconname = 'fa-solid fa-slot-machine';
      } else if (element.keyword_code == 'live') {
        element.iconname = 'fa-solid fa-clapperboard-play';
      } else if (element.keyword_code == 'table') {
        element.iconname = 'fa-solid fa-dice';
      } else if (element.keyword_code == 'blackJack') {
        element.iconname = 'fa-solid fa-card-spade';
      } else if (element.keyword_code == 'poker') {
        element.iconname = 'fa-duotone fa-cards';
      } else if (element.keyword_code == 'variety') {
        element.iconname = 'fa-solid fa-card-spade';
      } else if (element.keyword_code == 'lastplayed') {
        element.iconname = 'fa-solid fa-clock-rotate-left';
      } else if (element.keyword_code == 'new') {
        element.iconname = 'fa-solid fa-newspaper';
      } else if (element.keyword_code == 'hot') {
        element.iconname = 'fa-solid fa-sun';
      } else {
        element.iconname = 'fa-solid fa-gamepad';
      }
      element['gameList'] = allGames[element['name']] || [];
    });
    console.log('keywordList: ', categories);
    this.keywordList = categories.filter(ee => (ee.action_type == "lastplayed" || ee.gameList.length));
    this.comService.gameKeywordsSubject.next(this.keywordList);
  }

  public allGamesDataList(gamesData) {
    if (gamesData) {
      let allCasGames = [];
      Object.keys(gamesData).forEach(key => {
        (gamesData[key] as Array<any>).forEach(el => {
          allCasGames.push(el);
        });
      });
      var allGamesDataArray = allCasGames.reduce((a, b) => {
        return a.concat(b);
      }, []);
      this.comService.allGamesSubject.next(allGamesDataArray);
    }
  }

  async selectMenu(menu, index) {
    this.activeMenu = menu;
    this.events.publishSomeData({event: 'event:tabs:load', tab: 'dashboard'});
    setTimeout(() => {
      this.events.publishSomeData({event: 'event:casino:item', data: index});
    }, 150);
    this.menuCtrl.toggle();
  }

  changeSizeFunc() {
    if (this.comService.isMobileWeb || this.comService.isMobileApp) {
      this.activeMenu = null;
      this.events.publishSomeData({event: 'event:tabs:load', tab: 'dashboard'});
      this.menuCtrl.toggle();
    } else {
      if (this.size == 'large') {
        this.size = 'small';
      } else if (this.size == 'small') {
        this.size = 'large';
      }
      this.changeSize.emit(this.size);
      this.events.publishSomeData({event: 'sizebar:size:changed', size: this.size});
    }
  }

  selectListHeader(item) {
    this.activeMenu = item;
    this.events.publishSomeData({event: 'event:tabs:load', tab: item});
    this.menuCtrl.toggle();
  }


  category: any = 'casino';
  changeAccordion($event) {
    this.category = $event.target.value;
  }

  lang: any;
  changeAccordionLang($event) {
    this.lang = $event.target.value;
  }

  changeLang(lang) {
    this.currentLang = lang;
    this.transService.setLanguage(lang.code);
    setTimeout(() => {
      this.lang = null;
    }, 100);
  }

  goSport(sport?) {
    if (!sport) {

      if (!this.sportGames.length) Navigator.openModal('FootballPage', 'football_modal');

    } else {
      if (this.appConfigInfo.experimental.sports) {
        this.activeMenu = sport.sportGameName;
        sessionStorage.setItem('sport_game_api', sport.sportGameLink);
        this.events.publishSomeData({event: 'event:tabs:load', tab: 'sports'});
      } else {
        Navigator.openModal('FootballPage', 'football_modal');
      }
    }
  }

  getSportsData() {
    this.webService.get('sports/games').subscribe(response => {
      console.log('sports games == ', response);
      if (response.code == 200) {
        this.sportGames = response.payload;
      }
    }, error => {
      console.error('sports err == ', error);
    });
  }

  /**
   *
   */
  openLuckySpin() {
    Navigator.navigateTo('SpinWheelComponent', {forceModal: true, cssClass: 'spin-wheel'});
  }

}
