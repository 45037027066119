import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormatter',
})
export class CurrencyFormatter implements PipeTransform {
  public forceDecimals: boolean = false;
  public amount: number;
  public currencyType: string;

  /**
   * Formats a number as a currency
   */
  transform(amount: number, currencyType: string): any {
    this.amount = amount;
    this.currencyType = currencyType;
    if (!amount) return this.getCurrencySymbol() + ' ' + '00.00';
    else return this.toClientString();
  }

  getCurrencySymbol(): string {
    let symbol: string = '';

    switch (this.currencyType) {
      case 'USD':
      case 'CAD':
        symbol = '$';
        break;
      case 'NGN':
        symbol = '₦';
        break;
      case 'INR':
        symbol = '₹';
        break;
      default:
        symbol = '$';
    }

    return symbol;
  }

  toClientString(): string {
    let str: string = this.getCurrencySymbol() + ' ';
    if (this.currencyType == 'PLAY')
      str += this.numberFormat(this.amount / 100, 0, false, false);
    else if (this.currencyType == 'RWP')
      str += this.numberFormat(this.amount, 0, false, false);
    else
      str += this.numberFormat(
        this.amount / 100,
        2,
        this.forceDecimals || this.amount % 100 != 0,
        false
      );

    return str;
  }

  numberFormat(
    number: any,
    maxDecimals: number = 2,
    forceDecimals: boolean = false,
    siStyle: boolean = true
  ): string {
    let str: string = '';
    var i: number = 0;
    var inc: number = Math.pow(10, maxDecimals);
    if (maxDecimals > 0) {
      str = String(Math.round(inc * Number(number)) / inc);
    } else {
      str = String(Math.trunc(inc * Number(number)) / inc);
    }

    var hasSep: boolean = str.indexOf('.') == -1,
      sep: number = hasSep ? str.length : str.indexOf('.');
    var ret: String =
      (hasSep && !forceDecimals ? '' : siStyle ? ',' : '.') +
      str.substr(sep + 1);
    ret = (hasSep && !forceDecimals) ? ret + '.00' : ret;
    if (forceDecimals) {
      for (
        var j: number = 0;
        j <= maxDecimals - (str.length - (hasSep ? sep - 1 : sep));
        j++
      )
        ret += '0';
    }
    while (i + 3 < (str.substr(0, 1) == '-' ? sep - 1 : sep))
      ret = (siStyle ? '.' : ',') + str.substr(sep - (i += 3), 3) + ret;
    return str.substr(0, sep - i) + ret;
  }
}
