import {
  Directive,
  ElementRef,
  HostListener,
  Input
} from '@angular/core';
/**
 * get parent element by class name from View and assign hover effects to child elements
 * requires a class named hover-class, which can contain any type of effect
 */
@Directive({
  selector: '[hoverEffect]' // Attribute selector
})
export class HoverEffectDirective {
  @Input('mobileCheck') mobileCheck:boolean;
  @Input('isAuth') isAuth:boolean;

  constructor( private el:ElementRef ) {}

  @HostListener('mouseenter') mouseover() {
    this.hoverOverElem(this.el.nativeElement, this.hoverEffect, true, this.mobileCheck);
  };

  @HostListener('mouseleave') mouseleave() {
    this.hoverOverElem(this.el.nativeElement, this.hoverEffect, false, this.mobileCheck);
  }

  @Input()
  hoverEffect: any;

  hoverOverElem(parentElem: HTMLElement, className: string, hoverOn: boolean, isMobileDevice:boolean) {
    if (!parentElem) return;
    const childElems = parentElem.getElementsByClassName(className);
    if (!childElems) return;
    const tileBtn = parentElem.getElementsByClassName('tile-btn');
    if (!tileBtn) return;
    if(hoverOn) {
      if(!isMobileDevice) {
        Array.from(childElems)
          .forEach((x: HTMLElement) => {
              x.classList.add('tile-hover');
            }
          );
        Array.from(tileBtn)
          .forEach((x: HTMLElement) => {
              x.style.display = "block";
            }
          );
      }
      else if (isMobileDevice) {
        Array.from(childElems)
        .forEach((x: HTMLElement) => {
            setTimeout(()=> { x.classList.add('tile-hover') }, 15);

          }
        );
        Array.from(tileBtn)
        .forEach((x: HTMLElement) => {
            setTimeout(()=> { x.style.display = "block" }, 10);
          }
        );
      }
    }

    else { //keep for mobile to remove hover on tap event of another tile before timeout expires
      Array.from(childElems)
        .forEach((x: HTMLElement) => {
            x.classList.remove('tile-hover');
          }
        );
      Array.from(tileBtn)
        .forEach((x: HTMLElement) => {
            x.style.display = "none";
          }
        );
    }
  }
}
