import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IonContent } from '@ionic/angular';
import { CustomerModel } from 'src/app/modules/account/models/customer-model';
import { CashierContentProvider } from 'src/app/modules/cashier/providers/cashier-content-provider';
import { CashierProvider } from 'src/app/modules/cashier/providers/cashier-provider';
import { CommonService } from 'src/app/service/common.service';
import { MyEvent } from 'src/app/service/myevent.services';
import { idNumberGroupMatchValidator } from 'src/app/shared/customFormValidators';
import { Navigator } from 'src/app/system/components/menu/navigator';
import { Config } from 'src/app/system/providers/configuration';
import { ConfigurationProvider } from 'src/app/system/providers/configuration-provider';
import { SuccessMessageProvider } from 'src/app/system/providers/success-message-provider';
import { UserSessionProvider } from 'src/app/system/providers/user-session';
import { WebservicesProvider } from 'src/app/system/providers/webservices';

@Component({
  selector: 'app-new-bank',
  templateUrl: './new-bank.page.html',
  styleUrls: ['./new-bank.page.scss'],
})
export class NewBankPage implements OnInit, OnDestroy {

  @ViewChild('Content') content: IonContent;

  public states: any[] = [];
  selectedProvince: any;
  error: any = null;

  public bankForm: FormGroup;
  // public idNumberGroup: AbstractControl;
  // public idNumber: AbstractControl;
  // public idNumber2: AbstractControl;

  depositMethods: any[] = [];
  allBankList: any;
  bankList: any[] = [];

  customer: CustomerModel;

  constructor(
    public configProvider: ConfigurationProvider,
    public successMessageProvider: SuccessMessageProvider,
    private cashierProvider: CashierProvider,
    private contentProvider: CashierContentProvider,
    public comService: CommonService,
    private events: MyEvent,
    private config: Config,
    private webservice: WebservicesProvider,
    private userSession: UserSessionProvider
  ) {
    this.bankForm = new FormGroup({
      // idType: new FormControl('', [Validators.required]),
      // idNumberGroup: new FormGroup(
      //   {
      //     idNumber: new FormControl('', [Validators.required]),
      //     idNumber2: new FormControl('', [Validators.required]),
      //   },
      //   idNumberGroupMatchValidator
      // ),
      // idState: new FormControl('', [Validators.required]),
      // routingNumber: new FormControl('', [
      //   Validators.required,
      //   Validators.minLength(1),
      //   Validators.maxLength(9),
      //   Validators.pattern('^[0-9]*'),
      // ]),
      accountNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(17),
        Validators.pattern('^[0-9]*'),
      ]),
      bankCode: new FormControl('', [Validators.required]),
      paymentType: new FormControl('', [Validators.required])
    });

    // this.idNumberGroup = this.bankForm.get('idNumberGroup');
    // this.idNumber = this.idNumberGroup.get('idNumber');
    // this.idNumber2 = this.idNumberGroup.get('idNumber2');
  }

  ngOnInit() {

    this.getDepoditMethods();

    this.userSession.getCustomer().subscribe((customer) => {
      this.customer = customer;
      if (customer) {
        this.getBankCodes();
      }
    });
  }

  ngOnDestroy(): void {
    this.successMessageProvider.clearMessage();
    this.error = null;
  }

  ionViewWillLeave() {
    this.events.publishSomeData({event: 'new:bank:added'});
    this.successMessageProvider.clearMessage();
    this.error = null;
  }

  getBankCodes() {
    this.webservice.get('cashier/bankcode/list/static', {
      player_id: this.customer.customerId,
      session_id: this.userSession.sessionToken,
    }).subscribe(data => {
      console.log('bank codes == ', data);
      if (data.code == 200 && data.result) this.allBankList = data.payload;
    });
  }

  getStates() {
    this.configProvider.callGetStatesByCountry('USA').subscribe(
      (response: any) => {
        console.log('callGetStatesByCountry ', response);
        if (response.code == 200 && !!response.result) {
          let provs = response.payload;

          this.states = [];
          for (let p in provs) {
            this.states.push({ code: p, name: provs[p] });
          }

          this.selectedProvince = '';
        }
      },
      (error) => {
        console.log('callGetStatesByCountry ', error);
      }
    );
  }

  getDepoditMethods() {
    this.getVendorData();
  }

  getVendorData(methods = []) {
    this.comService.showLoader('');
    this.contentProvider
      .getActiveTypesCategories({ include_types: true })
      .subscribe(
        (response: any) => {
          if (response.result) {
            let vendors = response.payload.paymentTypesInfo;
            let sortedDepositMethods: any[] = [];
            for (let vendor of vendors) {
              if (!vendor.name) vendor.name = vendor.types[0].name;
              if (vendor.code != 'XP') sortedDepositMethods.push(vendor);
            }
            this.depositMethods = sortedDepositMethods;
            console.log('this.depositMethods == ', this.depositMethods);
          }
          this.comService.hideLoader();
        },
        (error) => {
          this.comService.hideLoader();
        }
      );
  }

  selectPayment($event) {
    this.bankList = [];
    var paymentCode = this.bankForm.get('paymentType').value;
    var bankCodes = this.allBankList[paymentCode];
    if (bankCodes) {
      Object.keys(bankCodes).forEach(key => {
        var temp = {
          code: key,
          name: bankCodes[key]
        }
        this.bankList.push(temp);
      });
    }
  }

  onSubmit() {
    this.successMessageProvider.clearMessage();
    this.error = null;

    let addCardFormObj: any = this.bankForm.value;

    // addCardFormObj.payment_type_code = this.bankForm.get('paymentType').value;
    // addCardFormObj.idNumber = this.idNumberGroup.get('idNumber').value;

    this.cashierProvider.callAddAccount(addCardFormObj).subscribe(
      (response: any) => {
        console.log('resp == ', response);
        if (response.code == 200 && !!response.result) {
          this.successMessageProvider.setMessage(
            'You added new bank account'
          );
          this.bankForm.reset();
          setTimeout(() => {
            this.content.scrollToTop();
          }, 320);
        }
      },
      (error) => {
        // handle all deposit errors in base
        console.log('onSubmit error = ', error);
        this.error.setErrorMessage(error.getErrorMessage());
        setTimeout(() => {
          this.content.scrollToTop();
        }, 320);
      }
    );
  }

  close() {
    Navigator.back();
  }

  addNew() {
    this.successMessageProvider.clearMessage();
    this.error = null;
  }

}
