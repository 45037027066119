import { Component, OnInit } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { Subscription } from 'rxjs';
import { CustomerModel } from 'src/app/modules/account/models/customer-model';
import { TouchUserProvider } from 'src/app/modules/authentication/providers/touch-user-provider';
import { CommonService } from 'src/app/service/common.service';
import { MyEvent } from 'src/app/service/myevent.services';
import { Navigator } from 'src/app/system/components/menu/navigator';
import { Config } from 'src/app/system/providers/configuration';
import { UserSessionProvider } from 'src/app/system/providers/user-session';

declare var BTRenderer: any;
declare var $: any;

@Component({
  selector: 'app-football',
  templateUrl: './football.page.html',
  styleUrls: ['./football.page.scss'],
})
export class FootballPage implements OnInit {

  bt: any;

  customerSub: Subscription;
  customer: CustomerModel;
  checkSession = false;

  constructor(
    public navCtrl: NavController,
    public userSession: UserSessionProvider,
    private platform: Platform,
    private storage: Storage,
    public touchUserProvider: TouchUserProvider,
    private config: Config,
    private events: MyEvent,
    public comService: CommonService
  ) { }

  ngOnInit() {

  }

  ionViewWillEnter() {
  }

  ionViewDidEnter() {
    this.customerSub = this.userSession.getCustomer().subscribe((customer) => {
      this.customer = customer;
      // this.killBT();
      // setTimeout(() => {
      //   this.initailize(this.userSession.getSessionToken());
      // }, 200);
      this.checkSession = true;
    });
  }

  ionViewWillLeave() {
    // this.killBT();
    this.checkSession = false;
    this.customerSub.unsubscribe();
  }

  killBT() {
    this.bt && this.bt.kill();
    this.bt = null;
    setTimeout(() => {
      document.getElementById("betby").append('');
    }, 50);
  }

  initailize(token = null) {

    let offsetTop: any = 0;
    let self = this;

    this.bt && this.bt.kill();
    let queryDatas = this.getQuerys();

    this.bt = new BTRenderer().initialize({
      /*Details of the following parameters can be found in the API documentation*/
      brand_id: queryDatas.brand_id || "2215154845441200128",
      token: (queryDatas.token || token) || null,
      themeName: "default",
      lang: queryDatas.lang || "en-US",
      target: document.getElementById("betby"),
      stickyTop: offsetTop,
      betSlipOffsetTop: offsetTop,
      betslipZIndex: 999,
      fontFamilies: ["Montserrat, sans-serif", "Roboto, sans-serif"],
      onTokenExpired: () => {
        console.error("Token Expired.");
        self.killBT();
        setTimeout(() => {
          self.initailize();
        }, 200);
      },
      onRouteChange: () => {},
      onLogin: () => {self.onLogin()},
      onRegister: () => {self.onSignup()},
      onSessionRefresh: () => {},
      onBetSlipStateChange: () => {},
      onBannerClick: ()=> {}
    });
  }

  getQuerys(): any {
    if (location.search) {
      const queryString = location.search.slice(1);
      return queryString.split("&").reduce((acc, cur) => {
        const [key, val] = cur.split("=");
        acc[key] = val;
        return acc;
      }, {});
    } else {
      return {};
    }
  }

  onLogin() {
    Navigator.navigateTo("LoginPage", { forceModal: true, cssClass: 'login_modal' });
  }

  onSignup() {
    let signupPage = "SignupStepOnePage";
    Navigator.navigateTo(signupPage, { forceModal: true });
  }

  goBack() {
    Navigator.dismissModal();
    this.events.publishSomeData({event: 'event:tabs:load', tab: 'dashboard'});
  }

  close() {
    Navigator.back();
  }

}
