import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { Navigator } from 'src/app/system/components/menu/navigator';
import { UserSessionProvider } from 'src/app/system/providers/user-session';
import { WebservicesProvider } from 'src/app/system/providers/webservices';
import { format } from 'date-fns';
import { debounceTime, map } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { Config } from 'src/app/system/providers/configuration';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {

  activeTab: any;
  showSelection = false;

  selectedItem: any;
  selections: any[] = [
    {title: 'REFERRALS.BETSPLUS.USER_LIST.SELECTS.ITEM1', value: 'all'},
    {title: 'REFERRALS.BETSPLUS.USER_LIST.SELECTS.ITEM2', value: 'today'},
    {title: 'REFERRALS.BETSPLUS.USER_LIST.SELECTS.ITEM3', value: 'past_7'},
    {title: 'REFERRALS.BETSPLUS.USER_LIST.SELECTS.ITEM4', value: 'past_30'},
    {title: 'REFERRALS.BETSPLUS.USER_LIST.SELECTS.ITEM5', value: 'past_60'}
  ];
  fromDate: any;
  toDate: any;

  displayCount;
  allDirects: any[] = [];
  directs: any[] = [];

  currentPage = 1;
  totalRecords: any = 0;

  searchUserId: any;
  searchSub: Subject<string> = new Subject<string>();

  defaultCurrency: any;

  constructor(
    public comService: CommonService,
    private webService: WebservicesProvider,
    private userSession: UserSessionProvider,
    private cdr: ChangeDetectorRef,
    private config: Config
  ) { }

  ngOnInit() {
    this.selectedItem = this.selections[0];
    this.searchSub.pipe(map((userId: any) => userId), debounceTime(500)).subscribe((text: string) => {
      this.getDirects();
    });

    this.defaultCurrency = this.config.getConfig()['DEFAULT_CURRENCY'];
  }

  ionViewDidEnter() {
    this.activeTab = 'sub';
    this.getDirects();
  }

  close() {
    Navigator.back();
  }

  changeTab(tab) {
    this.activeTab = tab;
    this.currentPage = 1;
    this.getDirects();
  }

  searchUserById($event) {
    this.searchUserId = $event.target.value;
    this.currentPage = 1;
    this.searchSub.next(this.searchUserId);
  }

  filter(item) {
    this.selectedItem = item;
    this.showSelection = false;
    this.currentPage = 1;

    let today = new Date();
    let date = new Date();

    if (this.selectedItem.value == 'today') {
      this.fromDate = format(today, 'yyyy-MM-dd');
      this.toDate = format(today, 'yyyy-MM-dd');
    }
    else if (this.selectedItem.value == 'past_7') {
      var fDate = new Date(date.setDate(date.getDate() - 7));
      this.fromDate = format(fDate, 'yyyy-MM-dd');

      this.toDate = format(today, 'yyyy-MM-dd');
    }
    else if (this.selectedItem.value == 'past_30') {
      var fDate = new Date(date.setDate(date.getDate() - 30));
      this.fromDate = format(fDate, 'yyyy-MM-dd');

      this.toDate = format(today, 'yyyy-MM-dd');
    }
    else if (this.selectedItem.value == 'past_60') {
      var fDate = new Date(date.setDate(date.getDate() - 60));
      this.fromDate = format(fDate, 'yyyy-MM-dd');

      this.toDate = format(today, 'yyyy-MM-dd');
    }
    else if (this.selectedItem.value == 'all') {
      this.fromDate = format(today, 'yyyy-MM-dd');
      this.toDate = format(today, 'yyyy-MM-dd');
    }
    this.getDirects();
  }

  getDirects() {
    var sendParams: any = {
      player_id: this.userSession.getCustomerId(),
      session_id: this.userSession.getSessionToken(),
      page_number: this.currentPage,
      page_size: 10,
      is_direct: this.activeTab == 'sub' ? true : false
    };

    if (this.searchUserId) {
      sendParams['child_id'] = this.searchUserId;
    }

    if (this.selectedItem.value != 'all') {
      sendParams['from_date'] = this.fromDate;
      sendParams['to_date'] = this.toDate;
    }

    this.webService.get('raf/my-referral/children', sendParams).subscribe(response => {
      console.log('direct response == ', response);
      if (response.code == 200) {
        this.allDirects = response.payload.children;
        this.directs = response.payload.children;
        this.totalRecords = response.payload.total_count;
      }
      this.cdr.detectChanges();
    }, err => {
      console.error('direct err == ', err);
    });
  }

  onPageChange($event) {
    this.currentPage = $event;
    this.getDirects();
  }

}
