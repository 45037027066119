import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ActiveAccountPageRoutingModule } from './active-account-routing.module';

import { ActiveAccountPage } from './active-account.page';
import { SystemModule } from 'src/app/system/system.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ActiveAccountPageRoutingModule,
    ReactiveFormsModule,
    SystemModule,
    SharedModule,
    TranslateModule
  ],
  declarations: [ActiveAccountPage]
})
export class ActiveAccountPageModule {}
