import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UserSessionProvider } from 'src/app/system/providers/user-session';
import { CustomerModel } from '../../../models/customer-model';
import { IonInput, ModalController } from '@ionic/angular';
import { Navigator } from 'src/app/system/components/menu/navigator';
import { TranslationService } from 'src/app/service/translation.service';

@Component({
  selector: 'app-edit-name',
  templateUrl: './edit-name.page.html',
  styleUrls: ['./edit-name.page.scss'],
})
export class EditNamePage implements OnInit {

  @ViewChild('nameInput') nameInput!: IonInput;

  @Input() callback: any;

  customer: CustomerModel;

  firstName: any;
  lastName: any;

  error: any;

  constructor(
    private userSession: UserSessionProvider,
    private modalCtrl: ModalController,
    private transService: TranslationService
  ) { }

  ngOnInit() {
    this.userSession.getCustomer().subscribe((customer) => {
      if (customer) {
        this.customer = customer;
        this.firstName = customer.firstName;
        this.lastName = customer.lastName;
      }
    });
  }

  async save() {
    if (!this.firstName) {
      var msg = await this.transService.getTranslationWord('EDIT_USERNAME.MESSAGES.USERNAME_REQUIRED');
      this.error = msg;
      return;
    }

    if (this.firstName.length < 3) {
      var msg = await this.transService.getTranslationWord('EDIT_USERNAME.MESSAGES.USERNAME_LENGTH');
      this.error = msg;
      return;
    }

    this.userSession.updateAccount('' + this.customer.customerId, {
      firstName: this.firstName,
      // lastName: this.lastName
    }).then(resp => {
      this.callback();
    });
  }

  async input($event) {
    let val = $event.target!.value;
    // var filteredValue = val.replace(/[^0-9a-zA-Z\s]+/g, '');
    // var filteredValue = val.replace(/[-!\/\\$@#%^&*()`'".,;:[\]\<>?_+|~={}]+/g, '');
    var filteredValue = val.replace(/[^a-zA-Z\s]*$/g, '');
    this.firstName = filteredValue;
    if (this.nameInput) this.nameInput.value = filteredValue;
    this.error = null;

    if (!this.firstName) {
      var msg = await this.transService.getTranslationWord('EDIT_USERNAME.MESSAGES.USERNAME_REQUIRED');
      this.error = msg;
    } else if (this.firstName.length < 3) {
      var msg = await this.transService.getTranslationWord('EDIT_USERNAME.MESSAGES.USERNAME_LENGTH');
      this.error = msg;
    }
  }

  async lossFocus($event) {
    var filteredValue = (this.firstName || '').replace(/[^a-zA-Z\s]*$/g, '');
    this.firstName = filteredValue;
    if (this.nameInput) this.nameInput.value = filteredValue;

    if (!this.firstName) {
      var msg = await this.transService.getTranslationWord('EDIT_USERNAME.MESSAGES.USERNAME_REQUIRED');
      this.error = msg;
    } else if (this.firstName.length < 3) {
      var msg = await this.transService.getTranslationWord('EDIT_USERNAME.MESSAGES.USERNAME_LENGTH');
      this.error = msg;
    }
  }

}
