import { Component, Input } from '@angular/core';

/**
 * Generated class for the UserMenuPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-user-menu',
  templateUrl: 'user-menu.html',
})
export class UserMenuPage {

  @Input() menus: any[] = [];

  constructor() {
  }

  ionViewDidLoad() {
    console.log('ionViewDidLoad UserMenuPage');
  }

}
