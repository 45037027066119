import { CashierProvider } from '../../../providers/cashier-provider';
import { Component, OnInit } from '@angular/core';
import { Navigator } from './../../../../../system/components/menu/navigator';
import { SuccessMessageProvider } from '../../../../../system/providers/success-message-provider';
import { BalanceProvider } from './../../../../../system/providers/balance-provider';
import { NavParams } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';

@Component({
  selector: 'page-withdrawal-reverse-confirm',
  templateUrl: 'withdrawal-reverse-confirm.html',
  styleUrls: ['./withdrawal-reverse-confirm.scss'],
})
export class WithdrawalReverseConfirmPage implements OnInit {

  error = null;

  payoutRequestID: string;
  amount: string;

  constructor(
    private navParams: NavParams,
    private cashierProvider: CashierProvider,
    private balanceProvider: BalanceProvider,
    public successMessageProvider: SuccessMessageProvider,
    private events: MyEvent
  ) {
    this.payoutRequestID = this.navParams.get('payoutRequestID');
    this.amount = this.navParams.get('amount');

    this.successMessageProvider.clearMessage();
    this.error = null;
  }

  ngOnInit(): void {
    
  }

  onReverseWithdraw() {
    let requestObj: any = {};
    requestObj.payoutRequestID = this.payoutRequestID;

    this.cashierProvider.callReverseWithdrawal(requestObj).subscribe(
      (response: any) => {
        if (response.code == 200 && !!response.result) {
          this.successMessageProvider.setMessage(
            'Your withdrawal was successfully reversed.'
          );
          this.balanceProvider.updateBalance();
          this.events.publishSomeData({event: 'success_reverse'});
        }
      },
      (error) => {
      }
    );

    this.error = null;
  }

  onBack = () => {
    if (!!this.navParams.get('navigateBackPage')) {
      let navigateBackPage = this.navParams.get('navigateBackPage');
      Navigator.navigateTo(navigateBackPage);
    } else {
      Navigator.back();
    }
  };
}
