import { ForgotPasswordPageModule } from './pages/forgot-password/forgot-password.module';
import { LoginPageModule } from './pages/login/login.module';
import { LogoutPageModule } from './pages/logout/logout.module';
import { NgModule } from '@angular/core';
import { ActiveAccountPageModule } from './pages/active-account/active-account.module';

@NgModule({
  declarations: [],
  imports: [
    LoginPageModule,
    LogoutPageModule,
    ForgotPasswordPageModule,
    ActiveAccountPageModule
  ],
  entryComponents: [],
  providers: [],
  exports: [
    LogoutPageModule
  ],
})
export class AuthenticationModule {}
