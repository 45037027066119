import { CustomerModel } from './../../account/models/customer-model';
import { UserSessionProvider } from './../../../system/providers/user-session';
import { Injectable } from '@angular/core';
import { WebservicesProvider } from './../../../system/providers/webservices';
import { Utils } from './../../../shared/utils';
import { first } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CashierContentProvider {
  public customer: CustomerModel;
  public selectedBonus: any;
  public stopBestBonusFlag: any;
  private pageConfigs;

  constructor(
    private userProvider: UserSessionProvider,
    private webservice: WebservicesProvider,
    private utils: Utils
  ) {
    this.userProvider.getCustomer().subscribe(
      (customer) => {
        this.customer = customer;
      }
    );
    // cache for pageConfigs
    this.pageConfigs = new Map();
  }


  getActiveTypesCategories(data) {
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userProvider.sessionToken,
    }

    if (data && data.include_types) obj.fetch_types = true;
    return this.webservice.getResourcesApi('cashier/payment-method-category/list/active', obj);
  }

  getActivePaymentTypes(data?) {
    let obj: any = {
      category_code: data,
      player_id: this.customer.customerId,
      session_id: this.userProvider.sessionToken,
    }
    return this.webservice.getResourcesApi('cashier/payment-method/list/active', obj);
  }

  getPageConfig(pageName) {
    return new Promise((resolve) => {
      //check if there's cached data for this page
      if (this.pageConfigs.has(pageName)) {
        let response = this.pageConfigs.get(pageName);
        resolve(response);
      } else {
        let fileUrl = this.utils.getCDNConfigAsset("deposit_configs/" + pageName) + '.config?v=' + new Date().getTime();
        this.webservice.get(fileUrl).pipe(first()).subscribe(
          (response: any) => {
            console.log('Deposit configs loading from CDN for ', pageName, response);
            if (response != null) {
              this.pageConfigs.set(pageName, response);
              resolve(response);
            }
          },
          () => {
            console.error('Deposit configs not loading from CDN for', pageName);
            let localPath = "/assets/deposit_configs/" + pageName + '.config';
            this.webservice.get(localPath).subscribe((res:any) => {
              if(res != null){
                this.pageConfigs.set(pageName, res);
                resolve(res)
              }
            });
          }
        );
      }
    })
  }


  // Logic for Carrying Bonus from One Page to Another Start

  setSelectedBonus(data){
    this.selectedBonus= data;
  }

  getSelectedBonus(){
    return this.selectedBonus;
  }

  setSelectBestOne(val){
    if(val){
      this.stopBestBonusFlag = true;
    }
    else{
      this.stopBestBonusFlag = false
    }
  }

  getBraintreeToken(data){
    let obj: any = {
      player_id: this.customer.customerId,
      session_id: this.userProvider.sessionToken,
    }
    return this.webservice.getResourcesApi('cashier/external-services/braintree/token', obj);
  }


  getSelectBestOne(){
    return this.stopBestBonusFlag;
  }
  // Logic for Carrying Bonus from One Page to Another Ends

  getVendorPage(data){

    let page = '';

    switch (data) {
        case 'ACH':
            page = "CashierECheckBasePage";
            break;
        case 'EC':
            break;
        case 'EFT':
            break;
        case 'BD':
            page = "CashierECheckPage";
            break;
        case 'GP':
            page = "CashierVipPage";
            break;
        case 'NETL':
            //return $this->_forward('deposit-neteller', null, null, $params);
            break;
        case "GDIE":
            page = "CashierGigadetInteracPage";
            break;
        case "INSTA":
            page = "CashierInstaDebitPage";
            break;
        case "IDS":
            page = "CashierIDebitPage";
            break;
        case 'VI':
            page = "CashierCreditCardPage";
            break;
        case 'MC':
            page = "CashierCreditCardPage";
            break;
        case 'AM':
            page = "CashierCreditCardPage";
            break;
        case 'CREDIT':
            page = "CashierCreditCardPage";
            break;
        case 'NS':
            page = "CashierNeosurfPage";
            break;
        case 'PPD':
            page = "CashierPayPalPage";
            break;
        case 'SL':
            //deposit-sightline
            page = "CashierPrePaidCardsPage";
            break;
        case 'MZM':
            page = "CashierOnlineBankingPage"
            break;
        case 'PNM':
            page = "CashierPayNearMePage";
            break;
        case 'CAGE':
            page = "CashierCasinoCagePage";
            break;
        case 'PWB':
            page = "CashierPayWithMyBankPage";
            break;
        case 'VEN':
          page = "CashierVenmoPage";
          break;
        default:
            page = "CashierMainPage";
            break;
    }
      return page;
  }

}
