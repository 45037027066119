import { NgModule } from '@angular/core';
import { PopoverPageModule } from './pages/popover.module';

@NgModule({
  declarations: [],
  imports: [PopoverPageModule],
  entryComponents: [],
  providers: [],
  exports: [],
})
export class PopoverModule {}
