import { NotificationApiProvider } from './../../shared/providers/notification-api';
import { CashierProvider } from './../cashier/providers/cashier-provider';
import { MainProvider } from './../lobby/providers/main.provider';

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject, fromEvent } from 'rxjs';

import { Config } from './../../system/providers/configuration';
import { UserSessionProvider } from './../../system/providers/user-session';
import { WebservicesProvider } from './../../system/providers/webservices';
import { GameHub } from './providers/game-hub';
import { Navigator } from './../../system/components/menu/navigator';

import { CustomerModel } from './../account/models/customer-model';
import { CasinoGameModel } from './models/casino-game-model';
import { isUndefined } from '../../shared/codeUtils';
import { AppLinks } from './../../shared/app-links';
import { BalanceProvider } from './../../system/providers/balance-provider';
import { MyEvent } from 'src/app/service/myevent.services';
import { AlertController, Platform, PopoverController } from '@ionic/angular';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { CommonService } from 'src/app/service/common.service';

declare var window: any;

@Injectable({
  providedIn: 'root',
})
export class GameHubController {

  private focusedInstance: BehaviorSubject<any>;
  private LIMITS: any = {};
  public windowRef;
  // gameLaunchingStrategies: any = {};
  customer: CustomerModel;

  constructor(
    public config: Config,
    public userSessionProvider: UserSessionProvider,
    public webservices: WebservicesProvider,
    public events: MyEvent,
    public gameHub: GameHub,
    public platform: Platform,
    private iab: InAppBrowser,
    private mainProvider: MainProvider,
    private cashierProvider: CashierProvider,
    private notificationApi: NotificationApiProvider,
    private appLinks: AppLinks,
    public balanceProvider: BalanceProvider,
    public popoverCtrl: PopoverController,
    public comService: CommonService
  ) {
    let maxCasinoGames = this.config.getConfig().WEB_LIMIT_CASINOGAME || 1;

    if (this.platform.is('electron')) {
      maxCasinoGames = this.config.getConfig().DOWNLOADABLE_LIMIT_CASINOGAME;
    }

    this.LIMITS = {
      maxCasinoGames: maxCasinoGames,
    };

    fromEvent(window, 'message').subscribe((event: any) => {
      this.handleGameEvent(event);
    });

    this.focusedInstance = new BehaviorSubject(null);

    // this.gameLaunchingStrategies = {
    //   default: this.launchInGameTab,
    //   downloadable: this.launchInGameTab,
    //   mobileWebIos: this.launchInGameTab,
    //   mobileWebAndroid: this.launchInGameTab,
    //   mobile: this.launchInGameTab,
    //   mobileIos: this.launchInGameTab, // this.launchMobileIos, // ODR launcher
    // };

    this.userSessionProvider
      .getCustomer()
      .subscribe((customer: CustomerModel) => {
        this.customer = customer;
      });
  }

  private hasEntered: boolean = false;

  launchCasinoGame(data: any) {
    return this.regularLaunch(data);
  }

  launchCasinoGameDemo(data: any) {
    this.regularLaunch(data, true);
  }

  // async liveDealerLaunch(gameData) {
  //   let bonusNotificationDisplayedTimes: number = 0;
  //   const bonusMoneyUseEnabled =
  //     this.config.getConfig().EVOLUTION_BONUS_USE_ENABLED;
  //   const bonusNotificationDisplayedTimesMAX = this.config.getConfig()
  //     .EVOLUTION_BONUS_USE_MAX
  //     ? this.config.getConfig().EVOLUTION_BONUS_USE_MAX
  //     : 3;

  //   if (
  //     bonusMoneyUseEnabled == '0' &&
  //     bonusNotificationDisplayedTimes <=
  //       Number(bonusNotificationDisplayedTimesMAX)
  //   ) {
  //     let wasDismissed: boolean = false;
  //     let data = {
  //       title: 'Live Dealer Bonus',
  //       message2: 'Using bonus money on Live Dealer games is restricted',
  //       message:
  //         'Please note that players cannot currently use bonus funds on any Live Dealer games.',
  //     };
  //     let page = PopoverFactory('YesNoPopover');
  //     let popover = await this.popoverCtrl.create({
  //       component: page,
  //       componentProps: { data },
  //       showBackdrop: false,
  //       cssClass: 'standard-popover',
  //     });
  //     popover.present();
  //     bonusNotificationDisplayedTimes++;
  //     popover.onDidDismiss().then((data) => {
  //       wasDismissed = true;
  //       if (data.data)
  //         if (data.data == 'yes') return this.regularLaunch(gameData);
  //     });
  //     setTimeout(() => {
  //       if (!wasDismissed) return popover.dismiss();
  //     }, 15000);
  //   }
  // }

  regularLaunch(data, isDemo = false) {
    if (this.hasEntered) {
      return;
    } else {
      setTimeout(() => (this.hasEntered = false), 5000);
      this.hasEntered = true;
    }
    this.mainProvider.clearWaitingGameData();

    // if (!this.jurisdiction.hasBeenVerifiedBefore()) {
    //   Navigator.navigateTo(
    //     'GeoNotEnabledAtLaunchPage',
    //     { forceModal: true },
    //     {
    //       callBackFunction: (geoResp: GeoResponse) => {
    //         if (!!geoResp.is_allowed) {
    //           this.regularLaunch(data);
    //         } else {
    //           console.log('game-hub-controller.ts: aborting launch');
    //         }
    //         this.events.publishSomeData({ event: 'event:appInit:next' });
    //       },
    //     }
    //   );
    //   return;
    // }

    let isMobileApp = this.getDeviceType() == 'mobile' ? true : false;
    console.log('Device Type to make Game model == ', isMobileApp);
    let model = new CasinoGameModel(
      data,
      this.config,
      this.customer,
      this.userSessionProvider,
      isMobileApp,
      this.customer ? this.comService.defaulCurrency : this.config.getConfig().DEFAULT_CURRENCY,
      isDemo
    );
    console.log('BOUNCE: Casino Game Model: ', model);
    this.killAllInstances();
    console.log('BOUNCE: killAllInstances');
    this.launchGame(model);
    // this.jurisdiction
    //   .verifyJurisdiction(() => this.regularLaunch(data))
    //   .then((geoResp: GeoResponse) => {
    //     console.log('BOUNCE: verifyJurisdiction');
    //     this.events.publishSomeData({
    //       event: 'event:gameLaunched',
    //       data: {
    //         customer_id: this.customer?.customerId,
    //         game_name: model.game_name,
    //         is_demo: false,
    //         game_id: data.id,
    //       },
    //     });
    //     this.launchGame(model);
    //   })
    //   .catch((err) => {
    //     console.log('launch aborted.', err);
    //   });
  }

  launchGame(model) {
    this.events.publishSomeData({
      event: 'event:balanceState',
      data: { show: false, code: 'IN_GAME' },
    });
    let deviceType = this.getDeviceType();
    console.log('BOUNCE: deviceType: ', deviceType);
    this.launchInGameTab(model);
    // if (deviceType in this.gameLaunchingStrategies) {
    //   let launcherFn = this.gameLaunchingStrategies[deviceType];
    //   console.log('BOUNCE: launcherFn: ', launcherFn);
    //   launcherFn.apply(this, [model]);
    // }
  }

  getDeviceType() {
    let deviceType = 'default';
    if (this.platform.is('electron')) {
      deviceType = 'downloadable';
    } else if (
      this.platform.is('mobileweb') &&
      !this.platform.is('cordova') &&
      this.platform.is('ios')
    ) {
      deviceType = 'mobileWebIos';
    } else if (
      this.platform.is('mobileweb') &&
      !this.platform.is('cordova') &&
      this.platform.is('android')
    ) {
      deviceType = 'mobileWebAndroid';
    } else if (this.platform.is('cordova') && this.platform.is('ios')) {
      deviceType = 'mobileIos';
    } else if (this.platform.is('cordova')) {
      deviceType = 'mobile';
    }
    return deviceType;
  }

  launchInGameTab = (model) => {
    let instanceId = this.gameHub.addCasinoGame(model, {
      viewContainer: 'IFRAME',
    });
    this.events.getObservable().subscribe((data) => {
      if (data.event == 'event:authentication:logout' || data.event == 'event:game:close') this.removeInstance(instanceId);
    });
    this.focusInstance(instanceId);

    // let obj: any = {
    //   player_id: this.cashierProvider.customer.customerId,
    //   session_id: this.userSessionProvider.getSessionToken(),
    //   game_launcher_id: '-1',
    // };

    // this.webservices.postResourcesApi('games/close-game', obj).subscribe(() => {
    //   this.focusInstance(instanceId);
    // });
  };

  launchMobileWeb = (model: CasinoGameModel) => {
    let id = 'game-' + Date.now(); //need a unique window id.
    let instanceId;
    let instance;
    if (this.instanceDoesNotExist(model)) {
      instanceId = this.gameHub.addCasinoGame(model, {
        viewContainer: 'NEWWINDOW',
      });

      let url = `${model.url}&windowID=${instanceId}`;
      let gameName = model.game_name;

      let windowInstance: any = window.open(
        'about:blank',
        id,
        'height=' +
          this.windowSize.height +
          ',width=' +
          this.windowSize.width +
          ',directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no'
      );
      windowInstance.document.write(
        '<style> body{ background:#000033;!important;  position: relative;} div.container { margin: 0;position: absolute;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%);color: white;font-family: arial;font-size: 1.5em; }</style>'
      );
      windowInstance.document.write(
        "<body><div class='container'>LOADING...</div></body>"
      );
      windowInstance.location = url;
      windowInstance.document.title = gameName;

      instance = this.gameHub.getInstance(instanceId);
      instance.windowInstance = windowInstance;
      this.gameHub.updateInstance(instanceId, instance);
    } else {
      instanceId = this.gameHub.instanceExists(model);
    }

    instance = this.gameHub.getInstance(instanceId);
    // instance.windowInstance.focus();
    this.gameHub.focusInstance(instanceId);
    // WHEN the 'NEWWINDOW' is closed
    let closingGameInterval = setInterval(() => {
      if (instance.windowInstance.closed) {
        clearInterval(closingGameInterval);
        this.removeInstance(instanceId);
      }
    }, 100);
  };

  launchMobileApp = (model) => {
    let id = 'game-' + Date.now(); //need a unique window id.
    let instanceId;
    let instance;

    let windowInstance: any;
    if (this.instanceDoesNotExist(model)) {
      instanceId = this.gameHub.addCasinoGame(model, {
        viewContainer: 'NEWWINDOW',
      });
      let url = `${model.url}&windowID=${instanceId}`;
      let gameName = model.game_name;
      windowInstance = this.iab.create(
        url,
        '',
        'location=no,footer=no,hardwareback=no,hidenavigationbuttons=yes,closebuttoncaption=< back,fullscreen=no'
      );
      instance = this.gameHub.getInstance(instanceId);
      instance.windowInstance = windowInstance;
      this.gameHub.updateInstance(instanceId, instance);
    } else {
      instanceId = this.gameHub.instanceExists(model);
    }

    instance = this.gameHub.getInstance(instanceId);

    this.gameHub.focusInstance(instanceId);

    windowInstance.on('exit').subscribe((event) => {
      this.removeInstance(instanceId);
    });

    setTimeout(() => {
      console.log('Set the mobile game interval');
      let interval = setInterval(() => {
        windowInstance.executeScript(
          { code: 'window._game_has_closed' },
          (val) => {
            if (val[0]) {
              this.removeInstance(instanceId);
              clearInterval(interval);
            }
          }
        );
      }, 500);
    }, 3000);
  };

  getFocusedInstance() {
    return this.focusedInstance;
  }

  focusInstance(instance_id) {
    this.gameHub.focusInstance(instance_id);

    // get instance to determine how the game is loaded and how to brin git into focus
    let instance = this.gameHub.getInstance(instance_id);

    console.log('getInstance: ', instance);

    if (instance.viewContainer == 'NEWWINDOW') {
      instance.windowInstance.focus();
    }

    if (instance.viewContainer == 'IFRAME') {
      this.events.publishSomeData({
        event: 'event:gameHub:embedGame',
        data: { instanceId: instance_id },
      });
      this.focusedInstance.next(instance_id);
    }
    this.gameHub.unHighlightInstance(instance_id);
    this.events.publishSomeData({ event: 'event:mygames:unhighlight' });
    this.gameHub.muteGames(instance_id);
  }

  killInstance(instance_id) {
    if (!isUndefined(instance_id)) {
      let instance = this.gameHub.getInstance(instance_id);
      if (!isUndefined(instance)) {
        // opened in a new window
        if (instance.viewContainer == 'NEWWINDOW') {
          // in case the window is closed from x on the window
          if (instance.windowInstance) instance.windowInstance.close();
        }
        if (instance.viewContainer == 'IFRAME') {
          this.events.publishSomeData({
            event: 'event:gameHub:removeGame',
            data: instance_id,
          });
          if (instance.model.getInstanceCategoryType() === 'casino-game') {
            this.events.publishSomeData({
              event: 'event:balanceState',
              data: { show: true },
            });
          }
        }
      }
      // remove from GameHub
      this.gameHub.removeInstance(instance_id);
    }
  }

  removeInstance(instance_id) {
    console.log('REMOVE INSTANCE');
    if (!isUndefined(instance_id)) {
      let instance = this.gameHub.getInstance(instance_id);
      if (!isUndefined(instance)) {
        if (instance.model.getInstanceCategoryType() === 'casino-game') {
          this.events.publishSomeData({
            event: 'event:balanceState',
            data: { show: true },
          });
        }
        // opened in a new window
        if (instance.viewContainer == 'NEWWINDOW') {
          // in case the window is closed from x on the window
          if (instance.windowInstance) instance.windowInstance.close();
        }

        if (instance.viewContainer == 'IFRAME') {
          this.events.publishSomeData({
            event: 'event:gameHub:removeGame',
            data: instance_id,
          });
        }
      }
      // remove from GameHub
      this.gameHub.removeInstance(instance_id);
    }
  }

  removeAllInstances() {
    let instances = this.gameHub.getAllInstances();
    if (Object.keys(instances).length) {
      for (var id in instances) {
        this.removeInstance(id);
      }
    }
    this.focusedInstance.next('');
  }

  killAllInstances() {
    let instances = this.gameHub.getAllInstances();
    if (Object.keys(instances).length) {
      for (var id in instances) {
        this.killInstance(id);
      }
    }
    this.focusedInstance.next('');
  }

  handleGameEvent(event) {
    if (!isUndefined(event.data) && !isUndefined(event.data.name)) {
      // windowID maps to our gameHub instanceID
      let instance_id = event.data.windowID;
      let instance = this.gameHub.getInstance(instance_id);

      switch (event.data.name) {
        case 'registerActivity':
          this.events.publishSomeData({ event: 'event:inactivity:reset' });
          break;
        case 'close_game':
          this.removeInstance(instance_id);

          this.events.unsubscribe('event:geoComply:present');
          this.events.unsubscribe('event:geoComply:dismiss');

          if (
            !isUndefined(event.data.closeCode) &&
            event.data.closeCode == 'CLT_LOGOUT'
          ) {
            Navigator.navigateTo(
              'LogoutPage',
              {},
              { code: 'INACTIVITY', reason: 'GAME_CLT_LOGOUT' }
            );
          }
          break;
        case 'show_buy_chips':
          break;
        case 'action_request':
        case 'bet_request':
        case 'insurance_request':
        case 'post_ante_request':
        case 'post_blind_request':
        case 'player_timeout':
          let focusedInstanceId = this.gameHub.getFocusedInstance();
          if (instance_id !== focusedInstanceId) {
            this.events.publishSomeData({ event: 'event:mygames:highlight' });
            this.gameHub.highlightInstance(instance_id);
          }
          break;
      }
    }
  }

  private canLaunchCasinoGame(model) {
    return (
      this.LIMITS.maxCasinoGames > this.gameHub.totalCasinoGames() ||
      this.instanceExist(model)
    );
  }

  private showReachedLimitDialog(type, limit) {
    this.comService.showAlert(
      `You have reached the max number of ${type} allowed (${limit}). Please close your current game before launching a new game.`,
      'Attention'
    );
  }

  private instanceExist(model) {
    return this.gameHub.instanceExists(model) != -1;
  }

  private instanceDoesNotExist(model) {
    return this.gameHub.instanceExists(model) == -1;
  }

  launchDailySpin(spins) {}

  get windowSize() {
    let wWidth = window.innerWidth;
    let wHeight = window.innerHeight;
    return { width: wWidth, height: wHeight };
  }

  gamesAreRunning() {
    return this.gameHub.totalInstances() >= 1 ? true : false;
  }

  sportsTabWindow: any;
  launchMobileAppSports = () => {
    let url = this.getSportsbookURL();
    let defaultIABOptions =
      'location=no,footer=no,toolbar=no,hardwareback=no,hidenavigationbuttons=yes,fullscreen=yes';
    let options: string = defaultIABOptions;    

    this.notificationApi.unsubscribe();

    this.sportsTabWindow = this.iab.create(url, '', options);

    this.sportsTabWindow.on('exit').subscribe((event) => {
      this.sportsWindowClosedActions();
    });

    //IAB tab gets IP Address with window.BWCIP to fix the exposing ip Address on IAB/mobilesports.html page
    this.sportsTabWindow.on('loadstop').subscribe((e) => {
      var IAB_IP_ADDRESS = this.userSessionProvider.getCustomerIPAddress();
      console.log(IAB_IP_ADDRESS);
      this.sportsTabWindow.executeScript({
        code: `window.BWCIP="${IAB_IP_ADDRESS}";`,
      });
    });

    setTimeout(() => {
      let intervalTab = setInterval(() => {
        this.sportsTabWindow.executeScript(
          { code: 'window.LOAD_TAB' },
          (val) => {
            if (val[0]) {
              if (val[0] == 'CASINO' || 'HOME' || 'LIVE_DEALER') {
                this.events.publishSomeData({event: 'event:tabs:load', tab: val[0]});
              }
              this.sportsTabWindow.close();
              clearInterval(intervalTab);
              clearInterval(intervalLogout);
              clearInterval(intervalExternalLink);
              clearInterval(intervalTimeout);
              clearInterval(intervalAppsFlyer);
            }
          }
        );
      }, 500);
      let intervalLogout = setInterval(() => {
        this.sportsTabWindow.executeScript(
          { code: 'window.LOGOUT_EVENT' },
          (val) => {
            if (val[0]) {
              this.sportsTabWindow.close();
              clearInterval(intervalTab);
              clearInterval(intervalLogout);
              clearInterval(intervalExternalLink);
              clearInterval(intervalTimeout);
              clearInterval(intervalAppsFlyer);
            }
          }
        );
      }, 500);
      let intervalExternalLink = setInterval(() => {
        this.sportsTabWindow.executeScript(
          { code: 'window.EXTERNAL_LINK_URL' },
          (val) => {
            if (val[0]) {
              this.sportsTabWindow.close();
              //Internal Wordpress URL call from sports tab or Specific Self Exclude Call for External URL with Logout
              if (!~val[0].indexOf('http')) {
                if (val[0] == 'SELFEXCLUDEIAB') {
                  this.events.publishSomeData({event: 'event:IAB:SelfExclusion'});
                } else {
                  this.events.publishSomeData({event: 'event:webview:load', data: val[0]});
                }
              } else {                
                this.appLinks.openExternalPage(val[0]);
              }
              clearInterval(intervalTab);
              clearInterval(intervalLogout);
              clearInterval(intervalExternalLink);
              clearInterval(intervalTimeout);
              clearInterval(intervalAppsFlyer);
            }
          }
        );
      }, 500);
      let intervalAppsFlyer = setInterval(() => {
        this.sportsTabWindow.executeScript(
          { code: 'window.APPSFLYER_EVENT' },
          (val) => {
            if (val[0]) {
              this.events.publishSomeData({event: 'event:IAB:AppsFlyer', data: val[0]});
              console.log(
                '-------APPS FLYER Event -------',
                val[0].appsFlyerEvent
              );
              console.log('-------APPS FLYER Value -------', val[0].dataToSend);
              window.plugins.appsFlyer.logEvent(
                val[0].appsFlyerEvent,
                val[0].dataToSend
              );
              this.sportsTabWindow.executeScript({
                code: `window.APPSFLYER_EVENT = null;`,
              });
            }
          }
        );
      }, 500);
      let intervalTimeout = setInterval(() => {
        this.sportsTabWindow.executeScript(
          { code: 'window.INACTIVITY_TIME' },
          (val) => {
            if (val[0] && val[0] !== this.timeIntervalFromIAB) {
              this.events.publishSomeData({event: 'event:inactivity:reset'});
              this.timeIntervalFromIAB = val[0];
            }
          }
        );
      }, 10000);
    }, 2000);

    this.mainProvider.setSportsTabURL('');
  };

  sportsWindowClosedActions() {
    this.sportsTabWindow.close();
    this.notificationApi.startOver();
    setTimeout(() => {
      this.balanceProvider.updateBalance();
    }, 1000);
  }

  getSportsbookURL() {
    const data: any = {};

    if (this.userSessionProvider.isAuthenticated()) {
      data.cid = this.cashierProvider.customer.customerId;
      data.stoken = this.userSessionProvider.getSessionToken();
      data.ipaddress = this.userSessionProvider.getCustomerIPAddress();
      if (this.platform.is('cordova') && this.platform.is('ios')) {
        let iphonewithnotch = this.mainProvider.getIphoneWithNotch();
        if (iphonewithnotch) {
          data.iphonewithnotch = 'true';
        } else {
          data.iphonewithnotch = 'false';
        }
      } else {
        data.iphonewithnotch = 'false';
      }
    }

    data.v = this.config.cache_buster() + new Date().getTime();

    if (this.mainProvider.getSportSubpage()) {
      data.subpage = this.mainProvider.getSportSubpage();
      this.mainProvider.setSportSubpage('');
    }
    data.url = `${this.config.getConfig().FEATURE_SBTECH_URL}/?stoken=logout`;
    data.baseurl = this.config.getConfig().LOBBY_URL;
    //data.baseurl = 'http://10.0.0.32:8100';
    const params = [];
    for (let d in data) {
      params.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
    let query = params.join('&');

    return `${
      this.config.getConfig().LOBBY_URL
    }/assets/static/mobilesports.html?${query}#iab`;
    //return `http://10.0.0.32:8100/assets/static/mobilesports.html?${query}#iab`;
  }
  timeIntervalFromIAB = '';

}
