// Thai
export const locale = {
  lang: 'th',
  data: {
    LEFT_SIDE: {
      EARN_CATEGORY_TITLE: 'รับรายได้',
      REFERRAL: 'แนะนำ',
      VIP: 'VIP Club',
      LANGS: {
        // https://www.omniglot.com/language/names.htm
        ENGLISH: 'English',
        CHINESE: '简体中文',
        PORTUGUES: 'Português',
        THAILIAND: 'ภาษาไทย',
        VIETNAME: 'Tiếng Việt',
        SPANISH: 'Español',
        INDONESIAN: 'Bahasa Indonesia',
        JAPANESE: '日本語'
      },
      INTEREST: "interest",
      REFER_EARN: "Refer & Earn",
      VIP_CLUB: '<span style="color: #ffb636;">VIP</span> Club'
    },
		HEADER: {
      SPORT_BUTTON_TEXT: "กีฬา",
      CASINO_BUTTON_TEXT: "คาสิโน",
      PROMOTIONS: 'โปรโมชั่น',
      SEARCH: "Search",
      SEARCH_PLACEHOLDER: "ค้นหาเกมหรือผู้ให้บริการ",
      SEARCH_PART_GAMES: "เกม",
      SEARCH_PART_PROVIDERS: "ผู้ให้บริการ",
      SEARCH_RESULT: "Search Results",
      SEARCH_NO_RESULT: "ไม่พบผลลัพธ์ในการค้นหา",
      SEARCH_TRY_GAMES: "Recommended",
      RECOMMENDED_GAMES: "Recommended for you",
      LOGIN_BUTTON_TEXT: "เข้าสู่ระบบ",
      REGISTER_BUTTON_TEXT: "ลงทะเบียน",
      BALANCE_INGAME: "ในเกม",
      DEPOSIT_BUTTON_TEXT: "กระเป๋าเงิน",
      Settings: "Settings",
      ALL_GAMES: "All Games",
      SELECT_GAME: "Select Game"
    },
    BALANCES: {
      FIAT: 'เงินตรา',
      CRYPTO: 'เงินตรา',
      DISPLAY_FIAT: 'แสดงในเงินตรา'
    },
    MOBILE_FOOTER: {
      BETSPLUS: {
        SPORT_TAB_TEXT: 'กีฬา',
        CASINO_TAB_TEXT: 'คาสิโน',
        PROMOTIONS_TAB_TEXT: 'โปรโมชั่น',
        MENU_TAB_TEXT: 'เมนู'
      }
    },
    DASHBOARD: {
      CATEGORY_LOBBY_NAME: "ล็อบบี้",
      LAST_PLAYED: "เร็วๆ นี้",
      VIEWALL_BUTTON_TEXT: "ทั้งหมด",
      VIEWALL_BUTTON_TEXT1: "View All",
      ALL_GAMES_COUNT: "ทั้งหมด  {{count}}",
      NO_GAMES: "No game data",
      GAME_PROVIDERS_TITLE: "ผู้ให้บริการเกม",
      RECORDS: {
        TITLE: "บันทึก",
        TABS: {
          MY_BETS_TITLE: "เดิมพันของฉัน",
          ALL_BETS_TITLE: "เดิมพันทั้งหมด",
          RARE_WINS_TITLE: 'ชนะรางวัลใหญ่',
        },
        DATA: {
          BETS_TABLE: {
            COLUMN1: 'เกม',
            COLUMN2: 'ชื่อผู้ใช้',
            COLUMN3: 'เวลา',
            COLUMN4: 'จำนวนเงินเดิมพัน',
            COLUMN5: 'ตัวคูณ',
            COLUMN6: 'การจ่ายเงิน'
          },
          WINS_TABLE: {
            COLUMN1: 'เกม',
            COLUMN2: 'ชื่อผู้ใช้',
            COLUMN3: 'จำนวนเงินเดิมพัน',
            COLUMN4: 'ตัวคูณ',
            COLUMN5: 'การจ่ายเงิน'
          },
          WINS_TABLE_BETSPLUS: {
            COLUMN1: 'อันดับ',
            COLUMN2: 'ชื่อผู้ใช้',
            COLUMN3: 'เงินเดิมพัน',
            COLUMN4: 'รางวัล'
          },
          NO_MY_BETS_TEXT: "ไม่มีเดิมพันของฉัน",
          NO_ALL_BETS_TEXT: "ไม่มีเดิมพันทั้งหมด",
          NO_RARE_WINS_TEXT: "ไม่มีรางวัลใหญ่"
        }
      }
    },
    AUTH: {
      TOPBAR: {
        LOGIN_BUTTON_TEXT: "เข้าสู่ระบบ",
        REGISTER_BUTTON_TEXT: "ลงทะเบียน"
      },
      LOGIN: {
        TITLE: "ยินดีต้อนรับกลับมา!",
        EMAIL: 'อีเมล',
        EMAIL_EMPTY_ERROR: "โปรดกรอกอีเมล",
        EMAIL_INVALID_ERROR: 'โปรดกรอกอีเมลที่ถูกต้อง',
        PHONE_NUMBER: "หมายเลขโทรศัพท์",
        PHONE_EMPTY_ERROR: 'โปรดกรอกหมายเลขโทรศัพท์ของคุณ',
        PHONE_INVALID_ERROR: 'โปรดกรอกหมายเลขโทรศัพท์ที่ถูกต้อง',
        PASSWORD: 'รหัสผ่าน',
        PASSWORD_EMPTY_ERROR: 'จำเป็นต้องกรอกรหัสผ่าน',
        PASSWORD_LENGTH_ERROR: 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร',
        SUBMIT_BUTTON_TEXT: 'เข้าสู่ระบบ',
        DONT_HAVE_ACCOUNT: "ยังไม่มีบัญชี?",
        CREATE_NEW_ONE: "ลงทะเบียน",
        FORGOT_PASSWORD_BUTTON_TEXT: 'ลืมรหัสผ่าน?',
        ERRORS: {
          NOT_EXIST: "อีเมลนี้ไม่ได้ลงทะเบียน",
          WRONG_PASSWORD: 'รหัสผ่านไม่ถูกต้อง',
          TOO_MANY_ATEMP: 'บัญชีของคุณถูกปิดการใช้งานเนื่องจากพยายามเข้าสู่ระบบล้มเหลวมากเกินไป โปรดติดต่อฝ่ายสนับสนุน.',
          TIME_LIMIT: 'เวลาที่กำหนดเกิน.',
          NOT_ACTIVE: "บัญชีของคุณไม่ได้อยู่ในสถานะ 'ใช้งาน'",
          PERMANENT_EXCLUDE: 'บัญชีของคุณถูกหยุดการใช้งานอย่างถาวรในเว็บไซต์นี้',
          SUSPENDED: 'คุณได้ระงับการใช้งานเอง',
          TEMPORARY_SUSPENDED: 'บัญชีของคุณถูกระงับชั่วคราว หากสถานการณ์นี้ไม่ได้แก้ไขภายใน 24 ชั่วโมง โปรดติดต่อฝ่ายสนับสนุน',
          ERROR_OCCURED: 'เกิดข้อผิดพลาดในขณะที่คุณพยายามเข้าสู่ระบบ โปรดลองอีกครั้ง',
          SUCCESS_LOGIN: 'เข้าสู่ระบบสำเร็จ',
          INACTIVITY: 'เซสชันครั้งล่าสุดของคุณหมดอายุเนื่องจากไม่มีกิจกรรม'
        },
        USING_SOCIAL: 'Login using'
      },
      SIGNUP: {
        TITLE: 'ลงทะเบียนที่ {{sitename}}',
        EMAIL: 'Email',
        EMAIL_EMPTY_ERROR: "โปรดกรอกอีเมล",
        EMAIL_INVALID_ERROR: 'โปรดกรอกอีเมลที่ถูกต้อง',
        EMAIL_ALREADY_USED_ERROR: 'อีเมลนี้ถูกใช้งานแล้ว หากคุณกำลังพยายามทำการลงทะเบียนให้เสร็จสิ้น โปรดเข้าสู่ระบบเพื่อดำเนินการต่อ',
        PHONE_NUMBER: "หมายเลขโทรศัพท์",
        PHONE_EMPTY_ERROR: 'โปรดกรอกหมายเลขโทรศัพท์ของคุณ',
        PHONE_INVALID_ERROR: 'โปรดกรอกหมายเลขโทรศัพท์ที่ถูกต้อง',
        PHONE_ALREADY_USED_ERROR: 'That phone number is already in use. If you are trying to complete your registration, please sign in to continue where you left off.',
        VERIFICATION_CODE: 'รหัสยืนยัน',
        VERIFY_BUTTON_TEXT: 'ยืนยัน',
        GET_CODE_TXT: 'รับรหัส',
        CODE_SENT: 'Code Sent',
        RESEND: 'Resend',
        PASSWORD: 'รหัสผ่าน',
        PASSWORD_EMPTY_ERROR: 'จำเป็นต้องกรอกรหัสผ่าน',
        PASSWORD_LENGTH_ERROR: 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร',
        PASSOWRD_PATTERN_ERROR: 'รหัสผ่านควรมีอย่างน้อยหนึ่งตัวเลข, หนึ่งตัวอักษรพิมพ์เล็ก, หนึ่งตัวอักษรพิมพ์ใหญ่, และอักขระพิเศษที่ไม่ใช่ตัวอักษร: $ # ! @ - _',
        REFERRAL_CODE: 'รหัสอ้างอิง/โปรโมโค้ด (ตัวเลือก)',
        REFERAL_CODE_ERROR: 'รหัสโปรโมโค้ดไม่ถูกต้อง',
        TERMS_DESC: 'เมื่อลงทะเบียนคุณยินยอมตาม',
        TERMS_BUTTON_TEXT: 'ข้อกำหนดและเงื่อนไข',
        SUBMIT_BUTTON_TEXT: 'เริ่มต้น',
        ALREADY_HAVE_ACCOUNT: "มีบัญชีอยู่แล้วหรือไม่?",
        SIGNIN_BUTTON_TEXT: 'เข้าสู่ระบบ',
        ERRORS: {
          ALREADY_EXIST: 'อีเมลนี้มีอยู่แล้ว',
          NOT_CREATED: 'ไม่สามารถสร้างบัญชีได้ โปรดติดต่อฝ่ายบริการลูกค้า'
        },
        VERIFY_CODE_REQUIRED: 'จำเป็นต้องกรอกรหัสยืนยัน',
        VERIFICATION_CODE_LENGTH_ERROR: "รหัสยืนยันต้องมี 6 หลัก",
        VERIFICATION_WRONG_CODE: "รหัสยืนยันไม่ถูกต้อง",
        SENT_VERIFY_EMAIL: "เราได้ส่งอีเมลสำหรับการยืนยันแล้ว",
        SENT_VERIFY_SMS: "We've sent the sms code for verification",
        USING_SOCIAL: 'Register using',
        CAPTCHA: "CAPTCHA",
        CAPTCHA_REQUIRED: "CAPTCHA is required",
        CAPTCHA_PATTERN_ERROR: "Please enter valid CAPTCHA",
        CAPTCHA_WRONG: "Invalid CAPTCHA, please try again"
      },
      FORGOT_PASSWORD: {
        TITLE: "ลืมรหัสผ่าน?",
        DESCRIPTION: 'ลืมรหัสผ่าน?',
        EMAIL: 'อีเมล',
        EMAIL_EMPTY_ERROR: "โปรดกรอกอีเมล",
        EMAIL_INVALID_ERROR: 'โปรดกรอกอีเมลที่ถูกต้อง',
        PHONE_NUMBER: "หมายเลขโทรศัพท์",
        PHONE_EMPTY_ERROR: 'โปรดกรอกหมายเลขโทรศัพท์ของคุณ',
        PHONE_INVALID_ERROR: 'โปรดกรอกหมายเลขโทรศัพท์ที่ถูกต้อง',
        VERIFICATION_CODE: 'รหัสยืนยัน',
        VERIFY_BUTTON_TEXT: 'ยืนยัน',
        SUBMIT_BUTTON_TEXT: 'ส่ง',
        SUCCESS_MSG: 'ส่งลิงก์รีเซ็ตรหัสผ่านสำเร็จ',
        VERIFICATION_CODE_LENGTH_ERROR: "Verification code must be 8 characters",
        SUCCESS_RESET_MSG: 'Your password has been reset successfully. Please login with new password again'
      },
      LOGOUT: {
        MULTI_DEVICE: "คุณถูกลงชื่อออกเนื่องจากการเข้าสู่ระบบจากอุปกรณ์หลายเครื่อง",
        INACTIVITY: 'คุณถูกลงชื่อออกเนื่องจากไม่มีกิจกรรม',
        DEFAULT: 'คุณถูกลงชื่อออกเนื่องจากขณะนี้'
      },
      OR: 'หรือ',
      GOOGLE_BUTTON_TEXT: "Google"
    },
    DEPOSIT: {
      CURRENCY_TITLE: 'ฝากเงิน',
      CHOOSE_PAYMENT_TITLE: 'เลือกวิธีการชำระเงิน',
      AMOUNT: 'จำนวน',
      INSTANT: 'ทันที',
      MIN: 'ขั้นต่ำ',
      MAX: 'สูงสุด',
      SUBMIT_BUTTON_TEXT: 'ฝากเงิน',
      NEED_HELP: 'ต้องการความช่วยเหลือ?',
      CONTACT_SUPPORT: 'ติดต่อฝ่ายสนับสนุนสดของเรา',
      CRYPTO_DEPOSIT_TITLE: 'ฝากเงิน',
      CRYPTO_DEPOSIT_DESCRIPTION: 'นี่คือที่อยู่ฝากเงินส่วนตัวของคุณ การฝากเงินใด ๆ ที่คุณทำจะปรากฏในยอดเงินของคุณหลังจากการยืนยันบล็อกเชน เราจะแจ้งให้คุณทราบผ่านทางอีเมลเมื่อการฝากเงินเข้ามา.',
      MINIMUM_DEPOSIT_AMOUNT: 'จำนวนเงินฝากขั้นต่ำคือ {{amount}} {{currency}}',
      SEND_MONEY_TO: 'ส่งจำนวนเงินใด ๆ ของ {{currency}} (รวมค่าธรรมเนียมขุดเหมือง) ไปที่:',
      NO_PAYMENT_METHOD: 'ไม่มีวิธีการชำระเงินที่ใช้ได้',
      ADDRESS_COPIED: "Copy and Deposit",
      CLUB_PAY_TYPE: "Pay Type"
    },
    WITHDRAW: {
      CLUB_PAY_TYPE: "Pay Type",
      CURRENCY_TITLE: 'ถอนเงินสกุลเงิน',
      CHOOSE_PAYMENT_TITLE: 'เลือกวิธีการชำระเงิน',
      INFO_TITLE: 'ข้อมูลการถอนเงิน',
      BANK_CODE_TITLE: 'รหัสผู้ออกบัตร',
      BANK_NAME_TITLE: 'ชื่อผู้รับเงิน',
      ACCOUNT_TYPE: "Account Type",
      BANK_ACCOUNT_TITLE: 'หมายเลขบัญชีผู้รับเงิน',
      AMOUNT: 'จำนวน',
      INSTANT: 'ทันที',
      MIN: 'ขั้นต่ำ',
      MAX: 'สูงสุด',
      SUBMIT_BUTTON_TEXT: 'ถอนเงิน',
      NEED_HELP: 'ต้องการความช่วยเหลือ?',
      CONTACT_SUPPORT: 'ติดต่อฝ่ายสนับสนุนสดของเรา',
      EXIST_ACCOUNT_TITLE: 'บัญชีที่มีอยู่',
      ADDRESS: 'ที่อยู่',
      WITHDRAW_FEE_ESTIMATED: 'ค่าธรรมเนียมการถอนเงินโดยประมาณคือ {{amount}} {{currency}}',
      MINIMUM_WITHDRAW_AMOUNT: 'จำนวนเงินถอนขั้นต่ำคือ {{amount}} {{currency}}',
      WITHDRAW_DEDUCT_DESC: 'การถอนเงินจะหักค่าบริการ 10%',
      COMPLETE_EMAIL_VERIFY: 'เพื่อเหตุผลด้านความปลอดภัย กรุณาทำการยืนยันอีเมลก่อนถอนเงิน',
      VERIFY_EMAIL_BUTTON: 'ยืนยันที่อยู่อีเมล',
      COMPLETE_PHONE_VERIFY: 'For safety reasons, complete phone verification before withdrawal',
      VERIFY_PHONE_BUTTON: 'Verify Phone Address',
      COMPLETE_EMAIL_PHONE_VERIFY: 'For safety reasons, complete email and phone verification before withdrawal',
      VERIFY_EMAIL_PHONE_BUTTON: 'Verify Now',
      COMPLETE_PROFILE: 'เพื่อเหตุผลด้านความปลอดภัย กรุณากรอกข้อมูลโปรไฟล์ของคุณก่อนถอนเงิน',
      ACCOUNT_BUTTON: 'บัญชี',
      WITHDRAW_SUCCESS: 'การถอนเงินถูกส่งเรียบร้อยแล้ว โปรดตรวจสอบบัญชีถอนเงินของคุณอย่างต่อเนื่อง ขอบคุณ',
      HOME_BUTTON: 'หน้าหลัก',
      RESTRICTED_BALANCE: 'คุณมียอดเงินถอน {{currency}} {{amount}} ที่ถูกจำกัด',
      RESTRICTED_BALANCE_VIEW: 'ดู',
      NO_PAYMENT_METHOD: 'ไม่มีวิธีการชำระเงินที่ใช้ได้',
      IFSC_TITLE: 'IFSC Number',
      INVALID_AMOUNT_ERROR: {
        EMPTY_AMOUNT: 'Please input amount',
        LESS_ZERO: 'Amount cannot be less than zero',
        LESS_MIN: 'Amount is less than minimum value',
        GREATER_MAX: 'Exceed max value',
        GREATER_BALANCE: 'Exceed balance',
        GREATER_AVAILABLE: 'Insufficient Balance'
      },
      ADDRESS_REQUIRED: "Address is required",
      ADDRESS_PATTERM_ERROR: "Address is not correct"
    },
    GAME_GALLEY: {
      NO_GAME_DATA: 'ไม่มีเกมในรายการของคุณ โปรดคลิกปุ่มหน้าหลักและเล่นเกมหนึ่งในเกมมากมาย',
      DISPLAY_COUNT_GAMES: 'กำลังแสดง {{showCount}} จาก {{totalCount}} เกม'
    },
    GAME: {
      LOADING: 'กำลังโหลด',
      PLAY_BUTTON_WITHOUT_LOGIN: 'เข้าสู่ระบบ',
      PLAY_BUTTON_WITH_LOGIN: 'เล่น',
      DEMO_PLAY_BUTTON: "Demo",
      RTP: 'RTP',
      RECENT_BIGGEST_WIN: 'รางวัลที่ชนะล่าสุด',
     MY_GAMES_TITLE: 'เกมของฉัน'
    },
    ACTIVE_ACCOUNT: {
      TITLE: 'ยืนยันอีเมล',
      EMAIL_VERIFY_DESCRIPTION: 'โปรดเข้าสู่ระบบอีเมลของคุณ คลิกลิงก์ในอีเมลเพื่อทำการยืนยัน',
      GET_CODE_TEXT: 'Send Code',
      RESEND_BUTTON_TEXT: 'ส่งอีกครั้ง',
      SUBMIT_BUTTON_TEXT: 'Submit',
      NOT_RECEIVE_TXT: "Didn't receive the code?",
      CODE: 'Code',
      CODE_INPUT_PLACEHOLDER: 'Fill in the code here'
    },
    EMAIL_VERIFY: {
      DISPLAY_INFO: 'กำลังยืนยันตอนนี้ โปรดรอสักครู่...',
      ERROR_TITLE: 'ขออภัย มีปัญหาบางอย่าง.',
      VERIFY_ERROR: 'ไม่พบรหัสอีเมล',
      SUCCESS_TITLE: 'สำเร็จ!',
      VERIFY_SUCCESS: 'อีเมลของคุณได้รับการยืนยันเรียบร้อยแล้ว'
    },
    BONUSES: {
      BONUSES_TITLE: 'โปรโมชั่น',
      BONUS_TITLE: 'โบนัส',
      NO_BONUSES_DATA: 'ไม่มีโปรโมชั่นในขณะนี้',
      CHECK_LATER: 'ตรวจสอบในภายหลัง!',
      MORE_DETAIL_BUTTON_TEXT: 'รายละเอียดเพิ่มเติม',
      APPLY_BUTTON_TEXT: 'สมัครเลย',
      TAB1: 'Casino',
      TAB2: 'Sport',
      ENDS_AT: 'Ends :',
      EVENT_ENDED: 'Event Ended'
    },
    EMPTY_PAGE: {
      NOT_FOUND: 'อุ๊ปส์.. ไม่พบหน้า.',
      REFRESH_OR_GOHOME: 'โปรดรีเฟรชหน้าหรือคลิกด้านล่างเพื่อกลับไปที่หน้าหลัก',
      ACTION_BUTTON_TEXT: 'หน้าหลัก'
    },
    FOOTBALL_PAGE: {
      TITLE: 'กีฬาา',
      DESCRIPTION: 'สโมสรกีฬาเร็ว ๆ นี้ โปรดตรวจสอบในภายหลัง!',
      ACTION_BUTTON_TEXT: 'หน้าหลัก'
    },
    LOGOUT_CONFIRM: {
      TITLE: 'ออกจากระบบ',
      DESCRIPTION: 'อย่าลืมตรวจสอบโปรโมชั่นมากมายของเราทั้งในส่วนคาสิโนและกีฬาก่อนออกจากเว็บไซต์!',
      BUTTON_TEXT: 'ออกจากระบบ'
    },
    ACCOUNT_INFO: {
      UID: 'UID',
      USERNAME_TITLE: 'ชื่อผู้ใช้',
      EMAIL_TITLE: 'อีเมล',
      PHONE_TITLE: 'หมายเลขโทรศัพท์',
      PHONE_PLACEHOLDER: 'Add Phone number',
      PASSWORD_TITLE: 'รหัสผ่าน',
      EDIT_BUTTON_TEXT: 'แก้ไข',
      VERIFY_BUTTON_TEXT: 'ยืนยัน',
      ADD_EMAIL: 'เพิ่มอีเมล',
      COPY_BUTTON_TXT: 'Copy',
      SUMMARY_TEXT: ''
    },
    ADD_EMAIL: {
      TITLE: 'เพิ่มอีเมล',
      DESCRIPTION: 'เพิ่มอีเมลของคุณเพื่อความปลอดภัยของบัญชี',
      EMAIL_PLACEHOLDER: 'อีเมล',
      VERIFICATION_CODE: 'รหัสยืนยัน',
      VERIFY_BUTTON_TEXT: 'ยืนยัน',
      SUBMIT_BUTTON_TEXT: 'บันทึก'
    },
    CHANGE_PASSWORD: {
      TITLE: 'เปลี่ยนรหัสผ่าน',
      CURRENT_PW_PLACEHOLDER: 'รหัสผ่านปัจจุบัน',
      CURRENT_PW_REQUIRED: 'โปรดป้อนรหัสผ่านปัจจุบันของคุณ',
      CURRENT_PW_WRONG: 'รหัสผ่านปัจจุบันไม่ถูกต้อง',
      NEW_PW_PLACEHOLDER: 'รหัสผ่านใหม่',
      NEW_PW_REQUIRED: 'โปรดป้อนรหัสผ่านใหม่',
      NEW_PW_LENGTH_ERROR: 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร',
      CONFIRM_PW_PLACEHOLDER: 'ยืนยันรหัสผ่านใหม่',
      CONFIRM_PW_REQUIRED: 'โปรดยืนยันรหัสผ่านใหม่ของคุณ',
      NEW_PW_NOT_MATCH: 'รหัสผ่านที่ป้อนไม่ตรงกัน',
      NEW_PW_NOT_CURRENT: 'รหัสผ่านใหม่ไม่สามารถตรงกับรหัสผ่านปัจจุบันของคุณ',
      SUBMIT_BUTTON_TEXT: 'บันทึก',
      MESSAGES: {
        SUCCESS: 'รหัสผ่านของคุณได้รับการเปลี่ยนแปลง',
        WRONG_CURRENT_PASSWORD: 'คุณป้อนรหัสผ่านปัจจุบันไม่ถูกต้อง',
        NEW_NOT_MATCH_CURRENT: 'รหัสผ่านใหม่ของคุณไม่สามารถตรงกับรหัสผ่านปัจจุบันของคุณ'
      }
    },
    EDIT_USERNAME: {
      TITLE: 'แก้ไขชื่อผู้ใช้',
      PLACEHOLDER_TEXT: 'ชื่อผู้ใช้',
      SUBMIT_BUTTON_TEXT: 'บันทึก',
      MESSAGES: {
        USERNAME_REQUIRED: 'โปรดป้อนชื่อผู้ใช้',
        USERNAME_LENGTH: 'ชื่อผู้ใช้ต้องมีความยาวมากกว่า 3 ตัวอักษร'
      }
    },
    RESET_PASSWORD: {
      TITLE: 'รีเซ็ตรหัสผ่าน',
      DESCRIPTION: 'รีเซ็ตรหัสผ่าน',
      PASSWORD_PLACEHOLDER: 'รหัสผ่าน',
      PW_LENGTH_ERROR: 'รหัสผ่านต้องมีอย่างน้อย 6 ตัวอักษร',
      PW_REQUIRED: 'โปรดป้อนรหัสผ่านใหม่',
      PW_PATTERN_ERROR: 'รหัสผ่านต้องรวมตัวเลข ตัวอักษรตัวเล็ก ตัวอักษรใหญ่และสัญลักษณ์พิเศษ',
      CONFIRM_PW_PLACEHOLDER: 'ยืนยันรหัสผ่านใหม่',
      CONFIRM_PW_REQUIRED: 'โปรดยืนยันรหัสผ่านใหม่ของคุณ',
      CONFIRM_PW_MISMATCH: 'รหัสผ่านที่ป้อนไม่ตรงกัน',
      NEW_PW_NOT_CURRENT: 'รหัสผ่านใหม่ของคุณไม่สามารถตรงกับรหัสผ่านปัจจุบันของคุณ',
      SUBMIT_BUTTON_TEXT: 'รีเซ็ต',
      MESSAGES: {
        TOKEN_INVALID: 'โทเค็นที่ให้มาไม่ถูกต้อง',
        TOKEN_EXPIRED: 'โทเค็นที่ให้มาหมดอายุแล้ว',
        SUCCESS: 'รหัสผ่านของคุณได้รับการรีเซ็ตแล้ว โปรดเข้าสู่ระบบโดยใช้รหัสผ่านใหม่',
        FAILD: 'การรีเซ็ตรหัสผ่านล้มเหลว โปรดลองอีกครั้ง'
      }
    },
    UPDATE_PHONE: {
      TITLE: 'แก้ไขหมายเลขโทรศัพท์',
      DESCRIPTION: 'เพิ่มหมายเลขโทรศัพท์ของคุณเพื่อความปลอดภัยของบัญชี',
      PLACEHOLDER: 'เฉพาะตัวเลข',
      PHONE_REQUIRED: 'โปรดป้อนหมายเลขโทรศัพท์ของคุณ',
      PHONE_INVALID: 'โปรดป้อนหมายเลขโทรศัพท์ที่ถูกต้อง',
      VERIFY_CODE: 'รหัสยืนยัน',
      VERIFY_BUTTON_TEXT: 'ยืนยัน',
      SUBMIT_BUTTON_TEXT: 'ส่ง',
      MESSAGES: {
        FAILD: 'มีปัญหาเกี่ยวกับการอัปเดตหมายเลขโทรศัพท์ โปรดติดต่อฝ่ายบริการลูกค้า.'
      }
    },
    ACTIVE_BONUSES: {
      TITLE: 'โบนัสที่ใช้งาน',
      NO_DATA: "คุณยังไม่ได้รับโบนัสที่ใช้งานอยู่ในขณะนี้",
      SHOW_RESULT: 'คลิกที่โบนัสเพื่อดูรายละเอียดเต็มรูปแบบเกี่ยวกับมัน',
      BONUS_ACCOUNT: 'บัญชีโบนัส',
      DESC1: "บางครั้งคุณอาจได้รับ 'โบนัส' เป็นรางวัลพิเศษสำหรับการกระทำบางอย่าง เช่น การฝากเงิน",
      DESC2: "‘ยอดรวมจำกัด’ (เงินฝากของคุณ + จำนวนโบนัส) ถูกจำกัดให้เดิมพันเฉพาะในเกมเท่านั้น เมื่อข้อกำหนดการเดิมพันถูกทำครบแล้ว ยอดรวมจำกัดจะถูกปลดล็อกและเป็นส่วนหนึ่งของยอดเงินจริงของคุณ",
      TABLE_HEADER: {
        COLUMN1: 'ยอดโบนัสทั้งหมด',
        COLUMN2: 'ยอดโบนัสที่เหลืออยู่',
        COLUMN3: 'รหัสอ้างอิง',
        COLUMN4: 'วันที่เริ่ม',
        COLUMN5: 'วันที่หมดอายุ',
        COLUMN6: 'สถานะ',
        COLUMN7: 'ยอดจำกัด'
      }
    },
    PAST_BONUSES: {
      TITLE: 'โบนัสที่ผ่านมา',
      DESCRIPTION: 'ด้านล่างนี้คุณสามารถดูโบนัสที่คุณได้รับในอดีต โปรดเลือกช่วงวันที่:',
      FROM_DATE_LABEL: 'เริ่มต้น',
      INPUT_DATE_PLACEHOLDER: 'เลือกวันที่',
      TO_DATE_LABEL: 'สิ้นสุด',
      SUBMIT_BUTTON_TEXT: 'ส่ง',
      NO_RESULT: 'ไม่พบข้อมูล',
      SHOW_RESULT: 'เลือกโบนัสใดก็ได้ด้านล่างเพื่อดูรายละเอียดเพิ่มเติม:',
      TABLE_HEADER: {
        COLUMN1: 'รหัสอ้างอิง',
        COLUMN2: 'ยอดโบนัสทั้งหมด',
        COLUMN3: 'วันที่เริ่ม',
        COLUMN4: 'วันหมดอายุ',
        COLUMN5: 'สถานะ',
      }
    },
    BONUS_DETAIL: {
      TITLE: 'โบนัส - รายละเอียด',
      TERMS_SERVICE: 'ข้อกำหนดและเงื่อนไข',
      BONUS: 'โบนัส',
      START_DATE: 'วันที่เริ่ม',
      EXPIRED_DATE: 'วันที่หมดอายุ',
      PROGRESS: 'ความคืบหน้า',
      CASINO: 'คาสิโน',
      SPORTS: 'กีฬา',
      LIVE: 'Live',
      VALID_GAMES: 'เกมที่ถูกต้อง',
      ROLLOVER: 'ข้อกำหนดการเดิมพัน',
      WAGERS_REMAIN: 'จำนวนการเดิมพันที่เหลืออยู่',
      RESTRICT_AMOUNT: 'ยอดจำกัด',
      WINNINGS: 'รางวัล',
      DEPOSIT: 'ฝากเงิน',
      TOTAL_RESTRICT_AMOUNT: 'ยอดจำกัดรวมทั้งหมด',
      FORFEIT_BUTTON_TEXT: 'ยกเลิกโบนัสของฉัน',
      FORFEIT_DESC1: "หากคุณยกเลิกโบนัสนี้ เราจะลบ:",
      FORFEIT_DESC2: '{{currency}} {{winning}} จากยอดรางวัลที่ถูกจำกัด.',
      FORFEIT_DESC3: '{{currency}} {{bonus}} จากเงินโบนัสที่ถูกจำกัด.',
      FORFEIT_DESC4: 'นี้จะทิ้งคุณด้วย {{currency}} {{deposit}} จากเงินฝากของคุณ',
      FORFEIT_DESC5: 'คุณแน่ใจหรือไม่ว่าคุณต้องการยกเลิกโบนัสนี้?',
      CANCEL_BUTTON_TEXT: 'ยกเลิก',
      CONFIRM_BUTTON_TEXT: 'ใช่, ยกเลิกโบนัส',
      ERRORS: {
        CLOSE_OPENED_GAMES: 'โปรดปิดเกมที่เปิดอยู่ทั้งหมดเพื่อยกเลิกโบนัส.',
        RESOLVE_SPORTS_GAME: 'เหตุการณ์กีฬาสงวนเงินจากโบนัสนี้ - โปรดรอการแก้ไขให้เสร็จสมบูรณ์ก่อน',
        RESOLVE_SAVED_GAME: 'เกมที่บันทึกสงวนเงินจากโบนัสนี้ - โปรดแก้ไขก่อน'
      },
      APPLY_BUTTON_TXT: {
        DEFAULT: 'Deposit Now',
        LABEL1: 'Apply Now',
        LABEL2: 'Deposit Now',
        LABEL3: 'Play Now',
        LABEL4: 'Redeem Now',
        LABEL5: 'More Detail'
      }
    },
    REDEEM_BONUS: {
      TITLE: 'Promo Code',
      DESCRIPTION: "Join {{name}} community and receive a promo code!",
      INPUT_LABEL: 'รหัสโบนัส:',
      INPUT_PLACEHOLDER: 'Promo Code',
      SUBMIT_BUTTON_TEXT: 'แลกรหัส',
      FAILD: 'รหัสโบนัสไม่ถูกต้องหรือหมดอายุ โปรดลองใช้รหัสอื่น',
      NOVALID: "Promo code not available",
      SUCCESS: "Promo Code has been used successfully",
      SUCCESS_BUTTON_TXT: "Finish"
    },
    REFER_FRIEND: {
      TITLE: 'แนะนำเพื่อน',
      SUBTITLE1: 'เชิญเพื่อน, รับเงิน',
      DESCRIPTION1: 'ได้รับเงินคงคลังสำหรับทุกคนที่คุณแนะนำให้มาที่ {{website}}',
      TOTAL_REFERRED: 'รวมผู้ใช้ที่คุณแนะนำ',
      SUBTITLE2: 'แชร์รหัสของคุณ',
      DESCRIPTION2: 'รหัสของคุณ'
    },
    SPORTS_PAGE: {
      LOADING: 'กำลังโหลด'
    },
    GAME_HISTORY: {
      TITLE: "ประวัติเกม",
      TABLE: {
        COLUMN1: "เกม",
        COLUMN2: "ผลเดิมพัน",
        COLUMN3: "เวลา",
        COLUMN4: "ยอดเดิมพัน",
        COLUMN5: "ตัวคูณ",
        COLUMN6: "การจ่ายเงิน"
      },
      NO_HISTORY: "ไม่มีประวัติเกม",
      SHOW_DATA_DESC: 'มีบันทึกเกมล่าสุด 50 รายการเท่านั้น',
      TOTAL: "รวม",
      WIN: 'ชนะ',
      LOSS: 'แพ้',
      DRAW: 'เสมอ',
      UNSETTLED: 'UNSETTLED',
      MOBILE: {
        CASH: 'สล็อต',
        BET_AMOUNT: 'ยอดเดิมพัน',
        MULTIPLIER: 'ตัวคูณ',
        PAYOUT: 'การจ่ายเงิน'
      },
      SHAREING: {
        SUCCESS: {
          TITLE1: 'More than just wins,',
          TITLE2: 'earn extra tokens and dividends here.',
          BET_AMOUNT: 'by betting',
          MULTIPLIER: "Multiplier {{value}}x",
          PLACED_USER: 'Placed By: {{username}}',
          REFERRAL_CODE: 'Referral Code',
          CTA_SENTENCE2: 'Share NOW & win lottery rewards',
          DOWNLOAD_BTN: 'Download',
          SHARE_BTN: 'Share'
        },
        FAILD: {
          TITLE: 'Fail To Share',
          ALREADY_SHARED_GAME_LOG: 'This win has been shared by other people.',
          ABOVE_ONE_MULTIPLIER: 'Only transactions that are above 1x Multiplier can be shared.',
          EXCEEDED_DAILY: "You have reached your daily limit."
        }
      }
    },
    LEADERBOARD: {
      TITLE: 'ตารางคะแนนสูงสุด',
      TABLE: {
        COLUMN1: "",
        COLUMN2: "",
        COLUMN3: "ผู้เล่น",
        COLUMN4: "ยอด $FUN Token รวม",
        COLUMN5: "รางวัล"
      },
      NO_DATA: 'ไม่มีข้อมูลคะแนนสูงสุด'
    },
    NEW_BANK: {
      TITLE: 'ข้อมูลธนาคารใหม่',
      PAYMENT_TYPE: 'ประเภทการชำระเงิน',
      SELECT_PAYMENT_TYPE: 'เลือกประเภทการชำระเงิน',
      BANK_LIST: 'รายชื่อธนาคาร',
      SELECT_BANK: 'เลือกธนาคาร',
      NAME: 'ชื่อเต็ม',
      ACCOUNT_NUMBER: 'หมายเลขบัญชี',
      ACCOUNT_NUMBER_DESC: '1 ถึง 17 หลักเท่านั้น',
      SUBMIT_BUTTON_TXT: 'ส่ง',
      ADD_NEW_BUTTON_TXT: 'เพิ่มใหม่',
      CLOSE_BUTTON_TXT: 'ปิด'
    },
    PAYMENTS: {
      DEPOSIT_TXT: 'ฝากเงิน',
      WITHDRAW_TXT: 'ถอนเงิน'
    },
    PROFILE_INFO: {
      TITLE: 'ข้อมูลส่วนบุคคล',
      FIRST_NAME: 'ชื่อจริง',
      FIRST_NAME_REQURIED_ERROR: 'โปรดกรอกชื่อจริงตามเอกสารประจำตัว',
      FIRST_NAME_PATTERN_ERROR: "ชื่อจริงสามารถประกอบด้วยตัวอักษรเท่านั้น พร้อมเว้นวรรค ขีดล่าง และจุด โปรดกรอกอย่างน้อย 2 ตัวอักษรและสูงสุด 20 ตัวอักษร",
      LAST_NAME: 'นามสกุล',
      LAST_NAME_REQUIRED_ERROR: 'โปรดกรอกนามสกุลตามเอกสารประจำตัว',
      LAST_NAME_PATTERN_ERROR: "นามสกุลสามารถประกอบด้วยตัวอักษรเท่านั้น พร้อมเว้นวรรค ขีดล่าง และจุด โปรดกรอกอย่างน้อย 2 ตัวอักษรและสูงสุด 20 ตัวอักษร",
      BIRTHDAY: {
        TITLE: 'วันเกิด',
        YEAR: 'ปี',
        MONTH: 'เดือน',
        DAY: 'วัน',
        ERROR: 'โปรดกรอกวันเกิดที่ถูกต้อง'
      },
      ADDRESS: {
        STREET: 'ที่อยู่',
        STREET_REQURIED_ERROR: 'โปรดกรอกที่อยู่ของคุณ',
        PO_BOX_ERROR: 'ไม่สามารถใช้กล่องไปรษณีย์ได้',
        COUNTRY: 'ประเทศ',
        STATE: 'รัฐ',
        CITY: 'เมือง',
        CITY_REQURIED_ERROR: 'โปรดกรอกเมืองของคุณ',
        CITY_PATTERN_ERROR: 'โปรดกรอกชื่อเมืองที่ถูกต้อง ชื่อเมืองไม่ควรมีตัวเลข',
        ZIPCODE: 'รหัสไปรษณีย์',
        ZIPCODE_REQUIRED_ERROR: 'โปรดกรอกรหัสไปรษณีย์ของคุณ',
        ZIPCODE_PATTERN_ERROR: 'โปรดกรอกตัวอักษรและตัวเลขเท่านั้น ไม่มีช่องว่างหรืออักขระพิเศษ',
        ZIPCODE_MINLENGTH_ERROR: 'Zipcode must be 5 charactors at least'
      },
      SUBMIT_BUTTON_TXT: 'ส่ง',
      CHOOSE_FILE: 'Proof of Identity/Choose file',
      FILE_NOTE: "*Document accepted : Passport, driver’s license or ID card.",
      ALERT: {
        SUCCESS: {
          TITLE: 'Verification',
          MSG: 'Successfully updated'
        }
      }
    },
    PROVIDERS: {
      TITLE: 'ผู้ให้บริการเกม',
      GAMES_LENGTH: '{{count}} เกม',
      GAMES: 'เกม',
      CURRENT_DISPLAYING: 'แสดง {{count}} จาก {{total}} ผู้ให้บริการ'
    },
    TERMS: {
      TITLE: 'ข้อกำหนดและเงื่อนไข',
      PRINT_BUTTON_TXT: 'พิมพ์',
      DOWNLOAD_BUTTON_TXT: 'ดาวน์โหลด'
    },
    TRANSACTIONS: {
      TITLE: 'ธุรกรรม',
      DEPOSIT_BUTTON_TXT: 'ฝากเงิน',
      WITHDRAW_BUTTON_TXT: 'ถอนเงิน',
      BONUS_BUTTON_TXT: 'โบนัส',
      NO: 'ไม่',
      SHOW_DATA_DESC: 'มีข้อมูลธุรกรรมล่าสุดเพียง 50 รายการเท่านั้น',
      TOTAL: 'รวม',
      deposit: 'ฝากเงิน',
      withdraw: 'ถอนเงิน',
      bonuses: 'โบนัส',
      STATE: {
        CONFIRMED: "Confirmed",
        UNCONFIRMED: "Unconfirmed",
        UNSUCCESSFUL: "Unsuccessful"
      },
      DEPOSIT_TABLE: {
        COLUMN1: 'รหัสคำสั่ง',
        COLUMN2: 'เวลา',
        COLUMN3: 'สถานะ',
        COLUMN4: 'สกุลเงิน',
        COLUMN5: 'ฝากเงิน',
        COLUMN6: 'ยอดคงเหลือก่อน',
        COLUMN7: 'ยอดคงเหลือหลัง',
        COLUMN8: 'Amount'
      },
      WITHDRAW_TABLE: {
        COLUMN1: 'รหัสคำสั่ง',
        COLUMN2: 'เวลา',
        COLUMN3: 'สถานะ',
        COLUMN4: 'สกุลเงิน',
        COLUMN5: 'ถอนเงิน',
        COLUMN6: 'ยอดคงเหลือก่อน',
        COLUMN7: 'ยอดคงเหลือหลัง',
        COLUMN8: 'Amount'
      },
      BONUSES_TABLE: {
        COLUMN1: 'ประเภท',
        COLUMN2: 'รหัสคำสั่ง',
        COLUMN3: 'เวลา',
        COLUMN4: 'จำนวนโบนัส',
        COLUMN5: 'Bonus Name'
      }
    },
    USER_MENU: {
      WALLET: 'กระเป๋าเงิน',
      TRANSACTIONS: 'ธุรกรรม',
      GAME_HIISTORY: 'ประวัติเกม',
      KYC_INFO: 'KYC',
      VIP: 'สโมสร VIP',
      REDEEM_COUPON: 'แลกรหัสคูปอง',
      REFERRAL: 'แนะนำเพื่อน',
      PROMOTIONS: 'โปรโมชั่น',
      ACTIVE_BONUS: 'โบนัสที่กำลังใช้งาน',
      PAST_BONUS: 'โบนัสที่ผ่านมา',
      LIVE_SUPPORT: 'สนับสนุนสด',
      ACCOUNT: 'โปรไฟล์'
    },
    STEP_COMPONENT: {
      STEP_TXT: 'ขั้นตอน'
    },
    PLAYER_VIP: {
      MY_DATA: {
        VIP_PROGRESS: 'ความคืบหน้า VIP ของคุณ',
        TOOLTIP: 'ทุกครั้งที่คุณเดิมพัน $1 คุณจะได้รับ 1 XP ยิ่งคุณเล่นมากเท่าไรคุณก็จะเข้าถึงอันดับสูงสุดไว้เร็วขึ้น',
        RAKEBACK: 'คืนค่าการทำเหมือง',
        RANK_UP_BUTTON_TXT: 'เพิ่มอันดับ',
        TOTAL_CLAIMED: 'รวมคืนค่าการทำเหมืองที่เรียกรับแล้ว',
        CLAIM_BUTTON_TXT: 'เรียกรับ',
        REMAIN_UNTIL_NEXT: '{{xp}} XP จนถึง VIP {{level}} ถัดไป'
      },
      BETSPLUS: {
        LEVEL: "Level",
        REQUIRED_XP: 'Required EXP {{amount}} {{currency}}',
        LEVELUP_DESC: 'Get rewarded every time you fill the progress bar. Leveling up entitles you to bigger and better rewards!',
        WITHDRAWN_AMOUNT: 'Amount that can be withdrawn',
        TOTAL_REWARDS: 'Total Rewards Available',
        EXCHANGE_BUTTON_TXT: 'Exchange',
        CLAIM_BUTTON_TXT: 'Claim',
        REWARD_REDEMPTION: "Reward Redemption",
        TOTAL_CLAIMED: 'Total Rewards Claimed',
        REWARD: "Reward"
      }
    },
    REFERRALS: {
      MY_REFERRALS: {
        TOTAL_COMMISSION_EARNING: 'รวมรายได้ค่าคอมมิชชันจากการอ้างอิง',
        REFERRED_YOU: "ใครได้แนะนำคุณ",
        AMOUNT_REFERRED: 'จำนวนผู้ใช้ที่คุณได้แนะนำ',
        NUMBER_OF_USERS: "Number of users"
      },
      BETSPLUS: {
        TITLE: 'Millionaire Project',
        DESCR1: 'GET UP TO <span style="color: #FFC83A;">${{value}}</span>',
        DESCR2: 'AND UP TO <span style="color: #FFC83A;">{{percent}}%</span> COMMISSION',
        HOWTOWORK: 'how it works',
        TOOLTIP_DETAIL: 'Step 1: Share referral code to friends and social media. <br> Step 2: Guide them to get their deposit bonus. <br> Step 3: Recommend some great games for them. <br> Step 4: Claim and spend your referral rewards. <br> Step 5: Repeat the steps!',
        INVITE_SECT: {
          TITLE: 'Invite Users',
          SHARES: 'Shares',
          INVITEES: 'Invitees',
          INVITE_LINK: 'Invite link',
          INVITE_QR_CODE: 'Invite QR code',
          QUICK_SHARE: 'Quick share',
          DOWNLOAD_BTN: 'Download'
        },
        DATA_CENTER: {
          TITLE: 'Data Center',
          TOTAL_REVENUE: 'Total revenue',
          INVITATION: 'Invitation',
          WAGER: 'Wager',
          ACHIEVEMENT: 'Achievement',
          VIP: 'VIP',
          MORE_BTN: 'More'
        },
        REWARD_SECT: {
          TITLE: 'Reward Redemption',
          AMOUNT_WITHDRAWN: 'Amount that can be claimed',
          EXCHANGE_BUTTON_TXT: 'Exchange',
          CLAIM_BUTTON_TXT: 'Claim',
          TOTAL_REWARD: 'Total Rewards Available : {{value}}'
        },
        ACTIVITY_RULES: {
          TITLE: 'Activity Rules',
          SEE_MORE: 'See More',
          DATA1: {
            LABEL: 'INVITATION BONUS',
            TEXT: 'Every time you invite a friend whose deposit reaches 20 USD, you will get, 5 USD rewards.'
          },
          DATA2: {
            LABEL: 'BETTING COMMISSION',
            TEXT: 'Bonus Rules: All players at the "Promoter" level receive a percentage of the platform advantage bonus for every bet.'
          },
          DATA3: {
            LABEL: 'ACHIEVEMENT BONUS',
            TEXT: 'If the cumulative number of invitations meets the conditions, you will receive additional achievement rewards. <br> The more people you invite, the higher the reward.'
          },
          DATA4: {
            LABEL: 'VIP LEVEL-UP BONUS',
            TEXT: 'Every user you invite reaches the specified level in the game, and you will get additional VIP level-up bonus. <br> USD is a special currency launched by Platform . You can …'
          }
        },
        AMONG_BEST: 'Among the best',
        LIVE_REWARD: 'Live Rewards',
        QA: {
          TITLE: 'Q&A',
          DATA1: {
            QUESTION: 'What is ‘Level-up Bonus’?',
            ANSWER: 'By sharing your referral link with your friends & family, any player who signs up to Africa365.com using your link becomes your referral. You can earn commissions while your referral is playing, earning you invitation rewards. Earn achievement rewards by accumulating invitations rewards! For instance, you only need 5 players to join with your referral link to get additional rewards from Africa365!'
          },
          DATA2: {
            QUESTION: 'How much can I earn from my referrals?',
            ANSWER: "The commission rewards program offers as high as 50% of your referral's 1% betting wager. Furthermore, the achievement rewards program can earn you up to $1,300 USD everyday!"
          },
          DATA3: {
            QUESTION: 'What is USD reward and how does it work?',
            ANSWER: "Africa365.com offers a reward of $1.3 USDT for every referral you bring to us. This reward is separated to 10 levels, as your referral reaches new VIP levels from VIP4 to SVIP1. The higher the level the better it benefits!"
          },
          DATA4: {
            QUESTION: 'I have a large audience, how can I get special deals?',
            ANSWER: "Please contact us at africa365.com to join special deals from Africa365!"
          },
          DATA5: {
            QUESTION: "Why didn't the people I invited become valid subordinate players?",
            ANSWER: "Valid subordinate players need to meet the requirements of recharge amount and only one valid player is counted for the same IP, email, phone number, and ID. Please refrain from using cheating tools as it may result in your account being banned by the system."
          }
        },
        EXCHANGE: {
          MESSAGE: 'Received successfully',
          CONFIRM_BUTTON_TXT: 'Confirm'
        },
        ACHIEVE_BONUS: {
          TITLE: 'Achievement Bonus',
          DESCRIPTION: 'If the cumulative number of invitations meets the conditions, you will receive additional achievement rewards. The more people you invite, the higher the reward.',
          TABLE: {
            COLUMN1: 'Friend’s Level',
            COLUMN2: 'Several people',
            COLUMN3: 'Rewards'
          }
        },
        BETTING_COMMISSION: {
          TITLE: 'Betting Commission',
          SHORT_DESC:'Bonus Rules: All players at the "Promoter" level receive a percentage of the platform advantage bonus for every bet.',
          LONG_DESC: `The proportions of the 3 levels are as follows <br>
          Level 1: Get 35% Of 1% wager <br>
          Level 2: Get 12.25% Of 1% wager <br>
          Level 3: Get 4.29% Of 1% wager <br>
          This will be your long-term income, and every time a player you refer places a bet, you will receive a different percentage of the commission regardless of whether he wins or loses.So what you have to do is to improve your game skills, think about how to win the game and share it with others, and help more people win with your method.We want all players to have fun in our platform, whether it's the fun of winning bets or the game itself!`
        },
        USER_LIST: {
          TITLE: "Users List",
          TABS: {
            TAB1: 'Sub-user',
            TAB2: 'Subordinate users'
          },
          SEARCH_PLACEHOLDER: 'Search User UID',
          SELECTS: {
            ITEM1: 'All',
            ITEM2: 'Today',
            ITEM3: 'Past 7 days',
            ITEM4: 'Past 30 days',
            ITEM5: 'Past 60 days'
          },
          TABLE: {
            COLUMN1: 'Email / Phone',
            COLUMN2: 'Registered At',
            COLUMN3: 'Bonus',
            COLUMN4: 'BETTING',
            COLUMN5: 'INVITATION',
          },
          TOTAL: 'Total',
          NO_DATA: "No user data"
        },
        VIP_LEVEL_BONUS: {
          TITLE: 'VIP Level-Up Bonus',
          DESC: 'Every user you invite reaches the specified level in the game, and you will get additional VIP level-up bonus. USD is a special currency launched by Platform . You can Swap USD into other currencies at any time.',
          TABLE: {
            COLUMN1: 'Friend’s Level',
            COLUMN2: 'Total Wager',
            COLUMN3: 'Rewards'
          }
        }
      }
    },
    KYC: {
      TITLE: "KYC",
      DESC: "Due to the regulatory requirement from National Lottery Regulatory Commission (NLRC) and Curaçao operating license,we require you to verify your Email Address and Phone number to prevent fraud, cheating and suspicious activity. If you are experiencing issue, you may also seek assistance from Live Chat.",
      BUTTON_TXT: "Account"
    },
    GOOGLE_LOGIN: {
      EERROR_MSG: 'ไม่สามารถเข้าสู่ระบบด้วยข้อมูลบัญชีนี้ได้ โปรดลองอีกครั้งด้วยบัญชีอื่น'
    },
    SUCCESS_MSG: {
      TITLE: 'สำเร็จแล้ว!'
    },
    ERROR_MSG: {
      TITLE: 'ขออภัย มีปัญหา'
    },
    COMMON: {
      COMING_SOON: "เร็ว ๆ นี้",
      LOAD_MORE: "โหลดเพิ่มเติม",
      LOGOUT: 'ออกจากระบบ',
      DEPOSIT: 'ฝากเงิน',
      WITHDRAW: 'ถอนเงิน',
      INVALID_AMOUNT: 'จำนวนไม่ถูกต้อง',
      HOME_BUTTON_TXT: 'หน้าหลัก',
      BACK_BUTTON_TXT: 'ย้อนกลับ',
      COPIED: 'Copied',
      OK: "OK",
      CANCEL: "Cancel",
      SEARCH: "Search"
    },
    LUCKSPIN: {
      TITLE: "Lucky Spin",
      TRY_AGAIN: "Try again",
      BUTTON_TEXT: "Spin",
      WIN_SPIN_TEXT: "LUCKY WIN SPIN NOW",
      BOTTOM_TEXT: "DAILY FREE SPINS",
      AVAILABLE_SPINS: "Available spins"
    },
    ERRORS: {
      400: 'Illegal status',
      401: 'Unauthorized',
      402: 'Payment required',
      404: 'Not found',
      500: 'Internal server error',
      503: '[Ezugi] Invalid game identifier',
      504: '[Ezugi] Invalid operation',
      506: '[Ezugi] Invalid currency',
      508: '[Ezugi] Invalid transaction identifier',
      511: '[Leander] Invalid token',
      1002: '[Evolution] Invalid session id',
      1007: '[NYX] RC unknown currency',
      6001: '[Vanguard] Game session invalid',
      6003: '[Vanguard] Authentication failed',
      40011: '[RGS] Invalid game type',
      40012: '[RGS] Invalid game ID',
      10000: 'Accounts limit exceeded',
      10001: 'Addacct failed',
      10002: 'Age verification failed',
      10004: 'Already has account',
      10005: 'Already registered',
      10006: 'Already sent',
      10010: 'Bad argument',
      10017: "Bonus code not found",
      10018: 'Bonus does not exist',
      10019: 'Bonus invalid',
      10021: 'Can not approve payout request',
      10022: 'Can not cancel payout request',
      10024: 'Can not process payout',
      10025: 'Can not process payout request',
      10034: 'Currency code not found',
      10037: 'Customer already in',
      10038: 'Customer does not exist', //
      10042: 'Customer in blacklist',
      10045: 'Customer not found', //
      10046: 'Customer not registered', //
      10047: 'Customer self excluded',
      10049: 'Customer suspended',
      10052: 'Customer verification not found',
      10055: 'Cust update failed',
      10059: 'Disabled acct',
      10064: 'Email already exists',
      10066: 'Email code not found',
      10069: 'Email in use',
      10071: 'Email not found',
      10143: 'File invalid format',
      10145: 'Game already started',
      10146: 'Game code is not valid',
      10150: 'Game not found',
      10154: 'Get balance',
      10155: 'Get balance error',
      10173: 'Invalid amount max',
      10174: 'Invalid amount min',
      10177: 'Invalid bonus id',
      10178: 'Invalid bonus type',
      10186: 'Invalid customer',
      10190: 'Invalid game id',
      10200: 'Invalid session',
      10207: 'Invalid token',
      10221: 'Login failure',
      10222: 'Login max logins reached',
      10223: 'Login wrong password',
      10224: 'Login wrong username',
      10225: 'Logout failed',
      10246: 'Password not match',
      10250: 'Phone already exists',
      10307: 'Username already exists',
      10308: 'Validate birthdate failed',
      10325: 'The email is not registered yet',
      10326: 'The email has already been used as google sso account',
      10327: 'Wrong referral code',
      10328: 'Customer has not disbursed referral commission',
      10329: 'cannot level up',
      10330: 'Failed to claim VIP cashback',
      10331: 'Invalid customer VIP level',
      10332: 'Failed to claim referral commission',
      10333: 'Failed to get referral achievement information',
      10334: 'Invalid phone number',
      10341: "Not enough value to claim bonus",
      11507: 'Country not found',
      11511: 'Invalid casino bonus',
      11512: 'Please close all opened games in order to forfeit the bonus.',
      11513: 'A saved game reserved funds from this bonus - please resolve it first.',
      11514: 'A sport event reserved funds from this bonus - please wait for its resolution first.',
      11515: 'Inactive casino bonus',
      11516: 'Could not forfeit Any Deposit Rollover casino bonus.',
      11517: "Insufficient player's daily credit",
      11518: 'Failed to acquire prize',
      11519: "Insufficient prize's daily count",
      11520: "Please wait for the cooldown period before sending another email.",
      11521: "Please wait for the cooldown period before sending another sms.",
      11522: "Reach maximum times for sending email for verification in a day.",
      11523: "Reach maximum times for sending sms for verification in a day.",
      11526: "ClubPay Exception",
      100000: 'VALIDATE_SIGNUP_CODE_FAILED',
      100002: 'SEND_SMS_VALIDATION_FAILED'
    },
    BONUS_TYPES: {
      "Deposit": "Deposit",
      "Withdrawal": "Withdrawal",
      "Table Buyin": "Table Buyin",
      "Table Rebuy": "Table Rebuy",
      "Table Leave": "Table Leave",
      "Place Bet": "Place Bet",
      "Tournament Buyin": "Tournament Buyin",
      "Tournament Fee": "Tournament Fee",
      "Tournament Buyin Refund": "Tournament Buyin Refund",
      "Tournament Fee Refund": "Tournament Fee Refund",
      "Manual Adjustment": "Manual Adjustment",
      "Recovery Refund": "Recovery Refund",
      "Transfer In": "Transfer In",
      "Transfer Out": "Transfer Out",
      "Round Win": "Round Win",
      "Bonus Win": "Bonus Win",
      "Deposit Bonus": "Deposit Bonus",
      "Account Creation Bonus": "Account Creation Bonus",
      "Referral Bonus": "Referral Bonus",
      "Bonus Conversion": "Bonus Conversion",
      "Login Grant": "Login Grant",
      "Initial Grant": "Initial Grant",
      "Virtual Goods Purchase": "Virtual Goods Purchase",
      "Loyalty": "Loyalty",
      "Winning in random scratcher": "Winning in random scratcher",
      "Mystery Gift Win": "Mystery Gift Win",
      "FB Credit Order": "FB Credit Order",
      "Leader Board Points": "Leader Board Points",
      "Tournament Award": "Tournament Award",
      "Returning Player Bonus": "Returning Player Bonus",
      "Cancelled round refund": "Cancelled round refund",
      "Sit & Go Award": "Sit & Go Award",
      "Sit & Go Buyin": "Sit & Go Buyin",
      "Sit & Go Fee": "Sit & Go Fee",
      "Sit & Go Buyin Refund": "Sit & Go Buyin Refund",
      "Sit & Go Fee Refund": "Sit & Go Fee Refund",
      "Fast Poker buyin": "Fast Poker buyin",
      "Fast Poker leave": "Fast Poker leave",
      "Fast Poker rebuy": "Fast Poker rebuy",
      "Tournament Rebuy": "Tournament Rebuy",
      "Tournament AddOn": "Tournament AddOn",
      "Sit & Go Rebuy": "Sit & Go Rebuy",
      "Sit & Go AddOn": "Sit & Go AddOn",
      "Bonus Deposit": "Bonus Deposit",
      "Bonus Released": "Bonus Released",
      "Bonus Deposit Released": "Bonus Deposit Released",
      "Bonus Forfeit": "Bonus Forfeit",
      "Bonus Expired": "Bonus Expired",
      "Bonus Winning Released": "Bonus Winning Released",
      "Void Deposit": "Void Deposit",
      "Bonus Deposit Forfeit": "Bonus Deposit Forfeit",
      "Bonus Winnings Forfeit": "Bonus Winnings Forfeit",
      "Bonus Deposit Expired": "Bonus Deposit Expired",
      "Bonus Winnings Expired": "Bonus Winnings Expired",
      "SnG Buyin Recovery Refund": "SnG Buyin Recovery Refund",
      "SnG Fee Recovery Refund": "SnG Fee Recovery Refund",
      "Tournament Buyin Recovery Refund": "Tournament Buyin Recovery Refund",
      "Tournament Fee Recovery Refund": "Tournament Fee Recovery Refund",
      "Clearing released bonus": "Clearing released bonus",
      "Clearing released bonus deposit": "Clearing released bonus deposit",
      "Clearing released bonus winnings": "Clearing released bonus winnings",
      "Void Withdrawal": "Void Withdrawal",
      "Bonus Deposit Forfeit Cleared": "Bonus Deposit Forfeit Cleared",
      "Bonus Deposit Expired Cleared": "Bonus Deposit Expired Cleared",
      "Bonus Cash Grant": "Bonus Cash Grant",
      "Place a Purchase": "Place a Purchase",
      "Cancel a Purchase": "Cancel a Purchase",
      "Tournament Rebuy Refund": "Tournament Rebuy Refund",
      "Tournament Rebuy Recovery Refund": "Tournament Rebuy Recovery Refund",
      "Tournament AddOn Refund": "Tournament AddOn Refund",
      "Tournament AddOn Recovery Refund": "Tournament AddOn Recovery Refund",
      "SnG Rebuy Refund": "SnG Rebuy Refund",
      "SnG Rebuy Recovery Refund": "SnG Rebuy Recovery Refund",
      "SnG AddOn Refund": "SnG AddOn Refund",
      "SnG AddOn Recovery Refund": "SnG AddOn Recovery Refund",
      "Bounty Tournament Award": "Bounty Tournament Award",
      "Jackpot winning": "Jackpot winning",
      "Collectible game award": "Collectible game award",
      "Claim reward": "Claim reward",
      "Cancel reward": "Cancel reward",
      "Coupon redeemed": "Coupon redeemed",
      "Tournament Bounty Buyin": "Tournament Bounty Buyin",
      "Tournament Bounty Buyin Refund": "Tournament Bounty Buyin Refund",
      "Tournament Bounty Buyin Recovery Refund": "Tournament Bounty Buyin Recovery Refund",
      "SnG Bounty Buyin": "SnG Bounty Buyin",
      "SnG Bounty Buyin Refund": "SnG Bounty Buyin Refund",
      "SnG Bounty Buyin Recovery Refund": "SnG Bounty Buyin Recovery Refund",
      "Reward Claimed Refund": "Reward Claimed Refund",
      "Void reward": "Void reward",
      "Tournament Prize Pool Distribution On Recovery": "Tournament Prize Pool Distribution On Recovery",
      "SnG Prize Pool Distribution On Recovery": "SnG Prize Pool Distribution On Recovery",
      "Chips Coupon Claimed": "Chips Coupon Claimed",
      "Milestone Bonus": "Milestone Bonus",
      "External Grant": "External Grant",
      "Void Bet": "Void Bet",
      "Daily TopOff": "Daily TopOff",
      "Bonus Grant": "Bonus Grant",
      "Bonus Used": "Bonus Used",
      "Sport Bet": "Sport Bet",
      "Sport Win": "Sport Win",
      "Sport Win Adjustment": "Sport Win Adjustment",
      "Sport Round Refund": "Sport Round Refund",
      "Sport Round Void": "Sport Round Void",
      "Round Win Bonus": "Round Win Bonus",
      "IBC Sport Unsettle": "IBC Sport Unsettle",
      "IBC Sport Adjust Balance": "IBC Sport Adjust Balance",
      "VIP Level rank-up bonus": "VIP Level rank-up bonus",
      "VIP Level cashback bonus": "VIP Level cashback bonus",
      "Referral commission bonus": "Referral commission bonus",
      "Any Deposit Rollover Bonus": "Any Deposit Rollover Bonus",
      "KYC bonus": "KYC bonus",
      "Daily Lose Rake Back": "Daily Lose Rake Back",
      "Daily lose cashback bonus": "Daily lose cashback bonus",
      "IBC Sport Settled": "IBC Sport Settled"
    }
	}
};
