import { Navigator } from './../../../../../system/components/menu/navigator';
import { SuccessMessageProvider } from './../../../../../system/providers/success-message-provider';
import { AuthenticationProvider } from './../../../../authentication/providers/authentication-provider';
import { ConfigurationProvider } from './../../../../../system/providers/configuration-provider';
import { CustomerModel } from './../../../models/customer-model';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { UserSessionProvider } from './../../../../../system/providers/user-session';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Utils } from '../../../../../shared/utils';
import {
  coutryPostalMatchValidator,
  POboxValidator,
  POboxValidatorGroup,
  birthdayValidator,
} from '../../../../../shared/customFormValidators';
import { Config } from './../../../../../system/providers/configuration';
import { isUndefined } from '../../../../../shared/codeUtils';
import { IonContent, NavController, Platform } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';

@Component({
  selector: 'page_personal_information',
  templateUrl: 'personal-information.html',
  styleUrls: ['./personal-information.scss'],
})
export class PersonalInformationPage implements OnInit {

  @ViewChild(IonContent) content: IonContent;

  public error: any = null;

  public customer: CustomerModel;

  public infoForm: FormGroup;

  public street: AbstractControl;
  public street2: AbstractControl;
  public city: AbstractControl;
  public country: AbstractControl;
  public province: AbstractControl;
  public postalCode: AbstractControl;
  public countryInfoGroup: AbstractControl;
  public streetGroup: AbstractControl;
  public phoneNumber: AbstractControl;
  public sendEmailUponLogin: AbstractControl;
  public allowPromoSms: AbstractControl;
  public allowPromoEmails: AbstractControl;

  public selectedCountry: string;
  public selectedProvince: string;
  public selectedSecurityQuestion: string;

  public countries: any[];
  public provinces: any[];
  public countryChanged: boolean;
  public emailUponLogin: boolean = false;

  public allowPromoSmsCheck: boolean = false;
  public allowPromoEmailsCheck: boolean = false;
  public enableEdit: boolean;
  public updateButtonDisabled: boolean = false;

  constructor(
    public navCtrl: NavController,
    public userSession: UserSessionProvider,
    public configProvider: ConfigurationProvider,
    public authProvider: AuthenticationProvider,
    public successMessageProvider: SuccessMessageProvider,
    public events: MyEvent,
    public config: Config,
    public utils: Utils,
    public platform: Platform
  ) {
    this.userSession.getCustomer().subscribe((customer) => {
      if (customer) {
        this.customer = customer;

        if (this.customer.sendEmailUponLogin == true) {
          this.emailUponLogin = true;
        }
        if (this.customer.allowPromoEmails == true) {
          this.allowPromoEmailsCheck = true;
        }
        if (this.customer.allowPromoSms == true) {
          this.allowPromoSmsCheck = true;
        }
      }
    });

    this.userSession.refreshCustomer();
  }

  ngOnInit() {
    this.infoForm = new FormGroup({
      countryInfoGroup: new FormGroup(
        {
          country: new FormControl('', [Validators.required]),
          postalCode: new FormControl('', [Validators.required]),
        },
        coutryPostalMatchValidator
      ),
      streetGroup: new FormGroup(
        {
          street: new FormControl('', [Validators.required, POboxValidator]),
          street2: new FormControl('', [POboxValidator]),
        },
        POboxValidatorGroup
      ),
      city: new FormControl('', [Validators.required]),
      province: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+$'),
        Validators.minLength(10),
        Validators.maxLength(10),
      ]),
      sendEmailUponLogin: new FormControl(''),
      allowPromoSms: new FormControl(''),
      allowPromoEmails: new FormControl(''),
    });
    this.enableEdit =
      this.config.getConfig().ENABLE_CUSTOMER_EDIT == 1 ? true : false;
    //this.infoForm.controls['dateOfBirth'].setValue(this.defaultDate);

    this.city = this.infoForm.get('city');
    this.province = this.infoForm.get('province');
    this.phoneNumber = this.infoForm.get('phoneNumber');
    this.sendEmailUponLogin = this.infoForm.get('sendEmailUponLogin');
    this.allowPromoSms = this.infoForm.get('allowPromoSms');
    this.allowPromoEmails = this.infoForm.get('allowPromoEmails');

    this.streetGroup = this.infoForm.get('streetGroup');
    this.street = this.streetGroup.get('street');
    this.street2 = this.streetGroup.get('street2');

    this.countryInfoGroup = this.infoForm.get('countryInfoGroup');
    this.country = this.countryInfoGroup.get('country');
    this.postalCode = this.countryInfoGroup.get('postalCode');
  }

  ionViewDidEnter() {
    //this.updateButtonDisabled = false;
    this.successMessageProvider.clearMessage();
    this.error = null;

    this.countryChanged = false;

    this.configProvider.callGetAllCountries().subscribe(
      (response: any) => {
        console.log('callGetAllCountries ', response);
        if (response && !!response.result) {
          this.countries = response.payload;
          //this.countries.shift() //REMOVING DUPLICATE USA FROM LIST. IT WILL CAUSE DOUBLE "United States" IN PICKER IF NOT...

          if (
            !isUndefined(this.customer) &&
            !isUndefined(this.customer.address.country)
          ) {
            this.selectedCountry = this.customer.address.country;
          } else {
            this.selectedCountry = 'USA';
          }
          this.getProvinces(this.selectedCountry);
          this.selectedCountry = this.countries.filter((c) => {
            return c.code === this.customer.address.country;
          })[0].name;
        }
      },
      (error) => {
        console.log('callGetAllCountries ', error);
      }
    );
  }

  ionViewWillLeave() {
    if (
      this.config.getConfig().KYC_CHECK_PLAY == '1' &&
      this.customer.isIncomplete()
    ) {
      Navigator.navigateTo(
        'LogoutPage',
        {},
        { reason: 'EDIT_PROFILE_KYC_FAIL' }
      );
    }
  }

  onChangeCountry(event) {
    console.log('onChangeCountry ', event);
    this.countryChanged = true;
    this.getProvinces(event);
  }

  getProvinces(country) {
    this.configProvider.callGetStatesByCountry(this.selectedCountry).subscribe(
      (response: any) => {
        console.log('callGetStatesByCountry ', response);
        if (response && !!response.result) {
          let provs = response.payload;

          this.provinces = [];
          for (let p in provs) {
            this.provinces.push({ code: p, name: provs[p] });
          }
          if (this.countryChanged) {
            this.selectedProvince = '';
          } else {
            this.selectedProvince = this.customer.address.state;
          }
          this.selectedProvince = this.provinces.filter((p) => {
            return p.code === this.customer.address.state;
          })[0].name;
        }
      },
      (error) => {
        console.log('callGetStatesByCountry ', error);
      }
    );
  }

  onSubmit() {
    this.infoForm.addControl(
      'street',
      new FormControl(this.streetGroup.get('street').value)
    );
    this.infoForm.addControl(
      'street2',
      new FormControl(this.streetGroup.get('street2').value)
    );

    if (this.emailUponLogin == true) {
      this.infoForm.get('sendEmailUponLogin').setValue('1');
    } else {
      this.infoForm.get('sendEmailUponLogin').setValue('0');
    }
    if (this.allowPromoSmsCheck == true) {
      this.infoForm.get('allowPromoSms').setValue('1');
    } else {
      this.infoForm.get('allowPromoSms').setValue('0');
    }

    if (this.allowPromoEmailsCheck == true) {
      this.infoForm.get('allowPromoEmails').setValue('1');
    } else {
      this.infoForm.get('allowPromoEmails').setValue('0');
    }

    this.infoForm.value.clientapp = this.config.getClientApp();

    // Setting the province and country back to codes as that is accepted on the server side
    let fProv = this.provinces.filter((p) => {
      return p.name === this.selectedProvince;
    })[0].code;

    let fCountry = this.countries.filter((p) => {
      return p.name === this.selectedCountry;
    })[0].code;

    let obj = this.infoForm.value;
    obj.countryInfoGroup.country = fCountry;
    obj.province = fProv;

    console.log('update data == ', this.infoForm.value);

    this.authProvider.callUpdateAccount(this.infoForm.value).subscribe(
      (response: any) => {
        console.log('callUpdateAccount ', response);
        if (response.code == 200 && response.result) {
          this.userSession.refreshCustomer();
          this.successMessageProvider.setMessage(
            'Your account has been updated!'
          );
          //this.updateButtonDisabled = true;
          this.error = null;

          setTimeout(() => {
            this.content.scrollToTop();
          }, 320);

          this.infoForm.removeControl('street');
          this.infoForm.removeControl('street2');
        }
      },
      (error) => {
        console.log('callUpdateAccount ', error);

        switch (error.error_code) {
          case 10004:
            error.setErrorMessage(
              'It looks like you may have an account with us already.'
            );
            break;
          case 10042:
            error.setErrorMessage(
              'Cannot create account please contact customer support.'
            );
            break;
          case 10157:
            error.setErrorMessage(
              'Failed to update your account with this SSN'
            );
            break;
          case 'INVALID_BIRTHDATE':
            error.setErrorMessage('The birthdate entered is invalid');
            break;
          case 10250:
            error.setErrorMessage(
              "Phone Number already exist and it's not available."
            );
            break;
          case 10011:
            error.setErrorMessage(error.getErrorMessage());
            break;
          default:
            error.setErrorMessage(error.getErrorMessage());
            break;
        }
        this.successMessageProvider.clearMessage();
        this.error = error;
        this.content.scrollToTop();
        this.infoForm.removeControl('street');
        this.infoForm.removeControl('street2');
      }
    );
  }

  isNotEditable() {
    if (this.customer.isActive()) {
      return true;
    }
    return false;
  }

  get isMobile() {
    return this.platform.is('mobile') || this.platform.is('mobileweb')
      ? true
      : false;
  }
}
