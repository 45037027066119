import { NgModule } from '@angular/core';
import { SetWagerLimitsPage } from './set-wager-limits';
import { SharedModule } from '../../../../../shared/shared.module';
import { SystemModule } from '../../../../../system/system.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    SetWagerLimitsPage,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SystemModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule
  ],
})
export class SetWagerLimitsPageModule {}
