import { Component } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { RewardsProvider } from './../../../../providers/rewards-provider';
import { Navigator } from './../../../../../../system/components/menu/navigator';
import { Utils } from '../../../../../../shared/utils';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';

@Component({
  selector: 'page-casino-bonus-history',
  templateUrl: 'casino-bonus-history.html',
  styleUrls: ['./casino-bonus-history.scss'],
})
export class CasinoBonusHistoryPage {

  error = null;

  public bonusForm: FormGroup;

  public dateFrom: AbstractControl;
  public dateTo: AbstractControl;

  public bonusObj: any[] = [];
  public bonusArray: any[] = new Array();

  public showTable: boolean = false;
  public showNoResults: boolean = false;

  public toDate;
  public fromDate;
  public todayDate = '2018-01-01';

  //set options for the first datepicker
  public DatePickerOptionsFrom: IMyDpOptions = {
    dateFormat: 'mm-dd-yyyy',
    showClearDateBtn: false,
    editableDateField: false,
    disableSince: { year: 0, month: 0, day: 0 },
    disableUntil: { year: 0, month: 0, day: 0 },
  };
  //set options for the seconds datepicker
  public DatePickerOptionsTo: IMyDpOptions = {
    dateFormat: 'mm-dd-yyyy',
    showClearDateBtn: false,
    editableDateField: false,
    disableSince: { year: 0, month: 0, day: 0 },
    disableUntil: { year: 0, month: 0, day: 0 },
  };
  public jsDateObject: any;
  public startDate: any;

  constructor(private rewardsProvider: RewardsProvider, public utils: Utils) {}

  ionViewDidEnter() {
    let today = new Date();
    let date = new Date();
    this.startDate = new Date(date.setDate(date.getDate() - 14)); //set start date 2 weeks ago to dynamically add 2 weeks range
    this.todayDate, (this.toDate = this.utils.formatDate_MM_DD_YYYY(today)); //setting today date fallback and initial value for 'To' data passed to webservices
    this.fromDate = this.utils.formatDate_MM_DD_YYYY(date); //'From' data passed to webservice

    this.disableSinceFrom();
    this.disableSinceTo();
    this.disableUntilTo();
  }

  onDateChangedFrom(event: IMyDateModel) {
    this.fromDate = event.formatted;
    this.jsDateObject = event.jsdate;
    let toDateForward = new Date(this.jsDateObject);
    toDateForward.setDate(toDateForward.getDate() + 14); //add 2 weeks to 'toDate' automatically when user picks a date
    this.toDate = this.utils.formatDate_MM_DD_YYYY(toDateForward);
    this.disableSinceTo();
    this.disableUntilTo();
  }

  onDateChangedTo(event: IMyDateModel) {
    this.toDate = event.formatted;
    console.log(this.toDate);
  }
  disableSinceFrom() {
    let d: Date = new Date();
    d.setDate(d.getDate() + 1);
    let copy = this.getCopyOfOptionsFrom();
    copy.disableSince = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.DatePickerOptionsFrom = copy;
  }

  getCopyOfOptionsFrom(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.DatePickerOptionsFrom));
  }

  disableSinceTo() {
    let d: Date =
      this.jsDateObject !== undefined
        ? new Date(this.jsDateObject)
        : new Date(this.startDate);
    d.setDate(d.getDate() + 15);
    let copy = this.getCopyOfOptionsTo();
    copy.disableSince = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.DatePickerOptionsTo = copy;
  }

  disableUntilTo() {
    let d: Date =
      this.jsDateObject !== undefined
        ? new Date(this.jsDateObject)
        : new Date(this.startDate);
    d.setDate(d.getDate());
    let copy = this.getCopyOfOptionsTo();
    copy.disableUntil = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.DatePickerOptionsTo = copy;
  }

  getCopyOfOptionsTo(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.DatePickerOptionsTo));
  }

  onSearch() {
    let fromDate = this.utils.formatDate_from_MM_DD_YYYY_to_YYYY_MM_DD(
      this.fromDate
    );
    let toDate = this.utils.formatDate_from_MM_DD_YYYY_to_YYYY_MM_DD(
      this.toDate
    );

    this.rewardsProvider
      .callGetCustomerCasinoBonusesHistory(fromDate, toDate)
      .subscribe(
        (response: any) => {
          console.log('past bonus == ', response);
          if (response.code == 200 && !!response.result) {
            //this.bonusObj = response.payload.response;
            this.bonusArray = response.payload.response;
            this.bonusArray.forEach((element) => {
              let shortenedDisplayName: string = element.displayName;
              if (
                shortenedDisplayName.length > 9 &&
                shortenedDisplayName.substr(0, 12).indexOf(' ') == -1
              ) {
                shortenedDisplayName =
                  shortenedDisplayName.substring(0, 7) + '...';
                element.shortenedDisplayName = shortenedDisplayName;
              } else {
                element.shortenedDisplayName = element.displayName;
              }
            });

            if (this.bonusArray.length > 0) {
              this.showTable = true;
              this.showNoResults = false;
            } else {
              this.showNoResults = true;
              this.showTable = false;
            }
          }
        },
        (error) => {
          this.showTable = false;
          this.error = error;
        }
      );
  }

  onTotalBonusClicked(bonus: any) {
    //bonus["currentBonus"] = bonus["originalBonus"];
    Navigator.navigateTo(
      'CasinoBonusDetailsPage',
      { multipleModals: true },
      { param1: bonus }
    );
    return false;
  }

  onChangeFrom(event) {
    console.log('onChangeFrom CasinoBonusHistoryPage', event);
  }

  onChangeTo(event) {
    console.log('onChangeTo CasinoBonusHistoryPage', event);
  }

  getYesterday() {
    let today = new Date();
    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    return this.utils.formatDate_MM_DD_YYYY(yesterday);
  }

  getToday() {
    let today = new Date();

    return this.utils.formatDate_MM_DD_YYYY(today);
  }
}
