// Window declaration for TS to use it with google dataLayer
declare global {
  interface Window {
    dataLayer: any;
  }
}

window.dataLayer = window.dataLayer || [];

import { Inject, Injectable } from '@angular/core';
import { Config } from './../../system/providers/configuration';
import { CustomerModel } from './../../modules/account/models/customer-model';
import { UserSessionProvider } from '../../system/providers/user-session';
import { WebservicesProvider } from '../../system/providers/webservices';
import { MainProvider } from '../../modules/lobby/providers/main.provider';

@Injectable({
  providedIn: 'root'
})
export class GoogleToolsProvider {

  private transactionDevice;
  private customer: CustomerModel;
  private curency;

  constructor(
    private config: Config,
    private userSession: UserSessionProvider,
    private webservice: WebservicesProvider,
    protected mainProvider: MainProvider
  ) {
    this.transactionDevice = this.config.getClientApp();
    this.userSession.getCustomer().subscribe((customer) => {
      if (customer) {
        this.customer = customer;
      }
    });
  }

  setup() {
    this.setupGoogleAnalytics();
    this.setupGoogleABTesting();
    this.setUpGTM();
  }

  private setUpGTM() {
    let GTMKey = this.config.getGTMKey();
    if (GTMKey && GTMKey !== '') {
      const script = document.createElement('script');
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTMKey}');`;

      document.head.appendChild(script);

      const noScript = document.createElement('noscript');
      noScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTMKey}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      var s = document.body.firstChild;
      s.parentNode.insertBefore(noScript, s);
    }
  }

  private setupGoogleAnalytics() {
    let UAKey = this.config.getGoogleAnalyticsKey();
    let ABKey = this.config.getGoogleABTestingKey();
    if (UAKey && UAKey !== '') {
      const script = document.createElement('script');
      script.innerHTML = `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
                ga('create','${UAKey}','auto');
                ga('send', 'pageview');
                ga('require','${ABKey}');
           `;

      document.head.appendChild(script);
    }
  }

  private setupGoogleABTesting() {
    let ABKey = this.config.getGoogleABTestingKey();
    if (ABKey && ABKey !== '') {
      const script = document.createElement('script');
      script.innerHTML = `(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
            h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
            (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
            })(window,document.documentElement,'async-hide','dataLayer',4000,
            {'${ABKey}':true});`;
      document.head.appendChild(script);
    }
  }

  //=======================EVENTS===============================//

  loginEvent(customer_id) {
    let dataToSend = {
      customerId: this.formatId(customer_id),
      device: this.transactionDevice,
      event: 'Login',
      transactionType: 'Login',
    };
    this.sendGtmEvent(dataToSend);
  }
  // step 1
  createAccountEvent(customer_id) {
    let dataToSend = {
      event: 'Registration',
      customerId: this.formatId(customer_id),
      device: this.transactionDevice,
      transactionType: 'Registration',
    };

    this.sendGtmEvent(dataToSend);
  }
  // step 2
  createAccountCompleteEvent(data) {
    let dataToSend = {
      event: 'Registration Complete',
      customerId: this.formatId(data.customer_id),
      device: this.transactionDevice,
      transactionType: 'Registration Complete',
      promoCode: data.promo_code,
    };

    this.sendGtmEvent(dataToSend);
  }

  depositEvent(data) {
    let customerId = 'customer_id' in data ? data.customer_id : '';
    let sessionToken = 'sessionToken' in data ? data.sessionToken : '';

    this.getEventData('deposit', customerId, sessionToken).then(
      (eventData: any) => {
        let dataToSend = {
          customerId: this.formatId(data.customer_id),
          device: this.transactionDevice,
          depositCount: eventData.transactionNumber,
          depositAmount: data.amount,
          depositCurrency: this.config.getConfig().DEFAULT_CURRENCY,
          transactionId: data.transaction_id,
          transactionType: 'Deposit',
          event: 'Deposit',
          paymentMethod: data.paymentMethod ? data.paymentMethod : 'N/A',
          firstDeposit: ~~eventData.transactionNumber > 1 ? false : true,
          transactionTotal: data.amount,
          bonusCode:
            data.bonusCode == null || data.bonusCode == undefined
              ? 'N/A'
              : data.bonusCode,
          bonusID:
            data.bonusID == null || data.bonusID == undefined
              ? 'N/A'
              : data.bonusID,
        };
        // check for first deposit
        dataToSend.transactionType =
          eventData.transactionNumber > 1
            ? 'Further Deposit Complete'
            : 'First Deposit Complete';

        //Executing AppsFlyer Event call for IAB tab - Sports Lobby IOS
        if (this.mainProvider.isIABApp()) {
          console.log('--IAB Tab Deposit--', data);
          window['APPSFLYER_EVENT'] = {
            appsFlyerEvent: 'deposit',
            dataToSend: data,
          };
        }
        this.sendGtmEvent(dataToSend);
      }
    );
  }

  withdrawalEvent(data) {
    this.getEventData('withdraw').then((eventData: any) => {
      let dataToSend = {
        transactionPlayerID: this.formatId(data.customer_id),
        device: this.transactionDevice,
        transactionTotal: data.amount,
        transactionType: 'Withdrawal',
        event: 'Withdrawal',
      };
      //Executing AppsFlyer Event call for IAB tab - Sports Lobby IOS
      if (this.mainProvider.isIABApp()) {
        console.log('--IAB Tab Withdraw--', data);
        window['APPSFLYER_EVENT'] = {
          appsFlyerEvent: 'withdraw',
          dataToSend: data,
        };
      }
      this.sendGtmEvent(dataToSend);
    });
  }

  sendPageHistoryEvent(pageName) {
    let websiteUrl = document.location.origin;
    let virtualRoute = `${websiteUrl}/${pageName} `;
    let data = {
      event: 'Pageview',
      url: virtualRoute,
    };
    this.sendGtmEvent(data);
  }

  sendWebViewEvent() {
    let data = {
      event: 'Pageview',
      url: window.location.href,
    };
    this.sendGtmEvent(data);
  }

  gameLaunched(data) {
    let customer_id = !!this.customer ? this.customer.customerId : 'undefined';
    let dataToSend = {
      customerId: this.formatId(customer_id),
      gameName: data.game_name != null ? data.game_name : data.name,
      event: 'Game Launched',
      transactionType: 'Game Launched',
    };
    this.sendGtmEvent(dataToSend);
  }

  betPlaced(data) {
    data.customerId = this.formatId(data.customer_id);
    data.event = data.transactionType = 'bet_placed';
    //Executing AppsFlyer Event call for IAB tab - Sports Lobby IOS
    if (this.mainProvider.isIABApp()) {
      console.log('--IAB Tab Deposit--', data);
      window['APPSFLYER_EVENT'] = {
        appsFlyerEvent: 'bet_placed',
        dataToSend: data,
      };
    }
    this.sendGtmEvent(data);
  }

  conversion(data) {
    data.customerId = this.formatId(data.customer_id);
    data.event = data.transactionType = 'conversion_succeeded';
    //Executing AppsFlyer Event call for IAB tab - Sports Lobby IOS
    if (this.mainProvider.isIABApp()) {
      console.log('--IAB Tab Deposit--', data);
      window['APPSFLYER_EVENT'] = {
        appsFlyerEvent: 'conversion_succeeded',
        dataToSend: data,
      };
    }
    this.sendGtmEvent(data);
  }

  forgotPassword(data) {
    // Forgot Password - Customer completed the forgot password process
    var dataToSend = {
      type: 'forgotPassword',
      detail: {
        timestamp: new Date().getTime(),
      },
    };
    this.sendGtmEvent(dataToSend);
  }

  logout() {
    let customerId =
      this.customer && 'customerId' in this.customer
        ? this.customer.customerId
        : '';
    let dataToSend = {
      customerId: customerId,
      event: 'Logout',
      transactionType: 'Logout',
    };
    this.sendGtmEvent(dataToSend);
  }

  gameClosed() {
    let customerId =
      this.customer && 'customerId' in this.customer
        ? this.customer.customerId
        : '';
    let dataToSend = {
      customerId: customerId,
      event: 'Game Closed',
      transactionType: 'Game Closed',
    };
    this.sendGtmEvent(dataToSend);
  }

  loginError(data) {
    var dataToSend = {
      type: 'loginError',
      detail: {
        errorCode: data.errorCode,
        errorName: data.errorName,
        errorType: 'loginError',
        timestamp: new Date().getTime(),
      },
    };
    this.sendGtmEvent(dataToSend);
  }

  depositFailed(data) {
    let dataToSend = {
      info: data,
      event: 'DepositFailed',
      transactionType: 'DepositFailed',
    };

    this.sendGtmEvent(dataToSend);
  }

  bannerClick(info) {
    let data = {
      event: 'bannerClick',
      info: info,
    };

    this.sendGtmEvent(data);
  }

  //==================== END of EVENTS ======================//
  //========================================================//

  private sendGtmEvent(data) {
    window.dataLayer.push(data);
  }

  get transactionRegDate() {
    let transactionRegDate = this.customer.createdDate;
    return transactionRegDate;
  }

  get transactionGameEngage() {
    let transactionGameEngage;
    return transactionGameEngage;
  }

  public getEventData(eventName, customerId?, sessionToken?) {
    return new Promise((resolve, reject) => {
      let customer_id;
      let session_token = sessionToken
        ? sessionToken
        : this.userSession.sessionToken;
      if (customerId) {
        customer_id = customerId;
      } else {
        customer_id = !!this.customer ? this.customer.customerId : 'undefined';
      }
      let params = {
        player_id: customer_id,
        session_id: session_token,
      };

      this.webservice.get('player/default-balance', params).subscribe(
        (transactionData) => {
          if (!!transactionData.payload) {
            let payload = transactionData.payload;
            let data: any = {
              transactionRegDate: payload.transactionRegDate,
              transactionNumber:
                eventName === 'deposit'
                  ? payload.depositCount
                  : payload.withdrawalCount,
              transactionLastDeposit: payload.lastDepositDate,
              transaction30NGR: payload.last30DayWinLoss,
              transactionLifeNGR: payload.allTimeWinLoss,
              transactionGameEngage: payload.lastPlayedGameName,
            };
            resolve(data);
          } else {
            reject(
              new Error('Payload missing from get-transaction-info service')
            );
          }
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  formatId = (customerId) => {
    let prefix = '';
    let config = this.config.getAppConfigInfo();
    if (!!config && 'playerIdPrefix' in config) {
      prefix = config.playerIdPrefix;
    }

    return prefix + customerId;
  };
}
