import { Navigator } from 'src/app/system/components/menu/navigator';
import { CustomerModel } from './../../../models/customer-model';
import { Config } from './../../../../../system/providers/configuration';
import { UserSessionProvider } from './../../../../../system/providers/user-session';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TouchID } from '@ionic-native/touch-id/ngx';
import { NavController } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';
import { CommonService } from 'src/app/service/common.service';
import * as copy from 'copy-to-clipboard';
import { TranslationService } from 'src/app/service/translation.service';

@Component({
  selector: 'page-account-information',
  templateUrl: 'account-information.html',
  styleUrls: ['./account-information.scss'],
})
export class AccountInformationPage implements OnInit {

  customer: CustomerModel;
  countryCode: any;
  appConfigInfo: any;
  summaryText: any;
  hidePhoneField: any;

  constructor(
    public navCtrl: NavController,
    private userSession: UserSessionProvider,
    private config: Config,
    public touchID: TouchID,
    private events: MyEvent,
    public comService: CommonService,
    private transService: TranslationService,
    private cdr: ChangeDetectorRef
  ) {
    this.appConfigInfo = this.config.getAppConfigInfo();
    this.hidePhoneField = this.appConfigInfo?.HIDE_PHONE_NUMBER;
  }

  ionViewDidEnter() {
    this.summaryText = this.appConfigInfo?.account_summary;
  }

  ionViewWillEnter() {

  }

  ngOnInit() {
    this.userSession.getCustomer().subscribe((customer) => {
      if (customer) {
        this.customer = customer;
        this.countryCode = (this.customer.address && this.customer.address?.dialCode) ? this.customer.address?.dialCode : this.appConfigInfo?.country_code;
      } else {
        this.countryCode = this.appConfigInfo?.country_code;
      }
      this.cdr.detectChanges();
    });
  }

  async copy(text) {
    if (!text) return;

    try {
      copy(text);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
    var msg = await this.transService.getTranslationWord('COMMON.COPIED');
    this.comService.showToast(msg);
  }

  editName() {
    Navigator.navigateTo('EditNamePage', {forceModal: true, cssClass: 'editname_modal', disableBackdrop: false}, {callback: () => this.callBack()});
  }

  addEmail() {
    Navigator.navigateTo('AddEmailPage', {forceModal: true, cssClass: 'add_email_modal', disableBackdrop: false}, {callback: () => this.callBack()});
  }

  verifyEmail() {
    Navigator.navigateTo('ActiveAccountPage', {forceModal: true, cssClass: 'add_email_modal', disableBackdrop: false}, {
      callback: () => {
        this.callBack();
      }
    });
  }

  editPassword() {
    Navigator.navigateTo('ChangePasswordPage', {forceModal: true, cssClass: 'change_pass_modal', disableBackdrop: false}, {callback: () => this.callBack()});
  }

  editPhone() {
    Navigator.navigateTo('UpdatePhoneNumberPage', {forceModal: true, cssClass: 'add_email_modal', disableBackdrop: false}, {callback: () => this.callBack()});
  }

  callBack() {
    Navigator.back();
    this.userSession.refreshCustomer();
    Navigator.navigateTo('AccountInformationPage', {forceModal: true, cssClass: ''});
  }
}
