// USA
export const locale = {
  lang: 'vi',
  data: {
    LEFT_SIDE: {
      EARN_CATEGORY_TITLE: 'Thu Được',
      REFERRAL: 'Sự Giới Thiệu Qua Lại',
      VIP: 'VIP Club',
      LANGS: {
        // https://www.omniglot.com/language/names.htm
        ENGLISH: 'English',
        CHINESE: '简体中文',
        PORTUGUES: 'Português',
        THAILIAND: 'ภาษาไทย',
        VIETNAME: 'Tiếng Việt',
        SPANISH: 'Español',
        INDONESIAN: 'Bahasa Indonesia',
        JAPANESE: '日本語'
      },
      INTEREST: "interest",
      REFER_EARN: "Refer & Earn",
      VIP_CLUB: '<span style="color: #ffb636;">VIP</span> Club'
    },
		HEADER: {
      SPORT_BUTTON_TEXT: "THỂ THAO",
      CASINO_BUTTON_TEXT: "Casino",
      PROMOTIONS: 'Sự khuyến mãi',
      SEARCH: "Search",
      SEARCH_PLACEHOLDER: "Tìm Kiếm Theo Trò Chơi Hoặc Nhà Cung Cấp",
      SEARCH_PART_GAMES: "Trò Chơi",
      SEARCH_PART_PROVIDERS: "Nhà Cung Cấp",
      SEARCH_RESULT: "Search Results",
      SEARCH_NO_RESULT: "Không Có Kết Quả Tìm Kiếm",
      SEARCH_TRY_GAMES: "Recommended",
      RECOMMENDED_GAMES: "Recommended for you",
      LOGIN_BUTTON_TEXT: "Đăng Nhập",
      REGISTER_BUTTON_TEXT: "Đăng Ký",
      BALANCE_INGAME: "Vào Trò Chơi",
      DEPOSIT_BUTTON_TEXT: "Ví",
      Settings: "Settings",
      ALL_GAMES: "All Games",
      SELECT_GAME: "Select Game"
    },
    BALANCES: {
      FIAT: 'Tiền Tệ Fiat',
      CRYPTO: 'Tiền Điện Tử',
      DISPLAY_FIAT: 'Hiển Thị Giá Trị Bằng Tiền Tệ Fiat'
    },
    MOBILE_FOOTER: {
      BETSPLUS: {
        SPORT_TAB_TEXT: 'Thể Thao',
        CASINO_TAB_TEXT: 'Casino',
        PROMOTIONS_TAB_TEXT: 'Chương Trình Khuyến Mãi',
        MENU_TAB_TEXT: 'Menu'
      }
    },
    DASHBOARD: {
      CATEGORY_LOBBY_NAME: "Lối Vào",
      LAST_PLAYED: "Gần Đây",
      VIEWALL_BUTTON_TEXT: "Tất Cả",
      VIEWALL_BUTTON_TEXT1: "View All",
      ALL_GAMES_COUNT: "Tất Cả {{count}}",
      NO_GAMES: "Không Có Dữ Liệu Trò Chơi",
      GAME_PROVIDERS_TITLE: "Nhà Cung Cấp Trò Chơi",
      RECORDS: {
        TITLE: "Sự Ghi Chép",
        TABS: {
          MY_BETS_TITLE: "Cá Cược Của Tôi",
          ALL_BETS_TITLE: "Tất Cả Cá Cược",
          RARE_WINS_TITLE: 'Thắng Lớn',
        },
        DATA: {
          BETS_TABLE: {
            COLUMN1: 'Trò Chơi',
            COLUMN2: 'Tên Đăng Nhập',
            COLUMN3: 'Thời Gian',
            COLUMN4: 'Số Tiền Cược',
            COLUMN5: 'Nhân Số Tiền Thắng',
            COLUMN6: 'Số Tiền Nhận Được'
          },
          WINS_TABLE: {
            COLUMN1: 'Trò Chơi',
            COLUMN2: 'Tên Đăng Nhập',
            COLUMN3: 'Số Tiền Cược',
            COLUMN4: 'Nhân Số Tiền Thắng',
            COLUMN5: 'Số Tiền Nhận Được'
          },
          WINS_TABLE_BETSPLUS: {
            COLUMN1: 'Xếp Hạng',
            COLUMN2: 'Tên Đăng Nhập',
            COLUMN3: 'Đặt Cược',
            COLUMN4: 'Phần Thưởng'
          },
          NO_MY_BETS_TEXT: "Không Có Cá Cược Của Tôi",
          NO_ALL_BETS_TEXT: "Không Có Tất Cả Cá Cược",
          NO_RARE_WINS_TEXT: "Không Có Thắng Lớn"
        }
      }
    },
    AUTH: {
      TOPBAR: {
        LOGIN_BUTTON_TEXT: "Đăng Nhập",
        REGISTER_BUTTON_TEXT: "Đăng Ký"
      },
      LOGIN: {
        TITLE: "Chào Mừng Quay Lại!",
        EMAIL: 'Thư Điện Tử',
        EMAIL_EMPTY_ERROR: "Vui Lòng Nhập Một Địa Chỉ Thư Điện Tử",
        EMAIL_INVALID_ERROR: 'Vui Lòng Nhập Một Địa Chỉ Thư Điện Tử Hợp Lệ',
        PHONE_NUMBER: "Số Điện Thoại",
        PHONE_EMPTY_ERROR: 'Vui Lòng Nhập Một Số Điện Thoại',
        PHONE_INVALID_ERROR: 'Vui Lòng Nhập Một Số Điện Thoại Hợp Lệ',
        PASSWORD: 'Mật khẩu',
        PASSWORD_EMPTY_ERROR: 'Mật khẩu Được Yêu Cầu',
        PASSWORD_LENGTH_ERROR: 'Mật khẩu Phải Dài Hơn 6 Ký Tự',
        SUBMIT_BUTTON_TEXT: 'Đăng Nhập',
        DONT_HAVE_ACCOUNT: "Bạn Chưa Có Tài Khoản?",
        CREATE_NEW_ONE: "Đăng Ký",
        FORGOT_PASSWORD_BUTTON_TEXT: 'Quên Mật khẩu?',
        ERRORS: {
          NOT_EXIST: "Thư Điện Tử Chưa Được Đăng Ký",
          WRONG_PASSWORD: 'Sai Mật Khẩu Đã Được Đăng Nhập',
          TOO_MANY_ATEMP: 'Tài khoản của bạn đã bị tắt do quá nhiều lần đăng nhập không thành công, vui lòng liên hệ với bộ phận hỗ trợ.',
          TIME_LIMIT: 'Quá thời gian cho phép.',
          NOT_ACTIVE: "Tài khoản của bạn không ở trạng thái 'Hoạt động'. Vui lòng liên hệ với bộ phận hỗ trợ.",
          PERMANENT_EXCLUDE: ' Tài khoản của bạn đã bị loại trừ vĩnh viễn khỏi trang này.',
          SUSPENDED: 'Bạn đã tự tạm ngừng chơi trò chơi .',
          TEMPORARY_SUSPENDED: 'Tài khoản của bạn đã bị tạm thời đình chỉ hoạt động. Nếu vấn đề này không được giải quyết trong vòng 24 giờ, vui lòng liên hệ với bộ phận hỗ trợ.',
          ERROR_OCCURED: 'Một lỗi đã xảy ra khi bạn cố gắng đăng nhập. Bạn được yêu cầu thử lại.',
          SUCCESS_LOGIN: 'Đã Đăng Nhập Thành Công',
          INACTIVITY: 'Phiên làm việc trước đó của bạn đã hết hạn do không hoạt động'
        },
        USING_SOCIAL: 'Login using'
      },
      SIGNUP: {
        TITLE: 'Đăng ký tại {{sitename}}',
        EMAIL: 'Thư Điện Tử',
        EMAIL_EMPTY_ERROR: "Vui Lòng Nhập Một Địa Chỉ Thư Điện Tử",
        EMAIL_INVALID_ERROR: 'Vui Lòng Nhập Một Địa Chỉ Thư Điện Tử Hợp Lệ',
        EMAIL_ALREADY_USED_ERROR: 'Địa chỉ email đó đã được sử dụng trước đó. Nếu bạn đang cố gắng hoàn tất việc đăng ký của mình, vui lòng đăng nhập để tiếp tục từ nơi bạn đã dừng lại.',
        PHONE_NUMBER: "Số Điện Thoại",
        PHONE_EMPTY_ERROR: 'Vui Lòng Nhập Một Số Điện Thoại',
        PHONE_INVALID_ERROR: 'Vui Lòng Nhập Một Số Điện Thoại Hợp Lệ',
        PHONE_ALREADY_USED_ERROR: 'That phone number is already in use. If you are trying to complete your registration, please sign in to continue where you left off.',
        VERIFICATION_CODE: 'Mã Xác Minh',
        VERIFY_BUTTON_TEXT: 'Xác Minh',
        GET_CODE_TXT: 'Nhận Mã',
        CODE_SENT: 'Code Sent',
        RESEND: 'Resend',
        PASSWORD: 'Mật khẩu',
        PASSWORD_EMPTY_ERROR: 'Mật khẩu Được Yêu Cầu',
        PASSWORD_LENGTH_ERROR: 'Mật khẩu Phải Dài Hơn 6 Ký Tự',
        PASSOWRD_PATTERN_ERROR: 'Mật khẩu cần phải bao gồm ít nhất một số, một chữ thường, một chữ hoa và một ký tự đặc biệt không phải là ký tự trong bảng chữ cái:',
        REFERRAL_CODE: 'Mã giới thiệu / mã khuyến mãi (Tùy chọn)',
        REFERAL_CODE_ERROR: 'Mã khuyến mãi bạn đã nhập không còn hiệu lực',
        TERMS_DESC: 'Khi đăng ký, bạn đồng ý với',
        TERMS_BUTTON_TEXT: 'Điều Khoản & Điều Kiện',
        SUBMIT_BUTTON_TEXT: 'Bắt đầu',
        ALREADY_HAVE_ACCOUNT: "Đã có sẵn tài khoản?",
        SIGNIN_BUTTON_TEXT: 'Đăng Nhập',
        ERRORS: {
          ALREADY_EXIST: 'Thư Điện Tử Đã Tồn Tại',
          NOT_CREATED: 'Không thể tạo tài khoản và được yêu cầu liên hệ với bộ phận hỗ trợ khách hàng để giải quyết vấn đề.'
        },
        VERIFY_CODE_REQUIRED: 'Mã xác minh là bắt buộc',
        VERIFICATION_CODE_LENGTH_ERROR: "Mã xác minh phải là 6 số",
        VERIFICATION_WRONG_CODE: "Mã Xác Minh Sai",
        SENT_VERIFY_EMAIL: "Chúng Tôi Gửi Thư Điện Tử Xác Minh",
        SENT_VERIFY_SMS: "We've sent the sms code for verification",
        USING_SOCIAL: 'Register using',
        CAPTCHA: "CAPTCHA",
        CAPTCHA_REQUIRED: "CAPTCHA is required",
        CAPTCHA_PATTERN_ERROR: "Please enter valid CAPTCHA",
        CAPTCHA_WRONG: "Invalid CAPTCHA, please try again"
      },
      FORGOT_PASSWORD: {
        TITLE: "Quên Mật Khẩu?",
        DESCRIPTION: 'Quên Mật Khẩu?',
        EMAIL: 'Thư Điện Tử',
        EMAIL_EMPTY_ERROR: "Vui Lòng Nhập Một Địa Chỉ Thư Điện Tử",
        EMAIL_INVALID_ERROR: 'Vui Lòng Nhập Một Địa Chỉ Thư Điện Tử Hợp Lệ',
        PHONE_NUMBER: "Số Điện Thoại",
        PHONE_EMPTY_ERROR: 'Vui Lòng Nhập Một Số Điện Thoại',
        PHONE_INVALID_ERROR: 'Vui Lòng Nhập Một Số Điện Thoại Hợp Lệ',
        VERIFICATION_CODE: 'Mã Xác Minh',
        VERIFY_BUTTON_TEXT: 'Xác Minh',
        SUBMIT_BUTTON_TEXT: 'Gửi',
        SUCCESS_MSG: 'Liên kết email để đặt lại đã được gửi thành công',
        VERIFICATION_CODE_LENGTH_ERROR: "Verification code must be 8 characters",
        SUCCESS_RESET_MSG: 'Your password has been reset successfully. Please login with new password again'
      },
      LOGOUT: {
        MULTI_DEVICE: "Bạn đã bị đăng xuất khỏi tài khoản của mình do đăng nhập từ nhiều thiết bị cùng một lúc",
        INACTIVITY: 'Bạn đã bị đăng xuất do không hoạt động',
        DEFAULT: 'Bạn đã được đăng xuất'
      },
      OR: 'HOẶC',
      GOOGLE_BUTTON_TEXT: "Google"
    },
    DEPOSIT: {
      CURRENCY_TITLE: 'Tiền Gửi',
      CHOOSE_PAYMENT_TITLE: 'Mạng',
      AMOUNT: 'Số lượng',
      INSTANT: 'Ngay Lập Tức',
      MIN: 'Nhỏ nhất',
      MAX: 'Lớn Nhất',
      SUBMIT_BUTTON_TEXT: 'Tiền Gửi',
      NEED_HELP: 'Cần sự trợ giúp?',
      CONTACT_SUPPORT: 'Liên hệ với hỗ trợ trực tiếp của chúng tôi',
      CRYPTO_DEPOSIT_TITLE: 'Tiền Gửi',
      CRYPTO_DEPOSIT_DESCRIPTION: 'Đây là địa chỉ gửi tiền riêng của bạn. Bất kỳ khoản tiền gửi nào bạn thực hiện sẽ xuất hiện trong số dư của bạn sau khi được xác nhận trên blockchain. Chúng tôi sẽ thông báo cho bạn qua email khi khoản tiền gửi đến.',
      MINIMUM_DEPOSIT_AMOUNT: 'Số tiền gửi tối thiểu là {{amount}} {{currency}}',
      SEND_MONEY_TO: 'Gửi bất kỳ số tiền nào của {{currency}} (bao gồm phí cho người đào)đến:',
      NO_PAYMENT_METHOD: 'Không có phương thức thanh toán nào có sẵn',
      ADDRESS_COPIED: "Copy and Deposit",
      CLUB_PAY_TYPE: "Pay Type"
    },
    WITHDRAW: {
      CLUB_PAY_TYPE: "Pay Type",
      CURRENCY_TITLE: 'Tiền tệ rút ra',
      CHOOSE_PAYMENT_TITLE: 'Mạng',
      INFO_TITLE: 'thông tin rút tiền',
      BANK_CODE_TITLE: 'Mã nhận dạng nhà phát hành',
      BANK_NAME_TITLE: 'Tên người hưởng',
      ACCOUNT_TYPE: "Account Type",
      BANK_ACCOUNT_TITLE: 'Tài khoản người hưởng',
      AMOUNT: 'Tổng số',
      INSTANT: 'Ngay Lập Tức',
      MIN: 'Nhỏ nhất',
      MAX: 'Lớn nhất',
      SUBMIT_BUTTON_TEXT: 'Rút tiền',
      NEED_HELP: 'Cần sự trợ giúp?',
      CONTACT_SUPPORT: 'Liên hệ với hỗ trợ trực tiếp của chúng tôi',
      EXIST_ACCOUNT_TITLE: 'Tài khoản tồn tại',
      ADDRESS: 'Địa chỉ',
      WITHDRAW_FEE_ESTIMATED: 'Phí rút tiền dự kiến sẽ là {{amount}} {{currency}}',
      MINIMUM_WITHDRAW_AMOUNT: 'Số tiền rút tối thiểu là {{amount}} {{currency}}',
      WITHDRAW_DEDUCT_DESC: 'Khi rút tiền, sẽ bị khấu trừ 10% phí xử lý',
      COMPLETE_EMAIL_VERIFY: 'Vì lý do an toàn, vui lòng hoàn tất xác minh qua email trước khi thực hiện rút tiền',
      VERIFY_EMAIL_BUTTON: 'Xác minh Địa chỉ Email',
      COMPLETE_PHONE_VERIFY: 'For safety reasons, complete phone verification before withdrawal',
      VERIFY_PHONE_BUTTON: 'Verify Phone Address',
      COMPLETE_EMAIL_PHONE_VERIFY: 'For safety reasons, complete email and phone verification before withdrawal',
      VERIFY_EMAIL_PHONE_BUTTON: 'Verify Now',
      COMPLETE_PROFILE: 'Vì lý do an toàn, vui lòng hoàn tất thông tin hồ sơ của bạn trước khi thực hiện rút tiền',
      ACCOUNT_BUTTON: 'Tài khoản',
      WITHDRAW_SUCCESS: 'Yêu cầu rút tiền đã được gửi, vui lòng theo dõi tài khoản rút tiền của bạn, cảm ơn bạn',
      HOME_BUTTON: 'Trang chủ',
      RESTRICTED_BALANCE: 'Bạn có {{currency}} {{amount}} trong số dư bị hạn chế.',
      RESTRICTED_BALANCE_VIEW: 'Xem',
      NO_PAYMENT_METHOD: 'Không có phương thức thanh toán nào có sẵn',
      IFSC_TITLE: 'IFSC Number',
      INVALID_AMOUNT_ERROR: {
        EMPTY_AMOUNT: 'Please input amount',
        LESS_ZERO: 'Amount cannot be less than zero',
        LESS_MIN: 'Amount is less than minimum value',
        GREATER_MAX: 'Exceed max value',
        GREATER_BALANCE: 'Exceed balance',
        GREATER_AVAILABLE: 'Insufficient Balance'
      },
      ADDRESS_REQUIRED: "Address is required",
      ADDRESS_PATTERM_ERROR: "Address is not correct"
    },
    GAME_GALLEY: {
      NO_GAME_DATA: 'Không có trò chơi nào trong danh sách của bạn. Vui lòng nhấn nút trang chủ và thưởng thức một trong nhiều trò chơi.',
      DISPLAY_COUNT_GAMES: 'Hiển thị {{showCount}} của {{totalCount}} trò chơi'
    },
    GAME: {
      LOADING: 'Quá trình tải',
      PLAY_BUTTON_WITHOUT_LOGIN: 'Đăng nhập',
      PLAY_BUTTON_WITH_LOGIN: 'Chơi',
      DEMO_PLAY_BUTTON: "Demo",
      RTP: 'Tỷ lệ trả thưởng hiệu quả',
      RECENT_BIGGEST_WIN: 'Thắng lớn gần đây nhất',
      MY_GAMES_TITLE: 'Trò chơi của tôi'
    },
    ACTIVE_ACCOUNT: {
      TITLE: 'Xác nhận thư điện tử',
      EMAIL_VERIFY_DESCRIPTION: 'Vui lòng đăng nhập vào email của bạn, sau đó nhấn vào liên kết trong email để hoàn tất quá trình xác minh',
      GET_CODE_TEXT: 'Send Code',
      RESEND_BUTTON_TEXT: 'Gửi lại',
      SUBMIT_BUTTON_TEXT: 'Submit',
      NOT_RECEIVE_TXT: "Didn't receive the code?",
      CODE: 'Code',
      CODE_INPUT_PLACEHOLDER: 'Fill in the code here'
    },
    EMAIL_VERIFY: {
      DISPLAY_INFO: 'Đang tiến hành xác minh. Vui lòng đợi trong một thời gian...',
      ERROR_TITLE: 'Xin lỗi, đã xảy ra một vấn đề.',
      VERIFY_ERROR: 'Không tìm thấy mã xác minh qua thư điện tử',
      SUCCESS_TITLE: 'Thành công!',
      VERIFY_SUCCESS: 'thư điện tử của bạn đã được xác minh thành công'
    },
    BONUSES: {
      BONUSES_TITLE: 'Khuyến mãi',
      BONUS_TITLE: 'Phần thưởng',
      NO_BONUSES_DATA: 'Hiện tại không có chương trình khuyến mãi nào có sẵn',
      CHECK_LATER: 'Hãy quay lại sau!',
      MORE_DETAIL_BUTTON_TEXT: 'Xem thêm',
      APPLY_BUTTON_TEXT: 'Đăng nhập ngay bây giờ',
      TAB1: 'Casino',
      TAB2: 'Sport',
      ENDS_AT: 'Ends :',
      EVENT_ENDED: 'Event Ended'
    },
    EMPTY_PAGE: {
      NOT_FOUND: 'Oops.. Không tìm thấy trang.',
      REFRESH_OR_GOHOME: 'Vui lòng làm mới trang hoặc nhấn vào dưới đây để quay lại trang chủ',
      ACTION_BUTTON_TEXT: 'Trang chủ'
    },
    FOOTBALL_PAGE: {
      TITLE: 'Thể thao',
      DESCRIPTION: 'Khắc phục lỗi hoặc quay trở lại trang chủ!',
      ACTION_BUTTON_TEXT: 'Trang chủ'
    },
    LOGOUT_CONFIRM: {
      TITLE: 'Đăng xuất',
      DESCRIPTION: "Đừng quên kiểm tra các chương trình khuyến mãi lớn của chúng tôi về Casino và thể thao trước khi bạn ra đi!",
      BUTTON_TEXT: 'Đăng xuất'
    },
    ACCOUNT_INFO: {
      UID: 'UID',
      USERNAME_TITLE: 'Tên đăng nhập',
      EMAIL_TITLE: 'Thư điện tử',
      PHONE_TITLE: 'Số điện thoại',
      PHONE_PLACEHOLDER: 'Add Phone number',
      PASSWORD_TITLE: 'Mật Khẩu',
      EDIT_BUTTON_TEXT: 'Chỉnh sửa',
      VERIFY_BUTTON_TEXT: 'Xác minh',
      ADD_EMAIL: 'Thêm thư điện tử',
      COPY_BUTTON_TXT: 'Copy',
      SUMMARY_TEXT: ''
    },
    ADD_EMAIL: {
      TITLE: 'Thêm thư điện tử',
      DESCRIPTION: 'Thêm địa chỉ thư điện tử của bạn để bảo mật tài khoản',
      EMAIL_PLACEHOLDER: 'Thư điện tử',
      VERIFICATION_CODE: 'Mã xác minh',
      VERIFY_BUTTON_TEXT: 'Xác minh',
      SUBMIT_BUTTON_TEXT: 'Lưu'
    },
    CHANGE_PASSWORD: {
      TITLE: 'Thay đổi mật khẩu',
      CURRENT_PW_PLACEHOLDER: 'Mật khẩu hiện tại',
      CURRENT_PW_REQUIRED: 'Vui lòng đăng nhập mật khẩu hiện tại',
      CURRENT_PW_WRONG: 'Mật khẩu hiện tại nhập sai',
      NEW_PW_PLACEHOLDER: 'Mật khẩu mới',
      NEW_PW_REQUIRED: 'Vui lòng đăng nhập một mật khẩu mới',
      NEW_PW_LENGTH_ERROR: 'Mật khẩu phải có ít nhất 6 ký tự',
      CONFIRM_PW_PLACEHOLDER: 'Nhập lại Mật khẩu Mới',
      CONFIRM_PW_REQUIRED: 'Vui lòng xác nhận Mật khẩu Mới của bạn',
      NEW_PW_NOT_MATCH: 'Mật khẩu đã nhập không khớp',
      NEW_PW_NOT_CURRENT: 'Mật khẩu mới không được trùng với mật khẩu hiện tại của bạn',
      SUBMIT_BUTTON_TEXT: 'Lưu',
      MESSAGES: {
        SUCCESS: 'Mật khẩu của bạn đã thay đổi',
        WRONG_CURRENT_PASSWORD: 'Bạn đã nhập sai mật khẩu hiện tại của mình.',
        NEW_NOT_MATCH_CURRENT: 'Mật khẩu mới của bạn không thể trùng với mật khẩu hiện tại của bạn..'
      }
    },
    EDIT_USERNAME: {
      TITLE: 'Sửa tên đăng nhập',
      PLACEHOLDER_TEXT: 'Tên đăng nhập',
      SUBMIT_BUTTON_TEXT: 'Lưu',
      MESSAGES: {
        USERNAME_REQUIRED: 'Vui lòng nhập tên đăng nhập.',
        USERNAME_LENGTH: 'Tên đăng nhập phải dài hơn 3 ký tự'
      }
    },
    RESET_PASSWORD: {
      TITLE: 'Đặt lại mật khẩu',
      DESCRIPTION: 'Đặt lại mật khẩu',
      PASSWORD_PLACEHOLDER: 'Mật khẩu',
      PW_LENGTH_ERROR: 'Mật khẩu phải ít nhât 6 ký tự.',
      PW_REQUIRED: 'Vui lòng đăng nhập một mật khẩu mới.',
      PW_PATTERN_ERROR: 'Mật khẩu phải bao gồm số, chữ in hoa và ký tự đặc biệt.',
      CONFIRM_PW_PLACEHOLDER: 'Nhập lại mật khẩu để xác nhận',
      CONFIRM_PW_REQUIRED: 'Vui lòng xác nhận mật khẩu mới của bạn.',
      CONFIRM_PW_MISMATCH: 'Các mật khẩu không khớp.',
      NEW_PW_NOT_CURRENT: 'Mật khẩu mới của bạn không thể trùng với mật khẩu hiện tại của bạn.',
      SUBMIT_BUTTON_TEXT: 'Đặt lại',
      MESSAGES: {
        TOKEN_INVALID: 'Mã thông báo cung cấp không hợp lệ',
        TOKEN_EXPIRED: 'Mã thông báo cung cấp quá hạn',
        SUCCESS: 'Mật khẩu Của bạn đã được đặt lại. Vui lòng đăng nhập bằng mật khẩu',
        FAILD: 'Cố gắng đặt lại mật khẩu thất bại. Vui lòng thử lại'
      }
    },
    UPDATE_PHONE: {
      TITLE: 'Chỉnh sửa số điện thoại',
      DESCRIPTION: 'Thêm số điện thoại của bạn để đảm bảo tính bảo mật tài khoản',
      PLACEHOLDER: 'Chỉ số duy nhất',
      PHONE_REQUIRED: 'Vui Lòng Nhập Một Số Điện Thoại',
      PHONE_INVALID: 'Vui Lòng Nhập Một Số Điện Thoại Hợp Lệ',
      VERIFY_CODE: 'Mã xác minh',
      VERIFY_BUTTON_TEXT: 'Xác minh',
      SUBMIT_BUTTON_TEXT: 'Gửi',
      MESSAGES: {
        FAILD: 'Có sự cố khi cố gắng cập nhật số điện thoại. Vui lòng liên hệ với bộ phận hỗ trợ khách hàng.'
      }
    },
    ACTIVE_BONUSES: {
      TITLE: 'Phần thưởng hoạt động',
      NO_DATA: "Hiện tại bạn không có bất kỳ phần thưởng hoạt động nào.",
      SHOW_RESULT: 'Bấm vào một phần thưởng để xem thông tin chi tiết đầy đủ về nó',
      BONUS_ACCOUNT: 'Tài khoản phần thưởng',
      DESC1: "Đôi khi bạn có thể nhận được một 'Phần thưởng' như một phần thưởng đặc biệt cho một hành động cụ thể, như việc thực hiện gửi tiền.",
      DESC2: "Số tiền 'Tổng số tiền bị hạn chế' (gồm tiền gửi của bạn và số tiền thưởng) bị hạn chế chỉ dùng để đặt cược trong các trò chơi. Sau khi bạn hoàn thành các yêu cầu đặt cược, 'Tổng số tiền bị hạn chế' sẽ được giải phóng và trở thành một phần của số dư tiền thực sự của bạn.",
      TABLE_HEADER: {
        COLUMN1: 'Tổng phần thưởng',
        COLUMN2: 'Phần thưởng còn lại',
        COLUMN3: 'THAM KHẢO',
        COLUMN4: 'NGÀY BẮT ĐẦU',
        COLUMN5: 'NGÀY HẾT HẠN',
        COLUMN6: 'TRẠNG THÁI',
        COLUMN7: 'Số tiền bị hạn chế'
      }
    },
    PAST_BONUSES: {
      TITLE: 'Các phần thưởng trước đây',
      DESCRIPTION: 'Dưới đây, bạn có thể xem tất cả các phần thưởng trước đó mà bạn đã nhận. Vui lòng chọn một khoảng thời gian ngày tháng:',
      FROM_DATE_LABEL: 'Từ',
      INPUT_DATE_PLACEHOLDER: 'Chọn một ngày',
      TO_DATE_LABEL: 'Đến',
      SUBMIT_BUTTON_TEXT: 'GỬI',
      NO_RESULT: 'Không có kết quả nào được tìm thấy.',
      SHOW_RESULT: 'Chọn bất kỳ phần thưởng nào dưới đây để xem thêm chi tiết:',
      TABLE_HEADER: {
        COLUMN1: 'Tham khảo',
        COLUMN2: 'Tổng phần thưởng',
        COLUMN3: 'Ngày bắt đầu',
        COLUMN4: 'Ngày hết hạn',
        COLUMN5: 'Trạng thái',
      }
    },
    BONUS_DETAIL: {
      TITLE: 'Phần thưởng-Chi tiết',
      TERMS_SERVICE: 'Điều Khoản & Điều Kiện',
      BONUS: 'Phần thưởng',
      START_DATE: 'Ngày bắt đầu',
      EXPIRED_DATE: 'Ngày hết hạn',
      PROGRESS: 'Quá trình',
      CASINO: 'Casino',
      SPORTS: 'Thể thao',
      LIVE: 'Live',
      VALID_GAMES: 'Các trò chơi hợp lệ',
      ROLLOVER: 'Yêu cầu đặt cược lại',
      WAGERS_REMAIN: 'Số lượt cược còn lại',
      RESTRICT_AMOUNT: 'Số tiền bị hạn chế',
      WINNINGS: 'Số tiền thắng',
      DEPOSIT: 'Nạp tiền',
      TOTAL_RESTRICT_AMOUNT: 'Tổng số tiền bị hạn chế',
      FORFEIT_BUTTON_TEXT: 'Huỷ bỏ phần thưởng của tôi',
      FORFEIT_DESC1: "Nếu bạn từ bỏ phần thưởng này, chúng tôi sẽ loại bỏ:",
      FORFEIT_DESC2: '{{currency}} {{winning}} của số tiền thắng bị hạn chế.',
      FORFEIT_DESC3: '{{currency}} {{bonus}} của số tiền thưởng bị hạn chế.',
      FORFEIT_DESC4: 'Điều này sẽ để lại cho bạn {{currency}} {{deposit}} từ số tiền gửi của bạn.',
      FORFEIT_DESC5: 'Bạn chắc chắn muốn từ bỏ phần thưởng này sao?',
      CANCEL_BUTTON_TEXT: 'Hủy',
      CONFIRM_BUTTON_TEXT: 'Đúng, Từ bỏ phần thưởng',
      ERRORS: {
        CLOSE_OPENED_GAMES: 'Vui lòng đóng tất cả các trò chơi đã mở để từ bỏ phần thưởng.',
        RESOLVE_SPORTS_GAME: 'Một sự kiện thể thao đã giữ lại tiền từ phần thưởng này - vui lòng đợi cho đến khi sự kiện này được giải quyết trước.',
        RESOLVE_SAVED_GAME: 'Một trò chơi đã lưu lại tiền từ phần thưởng này - vui lòng giải quyết nó trước.'
      },
      APPLY_BUTTON_TXT: {
        DEFAULT: 'Deposit Now',
        LABEL1: 'Apply Now',
        LABEL2: 'Deposit Now',
        LABEL3: 'Play Now',
        LABEL4: 'Redeem Now',
        LABEL5: 'More Detail'
      }
    },
    REDEEM_BONUS: {
      TITLE: 'Promo Code',
      DESCRIPTION: "Join {{name}} community and receive a promo code!",
      INPUT_LABEL: 'Mã khuyến mãi:',
      INPUT_PLACEHOLDER: 'Promo Code',
      SUBMIT_BUTTON_TEXT: 'Rút',
      FAILD: 'Mã khuyến mãi không đúng hoặc đã hết hạn, vui lòng thử một mã khác.',
      NOVALID: "Promo code not available",
      SUCCESS: "Promo Code has been used successfully",
      SUCCESS_BUTTON_TXT: "Finish"
    },
    REFER_FRIEND: {
      TITLE: 'Giới thiệu một người bạn',
      SUBTITLE1: 'Mời bạn, kiếm tiền',
      DESCRIPTION1: 'Nhận số dư tiền mặt cho mỗi người bạn mà bạn giới thiệu{{website}}',
      TOTAL_REFERRED: 'Tổng số người dùng bạn đã giới thiệu',
      SUBTITLE2: 'Chia sẻ mã mời của bạn',
      DESCRIPTION2: 'Mã giới thiệu của bạn'
    },
    SPORTS_PAGE: {
      LOADING: 'Đang tải'
    },
    GAME_HISTORY: {
      TITLE: "Lịch sử trò chơi",
      TABLE: {
        COLUMN1: "Trò chơi",
        COLUMN2: "Kết quả đặt cược",
        COLUMN3: "Thời gian",
        COLUMN4: "Tổng số cược",
        COLUMN5: "Số nhân",
        COLUMN6: "Thanh toán"
      },
      NO_HISTORY: "Không có lịch sử trò chơi",
      SHOW_DATA_DESC: 'Chỉ có 50 hồ sơ trò chơi cuối cùng có sẵn',
      TOTAL: "Tổng số",
      WIN: 'Thắng',
      LOSS: 'Thua',
      DRAW: 'Hòa',
      UNSETTLED: 'UNSETTLED',
      MOBILE: {
        CASH: 'Trò Chơi Slots',
        BET_AMOUNT: 'Tổng số cược',
        MULTIPLIER: 'Số nhân',
        PAYOUT: 'Thanh toán'
      },
      SHAREING: {
        SUCCESS: {
          TITLE1: 'More than just wins,',
          TITLE2: 'earn extra tokens and dividends here.',
          BET_AMOUNT: 'by betting',
          MULTIPLIER: "Multiplier {{value}}x",
          PLACED_USER: 'Placed By: {{username}}',
          REFERRAL_CODE: 'Referral Code',
          CTA_SENTENCE2: 'Share NOW & win lottery rewards',
          DOWNLOAD_BTN: 'Download',
          SHARE_BTN: 'Share'
        },
        FAILD: {
          TITLE: 'Fail To Share',
          ALREADY_SHARED_GAME_LOG: 'This win has been shared by other people.',
          ABOVE_ONE_MULTIPLIER: 'Only transactions that are above 1x Multiplier can be shared.',
          EXCEEDED_DAILY: "You have reached your daily limit."
        }
      }
    },
    LEADERBOARD: {
      TITLE: 'Bảng xếp hạng',
      TABLE: {
        COLUMN1: "",
        COLUMN2: "",
        COLUMN3: "Người chơi",
        COLUMN4: "Tổng số $FUN Token",
        COLUMN5: "Phần thưởng"
      },
      NO_DATA: 'Không có bảng xếp hạng'
    },
    NEW_BANK: {
      TITLE: 'Thông tin ngân hàng',
      PAYMENT_TYPE: 'Hình thức thanh toán',
      SELECT_PAYMENT_TYPE: 'Chọn một hình thức thanh toán',
      BANK_LIST: 'Danh sách ngân hàng',
      SELECT_BANK: 'Chọn ngân hàng',
      NAME: 'Tên đầy đủ',
      ACCOUNT_NUMBER: 'Số tài khoản',
      ACCOUNT_NUMBER_DESC: 'Chỉ 1 đến 17 chữ số',
      SUBMIT_BUTTON_TXT: 'Gửi',
      ADD_NEW_BUTTON_TXT: 'Thêm mới',
      CLOSE_BUTTON_TXT: 'Đóng'
    },
    PAYMENTS: {
      DEPOSIT_TXT: 'Tiền gửi',
      WITHDRAW_TXT: 'Rút'
    },
    PROFILE_INFO: {
      TITLE: 'Thông tin cá nhân',
      FIRST_NAME: 'Tên đầu tiên',
      FIRST_NAME_REQURIED_ERROR: 'Vui lòng nhập tên hợp pháp của bạn.',
      FIRST_NAME_PATTERN_ERROR: "Tên chỉ có thể chứa các chữ cái, dấu cách, dấu gạch nối và dấu chấm. Vui lòng nhập ít nhất 2 và tối đa 20 ký tự.",
      LAST_NAME: 'Họ',
      LAST_NAME_REQUIRED_ERROR: 'Vui lòng nhập họ hợp pháp của bạn.',
      LAST_NAME_PATTERN_ERROR: "Họ chỉ có thể chứa các ký tự chữ cái, dấu cách, dấu gạch nối và dấu chấm. Vui lòng nhập ít nhất 2 và tối đa 20 ký tự.",
      BIRTHDAY: {
        TITLE: 'Ngày tháng năm sinh',
        YEAR: 'Năm',
        MONTH: 'Tháng',
        DAY: 'Ngày',
        ERROR: 'Vui lòng nhập một ngày sinh hợp lệ.'
      },
      ADDRESS: {
        STREET: 'Địa chỉ đường phố',
        STREET_REQURIED_ERROR: 'Vui lòng nhập địa chỉ đường phố của bạn.',
        PO_BOX_ERROR: 'Không thể sử dụng hộp thư Bưu điện.',
        COUNTRY: 'Quốc gia',
        STATE: 'Tỉnh',
        CITY: 'Thành phố',
        CITY_REQURIED_ERROR: 'Vui lòng nhập tên thành phố bạn đang sinh sống.',
        CITY_PATTERN_ERROR: 'Vui lòng nhập tên thành phố hợp lệ. Thành phố không nên bao gồm số.',
        ZIPCODE: 'Postal Code',
        ZIPCODE_REQUIRED_ERROR: 'Vui lòng nhập mã vùng của bạn.',
        ZIPCODE_PATTERN_ERROR: 'Vui lòng chỉ nhập chữ cái và số. Không có khoảng trắng hoặc ký tự đặc biệt.',
        ZIPCODE_MINLENGTH_ERROR: 'Zipcode must be 5 charactors at least'
      },
      SUBMIT_BUTTON_TXT: 'Gửi',
      CHOOSE_FILE: 'Proof of Identity/Choose file',
      FILE_NOTE: "*Document accepted : Passport, driver’s license or ID card.",
      ALERT: {
        SUCCESS: {
          TITLE: 'Verification',
          MSG: 'Successfully updated'
        }
      }
    },
    PROVIDERS: {
      TITLE: 'Nhà phát hành trò chơi',
      GAMES_LENGTH: '{{count}} Trò chơi',
      GAMES: 'Trò chơi',
      CURRENT_DISPLAYING: 'Hiển thị {{count}} {{total}} nhà phát hành'
    },
    TERMS: {
      TITLE: 'Điều khoản và Điều kiện',
      PRINT_BUTTON_TXT: 'In',
      DOWNLOAD_BUTTON_TXT: 'Tải xuống'
    },
    TRANSACTIONS: {
      TITLE: 'Giao dịch',
      DEPOSIT_BUTTON_TXT: 'Tiền gửi',
      WITHDRAW_BUTTON_TXT: 'Rút tiền',
      BONUS_BUTTON_TXT: 'Phần thưởng',
      NO: 'Không',
      SHOW_DATA_DESC: 'Chỉ có 50 giao dịch cuối cùng',
      TOTAL: 'Tổng',
      deposit: 'Tiền gửi',
      withdraw: 'Rút tiền',
      bonuses: 'Phần thưởng',
      STATE: {
        CONFIRMED: "Confirmed",
        UNCONFIRMED: "Unconfirmed",
        UNSUCCESSFUL: "Unsuccessful"
      },
      DEPOSIT_TABLE: {
        COLUMN1: 'Mã đơn hàng',
        COLUMN2: 'Thời gian',
        COLUMN3: 'Tỉnh',
        COLUMN4: 'Tiền tệ',
        COLUMN5: 'Tiền gửi',
        COLUMN6: 'Số dư trước đó',
        COLUMN7: 'Số dư sau đó',
        COLUMN8: 'Amount'
      },
      WITHDRAW_TABLE: {
        COLUMN1: 'Mã đơn hàng',
        COLUMN2: 'Thời gian',
        COLUMN3: 'Tỉnh',
        COLUMN4: 'Tiền tệ',
        COLUMN5: 'Tiền gửi',
        COLUMN6: 'Số dư trước đó',
        COLUMN7: 'Số dư sau đó',
        COLUMN8: 'Amount'
      },
      BONUSES_TABLE: {
        COLUMN1: 'Loại',
        COLUMN2: 'Mã đơn hàng',
        COLUMN3: 'Thời gian',
        COLUMN4: 'Tổng số phần thưởng',
        COLUMN5: 'Bonus Name'
      }
    },
    USER_MENU: {
      WALLET: 'Ví',
      TRANSACTIONS: 'Giao dịch',
      GAME_HIISTORY: 'Lịch sử trò chơi',
      KYC_INFO: 'KYC',
      VIP: 'Câu lạc bộ VIP',
      REDEEM_COUPON: 'Đổi phiếu giảm giá',
      REFERRAL: 'Giới thiệu một người bạn',
      PROMOTIONS: 'Khuyến mãi',
      ACTIVE_BONUS:'Phần thưởng hoạt động',
      PAST_BONUS: 'Phần thưởng trước đây',
      LIVE_SUPPORT: 'Dịch vụ hỗ trợ trực tiếp',
      ACCOUNT: 'Hồ sơ'
    },
    STEP_COMPONENT: {
      STEP_TXT: 'Bước'
    },
    PLAYER_VIP: {
      MY_DATA: {
        VIP_PROGRESS: "Tiến trình VIP của bạn",
        TOOLTIP: 'Mỗi đặt cược $1 sẽ giúp bạn kiếm 1 XP. Bạn chơi càng nhiều, bạn sẽ nhanh chóng đạt đến cấp độ cao nhất.',
        RAKEBACK: 'Rakeback',
        RANK_UP_BUTTON_TXT: 'Tăng hạng',
        TOTAL_CLAIMED: 'Tổng Rakeback đã yêu cầu',
        CLAIM_BUTTON_TXT: 'Yêu cầu',
        REMAIN_UNTIL_NEXT: "{{xp}} XP cho đến VIP {{level}}",
        REACHED_MAX_LEVEL: "Bạn đã đạt đến cấp độ tối đa!"
      },
      BETSPLUS: {
        LEVEL: "Level",
        REQUIRED_XP: 'Required EXP {{amount}} {{currency}}',
        LEVELUP_DESC: 'Get rewarded every time you fill the progress bar. Leveling up entitles you to bigger and better rewards!',
        WITHDRAWN_AMOUNT: 'Amount that can be withdrawn',
        TOTAL_REWARDS: 'Total Rewards Available',
        EXCHANGE_BUTTON_TXT: 'Exchange',
        CLAIM_BUTTON_TXT: 'Claim',
        REWARD_REDEMPTION: "Reward Redemption",
        TOTAL_CLAIMED: 'Total Rewards Claimed',
        REWARD: "Reward"
      }
    },
    REFERRALS: {
      MY_REFERRALS: {
        TOTAL_COMMISSION_EARNING: 'Tổng thu nhập hoa hồng từ giới thiệu',
        REFERRED_YOU: "Người giới thiệu bạn",
        AMOUNT_REFERRED: 'Số lượng người dùng bạn đã giới thiệu',
        NUMBER_OF_USERS: "Number of users"
      },
      BETSPLUS: {
        TITLE: 'Millionaire Project',
        DESCR1: 'GET UP TO <span style="color: #FFC83A;">${{value}}</span>',
        DESCR2: 'AND UP TO <span style="color: #FFC83A;">{{percent}}%</span> COMMISSION',
        HOWTOWORK: 'how it works',
        TOOLTIP_DETAIL: 'Step 1: Share referral code to friends and social media. <br> Step 2: Guide them to get their deposit bonus. <br> Step 3: Recommend some great games for them. <br> Step 4: Claim and spend your referral rewards. <br> Step 5: Repeat the steps!',
        INVITE_SECT: {
          TITLE: 'Invite Users',
          SHARES: 'Shares',
          INVITEES: 'Invitees',
          INVITE_LINK: 'Invite link',
          INVITE_QR_CODE: 'Invite QR code',
          QUICK_SHARE: 'Quick share',
          DOWNLOAD_BTN: 'Download'
        },
        DATA_CENTER: {
          TITLE: 'Data Center',
          TOTAL_REVENUE: 'Total revenue',
          INVITATION: 'Invitation',
          WAGER: 'Wager',
          ACHIEVEMENT: 'Achievement',
          VIP: 'VIP',
          MORE_BTN: 'More'
        },
        REWARD_SECT: {
          TITLE: 'Reward Redemption',
          AMOUNT_WITHDRAWN: 'Amount that can be claimed',
          EXCHANGE_BUTTON_TXT: 'Exchange',
          CLAIM_BUTTON_TXT: 'Claim',
          TOTAL_REWARD: 'Total Rewards Available : {{value}}'
        },
        ACTIVITY_RULES: {
          TITLE: 'Activity Rules',
          SEE_MORE: 'See More',
          DATA1: {
            LABEL: 'INVITATION BONUS',
            TEXT: 'Every time you invite a friend whose deposit reaches 20 USD, you will get, 5 USD rewards.'
          },
          DATA2: {
            LABEL: 'BETTING COMMISSION',
            TEXT: 'Bonus Rules: All players at the "Promoter" level receive a percentage of the platform advantage bonus for every bet.'
          },
          DATA3: {
            LABEL: 'ACHIEVEMENT BONUS',
            TEXT: 'If the cumulative number of invitations meets the conditions, you will receive additional achievement rewards. <br> The more people you invite, the higher the reward.'
          },
          DATA4: {
            LABEL: 'VIP LEVEL-UP BONUS',
            TEXT: 'Every user you invite reaches the specified level in the game, and you will get additional VIP level-up bonus. <br> USD is a special currency launched by Platform . You can …'
          }
        },
        AMONG_BEST: 'Among the best',
        LIVE_REWARD: 'Live Rewards',
        QA: {
          TITLE: 'Q&A'
        },
        EXCHANGE: {
          MESSAGE: 'Received successfully',
          CONFIRM_BUTTON_TXT: 'Confirm'
        },
        ACHIEVE_BONUS: {
          TITLE: 'Achievement Bonus',
          DESCRIPTION: 'If the cumulative number of invitations meets the conditions, you will receive additional achievement rewards. The more people you invite, the higher the reward.',
          TABLE: {
            COLUMN1: 'Friend’s Level',
            COLUMN2: 'Several people',
            COLUMN3: 'Rewards'
          }
        },
        BETTING_COMMISSION: {
          TITLE: 'Betting Commission',
          SHORT_DESC:'Bonus Rules: All players at the "Promoter" level receive a percentage of the platform advantage bonus for every bet.',
          LONG_DESC: `The proportions of the 3 levels are as follows <br>
          Level 1: Get 35% Of 1% wager <br>
          Level 2: Get 12.25% Of 1% wager <br>
          Level 3: Get 4.29% Of 1% wager <br>
          This will be your long-term income, and every time a player you refer places a bet, you will receive a different percentage of the commission regardless of whether he wins or loses.So what you have to do is to improve your game skills, think about how to win the game and share it with others, and help more people win with your method.We want all players to have fun in our platform, whether it's the fun of winning bets or the game itself!`
        },
        USER_LIST: {
          TITLE: "Users List",
          TABS: {
            TAB1: 'Sub-user',
            TAB2: 'Subordinate users'
          },
          SEARCH_PLACEHOLDER: 'Search User UID',
          SELECTS: {
            ITEM1: 'All',
            ITEM2: 'Today',
            ITEM3: 'Past 7 days',
            ITEM4: 'Past 30 days',
            ITEM5: 'Past 60 days'
          },
          TABLE: {
            COLUMN1: 'Email / Phone',
            COLUMN2: 'Registered At',
            COLUMN3: 'Bonus',
            COLUMN4: 'BETTING',
            COLUMN5: 'INVITATION',
          },
          TOTAL: 'Total',
          NO_DATA: "No user data"
        },
        VIP_LEVEL_BONUS: {
          TITLE: 'VIP Level-Up Bonus',
          DESC: 'Every user you invite reaches the specified level in the game, and you will get additional VIP level-up bonus. USD is a special currency launched by Platform . You can Swap USD into other currencies at any time.',
          TABLE: {
            COLUMN1: 'Friend’s Level',
            COLUMN2: 'Total Wager',
            COLUMN3: 'Rewards'
          }
        }
      }
    },
    KYC: {
      TITLE: "KYC",
      DESC: "Due to the regulatory requirement from National Lottery Regulatory Commission (NLRC) and Curaçao operating license,we require you to verify your Email Address and Phone number to prevent fraud, cheating and suspicious activity. If you are experiencing issue, you may also seek assistance from Live Chat.",
      BUTTON_TXT: "Account"
    },
    GOOGLE_LOGIN: {
      EERROR_MSG: 'Không thể đăng nhập bằng thông tin tài khoản. Vui lòng thử lại với thông tin khác'
    },
    SUCCESS_MSG: {
      TITLE: 'Thành công!'
    },
    ERROR_MSG: {
      TITLE: 'Xin lỗi, có một vấn đề xảy ra'
    },
    COMMON: {
      COMING_SOON: "Sắp ra mắt",
      LOAD_MORE: "Tải thêm",
      LOGOUT: 'Đăng xuất',
      DEPOSIT: 'Nạp tiền',
      WITHDRAW: 'Rút tiền',
      INVALID_AMOUNT: 'Số tiền không hợp lệ',
      HOME_BUTTON_TXT: 'Trang chủ',
      BACK_BUTTON_TXT: 'Quay lại',
      COPIED: 'Copied',
      OK: "OK",
      CANCEL: "Cancel",
      SEARCH: "Search"
    },
    LUCKSPIN: {
      TITLE: "Lucky Spin",
      TRY_AGAIN: "Try again",
      BUTTON_TEXT: "Spin",
      WIN_SPIN_TEXT: "LUCKY WIN SPIN NOW",
      BOTTOM_TEXT: "DAILY FREE SPINS",
      AVAILABLE_SPINS: "Available spins"
    },
    ERRORS: {
      400: 'Illegal status',
      401: 'Unauthorized',
      402: 'Payment required',
      404: 'Not found',
      500: 'Internal server error',
      503: '[Ezugi] Invalid game identifier',
      504: '[Ezugi] Invalid operation',
      506: '[Ezugi] Invalid currency',
      508: '[Ezugi] Invalid transaction identifier',
      511: '[Leander] Invalid token',
      1002: '[Evolution] Invalid session id',
      1007: '[NYX] RC unknown currency',
      6001: '[Vanguard] Game session invalid',
      6003: '[Vanguard] Authentication failed',
      40011: '[RGS] Invalid game type',
      40012: '[RGS] Invalid game ID',
      10000: 'Accounts limit exceeded',
      10001: 'Addacct failed',
      10002: 'Age verification failed',
      10004: 'Already has account',
      10005: 'Already registered',
      10006: 'Already sent',
      10010: 'Bad argument',
      10017: "Bonus code not found",
      10018: 'Bonus does not exist',
      10019: 'Bonus invalid',
      10021: 'Can not approve payout request',
      10022: 'Can not cancel payout request',
      10024: 'Can not process payout',
      10025: 'Can not process payout request',
      10034: 'Currency code not found',
      10037: 'Customer already in',
      10038: 'Customer does not exist', //
      10042: 'Customer in blacklist',
      10045: 'Customer not found', //
      10046: 'Customer not registered', //
      10047: 'Customer self excluded',
      10049: 'Customer suspended',
      10052: 'Customer verification not found',
      10055: 'Cust update failed',
      10059: 'Disabled acct',
      10064: 'Email already exists',
      10066: 'Email code not found',
      10069: 'Email in use',
      10071: 'Email not found',
      10143: 'File invalid format',
      10145: 'Game already started',
      10146: 'Game code is not valid',
      10150: 'Game not found',
      10154: 'Get balance',
      10155: 'Get balance error',
      10173: 'Invalid amount max',
      10174: 'Invalid amount min',
      10177: 'Invalid bonus id',
      10178: 'Invalid bonus type',
      10186: 'Invalid customer',
      10190: 'Invalid game id',
      10200: 'Invalid session',
      10207: 'Invalid token',
      10221: 'Login failure',
      10222: 'Login max logins reached',
      10223: 'Login wrong password',
      10224: 'Login wrong username',
      10225: 'Logout failed',
      10246: 'Password not match',
      10250: 'Phone already exists',
      10307: 'Username already exists',
      10308: 'Validate birthdate failed',
      10325: 'The email is not registered yet',
      10326: 'The email has already been used as google sso account',
      10327: 'Wrong referral code',
      10328: 'Customer has not disbursed referral commission',
      10329: 'cannot level up',
      10330: 'Failed to claim VIP cashback',
      10331: 'Invalid customer VIP level',
      10332: 'Failed to claim referral commission',
      10333: 'Failed to get referral achievement information',
      10334: 'Invalid phone number',
      10341: "Not enough value to claim bonus",
      11507: 'Country not found',
      11511: 'Invalid casino bonus',
      11512: 'Please close all opened games in order to forfeit the bonus.',
      11513: 'A saved game reserved funds from this bonus - please resolve it first.',
      11514: 'A sport event reserved funds from this bonus - please wait for its resolution first.',
      11515: 'Inactive casino bonus',
      11516: 'Could not forfeit Any Deposit Rollover casino bonus.',
      11517: "Insufficient player's daily credit",
      11518: 'Failed to acquire prize',
      11519: "Insufficient prize's daily count",
      11520: "Please wait for the cooldown period before sending another email.",
      11521: "Please wait for the cooldown period before sending another sms.",
      11522: "Reach maximum times for sending email for verification in a day.",
      11523: "Reach maximum times for sending sms for verification in a day.",
      11526: "ClubPay Exception",
      100000: 'VALIDATE_SIGNUP_CODE_FAILED',
      100002: 'SEND_SMS_VALIDATION_FAILED'
    },
    BONUS_TYPES: {
      "Deposit": "Deposit",
      "Withdrawal": "Withdrawal",
      "Table Buyin": "Table Buyin",
      "Table Rebuy": "Table Rebuy",
      "Table Leave": "Table Leave",
      "Place Bet": "Place Bet",
      "Tournament Buyin": "Tournament Buyin",
      "Tournament Fee": "Tournament Fee",
      "Tournament Buyin Refund": "Tournament Buyin Refund",
      "Tournament Fee Refund": "Tournament Fee Refund",
      "Manual Adjustment": "Manual Adjustment",
      "Recovery Refund": "Recovery Refund",
      "Transfer In": "Transfer In",
      "Transfer Out": "Transfer Out",
      "Round Win": "Round Win",
      "Bonus Win": "Bonus Win",
      "Deposit Bonus": "Deposit Bonus",
      "Account Creation Bonus": "Account Creation Bonus",
      "Referral Bonus": "Referral Bonus",
      "Bonus Conversion": "Bonus Conversion",
      "Login Grant": "Login Grant",
      "Initial Grant": "Initial Grant",
      "Virtual Goods Purchase": "Virtual Goods Purchase",
      "Loyalty": "Loyalty",
      "Winning in random scratcher": "Winning in random scratcher",
      "Mystery Gift Win": "Mystery Gift Win",
      "FB Credit Order": "FB Credit Order",
      "Leader Board Points": "Leader Board Points",
      "Tournament Award": "Tournament Award",
      "Returning Player Bonus": "Returning Player Bonus",
      "Cancelled round refund": "Cancelled round refund",
      "Sit & Go Award": "Sit & Go Award",
      "Sit & Go Buyin": "Sit & Go Buyin",
      "Sit & Go Fee": "Sit & Go Fee",
      "Sit & Go Buyin Refund": "Sit & Go Buyin Refund",
      "Sit & Go Fee Refund": "Sit & Go Fee Refund",
      "Fast Poker buyin": "Fast Poker buyin",
      "Fast Poker leave": "Fast Poker leave",
      "Fast Poker rebuy": "Fast Poker rebuy",
      "Tournament Rebuy": "Tournament Rebuy",
      "Tournament AddOn": "Tournament AddOn",
      "Sit & Go Rebuy": "Sit & Go Rebuy",
      "Sit & Go AddOn": "Sit & Go AddOn",
      "Bonus Deposit": "Bonus Deposit",
      "Bonus Released": "Bonus Released",
      "Bonus Deposit Released": "Bonus Deposit Released",
      "Bonus Forfeit": "Bonus Forfeit",
      "Bonus Expired": "Bonus Expired",
      "Bonus Winning Released": "Bonus Winning Released",
      "Void Deposit": "Void Deposit",
      "Bonus Deposit Forfeit": "Bonus Deposit Forfeit",
      "Bonus Winnings Forfeit": "Bonus Winnings Forfeit",
      "Bonus Deposit Expired": "Bonus Deposit Expired",
      "Bonus Winnings Expired": "Bonus Winnings Expired",
      "SnG Buyin Recovery Refund": "SnG Buyin Recovery Refund",
      "SnG Fee Recovery Refund": "SnG Fee Recovery Refund",
      "Tournament Buyin Recovery Refund": "Tournament Buyin Recovery Refund",
      "Tournament Fee Recovery Refund": "Tournament Fee Recovery Refund",
      "Clearing released bonus": "Clearing released bonus",
      "Clearing released bonus deposit": "Clearing released bonus deposit",
      "Clearing released bonus winnings": "Clearing released bonus winnings",
      "Void Withdrawal": "Void Withdrawal",
      "Bonus Deposit Forfeit Cleared": "Bonus Deposit Forfeit Cleared",
      "Bonus Deposit Expired Cleared": "Bonus Deposit Expired Cleared",
      "Bonus Cash Grant": "Bonus Cash Grant",
      "Place a Purchase": "Place a Purchase",
      "Cancel a Purchase": "Cancel a Purchase",
      "Tournament Rebuy Refund": "Tournament Rebuy Refund",
      "Tournament Rebuy Recovery Refund": "Tournament Rebuy Recovery Refund",
      "Tournament AddOn Refund": "Tournament AddOn Refund",
      "Tournament AddOn Recovery Refund": "Tournament AddOn Recovery Refund",
      "SnG Rebuy Refund": "SnG Rebuy Refund",
      "SnG Rebuy Recovery Refund": "SnG Rebuy Recovery Refund",
      "SnG AddOn Refund": "SnG AddOn Refund",
      "SnG AddOn Recovery Refund": "SnG AddOn Recovery Refund",
      "Bounty Tournament Award": "Bounty Tournament Award",
      "Jackpot winning": "Jackpot winning",
      "Collectible game award": "Collectible game award",
      "Claim reward": "Claim reward",
      "Cancel reward": "Cancel reward",
      "Coupon redeemed": "Coupon redeemed",
      "Tournament Bounty Buyin": "Tournament Bounty Buyin",
      "Tournament Bounty Buyin Refund": "Tournament Bounty Buyin Refund",
      "Tournament Bounty Buyin Recovery Refund": "Tournament Bounty Buyin Recovery Refund",
      "SnG Bounty Buyin": "SnG Bounty Buyin",
      "SnG Bounty Buyin Refund": "SnG Bounty Buyin Refund",
      "SnG Bounty Buyin Recovery Refund": "SnG Bounty Buyin Recovery Refund",
      "Reward Claimed Refund": "Reward Claimed Refund",
      "Void reward": "Void reward",
      "Tournament Prize Pool Distribution On Recovery": "Tournament Prize Pool Distribution On Recovery",
      "SnG Prize Pool Distribution On Recovery": "SnG Prize Pool Distribution On Recovery",
      "Chips Coupon Claimed": "Chips Coupon Claimed",
      "Milestone Bonus": "Milestone Bonus",
      "External Grant": "External Grant",
      "Void Bet": "Void Bet",
      "Daily TopOff": "Daily TopOff",
      "Bonus Grant": "Bonus Grant",
      "Bonus Used": "Bonus Used",
      "Sport Bet": "Sport Bet",
      "Sport Win": "Sport Win",
      "Sport Win Adjustment": "Sport Win Adjustment",
      "Sport Round Refund": "Sport Round Refund",
      "Sport Round Void": "Sport Round Void",
      "Round Win Bonus": "Round Win Bonus",
      "IBC Sport Unsettle": "IBC Sport Unsettle",
      "IBC Sport Adjust Balance": "IBC Sport Adjust Balance",
      "VIP Level rank-up bonus": "VIP Level rank-up bonus",
      "VIP Level cashback bonus": "VIP Level cashback bonus",
      "Referral commission bonus": "Referral commission bonus",
      "Any Deposit Rollover Bonus": "Any Deposit Rollover Bonus",
      "KYC bonus": "KYC bonus",
      "Daily Lose Rake Back": "Daily Lose Rake Back",
      "Daily lose cashback bonus": "Daily lose cashback bonus",
      "IBC Sport Settled": "IBC Sport Settled"
    }
	}
};
