import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EditNamePageRoutingModule } from './edit-name-routing.module';

import { EditNamePage } from './edit-name.page';
import { SharedModule } from 'src/app/shared/shared.module';
import { SystemModule } from 'src/app/system/system.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    EditNamePageRoutingModule,
    SharedModule,
    SystemModule,
    TranslateModule
  ],
  declarations: [EditNamePage]
})
export class EditNamePageModule {}
