import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { format } from 'date-fns';

@Component({
  selector: 'app-leader-rang',
  templateUrl: './leader-rang.component.html',
  styleUrls: ['./leader-rang.component.scss'],
})
export class LeaderRangComponent implements OnInit {

  @Input() s_date: Date;

  dateRangs: any[] = [];

  format = format;

  constructor(
    private popCtrl: PopoverController
  ) { }

  ngOnInit() {
    let rang = [];
    for (let i = 0; i < 8; i++) {
      let tdate = new Date();
      let startDate = new Date(tdate.setDate(tdate.getDate() - (tdate.getDay() + (i * 7)) + 1));
      let endDate = new Date(tdate.setDate(tdate.getDate() - tdate.getDay() + 7));
      let item = {
        startDate,
        endDate,
        selected: format(this.s_date, 'yyyy-MM-dd') == format(startDate, 'yyyy-MM-dd') ? true : false
      }
      rang.push(item);
    }
    this.dateRangs = rang;
  }

  selectRang(rang) {
    this.dateRangs.forEach(el => el.selected = false);
    rang.selected = true;
    setTimeout(() => {
      this.popCtrl.dismiss(rang, 'selected');
    }, 360);
  }

}
