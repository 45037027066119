import { AppLinks } from './../../../shared/app-links';
import { MainProvider } from './../../../modules/lobby/providers/main.provider';
import { GameHubController } from './../../../modules/gameHub/game-hub-controller';
import { JurisdictionProvider, GeoResponse } from './../../../shared/providers/jurisdiction';
import { Utils } from './../../../shared/utils';
import { getSidemenuSettings } from './sidemenu-settings';
import { Component, Renderer2, ViewChildren, QueryList, Input, Inject, ViewChild } from '@angular/core';
import { UserSessionProvider } from './../../providers/user-session';
import { Navigator } from '../menu/navigator';
import { Config } from '../../providers/configuration';
import { sortBy } from '../../../shared/codeUtils';
import { DomController, IonAccordionGroup, MenuController, ModalController, NavController, Platform, PopoverController } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';
import { AuthenticationProvider } from 'src/app/modules/authentication/providers/authentication-provider';
import { AccountProvider } from 'src/app/modules/account/providers/account-provider';
import { HttpClient } from '@angular/common/http';
import { CommonService } from 'src/app/service/common.service';

/**
 * Generated class for the SideMenuContentComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
// MenuOptionModel interface
export interface MenuOptionModel {
  // if null -> use the 'ios-arrow-down'
  iconName?: string;

  // title to display on the menu
  displayName: string;

  // target component (null for login logout)
  component?: any;

  // passing data, functions, methods
  custom?: any;

  // if selected by default
  selected?: boolean;

  subItems?: Array<MenuOptionModel>;

  subItemsLevel3?: Array<MenuOptionModel>;

  isCategory?: boolean;

  isExternalLink?: boolean;

  // deposit, daily spin
  requiresQualification?: boolean;

  clickAction?: any;
  loadTab?: any;
  sectionbegins?: any;
  hr?: any;
  url?: any;
  tabName?: any;
  link?: any;
  iosCheck?: any;
  casinoCheck?: any;
  withdrawCheck?: any;
  coming_soon?: boolean;
  isDisabled?: boolean;
}


//SideMenuSettings Interface
export interface SideMenuSettings {
  accordionMode?: boolean;
  arrowIcon?: string;
  expandIcon?: string;
  collapseIcon?: string;

  itemHeight?: {
    ios?: number,
    md?: number,
    wp?: number
  };

  showSelectedOption?: boolean;
  selectedOptionClass?: string;

  indentSubOptionsWithoutIcons?: boolean;

  subOptionIndentation?: {
    ios?: string,
    md?: string,
    wp?: string
  };

  subOptionIndentationLevel3?: {
    ios?: string,
    md?: string,
    wp?: string
  };
}



/**
 * Generated class for the TestComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'sidemenu',
  templateUrl: 'sidemenu.html',
  styleUrls: ['./sidemenu.scss']
})
export class SidemenuComponent {

  @ViewChildren('options') optionDivs: QueryList<any>;
  @ViewChildren('optionsLevel3') optionDivsLevel3: QueryList<any>;
  @ViewChildren('headerIcon') headerIcons: QueryList<any>;
  @ViewChildren('headerIconLevel3') headerIconsLevel3: QueryList<any>;
  
  @Input() menuSelected: string = 'desktop';
  @Input() menus: Array<MenuOptionModel>;

  public menuSettings: SideMenuSettings;
  public menuOptions: Array<MenuOptionModel>;
  public isMobile: boolean = false;
  public webviewButtons;
  public externalWebviewButtons;
  private promoItem: any;
  private promoButtonPosition: string;
  public environmentName: string;
  public isIOSApp:boolean = false;
  public isSportsApp: boolean = false;
  public isWithdrawEnable: boolean;
  public isMobileApp:boolean = false;
  public isIABIOSApp:boolean = false;

  _category: any;
  _subCategory: any;
  @ViewChild(IonAccordionGroup, { static: true }) accordionGroup: IonAccordionGroup;

  constructor(
    public platform: Platform,
    private domCtrl: DomController,
    private renderer: Renderer2,
    public events: MyEvent,
    public modalCtrl: ModalController,
    public menuCtrl: MenuController,
    public userSession: UserSessionProvider,
    public authProvider: AuthenticationProvider,
    private config: Config,
    public accountProvider: AccountProvider,
    public utils: Utils,
    public jurisdiction: JurisdictionProvider,
    public mainProvider: MainProvider,
    private gameHubController: GameHubController,
    private appLinks: AppLinks,
    public http: HttpClient,
    private popCtrl: PopoverController,
    public comService: CommonService
  ) {
    
  }

  ngOnInit() {

    if (this.menus && this.menus.length) this.menuOptions = this.menus;
    else {
      this.mainProvider.getSidemenuData(this.menuSelected).then(
        data => {
          this.menuOptions = data.filter(el => !el.isDisabled);
        }
      );
    }

    // this.menuSettings = getSidemenuSettings();

    // this.webviewButtons =  this.config.getExternalLinks().filter(link => link.isHamburgerMenu == "1").sort(sortBy('lobbyNavBarPosition'));
    //       //Adding the call for External Links to fix the Env Selector issue for Mobile Apps to show environment specific links
    // let v = new Date().getTime();
    // let serviceUrl = this.config.getEnv().webservices_url + "/configuration/get-external-links?v=" + v;
    // this.http.get(serviceUrl).subscribe((data: any) => {
    // if ('payload' in data) {
    //     this.webviewButtons = data.payload.filter(link => link.isHamburgerMenu == "1").sort(sortBy('lobbyNavBarPosition'));
    //   } else {
    //     this.webviewButtons = [];
    //   }
    //   console.log('External Links in Menu - After Env Selector', this.webviewButtons);
    // });

    this.events.getObservable().subscribe((data) =>{
      if (data.event == "event:config:reload") {
        if (this.menus && this.menus.length) this.menuOptions = this.menus;
        else {
          this.mainProvider.getSidemenuData(this.menuSelected).then(
            data => {
              this.menuOptions = data.filter(el => !el.isDisabled);
            }
          );
        }
      }
    });
  }

  ngAfterViewInit() {
    if (sessionStorage.getItem('parent')) {
      this._category = sessionStorage.getItem('parent');
    }
    if (sessionStorage.getItem('subcate')) {
      this._subCategory = sessionStorage.getItem('subcate');
    }
  }

  changeAccordion($event) {
    this._category = $event.target.value;
    console.log('event == ', this.accordionGroup.value);
  }

  clickSubItem(parent, subcate) {
    if (subcate.subItems && subcate.subItems.length) {
      if (this._subCategory != subcate.displayName) this._subCategory = subcate.displayName;
      else this._subCategory = null;
    } else {
      this._subCategory = subcate.displayName;
      this.checkAction(subcate);
      sessionStorage.setItem('parent', parent.displayName);
      sessionStorage.setItem('subcate', subcate.displayName);
    }
  }

  clickSubSubItem(parent, subcate, ssubCate) {
    sessionStorage.setItem('parent', parent.displayName);
    sessionStorage.setItem('subcate', subcate.displayName);
    this.checkAction(ssubCate);
  }

  clickHeaderItem(cate) {
    if (!cate.subItems) {
      if (cate.loadModal) {
        var css = (cate.component == 'AccountInformationPage') ? 'account_info' : (cate.cssClass || '');
        Navigator.navigateTo(cate.component, {forceModal: true, cssClass: css});
        this.popCtrl.dismiss();
      } else if (cate.loadTab) {
        this.loadTab(cate);
      } else if (cate.isExternalLink) {
        this.openExternalLink(cate);
      } else {
        this.execute(cate.clickAction);
      }
    }
  }

  checkAction(item) {
    if (item.loadTab) {
      this.loadTab(item);
    } else if (!item.loadTab && item.isExternalLink) {
      this.openExternalLink(item.link);
    } else {
      this.select(item);
    }
  }

  private checkForMobile() {
    if (this.platform.is("cordova") && this.platform.is("ios")) {
        this.isIOSApp = true;
    } else {
        this.isIOSApp = false;
    }

    //Adding IOS IAB check for ios App sports lobby which loads webclient in iosApp so, we can show Delete Account for this case as well.
    if(this.mainProvider.isIABApp() && this.platform.is('ios')){
      this.isIABIOSApp = true;
    }
    if(this.platform.is("cordova")) {
      this.isMobileApp = true;
    } else {
        this.isMobileApp = false;
    }
  }

  getAppConfig() {
    this.http.get('/assets/web.config').subscribe((data: any) => {
      this.promoButtonPosition = data.promotions_button_position;
    });
  }

  onViewButtonClick(button) {
    this.menuCtrl.close();
    this.popCtrl.dismiss();
    if(this.mainProvider.isIABApp()){
      window.EXTERNAL_LINK_URL = button.url;
    } else {
      this.events.publishSomeData({event: "event:webview:load", data: button.url});
    }

  }

  select(option: MenuOptionModel) {
    if (!!option.requiresQualification) {
      this.runQualificationsActions(option);
    } else if (option.component) {
      this.navigateToAPage(option.component, option.custom);
    }
  }

  runQualificationsActions(option) {
    this.accountProvider.callGetQualification(option.requiresQualification).subscribe(
      customrQualification => {
        let qualifyParams = {
          finalDestination: option.component,
          params: { qualification: option.requiresQualification }
        }
        //is qualified
        if (this.utils.isQualified(customrQualification).result) {
          this.navigateToAPage(option.component, option.custom);
        } else {
          //not qualified
          // check for geo
          if (this.utils.isQualified(customrQualification).pageToNavigate === "InvalidGeoPage") {
            this.jurisdiction.listen().subscribe(geoResp => {
              if (geoResp.is_allowed) {
                this.navigateToAPage(option.component, option.custom);
              } else {
                this.navigateToAPage(this.utils.isQualified(customrQualification).pageToNavigate, qualifyParams);
              }
            }, err => {

              if (!!err && err.message && err.code) {
                Navigator.navigateTo("GeoNotEnabledPage");
              } else {
                this.navigateToAPage(this.utils.isQualified(customrQualification).pageToNavigate, qualifyParams);
              }
            });
          } else {
            this.navigateToAPage(this.utils.isQualified(customrQualification).pageToNavigate, qualifyParams);
          }
        }
      }
    );
  }

  navigateToAPage(pageName, params) {
    Navigator.navigateTo(pageName, {}, params);
    // this.menuCtrl.close();
    this.popCtrl.dismiss();
  }

  openExternalLink(args) {
    let configuredURL = args ? this.config.getConfig()[args.externalURL] : "";
    if (!configuredURL) return;
    this.popCtrl.dismiss();
    this.appLinks.openExternalPage(configuredURL);
  }

  loadTab(args) {
    if (args.tab == 'SPORTS') {
      this.mainProvider.setSportsTabURL(this.config.getConfig()[args.data.iFramePage]);
      this.mainProvider.setSportSubpage(args.data.iFramePage);
      this.events.publishSomeData({event: "event:tabs:load", tab: 'SPORTS'});
      this.events.publishSomeData({event: "event:sportstab:reload"});
    } else if (args.tab == 'transactions') {
      if (this.comService.isMobileWeb) {
        Navigator.navigateTo(args.component, {forceModal: true});
      } else {
        this.events.publishSomeData({event: 'event:tabs:load', tab: args.tab});
        this.events.publishSomeData({event: 'event:main:button'});
      }
    } else if (args.tab == 'game-history') {
      if (this.comService.isMobileWeb) {
        Navigator.navigateTo(args.component, {forceModal: true});
      } else {
        this.events.publishSomeData({event: 'event:tabs:load', tab: args.tab});
        this.events.publishSomeData({event: 'event:main:button'});
      }
    } else {
      this.events.publishSomeData({event: 'event:tabs:load', tab: args.tab});
      this.events.publishSomeData({event: 'event:main:button'});
    }
    this.popCtrl.dismiss();
  }

  execute(method) {
    this[method]();
    this.popCtrl.dismiss();
  }

  onLogout() {
    Navigator.navigateTo('LogoutConfirmComponent', {forceModal: true, cssClass: 'logout_confirm'}, {reason: 'MENU_CLICK_LOGOUT'});
    this.popCtrl.dismiss();
    // Navigator.navigateTo("LogoutPage", {}, { reason: 'MENU_CLICK_LOGOUT' });
  }

  closeGame() {
    this.gameHubController.killAllInstances();
  }

  onOpenInnerURL(url) {
    this.events.publishSomeData({event: "event:webview:load", data: url});
    this.menuCtrl.close();
    this.popCtrl.dismiss();
  }

  toggleItemOptions(optionsDivElement: any, headerLevel:any, arrowIcon: any, itemsCount: number, level: number): void {
    if (this.menuSettings.accordionMode) {
      this.collapseAllOptionsExceptToogdled(optionsDivElement, level);
      this.resetAllIconsExceptToggled(arrowIcon, level);
    }
    this.toggleOptionSubItems(optionsDivElement, this.itemHeight + 1, itemsCount);
    this.toggleOptionIcon(arrowIcon, level);
    this.menuOptionExpanded(headerLevel, level);
  }

  private collapseAllOptionsExceptToogdled(selectedOptionsContainer, level): void {
    if (level == 2) {
      this.optionDivs.forEach(optionDiv => {
        this.domCtrl.read(() => {
          let optionElement = optionDiv.nativeElement;
          if (optionElement.id !== selectedOptionsContainer.id && this.subItemsAreExpanded(optionElement)) {
            this.hideSubItems(optionElement);
          }
        });
      });
    } else {
      this.optionDivsLevel3.forEach(optionDiv => {
        this.domCtrl.read(() => {
          let optionElement = optionDiv.nativeElement;
          if (optionElement.id !== selectedOptionsContainer.id && this.subItemsAreExpanded(optionElement)) {
            this.hideSubItems(optionElement);
          }
        });
      });
    }

  }
  // Reset the arrow icon of all the options except the selected option
  private resetAllIconsExceptToggled(selectedArrowIcon: any, level: number): void {
    if (level == 2) {
      this.headerIcons.forEach(headerIcon => {
        this.domCtrl.read(() => {
          let iconElement = headerIcon.nativeElement;
          if (iconElement.id !== selectedArrowIcon.id && this.iconIsExpanded(iconElement)) {
            this.resetExpandCollapseIcon(iconElement);
          }
        });
      });
    } else {
      this.headerIconsLevel3.forEach(headerIcon => {
        this.domCtrl.read(() => {
          let iconElement = headerIcon.nativeElement;
          if (iconElement.id !== selectedArrowIcon.id && this.iconIsRotated(iconElement)) {
            this.resetIcon(iconElement);
          }
        });
      });
    }
  }

  private toggleOptionSubItems(optionsContainer: any, elementHeight: number, itemsCount: number) {
    this.domCtrl.write(() => {

      this.subItemsAreExpanded(optionsContainer)
        ? shrink()
        : expand()
    });

    let shrink = () => {
      this.renderer.setStyle(optionsContainer, 'height', '0px');
      this.renderer.setStyle(optionsContainer, 'box-shadow', `none`);
      this.renderer.setStyle(optionsContainer, 'padding-top', '0px');
      this.renderer.setStyle(optionsContainer, 'padding-bottom', '0px');

    }
    let expand = () => {
      //this.renderer.setStyle(optionsContainer, 'height', `${(elementHeight * itemsCount)}px`);
      this.renderer.setStyle(optionsContainer, 'height', `auto`);
    }
  }
  public get itemHeight(): number {
    if (this.platform.is('ios')) return this.menuSettings.itemHeight.ios;
    if (this.platform.is('desktop')) return this.menuSettings.itemHeight.wp;
    return this.menuSettings.itemHeight.md;
  }
  // Return true if sub items are expanded
  private subItemsAreExpanded(element: any): boolean {
    return element.style.height !== '' && element.style.height !== '0px';
  }
  // Return true if the icon is rotated
  private iconIsRotated(element: any): boolean {
    return element.classList.contains('rotate');
  }
  private iconIsExpanded(element: any): boolean {
    return element.classList.contains('switch');
  }
  private headerIsExpanded(element: any): boolean {
    return element.classList.contains('menu-expanded');
  }
  // Toggle the arrow icon of the selected option
  private toggleOptionIcon(arrowIcon: any, level: number): void {
    if (level == 3) {
      this.domCtrl.write(() => {
        this.iconIsRotated(arrowIcon)
          ? this.renderer.removeClass(arrowIcon, 'rotate')
          : this.renderer.addClass(arrowIcon, 'rotate');
      });
    }
  }
  // Adding class when menu is expanded
  private menuOptionExpanded(headerLevel: any, level: number): void {
    if (level == 3) {
      this.domCtrl.write(() => {
        this.headerIsExpanded(headerLevel)
          ? this.renderer.removeClass(headerLevel, 'menu-expanded')
          : this.renderer.addClass(headerLevel, 'menu-expanded');
      });
    }
  }

  // Collapse the sub items of the selected option
  private hideSubItems(optionsContainer: any): void {
    this.domCtrl.write(() => {
      this.renderer.setStyle(optionsContainer, 'height', '0px');
      this.renderer.setStyle(optionsContainer, 'box-shadow', 'none');
    });
  }
  // Reset the arrow icon of the selected option
  private resetIcon(arrowIcon: any): void {
    this.domCtrl.write(() => {
      this.renderer.removeClass(arrowIcon, 'rotate');
    });
  }
  // Reset the arrow icon of the selected option
  private resetExpandCollapseIcon(arrowIcon: any): void {
    this.domCtrl.write(() => {
      this.renderer.removeClass(arrowIcon, 'switch');
      this.renderer.removeClass(arrowIcon, 'icon-collapse');
      this.renderer.addClass(arrowIcon, 'icon-expand');
    });
  }
  isVerticalEnabled(vertical) {
    return this.mainProvider.isVerticalEnabled(vertical)
  }

  getSportsbookURL() {
    let url = '';
    let iphoneWithNotch = false;
    const data:any = {};

    if (this.platform.is("cordova") && (this.platform.is('ios'))) {
        let iphoneWithNotch = this.mainProvider.getIphoneWithNotch();
        if (iphoneWithNotch) {
          data.iphoneWithNotch = 'true';
        }
    }

    data.iframe = this.config.getConfig().FEATURE_SBTECH_URL;

    if (this.userSession.isAuthenticated()) {
      data.iframe = `${data.iframe}?stoken=${this.userSession.getSessionToken()}`;
    }

    data.v = this.config.cache_buster();

    const params = [];
    for (let d in data) {
      params.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
    let query = params.join('&');

    return `${this.config.getConfig().LOBBY_URL}/assets/static/mobilesports.html?${query}`;
  }

}
