import { Navigator } from './../../../../../system/components/menu/navigator';
import { CashierProvider } from '../../../providers/cashier-provider';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SuccessMessageProvider } from '../../../../../system/providers/success-message-provider';
import { Utils } from './../../../../../shared/utils';
import { Config } from './../../../../../system/providers/configuration';
import { AlertController, IonContent } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'page-withdrawal-reverse-withdrawal',
  templateUrl: 'withdrawal-reverse-withdrawal.html',
  styleUrls: ['./withdrawal-reverse-withdrawal.scss'],
})
export class WithdrawalReverseWithdrawalPage implements OnInit {

  @ViewChild('Content') content: IonContent;

  public error = null;
  public confirm: boolean = false;
  public pendingWithdrawals: any = [];
  public payoutLock: boolean;
  public getComplete;

  constructor(
    private cashierProvider: CashierProvider,
    public successMessageProvider: SuccessMessageProvider,
    public utils: Utils,
    private config: Config,
    private events: MyEvent,
    public comService: CommonService
  ) {
    let appConfigInfo = this.config.getAppConfigInfo();
    this.payoutLock = appConfigInfo.payoutLock === 'true';
    this.successMessageProvider.clearMessage();
    this.error = null;

    this.events.getObservable().subscribe(data => {
      if (data.event == 'success_reverse') {
        this.onGetPendingWithdrawals();
      }
    })
  }

  ngOnInit(): void {
    
  }

  ionViewDidEnter() {
    this.onGetPendingWithdrawals();
  }

  onGetPendingWithdrawals() {
    this.pendingWithdrawals = [];
    this.cashierProvider.callGetPendingWithdrawals().subscribe(
      (response: any) => {
        console.log('onGetPendingWithdrawals == ', response);
        if (response.code == 200 && response.result) {
          var tempWithdrawals: any = response.payload;
          for (let index in tempWithdrawals) {
            this.pendingWithdrawals.push(tempWithdrawals[index]);
          }
        }
        this.getComplete = true;
      },
      (error) => {
        this.error = error;
        this.getComplete = true;
      }
    );
  }

  onReverseClick(withdrawal) {
    this.successMessageProvider.clearMessage();
    this.error = null;
    Navigator.navigateTo(
      'WithdrawalReverseConfirmPage',
      { multipleModals: true },
      { payoutRequestID: withdrawal.payoutRequestID, amount: withdrawal.amount }
    );
  }

  onReverseWithdraw(payoutRequestID: string) {
    var requestObj: any = {};
    requestObj.payoutRequestID = payoutRequestID;

    console.log(
      'onReverseWithdraw WithdrawalMailPage',
      requestObj.payoutRequestID
    );

    this.cashierProvider.callReverseWithdrawal(requestObj).subscribe(
      (response: any) => {
        console.log('callReverseWithdrawal response = ', response);
        if (response && !!response.result) {
          this.onGetPendingWithdrawals();

          this.successMessageProvider.setMessage(
            'Your withdrawal was successfully reversed.'
          );

          this.content.scrollToTop();
        }
      },
      (error) => {
      }
    );

    this.error = null;
  }

  onFormatDate(date: string) {
    return this.utils.toAmericanDateStandardfromYYYY(date);
  }

  pendingWithdrawalsEmiter(data) {
    if (data.success) {
      this.onGetPendingWithdrawals();
    }
    if (data.error) {
      this.error = data.error;
    }
  }
}
