import { SystemModule } from '../../../../../system/system.module';
import { NgModule } from '@angular/core';
import { UpdatePhoneNumberPage } from './update-phone-number';
import { SharedModule } from '../../../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/components/components.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    UpdatePhoneNumberPage,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SystemModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    TranslateModule
  ],
})
export class UpdatePhoneNumberPageModule {}
