import { Config } from './../../../../../../system/providers/configuration';
import { Component, Input, OnInit } from '@angular/core';
import { Navigator } from './../../../../../../system/components/menu/navigator';
import { RewardsProvider } from './../../../../providers/rewards-provider';
import { Utils } from '../../../../../../shared/utils';
import { SuccessMessageProvider } from './../../../../../../system/providers/success-message-provider';
import { isUndefined } from './../../../../../../shared/codeUtils';
import { AlertController, NavParams } from '@ionic/angular';
import { CommonService } from 'src/app/service/common.service';
import { TranslationService } from 'src/app/service/translation.service';

@Component({
  selector: 'page-casino-bonus-details',
  templateUrl: 'casino-bonus-details.html',
  styleUrls: ['./casino-bonus-details.scss'],
})
export class CasinoBonusDetailsPage implements OnInit {

  @Input() data: any;

  error = null;
  public bonusAmount: number;
  public totalBonus: string;
  public bonus: any;
  public bonusPageSource: any;
  public showForfeitConfirm: boolean = false;
  public hasBeenForfeit: boolean = false;
  public featureSiteTermsLink;
  public wager;

  sportsEnabled = false;
  casinoEnabled = false;
  liveEnabled = false;

  currency: any;
  rollovers: any[] = [];

  constructor(
    private alertCtrl: AlertController,
    private rewardsProvider: RewardsProvider,
    public utils: Utils,
    public config: Config,
    public successMessageProvider: SuccessMessageProvider,
    private navParam: NavParams,
    public comService: CommonService,
    private transService: TranslationService
  ) {
    console.log('param data == ', this.navParam.data);
    if (this.navParam) {
      this.bonus = this.navParam.get('param1');
      this.bonusPageSource = this.navParam.get('param2');
    }

    this.successMessageProvider.clearMessage();

    // check which bonus vertical selected bonus has
    if (
      !isUndefined(this.bonus.vertical) &&
      !isUndefined(this.bonus.vertical.SPORTS)
    ) {
      this.sportsEnabled = true;
    }

    if (
      !isUndefined(this.bonus.vertical) &&
      !isUndefined(this.bonus.vertical.CASINO)
    ) {
      this.casinoEnabled = true;
    }

    if (
      !isUndefined(this.bonus.vertical) &&
      !isUndefined(this.bonus.vertical.LIVE)
    ) {
      this.liveEnabled = true;
    }

    if (this.bonus.vertical) {
      Object.keys(this.bonus.vertical).forEach((key: any) => {
        var temp: any = {
          name: key,
          ...this.bonus.vertical[key]
        };
        this.rollovers.push(temp);
      });
    }
  }

  ngOnInit(): void {
    this.currency = this.comService.currencySymbols[this.config.getConfig().DEFAULT_CURRENCY] || this.config.getConfig().DEFAULT_CURRENCY;
  }

  ionViewWillEnter() {
    this.wager = [];
  }

  wagerCalculations() {}

  ionViewDidEnter() {
    this.featureSiteTermsLink = this.config.getConfig().FEATURE_SITE_TERMS_LINK || '';
  }

  onShowForfeitConfirm() {
    this.showForfeitConfirm = true;
  }

  onCancelForfeitBonus() {
    this.showForfeitConfirm = false;
  }

  onForfeitBonus() {
    this.error = null;

    this.rewardsProvider.callForfeitBonus(this.bonus.id).subscribe(
      (response: any) => {
        console.log('onForfeitBonus resp == ', response);
        if (response.code == 200 && !!response.result) {
          this.successMessageProvider.setMessage('Your bonus was forfeited.');
          this.showForfeitConfirm = false;
          this.hasBeenForfeit = true;

          this.rewardsProvider.callGetBonusDetails(this.bonus.id).subscribe(
            (response: any) => {
              console.log('callGetBonusDetails response = ', response);
              if (response.code == 200 && !!response.result) {
                this.bonus.status = 'FORFEITED';
              }
            },
            (error) => {
              console.log('callGetBonusDetails error = ', error);

              this.error = error;
            }
          );
        }
      },
      async (error) => {
        this.error = error;
        console.error('onForfeitBonus error == ', this.error);
        this.comService.showToast(error.getErrorMessage(), 'error_toast');
      }
    );
  }

  onBackClicked() {
    Navigator.back();
  }

  getBonusAmount(): string {
    let currentBonus = parseFloat(this.bonus.currentBonus) || 0;
    let currentDeposit = parseFloat(this.bonus.currentDeposit) || 0;
    let currentWinning = parseFloat(this.bonus.currentWinning) || 0;
    this.bonusAmount = currentBonus + currentDeposit + currentWinning;
    return this.bonusAmount.toFixed(2);
  }

  getProgressCasinoWidth = () =>
    this.bonus.percentContributedCasino > 100
      ? '100%'
      : this.bonus.percentContributedCasino + '%';
  getProgressSportsWidth = () =>
    this.bonus.percentContributedSports > 100
      ? '100%'
      : this.bonus.percentContributedSports + '%';

  getProgressLiveWidth = () =>
    this.bonus.percentContributedLive > 100
      ? '100%'
      : this.bonus.percentContributedLive + '%';

  getWagersRemaining(selectedVertical: string) {
    let wagersRemaining;

    if (this.bonus.vertical != null) {
      if (
        this.bonus.originalDeposit != null &&
        !!this.bonus.originalBonus != null &&
        this.bonus.vertical[selectedVertical] &&
        this.bonus.vertical[selectedVertical].rolloverWagerMultiplier != null &&
        this.bonus.vertical[selectedVertical].wagerContribution != null
      ) {
        if (this.bonus.rolloverWagerOriginalBonusOnly) {
          wagersRemaining = this.bonus.originalBonus * Number(this.bonus.vertical[selectedVertical].rolloverWagerMultiplier) - Number(this.bonus.vertical[selectedVertical].wagerContribution || 0);
        }
        else {
          wagersRemaining = (Number(this.bonus.originalDeposit) + Number(this.bonus.originalBonus)) * Number(this.bonus.vertical[selectedVertical].rolloverWagerMultiplier) - Number(this.bonus.vertical[selectedVertical].wagerContribution || 0);
        }
      } else {
        return null;
      }
    } else {
      return null;
    }

    return this.formatNumberWithCommas(wagersRemaining.toFixed(2));
  }

  formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  bonusPolicy() {
    return false;
  }
}
