import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Config } from "./configuration"
import { GlobalErrorHandler } from './global-error-handler';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { CommonService } from 'src/app/service/common.service';
import { TranslationService } from 'src/app/service/translation.service';

export interface PlatformResponse<T> {
  result?: boolean;
  message?: string;
  code?: number;
  payload?: T;
}

@Injectable({
  providedIn: 'root'
})
export class WebservicesProvider {

  configData: any;

  constructor(
    private http: HttpClient,
    private globalErrorHandler: GlobalErrorHandler,
    private config: Config,
    public loadingCtrl: LoadingController,
    private comService: CommonService,
    private transService: TranslationService
  ) { }

  getByUrl(url: string, params?: any) {
    console.log('get api url: ', url);
    let options: any = {};
    if (params) {
      options = {params};
    }

    let headers: any = {};
    headers['Accept-Language'] = this.transService.getSelectedLanguage();
    options.headers = headers;

    let httpSubject = new Subject<any>();
    this.http.get(url, options).subscribe(
      (httpResp: any) => {
        let response = httpResp;

        if (response.code == 200) {
          response.result = true;
          httpSubject.next(response);
        } else {
          let error = this.globalErrorHandler.handleError(response);
          if (!error.isSuppressed()) {
            httpSubject.error(error);
          }
        }
      },
      (err) => {
        let error = this.globalErrorHandler.handleError({ 'code': 'HTTP_ERROR' });
        if (!error.isSuppressed()) {
          httpSubject.error(error);
        }
      }
    );
    return httpSubject;
  }

  get(method: string, param?: any, htpClient?: any) {
    let params: any = {};
    if (param) {
      for (let k in param) {
        params[k] = param[k];
      }
    }

    let headers: any = {};
    headers['Accept-Language'] = this.transService.getSelectedLanguage();

    console.log('get api url: ', this.config.getEnv().webservices_url + '/' + method);

    let httpSubject = new Subject<any>();

    this.http.get(this.config.getEnv().webservices_url + '/' + method, {headers, params}).subscribe(
      (httpResp: any) => {
        let response = httpResp;

        if (response.code == 200) {
          response.result = true;
          httpSubject.next(response);
        } else {
          let error = this.globalErrorHandler.handleError(response);
          httpSubject.error(error);
        }
      },
      (err) => {
        let error = this.globalErrorHandler.handleError({ 'code': 'HTTP_ERROR' });
        httpSubject.error(error);
      }
    );
    return httpSubject;
  }

  postJson(method: string, params?: any, showLoader = true) {
    // if (showLoader) this.comService.showLoader('Please wait...');

    let headers: any = {};
    headers['Accept-Language'] = this.transService.getSelectedLanguage();
    // headers['Content-Type'] = 'application/json';

    let httpSubject = new Subject<any>();

    let httpSub = this.http.post(this.config.getEnv().webservices_url + '/' + method, params, {headers});
    httpSub.subscribe(
      (httpResp: any) => {
        // if (showLoader) this.comService.hideLoader();
        let response = httpResp;
        if (response.code == 200) {
          response.result = true;
          httpSubject.next(response);
          httpSubject.complete();
        } else {
          let error = this.globalErrorHandler.handleError(response);
          if (!error.isSuppressed()) {
            httpSubject.error(error);
          }
        }
      },
      (err) => {
        // if (showLoader) this.comService.hideLoader();
        let error = this.globalErrorHandler.handleError({ 'code': 'HTTP_ERROR' });
        if (!error.isSuppressed()) {
          httpSubject.error(error);
        }
      }
    );

    return httpSubject;
  }

  post(method: string, params?: any, showLoader = true) {

    // if (showLoader) this.comService.showLoader('Please wait...');

    let headers: any = {};
    headers['Accept-Language'] = this.transService.getSelectedLanguage();
    // headers['Content-Type'] = 'application/x-www-form-urlencoded';

    if (params) {
      let body = new FormData();
      for (let k in params) {
        body.append(k, params[k]);
      }

      let httpSubject = new Subject<any>();
      console.log('post api: ', this.config.getEnv().webservices_url + '/' + method);

      this.http.post(this.config.getEnv().webservices_url + '/' + method, body, {headers}).subscribe(
        (httpResp: any) => {
          // if (showLoader) this.comService.hideLoader();
          let response = httpResp;
          console.log('post res == ', response);
          if (response.code == 200) {
            response.result = true;
            httpSubject.next(response);
            httpSubject.complete();
          } else {
            let error = this.globalErrorHandler.handleError(response);
            if (!error.isSuppressed()) {
              httpSubject.error(error);
            }
          }
        },
        (err) => {
          console.error('post err == ', err);
          // if (showLoader) this.comService.hideLoader();
          let error;
          if (err._body) {
            let obj = JSON.parse(err._body);
            error = this.globalErrorHandler.handleError(obj);
          } else {
            error = this.globalErrorHandler.handleError({ 'code': 'HTTP_ERROR' });
          }
          if (!error.isSuppressed()) {
            httpSubject.error(error);
          }
        }
      );
      return httpSubject;
    }
  }

  postByApiUrl(apiURL: string, params?: any, showLoader = true) {

    // if (showLoader) this.comService.showLoader('Please wait...');

    let headers: any = {};
    headers['Accept-Language'] = this.transService.getSelectedLanguage();
    // headers['Content-Type'] = 'application/x-www-form-urlencoded';

    if (params) {
      let body = new FormData();
      for (let k in params) {
        body.append(k, params[k]);
      }

      let httpSubject = new Subject<any>();
      console.log('api url: ', apiURL);

      this.http.post(apiURL, body, {headers}).subscribe(
        (httpResp: any) => {
          // if (showLoader) this.comService.hideLoader();
          let response = httpResp;
          if (response.code == 200) {
            response.result = true;
            httpSubject.next(response);
            httpSubject.complete();
          } else {
            let error = this.globalErrorHandler.handleError(response);
            if (!error.isSuppressed()) {
              httpSubject.error(error);
            }
          }
        },
        (err) => {
          // if (showLoader) this.comService.hideLoader();
          console.error('api err: ', err);
          let error;
          if (err._body) {
            let obj = JSON.parse(err._body);
            error = this.globalErrorHandler.handleError(obj);
          } else {
            error = this.globalErrorHandler.handleError({ 'code': 'HTTP_ERROR' });
          }
          if (!error.isSuppressed()) {
            httpSubject.error(error);
          }
        }
      );
      return httpSubject;
    }
  }

  postByUrl(apiURL: string, params?: any) {
    console.log('api url: ', apiURL);
    let headers: any = {};
    headers['Content-Type'] = 'application/x-www-form-urlencoded';

    if (params) {
      let body = new FormData();
      for (let k in params) {
        body.append(k, params[k]);
      }

      let httpSubject = new Subject<any>();      

      this.http.post(apiURL, body, {headers}).subscribe(
        (httpResp: any) => {
          let response = httpResp;
          httpSubject.next(response);
          httpSubject.complete();
        },
        (err) => {
          console.error('api err: ', err);
          httpSubject.error(err);
        }
      );
      return httpSubject;
    }
  }

  getRaw(method: string, param?: any) {
    let params: any = {};
    if (param) {
      for (let k in param) {
        params[k] = param[k];
      }
    }

    let headers: any = {};
    headers['Accept-Language'] = this.transService.getSelectedLanguage();

    let httpSubject = new Subject<any>();
    this.http.get(this.config.getEnv().webservices_url + '/' + method, {headers, params}).subscribe(
      (httpResp: any) => {
        let response = httpResp;
        let status = httpResp.status;
        if (response.code == 200) {
          response.result = true;
          httpSubject.next(response);
        } else {
          let error = this.globalErrorHandler.handleError(httpResp);
          if (!error.isSuppressed()) {
            httpSubject.error(error);
          }
        }
      },
      (err) => {
        let error = this.globalErrorHandler.handleError({ 'code': 'HTTP_ERROR' });
        if (!error.isSuppressed()) {
          httpSubject.error(error);
        }
      }
    );
    return httpSubject;
  }

  getXML(method: string, param?: any) {
    let params: any = {};
    if (param) {
      for (let k in param) {
        params[k] = param[k];
      }
    }

    let headers: any = {};
    headers['Accept-Language'] = this.transService.getSelectedLanguage();

    let httpSubject = new Subject<any>();
    this.http.get(this.config.getEnv().webservices_url + '/' + method, {headers, params}).subscribe(
      (httpResp) => {
          let response = httpResp; // .text();
          httpSubject.next( response);
      },
      (err) => {
        let error = this.globalErrorHandler.handleError({ 'code': 'HTTP_ERROR' });
        if (!error.isSuppressed()) {
          httpSubject.error(error);
        }
      }
    );
    return httpSubject;
  }

  postRaw(method: string, params?: any, showLoader = true) {
    // loader
    // if (showLoader) this.comService.showLoader('Please wait...');

    let headers: any = {};
    headers['Accept-Language'] = this.transService.getSelectedLanguage();
    // headers['Content-Type'] = 'application/x-www-form-urlencoded';

    if (params) {
      let body = new FormData();
      for (let k in params) {
        body.append(k, params[k]);
      }

      let httpSubject = new Subject<any>();
      this.http.post(this.config.getEnv().webservices_url + '/' + method, body, {headers}).subscribe(
        (httpResp: any) => {
          // if (showLoader) this.comService.hideLoader();
          let response = httpResp;
          let status = httpResp.status;
          if (response.code == 200) {
            response.result = true;
            httpSubject.next(response);
            httpSubject.complete();
          } else {
            let error = this.globalErrorHandler.handleError(httpResp);
            if (!error.isSuppressed()) {
              httpSubject.error(error);
            }
          }
        },
        (err) => {
          // if (showLoader) this.comService.hideLoader();
          let error = this.globalErrorHandler.handleError({ 'code': 'HTTP_ERROR' });
          if (!error.isSuppressed()) {
            httpSubject.error(error);
          }
        }
      );
      return httpSubject;
    }
  }

  postFormData(method: string, body?: FormData, showLoader = true) {
    // loader
    // if (showLoader) this.comService.showLoader('Please wait...');

    let headers: any = {};
    headers['Accept-Language'] = this.transService.getSelectedLanguage();
    // headers['Content-Type'] = 'multipart/form-data';

    let httpSubject = new Subject<any>();
    this.http.post(this.config.getEnv().webservices_url + '/' + method, body, {headers}).subscribe(
      (httpResp: any) => {
        // if (showLoader) this.comService.hideLoader();
        let response = httpResp;

        if (response.code == 200) {
          response.result = true;
          httpSubject.next(response);
        } else {
          let error = this.globalErrorHandler.handleError(httpResp);
          if (!error.isSuppressed()) {
            httpSubject.error(error);
          }
        }
      },
      (err) => {
        // if (showLoader) this.comService.hideLoader();
        let error = this.globalErrorHandler.handleError({ 'code': 'HTTP_ERROR' });
        if (!error.isSuppressed()) {
          httpSubject.error(error);
        }
      }
    );
    return httpSubject;
  }

  getResourcesApi(method: string, param?: any) {
    let params: any = {};
    if (param) {
      for (let k in param) {
        params[k] = param[k];
      }
    }

    let headers: any = {};
    headers['Accept-Language'] = this.transService.getSelectedLanguage();

    let httpSubject = new Subject<any>();
    console.log('getResourcesApi == ', this.config.getConfig()['RESOURCE_WEBSERVICE_ENDPOINT'] + method);
    this.http.get(this.config.getConfig()['RESOURCE_WEBSERVICE_ENDPOINT'] + method, {headers, params}).subscribe(
      (httpResp: any) => {
        let response = httpResp;
        response.result = true;
        httpSubject.next( response);
      },
      (err) => {
        let error = this.globalErrorHandler.handleError({ 'code': 'HTTP_ERROR' });
        if (!error.isSuppressed()) {
          httpSubject.error(error);
        }
      }
    );
    return httpSubject;
  }

  postResourcesApi(method: string, params?: any, showLoader = true) {
    // loader
    // if (showLoader) this.comService.showLoader('Please wait...');

    let headers: any = {};
    headers['Accept-Language'] = this.transService.getSelectedLanguage();
    // headers['Content-Type'] = 'application/x-www-form-urlencoded';

    if (params) {
      let body = new FormData();
      for (let k in params) {
        body.append(k, params[k]);
      }

      let httpSubject = new Subject<any>();
      this.http.post(this.config.getConfig()['RESOURCE_WEBSERVICE_ENDPOINT'] + method, body, {headers}).subscribe(
        (httpResp: any) => {
          // if (showLoader) this.comService.hideLoader();
          let response = httpResp;

          if (response.code == 200) {
            response.result = true;
            httpSubject.next(response);
            httpSubject.complete();
          } else {
            let error = this.globalErrorHandler.handleError(httpResp);
            if (!error.isSuppressed()) {
              httpSubject.error(error);
            }
          }
        },
        (err) => {
          // if (showLoader) this.comService.hideLoader();
          let error = this.globalErrorHandler.handleError({ 'code': 'HTTP_ERROR' });
          if (!error.isSuppressed()) {
            httpSubject.error(error);
          }
        }
      );
      return httpSubject;
    }
  }

  getApiNoRedirect(method: string, deviceType:string, param?: any) {
    let params: any = {};
    if (param) {
      for (let k in param) {
        params[k] = param[k];
      }
    }

    let headers: any = {};
    headers['Accept-Language'] = this.transService.getSelectedLanguage();

    //append if not IOS or Android app
    if( deviceType != 'mobile' && param.session_token) {
      // headers.set('random-x', param.session_token);
      headers['random-x'] = param.session_token;
    }

    let httpSubject = new Subject<any>();

    this.http.get(method, {headers, params}).subscribe(
      (httpResp: any) => {
        let response = httpResp;
        response.result = true;
        httpSubject.next( response);
      },
      (err) => {
        let error = this.globalErrorHandler.handleError({ 'code': 'HTTP_ERROR' });
        if (!error.isSuppressed()) {
          httpSubject.error(error);
        }
      }
    );
    return httpSubject;
  }

  getCoutries(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get('assets/countries.json').subscribe((countries: any[]) => {
        this.comService.countries = countries.filter(el => el.dial_code);
        this.comService.countries.forEach(ct => {
          ct.dial_code = (ct.dial_code as string).replace(' ', '');
        });
        resolve(this.comService.countries);
      }, error => reject(error));
    });
  }

}
