import { Component, Input, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CustomerModel } from 'src/app/modules/account/models/customer-model';
import { GameHubController } from 'src/app/modules/gameHub/game-hub-controller';
import { MyEvent } from 'src/app/service/myevent.services';
import { Config } from 'src/app/system/providers/configuration';
import { UserSessionProvider } from 'src/app/system/providers/user-session';

@Component({
  selector: 'app-gallery-casino',
  templateUrl: './gallery-casino.component.html',
  styleUrls: ['./gallery-casino.component.scss'],
})
export class GalleryCasinoComponent implements OnInit {

  @Input() filteredCasinoGames: any[] | any = [];
  @Input() showLastPlayed: boolean;
  @Input() customerLevel: number;
  @Input() isMobileWeb: boolean;
  @Input() LastPlayedGamesPresent: boolean;
  @Input() isIOSApp: boolean;
  @Input() enableInfinite: boolean = true;
  @Input() pageSizeSet: any = 30;
  @Input() smallSize: any = 4;

  customer: CustomerModel;
  public pageSize: any = this.pageSizeSet;

  loadingMore = false;
  progress = 0;

  endRowItemPosition = 5;
  countInRow = 6;

  constructor(
    public userSession: UserSessionProvider,
    public config: Config,
    public gameHubController: GameHubController,
    private events: MyEvent
  ) {
    this.events.getObservable().subscribe((data) => {
      if (data.event == 'event:keywordaction') {
        this.pageSize = this.pageSizeSet;
        this.progress = this.filteredCasinoGames.length ? this.pageSize / this.filteredCasinoGames.length : 0;
      }
      else if (data.event == 'event:togglebanners') {
      }
    });
  }

  ngOnInit() {
    fromEvent(window, 'resize').pipe(debounceTime(100)).subscribe((event) => {
      if (window.innerWidth > 698) {
        this.endRowItemPosition = 5;
        this.countInRow = 6;
      } else {
        this.endRowItemPosition = 2;
        this.countInRow = 3;
      }
    });
  }

  ngAfterViewInit() {
    if (window.innerWidth > 698) {
      this.endRowItemPosition = 5;
      this.countInRow = 6;
    } else {
      this.endRowItemPosition = 2;
      this.countInRow = 3;
    }
    this.pageSize = this.pageSizeSet;
    this.userSession.getCustomer().subscribe((customer: CustomerModel) => {
      this.customer = customer;
    });
    this.progress = this.filteredCasinoGames.length ? this.pageSize / this.filteredCasinoGames.length : 0;
  }

  doInfinite(e) {
    this.loadingMore = true;
    setTimeout(() => {
      this.loadingMore = false;
      this.increasePageSize();
      // e.target.complete();
    }, 1100);
  }

  increasePageSize() {
    this.pageSize = this.pageSize + this.pageSizeSet;
    this.progress = this.filteredCasinoGames.length ? this.pageSize / this.filteredCasinoGames.length : 0;
  }
}
