import { Component, Input } from '@angular/core';

/**
 * Generated class for the successMessageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'successMessageComponent',
  templateUrl: 'success-message.html',
  styleUrls: ['./success-message.scss']
})
export class SuccessMessageComponent {

  @Input() successMessageProvider: any;

  constructor() {}
}
