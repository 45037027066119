import { Component, OnInit } from '@angular/core';
import { Navigator } from 'src/app/system/components/menu/navigator';

@Component({
  selector: 'app-betting-commission',
  templateUrl: './betting-commission.component.html',
  styleUrls: ['./betting-commission.component.scss'],
})
export class BettingCommissionComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

  close() {
    Navigator.back();
  }

}
