import { Injectable } from '@angular/core';
//
import { CustomerModel } from '../../modules/account/models/customer-model';
import {
  AffiliateTrackerInterface,
  AffiliateTrackerCallbackParams,
} from './affiliation-event';
import { WebservicesProvider } from '../../system/providers/webservices';
import { UniqueIDProvider } from '../../shared/providers/unique-id-provider';
import { Config } from './../../system/providers/configuration';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class IncomeAccessMobileTracker implements AffiliateTrackerInterface {

  public customer: CustomerModel;
  protected configVars: any;
  protected params: any;
  protected attributionData: any = null;

  constructor(
    private platform: Platform,
    private webservices: WebservicesProvider,
    private uniqueIDProvider: UniqueIDProvider,
    private config: Config
  ) {}

  private isFirstInstall() {
    var applaunchCount = window.localStorage.getItem('launchCount');

    //Check if it already exists or not
    if (applaunchCount) {
      console.log(
        'incomeaccess-mobile-tracker.ts: applaunchCount == ' + applaunchCount
      );
    } else {
      //Local storage is not set, hence first time launch. set the local storage item
      console.log('incomeaccess-mobile-tracker.ts: First Install');
      window.localStorage.setItem('launchCount', '1');
    }

    return !applaunchCount;
  }

  initialize(): Promise<AffiliateTrackerInterface> {
    return new Promise((resolve) => {
      this.platform.ready().then(() => {
        if (this.isFirstInstall()) {
          this.getAttributionData().then((data) => {
            this.attributionData = data;
            this.eventtrackingInstallation(data);
            resolve(this);
          });
        } else {
          resolve(this);
        }
      });
    });
  }

  getAttributionData(): Promise<any> {
    return Promise.resolve(null);
  }

  protected eventtrackingInstallation(data: any) {
    this.uniqueIDProvider.getUniqueID().then((device_id) => {
      this.webservices
        .post('external-services/incomeaccess/event/install', {
          device_id: device_id,
          client_app_id: this.config.getClientApp(),
        })
        .subscribe(
          (data) => {
            console.log('incomeaccess-mobile-tracker.ts: data:', data);
          },
          (err) => {
            console.log('incomeaccess-mobile-tracker.ts: err:', err);
          }
        );
    });
  }
  protected eventtrackingRegistration(customer_id: number | string) {
    this.uniqueIDProvider.getUniqueID().then((device_id) => {
      this.webservices
        .post('external-services/incomeaccess/event/register', {
          device_id: device_id,
          player_id: customer_id,
          client_app_id: this.config.getClientApp(),
        })
        .subscribe(
          (data) => {
            console.log('incomeaccess-mobile-tracker.ts: data:', data);
          },
          (err) => {
            console.log('incomeaccess-mobile-tracker.ts: err:', err);
          }
        );
    });
  }

  public signupComplete(cbParams: AffiliateTrackerCallbackParams) {
    this.eventtrackingRegistration(cbParams.customer_id);
  }
  public signupStep1Complete(cbParams: AffiliateTrackerCallbackParams) {
    this.eventtrackingRegistration(cbParams.customer_id);
  }
}
