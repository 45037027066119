export class CustomerAddress {
  street?: string;
  street2?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
  phoneNumber?: string;
  originalPhoneNumber?: string;
  dialCode?: string;

  constructor(data: Object = {}) {
    Object.assign(this, data);
  }
}

export class CustomerModel {
  customerId: number;

  address?: CustomerAddress;
  affiliateCampaignCode?: string;
  affiliateId?: number;
  allowPromoEmails?: boolean;
  allowPromoSms?: boolean;
  americanCitizen?: boolean;
  avatarId?: number;
  birthDate?: Date;
  campaignCode?: string;
  certOver18?: boolean;
  createdDate?: Date;
  currentLoginTime?: string;
  customData: [
    {
      key?: string;
      value?: string;
    }
  ];
  customerAttributionData?: string;
  email?: string;
  excludedUntilTs?: Date;
  extAffiliateCampaignId?: string;
  extAffiliateId?: string;
  extCustId?: string;
  facebookId?: string;
  failedPasswordCount?: number;
  firstName?: string;
  gender?: string;
  governementId?: string;
  governmentIDs: [
    {
      key?: string;
      value?: string;
    }
  ];
  governmentTypeId?: string;
  lastLoginTime?: string;
  lastName?: string;
  middleName?: string;
  nickName?: string;
  password?: string;
  pinNumber?: string;
  preferredCurrency?: {
    currencyCode?: string;
  };
  preferredEmailFormat?: string;
  preferredLanguage?: string;
  privacyPolicy?: boolean;
  salutation?: string;
  sendEmailUponLogin?: boolean;
  status?: string;
  securityQuestion?: string;
  securityAnswer?: string;
  secondSecurityQuestion?: string;
  secondSecurityAnswer?: string;
  surveys?: boolean;
  skinId?: number;
  termsAndCondVersion?: string;
  testAccount?: boolean;
  identityVerificationMethod?: string;
  isBeginnger?: boolean;
  userName?: string;
  emailValidated?: boolean;
  kycValidated?: boolean;
  phoneValidated?: boolean;

  constructor(data: any = {}) {
    this.address = new CustomerAddress(data.address);
    Object.assign(this, data);
  }

  isActive() {
    return this.status.toUpperCase() == 'ACTIVE';
  }

  isIncomplete() {
    if (
      typeof this.firstName == 'undefined' ||
      typeof this.lastName == 'undefined'
    ) {
      return true;
    }
    if (this.status.toUpperCase() == 'INCOMPLETE') {
      return true;
    }
    return false;
  }

  isKycValidated() {
    return typeof this.kycValidated == 'undefined' || !this.kycValidated
      ? false
      : true;
  }

  getCustomDataProperty(key) {
    let value = null;
    if (this.customData) {
      this.customData.forEach(function (item) {
        if (item.key == key) {
          value = item.value;
        }
      });
    }
    return value;
  }
}
