import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ResponsibleGamingProvider } from '../../../providers/responsible-gaming-provider';
import { SuccessMessageProvider } from './../../../../../system/providers/success-message-provider';
import { Utils } from '../../../../../shared/utils';
import { Navigator } from './../../../../../system/components/menu/navigator';
import { Config } from '../../../../../system/providers/configuration';
import { IonContent, Platform } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'page-set-loss-limits',
  templateUrl: 'set-loss-limits.html',
  styleUrls: ['./set-loss-limits.scss'],
})
export class SetLossLimitsPage implements OnInit {

  @Input() data: any;

  @ViewChild('Content') content: IonContent;

  lossDailyCurrentLimit = '(No Limit Set)';
  lossDailyDelete = false;
  lossDailyRolloverPeriod = false;
  lossDailyRolloverPeriodContent: any;
  lossDailyRemoved: boolean;
  lossDailyRemovedContent: any;
  lossDailyAmountUsed = false;
  lossDailyAmountUsedContent: any;
  lossDailyNewLimit: boolean = true;
  lossDailyNewLimitContent: any;
  lossDailyNewLimitEffective: boolean;
  lossDailyNewLimitEffectiveContent: any;

  lossWeeklyCurrentLimit = '(No Limit Set)';
  lossWeeklyDelete: boolean;
  lossWeeklyRolloverPeriod: boolean;
  lossWeeklyRolloverPeriodContent: any;
  lossWeeklyRemoved: boolean;
  lossWeeklyRemovedContent: any;
  lossWeeklyAmountUsed: boolean;
  lossWeeklyAmountUsedContent: any;
  lossWeeklyNewLimit: boolean = true;
  lossWeeklyNewLimitContent: any;
  lossWeeklyNewLimitEffective: boolean;
  lossWeeklyNewLimitEffectiveContent: any;

  lossMonthlyCurrentLimit = '(No Limit Set)';
  lossMonthlyDelete: boolean;
  lossMonthlyRolloverPeriod: boolean;
  lossMonthlyRolloverPeriodContent: any;
  lossMonthlyRemoved: boolean;
  lossMonthlyRemovedContent: any;
  lossMonthlyAmountUsed: boolean;
  lossMonthlyAmountUsedContent: any;
  lossMonthlyNewLimit: boolean = true;
  lossMonthlyNewLimitContent: any;
  lossMonthlyNewLimitEffective: boolean;
  lossMonthlyNewLimitEffectiveContent: any;

  public error = null;

  public lossLimitsForm: FormGroup;

  public loss_daily_limit: AbstractControl;
  public loss_weekly_limit: AbstractControl;
  public loss_monthly_limit: AbstractControl;

  public lossLimits: any[] = [];
  public previousPage: string = '';

  public confirm: boolean = false;
  public successScreen: boolean = true;

  public deletePrompt: boolean = false;
  public deletePromptScreen: boolean = true;
  public deleteTerm: string;

  public rejectPrompt: boolean = false;
  public rejectPromptScreen: boolean = true;

  public state: string;
  public title: string = 'Set Loss Limits';

  constructor(
    private responsibleGamingProvider: ResponsibleGamingProvider,
    public successMessageProvider: SuccessMessageProvider,
    private utils: Utils,
    public events: MyEvent,
    public platform: Platform,
    private config: Config,
    public comService: CommonService
  ) {
    this.successMessageProvider.clearMessage();
    this.error = null;

    this.getLossLimits();

    this.previousPage =
      this.data && this.data.previousPage
        ? this.data.previousPage
        : '';
  }

  ngOnInit() {
    let appConfigInfo = this.config.getAppConfigInfo();
    this.state = appConfigInfo.state.toLowerCase();

    if (this.state === 'iowa') {
      this.title = 'Loss Limits';
    }

    this.lossLimitsForm = new FormGroup(
      {
        loss_daily_limit: new FormControl('', [Validators.pattern('^[0-9]*')]),
        loss_weekly_limit: new FormControl('', [Validators.pattern('^[0-9]*')]),
        loss_monthly_limit: new FormControl('', [
          Validators.pattern('^[0-9]*'),
        ]),
      },
      this.atLeastOneFieldValidator
    );

    this.loss_daily_limit = this.lossLimitsForm.get('loss_daily_limit');
    this.loss_weekly_limit = this.lossLimitsForm.get('loss_weekly_limit');
    this.loss_monthly_limit = this.lossLimitsForm.get('loss_monthly_limit');
  }

  ionViewDidEnter() {
    console.log('ionViewDidEnter SetLossLimitsPage');
  }

  getLossLimits() {
    var tempLimits: any[] = [];

    this.responsibleGamingProvider.getLossLimit().subscribe(
      (response: any) => {
        console.log('getLossLimits response == ', response);
        if (response.code == 200 && !!response.result) {
          tempLimits = response.payload;
          for (let index in tempLimits) {
            this.lossLimits.push(tempLimits[index]);
          }

          this.updateLossLimits(this.lossLimits);
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }

  updateLossLimits(lossLimits: any[]) {
    this.loss_daily_limit.setValue('');
    this.loss_weekly_limit.setValue('');
    this.loss_monthly_limit.setValue('');

    this.loss_daily_limit.markAsPristine();
    this.loss_weekly_limit.markAsPristine();
    this.loss_monthly_limit.markAsPristine();

    for (let i in lossLimits) {
      if (lossLimits[i] != null) {
        if (lossLimits[i].currentLimitTermType == 'DAILY') {
          if (
            lossLimits[i].currentLimit != null ||
            lossLimits[i].currentLimit != ''
          ) {
            this.lossDailyCurrentLimit = this.comService.defaulCurrencySymbol + lossLimits[i].currentLimit;
            this.lossDailyDelete = true;
          } else {
            this.lossDailyCurrentLimit = '(No Limit Set)';
            this.lossDailyDelete = false;
          }

          if (
            lossLimits[i].startPeriod != null &&
            lossLimits[i].endPeriod != null
          ) {
            if (lossLimits[i].cumulativeLimitValue == null) {
              lossLimits[i].cumulativeLimitValue = '0';
            }

            this.lossDailyRolloverPeriod = true;
            this.lossDailyRolloverPeriodContent =
              this.utils.toAmericanDateStandardfromYYYY(
                lossLimits[i].startPeriod
              ) +
              ' TO ' +
              this.utils.toAmericanDateStandardfromYYYY(
                lossLimits[i].endPeriod
              );

            this.lossDailyAmountUsed = true;
            this.lossDailyAmountUsedContent = this.comService.defaulCurrencySymbol + lossLimits[i].cumulativeLimitValue;
          }

          if (lossLimits[i].newLimit && lossLimits[i].endPeriod) {
            this.lossDailyDelete = false;
            this.lossDailyNewLimit = true;
            this.lossDailyNewLimitContent = this.comService.defaulCurrencySymbol + lossLimits[i].newLimit;
            this.lossDailyNewLimitEffective = true;
            this.lossDailyNewLimitEffectiveContent =
              this.utils.toAmericanDateStandardfromYYYY(
                lossLimits[i].endPeriod
              );
            this.lossDailyRemoved = false;
          } else if (
            !lossLimits[i].newLimit &&
            lossLimits[i].deleteFlag == '1'
          ) {
            this.lossDailyDelete = false;
            this.lossDailyNewLimit = false;
            this.lossDailyNewLimitEffective = false;
            this.lossDailyRemoved = true;
            this.lossDailyRemovedContent =
              this.utils.toAmericanDateStandardfromYYYY(
                lossLimits[i].endPeriod
              );
          }
        }

        if (lossLimits[i].currentLimitTermType == 'WEEKLY') {
          if (
            lossLimits[i].currentLimit != null ||
            lossLimits[i].currentLimit != ''
          ) {
            this.lossWeeklyCurrentLimit = this.comService.defaulCurrencySymbol + lossLimits[i].currentLimit;
            this.lossWeeklyDelete = true;
          } else {
            this.lossWeeklyCurrentLimit = '(No Limit Set)';
            this.lossWeeklyDelete = false;
          }

          if (
            lossLimits[i].startPeriod != null &&
            lossLimits[i].endPeriod != null
          ) {
            if (lossLimits[i].cumulativeLimitValue == null) {
              lossLimits[i].cumulativeLimitValue = '0';
            }

            this.lossWeeklyRolloverPeriod = true;
            this.lossWeeklyRolloverPeriodContent =
              this.utils.toAmericanDateStandardfromYYYY(
                lossLimits[i].startPeriod
              ) +
              ' TO ' +
              this.utils.toAmericanDateStandardfromYYYY(
                lossLimits[i].endPeriod
              );

            this.lossWeeklyAmountUsed = true;
            this.lossWeeklyAmountUsedContent = this.comService.defaulCurrencySymbol + lossLimits[i].cumulativeLimitValue;
          }

          if (lossLimits[i].newLimit && lossLimits[i].endPeriod) {
            this.lossWeeklyDelete = false;
            this.lossWeeklyNewLimit = true;
            this.lossWeeklyNewLimitContent = this.comService.defaulCurrencySymbol + lossLimits[i].newLimit;
            this.lossWeeklyNewLimitEffective = true;
            this.lossWeeklyNewLimitEffectiveContent =
              this.utils.toAmericanDateStandardfromYYYY(
                lossLimits[i].endPeriod
              );
            this.lossWeeklyRemoved = false;
          } else if (
            !lossLimits[i].newLimit &&
            lossLimits[i].deleteFlag == '1'
          ) {
            this.lossWeeklyDelete = false;
            this.lossWeeklyNewLimit = false;
            this.lossWeeklyNewLimitEffective = false;
            this.lossWeeklyRemoved = true;
            this.lossWeeklyRemovedContent =
              this.utils.toAmericanDateStandardfromYYYY(
                lossLimits[i].endPeriod
              );
          }
        }
        if (lossLimits[i].currentLimitTermType == 'MONTHLY') {
          if (
            lossLimits[i].currentLimit != null ||
            lossLimits[i].currentLimit != ''
          ) {
            this.lossMonthlyCurrentLimit = this.comService.defaulCurrencySymbol + lossLimits[i].currentLimit;
            this.lossMonthlyDelete = true;
          } else {
            this.lossMonthlyCurrentLimit = '(No Limit Set)';
            this.lossMonthlyDelete = false;
          }

          if (
            lossLimits[i].startPeriod != null &&
            lossLimits[i].endPeriod != null
          ) {
            if (lossLimits[i].cumulativeLimitValue == null) {
              lossLimits[i].cumulativeLimitValue = '0';
            }

            this.lossMonthlyRolloverPeriod = true;
            this.lossMonthlyRolloverPeriodContent =
              this.utils.toAmericanDateStandardfromYYYY(
                lossLimits[i].startPeriod
              ) +
              ' TO ' +
              this.utils.toAmericanDateStandardfromYYYY(
                lossLimits[i].endPeriod
              );

            this.lossMonthlyAmountUsed = true;
            this.lossMonthlyAmountUsedContent = this.comService.defaulCurrencySymbol + lossLimits[i].cumulativeLimitValue;
          }

          if (lossLimits[i].newLimit && lossLimits[i].endPeriod) {
            this.lossMonthlyDelete = false;
            this.lossMonthlyNewLimit = true;
            this.lossMonthlyNewLimitContent = this.comService.defaulCurrencySymbol + lossLimits[i].newLimit;
            this.lossMonthlyNewLimitEffective = true;
            this.lossMonthlyNewLimitEffectiveContent =
              this.utils.toAmericanDateStandardfromYYYY(
                lossLimits[i].endPeriod
              );
            this.lossMonthlyRemoved = false;
          } else if (
            !lossLimits[i].newLimit &&
            lossLimits[i].deleteFlag == '1'
          ) {
            this.lossMonthlyDelete = false;
            this.lossMonthlyNewLimit = false;
            this.lossMonthlyNewLimitEffective = false;
            this.lossMonthlyRemoved = true;
            this.lossMonthlyRemovedContent =
              this.utils.toAmericanDateStandardfromYYYY(
                lossLimits[i].endPeriod
              );
          }
        }
      }
    }
  }

  deleteLimit(term) {
    this.successMessageProvider.clearMessage();
    this.error = null;

    this.deletePrompt = false;
    this.deletePromptScreen = false;

    var requestObj: any = {};
    requestObj.termType = term.toUpperCase();

    this.responsibleGamingProvider.deleteLossLimit(requestObj).subscribe(
      (response: any) => {
        if (response && !!response.result) {
          this.getLossLimits();
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }

  setLimits() {
    this.successMessageProvider.clearMessage();
    this.error = null;
    this.successScreen = false;
    this.confirm = false;

    this.responsibleGamingProvider
      .setLossLimit(this.lossLimitsForm.value)
      .subscribe(
        (response: any) => {
          console.log('setLossLimit response = ', response);
          if (response && !!response.result) {
            this.getLossLimits();

            this.successMessageProvider.setMessage(
              'Your loss limits were set.'
            );

            setTimeout(() => {
              this.content.scrollToTop();
            }, 320);
          }
        },
        (error) => {
          console.log('setLossLimit ', error);

          switch (error.error_code) {
            case '':
              error.setErrorMessage('');
              break;
          }
          this.error = error;

          setTimeout(() => {
            this.content.scrollToTop();
          }, 320);
        }
      );
  }

  atLeastOneFieldValidator(group: FormGroup): { [key: string]: any } {
    let isAtLeastOne = false;
    if (group && group.controls) {
      for (const control in group.controls) {
        if (
          group.controls.hasOwnProperty(control) &&
          group.controls[control].valid &&
          group.controls[control].value
        ) {
          isAtLeastOne = true;
          break;
        }
      }
    }
    return isAtLeastOne ? null : { required: true };
  }

  closeModal = () => {
    if (this.previousPage && this.previousPage == 'SignupResult') {
      setTimeout(() => {
        this.events.publishSomeData({event: 'event:load:sports:signup'});
      }, 500);
    } else {
      Navigator.toLobby();
    }
  };

  onButtonClick() {
    this.confirm = true;
    this.successScreen = true;
    this.successMessageProvider.clearMessage();
  }

  enterPressed() {
    if (!this.lossLimitsForm.invalid) {
      this.onButtonClick();
    }
  }

  preventDefault(event) {
    event.preventDefault();
  }

  onDeleteLimitClicked(term) {
    this.deletePrompt = true;
    this.deletePromptScreen = true;
    this.deleteTerm = term;
    this.successMessageProvider.clearMessage();
  }

  onDeleteCancel() {
    this.onCancel();
  }

  onDeleteConfirm() {
    this.deleteLimit(this.deleteTerm);
  }

  onRejectClicked() {
    this.confirm = false;
    this.successScreen = false;
    this.rejectPrompt = true;
    this.rejectPromptScreen = true;
    this.successMessageProvider.clearMessage();
  }

  onCancelReject() {
    this.confirm = true;
    this.successScreen = true;
    this.rejectPrompt = false;
    this.rejectPromptScreen = false;
    this.successMessageProvider.clearMessage();
  }

  onRejectConfirm() {
    this.onCancel();
  }

  onCancel() {
    this.confirm = false;
    this.deletePrompt = false;
    this.rejectPrompt = false;
    this.getLossLimits();
  }

  onBack = () => {
    if (this.confirm || this.rejectPrompt || this.deletePrompt) {
      this.confirm = false;
      this.deletePrompt = false;
      this.rejectPrompt = false;
      this.getLossLimits();
    } else {
      Navigator.back();
    }
  };
}
