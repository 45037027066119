import { SuccessMessageProvider } from '../../../../../system/providers/success-message-provider';
import { ResponsibleGamingProvider } from '../../../providers/responsible-gaming-provider';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { birthdayValidator } from '../../../../../shared/customFormValidators';
import { UserSessionProvider } from '../../../../../system/providers/user-session';
import { CustomerModel } from '../../../models/customer-model';
import { Navigator } from '../../../../../system/components/menu/navigator';
import { AuthenticationProvider } from '../../../../authentication/providers/authentication-provider';
import { AppLinks } from '../../../../../shared/app-links';
import { Config } from '../../../../../system/providers/configuration';
import { IonInput, NavController, Platform } from '@ionic/angular';
import { CommonService } from 'src/app/service/common.service';
import { TranslationService } from 'src/app/service/translation.service';
import { WebservicesProvider } from 'src/app/system/providers/webservices';

@Component({
  selector: 'page-update-phone-number',
  templateUrl: 'update-phone-number.html',
  styleUrls: ['./update-phone-number.scss'],
})
export class UpdatePhoneNumberPage implements OnInit {

  @ViewChild('verifyCodeInput') verifyCodeInput: IonInput;
  @ViewChild('phoneInput') phoneInput: IonInput;

  @Input() data: any;
  @Input() callback: any;

  public error: any = null;

  public phoneNumber: AbstractControl;
  verifyCode: AbstractControl;

  public updatephoneNumberForm: FormGroup;
  public customer: CustomerModel;

  appConfigInfo: any;
  phoneMaxLength = 10;

  countryCode: any;

  showCountrySelection = false;

  canInputVerificationCode = false;
  disableGetCodeButton = false;
  resendTimePeriod = 60;
  downTimePeriod = 1;
  verifiedStatus = false;
  checkPhoneStatus = false;
  resendInterval: any;

  websiteName: any;

  constructor(
    public navCtrl: NavController,
    public responsibleProvider: ResponsibleGamingProvider,
    public platform: Platform,
    public authProvider: AuthenticationProvider,
    public userSession: UserSessionProvider,
    public appLinks: AppLinks,
    private config: Config,
    public successMessageProvider: SuccessMessageProvider,
    public comService: CommonService,
    private transService: TranslationService,
    private cdr: ChangeDetectorRef,
    private webService: WebservicesProvider
  ) {
    this.successMessageProvider.clearMessage();
  }

  ionViewDidEnter() {

  }

  ngOnInit() {
    this.websiteName = document.querySelector('title').text;

    this.appConfigInfo = this.config.getAppConfigInfo();
    this.phoneMaxLength = this.appConfigInfo.phone_max_length;

    this.updatephoneNumberForm = new FormGroup({
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]+$'),
        Validators.minLength(8),
        Validators.maxLength(20),
      ])
    });

    if (this.appConfigInfo.phone_with_code) {
      this.updatephoneNumberForm.addControl('verifycode', new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6)
      ]));
      this.verifyCode = this.updatephoneNumberForm.get('verifycode');
    }

    this.phoneNumber = this.updatephoneNumberForm.get('phoneNumber');

    this.userSession.getCustomer().subscribe((customer) => {
      if (customer) {
        this.customer = customer;
        this.countryCode = this.customer.address?.dialCode ? this.customer.address?.dialCode : this.appConfigInfo.country_code;
        this.phoneNumber.patchValue(this.customer.address?.originalPhoneNumber || '');
      } else {
        this.countryCode = this.appConfigInfo.country_code;
      }
    });

    this.downTimePeriod = this.config.getConfig().COOLDOWN_PERIOD_IN_MINUTES_FOR_SENDING_SMS_VERIFICATION ? Number(this.config.getConfig().COOLDOWN_PERIOD_IN_MINUTES_FOR_SENDING_SMS_VERIFICATION) : 1;
  }

  inputVerificationCode($event) {
    var phone = $event.target.value;
    var filteredValue = phone.replace(/[^0-9]+/g, '');

    if (this.verifyCodeInput) this.verifyCodeInput.value = filteredValue;

    if (this.verifyCode) {
      this.verifyCode.setErrors(null);
      this.verifyCode.patchValue(filteredValue);
      this.verifyCode.markAsTouched();
      this.verifyCode.markAsDirty();
    }

    this.verifiedStatus = false;

    if (filteredValue.length == 6) {
      this.codeVerification();
    }
  }

  inputPhone($event) {
    const value = $event.target!.value;
    var filteredValue = value.replace(/[^0-9]+/g, '');
    this.phoneNumber.patchValue(filteredValue);
    if (this.phoneInput) this.phoneInput.value = filteredValue;

    this.clearError('phone');
  }

  clearError(part?) {
    this.error = null;
    this.checkPhoneStatus = false;

    if (this.verifyCodeInput) this.verifyCodeInput.value = '';
    if (this.verifyCode) {
      this.verifyCode.patchValue('');
      this.verifyCode.setErrors(null);
      this.verifyCode.markAsPristine();
      this.verifyCode.markAsUntouched();
    }

    this.verifiedStatus = false;
    this.disableGetCodeButton = false;
    this.canInputVerificationCode = false;
  }

  checkPhone() {
    if (this.phoneNumber.invalid) return;
    this.checkPhoneStatus = false;
    var country_code = this.countryCode.substring(1);
    this.authProvider.checkPhone(`${country_code}${this.phoneNumber.value}`).subscribe(resp => {
      console.log('check phone == ', resp);
      if (resp.code == 200 && resp.result) {
        this.checkPhoneStatus = resp.payload;
      }
    }, error => {
      console.error('check phone error == ', error);
    });
  }

  sendSMSCode() {
    var country_code = this.countryCode.substring(1);
    this.webService.post('player/sms/send-validation-code', {
      phone_number: `${country_code}${this.phoneNumber.value}`,
      site: this.websiteName,
      skin_id: 1,
      language_code: this.transService.getSelectedLanguage(),
      session_id: this.userSession.getSessionToken()
    }).subscribe(response => {
      console.log('phone sms == ', response);
      if (response.code == 200) {
        this.canInputVerificationCode = true;
        this.disableGetCodeButton = true;

        if (this.verifyCodeInput) this.verifyCodeInput.value = '';

        if (this.verifyCode) {
          this.verifyCode.patchValue('');
          this.verifyCode.markAsUntouched();
          this.verifyCode.markAsPristine();
        }

        this.verifiedStatus = false;

        this.updatephoneNumberForm.markAsDirty();

        clearInterval(this.resendInterval);
        this.resendInterval = null;
        this.resendTimePeriod = this.downTimePeriod * 60;

        this.resendInterval = setInterval(() => {
          this.resendTimePeriod--;

          if (this.resendTimePeriod <= 0) {
            clearInterval(this.resendInterval);
            this.resendInterval = null;
            this.disableGetCodeButton = false;
            this.resendTimePeriod = this.downTimePeriod * 60;
          }

          this.cdr.detectChanges();
        }, 1000);

        this.cdr.detectChanges();
      }
    }, error => {
      console.error('phone sms error == ', error);
      this.canInputVerificationCode = false;
      this.disableGetCodeButton = false;
      this.comService.showToast(error.getErrorMessage(), 'error_toast');
    });
  }

  codeVerification() {
    var country_code = this.countryCode.substring(1);
    this.webService.post('player/sms/validate-code', {
      phone_number: `${country_code}${this.phoneNumber.value}`,
      code: this.verifyCode.value,
      skin_id: 1
    }).subscribe(response => {
      console.log('code verify sms == ', response);
      if (response.code == 200) {
        this.verifiedStatus = true;
      }
    }, error => {
      console.error('code verify error == ', error);
      this.verifyCode.setErrors({WRONG_CODE: true});
    });
  }


  updatePhoneNumber() {
    var country_code = this.countryCode.substring(1);
    this.webService.post('auth/player/account', {
      phoneNumber: `${this.phoneNumber.value}`,
      dialCode: country_code,
      customer_id: this.customer.customerId,
      session_id: this.userSession.getSessionToken(),
      phone_check: this.appConfigInfo.phone_with_code ? true : false,
      code: this.verifyCode.value
    }).subscribe(
      (response) => {
        if (response.code == 200 && response.result) {
          this.callback();
        }
      },
      async (error) => {
        var msg = await this.transService.getTranslationWord('UPDATE_PHONE.MESSAGES.FAILD');
        error.setErrorMessage(msg);
        this.error = error;
      }
    );
  }

  showCountries() {
    this.showCountrySelection = !this.showCountrySelection;
  }

  changeCountry($event) {
    this.countryCode = $event.country_code;
    this.showCountrySelection = !this.showCountrySelection;
  }

  clickOutside() {
    setTimeout(() => {
      this.showCountrySelection = false;
    }, 270);
  }
}
