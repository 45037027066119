import { NgModule } from '@angular/core';
import { AccountSummaryPage } from './account-summary';
import { SharedModule } from '../../../../shared/shared.module';
import { MyDatePickerModule } from 'mydatepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    AccountSummaryPage,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MyDatePickerModule,
    NgxPaginationModule,
    IonicModule
  ],
})
export class AccountSummaryPageModule {}
