import { NgModule } from '@angular/core';
import { RedeemBonusCodePage } from './redeem-bonus-code';
import { SharedModule } from '../../../../../shared/shared.module';
import { SystemModule } from '../../../../../system/system.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    RedeemBonusCodePage,
  ],
  imports: [
    SharedModule,
    SystemModule,
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
})
export class RedeemBonusCodePageModule {}
