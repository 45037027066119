import { Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { Config } from './../../system/providers/configuration';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JackpotCounterProvider {

  private subjectObs = new Subject<any>();

  constructor(
    private http: HttpClient,
    private config: Config
  ) { }

  sendCounterValue(counterData) {
    this.subjectObs.next(counterData);
  }

  getCounterValueAsObservable(): Observable<any> {
    return this.subjectObs.asObservable();
  }

  getMicrogamingDataAPI(moduleID, currency): Observable<any> {
    let params: any = {
      module_id: moduleID,
      currency_code: currency
    }
    let URL = this.config.getEnv().webservices_url + "/external-services/microgaming/jackpot";
    return this.http.get(URL, { params });
  }
}
