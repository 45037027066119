import { NgModule } from '@angular/core';
import { CasinoBonusActivePage } from './casino-bonus-active';
import { SharedModule } from '../../../../../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [
    CasinoBonusActivePage,
  ],
  imports: [
    SharedModule,
    CommonModule,
    IonicModule,
    TranslateModule
  ],
})
export class CasinoBonusActivePageModule {}
