import { Pipe, PipeTransform } from '@angular/core';
import { isUndefined } from '../../../shared/codeUtils';

@Pipe({
  name: 'filterKeywords',
  pure: true
})
export class FilterKeywords implements PipeTransform {

    transform(items:any[], args:boolean, param2?):any[] {
        if(!isUndefined(items) && !isUndefined(args)) {
            let resultArray = [];
            if(args) {
                for (let item of items) {
                    if(item.action_type != null && item.action_type != "externalpageload" ) {
                        resultArray.push(item); 
                    }
                }
                return resultArray;

            } else {
                for (let item of items) {
                    if(item.action_type != null && item.action_type != "externalpageload" && item.action_type != "lastplayed" ) {
                        resultArray.push(item); 
                    }
                }
                return resultArray;
            }
        } else {
            return null;
        }
    }
}