import {ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { format } from 'date-fns';
import { GamesProvider } from 'src/app/modules/account/providers/games-provider';
import { CommonService } from 'src/app/service/common.service';
import { Navigator } from 'src/app/system/components/menu/navigator';
import { Config } from 'src/app/system/providers/configuration';

@Component({
  selector: 'app-game-history',
  templateUrl: './game-history.page.html',
  styleUrls: ['./game-history.page.scss'],
})
export class GameHistoryPage implements OnInit {

  displaySize = 10;

  data: any[] = Array<any>(0);

  dataLoaded = false;
  pages = [];
  page = 1;
  totalCount = 0
  pageCount = 0;
  placeholderImg : any;

  constructor(
    public comService: CommonService,
    public gamesProvider: GamesProvider,
    public config: Config,
    private cdr: ChangeDetectorRef,
  ) {
    let appConfigInfo = this.config.getAppConfigInfo();
    this.placeholderImg = appConfigInfo.placeholder_img;
  }

  ngOnInit() {

  }

  ionViewDidEnter() {
    this.getHandHistory();
  }

  getHandHistory(cpage = 1) {
    let date = new Date();
    let fromDate = format(new Date(date.setDate(date.getDate() - 14)), 'yyyy-MM-dd');
    let toDate = format(new Date(), 'yyyy-MM-dd');

    this.gamesProvider.callGameLogHistory(fromDate, toDate, cpage, 'all').subscribe((response) => {
      console.log('callGameLogHistory response = ', response);
      this.dataLoaded = true;
      if (response.code == 200 && response.result) {
        this.totalCount = response.payload.count;
        this.page = response.payload.current_page;
        this.displaySize = response.payload.page_size;

        if (this.page == 1) {
          this.pageCount = response.payload.pages;
          this.getPageCount(this.pageCount);
        }

        this.data = response.payload.response;
      }
    }, err => {
      this.dataLoaded = true;
    });
  }

  getPageCount(pageCount = 0) {
    this.pages = [];
    if (pageCount) {
      for (let i = 0; i < pageCount; i++) {
        this.pages.push(i + 1);
      }
    } else {
      var tt = Math.floor(this.data.length / this.displaySize);
      if (this.data.length % this.displaySize > 0) tt++;
      for (let i = 0; i < tt; i++) {
        this.pages.push(i + 1);
      }
    }
  }

  activePage(pp) {
    this.page = pp;
    this.getHandHistory(this.page);
  }

  close() {
    Navigator.back();
  }
  loadImg(gameData) {
    gameData.loaded = true;
    this.cdr.detectChanges();
  }

  errorImg(gameData) {
    gameData.loaded = 'error';
    this.cdr.detectChanges();
  }

  gameResult(bet) {
    if (+bet.betAmount < 0) {
      return 'GAME_HISTORY.DRAW'
    }
    if (+bet.payout > +bet.betAmount) {
      return 'GAME_HISTORY.WIN'
    }
    if (+bet.payout < +bet.betAmount) {
      return 'GAME_HISTORY.LOSS'
    }
    return 'GAME_HISTORY.DRAW'
  }
}
