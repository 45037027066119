import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ResponsibleGamingProvider } from '../../../providers/responsible-gaming-provider';
import { SuccessMessageProvider } from './../../../../../system/providers/success-message-provider';
import { Utils } from '../../../../../shared/utils';
import { Navigator } from './../../../../../system/components/menu/navigator';
import { Config } from '../../../../../system/providers/configuration';
import { IonContent } from '@ionic/angular';
import { MyEvent } from 'src/app/service/myevent.services';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'page-set-wager-limits',
  templateUrl: 'set-wager-limits.html',
  styleUrls: ['./set-wager-limits.scss'],
})
export class SetWagerLimitsPage implements OnInit {

  @Input() data: any;

  @ViewChild('Content') content: IonContent;

  wagerSingleMaxCurrentLimit = '(No Limit Set)';
  wagerSingleMaxDelete: boolean;
  wagerSingleMaxRolloverPeriod: boolean;
  wagerSingleMaxRolloverPeriodContent: any;
  wagerSingleMaxRemoved: boolean;
  wagerSingleMaxRemovedContent: any;
  wagerSingleMaxAmountUsed: boolean;
  wagerSingleMaxAmountUsedContent: any;
  wagerSingleMaxNewLimit: boolean = true;
  wagerSingleMaxNewLimitError: boolean;
  wagerSingleMaxNewLimitContent: any;
  wagerSingleMaxNewLimitEffective: boolean;
  wagerSingleMaxNewLimitEffectiveContent: any;

  public error = null;

  public wagerLimitsForm: FormGroup;

  public wager_single_max_limit: AbstractControl;

  public wagerLimits: any[] = [];

  public globalSingleMaxLimit: any;

  public globalLimits: any[] = [];

  private floatingPointValidator = '^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$';

  public previousPage: string = '';

  public confirm: boolean = false;
  public successScreen: boolean = true;

  public deletePrompt: boolean = false;
  public deletePromptScreen: boolean = true;
  public deleteTerm: string;

  public rejectPrompt: boolean = false;
  public rejectPromptScreen: boolean = true;

  public state: string;

  constructor(
    private responsibleGamingProvider: ResponsibleGamingProvider,
    public successMessageProvider: SuccessMessageProvider,
    private utils: Utils,
    public events: MyEvent,
    private config: Config,
    public comService: CommonService
  ) {
    this.successMessageProvider.clearMessage();
    this.error = null;
  }

  ngOnInit() {
    let appConfigInfo = this.config.getAppConfigInfo();
    this.state = appConfigInfo.state.toLowerCase();

    this.wagerLimitsForm = new FormGroup(
      {
        wager_single_max_limit: new FormControl('', [
          Validators.pattern(this.floatingPointValidator),
        ]),
      },
      this.atLeastOneFieldValidator
    );

    this.wager_single_max_limit = this.wagerLimitsForm.get(
      'wager_single_max_limit'
    );

    this.getGlobalLimits();
    this.getWagerLimits();

    this.previousPage = this.data && this.data.previousPage ? this.data.previousPage : '';
  }

  getGlobalLimits() {
    this.responsibleGamingProvider.getGlobalLimits().subscribe(
      (response: any) => {
        if (response && !!response.result) {
          this.globalLimits = response.payload.filter((limit) => {
            return limit.limitType == 'WAGER';
          });

          // console.log("wager getGlobalLimits = ", this.globalLimits);

          this.globalLimits.forEach((limit) => {
            if (limit.limitTermType == 'SINGLE') {
              this.globalSingleMaxLimit = limit;
              this.wager_single_max_limit.setValidators([
                Validators.pattern(this.floatingPointValidator),
                Validators.max(this.globalSingleMaxLimit.currentLimit),
              ]);
            }
          });
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }

  getWagerLimits() {
    var tempLimits: any[] = [];

    this.responsibleGamingProvider.getWagerLimit().subscribe(
      (response: any) => {
        if (response && !!response.result) {
          tempLimits = response.payload;

          // console.log("getWagerLimits = ", tempLimits);

          for (let index in tempLimits) {
            if (tempLimits[index].limitSource != 'GLOBAL') {
              this.wagerLimits.push(tempLimits[index]);
            }
          }

          this.updateWagerLimits(this.wagerLimits);
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }

  updateWagerLimits(wagerLimits: any[]) {
    this.wager_single_max_limit.setValue('');

    this.wager_single_max_limit.markAsPristine();

    this.wagerSingleMaxNewLimitError = false;

    for (let i in wagerLimits) {
      if (wagerLimits[i] != null) {
        if (wagerLimits[i].currentLimitTermType == 'SINGLE') {
          if (
            wagerLimits[i].currentLimit != null ||
            wagerLimits[i].currentLimit != ''
          ) {
            this.wagerSingleMaxCurrentLimit = this.comService.defaulCurrencySymbol + wagerLimits[i].currentLimit;
            this.wagerSingleMaxDelete = true;
          } else {
            this.wagerSingleMaxCurrentLimit = '(No Limit Set)';
            this.wagerSingleMaxDelete = false;
          }

          if (
            wagerLimits[i].startPeriod != null &&
            wagerLimits[i].endPeriod != null
          ) {
            this.wagerSingleMaxRolloverPeriod = true;
            this.wagerSingleMaxRolloverPeriodContent =
              this.utils.toAmericanDateStandardfromYYYY(
                wagerLimits[i].startPeriod
              ) +
              ' TO ' +
              this.utils.toAmericanDateStandardfromYYYY(
                wagerLimits[i].endPeriod
              );
          }

          if (wagerLimits[i].newLimit && wagerLimits[i].endPeriod) {
            this.wagerSingleMaxDelete = false;
            this.wagerSingleMaxNewLimit = true;
            this.wagerSingleMaxNewLimitContent = this.comService.defaulCurrencySymbol + wagerLimits[i].newLimit;
            this.wagerSingleMaxNewLimitEffective = true;
            this.wagerSingleMaxNewLimitEffectiveContent =
              this.utils.toAmericanDateStandardfromYYYY(
                wagerLimits[i].endPeriod
              );
            this.wagerSingleMaxRemoved = false;
          } else if (
            !wagerLimits[i].newLimit &&
            wagerLimits[i].deleteFlag == '1'
          ) {
            this.wagerSingleMaxDelete = false;
            this.wagerSingleMaxNewLimit = false;
            this.wagerSingleMaxNewLimitEffective = false;
            this.wagerSingleMaxRemoved = true;
            this.wagerSingleMaxRemovedContent =
              this.utils.toAmericanDateStandardfromYYYY(
                wagerLimits[i].endPeriod
              );
          }
        }
      }
    }
  }

  deleteLimit(term) {
    this.successMessageProvider.clearMessage();
    this.error = null;

    this.deletePrompt = false;
    this.deletePromptScreen = false;

    var requestObj: any = {};
    requestObj.termType = term.toUpperCase();

    this.responsibleGamingProvider.deleteWagerLimit(requestObj).subscribe(
      (response: any) => {
        if (response && !!response.result) {
          this.getWagerLimits();
        }
      },
      (error) => {
        this.error = error;
      }
    );
  }

  setLimits() {
    this.successMessageProvider.clearMessage();
    this.error = null;

    this.successScreen = false;
    this.confirm = false;

    this.responsibleGamingProvider
      .setWagerLimit(this.wagerLimitsForm.value)
      .subscribe(
        (response: any) => {
          console.log('setWagerLimit response: ', response);
          if (response && !!response.result) {
            this.getWagerLimits();

            this.successMessageProvider.setMessage(
              'Your single bet limit was set.'
            );

            this.content.scrollToTop();
          }
        },
        (error) => {
          console.log('setWagerLimit error: ', error);

          switch (error.error_code) {
            case '':
              error.setErrorMessage('');
              break;
          }
          this.error = error;
          this.content.scrollToTop();
        }
      );
  }

  singleMaxLimitChanged() {
    if (this.globalSingleMaxLimit != null) {
      if (
        parseInt(this.wager_single_max_limit.value) >
        parseInt(this.globalSingleMaxLimit.currentLimit)
      ) {
        this.wagerSingleMaxNewLimitError = true;
      } else {
        this.wagerSingleMaxNewLimitError = false;
      }
    }
  }

  atLeastOneFieldValidator(group: FormGroup): { [key: string]: any } {
    let isAtLeastOne = false;
    if (group && group.controls) {
      for (const control in group.controls) {
        if (
          group.controls.hasOwnProperty(control) &&
          group.controls[control].valid &&
          group.controls[control].value
        ) {
          isAtLeastOne = true;
          break;
        }
      }
    }
    return isAtLeastOne ? null : { required: true };
  }

  formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  closeModal = () => {
    if (this.previousPage && this.previousPage == 'SignupResult') {
      setTimeout(() => {
        this.events.publishSomeData({event: 'event:load:sports:signup'});
      }, 500);
    } else {
      Navigator.toLobby();
    }
  };

  onButtonClick() {
    this.confirm = true;
    this.successScreen = true;
    this.successMessageProvider.clearMessage();
  }

  preventDefault(event) {
    event.preventDefault();
  }

  onDeleteLimitClicked(term) {
    this.deletePrompt = true;
    this.deletePromptScreen = true;
    this.deleteTerm = term;
    this.successMessageProvider.clearMessage();
  }

  onDeleteCancel() {
    this.onCancel();
  }

  onDeleteConfirm() {
    this.deleteLimit(this.deleteTerm);
  }

  onRejectClicked() {
    this.confirm = false;
    this.successScreen = false;
    this.rejectPrompt = true;
    this.rejectPromptScreen = true;
    this.successMessageProvider.clearMessage();
  }

  onCancelReject() {
    this.confirm = true;
    this.successScreen = true;
    this.rejectPrompt = false;
    this.rejectPromptScreen = false;
    this.successMessageProvider.clearMessage();
  }

  onRejectConfirm() {
    this.onCancel();
  }

  onCancel() {
    this.confirm = false;
    this.deletePrompt = false;
    this.rejectPrompt = false;
    this.getGlobalLimits();
    this.getWagerLimits();
  }

  onBack = () => {
    if (this.confirm || this.rejectPrompt || this.deletePrompt) {
      this.confirm = false;
      this.deletePrompt = false;
      this.rejectPrompt = false;
      this.getGlobalLimits();
      this.getWagerLimits();
    } else {
      Navigator.back();
    }
  };
}
