/**
 * Base Model to implement shared funcitonlity for GameHub instances
 */

export class BaseModel {

    private identifier       : any; // set by each child class to define how to uniquely identify it within GameHub
    private label            : string;
    private instanceCategoryType : string; /* value = cash-game || poker-tourn-game || casino-game || sublobby */

    constructor() {}

    setIdentifier(value) {
        this.identifier = value;
    }

    getIdentifier() {
        return this.identifier;
    }

    setLabel(value) {
        this.label = value;
    }

    getLabel() {
        return this.label;
    }
    
    setInstanceCategoryType( value ) {
        this.instanceCategoryType = value;
    }

    getInstanceCategoryType() {
        return this.instanceCategoryType;
    }
}