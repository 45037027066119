import { MainProvider } from './../../../modules/lobby/providers/main.provider';
import { Utils } from './../../utils';
import { GameHubController } from './../../../modules/gameHub/game-hub-controller';
import { AccountProvider } from './../../../modules/account/providers/account-provider';
import { UserSessionProvider } from './../../../system/providers/user-session';
import { BalanceProvider } from './../../../system/providers/balance-provider';
import { Navigator } from './../../../system/components/menu/navigator';
import { Component, OnInit } from '@angular/core';
import { isUndefined } from '../../codeUtils';
import { Config } from './../../../system/providers/configuration';
// import { Subscription, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { forkJoin } from "rxjs/observable/forkJoin";
import { Storage } from '@ionic/storage-angular';
import { ResponsibleGamingProvider } from '../../../modules/account/providers/responsible-gaming-provider';
import { MyEvent } from 'src/app/service/myevent.services';
import { LoadingController, PopoverController } from '@ionic/angular';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-init',
  templateUrl: 'app-init.html',
  styleUrls: ['./app-init.scss'],
})
export class AppInitComponent {

  public customer;
  public accountBalances;

  private _Que = [];

  public customrQualification;

  public geoData;
  public loading;
  public initTime = 0;
  public depositCode = '';
  public geolocationPasses = false;
  public strongAuthenticationRequired = false;

  constructor(
    public events: MyEvent,
    public balanceProvider: BalanceProvider,
    public userSession: UserSessionProvider,
    private config: Config,
    public accountProvider: AccountProvider,
    public popoverCtrl: PopoverController,
    private comService: CommonService,
    public loadingCtrl: LoadingController,
    private gameHubController: GameHubController,
    private utils: Utils,
    private responsibleGamingProvider: ResponsibleGamingProvider,
    private mainProvider: MainProvider,
    private storage: Storage,
  ) {
    this.events.getObservable().subscribe((data) => {
      if (data.event == 'event:appInit:init') {
        let params = data.data;
        this.depositCode = !!params && !!params.depositCode ? params.depositCode : '';

        if (!this.initTime) {
          this.initTime++;
          setTimeout(() => this.init(), 60);
        }
      }
      else if (data.event == 'event:authentication:logout') {
        {
          this.initTime = 0;
          this._Que = [];
        }
      }
      else if (data.event == 'event:appInit:next') {
        let dat = data.data;
        if (dat && !isUndefined(dat.geolocationPasses)) {
          this.geolocationPasses = dat.geolocationPasses;
        }
        this._Que.pop();
        this.next();
      }
      this.events.unsubscribe("event:appInit:next");
    });

    //Adding BWPA strongAuthentication F5 fix
    this.storage.create().then(() => {
    });
  }

  init() {
    forkJoin([
      this.userSession.getCustomer().pipe(take(1)),
      this.balanceProvider.getBalance().pipe(take(2)),
      this.accountProvider.callGetQualification('freespin').pipe(take(1)),
    ]).subscribe(([customer, balance, customrQualification]) => {
      this.customer = customer;
      this.accountBalances = balance;
      this.customrQualification = customrQualification;
      this.setTheRulesAndStart();
      this.geolocationPasses = false;
    });
  }

  setTheRulesAndStart() {
    let rules = this.rules();

    if (rules.registrationLoginWithADepositCode()) this.addRedirectToDepositPage();
    if (rules.waitingToLaunchAGame()) this.addLaunchGame();

    this.addNotifications();

    this._Que.reverse();
    this.next();
  }

  next() {
    if (!this.userSession.isAuthenticated()) {
      return;
    }

    if (this._Que.length) {
      let next = this._Que[this._Que.length - 1];
      if (!isUndefined(next) && 'action' in next) {
        console.log('AppInit: ', next.name);
        next.action();
      }
    }
    else {
      this.onFinish();
    }
  }

  addLaunchGame() {
    this._Que.push({
      name: 'Launch a game',
      action: () => {
        let gameData = this.mainProvider.getWaitingGameData();

        if (!!gameData) {
          if (gameData.keyword_name == 'Live Dealer') {
            setTimeout(() => {
              this.events.publishSomeData({
                event: 'event:tabs:load',
                tab: 'WEBVIEW',
              });
            }, 100);
            setTimeout(() => {
              this.utils.pushHistoryState('LIVE_DEALER');
              this.events.publishSomeData({
                event: 'event:tabs:load',
                tab: 'LIVE_DEALER',
              });
            }, 100);
          } else {
            this.gameHubController.launchCasinoGame(gameData);
          }
        }
      },
    });
  }

  addRedirectToDepositPage() {
    this._Que.push({
      name: 'Navigate to Deposit page with code.',
      action: () => {
        Navigator.navigateTo(
          'PaymentsPage',
          { forceModal: true },
          { depositCode: this.depositCode }
        );
      },
    });
  }

  addNotifications() {
    this._Que.push({
      name: 'Notification Listener',
      action: () => {
        if (this.loading) this.loading.dismiss();
        this.events.publishSomeData({ event: 'event:notifications:start' });
      },
    });
  }

  onFinish() {
    if (this.loading) this.loading.dismiss();
    this.events.unsubscribe("event:appInit:next");
    this.events.publishSomeData({ event: 'event:lobby:show' });
  }

  rules() {
    let registrationLoginWithADepositCode = () => {
      return !!this.depositCode ? true : false;
    };

    let waitingToLaunchAGame = () => {
      return this.mainProvider.getWaitingGameData() !== null ? true : false;
    };

    return {
      registrationLoginWithADepositCode: registrationLoginWithADepositCode,
      waitingToLaunchAGame: waitingToLaunchAGame
    };
  }
}
