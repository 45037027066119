import { NgModule } from '@angular/core';
import { SharedModule } from './../../../../shared/shared.module';
import { SystemModule } from '../../../../system/system.module';

import { LogoutPage } from './logout';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    LogoutPage
  ],
  imports: [
    CommonModule,
    IonicModule,
    SystemModule,
    SharedModule
  ],
  exports: [
    LogoutPage
  ]
})
export class LogoutPageModule {}
