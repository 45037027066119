import { SuccessMessageProvider } from './../../../../../system/providers/success-message-provider';
import { CustomerModel } from './../../../models/customer-model';
import { UserSessionProvider } from './../../../../../system/providers/user-session';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { AuthenticationProvider } from './../../../../authentication/providers/authentication-provider';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PASSWORD_PATTERN } from './../../../../../shared/customFormValidators';
import { IonContent, ModalController, NavController } from '@ionic/angular';
import { Config } from 'src/app/system/providers/configuration';
import { TranslationService } from 'src/app/service/translation.service';

@Component({
  selector: 'page-change-password',
  templateUrl: 'change-password.html',
  styleUrls: ['./change-password.scss'],
})
export class ChangePasswordPage implements OnInit {

  @ViewChild(IonContent) content: IonContent;
  @Input() callback: any;

  public error: any = null;
  public passwordForm: FormGroup;

  public customer: CustomerModel;

  public currentPassword: AbstractControl;
  public passwordGroup: AbstractControl;
  public newPassword: AbstractControl;
  public confirmPassword: AbstractControl;
  public securityGroup: AbstractControl;
  public securityAnswer: AbstractControl;

  constructor(
    public navCtrl: NavController,
    public authProvider: AuthenticationProvider,
    public userSession: UserSessionProvider,
    public successMessageProvider: SuccessMessageProvider,
    private modalCtrl: ModalController,
    private config: Config,
    private transService: TranslationService
  ) {
    this.successMessageProvider.clearMessage();

    this.userSession.getCustomer().subscribe((customer) => {
      if (customer) {
        this.customer = customer;
      }
    });
  }

  ngOnInit() {
    let appConfigInfo = this.config.getAppConfigInfo();// get json from newjesey.config file

    this.passwordForm = new FormGroup({
      currentPassword: new FormControl('', [Validators.required]),
      passwordGroup: new FormGroup(
        {
          newPassword: new FormControl('', [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(48),
            Validators.pattern(appConfigInfo.password_pattern || ''),
          ]),
          confirmPassword: new FormControl('', [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(48),
            Validators.pattern(appConfigInfo.password_pattern || ''),
          ]),
        },
        this.passwordMatchValidator
      ),
      securityGroup: new FormGroup({
        securityAnswer: new FormControl(''),
      }),
    });

    this.currentPassword = this.passwordForm.get('currentPassword');
    this.passwordGroup = this.passwordForm.get('passwordGroup');
    this.newPassword = this.passwordGroup.get('newPassword');
    this.confirmPassword = this.passwordGroup.get('confirmPassword');
    this.securityGroup = this.passwordForm.get('securityGroup');
    this.securityAnswer = this.securityGroup.get('securityAnswer');
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('newPassword').value === g.get('confirmPassword').value ? null : { mismatch: true };
  }

  onSubmit() {
    console.log('onSubmit ChangePasswordPage', this.passwordForm);

    this.error = null;
    this.successMessageProvider.clearMessage();

    this.authProvider.callChangePassword(this.passwordForm.value).subscribe(
      async (response: any) => {
        console.log('callChangePassword ', response);
        if (response.code == 200 && response.result) {
          this.error = null;

          var msg = this.transService.getTranslationWord('CHANGE_PASSWORD.MESSAGES.SUCCESS');
          this.successMessageProvider.setMessage(msg);

          setTimeout(() => {
            this.content.scrollToTop(200);
          }, 320);

          setTimeout(() => {
            this.successMessageProvider.clearMessage();
            this.callback();
          }, 3200);
        }
      },
      async (error) => {
        console.log('callChangePassword ', error);

        switch (error.error_code) {
          case 10035:
            var msg = this.transService.getTranslationWord('CHANGE_PASSWORD.MESSAGES.WRONG_CURRENT_PASSWORD');
            error.setErrorMessage(msg);
            this.currentPassword.setErrors({ CURRENT_PASS_FAIL: true });
            this.currentPassword.markAsDirty();
            this.currentPassword.markAsTouched();
            break;
          case 10036:
            var msg = this.transService.getTranslationWord('CHANGE_PASSWORD.MESSAGES.NEW_NOT_MATCH_CURRENT');
            error.setErrorMessage(msg);
            this.passwordGroup.setErrors({ CURRENT_PASS_NO_MATCH: true });
            this.passwordGroup.markAsDirty();
            this.passwordGroup.markAsTouched();
            break;
          case 10276:
            error.setErrorMessage(
              'You have incorrectly answered your security question.'
            );
            this.securityGroup.setErrors({ SECURE_NO_MATCH: true });
            this.securityGroup.markAsDirty();
            this.securityGroup.markAsTouched();
            break;
        }
        this.error = error;
        this.content.scrollToTop();
      }
    );
  }


  passType = 'password';
  changeTypeOfPassword() {
    if (this.passType == 'password') this.passType = 'text';
    else this.passType = 'password';
  }

  cpassType = 'password';
  changeTypeOfCPassword() {
    if (this.cpassType == 'password') this.cpassType = 'text';
    else this.cpassType = 'password';
  }

}
