import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss'],
})
export class CustomButtonComponent implements OnInit {

  @Input() buttonTitle: any;
  @Input() roundButton = false;
  @Input() redButton = false;
  @Input() blueButton = false;
  @Input() height: any;
  @Input() width: any;
  @Input() fontSize: any = '14px';

  @Output() clickEvent = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  click() {
    this.clickEvent.emit();
  }

}
